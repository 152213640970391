<template>
  <div>
    <base-button
      v-if="secondaryLabel"
      @click="$emit('secondary')"
      type="primary"
      outline
      :size="size"
      >{{ secondaryLabel }}</base-button
    >
    <base-button
      v-if="primaryEnabled"
      @click="$emit('primary')"
      type="success"
      :size="size"
      >{{ $t(primaryLabel) }}</base-button
    >
    <base-button disabled type="success" :size="size" v-else>{{
      $t(primaryLabel)
    }}</base-button>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'sm',
    },
    secondaryLabel: String,
    primaryLabel: {
      type: String,
      default: 'knowledge.modals.add-item.validate',
    },
    primaryEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
