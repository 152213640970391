<template>
  <div>
    <p class="text-small font-weight-bold text-center">
      {{ $t('knowledge.modals.content-view-setting.review.title') }}
    </p>

    <div class="content-view-setting-review__area mx-4 my-4">
      <!-- CONTENT NAME -->
      <ContentLabelReview
        :content-label="contentLabel"
        :labels="contentViewSetting.labels"
      />

      <!-- PRODUCT PARAMETERS -->
      <ContentParametersReview :parameters="contentViewSetting.parameters" />

      <el-divider v-if="contentViewSetting.caseParameters.length"></el-divider>

      <!-- CASE PARAMETERS -->
      <ContentCaseParametersReview
        :case-parameters="contentViewSetting.caseParameters"
      />

      <el-divider v-if="isGranularAccessRestrictions"></el-divider>

      <!-- ACCESS RESTRICTIONS -->
      <ContentAccessRestrictionsReview
        v-if="isGranularAccessRestrictions"
        :access-restrictions="contentViewSetting.accessRestrictions"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContentLabelReview from './ContentLabelReview';
import ContentParametersReview from '@/components/Commons/ContentParametersReview';
import ContentCaseParametersReview from './ContentCaseParametersReview';
import ContentAccessRestrictionsReview from './ContentAccessRestrictionsReview';

export default {
  name: 'content-view-setting-review',
  components: {
    ContentLabelReview,
    ContentParametersReview,
    ContentCaseParametersReview,
    ContentAccessRestrictionsReview,
  },
  props: {
    contentViewSetting: {
      type: Object,
      default: () => ({
        parameters: [],
        caseParameters: [],
        labels: [],
        accessRestrictions: [],
      }),
    },
    contentLabel: String,
  },
  computed: {
    ...mapGetters(['isGranularAccessRestrictions']),
  },
};
</script>

<style lang="scss" scoped>
.text-small {
  color: $grey-5-mayday;
  font-size: 0.9em;
}

.content-view-setting-review__area {
  background-color: $grey-1-mayday;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 16px;
}
</style>
