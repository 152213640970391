<template>
  <div class="template-navigation-list">
    <div v-if="!templates.length">
      {{ $t('knowledge.templates.empty-label') }}
    </div>
    <TemplatesNavigationItem
      v-bind="template.content"
      v-for="template in templates"
      :key="template.id"
      :template-id="template.id"
      :is-translated="template.content.returnLanguage === navigationLanguage"
      @click.native="openTemplates(template.id)"
    />
  </div>
</template>

<script>
import TemplatesNavigationItem from '@/views/KnowledgeEditor/NavigationBar/Templates/TemplatesNavigationItem';
import { mapGetters } from 'vuex';

export default {
  name: 'templates-navigation-list',
  components: {
    TemplatesNavigationItem,
  },
  computed: {
    ...mapGetters('knowledgeModule', ['templates', 'navigationLanguage']),
  },
  methods: {
    openTemplates(id) {
      return this.$router.push(`/knowledge/templates/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.template-navigation-list {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}
</style>
