<template>
  <div :class="`${baseClass}`">
    <div class="row d-flex mt-5 justify-content-center">
      <div class="col-11 text-center">
        <div class="content-danger-text-container">
          <img
            class="deletion-warning-icon"
            src="~assets/empty-states/deletion-warning.svg"
          />
          <p class="mb-0 pt-1">
            Vous venez de supprimer la dernière plage IP de sécurisation de
            votre instance Mayday.
          </p>
          <p class="font-weight-bold mt-2">
            Souhaitez-vous désactiver la restriction d'IP à l'authentification à
            votre instance ?
          </p>
        </div>
        <div class="d-flex row mt-4 justify-center">
          <el-button type="info" @click="$emit('cancel')" plain>
            {{ $t('generic.cancel') }}
          </el-button>
          <el-button type="danger" @click="disable()" :loading="loading">
            {{ $t('generic.disable') }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const baseClass = 'ip-restriction-settings-drawer__deletion';
const i18nPre =
  'settings-layout.menu-data.authentication.ip-restriction.drawer';

export default {
  name: 'ip-restriction-settings-drawer-deletion',
  data() {
    return {
      baseClass,
      pre: i18nPre,
      loading: false,
    };
  },
  methods: {
    disable() {
      this.loading = true;
      this.$emit('disable');
    },
  },
};
</script>

<style lang="scss" scoped>
.ip-restriction-settings-drawer____deletion {
  width: 100%;
}

.delete-modal {
  :deep() .modal-body {
    max-height: 65vh;
    overflow: auto;
  }
  :deep() .modal-footer {
    justify-content: center;
    padding-top: 0;
  }
}
.deletion-warning-icon {
  max-height: 60px;
  margin-top: -30px;
}
.content-danger-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($red-mayday, 0.05);
}
.content-linked-text-container {
  margin-top: 24px;
  padding: 16px;
  border: 1px solid $red-mayday;
  border-radius: 4px;
}
</style>
