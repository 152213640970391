export const isValidHostname = (value) => {
  if (typeof value !== 'string') return false;

  const validHostnameChars =
    /^([a-z0-9][a-z0-9-]{0,62}[a-z0-9]\.){0,2}([a-z0-9][a-z0-9-]{0,62}[a-z0-9])$/g;

  // This Regex test :
  //      - Total String does not finish by . or -
  //      - There can't be two . or two - or one of each following each other
  //      - Each bit of domain is not longer than 62 chars and at least 2
  //      - Special chars are not allowed

  return validHostnameChars.test(value);
};
