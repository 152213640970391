<template>
  <BaseActionCollapse
    :header-title="$t('knowledge.action-triggers.options.message')"
    :header-icon="['fal', 'envelope-open-text']"
    :auto-open="autoOpen"
    :save-disabled="!isValid"
    :is-new="isNew"
    @create="$emit('create', { actionPayload, langs, title })"
    @delete="$emit('delete')"
  >
    <template v-slot:additional-header>
      <div class="addition-header-wrapper">
        <span>{{ title }}</span>
        <div v-if="langs.length" class="languages-count-wrapper">
          <font-awesome-icon :icon="['fal', 'globe-stand']" />
          <span
            >{{ langs.length }}
            {{
              $tc('knowledge.action-triggers.forms.languages', langs.length)
            }}</span
          >
        </div>
      </div>
    </template>

    <template v-slot:body>
      <div class="message-action-form">
        <WorkflowInput
          :title="$t('knowledge.action-triggers.forms.action-title')"
          :placeholder="$t('knowledge.action-triggers.forms.action-title')"
          :value="title"
          @input="title = $event"
        />
        <WorkflowSelect
          v-if="languagesOptions.length > 1"
          :title="$tc('knowledge.action-triggers.forms.languages', 2)"
          :placeholder="
            $t('knowledge.action-triggers.forms.select-placeholder')
          "
          multiple
          :options="languagesOptions"
          :value="langs"
          @change="langs = $event"
        />
        <div v-if="hasZendesk" class="message-zendesk-wrapper">
          <span class="message-zendesk-attributes-title">{{
            $t('knowledge.action-triggers.options.message')
          }}</span>
          <div
            v-if="showZendeskAttributes"
            class="message-zendesk-attributes-wrapper"
          >
            <div class="attributes-header">
              <span>{{
                $t('knowledge.action-triggers.message-form.zendesk-attributes')
              }}</span>
              <font-awesome-icon
                class="attribute-header-icon"
                :icon="['fal', 'trash']"
                @click="showZendeskAttributes = false"
              />
            </div>
            <div
              class="attribute-wrapper"
              v-for="zendeskAttribute in zendeskAttributes"
              :key="zendeskAttribute.key"
            >
              <span class="attribute-title">{{ zendeskAttribute.title }}</span>
              <input
                class="attribute-input"
                :placeholder="zendeskAttribute.placeholder"
                v-model="actionPayload[zendeskAttribute.key]"
              />
            </div>
          </div>
          <div
            v-else
            class="message-zendesk-attributes-add-button"
            @click="showZendeskAttributes = true"
          >
            <font-awesome-icon :icon="['fal', 'plus-circle']" />
            <span>{{
              $t('knowledge.action-triggers.message-form.zendesk-attributes')
            }}</span>
          </div>
        </div>
        <WorkflowInput
          :placeholder="
            $t('knowledge.action-triggers.message-form.object-placeholder')
          "
          :value="actionPayload.subject"
          @change="actionPayload.subject = $event"
        />

        <MaydayDiagnosticMessageEditor
          ref="editor"
          editable
          :contentId="action ? action.mongoId : null"
          :body="actionPayload.message"
          @input="actionPayload.message = $event"
        />
      </div>
    </template>
  </BaseActionCollapse>
</template>
x

<script>
import MaydayDiagnosticMessageEditor from '../Editors/MaydayDiagnosticMessageEditor';
import BaseActionCollapse from './Base/BaseActionCollapse';
import WorkflowSelect from '../Workflow/WorkflowSelect';
import WorkflowInput from '../Workflow/WorkflowInput';

export default {
  name: 'message-action-form',
  components: {
    MaydayDiagnosticMessageEditor,
    BaseActionCollapse,
    WorkflowSelect,
    WorkflowInput,
  },
  props: {
    languagesOptions: {
      type: Array,
      default: () => [],
    },
    hasZendesk: {
      type: Boolean,
      default: false,
    },
    autoOpen: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Object,
      required: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showZendeskAttributes: false,
      zendeskAttributes: [
        {
          title: this.$t('knowledge.action-triggers.message-form.type.title'),
          key: 'type',
          placeholder: this.$t(
            'knowledge.action-triggers.message-form.type.placeholder',
          ),
        },
        {
          title: this.$t('knowledge.action-triggers.message-form.user.title'),
          key: 'assignee_user_id',
          placeholder: this.$t(
            'knowledge.action-triggers.message-form.user.placeholder',
          ),
        },
        {
          title: this.$t('knowledge.action-triggers.message-form.group.title'),
          key: 'assignee_group_id',
          placeholder: this.$t(
            'knowledge.action-triggers.message-form.group.placeholder',
          ),
        },
        {
          title: this.$t('knowledge.action-triggers.message-form.email.title'),
          key: 'email_cc',
          placeholder: this.$t(
            'knowledge.action-triggers.message-form.email.placeholder',
          ),
        },
        {
          title: this.$t('knowledge.action-triggers.message-form.status.title'),
          key: 'status',
          placeholder: this.$t(
            'knowledge.action-triggers.message-form.status.placeholder',
          ),
        },
      ],
      actionPayload: {
        assignee_group_id: '',
        assignee_user_id: '',
        email_cc: '',
        message: '',
        status: '',
        subject: '',
        type: '',
      },
      langs: [],
      title: '',
    };
  },
  computed: {
    isValid() {
      return Object.values(this.actionPayload).some((value) => value);
    },
  },
  watch: {
    action: {
      handler() {
        if (!this.action) return;
        this.actionPayload = this.action.payload;
        this.langs = this.action.langs;
        this.title = this.action.title;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.languages-count-wrapper {
  color: $grey-5-mayday;
  font-size: 12px;
  font-weight: lighter;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
  border-radius: 4px;
  background-color: $grey-1-mayday;
}

.addition-header-wrapper {
  font-weight: 500;
  color: $grey-5-mayday;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.message-action-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.message-zendesk-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.message-zendesk-attributes-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-block: 12px;
  margin-block: 8px;
  border-block: 1px solid $grey-4-mayday;
}

.message-zendesk-attributes-title {
  font-weight: bold;
  color: black;
}

.message-zendesk-attributes-add-button {
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid black;
  background-color: $grey-1-mayday;
  width: fit-content;
  padding-inline: 6px;
  font-size: 12px;
  border-radius: 4px;
  padding-block: 2px;
  cursor: pointer;
}

.attributes-header {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.attribute-header-icon {
  color: $grey-5-mayday;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: $blue-mayday;
  }
}

.attribute-wrapper {
  display: flex;
  gap: 12px;
  align-items: center;
}
.attribute-title {
  width: 60px;
}
.attribute-input {
  border: 0px;
  font-size: 14px;
  background-color: $grey-1-mayday;
}

.attribute-input:focus {
  outline: none;
}
</style>
