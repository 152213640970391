<template>
  <div
    class="wrapping-row item-row col-12 d-flex align-items-center justify-content-between full-width"
  >
    <div
      class="d-flex align-items-center"
      style="max-width: 80%"
      @click="componentClicked()"
    >
      <div class="div icon-col text-center my-2">
        <img :src="icon" class="d-flex align-items-center card-icon" />
      </div>
      <FolderItemLabelEditor
        :label="label"
        :is-published="isPublished"
        :edit="edit_label"
        :node-type="type"
        :editable="editable"
        @update-content="handleUpdateContent"
        @close="edit_label = false"
      />
    </div>
    <div class="content-label d-flex justify-content-end">
      <ContentListItemLabel
        v-for="label in labels"
        :key="label.id"
        :label="label"
      />
      <FolderItemOptions
        v-if="editable"
        :node-id="node.id"
        :node-type="type"
        :label="label"
        :icon="contentIcon"
        @edit-label="edit_label = true"
        @delete-item="deleteItem"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import FolderItemLabelEditor from './FolderItemLabelEditor';
import FolderItemOptions from './FolderItemOptions';
import ContentListItemLabel from '@/components/ListItems/ContentListItemLabel';

const diagIcon = require('assets/diag-icon-v2.svg');
const articleIcon = require('assets/article-icon-v2.svg');
const folderIcon = require('assets/close-folder.svg');

export default {
  name: 'collection-content-row-item',
  components: {
    FolderItemLabelEditor,
    FolderItemOptions,
    ContentListItemLabel,
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      edit_label: false,
      modals: {
        moveTo: false,
        icon: false,
      },
    };
  },
  computed: {
    icon() {
      switch (this.node.type) {
        case 'Article':
          return articleIcon;
        case 'Diagnostic':
          return diagIcon;
        default:
          return folderIcon;
      }
    },
    childrenOrder() {
      return this.node ? this.node.childrenOrder || [] : [];
    },
    labels() {
      return this.node ? this.node.labels : [];
    },
    type() {
      return this.node.type ? this.node.type : 'Folder';
    },
    label() {
      return this.node ? this.node.label : '';
    },
    contentIcon() {
      return this.node ? this.node.icon : '';
    },
    isPublished() {
      return this.node && ['Article', 'Diagnostic'].includes(this.node.type)
        ? this.node.published
        : true;
    },
  },
  methods: {
    deleteItem() {
      switch (this.type) {
        case 'Article':
        case 'Diagnostic':
          this.$emit('archive', [this.node.id]);
          break;
        default:
          this.$emit('delete-case', this.node.id);
          break;
      }
    },
    componentClicked() {
      if (!this.edit_label) this.$emit('click');
    },
    async handleUpdateContent(label) {
      let newItem;
      switch (this.type) {
        case 'Article':
        case 'Diagnostic':
          newItem = await this.updateContent({
            id: this.node.id,
            payload: { label },
          });
          break;
        default:
          newItem = await this.updateCollection({
            caseId: this.node.id,
            label,
            body: this.node.body,
            childrenOrder: this.childrenOrder,
          });
          break;
      }
      this.$emit('update-item', newItem);
    },
    ...mapActions('knowledgeModule', ['updateContent', 'updateCollection']),
  },
};
</script>
<style lang="scss" scoped>
.item-row {
  margin-top: 2px;
  margin-bottom: 2px;
  &:hover {
    background-color: $grey-1-mayday;
    border-radius: 2px;
  }
}

.icon-col {
  margin-top: auto;
  margin-bottom: auto;
}

.wrapping-row {
  cursor: pointer;
}

.card-icon {
  max-height: 16px;
  max-width: 16px;
}
</style>
