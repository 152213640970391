<template>
  <div class="new-label-container">
    <ItemEditor
      ref="input"
      class="new-label"
      :value="newLabelName"
      :isLabel="true"
      placeholder="settings.permissions.user-labels.label-input-placeholder"
      @cancel="cancelNewLabelInput"
      @edit="addNewLabel"
    />
  </div>
</template>

<script>
import ItemEditor from './ItemEditor';
export default {
  components: { ItemEditor },
  data() {
    return {
      newLabelName: '',
    };
  },
  methods: {
    cancelNewLabelInput() {
      this.$emit('cancel-new-label-input');
    },
    addNewLabel(name) {
      this.$emit('edit', {
        name,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-label-container {
  display: flex;
  align-items: center;
}

.new-label {
  :deep() .item-editor__input {
    margin-left: 0px;
  }
}
</style>
