<template>
  <div class="d-flex flex-row align-items-center mb-2 change">
    <div
      class="icon-wrapper mr-2"
      v-bind:style="[
        inputType === 'color' ? { backgroundColor: value, color: 'white' } : {},
      ]"
    >
      <font-awesome-icon :icon="icon" class="icon"> </font-awesome-icon>
    </div>
    <div class="change-value">
      {{ action }}
      {{ $t(title) }}
    </div>
    <div class="delete-icon-wrapper ml-2" v-if="operationType !== 'add'">
      <font-awesome-icon
        class="delete-icon"
        :icon="['fas', 'times-circle']"
        @click="deleteOperation()"
      ></font-awesome-icon>
    </div>
  </div>
</template>

<script>
import { labelMapping } from './LabelMappingPublish';

export default {
  name: 'publish-popover-row',
  props: {
    inputType: String,
    label: String,
    operationType: String,
    path: String,
    value: [String, Object],
  },
  computed: {
    icon() {
      return labelMapping[this.label].icon;
    },
    title() {
      return labelMapping[this.label].title;
    },
    action() {
      if (this.operationType === 'add')
        return this.$t('public-knowledge.deployment.popover.add');
      if (this.operationType !== 'update')
        return this.$t('public-knowledge.deployment.popover.delete');
      if (this.inputType === 'boolean') {
        if (this.value === 'true')
          return this.$t('public-knowledge.deployment.popover.activate');
        else return this.$t('public-knowledge.deployment.popover.disable');
      }
      return this.$t('public-knowledge.deployment.popover.update');
    },
  },
  methods: {
    deleteOperation() {
      this.$emit('delete-operation', this.label);
    },
  },
};
</script>

<style lang="scss" scoped>
.change {
  width: max-content;
  &:hover {
    .delete-icon {
      display: block;
    }
  }
}
.delete-icon {
  display: none;
  color: $grey-4-mayday;
  cursor: pointer;
  width: 12px;
  height: 12px;
  margin-top: 3px;
  &:hover {
    color: $blue-mayday;
  }
}
.delete-icon-wrapper {
  width: 16px;
}
.icon-wrapper {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.icon {
  max-width: 12px;
  height: 12px;
}
</style>
