<template>
  <div class="ask-suggested-questions">
    <AskListItemQuestion
      v-for="(question, index) in questions"
      :key="index"
      :question="question"
      :clickable="clickable"
      @trigger-ask="$emit('trigger-ask', $event)"
    ></AskListItemQuestion>
  </div>
</template>

<script>
import AskListItemQuestion from './AskListItemQuestion.vue';
export default {
  name: 'ask-suggested-questions',
  components: { AskListItemQuestion },
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-suggested-questions {
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
</style>
