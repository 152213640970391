<template>
  <div>
    <div
      class="thread__input"
      :class="{
        'thread__input-focused': isFocus,
        'thread__input-disabled': disabled,
      }"
    >
      <div class="thread__input-info">
        <div class="thread__input-username">
          <ThreadAvatar :username="username" />
        </div>

        <div class="thread__input-editor">
          <MessageEditor
            ref="message-editor"
            :autofocus="autofocus"
            :body="newBody"
            :editable="true"
            @input="newBody = $event"
            @focus="isFocus = true"
            @blur="isFocus = false"
          />
        </div>
      </div>
      <div class="thread__input-actions">
        <div
          v-if="hasOptions"
          class="thread__input-actions-buttons"
          :class="{
            'thread__input-actions-buttons-disabled': disabled,
          }"
        >
          <!-- ATTACHMENTS -->
          <AddAttachment
            v-if="options.attachment"
            :attachment-loading="attachmentLoading"
            class="thread__input-actions-button"
            :disabled="fileInputDisable"
            :class="{
              'thread__input-actions-button-disabled':
                attachmentLoading || fileInputDisable,
            }"
            @disable-click="
              showDisableFileUpload(
                'task-view.task-focus-modal.upload-error.disable-file-upload',
              )
            "
            @new-attachment="handleFileChange"
          >
            <font-awesome-icon
              slot="slot-button"
              :icon="['fal', 'paperclip']"
            />
          </AddAttachment>
          <!-- MENTIONS -->
          <button
            v-if="options.mention"
            @click="insertMentions"
            class="thread__input-actions-button"
          >
            <font-awesome-icon :icon="['fal', 'at']" />
          </button>
          <!-- EMOJI -->
          <button
            v-if="options.emoji"
            @click="insertEmoji"
            class="thread__input-actions-button"
          >
            <font-awesome-icon :icon="['fal', 'smile']" />
          </button>
        </div>
        <button
          @click="handleSendMessage"
          :disabled="disabledSend"
          class="thread__input-actions-send"
          :class="{
            'thread__input-actions-send-disabled': disabledSend,
          }"
        >
          <font-awesome-icon
            v-if="loading"
            :icon="['fal', 'spinner-third']"
            spin
          />
          <font-awesome-icon v-else-if="success" :icon="['fal', 'check']" />
          <font-awesome-icon v-else :icon="['fal', 'arrow-up']" />
        </button>
      </div>
    </div>
    <AttachmentList
      :attachments="attachments"
      is-input
      :display-disable-file-upload="displayDisableFileUpload"
      :error-text="errorText"
      :display-header="false"
      :attachment-width="'33.33333%'"
      @delete-attachment="handleDeleteAttachment"
      @disable-click="showDisableFileUpload()"
    />
  </div>
</template>

<script>
import MessageEditor from '@/components/Editors/MessageEditor';
import ThreadAvatar from '@/components/Threads/ThreadAvatar';
import { mapGetters } from 'vuex';
import AddAttachment from '../Attachments/AddAttachment';
import AttachmentList from '../Attachments/AttachmentList';
import AttachmentsMixin from '@/mixins/AttachmentsMixin';

export default {
  name: 'thread-input',
  components: {
    MessageEditor,
    ThreadAvatar,
    AddAttachment,
    AttachmentList,
  },
  props: {
    creating: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({
        attachment: true,
        mention: true,
        emoji: true,
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [AttachmentsMixin()],
  mounted() {
    this.editor = this.$refs['message-editor'];
  },
  data() {
    return {
      newBody: '',
      isFocus: false,
      loading: false,
      success: false,
      editor: null,
    };
  },
  computed: {
    hasOptions() {
      return Object.values(this.options).some(Boolean);
    },
    disabledSend() {
      return (
        !(this.attachments.length && this.canSendAttachments) &&
        !this.newBody &&
        !this.success &&
        !this.loading
      );
    },
    canSendAttachments() {
      let sendable = true;
      if (this.attachments.length) {
        sendable = this.attachments
          .map((at) => !at.error || !at.loading)
          .every((el) => el);
      }
      return sendable && !this.attachmentLoading;
    },
    ...mapGetters(['username']),
  },
  methods: {
    handleSendMessage() {
      if (this.disabledSend) return;
      this.$emit('send-message', {
        body: this.newBody,
        attachments: this.attachments.map((at) => at.url),
      });
      this.loading = true;
      setTimeout(() => {
        this.handleSuccess();
      }, 500);
    },
    handleSuccess() {
      this.loading = false;
      this.success = true;

      setTimeout(() => {
        this.success = false;
      }, 1000);
    },
    insertMentions() {
      if (this.editor) return this.editor.insertMentions();
    },
    insertEmoji() {
      if (this.editor) return this.editor.insertEmoji();
    },
    focus() {
      if (this.editor) return this.editor.focus();
    },
    resetData() {
      (this.newBody = ''), (this.attachments = []);
    },
  },
  watch: {
    creating(newValue) {
      if (!newValue) {
        this.resetData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  background: transparent;
  border: none;
  outline: none;
}

.thread__input {
  display: flex;
  justify-content: space-between;
  padding: 0px 8px;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;

  &-focused {
    border: 1px solid $blue-mayday !important;
  }

  &-disabled {
    pointer-events: none;
    opacity: 0;
  }

  &-editor {
    width: 100%;
    margin-top: 3px;
    max-height: 48px;
    overflow: auto;
    padding: 8px 0;
  }

  &-info {
    width: 100%;
    display: flex;
    gap: 8px;
  }

  &-username {
    padding: 8px 0;
  }

  &-actions {
    display: flex;
    gap: 4px;
    align-self: flex-end;
    padding: 6px 0;

    &-buttons {
      display: flex;
      gap: 2px;

      &-disabled .thread__input-actions-button {
        background-color: $grey-2-mayday !important;
        color: $grey-5-mayday !important;
        cursor: not-allowed !important;
      }
    }

    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $grey-8-mayday;
      height: 24px;
      width: 24px;
      font-size: 14px;
      border-radius: 4px;
      transition: background-color 250ms ease-in-out;

      &-disabled {
        background-color: $grey-2-mayday !important;
        color: $grey-5-mayday !important;
        cursor: not-allowed !important;
      }

      &:hover {
        background-color: $grey-3-mayday !important;
      }
      &:active {
        background-color: $grey-4-mayday !important;
      }
    }

    &-send {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      font-size: 14px;
      background-color: $blue-mayday;
      color: white;
      border-radius: 4px;
      transition: background-color 250ms ease-in-out;
      &-disabled {
        background-color: $grey-2-mayday !important;
        color: $grey-5-mayday !important;
        cursor: not-allowed !important;
      }
      &:hover {
        background-color: $blue-6-mayday;
      }
      &:active {
        background-color: $blue-7-mayday;
      }
    }
  }
}
</style>
