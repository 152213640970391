<template>
  <modal
    class="contribution-modal"
    :show.sync="localOpen"
    :show-close="false"
    headerClasses="p-0 m-0"
    bodyClasses="admin-contribution-modal-body"
    footerClasses="admin-contribution-modal-footer"
  >
    <ModalHeader
      slot="header"
      :title="$t('knowledge.modals.contribution.main-title')"
      @close="closeModal"
    />

    <form
      @keyup.enter.prevent.stop
      @keydown.enter.prevent.stop
      class="admin-contribution-modal-sequence"
    >
      <AdminContributionEdition
        v-show="currentStep === 0"
        @update-main-content="updateMainContent"
        class="contribution-modal__section"
      />

      <GlobalParameterSelector
        v-show="currentStep === 1"
        :init-filters="contribution.parameters"
        :description="'knowledge.modals.admin-contribution.global-parameters'"
        @update-product-filters="updateProductFilters"
        class="contribution-modal__section"
      />

      <CaseParameterSelector
        v-if="currentStep === 2"
        :knowledge="chosenKnowledge"
        :case-parameters="contribution.caseParameters"
        @update-case-parameters="updateCaseParameters"
        class="contribution-modal__section case-section"
      >
        <template slot="subtitle">
          <div class="d-flex align-items-center mb-4">
            <font-awesome-icon
              :icon="['fad', 'info-circle']"
              class="info-icon"
            />
            <p class="text-small mb-0">
              {{
                $t(
                  'knowledge.modals.admin-contribution.case-parameter-description',
                )
              }}
            </p>
          </div>
        </template>
      </CaseParameterSelector>

      <IconSelector
        v-if="currentStep === 3"
        :selected-icons="contribution.labels"
        @update-labels="updateLabels"
        class="contribution-modal__section"
      />

      <GroupSelector
        v-if="currentStep === 4 && isGranularAccessRestrictions"
        :group-ids="contribution.accessRestrictions"
        :possible-user-groups="groups"
        @update-group-ids="updateGroupIds"
        class="contribution-modal__section"
      />
    </form>
    <template v-slot:footer>
      <AdminContributionModalFooter
        class="administration-contribution-modal-footer"
        :steps="steps"
        :current-step="currentStep"
        :step-count="steps.length"
        :is-label-invalid="$v.contribution.label.$invalid"
        :is-type-invalid="$v.contribution.type.$invalid"
        :is-knowledge-invalid="$v.contribution.knowledge.$invalid"
        :are-parameters-invalid="$v.contribution.parameters.$invalid"
        :are-access-restrictions-invalid="
          $v.contribution.accessRestrictions.$invalid
        "
        @close="closeModal"
        @go-to-previous-step="goToPreviousStep"
        @go-to-next-step="goToNextStep"
        @create-contribution="createContribution"
      />
    </template>
  </modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import ModalHeader from '../CreateProductNotificationModal/ModalHeader';
import AdminContributionEdition from './AdminContributionEdition';
import GlobalParameterSelector from '../CreateProductNotificationModal/GlobalParameterSelector';
import GroupSelector from '../CreateProductNotificationModal/GroupSelector';
import CaseParameterSelector from '../ContentViewSettingModal/CaseParameterSelector';
import AdminContributionModalFooter from './AdminContributionModalFooter';
import IconSelector from '../ContentViewSettingModal/IconSelector';

export default {
  name: 'admin-contribution-modal',
  components: {
    ModalHeader,
    AdminContributionEdition,
    GlobalParameterSelector,
    CaseParameterSelector,
    GroupSelector,
    AdminContributionModalFooter,
    IconSelector,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localOpen: this.open,
      currentStep: 0,
      contribution: {
        knowledge: '',
        type: '',
        label: '',
        parameters: [],
        casecpParameters: [],
        labels: [],
        accessRestrictions: [],
      },
      cases: {},
    };
  },
  validations: {
    contribution: {
      label: {
        required,
      },
      type: {
        matchEnum: (value) => ['Article', 'Diagnostic'].includes(value),
        required,
      },
      knowledge: {
        required,
      },
      parameters: {
        haveEnoughParameters: (value) =>
          Object.keys(value).filter((key) => key !== 'knowledge').length,
        required,
      },
      accessRestrictions: {
        required,
      },
    },
  },
  methods: {
    closeModal() {
      this.localOpen = false;
      this.$emit('close');
    },
    goToPreviousStep() {
      this.currentStep -= 1;
    },
    goToNextStep() {
      this.currentStep += 1;
    },
    updateMainContent({ type, label }) {
      this.contribution.type = type;
      this.contribution.label = label;
    },
    updateProductFilters(productFilters) {
      const knowledges = productFilters.filter(
        (filter) => filter.key === 'knowledge',
      );
      if (knowledges.length) {
        this.contribution.knowledge = knowledges[0].values[0];
      }
      this.contribution.parameters = productFilters;
    },
    updateCaseParameters(caseParameters) {
      this.contribution.caseParameters = caseParameters.caseSelection;
    },
    updateGroupIds(groupIds) {
      this.contribution.accessRestrictions = groupIds;
    },
    updateLabels(labels) {
      this.contribution.labels = labels.map((label) => label.id);
    },
    createContribution() {
      this.$emit('create-contribution', this.contribution);
      this.$message({
        // `Send a new contribution: ${JSON.stringify(this.contribution)}`,
        message: 'Félicitations, votre contenu a été créé !',
        type: 'success',
        center: true,
      });
      this.localOpen = false;
    },
  },
  computed: {
    ...mapGetters(['isGranularAccessRestrictions']),
    ...mapState(['userGroups']),
    chosenKnowledge() {
      const knowledge = this.contribution.parameters.find(
        (c) => c.key === 'knowledge',
      );
      return knowledge ? knowledge.values[0] : null;
    },
    steps() {
      return this.isGranularAccessRestrictions ? [0, 1, 2, 3, 4] : [0, 1, 2, 3];
    },
  },
  watch: {
    localOpen() {
      if (!this.localOpen) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss">
.admin-contribution-modal-footer {
  padding-top: 0px;
}

.administration-contribution-modal-footer {
  width: 100%;
}

.overflow-modal {
  max-height: 80vh !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.contribution-modal__section {
  max-height: 50vh;
  overflow: auto;
}

.case-icon {
  color: $grey-5-mayday;
  margin-right: 8px;
}

.info-icon {
  color: $grey-5-mayday;
  margin-right: 8px;
}
</style>
