<template>
  <div class="add-company-group-modal">
    <modal :show.sync="localDisplay">
      <modal-header :style-name="{ backgroundColor: '#0081F91A' }" class="mb-3">
        <div slot="icon" class="d-inline-flex">
          <font-awesome-icon
            class="icon blue-mayday"
            :icon="['fas', 'users']"
          />
        </div>
        <div class="mt-3">
          <div>
            <p class="mb-0 font-weight-bold">
              {{ $t('components.modals.add-company-group.title') }}
            </p>
          </div>
          <el-divider class="my-3"></el-divider>
          <small class="text-primary">
            {{ $t('components.modals.add-company-group.description') }}</small
          >
        </div>
      </modal-header>
      <div class="d-flex justify-content-center modal-steps">
        <div class="col-12 text-center body-wrapper">
          <set-group-name
            v-if="currentStep === 1"
            :current-name="groupName"
            :registeredNames="registeredNames"
            :former-name="formerName"
            @input="setGroupName"
          />
          <set-knowledge-restrictions
            v-else-if="currentStep === 2"
            :current-knowledges="newGroup.knowledges"
            :group-id="newGroup.id"
            @update-restrictions="updateRestrictions"
          />
          <group-summary v-else-if="currentStep === 3" :group="newGroup" />
        </div>
      </div>
      <div slot="footer" class="container">
        <div class="row pb-3 md-stepper-group">
          <el-steps
            align-center
            class="col-12"
            :active="currentStep - 1"
            finish-status="success"
          >
            <el-step
              :description="
                $t('components.modals.add-company-group.sequence.step-1')
              "
            ></el-step>
            <el-step
              :description="
                $t('components.modals.add-company-group.sequence.step-2')
              "
            ></el-step>
            <el-step
              :description="
                $t('components.modals.add-company-group.sequence.step-3')
              "
            ></el-step>
          </el-steps>
        </div>

        <div class="row justify-content-end" v-if="currentStep === 1">
          <base-button
            class="ml-2"
            type="link"
            outline
            size="sm"
            @click="userChoice(false)"
            >{{ $t('components.modals.add-company-group.cancel') }}</base-button
          >
          <base-button
            class="mr-2"
            type="success"
            size="sm"
            v-if="validate"
            @click="currentStep += 1"
            >{{
              $t('components.modals.add-company-group.next-step')
            }}</base-button
          >
          <base-button class="mr-2" type="success" size="sm" disabled v-else>{{
            $t('components.modals.add-company-group.next-step')
          }}</base-button>
        </div>

        <div class="row justify-content-end" v-else-if="currentStep === 2">
          <base-button
            class="mr-2"
            type="secondary"
            size="sm"
            @click="currentStep -= 1"
            >{{
              $t('components.modals.add-company-group.previous-step')
            }}</base-button
          >
          <base-button
            class="mr-2"
            type="success"
            size="sm"
            v-if="validate"
            @click="currentStep += 1"
            >{{
              $t('components.modals.add-company-group.next-step')
            }}</base-button
          >
          <base-button class="mr-2" type="success" size="sm" disabled v-else>{{
            $t('components.modals.add-company-group.next-step')
          }}</base-button>
        </div>
        <div class="row justify-content-end" v-else>
          <base-button
            class="ml-2"
            type="danger"
            outline
            size="sm"
            @click="userChoice(false)"
            >{{ $t('components.modals.add-company-group.cancel') }}</base-button
          >
          <base-button
            class="mr-2"
            type="secondary"
            size="sm"
            @click="currentStep -= 1"
            >{{
              $t('components.modals.add-company-group.previous-step')
            }}</base-button
          >
          <base-button
            class="mr-2"
            type="success"
            size="sm"
            v-if="validate"
            @click="userChoice(true)"
            >{{
              $t('components.modals.add-company-group.confirm')
            }}</base-button
          >
          <base-button class="mr-2" type="success" size="sm" disabled v-else>{{
            $t('components.modals.add-company-group.confirm')
          }}</base-button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SetGroupName from './SetGroupName';
import SetKnowledgeRestrictions from './SetKnowledgeRestrictions/SetKnowledgeRestrictions';
import GroupSummary from './GroupSummary';
import ModalHeader from '@/components/Modals/ModalHeader';

export default {
  name: 'add-company-group-modal',
  components: {
    SetGroupName,
    SetKnowledgeRestrictions,
    GroupSummary,
    ModalHeader,
  },
  props: {
    step: {
      type: Number,
      default: () => 1,
    },
    groups: {
      type: Array,
      default: () => ({}),
    },
    group: {
      type: Object,
      default: () => {
        return {
          name: '',
          knowledges: [],
        };
      },
    },
    formerName: {
      type: String,
      default: '',
    },
    display: Boolean,
  },
  computed: {
    registeredNames() {
      return this.groups.map((group) => group.name);
    },
    groupName() {
      return this.newGroup ? this.newGroup.name : '';
    },
    validate() {
      switch (this.currentStep) {
        case 1:
          if (this.groupName.length >= 3) {
            return (
              !this.registeredNames.includes(this.groupName) ||
              (this.formerName !== '' && this.groupName === this.formerName)
            );
          } else {
            return false;
          }
        case 2: {
          const { knowledges } = this.newGroup;
          const restrictedKnowledgesHaveAtLeastOneSelectedCase =
            knowledges.reduce(
              (acc, knowledge) =>
                acc &&
                (!knowledge.isRestricted ||
                  (knowledge.isRestricted && knowledge.restrictedNodes.length)),
              true,
            );

          return (
            knowledges.length > 0 &&
            restrictedKnowledgesHaveAtLeastOneSelectedCase
          );
        }
        default:
          return true;
      }
    },
    ...mapGetters(['isMultiLingual']),
  },
  data() {
    return {
      currentStep: this.step,
      newGroup: {
        name: '',
        knowledges: [],
      },
      localDisplay: this.display,
    };
  },
  created() {
    this.newGroup = Object.assign(this.newGroup, this.group);
  },
  methods: {
    userChoice(choice) {
      if (choice) {
        this.currentStep += 1;
      }
      this.$emit('choice', choice ? this.newGroup : null);
    },
    setGroupName(groupName) {
      this.newGroup.name = groupName;
    },
    setReachable(reachable) {
      this.newGroup.reachable = reachable;
    },
    updateRestrictions(restrictions) {
      this.newGroup.knowledges = restrictions;
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.add-company-group-modal {
  :deep() .modal-body {
    padding-bottom: 0px;
  }
}

.md-stepper-group {
  :deep() .el-step__main .el-step__description {
    padding-top: 10px;
  }
}
.nav-item {
  border: 1px solid #e9ecef;
  border-radius: 3px;
}

.vertical-divider {
  max-height: 18px;
}

.flag-icon {
  height: 18px;
  width: 18px;
}

.body-wrapper {
  max-height: 50vh;
  overflow: auto;
}

.blue-mayday {
  color: $blue-mayday;
}

.icon {
  width: 24px;
  height: 24px;
}

.modal-steps {
  border: 1px solid $blue-mayday;
  border-radius: 4px;
}
</style>
