<template>
  <div class="case-viewer row" :key="focusCollectionId">
    <div class="container">
      <div
        class="position-relative row justify-content-center mt-4"
        v-loading="collectionsViewerIsLoading"
      >
        <div
          v-if="!languageIsSet && focusKnowledgeIsMultilinguale"
          class="row justify-content-center untranslate-content"
        >
          <div class="col-lg-9 col-md-10 col-sm-12">
            <div class="content-blur"></div>
          </div>
        </div>
        <main
          v-if="focusCollection"
          class="col-md-8 col-sm-12 mt-2 pb-8 overflow"
        >
          <CaseViewerHeader
            :case-parameter="focusCollection"
            :typed-counts="typedCounts"
            :user-permissions="focusCollection.userPermissions"
            @update-case="updateCollection"
            @open-set-language-modal="openSetLanguageModal"
            @unset-language="openUnsetLanguageModal"
          />

          <bulk-contents-wrapper
            :contentsDisplayedCount="contentsDisplayedCount"
            :items="contents"
            :showSelect="!onlyCases"
            :user-permissions="focusCollection.userPermissions"
            :display-clone="!isParametric"
            @open-clone-contents-to-target-modal="
              openCloneContentsToTargetModal
            "
            @duplicate-in-same-location="duplicateInSameLocation"
            @bulk-soft-delete-contents="handleArchiveContent"
          >
            <template v-slot:header-list="{ showBulkActions }">
              <CaseViewerChildren
                v-if="isSplitKnowledgeViewer"
                :case-id="focusCollection.id"
                :user-permissions="focusCollection.userPermissions"
                :children="focusCollection.children"
                :extra-padding="showBulkActions"
                @focus-child="focusChild"
                @add-case="addCollectionChild"
                @update-case="updateCollection"
                @open-delete-modal="openDeleteModal"
                @open-clone-case-to-target-modal="openCloneCaseToTargetModal"
              />
              <SearchValues
                v-if="collectionSearchFilter"
                :resultCount="focusSearchTotal"
                :searchFilter="collectionSearchFilter"
                @reset-search="resetSearch"
              />
            </template>
            <template
              v-slot:main-list="{
                showBulkActions,
                selectedContents,
                updateSelectedContents,
              }"
            >
              <div v-if="isSplitKnowledgeViewer">
                <ContentList
                  :contents="contents"
                  :show-bulk-actions="showBulkActions"
                  :selected-contents="selectedContents"
                  @archive="handleArchiveContent"
                  @update-selected-contents="updateSelectedContents"
                  @update-content="handleUpdateContent"
                  @focus-content="focusContent"
                  @open-clone-contents-to-target-modal="
                    openCloneContentsToTargetModal
                  "
                  @duplicate-in-same-location="duplicateInSameLocation"
                  @update-content-settings="
                    updateContentSettingsAction({
                      id: $event.id,
                      payload: { ...$event },
                    })
                  "
                />
              </div>
              <div v-else>
                <not-allowed-tooltip
                  :user-permissions="focusCollection.userPermissions"
                  :disabled="true"
                  permission-key="canUpdateContent"
                  placement="top"
                >
                  <template v-slot:main-content="{ hasPermissions }">
                    <DraggableList
                      :contents="contents"
                      :focus-collection="focusCollection"
                      :focus-knowledge-value="focusKnowledgeValue"
                      :case-parameters="caseParameters"
                      :show-bulk-actions="showBulkActions"
                      :selected-contents="selectedContents"
                      :disableDraggable="
                        !hasPermissions ||
                        (!!collectionSearchFilter &&
                          !!collectionSearchFilter.length)
                      "
                      @update-selected-contents="updateSelectedContents"
                      @update-content="handleUpdateContent"
                      @update-content-verification="handleUpdateVerification"
                      @focus-content="focusContent"
                      @focus-child="focusChild"
                      @update-case="updateCollection"
                      @delete-case="openDeleteModal"
                      @open-clone-case-to-target-modal="
                        openCloneCaseToTargetModal
                      "
                      @open-clone-contents-to-target-modal="
                        openCloneContentsToTargetModal
                      "
                      @duplicate-in-same-location="duplicateInSameLocation"
                    />
                  </template>
                </not-allowed-tooltip>
              </div>
            </template>

            <template v-slot:footer-list>
              <BasePagination
                v-if="
                  isSplitKnowledgeViewer &&
                  focusCollectionContentsTotal > 0 &&
                  focusCollectionContentsPages > 1
                "
                :pageCount="focusCollectionContentsPages"
                :perPage="pageSize"
                :value="collectionSearchPage"
                :align="'center'"
                @input="changePage"
              />

              <!-- ADD ITEM BUTTON -->
              <not-allowed-tooltip
                :user-permissions="focusCollection.userPermissions"
                permission-key="canCreateContent"
                placement="top"
              >
                <template v-slot:main-content="{ hasPermissions }">
                  <div
                    class="w-100 row mx-0 add-item-into-collection-row"
                    @click="hasPermissions ? (displayAddModal = true) : ''"
                    v-if="!isSplitKnowledgeViewer"
                  >
                    <div class="col-12 d-flex align-items-center">
                      <font-awesome-icon
                        class="plus-icon"
                        :icon="['far', 'plus']"
                      />
                      <p class="col-11 mb-0">
                        {{ $t('knowledge.actions.add-element') }}
                      </p>
                    </div>
                  </div>
                </template>
              </not-allowed-tooltip>
            </template>
          </bulk-contents-wrapper>

          <AddCollectionItemModal
            v-if="displayAddModal"
            :display="displayAddModal"
            @close="displayAddModal = false"
            @add-item="addItem($event)"
            @add-item-from-template="openTemplateModal"
          />

          <ModalEvents
            modal-name="DeleteContentModal"
            :uid="deleteModalUniqueKey"
            @choice="deleteCase"
          />
          <ModalEvents
            modal-name="SoftDeleteContentModal"
            :uid="deleteModalUniqueKey"
            @choice="softDeleteCase"
          />

          <ModalEvents
            modal-name="AddTemplateModal"
            :uid="templateModalUniqueKey"
            @choice="addItemFromTemplate"
            @back="displayAddModal = true"
          />

          <ModalEvents
            modal-name="DeleteContentModal"
            :uid="unsetLanguageModalUniqueKey"
            @choice="unsetLanguage"
          />

          <ModalEvents
            modal-name="DeleteCaseBodyModal"
            :uid="removeCaseBodyUniqueKey"
            @choice="updateCollection"
          />

          <CloneKnowledgeBaseModal
            v-if="cloneToTargetModal"
            :display="cloneToTargetModal"
            :sourceType="sourceToClone.type"
            :sourceLabel="sourceToClone.label"
            :sourceId="sourceToClone.case ? sourceToClone.case.id : null"
            @close="cloneToTargetModal = false"
            @clone-to-target="handleCloneToTarget"
          />
        </main>
      </div>
    </div>
    <SetLanguageToast
      v-if="displaySetLanguageToast"
      class="toast-position"
      :language="editingLanguage"
      @open-set-language-modal="openSetLanguageModal"
    />
    <SetLanguageModal
      v-if="displaySetLanguageModal"
      :display="setLanguageModal"
      :target-language="language"
      :allow-automatic-translation="false"
      @set-new-language="setNewLanguage"
      @close="
        setLanguageModal = false;
        language = '';
      "
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { mapActions, mapState, mapGetters } from 'vuex';
import CaseViewerHeader from './CaseViewerHeader';
import BulkContentsWrapper from '@/components/ListItems/BulkContentsWrapper';
import ContentList from '@/components/ListItems/ContentList.vue';
import CaseViewerChildren from './CaseViewerChildren';
import BasePagination from '@/components/BasePagination';
import ModalEvents from '@/components/Modals/ModalEvents';
import DraggableList from '@/components/ListItems/DraggableList';
import AddCollectionItemModal from '@/components/Modals/AddCollectionItemModal';
import CloneKnowledgeBaseModal from 'components/Modals/CloneKnowledgeBaseModal/CloneKnowledgeBaseModal';
import SearchValues from '@/views/KnowledgeEditor/KnowledgeEditorMain/CaseViewer/SearchValues';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';
import SetLanguageToast from '@/components/Modals/SetLanguageToast';
import SetLanguageModal from '@/components/Modals/SetLanguageModal';
import Fuse from 'fuse.js';

export default {
  name: 'case-viewer',
  components: {
    CaseViewerHeader,
    BulkContentsWrapper,
    CaseViewerChildren,
    ContentList,
    BasePagination,
    ModalEvents,
    DraggableList,
    AddCollectionItemModal,
    CloneKnowledgeBaseModal,
    SearchValues,
    NotAllowedTooltip,
    SetLanguageToast,
    SetLanguageModal,
  },
  data() {
    return {
      preparedCaseFilters: [],
      relatedContentsCount: {},
      path: [],
      caseToDeleteId: '',
      cloneToTargetModal: false,
      sourceToClone: null,

      // PAGINATION DATA
      pages: this.focusCollectionContentsPages,
      pageSize: 20,

      // SPECIFIC TO MERGED VIEW
      displayAddModal: false,
      fuse: new Fuse([], {
        isCaseSensitive: false,
        keys: ['label'],
        threshold: 0.35,
        ignoreLocation: true,
      }),
      setLanguageModal: false,
      language: '',
      languageToUnset: '',
    };
  },
  async beforeCreate() {
    this.$emit('change-route-type', 'Case');
  },
  beforeDestroy() {
    this.updateCollectionSearch({ filter: '', page: 1 });
  },
  computed: {
    onlyCases() {
      if (this.contents.find((content) => content.__typename !== 'Case')) {
        return false;
      }
      return true;
    },
    deleteModalUniqueKey() {
      return `${this.$options.name}-delete-${this.caseToDeleteId}`;
    },
    templateModalUniqueKey() {
      return `${this.$options.name}-template-${this.focusCollection.id}`;
    },
    focusSearchTotal() {
      return this.isSplitKnowledgeViewer
        ? this.focusCollectionContentsTotal
        : this.contents.length;
    },
    contents() {
      if (this.isSplitKnowledgeViewer) {
        return this.focusCollectionContentsResults;
      }
      const tmpContents = this.getCaseOrderedChildren(this.focusCollectionId);

      if (!this.collectionSearchFilter) {
        return tmpContents;
      }

      this.fuse.setCollection([...tmpContents]);

      const result = this.fuse.search(this.collectionSearchFilter);
      return result.filter(Boolean).map(({ item }) => item);
    },

    typedCounts() {
      const initialCounts = { Article: 0, Diagnostic: 0 };

      return this.isSplitKnowledgeViewer
        ? initialCounts
        : this.contents.reduce((acc, { type }) => {
            acc[type]++;
            return acc;
          }, initialCounts);
    },

    contentsDisplayedCount() {
      return this.contents.length;
    },
    caseParameters() {
      return [this.casePath.map(({ id }) => id).join('/')];
    },
    languageIsSet() {
      if (!this.focusCollection || !this.focusCollection.setLanguages)
        return true;
      return !!this.focusCollection.setLanguages.filter(
        (lang) => lang === this.editingLanguage,
      ).length;
    },
    displaySetLanguageToast() {
      return (
        this.focusKnowledgeIsMultilinguale &&
        !this.collectionsViewerIsLoading &&
        !this.languageIsSet &&
        !this.language
      );
    },
    displaySetLanguageModal() {
      return this.focusKnowledgeIsMultilinguale && this.setLanguageModal;
    },
    unsetLanguageModalUniqueKey() {
      return `unset-case-${this.focusCollection.id}`;
    },
    removeCaseBodyUniqueKey() {
      return `remove-body-${this.focusCollection.id}`;
    },
    ...mapGetters(['isParametric']),
    ...mapState('kbStore', ['casePath']),
    ...mapGetters(['isSplitKnowledgeViewer']),
    ...mapGetters('knowledgeModule', [
      'focusCollection',
      'focusCollectionId',
      'collectionsViewerIsLoading',
      'focusKnowledgeValue',
      'focusKnowledgeId',
      'focusKnowledgeIsMultilinguale',
      'collectionSearchFilter',
      'collectionSearchPage',
      'focusCollectionContentsResults',
      'focusCollectionContentsTotal',
      'focusCollectionContentsPages',
      'getCaseOrderedChildren',
      'editingLanguage',
    ]),
  },
  methods: {
    async handleArchiveContent(payload) {
      const loadingMessage = this.$message({
        duration: 0,
        message: payload.softDeleted
          ? this.$t('knowledge.actions.delete-loader')
          : this.$t('knowledge.actions.archive-loader'),
      });
      try {
        await this.bulkArchiveContents(payload);
      } finally {
        loadingMessage.close();
      }
    },

    async duplicateInSameLocation(event) {
      if (this.isParametric) {
        return this.duplicateParametricContents(event.contents);
      }

      const clonePayload = {
        targetCaseId: this.focusCollectionId,
        targetKnowledge: this.focusKnowledgeValue,
        targetKnowledgeId: this.focusKnowledgeId,
      };

      if (event.type === 'case') {
        this.sourceToClone = { type: 'case', case: event.case };
        clonePayload.caseName = event.case.label;
      } else {
        this.sourceToClone = { type: 'content', contents: event.contents };
      }

      await this.handleCloneToTarget(clonePayload);
      await this.getFocusCollectionContents();
    },
    async duplicateParametricContents(contents) {
      if (!contents || !contents.length) return;

      const loadingMessage = this.$message({
        duration: 0,
        message: this.$t('knowledge.actions.clone-loading'),
      });

      try {
        await this.duplicateContents(contents.map((content) => content.id));
        await this.getFocusCollectionContents();

        loadingMessage.close();
        this.$message({
          message: this.$t('knowledge.actions.clone-success'),
          type: 'success',
        });
      } catch (e) {
        loadingMessage.close();
        this.$message({
          message: this.$t('knowledge.actions.clone-error'),
          type: 'error',
        });
      }
    },
    resetSearch() {
      this.updateCollectionSearch({ filter: '', page: 1 });
    },
    addItem({ type, label, templateId }) {
      switch (type) {
        case 'Article':
        case 'Diagnostic':
          this.createContent({
            knowledge: this.focusKnowledgeValue,
            type,
            label,
            parameters: [
              { key: 'knowledge', values: [this.focusKnowledgeValue] },
            ],
            caseParameters: this.caseParameters,
            accessRestrictions: [],
            labels: [],
            templateId,
          });
          break;
        default:
          this.addCollectionChild({ label, parentId: this.focusCollection.id });
          break;
      }
      this.displayAddModal = false;
    },
    async focusChild(child) {
      this.updateCasePath([...this.casePath, child]);
      await this.goToTranslatedEntity({
        entityId: child.id,
        entityType: 'Case',
        open: child.newTab === true,
      });
    },
    async openDeleteModal({ caseId, softDeleted, label } = {}) {
      this.caseToDeleteId = caseId;
      this.preparedCaseFilters = this.casePath
        .map((c) => c.id)
        .concat([caseId])
        .join('/');
      let loadingMessage = this.$message({
        duration: 0,
        dangerouslyUseHTMLString: true,
        iconClass: 'display: none',
        message: `<i class="fas fa-spinner fa-spin" style="margin-right: 1em;"></i> ${this.$t(
          'components.modals.delete-modal.loading',
        )}`,
      });
      this.relatedContentsCount =
        await this.$services.parametricContents.filterContentsCount(
          undefined,
          [],
          this.preparedCaseFilters,
          this.focusKnowledgeId,
        );
      loadingMessage.close();
      const payload = {
        component: softDeleted
          ? 'SoftDeleteContentModal'
          : 'DeleteContentModal',
        uid: this.deleteModalUniqueKey,
        props: {
          display: true,
          caseCount: this.relatedContentsCount,
          isCase: true,
          caseId: caseId,
          label,
        },
      };

      if (softDeleted) {
        payload.props = {
          ...payload.props,
          type: 'content',
          toDeleteName: 'SELECTED-COLLECTION',
        };
      }
      this.openModal(payload);
    },
    async openTemplateModal() {
      this.displayAddModal = false;
      const payload = {
        component: 'AddTemplateModal',
        uid: this.templateModalUniqueKey,
        props: {
          display: true,
          back: true,
        },
      };
      this.openModal(payload);
    },
    addItemFromTemplate(template) {
      const { type, label } = template.content;
      return this.addItem({
        type,
        label,
        templateId: template.id,
      });
    },
    async deleteCase(choice) {
      if (choice) {
        const caseId = this.caseToDeleteId;
        if (choice === 'softDeleted') {
          await this.deleteCollection({ caseId: caseId, softDeleted: true });
        } else {
          await this.deleteCollection({ caseId });
        }
        if (caseId === this.focusCollectionId) {
          this.$router.push('/knowledge');
        }
        this.caseToDeleteId = null;
      }
    },

    async softDeleteCase() {
      this.deleteCase('softDeleted');
    },
    async focusContent(content) {
      await this.goToTranslatedEntity({
        entityId: content.id,
        entityType: 'Content',
        open: content.newTab === true,
      });
    },

    handleUpdateContent(content) {
      this.updateContent(content);
    },

    async handleUpdateVerification({ id, isOutdated }) {
      await this.bulkUpdateStatus({
        contentIds: [id],
        key: 'isOutdated',
        value: isOutdated,
      });
    },
    async handleCloneToTarget(payload) {
      const loadingMessage = this.$message({
        duration: 0,
        message: this.$t('knowledge.actions.clone-loading'),
      });
      try {
        if (this.sourceToClone.type === 'case') {
          await this.cloneCaseToTarget({
            caseId: this.sourceToClone.case.id,
            ...payload,
          });
        } else {
          await this.cloneContentsToTarget({
            contentIds: this.sourceToClone.contents.map(
              (content) => content.id,
            ),
            ...payload,
          });
        }
        loadingMessage.close();
        this.$message({
          message: this.$t('knowledge.actions.clone-success'),
          type: 'success',
        });
      } catch (e) {
        loadingMessage.close();
        this.$message({
          message: this.$t('knowledge.actions.clone-error'),
          type: 'error',
        });
      }
      this.cloneToTargetModal = false;
    },
    openCloneCaseToTargetModal(collection) {
      this.sourceToClone = {
        type: 'case',
        label: collection.label,
        case: collection,
      };
      this.cloneToTargetModal = true;
    },
    openCloneContentsToTargetModal(contents) {
      this.sourceToClone = {
        type: 'contents',
        label: this.$tc(
          'knowledge.modals.clone-kb.contents-count',
          contents.length,
        ),
        contents: contents,
      };
      this.cloneToTargetModal = true;
    },
    changePage(page) {
      this.$router.push({
        path: this.$route.path,
        query: {
          'content-page': page,
        },
      });
    },
    openSetLanguageModal(lang) {
      this.setLanguageModal = true;
      this.language = lang || this.editingLanguage;
    },
    setNewLanguage(payload) {
      this.updateCollection({
        caseId: this.focusCollection.id,
        label: payload.label,
        body: '',
        childrenOrder: this.focusCollection.childrenOrder,
        lang: this.language,
      });
      this.setLanguageModal = false;
      this.language = '';
    },
    openUnsetLanguageModal(lang) {
      this.languageToUnset = lang;
      this.openModal({
        component: 'DeleteContentModal',
        uid: this.unsetLanguageModalUniqueKey,
        props: { display: true, lang, deleteType: 'language' },
      });
    },
    unsetLanguage(modalResp) {
      if (!modalResp) return;
      this.unsetCaseLanguage({
        caseId: this.focusCollection.id,
        lang: this.languageToUnset,
      });
    },
    ...mapActions('kbStore', ['updateCasePath']),
    ...mapActions('knowledgeModule', [
      'createContent',
      'addCollectionChild',
      'updateCollection',
      'updateContent',
      'deleteCollection',
      'cloneCaseToTarget',
      'cloneContentsToTarget',
      'duplicateContents',
      'updateCollectionSearch',
      'bulkUpdateStatus',
      'bulkArchiveContents',
      'unsetCaseLanguage',
      'getFocusCollectionContents',
      'goToTranslatedEntity',
      'updateContentSettingsAction',
    ]),
    ...mapActions('modalsModule', ['openModal']),
  },
  watch: {
    $route(to) {
      if (to.query['content-page']) {
        const page = parseInt(this.$route.query['content-page'], 10);
        this.updateCollectionSearch({ page });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-item-into-collection-row {
  color: $grey-5-mayday;
  border: 1px solid;
  border-color: transparent;
  border-radius: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  transition: all 0.3s;
  &:hover {
    background-color: $grey-2-mayday;
  }
  .plus-icon {
    border: 1px solid transparent;
    padding: 1px;
    width: 15px;
    height: 15px;
  }
}

.case-viewer {
  height: calc(100vh - 60px);
  overflow: auto;
  max-width: 100%;
}

.untranslate-content {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
}

.content-blur {
  width: 100%;
  height: 100%;
  background: rgba(246, 249, 252, 0.5);
}

.toast-position {
  top: 45px;
  right: 12px;
}

.overflow {
  overflow: initial !important;
}
</style>
