var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('el-tabs',{on:{"tab-click":_vm.handleTabClick},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[(_vm.features.includes('general'))?_c('el-tab-pane',{staticClass:"settings-panel",attrs:{"label":_vm.$t(
        'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.tabs.general',
      ),"name":"1"}},[(_vm.generalTabComponent)?_c(_vm.generalTabComponent,{tag:"component",attrs:{"currentPluginEntities":_vm.currentPluginEntities,"config":_vm.config,"errors":_vm.errors},on:{"update-plugin":function($event){return _vm.$emit('update-plugin', $event)},"add-plugin":function($event){return _vm.$emit('add-plugin', $event)},"delete-plugin":function($event){return _vm.$emit('delete-plugin', $event)}}}):_c('PluginAdvancedSettingsOptions',{attrs:{"currentPlugin":_vm.currentPluginEntities[0],"index":0,"errors":_vm.errors.length ? _vm.errors[0] : {},"config":_vm.config},on:{"update-plugin":function($event){return _vm.$emit('update-plugin', { index: 0, ...$event })}}})],1):_vm._e(),(_vm.features.includes('activation'))?_c('el-tab-pane',{staticClass:"settings-panel",attrs:{"label":_vm.$t(
        'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.tabs.activation',
      ),"name":"3"}},_vm._l((_vm.integrations),function(integration){return _c('div',{key:integration.name,staticClass:"item d-flex justify-between p-3"},[_c('PluginActivationSettings',{attrs:{"integration":integration,"pluginTranslationKey":_vm.pluginTranslationKey,"isPreferenceActive":_vm.isPreferenceActive}})],1)}),0):_vm._e(),(_vm.features.includes('access'))?_c('el-tab-pane',{staticClass:"settings-panel",attrs:{"label":_vm.$t(
        'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.tabs.access',
      ),"name":"4"}},[(this.pluginType === 'SmartActionEntity')?_c('SmartActionAccessSettings',{attrs:{"currentPluginEntities":_vm.currentPluginEntities},on:{"update-access-restriction":function($event){return _vm.$emit('update-access-restriction', $event)}}}):_c('PluginAccessSettings',{attrs:{"currentPlugin":_vm.currentPluginEntities[0]},on:{"update-access-restriction":function($event){return _vm.$emit('update-access-restriction', $event)}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }