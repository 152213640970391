<template>
  <div class="content-label-review">
    <p>
      <small>
        <font-awesome-icon
          :icon="['fad', 'check-circle']"
          class="content-view-setting-review__icon"
        />
        {{ $t('knowledge.modals.content-view-setting.review.content') }}
        <strong
          class="mr-1"
        >{{ contentLabel }}</strong>
        <ContentItemLabel v-for="label in labels" :key="label.id" :label="label" />
        {{ $t('knowledge.modals.content-view-setting.review.pinned') }}
      </small>
    </p>
  </div>
</template>

<script>
import ContentItemLabel from '@/components/Commons/ContentItemLabel';

export default {
  name: 'content-label-review',
  components: { ContentItemLabel },
  props: {
    contentLabel: String,
    labels: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>
</style>