<template>
  <p v-if="token.type === 'paragraph'">
    <AskMarkdownTokenRenderer
      @md-event="$emit('md-event', $event)"
      :hovered="hovered"
      v-for="(child, i) in token.tokens"
      :key="i"
      :token="child"
      :contexts="contexts"
      :links="links"
    >
    </AskMarkdownTokenRenderer>
  </p>
  <span
    v-else-if="token.type === 'text' && token.tokens && token.tokens.length > 0"
  >
    <AskMarkdownTokenRenderer
      @md-event="$emit('md-event', $event)"
      :hovered="hovered"
      v-for="(child, i) in token.tokens"
      :key="i"
      :token="child"
      :contexts="contexts"
      :links="links"
    >
    </AskMarkdownTokenRenderer>
  </span>
  <span v-else-if="token.type === 'text'">
    {{ token.raw }}
  </span>
  <br
    v-else-if="
      (token.type === 'space' && isMultipleNewLines) || token.type === 'br'
    "
  />
  <a
    v-else-if="token.type === 'link' && isExternalLink"
    :href="token.href"
    target="_blank"
    >{{ token.text }}
  </a>
  <!-- mailto: emails are incorrectly parsed by marked and lead to bad text (with &#...;) -->
  <a
    v-else-if="token.type === 'link' && token.href.startsWith('mailto:')"
    :href="`mailto:${token.raw}`"
    >{{ token.raw }}
  </a>
  <el-tooltip
    v-else-if="token.type === 'link' && token.text.startsWith('quote://')"
    effect="light"
    :disabled="contextToolTipDisabled(token.href)"
  >
    <div slot="content" class="label-tooltip">
      <div v-if="isOutdated(token.href)" class="outdated-warning">
        {{ $t('ask.document-outdated') }}
      </div>
      <div>{{ contexts[token.href] }}</div>
    </div>
    <span
      :class="[
        'link-quote',
        { hovered: isHovered },
        { outdated: isOutdated(token.href) },
      ]"
      @mouseover="emitLinkMouseOver"
      @mouseleave="emitLinkMouseLeave"
      @click="goToDocument(token.href)"
    >
      {{ token.text.slice(8) }}
    </span>
  </el-tooltip>
  <el-tooltip
    v-else-if="token.type === 'link'"
    effect="light"
    :disabled="contextToolTipDisabled(token.href)"
  >
    <div slot="content" class="label-tooltip">
      <div v-if="isOutdated(token.href)" class="outdated-warning">
        {{ $t('ask.document-outdated') }}
      </div>
      <div>{{ contexts[token.href] }}</div>
    </div>
    <div
      :class="[
        'link',
        { hovered: isHovered },
        { outdated: isOutdated(token.href) },
      ]"
      @mouseover="emitLinkMouseOver"
      @mouseleave="emitLinkMouseLeave"
      @click="goToDocument(token.href)"
    >
      {{ token.text }}
    </div>
  </el-tooltip>

  <ul v-else-if="token.type === 'list' && token.ordered === false">
    <AskMarkdownTokenRenderer
      @md-event="$emit('md-event', $event)"
      :hovered="hovered"
      v-for="(child, i) in token.items"
      :key="i"
      :token="child"
      :contexts="contexts"
      :links="links"
    >
    </AskMarkdownTokenRenderer>
  </ul>
  <ol v-else-if="token.type === 'list' && token.ordered">
    <AskMarkdownTokenRenderer
      @md-event="$emit('md-event', $event)"
      :hovered="hovered"
      v-for="(child, i) in token.items"
      :key="i"
      :token="child"
      :contexts="contexts"
      :links="links"
    >
    </AskMarkdownTokenRenderer>
  </ol>
  <li v-else-if="token.type === 'list_item'">
    <AskMarkdownTokenRenderer
      @md-event="$emit('md-event', $event)"
      :hovered="hovered"
      v-for="(child, i) in token.tokens"
      :key="i"
      :token="child"
      :contexts="contexts"
      :links="links"
    >
    </AskMarkdownTokenRenderer>
  </li>
  <h1 v-else-if="token.type === 'heading' && token.depth === 1">
    <AskMarkdownTokenRenderer
      @md-event="$emit('md-event', $event)"
      :hovered="hovered"
      v-for="(child, i) in token.tokens"
      :key="i"
      :token="child"
      :contexts="contexts"
      :links="links"
    >
    </AskMarkdownTokenRenderer>
  </h1>
  <h2 v-else-if="token.type === 'heading' && token.depth === 2">
    <AskMarkdownTokenRenderer
      @md-event="$emit('md-event', $event)"
      :hovered="hovered"
      v-for="(child, i) in token.tokens"
      :key="i"
      :token="child"
    >
    </AskMarkdownTokenRenderer>
  </h2>
  <h3 v-else-if="token.type === 'heading' && token.depth === 3">
    <AskMarkdownTokenRenderer
      @md-event="$emit('md-event', $event)"
      :hovered="hovered"
      v-for="(child, i) in token.tokens"
      :key="i"
      :token="child"
      :contexts="contexts"
      :links="links"
    >
    </AskMarkdownTokenRenderer>
  </h3>
  <strong v-else-if="token.type === 'strong'">
    <AskMarkdownTokenRenderer
      @md-event="$emit('md-event', $event)"
      :hovered="hovered"
      v-for="(child, i) in token.tokens"
      :key="i"
      :token="child"
      :contexts="contexts"
      :links="links"
    >
    </AskMarkdownTokenRenderer>
  </strong>
  <!-- <span v-else>
    {{ JSON.stringify(token) }}
  </span> -->
</template>

<script>
export default {
  name: 'AskMarkdownTokenRenderer',
  props: {
    token: {
      type: Object,
    },
    hovered: {
      type: String,
      default: '',
    },
    contexts: {
      type: Object,
      default: () => ({}),
    },
    links: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    hoveredLink: null,
  }),
  computed: {
    isHovered() {
      return this.hovered === this.token.href;
    },
    isExternalLink() {
      return (
        this.token.text.startsWith('https://') ||
        this.token.href.startsWith('https://') ||
        this.token.text.startsWith('http://') ||
        this.token.href.startsWith('http://')
      );
    },
    isMultipleNewLines() {
      const matches = this.token.raw.match(/\n+/g).length;
      return matches >= 1;
    },
  },
  methods: {
    goToDocument() {
      this.$emit('md-event', { id: this.token.href, type: 'go-to-document' });
    },
    emitLinkMouseOver() {
      this.$emit('md-event', { id: this.token.href, type: 'hover' });
    },
    emitLinkMouseLeave() {
      this.$emit('md-event', { id: this.token.href, type: 'leave' });
    },
    contextToolTipDisabled(href) {
      !this.contexts || !this.contexts[href] || this.contexts[href] === '[]';
    },
    isOutdated(id) {
      const document = this.links.find((d) => d.id === id);
      return document && document.isOutdated;
    },
  },
};
</script>

<style scoped lang="scss">
.link {
  color: $purple-5-mayday;
  background-color: $purple-3-mayday;
  font-size: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &.hovered,
  &:hover {
    background-color: $purple-5-mayday;
    color: white;
  }
}
.link-quote {
  color: $purple-5-mayday;
  background-color: $purple-3-mayday;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  &.hovered,
  &:hover {
    background-color: $purple-5-mayday;
    color: white;
  }
}
.label-tooltip {
  max-width: 300px;
}
.outdated-warning {
  color: $red-5-mayday;
}
</style>
