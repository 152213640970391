<template>
  <div>
    <div class="parameter-row col-12 px-0 my-1">
      <label class="group-selector__label">
        <ParameterTitle
          :type="paramType"
          :key-type="keyType"
          :field-type="fieldType"
          :disabled-tags="'knowledge'"
          :icon="hierarchy.icon"
          :count="parameters.length"
          :enriched-chosen-params="enrichedChosenParams"
          @update-chosen-params="chosenParams = $event"
          class="pl-3"
        />
      </label>
      <el-select
        v-if="fieldType !== 'remote-search'"
        v-model="chosenParams"
        :multiple="keyType !== 'knowledge'"
        filterable
        collapse-tags
        :placeholder="$t('knowledge.modals.notifications.groups.placeholder')"
        style="width: 100%"
      >
        <el-option
          v-for="({ label, value }, idx) in paramValues"
          :key="`${paramTypeLowercase}-${idx}-${label}`"
          :label="label"
          :value="value"
        ></el-option>
      </el-select>
      <el-select
        v-else
        v-model="chosenParams"
        :multiple="keyType !== 'knowledge'"
        collapse-tags
        remote
        filterable
        :placeholder="
          $t(
            'knowledge.drawers.content-view-setting.settings.parameters.placeholder',
          )
        "
        style="width: 100%"
        :remote-method="remoteMethod"
        :loading="loading"
      >
        <el-option
          v-for="({ label, value }, idx) in remoteOptions"
          :key="`${paramTypeLowercase}-${idx}-${label}`"
          :label="label"
          :value="value"
        ></el-option>
      </el-select>
    </div>
    <div>
      <div v-for="(o, idx) in optionsType" :key="o.id">
        <select-parameter
          v-if="
            paramsChildren.length ||
            hierarchy.options[idx].field === 'remote-search'
          "
          :parameters="paramsChildren"
          :hierarchy="hierarchy.options[idx]"
          :update-filter="updateFilter"
          :filters="filters"
          :reset="resetParams"
          @reset-over="resetParams = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ParameterTitle from './ParameterTitle';

export default {
  name: 'select-parameter',
  components: { ParameterTitle },
  props: {
    parameters: Array,
    hierarchy: Object,
    updateFilter: Function,
    filters: Array,
    reset: Boolean,
    showAll: Boolean,
  },
  computed: {
    chosenParams: {
      get() {
        const keyValues = this.filters.find((f) => f.key === this.keyType);
        if (this.fieldType === 'checkbox') {
          if (keyValues) return keyValues.values[0];
          return '';
        }
        if (keyValues) return [...keyValues.values];
        return [];
      },
      set(val) {
        this.resetParams = true;
        if (this.keyType === 'knowledge') {
          this.$emit(
            'update-knowledge',
            this.fieldType === 'checkbox' ? [val] : val,
          );
        }
        this.updateFilter({
          key: this.keyType,
          values: this.fieldType === 'checkbox' ? [val] : val,
        });
      },
    },
    enrichedChosenParams() {
      const loc_params =
        typeof this.chosenParams === 'string'
          ? [this.chosenParams]
          : this.chosenParams;
      if (this.chosenParams.length > 0) {
        if (this.fieldType === 'remote-search') {
          return loc_params.map((param) => ({
            label: param,
            value: param,
          }));
        }
        return loc_params.map((param) => ({
          label: this.paramValues.find((p) => p.value === param).label,
          value: param,
        }));
      }
      return typeof this.chosenParams === 'string' ? '' : [];
    },
    paramType() {
      return this.hierarchy.label;
    },
    keyType() {
      return this.hierarchy.value;
    },
    fieldType() {
      return this.hierarchy.field;
    },
    optionsType() {
      return this.hierarchy.options;
    },
    paramTypeLowercase() {
      return this.paramType.toLowerCase().split(' ').join('-');
    },
    paramValues() {
      return this.parameters.map((p) => ({
        label: p.label,
        value: p.value,
      }));
    },
  },
  async mounted() {
    if (this.filters) {
      const keyValues = this.filters.find((f) => f.key === this.keyType);
      if (keyValues) {
        const paramsChildren =
          await this.$services.hierarchies.getAggregatedOptions(
            this.chosenParams,
          );
        this.paramsChildren = paramsChildren.sort((a, b) => {
          return String(a.label).localeCompare(String(b.label));
        });
      }
    }
  },
  data() {
    return {
      activeNames: [],
      resetParams: false,
      paramsChildren: [],
      loading: false,
      remoteOptions: [],
    };
  },
  methods: {
    async remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        let results = [];
        if (this.keyType === 'facets') {
          results = await this.$services.products.regexProductFacets(query);
        } else {
          results = await this.$services.products.regexProductParameters(
            this.hierarchy.fullPath,
            query,
            this.filters.filter((f) => f.key !== 'knowledge'),
          );
        }
        this.remoteOptions = results.map((r) => ({
          label: r,
          value: r,
        }));
      } else {
        this.remoteOptions = [];
      }
      this.loading = false;
    },
  },
  watch: {
    reset() {
      this.chosenParams = [];
      this.$emit('reset-over');
    },
    async enrichedChosenParams() {
      if (this.enrichedChosenParams.length) {
        const paramsChildren =
          await this.$services.hierarchies.getAggregatedOptions(
            this.chosenParams,
          );
        this.paramsChildren = paramsChildren.sort((a, b) => {
          return String(a.label).localeCompare(String(b.label));
        });
      } else {
        this.paramsChildren = [];
      }
    },
  },
  beforeDestroy() {
    this.chosenParams = [];
    this.updateFilter({
      key: this.keyType,
      values: this.chosenParams,
    });
  },
};
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0px;
}
.parameter-row {
  padding-right: 0px;

  :deep() .el-select__input {
    margin-left: 0px;
  }
}
</style>
