<template>
  <div class="thread__author">
    <ThreadAvatar :username="initials" :is-deleted="author.isDeleted" />
    <div class="thread__author-text">
      <div
        class="thread__author-username"
        :class="{ 'thread__author-deleted': author.isDeleted }"
      >
        {{ username }}
      </div>
      <TimeAgo class="thread__author-date" :date="date" />
      <span v-if="edited" class="thread__author-date">
        {{ $t('threads.messages.edited') }}
      </span>
    </div>
  </div>
</template>

<script>
import TimeAgo from '@/components/Commons/TimeAgo';
import ThreadAvatar from './ThreadAvatar';

export default {
  name: 'thread-author',
  components: {
    ThreadAvatar,
    TimeAgo,
  },
  props: {
    author: {
      type: Object,
      default: () => ({
        username: null,
        isDeleted: true,
      }),
    },
    date: {
      type: String,
      required: true,
    },
    edited: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    username() {
      const { isDeleted, username } = this.author;
      if (isDeleted && username)
        return `${username} ${this.$t('threads.messages.deleted-user')}`;
      return username || this.$t('knowledge.contributors.deleted-user');
    },
    initials() {
      return this.author.username || '?';
    },
  },
};
</script>

<style lang="scss" scoped>
.thread__author {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 20px;

  &-text {
    display: flex;
    align-items: baseline;
    gap: 4px;
  }
  &-username {
    font-size: 14px;
    color: $grey-8-mayday;
    font-weight: 700;
  }
  &-deleted {
    color: $grey-5-mayday;
  }
  &-date {
    font-size: 10px;
    color: $grey-6-mayday;
  }
}
</style>
