<template>
  <el-tooltip
    :content="$t('settings.new-knowledge.choose-type.disabled')"
    :disabled="!disabled"
    placement="top"
  >
    <div
      class="option-card-wrapper"
      :class="{ 'option-card-wrapper-disabled': disabled }"
    >
      <div
        :class="[
          'option-card text-center',
          { chosen: isChosen },
          { 'option-card-disabled': disabled },
        ]"
        @click="$emit('click')"
      >
        <div class="title">
          {{ title }}
        </div>
        <div class="picture"><slot name="picture"></slot></div>
        <div class="description">{{ description }}</div>

        <div>
          <el-divider class="divider mx-auto mb-0 mt-0"></el-divider>
          <div class="icon-wrapper mx-auto my-3">
            <font-awesome-icon
              :icon="[icon.type, icon.value]"
              :style="`{color: ${icon.color}}`"
              :class="['icon', { chosenIcon: isChosen }]"
            ></font-awesome-icon>
          </div>
          <div class="warning">{{ warning }}</div>
        </div>
        <div class="radio"><slot name="radio"></slot></div>
      </div>
    </div>
  </el-tooltip>
</template>

<script>
export default {
  name: 'option-card',
  data() {
    return {
      radio: '',
    };
  },
  props: {
    icon: {
      type: Object,
      default: () => {
        return {
          value: 'tag',
          type: 'fal',
          color: '#ffffff',
        };
      },
    },
    isChosen: {
      type: Boolean,
    },
    title: String,
    description: String,
    warning: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.option-card {
  width: 278px;
  min-height: 466px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 19px;
  border: 1px solid $grey-4-mayday;
  border-radius: 8px;
  transition: border 250ms ease-in-out;
  &:hover {
    border: 1px solid $blue-mayday;
    cursor: pointer;
  }
  &-wrapper {
    display: flex;
    align-items: center;
    min-height: 466px;
    &-disabled {
      cursor: not-allowed;
      &:hover .option-card {
        border: 1px solid $grey-7-mayday;
      }
    }
  }
  &-disabled {
    pointer-events: none;
  }
}

.chosen {
  border: 1px solid $blue-mayday;
  background: rgba($blue-mayday, 0.1);
}

.chosenIcon {
  color: $blue-mayday;
}

.title {
  font-size: 24px;
  font-weight: 700;
}
.warning {
  color: $grey-7-mayday;
  font-size: 12px;
}

.divider {
  width: 50%;
}

.icon-wrapper {
  width: 22px;
  height: 22px;
  padding: 4px;
  background-color: $grey-2-mayday;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: $grey-7-mayday;
}

.icon {
  height: 100%;
  width: 100%;
}

.picture {
  height: 81px;
}

:deep() .el-radio__label {
  display: none;
}
</style>
