<template>
  <div>
    <div class="mb-2 text-sm">
      {{
        $t(
          'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.access.description',
        )
      }}
    </div>
    <plugin-access-settings-drawer
      :title="
        $t(
          'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.access.action',
        )
      "
      :accessRestrictionsIds="currentPlugin.accessRestrictions"
      @update-access-restriction="handleUpdateAccessRestriction"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import PluginAccessSettingsDrawer from './PluginAccessSettingsDrawer.vue';

export default defineComponent({
  name: 'plugin-access-settings',
  components: { PluginAccessSettingsDrawer },
  props: {
    currentPlugin: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleUpdateAccessRestriction(roleIds) {
      const payload = {
        id: this.currentPlugin.id,
        roleIds,
      };
      this.$emit('update-access-restriction', payload);
    },
  },
});
</script>
