<template>
  <div v-if="blur" class="row justify-content-center untranslate-content">
    <div class="col-lg-9 col-md-10 col-sm-12">
      <div class="content-blur"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'translation-blur',
  props: {
    blur: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.untranslate-content {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
}

.content-blur {
  width: 100%;
  height: 100%;
  background: rgba(246, 249, 252, 0.5);
}
</style>
