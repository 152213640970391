<template>
  <el-drawer
    class="role-drawer"
    :visible="show"
    :with-header="false"
    size="50%"
  >
    <RolesDrawerHeader
      :role-name="role.name"
      :edit="!role.id"
      @update-focused-role="handleUpdate"
    />
    <div v-if="!showCancel" class="container p-0">
      <RolesDrawerSide
        class="side"
        :role-permissions="role.customRolePermissions"
        :permission-items="permissionItems"
        :company-preferences="companyPreferences"
      />
      <RolesDrawerMain
        class="main"
        :role-permissions="role.customRolePermissions"
        :permission-items="permissionItems"
        :errors="errors"
        :company-preferences="companyPreferences"
        @update-focused-role="handleUpdate"
      />
    </div>
    <div v-else class="container p-0">
      <RolesDrawerCancelConfirm
        class="cancel-confirm"
        @cancel="showCancel = false"
        @confirm="$emit('cancel')"
      />
    </div>
    <RolesDrawerFooter
      class="drawer-footer"
      :hide-buttons="showCancel"
      @cancel="handleCancel"
      @save="handleSave"
    />
  </el-drawer>
</template>

<script>
import { mapState } from 'vuex';
import RolesDrawerHeader from './RolesDrawerHeader';
import RolesDrawerSide from './RolesDrawerSide';
import RolesDrawerMain from './RolesDrawerMain';
import RolesDrawerFooter from './RolesDrawerFooter';
import RolesDrawerCancelConfirm from './RolesDrawerCancelConfirm';
import { PERMISSION_ITEMS } from './utils';

export default {
  name: 'roles-drawer',
  components: {
    RolesDrawerHeader,
    RolesDrawerSide,
    RolesDrawerMain,
    RolesDrawerFooter,
    RolesDrawerCancelConfirm,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    role: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      permissionItems: PERMISSION_ITEMS,
      isConfirmedClose: false,
      showCancel: false,
      errors: {},
    };
  },
  computed: {
    // Returns an array of object {errorPath: string, relatedPermissions: string[]}
    // errorPath is a string delimited with . which will be used in the errors object
    errorFormattedPermissionItems() {
      return this.permissionItems.reduce((acc, permissionItem) => {
        if (!permissionItem.hasErrorState) return acc;

        const permissionsItemsWithErrorState = permissionItem.subItems.reduce(
          (acc2, subItem) => {
            if (!subItem.error) return acc2;
            acc.push({
              errorPath: `${permissionItem.name}.${subItem.name}`,
              relatedPermissions: subItem.relatedPermissions,
            });
            return acc2;
          },
          [],
        );

        return [...acc, ...permissionsItemsWithErrorState];
      }, []);
    },
    hasErrors() {
      return Object.keys(this.errors).length;
    },
    ...mapState(['companyPreferences']),
  },
  methods: {
    handleCancel() {
      if (this.role.hasBeenEdited) this.showCancel = true;
      else this.$emit('cancel');
    },
    setField(obj, path, val) {
      const keys = path.split('.');
      const lastKey = keys.pop();
      const lastObj = keys.reduce(
        (obj, key) => (obj[key] = obj[key] || {}),
        obj,
      );
      lastObj[lastKey] = val;
      this.errors = { ...obj };
    },
    isScopeEmpty(scope) {
      return !scope || !Object.keys(scope).length;
    },
    hasInvalidPermissions(permissionsToCheck) {
      return permissionsToCheck.some((permission) => {
        if (permission.isScopeToggled === undefined) return false;

        if (!permission.isScopeToggled && this.isScopeEmpty(permission.scope)) {
          return false;
        }

        return permission.isScopeToggled && this.isScopeEmpty(permission.scope);
      });
    },
    checkErrors() {
      this.errors = {};
      this.errorFormattedPermissionItems.forEach((errorPermissionItem) => {
        const permissionsToCheck = this.role.customRolePermissions.filter(
          (permissionToSave) =>
            errorPermissionItem.relatedPermissions.includes(
              permissionToSave.rolePermission,
            ),
        );
        if (this.hasInvalidPermissions(permissionsToCheck)) {
          this.setField(this.errors, errorPermissionItem.errorPath, true);
        }
      });
    },
    handleUpdate(e) {
      this.$emit('update-focused-role', e);
      if (this.hasErrors) this.checkErrors();
    },
    handleSave() {
      this.checkErrors();
      if (!this.hasErrors) this.$emit('save');
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-confirm {
  margin: auto;
}
.body-container {
  overflow: auto;
}
.container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 190px);
  min-height: 450px;
}
.side {
  width: 250px;
}
.main {
  flex: 0 0 auto;
  padding-right: 30px;
  width: calc(100% - 250px);
  overflow: auto;
}

.drawer-footer {
  height: 60px;
  padding: 20px;
}
</style>
