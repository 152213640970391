<template>
  <div class="knowledge-manager-section">
    <div
      :class="[
        'knowledge-manager-section__empty',
        {
          expanded: isEmpty,
        },
      ]"
    >
      <div class="knowledge-manager-section__empty-text">
        <div class="knowledge-manager-section__empty-title">
          {{ $t('knowledge.ai-drawer.knowledge-manager.title') }}
          <AiBadge size="medium" />
          <el-tooltip
            :content="$t('knowledge.ai-drawer.knowledge-manager.tooltip')"
            placement="top"
          >
            <font-awesome-icon
              :icon="['fal', 'info-circle']"
              class="knowledge-manager-section__infos"
            />
          </el-tooltip>
        </div>
        <div class="knowledge-manager-section__empty-description">
          {{ $t('knowledge.ai-drawer.knowledge-manager.description') }}
        </div>
      </div>
      <button
        @click="$emit('generate-knowledge-manager')"
        class="btn-primary btn-sm btn"
      >
        <font-awesome-icon :icon="['fal', 'bolt']" />
        {{ $t('knowledge.ai-drawer.generate') }}
      </button>
    </div>
    <div
      :class="[
        'knowledge-manager-section__body',
        { loading: generatingAnimation },
        {
          expanded: kmRecommendations && Object.keys(kmRecommendations).length,
        },
        { editing: editing },
      ]"
    >
      <div v-if="generatingAnimation" class="knowledge-manager-section__loader">
        <font-awesome-icon
          v-if="generatingAnimation"
          :icon="['fal', 'spinner-third']"
          spin
        />
        <span v-if="generatingAnimation">{{
          $t(`knowledge.ai-drawer.generating`)
        }}</span>
      </div>
      <LoadingBar v-if="generating" />
      <LoadingBar v-if="generating" />
      <LoadingBar v-if="generating" :width="50" />
      <div
        v-if="deletingAnimation"
        class="knowledge-manager-section__deleting-container"
      >
        <div v-if="deleting">
          <font-awesome-icon :icon="['fal', 'spinner-third']" spin />
          <span> {{ $t(`knowledge.ai-drawer.deleting.title`) }}</span>
          <button class="btn btn-sm" @click="$emit('cancel')">
            {{ $t(`knowledge.ai-drawer.cancel`) }}
          </button>
        </div>
        <div v-else-if="!processInterrupted">
          <font-awesome-icon :icon="['fal', 'check']" />
          <span> {{ $t(`knowledge.ai-drawer.deleting.done`) }}</span>
        </div>
      </div>
      <div v-else-if="!generating" class="knowledge-manager-section__content">
        <div
          v-if="!generatingAnimation"
          class="knowledge-manager-section__header"
        >
          <div class="knowledge-manager-section__actions">
            <el-tooltip
              placement="top"
              :content="$t('knowledge.ai-drawer.knowledge-manager.expired')"
            >
              <div :class="['knowledge-manager-section__date']">
                <font-awesome-icon :icon="['fal', 'info-circle']" />
                <span
                  >{{
                    $t(
                      `knowledge.ai-drawer.${
                        manualEdit ? 'edited-at' : 'generated-at'
                      }`,
                    )
                  }}
                  {{ updatedAt }}</span
                >
              </div>
            </el-tooltip>
            <base-dropdown direction="down">
              <div slot="title" class="knowledge-manager-section__button">
                <font-awesome-icon :icon="['fal', 'ellipsis-h']" />
              </div>
              <div
                :class="['dropdown-item', { disabled: generating }]"
                @click="$emit('generate-knowledge-manager')"
              >
                <font-awesome-icon
                  :icon="['fal', 'undo']"
                  class="dropdown-item__icon"
                />
                <span>{{ $t(`knowledge.ai-drawer.popover.regenerate`) }}</span>
              </div>
            </base-dropdown>
          </div>
        </div>
        <div class="knowledge-manager-section__actions-container">
          <div
            v-if="
              !kmRecommendations.form || (
              !kmRecommendations.form.length &&
              !kmRecommendations.content.length)
            "
          >
            {{ $t('knowledge.ai-drawer.knowledge-manager.no-recommendation') }}
          </div>
          <div v-else>
            <span>{{ $t('knowledge.ai-drawer.knowledge-manager.form') }}</span>
            <ul v-if="kmRecommendations.form">
              <li :key="`form-${i}`" v-for="(rec, i) in kmRecommendations.form">
                {{ rec }}
              </li>
            </ul>
            <span>{{
              $t('knowledge.ai-drawer.knowledge-manager.content')
            }}</span>
            <ul v-if="kmRecommendations.content">
              <li
                :key="`content-${i}`"
                v-for="(rec, i) in kmRecommendations.content"
              >
                {{ rec }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingBar from 'components/AI/LoadingBar.vue';
import AiBadge from 'components/AI/AiBadge.vue';

export default {
  name: 'KnowledgeManager',
  components: { AiBadge, LoadingBar },
  props: {
    generating: {
      type: Boolean,
      default: true,
    },
    deleting: {
      type: Boolean,
      default: false,
    },
    processInterrupted: {
      type: Boolean,
      default: false,
    },
    kmRecommendations: {
      type: Object,
      default: () => {},
    },
    lastUpdate: {
      type: String,
      default: '',
    },
    docLastUpdate: {
      type: String,
      default: '',
    },
    manualEdit: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    generatingAnimation: false,
    deletingAnimation: false,
    editing: false,
    visibility: false,
    currentRecommendations: {},
  }),
  created() {
    this.currentRecommendations = this.kmRecommendations;
    this.visibility = this.isVisible;
  },
  computed: {
    updatedAt() {
      if (!this.lastUpdate) return null;
      const date = new Date(parseInt(this.lastUpdate));
      return `${date.getDate() < 10 ? 0 : ''}${date.getDate()}/${
        date.getMonth() < 9 ? 0 : ''
      }${date.getMonth() + 1}/${date.getFullYear()}`;
    },
    isEmpty() {
      return (
        (!this.kmRecommendations || !this.lastUpdate) &&
        !this.generating &&
        !this.generatingAnimation
      );
    },
    isExpired() {
      return false;
      //We keep this code commented for now, we first need to define the expiration time
      /*if (!this.docLastUpdate) return false;
      return this.docLastUpdate - 3000 > this.lastUpdate;*/
    },
  },
  methods: {
    handleCancel() {
      this.editing = false;
      this.currentActionItems = this.kmRecommendations;
      this.$refs.actionsEditor.editor.commands.setContent(
        this.parseKMRecommendations,
      );
    },
  },
  watch: {
    deleting() {
      if (this.deleting) {
        this.generatingAnimation = false;
        this.deletingAnimation = true;
      } else if (!this.processInterrupted) {
        setTimeout(() => {
          this.deletingAnimation = false;
        }, 1000);
      } else {
        this.deletingAnimation = false;
      }
    },
    generating: {
      handler(newVal, oldVal) {
        if (oldVal && !newVal) {
          if (this.lastUpdate !== this.docLastUpdate)
            setTimeout(() => {
              this.generatingAnimation = false;
            }, 1000);
          else {
            this.generatingAnimation = false;
          }
        } else if (newVal) {
          this.generatingAnimation = true;
        }
      },
      immediate: true,
    },
    recommendations() {
      this.currentRecommendations = this.recommendations;
    },
  },
};
</script>

<style scoped lang="scss">
.knowledge-manager-section__empty {
  height: 0;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $grey-1-mayday;
  text-align: center;
  border-radius: 4px;

  &.expanded {
    height: 188px;
    gap: 8px;
    padding: 12px 18px;
  }
  .btn {
    background-color: $purple-5-mayday;
    border-color: $purple-5-mayday;
    svg {
      margin-right: 4px;
    }
  }

  .knowledge-manager-section__empty-title {
    font-size: 16px;
    font-weight: 700;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
  }

  .knowledge-manager-section__empty-description {
    font-size: 14px;
    font-weight: 400;
    color: $grey-6-mayday;
  }

  .ai-drawer__badge-container {
    margin-top: -4px;
  }

  .knowledge-manager-section__infos {
    font-size: 12px;
    color: $grey-6-mayday;
  }
}

.knowledge-manager-section__body {
  background-color: $grey-1-mayday;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 0;
  overflow: hidden;
  font-size: 14px;

  &.loading {
    padding: 8px;
    height: 188px;
  }

  &.expanded {
    padding: 12px 18px;
    height: 188px;
  }

  &.editing {
    padding: 12px 18px;
    height: 188px;
  }

  .knowledge-manager-section__loader {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    color: $purple-5-mayday;
    font-size: 16px;
  }

  .knowledge-manager-section__deleting-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 100%;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      svg {
        font-size: 24px;
        color: $red-mayday;
      }
      span {
        font-size: 16px;
        color: $grey-6-mayday;
        font-weight: 700;
      }
    }
  }
}

.knowledge-manager-section__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  .knowledge-manager-section__label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    color: $purple-5-mayday;
    font-size: 16px;

    .knowledge-manager-section__switch {
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 12px;
      color: $grey-6-mayday;
      font-weight: 500;

      :deep() .el-switch__core {
        height: 16px;
        width: 28px !important;
      }

      :deep() .el-switch__core:after {
        width: 12px;
        height: 12px;
      }

      :deep() .el-switch.is-checked .el-switch__core::after {
        margin-left: -13px;
      }

      :deep() .is-checked .el-switch__core {
        background-color: $purple-5-mayday;
        border-color: $purple-5-mayday;
      }
    }
  }

  .knowledge-manager-section__actions {
    display: flex;
    gap: 12px;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    color: $grey-6-mayday;
    margin-left: auto;

    .dropdown-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        color: $grey-2-mayday;
        pointer-events: none;
      }

      &:hover {
        color: $purple-5-mayday;
      }

      .dropdown-item__icon {
        font-size: 14px;
      }
    }
  }

  .knowledge-manager-section__date {
    padding: 2px 4px;
    border-radius: 4px;
    display: flex;
    gap: 4px;
    align-items: center;
    &.expired {
      color: $yellow-mayday;
      background-color: rgba($yellow-mayday, 0.1);
    }
  }
  .knowledge-manager-section__button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $grey-7-mayday;
    cursor: pointer;
    font-size: 16px;
    width: 20px;
    height: 20px;
    padding: 2px;
    border-radius: 4px;

    svg {
      transition: transform 0.2s ease-in-out;
    }

    &:hover {
      color: $purple-5-mayday;
      background-color: $grey-3-mayday;
    }
  }
}

.knowledge-manager-section__actions-container {
  max-height: 120px;
  overflow-y: auto;
  :deep() ul {
    padding: 0 14px;
    margin: 0;
    li {
      margin: 0;
      &::marker {
        color: $purple-5-mayday;
      }
      p {
        font-size: 14px !important;
      }
    }
  }
}
</style>
