<template>
  <div class="collection-navigator">
    <div
      :style="indent"
      style="text-align: horizontal"
      class="row mt-1"
      id="parent"
    >
      <div
        class="col-auto mr-2 px-0 icon-col"
        :class="{ 'no-cursor': !hasChildren }"
      >
        <div
          v-if="!isFocused"
          @click="toggle"
          :class="{ unclickable: !hasChildren }"
        >
          <div class="toggle-btn text-center" v-if="!showChildren">
            <img
              v-if="!restriction"
              src="~assets/folder-dont.svg"
              class="toggle-icon"
            />
            <img v-else src="~assets/folder-dont.svg" class="toggle-icon" />
          </div>
          <div class="toggle-btn text-center" v-else>
            <img
              v-if="!restriction"
              src="~/assets/folder-open-dont.svg"
              class="toggle-icon"
            />
            <img
              v-else
              src="~assets/folder-open-dont.svg"
              class="toggle-icon"
            />
          </div>
        </div>
        <div class="toggle-btn text-center" v-else>
          <img
            v-if="!restriction"
            src="../assets/folder-do.svg"
            class="toggle-icon"
          />
          <img v-else src="~assets/folder-do.svg" class="toggle-icon" />
        </div>
      </div>
      <div
        class="col-10 node-item"
        :class="{ focus: isFocused }"
        style="display: inline-block"
        @click="focusCollection"
      >
        <div class="row no-margin">
          <div
            class="col px-0"
            style="padding-top: 2px; display: flex; align-items: center"
          >
            <p class="inputNode mb-0 mt-1" :class="{ disable: disableNode }">
              {{ label }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showChildren">
      <ul>
        <li
          class="tree-node without_bullet"
          v-for="node in children"
          :key="node.id"
        >
          <collection-navigator
            :restriction="restriction"
            :node="node"
            :focus-ids="focusIds"
            :node-ids-to-expand="nodeIdsToExpand"
            :set-focus="setFocus"
            :fetch-children="fetchChildren"
          />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    node: Object,
    restriction: {
      type: Boolean,
      default: false,
    },
    focusIds: Array,
    setFocus: Function,
    disableNode: {
      type: Boolean,
      default: false,
    },
    fetchChildren: {
      type: Function,
      required: true,
    },
    nodeIdsToExpand: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  name: 'collection-navigator',
  computed: {
    indent() {
      return { transform: `translate(${this.depth * 50}px)` };
    },
    id() {
      return this.node.id;
    },
    label() {
      return this.node.label || '';
    },
    hasChildren() {
      const { hasChildren } = this.node;
      return hasChildren || this.children.length > 0;
    },
    isKnowledge() {
      const { key } = this.node;
      return key === 'knowledge';
    },
    isFocused() {
      return this.focusIds.includes(this.node.id);
    },
    shouldExpand() {
      return this.nodeIdsToExpand.includes(this.node.id);
    },
    childrenOrder() {
      return (
        this.node.childrenOrder.filter((item) => item.entityType === 'Case') ||
        []
      );
    },
  },
  data() {
    return {
      showChildren: false,
      children: [],
      descendentIds: [],
    };
  },
  async mounted() {
    if (this.shouldExpand) {
      this.children = await this.fetchChildren(
        this.id,
        this.isKnowledge,
        this.childrenOrder,
      );
      this.showChildren = !!this.children.length;
    }
  },
  methods: {
    async toggle() {
      this.children = await this.fetchChildren(
        this.id,
        this.isKnowledge,
        this.childrenOrder,
      );
      this.showChildren = !this.showChildren;
    },
    focusCollection() {
      if (!this.disableNode) {
        this.showChildren = false;
        this.setFocus(this.node.id, this.descendentIds);
      }
    },
  },
};
</script>
<style scoped>
.no-cursor {
  cursor: not-allowed;
}

.unclickable {
  pointer-events: none;
}

.icon-col {
  display: inline-block;
}

.no-margin {
  margin-right: 0px;
  margin-left: 0px;
}

.toggle-btn {
  height: 25px;
  width: 25px;
  margin-top: 3px;
}
.toggle-btn:hover {
  border-radius: 3px;
  background-color: #e9ecef;
}

.toggle-icon {
  max-width: 20px;
  cursor: pointer;
}

.node-item {
  padding-left: 5px;
}

.node-item .focus {
  z-index: 0;
}

.without_bullet {
  list-style: none;
}

#parent {
  height: 30px;
}

.inputNode {
  font-size: 80%;
  font-weight: 600;
  word-break: break-all;
  border: 0px;
  outline: none;
  border-radius: 5px;
  background-color: transparent;
  color: black;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 85%;
  height: 20px;
  line-height: 1.7;
}

.inputNode:focus {
  background-color: white;
  color: grey;
}

.disable {
  color: grey;
  font-style: italic;
  cursor: not-allowed;
}
</style>
