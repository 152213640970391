var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-popover',{attrs:{"placement":"top","trigger":"manual"},model:{value:(_vm.showPopover),callback:function ($$v) {_vm.showPopover=$$v},expression:"showPopover"}},[(_vm.disableSelect)?_c('section',{staticClass:"text-center"},[_c('small',[_vm._v(" ⚠️ "),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$t('knowledge.modals.clone-kb.popover-problem')))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$t('knowledge.modals.clone-kb.popover-solution')))])])]):_vm._e(),_c('div',{staticClass:"cascader-container",attrs:{"slot":"reference"},on:{"mouseenter":function($event){return _vm.triggerPopover(true)},"mouseleave":function($event){return _vm.triggerPopover(false)}},slot:"reference"},[(_vm.isInit)?_c('div',{staticClass:"d-flex align-items-center justify-content-between cascader-header is-init"},[_c('div',[_vm._v(_vm._s(_vm.$t('knowledge.modals.clone-kb.cascader-label')))]),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'times']},on:{"click":function($event){$event.preventDefault();return _vm.$emit('close')}}})],1):_c('div',{staticClass:"d-flex align-items-center cascader-header"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fal', 'arrow-left']},on:{"click":function($event){$event.preventDefault();return _vm.prev()}}}),_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm.hasChildren ? _vm.label : _vm.focusedOption.label)+" ")]),_c('font-awesome-icon',{staticClass:"icon grey-6",attrs:{"icon":['fas', 'times']},on:{"click":function($event){$event.preventDefault();return _vm.$emit('close')}}})],1)],1),_c('div',{staticClass:"overflow"},[(_vm.hasChildren)?_c('div',_vm._l((_vm.options),function(option){return _c('div',{key:option.id,staticClass:"d-flex align-items-center option",class:{
              'is-focus':
                _vm.focusedOption &&
                _vm.focusedOption.id == option.id &&
                !option.disabled,
              'is-disabled ': _vm.disableSelect || option.disabled,
            },on:{"mouseenter":function($event){_vm.hoveredOptionId = option.id},"mouseleave":function($event){_vm.hoveredOptionId = null},"click":function($event){$event.preventDefault();return _vm.focusOption(option)}}},[_c('font-awesome-icon',{class:!_vm.disableSelect &&
                (_vm.hoveredOptionId == option.id ||
                  (_vm.focusedOption && _vm.focusedOption.id == option.id)) &&
                !option.disabled
                  ? 'blue'
                  : 'grey-5',attrs:{"icon":_vm.getOptionIcon(option.caseId)}}),_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"pl-2"},[_vm._v(_vm._s(option.label))]),(!option.disabled)?_c('div',{staticClass:"option-children-selector",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.next(option)}}},[(_vm.hoveredOptionId == option.id)?_c('font-awesome-icon',{staticClass:"icon blue",attrs:{"icon":['fas', 'chevron-right']}}):_vm._e()],1):_vm._e()])],1)}),0):_c('div',{staticClass:"instruction"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.modals.clone-kb.cascader-instruction'))+" ")])]),_c('div',{staticClass:"d-flex justify-content-end cascader-footer"},[_c('ModalActions',{attrs:{"primaryLabel":'knowledge.modals.clone-kb.select',"primaryEnabled":_vm.cloneValidation},on:{"primary":function($event){return _vm.$emit('select-target', _vm.focusedOption)}}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }