<template>
  <header
    class="modal-header pb-3 d-flex px-3 align-items-center justify-content-between"
  >
    <h3 class="m-0">{{ title }}</h3>
    <font-awesome-icon
      class="close-icon-notification"
      :icon="['fas', 'times']"
      @click="sendCloseEvent"
    ></font-awesome-icon>
  </header>
</template>

<script>
export default {
  name: 'modal-header',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    sendCloseEvent() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-header {
  width: 100%;
  border-bottom: 1px solid $grey-4-mayday;
}
.close-icon-notification {
  cursor: pointer;
  color: $grey-4-mayday;
  &:hover {
    color: $grey-5-mayday;
  }
}
</style>
