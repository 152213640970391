<template>
  <div class="no-display"></div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'modal-events',
  props: {
    modalName: {
      type: String,
      required: true,
    },
    uid: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('modalsModule', ['event']),
    modalEvent() {
      return this.event({ uid: this.uid, modalName: this.modalName });
    },
  },
  watch: {
    modalEvent(event) {
      if (event && event.name) {
        this.$emit(event.name, event.data);
        setTimeout(() => this.initModal(), event.wait);
      }
    },
  },
  methods: {
    ...mapActions('modalsModule', ['initModal', 'sendEvent']),
  },
};
</script>

<style scoped>
.no-display {
  display: none;
}
</style>
