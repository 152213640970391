<template>
  <div>
    <BaseDropBox :icon="icon" :initCollapseStatus="!hasRestriction"
      ><template #content>
        <div class="d-flex align-items-center gap-2 w-100">
          <div class="d-flex flex-column w-100">
            <div class="text-sm font-bold">
              {{ title }}
            </div>
            <div class="text-sm">
              <font-awesome-icon
                :icon="['fal', 'check-circle']"
                :class="['text-md', checkCircleColor]"
              />
              {{
                $t(
                  `settings-layout.menu-data.plugins.advanced-settings.drawer.menu.access.${accessStatus}`,
                )
              }}
            </div>
          </div>
        </div>
      </template>
      <template #under-collapse-icon>
        <el-switch v-model="hasRestriction" @change="handleToggle"></el-switch>
      </template>

      <template #collapse-content>
        <div class="d-flex flex-column gap-2">
          <div class="text-base font-bold">
            {{
              $t(
                'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.access.roles',
              )
            }}
          </div>
          <el-select
            v-model="selectedRoles"
            :placeholder="
              $t(
                'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.access.placeholder',
              )
            "
            size="small"
            multiple
            collapse-tags
          >
            <el-option
              v-for="role in roles"
              :key="role.id"
              :label="role.name"
              :value="role.id"
            />
          </el-select>
          <div class="flex justify-end items-center mt-4">
            <button
              class="bg-transparent border-none btn-sm mr-4 text-sm"
              @click="cancel"
            >
              {{
                $t(
                  'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.access.cancel',
                )
              }}
            </button>
            <base-button
              size="sm"
              type="primary"
              :disabled="selectedRoles.length === 0"
              @click="save"
            >
              {{
                $t(
                  'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.access.save',
                )
              }}
            </base-button>
          </div>
        </div>
      </template>
    </BaseDropBox>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import BaseDropBox from './BaseDropBox.vue';

export default defineComponent({
  name: 'plugin-access-settings-drawer',
  components: { BaseDropBox },
  props: {
    title: {
      type: String,
      required: true,
    },
    accessRestrictionsIds: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      isCollapsed: true,
      hasRestriction: this.accessRestrictionsIds.length > 0,
      selectedRoles: this.accessRestrictionsIds,
    };
  },
  methods: {
    async toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
      if (this.isCollapsed && !this.roles) {
        await this.getCustomRoles;
      }
    },
    handleToggle() {
      if (!this.hasRestriction) {
        this.selectedRoles = [];
        this.$emit('update-access-restriction', this.selectedRoles);
      }
    },
    cancel() {
      this.selectedRoles = this.accessRestrictionsIds;
      this.hasRestriction = this.accessRestrictionsIds.length > 0;
    },
    save() {
      this.$emit('update-access-restriction', this.selectedRoles);
    },
  },
  computed: {
    checkCircleColor() {
      return this.hasRestriction ? 'text-blue' : 'text-muted';
    },
    accessStatus() {
      return this.hasRestriction ? 'activated' : 'disabled';
    },
    icon() {
      return this.hasRestriction ? 'lock' : 'lock-open';
    },
    ...mapActions('adminModule', ['getCustomRoles']),
    ...mapGetters('adminModule', ['roles']),
  },
});
</script>
