<template>
  <modal
    :show.sync="localOpen"
    modal-classes="modal-dialog-centered modal align-items-center"
  >
    <h4 slot="header" class="modal-title" id="modal-title-default">
      {{ $t('knowledge.product.characteristics') }}
    </h4>
    <h5 v-for="(characteristic, idx) in enrichedCharacteristics" :key="idx">
      <el-divider class="my-1"></el-divider>
      <font-awesome-icon
        class="collapse-parameter-icon mr-1"
        :icon="['fad', 'wrench']"
      />
      {{ characteristic }}
    </h5>
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue';

export default {
  name: 'characteristics-modal',
  props: {
    display: Boolean,
    enrichedCharacteristics: {
      type: Array,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      localOpen: this.display,
    };
  },
  watch: {
    localOpen() {
      if (!this.localOpen) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-main {
  max-height: 60vh;
  overflow: auto;
}

:deep() .modal-header {
  align-items: center;
}

:deep() .modal-body {
  padding-top: 0px;
}

.trigger-slideshow {
  cursor: pointer;
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.carousel-slide {
  cursor: crosshair;
}
</style>
