<template>
  <div>
    <el-tabs :value="tab" class="settings__menu-tabs" @tab-click="updateTab">
      <el-tab-pane name="general">
        <span slot="label" class="settings__menu-tab">
          <font-awesome-icon :icon="['fal', 'info-circle']" class="tabs-icon" />
          {{ $t('knowledge.drawers.content-view-setting.tabs.general') }}
        </span>
      </el-tab-pane>
      <el-tab-pane name="settings">
        <span slot="label" class="settings__menu-tab">
          <font-awesome-icon :icon="['fas', 'cog']" class="tabs-icon" />
          {{ $t('knowledge.drawers.content-view-setting.tabs.settings') }}
        </span>
      </el-tab-pane>
      <el-tab-pane name="access">
        <span slot="label" class="settings__menu-tab">
          <font-awesome-icon :icon="['fas', 'eye']" class="tabs-icon" />
          {{ $t('knowledge.drawers.content-view-setting.tabs.access') }}
        </span>
      </el-tab-pane>
      <el-tab-pane name="plugins" v-if="focusKnowledgeIsPublic && hasPlugins">
        <span slot="label" class="settings__menu-tab">
          <font-awesome-icon :icon="['fad', 'plug']" class="tabs-icon" />
          {{ $t('knowledge.drawers.content-view-setting.tabs.plugins') }}
        </span>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'content-view-settings-menu',
  props: {
    tab: {
      type: String,
      default: 'general',
    },
  },
  computed: {
    hasPlugins() {
      return (
        this.focusKnowledge.plugins && this.focusKnowledge.plugins.length > 0
      );
    },
    ...mapGetters('knowledgeModule', [
      'focusKnowledgeIsPublic',
      'focusKnowledge',
    ]),
  },
  methods: {
    updateTab(tab) {
      this.$emit('update-tab', tab.name);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .el-tabs__header {
  margin: 0;
  height: 40px;
  border-bottom: 1px solid $grey-4-mayday;
}

:deep() .el-tabs__item {
  padding: 0 !important;
  margin: 8px 0;
  height: 24px;
  line-height: 24px;
  color: $grey-7-mayday;
  &:hover {
    color: #409eff;
  }
}

:deep() .el-tabs__active-bar {
  height: 2px;
}

:deep() .el-tabs__item.is-active {
  margin: 8px 0px;
  background: rgba($blue-mayday, 0.1);
  border-radius: 2px;
  color: $blue-mayday;
}

:deep() .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.settings__menu-tabs {
  user-select: none !important;
  // box-shadow: inset 0 0 0 2px black;
}

.settings__menu-tab {
  margin: 0 8px;
}

.tabs-icon {
  width: 12px;
  height: 12px;
  margin-bottom: 1px;
}
</style>
