<template>
  <div class="mb-2">
    <div v-for="child in enrichedChildren" :key="child.title">
      <component
        v-if="child.display"
        :is="child.component"
        v-bind="child.props"
        class="mb-2"
      />
    </div>
  </div>
</template>

<script>
import SearchLogicSelect from './SearchLogicSelect';
import SynonymsSettingsCard from './SynonymsSettingsCard';
import { mapGetters } from 'vuex';
import PluginSetting from 'views/Settings/PluginsSettings/PluginSetting.vue';

export default {
  name: 'search-settings',
  props: {
    children: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    enrichedChildren() {
      return this.children.map((child) => {
        let display = true;
        if (child.component === 'SearchLogicSelect' && this.isParametric)
          display = false;
        return { ...child, display };
      });
    },
    ...mapGetters(['isParametric']),
  },
  components: {
    SearchLogicSelect,
    SynonymsSettingsCard,
    PluginSetting,
  },
};
</script>

<style lang="scss" scoped></style>
