<template>
  <section class="mayday-web-product-attached-files-card py-2">
    <product-viewer-item-card card-title="Fichiers Attachés" :is-collapsable="true">
      <el-tabs v-model="focusedTab">
        <el-tab-pane v-for="(label, idx) in contentsByLabels" :key="label.id" :name="String(idx)">
          <AttachedFilesCardTabContent slot="label" :label="label" />
          <div class="product__attached__item-row">
            <h4 class="section-title d-md-none">{{ label.labelName }}</h4>
            <div
              v-for="content in label.contents"
              :key="content.id"
              class="product__attached__link d-flex align-items-center full-width justify-content-between py-1 pl-2"
              @click="$emit('focus-content', content.id)"
            >
              <div class="d-flex">
                <img
                  v-if="content.type === 'Article'"
                  src="~assets/article-icon-v2.svg"
                  class="content-icon mx-2 mt-1"
                />
                <img
                  v-if="content.type === 'Diagnostic'"
                  src="~assets/diag-icon-v2.svg"
                  class="content-icon mx-2 mt-1"
                />
                <span>{{ content.label }}</span>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </product-viewer-item-card>
  </section>
</template>

<script>
import ProductViewerItemCard from './ProductViewerItemCard';
import AttachedFilesCardTabContent from './AttachedFilesCardTabContent';

export default {
  name: 'mayday-web-product-attached-files-card',
  props: {
    contentsByLabels: {
      type: Array,
    },
  },
  components: {
    ProductViewerItemCard,
    AttachedFilesCardTabContent,
  },
  data() {
    return {
      focusedTab: '0',
    };
  },
  watch: {
    $route(to) {
      const { hash, query } = to;
      if (hash === '#attached-files' && query['tab']) {
        this.focusedTab = String(
          this.contentsByLabels.findIndex((label) => label.id === query['tab']),
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product__list {
  margin-left: 24px;
  padding-left: 0px !important;
  list-style-type: none;
}

.contents-wrapper {
  border-left: 4px solid $grey-2-mayday;
}

.section-title {
  color: $grey-7-mayday;
  text-transform: uppercase;
}

.product__attached__item-row {
  width: 100%;
  .product__attached__link {
    width: 100%;
    color: $grey-9-mayday;
    cursor: pointer;
    border-left: 2px solid $grey-2-mayday;
    &:hover {
      border-color: $blue-mayday;
      background-color: $grey-1-mayday;
    }
  }
}

.content-icon {
  max-height: 16px;
  max-width: 16px;
}
</style>
