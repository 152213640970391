<template>
  <AnalyticsModal :visible="show" @close="hideContentModal">
    <template v-slot:header>
      <div v-loading="contentStatsIsLoading" class="content-title-container">
        <div class="title-wrapper">
          <i :class="[isDiagnostic ? 'diagnostic-icon' : 'article-icon']"></i>
          <span class="title">{{ contentMetadata.contentTitle }}</span>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div v-loading="contentStatsIsLoading" class="subtitle-wrapper">
        <div class="d-flex align-items-center">
          <font-awesome-icon
            :icon="['fas', 'folder']"
            class="collection-icon"
          />
          <span class="collection-name">{{ contentMetadata.parentTitle }}</span>
        </div>
        <div
          v-if="satisfaction.count"
          :class="[
            'satisfaction-container',
            satisfaction.rate >= 50 ? 'liked' : 'disliked',
          ]"
        >
          <font-awesome-icon
            :icon="[
              'fal',
              satisfaction.rate >= 50 ? 'thumbs-up' : 'thumbs-down',
            ]"
            class="satisfaction-icon"
          />
          {{ clamp(satisfaction.rate, 0, 100) || 0 }}%
          <el-tooltip
            :content="`${satisfaction.up}/${satisfaction.count}`"
            placement="top"
          >
            <font-awesome-icon
              :icon="['fal', 'info-circle']"
              class="satisfaction-icon"
            />
          </el-tooltip>
        </div>
      </div>
      <AnalyticsContentModalBody
        v-if="show"
        :is-diagnostic="isDiagnostic"
        :diagnostic-chart="diagnosticChart"
        :diagnostic-chart-is-loading="diagnosticChartIsLoading"
        :diagnostic-chart-is-empty="diagnosticChartIsEmpty"
        :chart-type.sync="chartTypeModel"
        :chart-type-is-line="chartTypeIsLine"
        :chart-is-empty="chartIsEmpty"
        :chart-is-loading="chartIsLoading"
        :chart-data="chartData"
        :content-stats="contentStats"
        :content-stats-is-loading="contentStatsIsLoading"
        :external-doc-url="externalDocUrl"
        :metadata="contentMetadata"
      ></AnalyticsContentModalBody
    ></template>
  </AnalyticsModal>
</template>

<script>
import AnalyticsModal from '../../AnalyticsModal';
import AnalyticsContentModalBody from './AnalyticsContentModalBody';
import { clamp } from '../../../../utils/utils';

export default {
  props: {
    diagnosticChart: {
      type: Array,
      required: true,
    },
    diagnosticChartIsLoading: {
      type: Boolean,
      required: true,
    },
    diagnosticChartIsEmpty: {
      type: Boolean,
      required: true,
    },
    chartType: {
      type: String,
      required: true,
    },
    chartTypeIsLine: {
      type: Boolean,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    chartIsLoading: {
      type: Boolean,
      required: true,
    },
    chartIsEmpty: {
      type: Boolean,
      required: true,
    },
    isDiagnostic: {
      type: Boolean,
      required: true,
    },
    contentStats: {
      type: Array,
      required: true,
    },
    contentStatsIsLoading: {
      type: Boolean,
      required: true,
    },
    contentMetadata: {
      type: Object,
      default: () => ({ contentTitle: '', parentTitle: '' }),
    },
    show: {
      type: Boolean,
      required: true,
    },
    externalDocUrl: {
      type: String,
      default: () => '',
    },
  },

  model: {
    prop: 'show',
    event: 'close',
  },

  components: {
    AnalyticsModal,
    AnalyticsContentModalBody,
  },

  computed: {
    chartTypeModel: {
      get() {
        return this.chartType;
      },
      set(value) {
        this.handleChartTypeChange(value);
        return value;
      },
    },
    satisfaction() {
      const satisfaction = this.contentStats.find(
        (stat) => !!stat.footerStat.satisfactionCount,
      );
      return satisfaction
        ? satisfaction.footerStat.satisfactionCount
        : {
            count: 0,
          };
    },
  },

  methods: {
    clamp,
    handleChartTypeChange(value) {
      this.$emit('update:chartType', value);
      return value;
    },

    hideContentModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.content-title-container {
  display: flex;
  gap: 24px;

  button {
    outline: none;
  }

  .title-wrapper {
    display: flex;
    align-items: center;

    .title {
      align-self: flex-end;
      margin-left: 16px;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.02em;
      color: #525f7f;
    }
  }
}

.subtitle-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  .collection-icon {
    max-height: 24px;
    max-width: 24px;
  }
  .collection-name {
    margin-left: 16px;
    font-size: 16px;
  }
  .satisfaction-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 6px;
    border-radius: 4px;
    width: 86px;
    gap: 4px;

    .satisfaction-icon {
      width: 14px;
      height: 14px;
    }

    &.liked {
      background: rgba($green-5-mayday, 0.1);
      color: $green-5-mayday;
    }

    &.disliked {
      background: rgba($red-5-mayday, 0.1);
      color: $red-5-mayday;
    }
  }
}

%type-icon {
  display: block;
  background-size: cover;
  width: 34px;
  height: 34px;
}

.article-icon {
  @extend %type-icon;
  background-image: url('~@/assets/article-icon-v2.1.svg');
}
.diagnostic-icon {
  @extend %type-icon;
  background-image: url('~@/assets/diag-icon-v2.1.svg');
}
.collection-icon {
  color: #adb5bd;
  width: 34px;
  height: 34px;
}
</style>
