<template>
  <footer>
    <StepIndicator :steps="steps" :current-step="currentStep" class="mb-3" />

    <AdminContributionModalActions
      :step-count="stepCount"
      :current-step="currentStep"
      :is-label-invalid="isLabelInvalid"
      :is-type-invalid="isTypeInvalid"
      :is-knowledge-invalid="isKnowledgeInvalid"
      :are-parameters-invalid="areParametersInvalid"
      :are-access-restrictions-invalid="areAccessRestrictionsInvalid"
      @close="sendCloseEvent"
      @go-to-previous-step="sendGoToPreviousStepEvent"
      @go-to-next-step="sendGoToNextStep"
      @create-contribution="createContributionEvent"
    />
  </footer>
</template>

<script>
import StepIndicator from '@/components/Modals/CreateProductNotificationModal/StepIndicator.vue';
import AdminContributionModalActions from './AdminContributionModalActions';

export default {
  name: 'admin-contribution-modal-footer',
  components: {
    StepIndicator,
    AdminContributionModalActions,
  },
  props: {
    steps: Array,
    currentStep: Number,
    stepCount: Number,
    isLabelInvalid: Boolean,
    isTypeInvalid: Boolean,
    isKnowledgeInvalid: Boolean,
    areParametersInvalid: Boolean,
    areAccessRestrictionsInvalid: Boolean,
  },
  methods: {
    sendCloseEvent() {
      this.$emit('close');
    },
    sendGoToPreviousStepEvent() {
      this.$emit('go-to-previous-step');
    },
    sendGoToNextStep() {
      this.$emit('go-to-next-step');
    },
    createContributionEvent() {
      this.$emit('create-contribution');
    },
  },
};
</script>
