<template>
  <span slot="label" :style="{ '--label-color': label.color }">
    <span class="content-list-item-label d-flex align-items-center">
      <font-awesome-icon class="content-label-icon mr-1" :icon="['fad', label.icon]" />
      <span class="d-none d-md-block item-label-name">{{ label.labelName }}</span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'attached-files-card-tab-content',
  props: {
    label: Object,
  },
};
</script>

<style lang="scss" scoped>
.content-label-icon {
  max-width: 24px;
  font-size: 20px;
  color: var(--label-color);
  background-color: rgba(var(--label-color), 0.1);
  border-radius: 2px;
  padding: 2px;
}

.item-label-name {
  &:hover {
    color: var(--label-color);
  }
}
</style>