<template>
  <div class="options-container">
    <div v-for="(option, i) in options" :key="i">
      <label>
        <span> {{ $t(`${translationPath}.${option}`) }}</span>
        <input
          :checked="selected.includes(option)"
          type="checkbox"
          @change="$emit('update-filter', option)"
        />
      </label>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HubCategoryFilterList',
  props: {
    options: Array,
    selected: Array,
    translationPath: String,
  },
};
</script>
<style lang="scss" scoped>
.options-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 0;
    padding: 4px 0;
    &:hover {
      color: $blue-2-mayday;
    }
  }
}
</style>
