<template>
  <base-dropdown>
    <base-button
      slot="title"
      type="secondary"
      class="ml-1 flex dropdown"
      id="dropdown-button"
    >
      <img id="options_selection" src="~assets/options_selection.png" />
    </base-button>

    <!-- LABEL -->
    <not-allowed-tooltip
      :user-permissions="userPermissions"
      permission-key="canUpdateContent"
    >
      <template v-slot:main-content="{ hasPermissions }">
        <div
          class="dropdown-item"
          @click="hasPermissions ? $emit('update-label') : ''"
        >
          <h5 class="text-overflow m-0">
            <font-awesome-icon class="text-grey" :icon="['fad', 'edit']" />
            <small class="ml-2">{{ $t('knowledge.actions.rename') }}</small>
          </h5>
        </div>
      </template>
    </not-allowed-tooltip>

    <!-- ICON -->
    <not-allowed-tooltip
      :user-permissions="userPermissions"
      permission-key="canUpdateContent"
    >
      <template v-slot:main-content="{ hasPermissions }">
        <div
          class="dropdown-item"
          @click="hasPermissions ? $emit('update-icon') : ''"
        >
          <h5 class="text-overflow m-0">
            <font-awesome-icon
              class="text-grey"
              :icon="['fad', 'image-polaroid']"
            />
            <small class="ml-2">{{
              $t('knowledge.actions.change-icon')
            }}</small>
          </h5>
        </div>
      </template>
    </not-allowed-tooltip>

    <!-- MINI ICON -->
    <not-allowed-tooltip
      :user-permissions="userPermissions"
      permission-key="canUpdateContent"
    >
      <template v-slot:main-content="{ hasPermissions }">
        <div
          class="dropdown-item"
          @click="hasPermissions ? $emit('update-mini-icon') : ''"
        >
          <h5 class="text-overflow m-0">
            <font-awesome-icon
              class="text-grey"
              :icon="['fad', 'image-polaroid']"
            />
            <small class="ml-2">{{
              $t('knowledge.actions.change-mini-icon')
            }}</small>
          </h5>
        </div>
      </template>
    </not-allowed-tooltip>

    <!-- ADD CHILD -->
    <not-allowed-tooltip
      :user-permissions="userPermissions"
      permission-key="canCreateContent"
    >
      <template v-slot:main-content="{ hasPermissions }">
        <div
          class="dropdown-item"
          @click="hasPermissions ? $emit('add-child') : ''"
        >
          <h5 class="text-overflow m-0">
            <font-awesome-icon
              class="text-grey"
              :icon="['fad', 'plus-circle']"
            />
            <small class="ml-2">{{
              $t('knowledge.actions.add-element')
            }}</small>
          </h5>
        </div>
      </template>
    </not-allowed-tooltip>

    <!-- CLONE -->
    <not-allowed-tooltip
      v-if="!isParametric"
      :user-permissions="userPermissions"
      permission-key="canCreateContent"
    >
      <template v-slot:main-content="{ hasPermissions }">
        <div
          class="dropdown-item"
          @click="hasPermissions ? $emit('clone-kb') : ''"
        >
          <h5 class="text-overflow m-0">
            <font-awesome-icon class="text-grey" :icon="['fad', 'clone']" />
            <small class="ml-2">{{
              $t('knowledge.actions.clone-in-other-kb')
            }}</small>
          </h5>
        </div>
      </template>
    </not-allowed-tooltip>

    <!-- DUPLICATE SAME LOCATION -->
    <not-allowed-tooltip
      v-if="!isParametric"
      :user-permissions="userPermissions"
      permission-key="canCreateContent"
    >
      <template v-slot:main-content="{ hasPermissions }">
        <div
          class="dropdown-item"
          @click="hasPermissions ? $emit('duplicate-in-same-location') : ''"
        >
          <h5 class="text-overflow m-0">
            <font-awesome-icon
              class="text-grey"
              :icon="['fad', 'object-group']"
            />
            <small class="ml-2">{{
              $t('knowledge.actions.duplicate-in-same-location')
            }}</small>
          </h5>
        </div>
      </template>
    </not-allowed-tooltip>
    <div class="dropdown-divider"></div>

    <!-- ARCHIVE -->
    <not-allowed-tooltip
      :user-permissions="userPermissions"
      permission-key="canDeleteContent"
    >
      <template v-slot:main-content="{ hasPermissions }">
        <div
          class="dropdown-item"
          :class="hasPermissions ? 'archive-item' : 'archive-disabled'"
          @click.prevent="hasPermissions ? $emit('delete-node') : ''"
        >
          <h5 class="text-overflow m-0">
            <font-awesome-icon class="text-red" :icon="['fad', 'archive']" />
            <small class="ml-2">{{ $t('knowledge.actions.delete') }}</small>
          </h5>
        </div>
      </template>
    </not-allowed-tooltip>
    <not-allowed-tooltip
      :user-permissions="userPermissions"
      permission-key="canDeleteContent"
    >
      <template v-slot:main-content="{ hasPermissions }">
        <div
          class="dropdown-item"
          :class="hasPermissions ? ' soft-delete' : 'soft-delete-disabled'"
          @click="
            hasPermissions ? $emit('delete-node', { softDeleted: true }) : ''
          "
        >
          <h5 class="text-overflow m-0">
            <font-awesome-icon
              :icon="['fad', 'trash']"
              class="edit-node-icon"
            />
            <small class="ml-2">{{
              $t('knowledge.actions.soft-delete')
            }}</small>
          </h5>
        </div>
      </template>
    </not-allowed-tooltip>
  </base-dropdown>
</template>

<script>
import { mapGetters } from 'vuex';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

export default {
  name: 'parametric-root-folder-card-options',
  components: {
    NotAllowedTooltip,
  },
  props: {
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(['isParametric']),
  },
};
</script>

<style lang="scss" scoped>
#options_selection {
  max-width: 15px;
}

#dropdown-button {
  border-width: 0px;
  box-shadow: none;
  background-color: transparent;
  margin: auto;
}

.dropdown {
  padding: 0px;
}

.dropdown-item:hover {
  background-color: rgba($blue-mayday, 0.05);
}

.dropdown-item :hover i {
  color: $blue-mayday;
}

.dropdown-item p {
  margin: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.dropdown-item span {
  margin-left: 6px;
}

.dropdown-item h5 {
  display: flex;
  align-items: center;
}

.not-allowed {
  &:hover {
    cursor: not-allowed;
  }
}

.archive-item:hover {
  background-color: rgba($yellow-mayday, 0.1);
}

.soft-delete {
  background-color: rgba($red-mayday, 0.1);
  small,
  .edit-node-icon {
    color: $red-mayday;
  }
  &:hover {
    background-color: $red-mayday;
    small,
    .edit-node-icon {
      color: white;
    }
  }
}

.dropdown-item.soft-delete-disabled {
  background-color: $grey-1-mayday;
  & .edit-node-icon {
    color: $red-mayday;
  }
  & small {
    color: $grey-5-mayday;
  }
}
.dropdown-item.archive-disabled {
  & small {
    color: $grey-5-mayday;
  }
  &:hover {
    background-color: transparent !important;
  }
}

:deep() #dropdown {
  padding-bottom: 0;
}
</style>
