<template>
  <div class="d-flex flex-nowrap parameters">
    <div class="product-breadcrumb" v-if="breadcrumb && breadcrumb.length">
      {{ breadcrumb[0] }}
    </div>
    <div class="parameter" v-if="knowledge">
      {{ knowledge }}
    </div>
    <div class="parameter" v-if="brand">
      {{ brand }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'product-parameters',
  props: {
    parameters: Object,
    breadcrumb: Array,
  },
  computed: {
    knowledge() {
      return this.parameters.knowledge ? this.parameters.knowledge.label : null;
    },
    brand() {
      return this.parameters.brand ? this.parameters.brand.label : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.parameters {
  margin-left: 2px;
}
.parameter {
  font-size: 10px;
  color: $grey-8-mayday;
  background-color: $grey-2-mayday;
  border: 1px solid $grey-8-mayday;
  padding: 0px 2px;
  margin: 0px 2px;
  border-radius: 1px;
  flex: none;
}

.product-breadcrumb {
  font-size: 12px;
  color: $grey-6-mayday;
  margin-right: 4px;
}
</style>
