<template>
  <div>
    <div
      v-for="userLabelCategory in userLabelCategories"
      :key="userLabelCategory.id"
    >
      <LabelCategorySettingsDropdown
        v-bind="userLabelCategory"
        @update-category="$emit('update-category', $event)"
        @update-label="$emit('update-label', $event)"
        @create-label="$emit('create-label', $event)"
        @delete-label="$emit('delete-label', $event)"
        @edit-icon="handleEditIconCategory"
        @trigger-delete="handleDeleteCategory"
      />
    </div>

    <DangerDeleteModal
      v-if="modals.delete"
      :display="modals.delete"
      type="category"
      :to-delete-name="toDeleteCategory.name"
      :count="toDeleteCategory.companyUserLabels.length"
      @choice="deleteCategory"
      @close="modals.delete = false"
    />

    <IconEditionModal
      v-if="modals.icon"
      :display="modals.icon"
      :current-icon="toUpdateCategory"
      :editionOptions="iconOptions"
      @choice="updateCategoryIcon"
      @delete="$emit('delete-content-icon')"
      @close="modals.icon = false"
    />
  </div>
</template>

<script>
import LabelCategorySettingsDropdown from './LabelCategorySettingsDropdown';
import DangerDeleteModal from '@/components/Modals/DangerDeleteModal';
import IconEditionModal from '@/components/Modals/IconEditionModal/IconEditionModal';

export default {
  name: 'label-category-settings-table',
  components: {
    LabelCategorySettingsDropdown,
    DangerDeleteModal,
    IconEditionModal,
  },
  props: {
    userLabelCategories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modals: {
        icon: false,
        delete: false,
      },
      toUpdateCategory: {},
      toDeleteCategory: {},
      iconOptions: {
        emoji: false,
        fa: true,
        upload: false,
        link: false,
      },
    };
  },
  methods: {
    // TRIGGER MODAL
    handleDeleteCategory(category) {
      this.toDeleteCategory = category;
      this.modals.delete = true;
    },

    handleEditIconCategory(category) {
      this.toUpdateCategory = {
        ...category,
        value: category.icon,
        iconType: 'fa',
      };
      this.modals.icon = true;
    },

    // CATEGORY ACTIONS MODAL
    deleteCategory() {
      this.$emit('delete-category', this.toDeleteCategory.id);
      this.modals.delete = false;
    },

    updateCategoryIcon({ value, color }) {
      this.$emit('update-category', {
        ...this.toUpdateCategory,
        icon: value,
        color,
      });
      this.modals.icon = false;
    },
  },
};
</script>
