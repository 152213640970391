var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[(_vm.token)?_c('div',{staticClass:"settings__public-item"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.drawers.content-view-setting.settings.public.url'))+" ")]),_c('el-input',{attrs:{"value":_vm.url,"disabled":""}},[_c('template',{slot:"append"},[_c('div',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.url),expression:"url",arg:"copy"}],staticClass:"copy-icon",on:{"click":function($event){return _vm.copyUrl()}}},[_c('el-tooltip',{attrs:{"content":_vm.$t(
                'knowledge.drawers.content-view-setting.settings.public.copy',
              ),"placement":"top"}},[_c('font-awesome-icon',{attrs:{"icon":['fad', 'clipboard-check']}})],1)],1)])],2),_c('div',{staticClass:"mt-2"},[_c('el-button',{on:{"click":_vm.handleRevokeToken}},[_vm._v(_vm._s(_vm.$t( 'knowledge.drawers.content-view-setting.settings.public.revoke-token', )))])],1)],1):_c('div',[_c('el-button',{on:{"click":_vm.handleAddToken}},[_vm._v(_vm._s(_vm.$t('knowledge.drawers.content-view-setting.settings.public.add-token')))])],1),(_vm.FOR_LATER)?_c('div',{staticClass:"settings__public-item"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.drawers.content-view-setting.settings.public.add-token'))+" "),_c('el-tooltip',{attrs:{"content":_vm.$t(
            'knowledge.drawers.content-view-setting.settings.public.add-token-tooltip',
          ),"placement":"top"}},[_c('font-awesome-icon',{staticClass:"info-icon mx-2",attrs:{"icon":['fad', 'info-circle']}})],1)],1),_c('el-input',{attrs:{"rows":3,"placeholder":_vm.$t(
          'knowledge.drawers.content-view-setting.settings.public.add-token-placeholder',
        )},model:{value:(_vm.newToken.name),callback:function ($$v) {_vm.$set(_vm.newToken, "name", $$v)},expression:"newToken.name"}}),_c('div',{staticClass:"settings__public-add"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'plus']},on:{"click":_vm.handleAddToken}})],1)],1):_vm._e(),(_vm.FOR_LATER)?_c('div',{staticClass:"settings__public-item"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.drawers.content-view-setting.settings.public.tokens'))+" "),_c('el-tooltip',{attrs:{"content":_vm.$t(
            'knowledge.drawers.content-view-setting.settings.public.meta-tooltip',
          ),"placement":"top"}},[_c('font-awesome-icon',{staticClass:"info-icon mx-2",attrs:{"icon":['fad', 'info-circle']}})],1)],1),_vm._l((_vm.tokens),function(token){return _c('div',{key:token.accessToken},[_vm._v(" "+_vm._s(token.name)+" ")])})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }