<template>
  <div class="ask-answer" v-if="!loading">
    <div
      class="d-flex flex-column w-100"
      v-if="!isDecline && !isError && !isInappropriate"
    >
      <div class="ask-result-with-answer w-100">
        <AskAnswerStream
          :answer-stream="answerStream"
          :hovered-link="hoveredLinkInSources"
          @update-hover-link="handleHoverLinkInStream"
          @open-sidebar="handleOpenSidebarFromStream"
          class="ask-answer-stream"
          :class="noSources ? 'w-100' : 'w-50'"
        ></AskAnswerStream>
        <div class="w-50 h-full">
          <AskBox
            v-if="displaySources"
            :title="'ask.search.sources'"
            :class="{
              'd-flex justify-content-center align-items-center':
                sourcesLoading,
            }"
            ><template v-slot:content>
              <AskAnswerSources
                v-if="!sourcesLoading"
                :sources="sortedSources.quoted"
                :hoveredLink="hoveredLinkInStream"
                :citation-labels="citationLabels"
                :language="language"
                @go-to-document="$emit('go-to-document', $event)"
                @open-sidebar="$emit('open-sidebar', $event)"
                @copy-document-url="$emit('copy-document-url', $event)"
                @update-hover-link="handleHoverLinkInSources"
              ></AskAnswerSources>
              <font-awesome-icon
                v-else
                :icon="['fad', 'spinner-third']"
                spin
                class="source-loading-icon"
              /> </template
          ></AskBox>
        </div>
      </div>
      <div class="action-wrapper">
        <el-tooltip :content="$t('knowledge.actions.copy-to-clipboard')"
          ><div
            class="copy-container"
            @click="handleCopyAnswer"
            @mouseover="copyHovered = true"
            @mouseleave="copyHovered = false"
          >
            <font-awesome-icon
              :icon="[copyHovered ? 'fas' : 'far', 'clipboard']"
            /></div
        ></el-tooltip>

        <AskFeedback
          :feedback="feedbackStatus"
          @update-feedback-status="$emit('update-feedback-status', $event)"
        ></AskFeedback>
      </div>
    </div>
    <div class="decline" v-if="isDecline || isError || isInappropriate">
      <AskBox
        v-if="isDecline"
        :title="'ask.search.no-result'"
        :icon="['fal', 'times']"
        ><template v-slot:content
          ><div class="is-decline-answer">{{ answerStream }}</div>
        </template></AskBox
      >
      <AskBox
        v-if="isInappropriate"
        :title="'ask.search.no-result'"
        :icon="['fal', 'times']"
        ><template v-slot:content
          ><div class="is-decline-answer">
            {{ $t('ask.search.inappropriate') }}
          </div>
        </template></AskBox
      >
      <AskBox
        v-if="isError"
        :title="'ask.search.error'"
        :icon="['fal', 'exclamation-triangle']"
        :border-color="'red'"
        :header-color="'red'"
        ><template v-slot:content>
          <div class="is-error-answer">
            <div class="error-message">{{ answerStream }}</div>
            <div
              class="error-retry-wrapper"
              @click.prevent.stop="$emit('retry')"
            >
              <div class="error-retry">
                <font-awesome-icon
                  :icon="['fas', 'redo']"
                  class="mr-2"
                ></font-awesome-icon>
                {{ $t('ask.search.retry') }}
              </div>
            </div>
          </div>
        </template></AskBox
      >
    </div>
  </div>
  <div v-else class="ask-answer">
    <AskSkeletonLoader @cancel-ask="$emit('cancel-ask')"></AskSkeletonLoader>
  </div>
</template>

<script>
import AskAnswerSources from './AskAnswerSources.vue';
import AskAnswerStream from './AskAnswerStream.vue';
import AskBox from './AskBox.vue';
import AskSkeletonLoader from './AskSkeletonLoader.vue';
import AskFeedback from './AskFeedback.vue';

export default {
  name: 'ask-answer',
  components: {
    AskAnswerStream,
    AskAnswerSources,
    AskBox,
    AskSkeletonLoader,
    AskFeedback,
  },
  props: {
    answerStream: {
      type: String,
      default: '',
    },
    sources: {
      type: Array,
      default: () => [],
    },
    citationLabels: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: true,
    },
    isDecline: {
      type: Boolean,
      default: false,
    },
    isInappropriate: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    feedbackStatus: {
      type: String,
      default: '',
    },
    endOfStream: {
      type: Boolean,
      default: false,
    },
    language: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hoveredLinkInSources: null,
      hoveredLinkInStream: null,
      copyHovered: false,
      sortedSources: { quoted: [], nonQuoted: [] },
    };
  },
  computed: {
    displaySources() {
      return (
        !this.isDecline &&
        !this.isError &&
        !this.noSources &&
        !this.isInappropriate
      );
    },
    sourcesLoading() {
      return (
        !this.sortedSources.quoted || this.sortedSources.quoted.length === 0
      );
    },
    noSources() {
      return (
        this.endOfStream &&
        this.sourcesLoading &&
        this.sortedSources.nonQuoted.length === 0
      );
    },
  },
  methods: {
    handleHoverLinkInSources({ id }) {
      this.hoveredLinkInSources = id;
    },
    handleHoverLinkInStream(id) {
      this.hoveredLinkInStream = id;
    },
    handleOpenSidebarFromStream({ entityId }) {
      const source = this.sources.find((s) => s.id === entityId);
      this.$emit('open-sidebar', {
        entityId,
        entityType: source.type,
        denormalizedCaseParameters: source.denormalizedCaseParameters[0],
      });
    },
    handleCopyAnswer() {
      navigator.clipboard.writeText(
        this.removeMarkdownLinks(this.answerStream),
      );
      this.$message(this.$t('knowledge.actions.copy-to-clipboard'));
      this.$emit('copy-answer');
    },
    removeMarkdownLinks(inputText) {
      const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
      return inputText.replace(markdownLinkRegex, '');
    },
    computeSortedSources() {
      this.sortedSources = this.sources.reduce(
        (acc, val) => {
          if (this.citationLabels[val.id]) acc.quoted.push(val);
          else acc.nonQuoted.push(val);
          return acc;
        },
        { quoted: [], nonQuoted: [] },
      );
    },
  },
  watch: {
    citationLabels: {
      handler() {
        this.computeSortedSources();
      },
      deep: true,
      immediate: true,
    },
    endOfStream(newVal) {
      if (newVal && this.sortedSources.quoted.length === 0)
        this.sortedSources.quoted = this.sources.slice(0, 3);
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-answer {
  display: flex;
  flex-direction: row;
  align-items: top;
  width: 100%;
  max-height: 350px;
  padding-top: 20px;
  overflow: hidden;
  padding-bottom: 10px;
}
.ask-result-with-answer {
  display: flex;
  flex-direction: row;
  max-height: 90%;
}

.decline {
  width: 100%;
}

.is-decline-answer {
  padding: 16px;
  color: $grey-8-mayday;
  font-size: 14px;
  padding-bottom: 10px;
}
.is-error-answer {
  padding: 16px;
  color: $red-5-mayday;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.error-retry-wrapper {
  width: 100%;
  justify-content: right;
  display: flex;
  &:hover {
    cursor: pointer;
  }
}
.error-retry {
  width: fit-content;
  border: 1px solid $grey-5-mayday;
  border-radius: 4px;
  padding: 3px 10px 3px 10px;
  color: $grey-8-mayday;
}

.action-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 5px;
}
.copy-container {
  color: $purple-5-mayday;
  background-color: white;
  border-radius: 4px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  font-size: 14px;
  &:hover {
    background-color: $purple-5-mayday;
    color: white;
    cursor: pointer;
  }
}
.source-loading-icon {
  font-size: xxx-large;
  color: $purple-5-mayday;
}
</style>
