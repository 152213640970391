<template>
  <el-input
    ref="remoteSearchInput"
    v-model="searchQuery"
    class="filter-remote-search-input"
    :placeholder="_placeholder"
    :maxlength="30"
    icon="fad fa-search"
    :clearable="true"
    :trigger-on-focus="false"
    @input="$emit('change-additional', { key: 'Remote', value: $event })"
  >
    <font-awesome-icon
      slot="prefix"
      :icon="['fad', 'search']"
      class="search-icon"
    />
    <el-tooltip v-if="condition" slot="append" placement="top">
      <div slot="content">{{ $t(conditionTooltip[condition]) }}</div>
      <el-button
        class="condition-search-button"
        @click="
          $emit('change-additional', {
            key: 'Condition',
            value: condition === 'OR' ? 'AND' : 'OR',
          })
        "
      >
        {{ $t(conditionLabels[condition]) }}
      </el-button>
    </el-tooltip>
  </el-input>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    condition: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      searchQuery: this.value,
      conditionLabels: {
        AND: 'new-dashboard.filter-type.and.value',
        OR: 'new-dashboard.filter-type.or.value',
      },
      conditionTooltip: {
        AND: 'new-dashboard.filter-type.and.tooltip',
        OR: 'new-dashboard.filter-type.or.tooltip',
      },
    };
  },

  computed: {
    _placeholder() {
      return this.placeholder != null
        ? this.placeholder
        : this.$t('filters.drawer.remote-search-placeholder');
    },
  },
};
</script>

<style lang="scss" scoped>
.condition-search-button {
  font-size: 12px;
}

.filter-remote-search-input {
  :deep() .el-input__prefix {
    display: flex;
    align-items: center;
    .search-icon {
      margin-left: 8px;
      color: $grey-4-mayday;
    }
  }

  :deep() .el-input__inner {
    // 30 (base) + 15 (figma)
    padding-left: 45px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    ::placeholder {
      color: $grey-5-mayday;
    }
  }
}
</style>
