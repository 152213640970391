<template>
  <div
    :class="[
      'category',
      'd-flex',
      'mb-0',
      'align-items-center',
      isLabel ? '' : 'col',
    ]"
  >
    <input
      ref="itemEditorInput"
      type="text"
      size="25"
      v-model="newValue"
      :placeholder="$t(placeholder)"
      :class="[
        'item-editor__input',
        isLabel ? 'item-editor__input--label' : 'item-editor__input--category',
      ]"
      @blur="handleBlur"
      @keyup.esc="handleEscape"
      @keyup.enter="handleEnter"
    />
  </div>
</template>

<script>
export default {
  name: 'item-editor',
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    isLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newValue: this.value,
      isCancel: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.focus();
    });
  },
  methods: {
    focus() {
      this.$refs.itemEditorInput.focus();
    },
    handleBlur() {
      if (!this.newValue) {
        this.$emit('cancel');
        return;
      }

      if (!this.isCancel) this.$emit('edit', this.newValue);
    },
    handleEnter(event) {
      event.target.blur();
    },
    handleEscape() {
      this.isCancel = true;
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.item-editor__input {
  border: none;
  padding: 0;
  margin-left: -5px;

  &::placeholder {
    color: $grey-5-mayday;
  }
}

.item-editor__input--label {
  color: $grey-9-mayday;
}

.item-editor__input--category {
  font-size: 1rem;
  padding-left: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
}
</style>
