const colorToHex = (color) => {
  var hexadecimal = color.toString(16);
  return hexadecimal.length == 1 ? '0' + hexadecimal : hexadecimal;
};
export const convertRGBtoHex = (rgb) => {
  rgb = rgb
    .substring(4, rgb.length - 1)
    .replace(/ /g, '')
    .split(',');

  return (
    '#' +
    colorToHex(parseInt(rgb[0])) +
    colorToHex(parseInt(rgb[1])) +
    colorToHex(parseInt(rgb[2]))
  );
};
