var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('DrawerRowTitle',{attrs:{"title":_vm.$t(
        'knowledge.drawers.content-view-setting.settings.access.title-public',
      ),"tooltip":_vm.$t(
        'knowledge.drawers.content-view-setting.settings.access.tooltip-public',
      )}}),(_vm.companyHostname)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('div',{staticClass:"settings__public-item"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.drawers.content-view-setting.settings.public.url'))+" ")]),_c('el-input',{attrs:{"value":_vm.url,"readonly":""},on:{"focus":function($event){return $event.target.select()}}},[_c('template',{slot:"append"},[_c('div',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.url),expression:"url",arg:"copy"}],staticClass:"copy-icon",class:{ 'click-bounce': _vm.animate.url },on:{"click":function($event){return _vm.copyUrl('url')}}},[_vm._v(" "+_vm._s(_vm.$t( 'knowledge.drawers.content-view-setting.settings.public.copy', ))+" ")])])],2)],1),_c('div',{staticClass:"settings__public-item"},[(_vm.knowledgeIsPublic)?_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t( 'knowledge.drawers.content-view-setting.settings.public.meta-label', ))+" "),_c('el-tooltip',{attrs:{"content":_vm.$t(
              'knowledge.drawers.content-view-setting.settings.public.meta-tooltip',
            ),"placement":"top"}},[_c('font-awesome-icon',{staticClass:"info-icon mx-2",attrs:{"icon":['fad', 'info-circle']}})],1),_c('div',{staticClass:"toggle"},[_c('button',{staticClass:"reveal",on:{"click":function($event){_vm.displayMetaLabel = !_vm.displayMetaLabel}}},[_c('div',{class:{ 'open-reveal': _vm.displayMetaLabel }},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'chevron-right']}})],1)])])],1):_vm._e(),_c('not-allowed-tooltip',{attrs:{"user-permissions":_vm.userPermissions,"permission-key":"canUpdateContent"},scopedSlots:_vm._u([{key:"main-content",fn:function({ hasPermissions }){return [(_vm.displayMetaLabel)?_c('el-input',{attrs:{"type":"textarea","disabled":!hasPermissions,"rows":3,"placeholder":_vm.$t(
                'knowledge.drawers.content-view-setting.settings.public.meta-label-placeholder',
              )},model:{value:(_vm.newMetaLabel),callback:function ($$v) {_vm.newMetaLabel=$$v},expression:"newMetaLabel"}}):_vm._e()]}}],null,false,1610886545)}),(_vm.knowledgeIsPublic)?_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.drawers.content-view-setting.settings.public.meta'))+" "),_c('el-tooltip',{attrs:{"content":_vm.$t(
              'knowledge.drawers.content-view-setting.settings.public.meta-tooltip',
            ),"placement":"top"}},[_c('font-awesome-icon',{staticClass:"info-icon mx-2",attrs:{"icon":['fad', 'info-circle']}})],1),_c('div',{staticClass:"toggle"},[_c('button',{staticClass:"reveal",on:{"click":function($event){_vm.displayDescription = !_vm.displayDescription}}},[_c('div',{class:{ 'open-reveal': _vm.displayDescription }},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'chevron-right']}})],1)])])],1):_vm._e(),_c('not-allowed-tooltip',{attrs:{"user-permissions":_vm.userPermissions,"permission-key":"canUpdateContent"},scopedSlots:_vm._u([{key:"main-content",fn:function({ hasPermissions }){return [(_vm.displayDescription)?_c('el-input',{attrs:{"type":"textarea","disabled":!hasPermissions,"rows":3,"placeholder":_vm.$t(
                'knowledge.drawers.content-view-setting.settings.public.meta-placeholder',
              )},model:{value:(_vm.newDescription),callback:function ($$v) {_vm.newDescription=$$v},expression:"newDescription"}}):_vm._e()]}}],null,false,935256378)}),(_vm.modified)?_c('div',{staticClass:"settings__public-cta"},[_c('el-button',{on:{"click":_vm.handleCancel}},[_vm._v(_vm._s(_vm.$t('knowledge.drawers.content-view-setting.settings.public.cancel')))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleUpdate}},[_vm._v(_vm._s(_vm.$t('knowledge.drawers.content-view-setting.settings.public.update')))])],1):_vm._e()],1),_c('ContentViewSettingsImageCover',{attrs:{"cover":_vm.cover,"userPermissions":_vm.userPermissions,"content":_vm.content},on:{"open-cover-modal":function($event){return _vm.$emit('open-cover-modal', $event)},"set-cover-image":function($event){return _vm.$emit('set-cover-image', $event)},"delete-cover":function($event){return _vm.$emit('delete-cover', $event)}}}),_c('div',{staticClass:"settings__public-item"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.drawers.content-view-setting.settings.public.iframe'))+" "),_c('el-tooltip',{attrs:{"content":_vm.$t(
              'knowledge.drawers.content-view-setting.settings.public.iframe-tooltip',
            ),"placement":"top"}},[_c('font-awesome-icon',{staticClass:"info-icon mx-2",attrs:{"icon":['fad', 'info-circle']}})],1),_c('div',{staticClass:"toggle"},[_c('button',{staticClass:"reveal",on:{"click":function($event){_vm.displayIframe = !_vm.displayIframe}}},[_c('div',{class:{ 'open-reveal': _vm.displayIframe }},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'chevron-right']}})],1)])])],1),(_vm.displayIframe)?_c('div',[_c('el-input',{attrs:{"value":_vm.iframeUrl,"readonly":""},on:{"focus":function($event){return $event.target.select()}}},[_c('template',{slot:"append"},[_c('div',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.iframeUrl),expression:"iframeUrl",arg:"copy"}],staticClass:"copy-icon",class:{ 'click-bounce': _vm.animate.iframeUrl },on:{"click":function($event){return _vm.copyUrl('iframeUrl')}}},[_vm._v(" "+_vm._s(_vm.$t( 'knowledge.drawers.content-view-setting.settings.public.copy', ))+" ")])])],2),_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t( 'knowledge.drawers.content-view-setting.settings.public.iframe-code', ))+" ")]),_c('el-input',{attrs:{"value":_vm.iframeCode,"readonly":""},on:{"focus":function($event){return $event.target.select()}}},[_c('template',{slot:"append"},[_c('div',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.iframeCode),expression:"iframeCode",arg:"copy"}],staticClass:"copy-icon",class:{ 'click-bounce': _vm.animate.iframeCode },on:{"click":function($event){return _vm.copyUrl('iframeCode')}}},[_vm._v(" "+_vm._s(_vm.$t( 'knowledge.drawers.content-view-setting.settings.public.copy', ))+" ")])])],2)],1):_vm._e()])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }