<template>
  <div
    :class="['tag', status]"
    :key="id"
    @mouseenter="setActive"
    @mouseleave="setInactive"
  >
    <span class="tag-text">{{ displayLabel }}</span>
    <font-awesome-icon
      v-if="showReject && active"
      class="action-button"
      :icon="['fal', 'trash']"
      @click="handleReject"
    />
    <font-awesome-icon
      v-if="showAccept && active"
      class="action-button"
      :icon="['fal', 'check']"
      @click="handleAccept"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import getTranslation from '@/utils/getTranslation';

export default {
  props: {
    translations: {
      type: Object,
      required: true,
    },
    defaultLanguage: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      active: false,
    };
  },
  methods: {
    ...mapActions('knowledgeModule', [
      'acceptContentConcept',
      'rejectContentConcept',
    ]),
    setActive() {
      this.active = true;
    },
    setInactive() {
      this.active = false;
    },
    async handleReject() {
      this.rejectContentConcept({
        conceptId: this.id,
        documentId: this.focusContentId,
      });
    },
    async handleAccept() {
      this.acceptContentConcept({
        conceptId: this.id,
        documentId: this.focusContentId,
      });
    },
  },
  computed: {
    ...mapGetters('knowledgeModule', [
      'focusContentId',
      'focusContentConcepts',
      'editingLanguage',
    ]),
    showReject() {
      return this.status === 'validated' || this.status === 'auto';
    },
    showAccept() {
      return this.status === 'rejected' || this.status === 'auto';
    },
    displayLabel() {
      return getTranslation(
        {
          translations: this.translations,
          defaultLanguage: this.defaultLanguage,
        },
        [this.editingLanguage],
      ).label;
    },
    ...mapGetters('knowledgeModule', ['focusContent']),
  },
};
</script>

<style scoped lang="scss">
.tag {
  display: flex;
  align-items: center;
  border-radius: 2px;
  font-size: 10px;
  height: 17px;
  border: solid 1px;
  padding: 4px;
}

.auto {
  color: $purple-5-mayday;
  background-color: transparent;

  &:hover {
    background-color: $purple-1-mayday;
  }
}

.validated {
  color: $green-5-mayday;
  background-color: transparent;

  &:hover {
    background-color: $green-1-mayday;
  }
}

.rejected {
  color: $red-5-mayday;
  background-color: transparent;

  &:hover {
    background-color: $red-1-mayday;
  }
}

.tag-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.action-button {
  margin-left: 5px;
  pointer-events: all;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    color: white;
    opacity: 0.5;
  }
}
</style>
