<template>
  <div>
    <el-select
      class="content-filter-select"
      :value="value"
      size="mini"
      @change="$emit('change', $event)"
    >
      <el-option
        v-for="({ label, value }, idx) in options"
        :key="`content-filter-select-${label}-${value}-${idx}`"
        :label="label"
        :value="value"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      validator(options) {
        const isArray = Array.isArray(options);
        const isOptionsValid =
          isArray &&
          options.every(
            (el) => el.hasOwnProperty('label') && el.hasOwnProperty('value'),
          );

        return isArray && isOptionsValid;
      },
      required: true,
    },
    value: {
      type: [String, Boolean],
      required: true,
    },
  },

  model: {
    prop: 'value',
    event: 'change',
  },
};
</script>

<style lang="scss" scoped>
.content-filter-select {
  :deep() .el-input > .el-input__inner {
    height: 30px;
    max-width: 140px;
    background-color: $grey-1-mayday;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #828282;
    border: unset;
  }
}
</style>
