<template>
  <div class="message-editor">
    <mayday-editor
      v-if="showEditor"
      ref="message-editor"
      v-model="content"
      :editable="editable"
      :options="options"
      :placeholder="$t('threads.messages.placeholder')"
      @update="onUpdate"
      @click:open-link="handleOpenLink"
      @click:focus-hash="handleFocusHash"
      @click:inline-content="handleOnClickInlineContent"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'message-editor',
  props: {
    body: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      maydayEditor: null,
      content: '',
      options: {
        fontOptions: true,
        structurationOptions: true,
        tableOptions: false,
        richOptions: false,
        messageOptions: true,
        extraStructurationOptions: false,
        extraFontOptions: true,
        tocClickOptions: false,
        allowToc: false,
        colorOptions: true,
        hideDragHandle: true,
      },
      showEditor: true,
    };
  },
  // this one is for loading editor size
  created() {
    this.content = this.body;
  },
  // this one is for focusing caret
  mounted() {
    this.maydayEditor = this.$refs['message-editor'];

    if (this.autofocus) this.setEditorContent(this.body);
  },
  beforeDestroy() {
    this.showEditor = false;
  },
  methods: {
    handleOpenLink({ href }) {
      window.open(href, '_blank');
    },
    onUpdate(message) {
      this.$emit('input', message === '<p></p>' ? '' : message);
    },
    setEditorContent(message) {
      if (this.maydayEditor) {
        this.maydayEditor.editor.commands.setContent(message);
        this.maydayEditor.editor.commands.focus();
      }
    },
    insertMentions() {
      if (this.maydayEditor) this.maydayEditor.insertMentions();
    },
    insertEmoji() {
      if (this.maydayEditor) this.maydayEditor.insertEmoji();
    },
    focus() {
      if (this.maydayEditor) this.maydayEditor.editor.commands.focus();
    },
    handleFocusHash(hash) {
      if (!hash) return;
      const id = hash.slice(1);

      const uniqueNode = document.querySelector(
        `[data-uid="${id}"], [id="${id}"]`,
      );
      if (!uniqueNode) return;
      uniqueNode.scrollIntoView({ behavior: 'smooth' });
    },
    ...mapActions('knowledgeModule', ['handleOnClickInlineContent']),
  },
  watch: {
    body(newValue) {
      if (!newValue) {
        this.setEditorContent('');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
:deep() p {
  font-size: 12px !important;
  line-height: 14px !important;
  margin: 0 !important;
}

:deep() li {
  font-size: 12px !important;
  line-height: 14px !important;
  margin: 0 !important;
}

:deep() .mayday-editor__content__ProseMirror .alert,
.mayday-editor__content__ProseMirror .box,
.mayday-editor__content__ProseMirror .footnotes,
.mayday-editor__content__ProseMirror .note,
.mayday-editor__content__ProseMirror blockquote,
.mayday-editor__content__ProseMirror ol,
.mayday-editor__content__ProseMirror p,
.mayday-editor__content__ProseMirror pre,
.mayday-editor__content__ProseMirror ul {
  margin: 2px 0 !important;
}

:deep() .mayday-editor__content__ProseMirror code {
  padding: 1px 2px;
  border-radius: 4px;
  margin: 0 1px;
  font-size: 12px !important;
}

:deep() .mayday-editor__inline-content {
  padding: 1px 2px !important;
  font-size: 12px !important;
  line-height: 14px !important;
  margin: 0 !important;
}

:deep() .mayday-editor__inline-content__icon {
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.message-editor {
  padding: 0;
  :deep() .velmld-parent {
    position: unset !important;
    width: 100%;
  }

  :deep() .mayday-editor {
    padding: 0px;
  }
}

:deep() .mayday-editor__content__ProseMirror > * + * {
  margin-top: 2px !important;
}
</style>
