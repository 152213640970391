<template>
  <div class="title-wrapper">
    <slot name="title" :title="title">
      <h3 class="title">
        {{ title }}
      </h3>
    </slot>
    <slot name="title-suffix"></slot>
    <el-tooltip
      v-if="showTooltip"
      class="item"
      effect="dark"
      :content="tooltipContent"
      placement="right"
    >
      <template v-slot:content>
        <slot name="tooltip-content" :content="tooltipContent"></slot>
      </template>
      <font-awesome-icon :icon="['fad', 'info-circle']" class="info-icon" />
    </el-tooltip>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    tooltipContent: {
      type: String,
      required: true,
    },
  },

  computed: {
    showTooltip() {
      return (
        (this.tooltipContent && !!this.tooltipContent.length) ||
        !!this.$scopedSlots['tooltip-content']
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.title-wrapper {
  display: flex;
  align-items: center;

  .title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    margin-bottom: 0;
  }

  .info-icon {
    margin-left: 10px;
    color: $grey-5-mayday;
    &:hover {
      color: $blue-mayday;
    }
  }
}
</style>
