<template>
  <div class="activation-settings-container">
    <div class="item__header d-flex align-items-center justify-content-between">
      <span class="item__title font-bold">{{ integration.name }}</span>
      <el-tooltip
        :content="
          $t(
            'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.integrations.disable',
            {
              name: $t(
                `settings-layout.menu-data.plugins.${pluginTranslationKey}.title`,
              ),
            },
          )
        "
        effect="light"
        :visible-arrow="false"
        :disabled="isPreferenceActive"
        placement="right-end"
      >
        <el-switch
          :disabled="!isPreferenceActive"
          v-model="integration.isActive"
          @change="
            $emit('update-plugin-integration', {
              ...integration,
            })
          "
        ></el-switch>
      </el-tooltip>
    </div>
    <p class="item__description text-sm text-gray">
      {{
        $t(
          'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.integrations.description',
        ) +
        ' ' +
        (integration.name === 'Zendesk App'
          ? $t(
              'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.integrations.zendesk',
            )
          : integration.name)
      }}
    </p>
    <div class="item__status d-flex align-items-center">
      <font-awesome-icon
        :icon="['fal', 'check-circle']"
        :class="['item__icon', { active: integration.isActive }]"
      />
      <div class="item-status-description">
        {{
          $t(
            `settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.integrations.${
              integration.isActive ? 'active' : 'disable'
            }`,
            {
              name: $t(
                `settings-layout.menu-data.plugins.${pluginTranslationKey}.title`,
              ),
            },
          )
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'plugin-activation-settings',
  props: {
    integration: {
      type: Object,
      required: true,
    },
    pluginTranslationKey: {
      type: String,
      required: true,
    },
    isPreferenceActive: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss">
.activation-settings-container {
  width: 100%;
}

.item-status-description {
  margin-left: 4px;
}

.item__icon {
  color: $grey-6-mayday;
}

.item__icon.active {
  color: $blue-5-mayday;
}
</style>
