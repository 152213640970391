<template>
  <div class="content-preview-header">
    <div class="title">
      {{ $t('knowledge.search-admin.sidebar.content-preview.title') }}
    </div>
    <div class="actions">
      <div
        v-if="displayAskButton"
        :class="[
          'action-icon-wrapper',
          showAskItems
            ? 'action-icon-wrapper__shown'
            : 'action-icon-wrapper__hidden',
        ]"
        @click.prevent.stop="$emit('show-ask-items')"
      >
        <font-awesome-icon class="action-icon" :icon="['fas', 'sparkles']" />
      </div>

      <font-awesome-icon
        class="action-icon"
        :icon="['fal', 'times']"
        @click.prevent.stop="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'content-preview-header',
  props: {
    displayAskButton: {
      type: Boolean,
      required: true,
    },
    showAskItems: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.content-preview-header {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background-color: $grey-1-mayday;
  height: 50px;
  width: 100%;
  margin-bottom: 8px;
  .title {
    font-size: 1rem;
    font-weight: 700;
    color: black;
  }
}

.actions {
  display: flex;
  align-items: center;
}

.action-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 16px;
  &:hover {
    cursor: pointer;
  }
  &__hidden {
    background-color: $grey-3-mayday;
    color: black;
  }
  &__hidden:hover {
    background-color: black;
    color: white;
  }
  &__shown {
    background-color: black;
    color: white;
  }
  &__shown:hover {
    background-color: $grey-3-mayday;
    color: black;
  }
}

.action-icon {
  &:hover {
    cursor: pointer;
  }
}
</style>
