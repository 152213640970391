<template>
  <el-popover placement="bottom-end" width="160" v-model="localDisplay">
    <!-- REFERENCE -->
    <button class="reset-button" slot="reference" id="add-group-popover">
      <el-tooltip
        :content="$t('settings.general.kb-manage.actions.action')"
        placement="right"
      >
        <font-awesome-icon :icon="['fal', 'ellipsis-h']" class="option-icon" />
      </el-tooltip>
    </button>
    <!-- DROPDOWN -->
    <div>
      <!-- RENAME ACTION -->
      <not-allowed-tooltip
        :user-permissions="userPermissions"
        permission-key="canUpdateContent"
        placement="top"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            class="dropdown-item px-0 d-flex align-items-center"
            @click="hasPermissions ? editLabel() : ''"
          >
            <h5 class="text-overflow m-0 d-flex align-items-center">
              <font-awesome-icon
                :icon="['fal', 'pen']"
                class="medium-icon ml-2"
              />
              <span class="action-label">
                {{ $t('settings.general.kb-manage.actions.rename') }}</span
              >
            </h5>
          </div>
        </template>
      </not-allowed-tooltip>

      <!-- IS_DEFAULT ACTION -->
      <not-allowed-tooltip
        :user-permissions="{
          canUpdateContent:
            userPermissions.canUpdateContent && hasAccessToDefaultKnowledge,
        }"
        permission-key="canUpdateContent"
        placement="top"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            v-if="displayIsDefault"
            class="dropdown-item px-0 d-flex align-items-center"
            @click="hasPermissions ? updateIsDefault() : ''"
          >
            <h5 class="text-overflow m-0 d-flex align-items-center">
              <font-awesome-icon
                :icon="['fal', 'map-pin']"
                class="medium-icon ml-2"
              />
              <span class="action-label">{{
                $t('settings.general.kb-manage.actions.set-as-default')
              }}</span>
            </h5>
          </div>
        </template>
      </not-allowed-tooltip>

      <!-- UPDATE ICON ACTION -->
      <not-allowed-tooltip
        :user-permissions="userPermissions"
        permission-key="canUpdateContent"
        placement="top"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            v-if="displayModifyIcon"
            class="dropdown-item px-0 align-items-center d-flex"
            @click="hasPermissions ? editIcon() : ''"
          >
            <h5 class="text-overflow m-0 d-flex align-items-center">
              <font-awesome-icon
                :icon="['fal', 'icons']"
                class="medium-icon ml-2"
              />
              <span class="action-label">{{
                $t('settings.general.kb-manage.actions.modify-icon')
              }}</span>
            </h5>
          </div>
        </template>
      </not-allowed-tooltip>

      <el-divider direction="horizontal" class="my-1" />

      <!-- DELETE ACTION -->
      <not-allowed-tooltip
        :user-permissions="userPermissions"
        permission-key="canDeleteContent"
        placement="top"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            class="dropdown-item px-0 align-items-center d-flex"
            @click="hasPermissions ? triggerDelete() : ''"
          >
            <h5 class="text-overflow m-0 d-flex align-items-center">
              <font-awesome-icon
                :icon="['fad', 'trash']"
                class="medium-icon ml-2 text-danger"
              />
              <span class="action-label">{{
                $t('settings.general.kb-manage.actions.delete')
              }}</span>
            </h5>
          </div>
        </template>
      </not-allowed-tooltip>
    </div>
  </el-popover>
</template>

<script>
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

export default {
  name: 'knowledge-manage-settings-row-action',
  components: {
    NotAllowedTooltip,
  },
  props: {
    displayIsDefault: {
      type: Boolean,
      default: true,
    },
    displayModifyIcon: {
      type: Boolean,
      required: true,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
    hasAccessToDefaultKnowledge: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localDisplay: false,
    };
  },
  methods: {
    updateIsDefault() {
      this.localDisplay = false;
      this.$emit('update-is-default');
    },
    editLabel() {
      this.localDisplay = false;
      this.$emit('edit-label');
    },
    editIcon() {
      this.localDisplay = false;
      this.$emit('edit-icon');
    },
    triggerDelete() {
      this.localDisplay = false;
      this.$emit('trigger-delete');
    },
  },
};
</script>
<style lang="scss" scoped>
.reset-button {
  display: flex;
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  width: 24px;
  height: 24px;
}
/* FA ICONS */

.option-icon {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  padding: 2px 4px;
  color: $grey-7-mayday;

  cursor: pointer;
  &:hover {
    background-color: rgba($blue-mayday, 0.1);
    color: $blue-mayday;
  }
}

.medium-icon {
  width: 16px;
  height: 16px;
  padding: 2px;
  color: #4f4f4f;
}

/* END FA ICONS */

.empty-placeholder {
  color: #ced4da;
}

.options_selection {
  max-width: 20px;
  cursor: pointer;
}

.center-element {
  justify-content: center;
  vertical-align: middle;
  display: flex;
}

.dropdown {
  padding: 0px;
  border-width: 0px;
  box-shadow: none;
  background-color: transparent;
  margin-right: 0px;
  margin: 5px;
}

.dropdown:hover {
  background-color: white;
}

.dropdown-item span {
  margin-left: 10px;
}

.col-1 {
  width: 400px;
}
th {
  font-weight: bold;
}

tr {
  align-items: baseline;
}

td {
  color: #525f7f;
  font-size: 13px !important;
}

.table-header {
  background-color: white;
  align-items: baseline;
  height: 40px;
  text-transform: uppercase;
  font-size: 0.65rem;
}

.header-col {
  color: #8898aa;
}

.no-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.action-label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
}

.divided {
  border-top: 1px solid $grey-2-mayday;
}
</style>
