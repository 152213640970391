<template>
  <el-popover placement="top" :width="200" trigger="click">
    <h6 class="text-muted text-uppercase">Général</h6>
    <div class="text-center">
      <div class="class">
        <span v-for="i in icons.General.iconCodes" :key="i[1]">
          <font-awesome-icon
            :icon="i"
            size="2x"
            :class="[
              i[1] === icon ? 'icon-selector--selected' : '',
              'm-1',
              'p-1',
              'icon-selector',
            ]"
            @click="$emit('update-icon', i[1])"
          />
        </span>
      </div>
    </div>

    <hr class="my-2" />
    <h6 class="text-muted text-uppercase">Couleur</h6>
    <v-swatches
      v-model="newColor"
      @input="$emit('update-color', $event)"
      row-length="5"
      :swatch-style="{
        width: '20px',
        height: '20px',
        borderRadius: '40px',
        margin: '5px',
      }"
      :swatches="swatches"
      :inline="true"
    ></v-swatches>
    <template #reference>
      <button
        slot="trigger"
        class="color-selector-button"
        :style="{ borderColor: color }"
      >
        <font-awesome-icon
          :icon="['fad', icon]"
          class="label__icon"
          :style="{ color: color }"
        />
      </button>
    </template>
  </el-popover>
</template>

<script>
import 'vue-swatches/dist/vue-swatches.css';
import VSwatches from 'vue-swatches';

export default {
  name: 'symbol-editor',
  components: {
    VSwatches,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.newColor = this.color;
  },
  data() {
    return {
      icons: {
        General: {
          category: 'General',
          iconCodes: [
            ['fad', 'globe-stand'],
            ['fad', 'phone'],
            ['fad', 'home'],
            ['fad', 'bags-shopping'],
            ['fad', 'camera-home'],
            ['fad', 'stars'],
            ['fad', 'magic'],
            ['fad', 'books'],
            ['fad', 'washer'],
            ['fad', 'map-marker-alt'],
            ['fad', 'users'],
            ['fad', 'medal'],
            ['fad', 'lock-alt'],
            ['fad', 'siren'],
            ['fad', 'wrench'],
          ],
        },
        Commerce: {
          category: 'Analyse & Commerce',
          iconCodes: [
            ['fad', 'shopping-cart'],
            ['fad', 'chart-pie'],
            ['fad', 'chart-area'],
            ['fad', 'abacus'],
            ['fad', 'badge-percent'],
          ],
        },
        Location: {
          category: 'Transport & Localisation',
          iconCodes: [
            ['fad', 'paper-plane'],
            ['fad', 'rocket'],
            ['fad', 'feather-alt'],
            ['fad', 'radar'],
            ['fad', 'globe-stand'],
          ],
        },
        Computer: {
          category: 'Informatique',
          iconCodes: [
            ['fad', 'desktop'],
            ['fad', 'code'],
            ['fad', 'file'],
            ['fad', 'database'],
            ['fad', 'upload'],
          ],
        },
      },
      swatchesCurrentColor: '',
      swatches: [
        '#0081F9',
        '#FCBF49',
        '#FD0044',
        '#8CB369',
        '#7B61FF',
        '#FF619A',
      ],
      newColor: this.color,
    };
  },
  watch: {
    color() {
      this.newColor = this.color;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-selector {
  cursor: pointer;
  width: 28px;
  height: 28px;
  color: $grey-5-mayday;
  border-radius: 2px;
  border: 1px solid transparent;
  &:hover {
    color: $grey-6-mayday;
    background-color: rgba($blue-mayday, 0.1);
    border-color: $blue-mayday;
  }
}

.icon-selector--selected {
  border: 1px solid $blue-mayday;
  border-radius: 4px;
}

.label__span {
  height: 24px;
  width: 24px;
  border-radius: 2px;
  border: 1px solid;
  margin-right: 8px;
  &:hover {
    box-shadow: 0px 5px 15px rgba(112, 144, 176, 0.2);
    cursor: pointer;
  }
}

.label__icon {
  width: 16px;
  height: 16px;
}

.color-selector-button {
  width: 24px;
  height: 24px;
  border: 1px solid transparent;
  border-radius: 2px;
  background: none;
  padding: 0;
  margin-right: 8px;
  background-color: white;
  &:hover {
    box-shadow: 0px 15px 30px $shadow-4-mayday;
  }
}
</style>
