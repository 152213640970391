<template>
  <div class="cursor">
    <div
      :class="[
        isFolder
          ? 'toggle-btn d-flex align-items-center justify-content-center'
          : 'd-flex align-items-center justify-content-center',
        { 'collection-focus-toggle-btn': isFocused && isFolder },
        'cursor',
      ]"
      @click="iconClick"
    >
      <img
        :src="nodeIcon"
        :class="[isFolder ? 'toggle-icon' : 'dropdown_icon']"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'common-node-icon',
  props: {
    nodeType: String,
    showChildren: Boolean,
    isFocused: Boolean,
  },
  computed: {
    isFolder() {
      return !this.nodeType;
    },
    nodeIcon() {
      if (this.isFolder) {
        if (this.showChildren) {
          return this.isFocused
            ? require(`../../../../../../assets/open-folder-focused.svg`)
            : require(`../../../../../../assets/open-folder.svg`);
        }
        return require(`../../../../../../assets/close-folder.svg`);
      }
      return this.nodeType === 'Article'
        ? require(`../../../../../../assets/article-icon-v2.1.svg`)
        : require(`../../../../../../assets/diag-icon-v2.1.svg`);
    },
  },
  methods: {
    iconClick() {
      if (this.isFolder) this.$emit('toggle-children');
      else this.$emit('focus');
    },
  },
};
</script>

<style lang="scss">
.common-navigator-node-icon {
  cursor: pointer;
  display: flex;
  z-index: 100;
}

.toggle-btn {
  height: 24px;
  width: 24px;
  border-radius: 2px;
  margin-right: 4px;
}

.toggle-btn:hover {
  background-color: $grey-1-mayday;
}

.collection-focus-toggle-btn {
  background-color: $grey-1-mayday;
}

.toggle-icon {
  max-width: 20px;
}

.dropdown_icon {
  max-width: 20px;
  margin-right: 5px;
}
</style>
