<template>
  <modal :show.sync="localOpen" :show-close="false" headerClasses="p-0 m-0">
    <ModalHeader
      slot="header"
      :title="$t('knowledge.modals.content-view-setting.main-title')"
      @close="closeModal"
    />

    <form @keyup.enter.prevent.stop @keydown.enter.prevent.stop>
      <GlobalParameterSelector
        v-show="currentStep === 0"
        :init-filters="contentViewSetting.parameters"
        :description="
          $t('knowledge.product.global-parameter-selector-description')
        "
        @update-product-filters="updateProductFilters"
        class="content-view-setting-modal__section"
      />

      <CaseParameterSelector
        v-if="currentStep === 1"
        :knowledge="chosenKnowledge"
        :case-parameters="contentViewSetting.caseParameters"
        @update-case-parameters="updateCaseParameters"
        class="content-view-setting-modal__section"
      >
        <template slot="subtitle">
          <div class="d-flex align-items-center mb-4">
            <font-awesome-icon
              :icon="['fad', 'info-circle']"
              class="info-icon"
            />
            <p class="text-small mb-0">
              Choisissez la place du contenu dans la base de connaissance
            </p>
          </div>
        </template>
        <template slot="main-action"> </template>
      </CaseParameterSelector>

      <IconSelector
        v-if="currentStep === 2"
        :selected-icons="contentViewSetting.labels"
        @update-labels="updateLabels"
        class="content-view-setting-modal__section"
      />

      <GroupSelector
        v-if="currentStep === 3 && isGranularAccessRestrictions"
        :group-ids="contentViewSetting.accessRestrictions"
        :possible-user-groups="userGroups"
        @update-group-ids="updateGroupIds"
        class="content-view-setting-modal__section"
      />
    </form>

    <ContentViewSettingReview
      v-if="currentStep === 4"
      :cases="cases"
      :content-view-setting="contentViewSetting"
      :content-label="content.label"
      class="content-view-setting-modal__section"
    />

    <ContentViewSettingModalFooter
      :steps="steps"
      :current-step="currentStep"
      :step-count="steps.length"
      :is-group-ids-invalid="$v.contentViewSetting.accessRestrictions.$invalid"
      @close="closeModal"
      @go-to-previous-step="goToPreviousStep"
      @go-to-next-step="goToNextStep"
      @update-content-view-setting="updateContentViewSetting"
    />
  </modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import ModalHeader from '../CreateProductNotificationModal/ModalHeader';
import GlobalParameterSelector from '../CreateProductNotificationModal/GlobalParameterSelector';
import CaseParameterSelector from './CaseParameterSelector';
import IconSelector from './IconSelector';
import GroupSelector from '../CreateProductNotificationModal/GroupSelector';
import ContentViewSettingReview from './ContentViewSettingReview/ContentViewSettingReview';
import ContentViewSettingModalFooter from './ContentViewSettingModalFooter';

export default {
  name: 'content-view-setting-modal',
  components: {
    ModalHeader,
    GlobalParameterSelector,
    CaseParameterSelector,
    IconSelector,
    GroupSelector,
    ContentViewSettingReview,
    ContentViewSettingModalFooter,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    content: {
      type: Object,
      required: true,
    },
    contentTitle: {
      type: String,
      // required: true,
    },
  },
  async created() {
    this.contentViewSetting.parameters = [
      { key: 'knowledge', values: [this.content.knowledgeId] },
      ...Object.keys(this.content.parameters).reduce((arr, val) => {
        arr.push({ key: val, values: this.content.parameters[val] });
        return arr;
      }, []),
    ];
    this.contentViewSetting.caseParameters = this.content.caseParameters;
    this.contentViewSetting.labels = this.content.labels;
    this.contentViewSetting.accessRestrictions = this
      .isGranularAccessRestrictions
      ? this.content.accessRestrictions
      : [];
    this.localOpen = this.open;
  },
  computed: {
    chosenKnowledge() {
      const knowledge = this.contentViewSetting.parameters.find(
        (c) => c.key === 'knowledge',
      );
      return knowledge ? knowledge.values[0] : null;
    },
    steps() {
      return this.isGranularAccessRestrictions ? [0, 1, 2, 3, 4] : [0, 1, 2, 4];
    },
    ...mapGetters(['isGranularAccessRestrictions']),
    ...mapState(['userGroups']),
  },
  data() {
    return {
      localOpen: false,
      currentStep: 0,
      contentViewSetting: {
        contentName: this.contentTitle,
        parameters: [],
        caseParameters: [],
        labels: [],
        accessRestrictions: [],
      },
      cases: {},
    };
  },
  validations: {
    contentViewSetting: {
      title: {
        required,
      },
      message: {
        required,
      },
      accessRestrictions: {
        required,
      },
    },
  },
  methods: {
    closeModal() {
      this.localOpen = false;
      this.$emit('close');
    },
    goToPreviousStep() {
      const newStepIndex = this.steps.indexOf(this.currentStep) - 1;
      this.currentStep = this.steps[newStepIndex];
    },
    goToNextStep() {
      const newStepIndex = this.steps.indexOf(this.currentStep) + 1;
      this.currentStep = this.steps[newStepIndex];
    },
    updateProductFilters(productFilters) {
      this.contentViewSetting.parameters = productFilters;
    },
    updateCaseParameters(caseParameters) {
      this.cases = caseParameters.cases;
      this.contentViewSetting.caseParameters = caseParameters.caseSelection;
    },
    updateLabels(labels) {
      this.contentViewSetting.labels = labels;
    },
    updateGroupIds(groupIds) {
      this.contentViewSetting.accessRestrictions = groupIds;
    },
    updateContentViewSetting() {
      const knowledgeSettigng = this.contentViewSetting.parameters.find(
        (c) => c.key === 'knowledge',
      );
      const parameters = this.contentViewSetting.parameters.filter(
        (p) => p.key !== 'knowledge',
      );
      const knowledge = knowledgeSettigng.values[0];
      const newSettings = {
        ...this.contentViewSetting,
        labels: this.contentViewSetting.labels.map((l) => l.id),
        knowledge,
        parameters,
      };
      this.$emit('update-content-view-setting', newSettings);
      this.$message({
        message: 'Les paramètres ont été assignés au contenu',
        type: 'success',
      });
      this.localOpen = false;
    },
  },
  watch: {
    localOpen() {
      if (!this.localOpen) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss">
.case-icon {
  color: $grey-5-mayday;
  margin-right: 8px;
}

.overflow-modal {
  max-height: 80vh !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.content-view-setting-modal__section {
  max-height: 60vh;
  overflow: auto;
}

.info-icon {
  color: $grey-5-mayday;
  margin-right: 8px;
}
</style>
