<template>
  <div class="sidebar-wrapper-docs">
    <div>
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
  </div>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions';

export default {
  components: {
    SlideYUpTransition,
  },
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped></style>
