<template>
  <div class="analytics-usages-table-container">
    <header class="header">
      <AnalyticsTitle
        :title="$t('new-dashboard.header.title.analytics-usages')"
        :tooltip-content="
          $t(
            `new-dashboard.header.tooltip.analytics-usages.${
              viewsFilter ? 'with' : 'without'
            }-views`,
          )
        "
      >
        <template v-slot:title-suffix>
          <ContentFilterSelect
            class="views-filter-select"
            v-model="viewsFilterModel"
            :options="viewsOptions"
          />
        </template>
      </AnalyticsTitle>
      <RemoteSearch
        class="analytics-usages-table-search"
        :placeholder="$t('new-dashboard.header.search-placeholder-usages')"
        :value="searchFilter"
        @change-additional="handleChange"
      />
    </header>
    <LazyCustomTable
      v-loading="isLoading"
      class="analytics-usages-table"
      :data="data"
      :headers="tableHeaders"
      :options="tableOptions"
      :data-count="totalCount"
      :init-data-options="dataOptions"
      :rows-count="[10]"
      :max-height="600"
      @update-data-options="handleUpdateDataOptions"
      @download="handleDownload"
    >
      <template v-slot:empty>
        <EmptyState
          v-if="isEmpty"
          :external-doc-url="externalDocUrl"
          :link-anchor-name="externalDocAnchor"
          @open-filters-drawer="() => $emit('open-filters-drawer')"
        />
      </template>

      <template v-slot:user="{ column: { title, tagAttributes } }">
        <font-awesome-icon
          :icon="['fad', 'user']"
          class="user-icon"
        ></font-awesome-icon>
        <span class="user-name" :class="computeDeletedClass(title)"
          >{{
            title | possiblyDeleted($t('new-dashboard.table.body.no-username'))
          }}
        </span>
        <span
          v-if="tagAttributes && tagAttributes.deletedAt"
          class="user-name"
          style="font-style: italic"
          :class="computeDeletedClass(title)"
          >{{ $t('new-dashboard.table.body.deleted-user') }}
        </span>
      </template>

      <template v-slot:externalId="{ row: { externalId } }">
        <span v-if="externalId" class="user-name">{{ externalId }}</span>
        <span v-else class="text-muted"
          >{{ $t('new-dashboard.table.body.no-external-id') }}
        </span>
      </template>

      <template v-slot:group="{ column: { title }, row: { user } }">
        <font-awesome-icon
          :icon="['fad', 'users']"
          class="user-group-icon"
        ></font-awesome-icon>
        <span
          v-if="title || !(user.tagAttributes && user.tagAttributes.deletedAt)"
          >{{ title }}</span
        >
        <span v-else class="user-name" style="font-style: italic"
          >{{ $t('new-dashboard.table.body.deleted-user-group') }}
        </span>
      </template>

      <template v-slot:labels="{ column: labels, row: { user } }">
        <div v-if="user.tagAttributes && user.tagAttributes.deletedAt">
          <font-awesome-icon :icon="['fad', 'tags']" class="no-label-icon" />
          <span class="text-muted">
            <small>{{
              $t('new-dashboard.table.body.deleted-user-label')
            }}</small>
          </span>
        </div>
        <el-popover
          v-else-if="labels && labels.length !== 0"
          trigger="hover"
          placement="top-end"
        >
          <LabelTag
            class="label-tag"
            v-for="label in labels"
            :key="label.id"
            :name="label.title"
            :color="label.tagAttributes.color"
            :icon="label.tagAttributes.icon"
          />
          <template #reference>
            <span>
              <LabelTag
                class="label-tag"
                v-for="label in labels"
                :key="label.id"
                :name="label.title"
                :color="label.tagAttributes.color"
                :icon="label.tagAttributes.icon"
              />
            </span>
          </template>
        </el-popover>
        <div v-else>
          <font-awesome-icon :icon="['fad', 'tags']" class="no-label-icon" />
          <span class="text-muted">
            <small>{{ $t('new-dashboard.table.body.no-label') }}</small>
          </span>
        </div>
      </template>

      <template v-slot:views="{ column: { count, trend }, row: { languages } }">
        <el-popover
          trigger="hover"
          placement="right"
          :disabled="shouldDisableLanguagesPopover(languages)"
        >
          <LanguagesCounts
            v-if="!shouldDisableLanguagesPopover(languages)"
            :languagesCounts="languages"
          />
          <template #reference>
            <div class="view-container">
              <font-awesome-icon
                :icon="['fal', 'arrow-right']"
                class="views-icon"
                :class="trend"
              />
              <span>{{ count }}</span>
            </div>
          </template>
        </el-popover>
      </template>

      <template v-slot:notifications="{ column: count }">
        <span>{{ count | percentage(2) }}</span>
      </template>

      <template v-slot:contributions="{ column: count }">
        <template v-if="count > 0">{{ count }}</template>
        <template v-else>
          <span class="no-feedback">
            <font-awesome-icon
              :icon="['fad', 'money-check-edit']"
              class="no-feedback-icon"
            ></font-awesome-icon>
            <span>{{ $t('new-dashboard.table.body.no-contributions') }}</span>
          </span>
        </template>
      </template>

      <template v-slot:userReturns="{ column: count }">
        <template v-if="count > 0">{{ count }}</template>
        <template v-else>
          <span class="no-feedback">
            <font-awesome-icon
              :icon="['fad', 'megaphone']"
              class="no-feedback-icon"
            ></font-awesome-icon>
            <span>{{ $t('new-dashboard.table.body.no-feedbacks') }}</span>
          </span>
        </template>
      </template>

      <template
        v-slot:feedbacks="{ column: { count, averageRating, ratingCount } }"
      >
        <template v-if="count > 0">
          {{ count }}
          <el-tooltip
            v-if="averageRating && ratingCount"
            placement="top"
            :content="
              $tc('new-dashboard.table.body.rated-feedbacks', ratingCount, {
                ratingCount: ratingCount,
              })
            "
          >
            <span class="rating" v-if="averageRating">
              |
              <font-awesome-icon
                :icon="['fas', 'star']"
                class="rating-icon"
              ></font-awesome-icon>
              {{ averageRating }}
            </span>
          </el-tooltip>
        </template>
        <template v-else>
          <span class="no-feedback">
            <font-awesome-icon
              :icon="['fad', 'megaphone']"
              class="no-feedback-icon"
            ></font-awesome-icon>
            <span>{{ $t('new-dashboard.table.body.no-feedbacks') }}</span>
          </span>
        </template>
      </template>

      <template v-slot:options="{ row }">
        <font-awesome-icon
          :icon="['fad', 'file-search']"
          class="analyze-icon"
          @click="() => handleShowDetails(row)"
        ></font-awesome-icon>
      </template>
    </LazyCustomTable>
  </div>
</template>

<script>
import LazyCustomTable from '@/components/Tables/LazyCustomTable';
import AnalyticsTitle from '../AnalyticsTitle';
import ContentFilterSelect from '../AnalyticsContents/ContentFilterSelect.vue';
import RemoteSearch from '@/components/Filters/Filter/RemoteSearch';
import EmptyState from '../EmptyState';
import { EXTERNAL_DOC_ANCHORS } from '@/store/dashboard/constants';
import { mapGetters } from 'vuex';
import LabelTag from '@/components/Settings/LabelTag';
import LanguagesCounts from '../LanguagesCounts';

export default {
  components: {
    LazyCustomTable,
    AnalyticsTitle,
    ContentFilterSelect,
    RemoteSearch,
    EmptyState,
    LabelTag,
    LanguagesCounts,
  },
  props: {
    viewsFilter: {
      type: Boolean,
      required: true,
    },
    searchFilter: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
    dataOptions: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    externalDocUrl: {
      type: String,
      required: true,
    },
    download: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.tableHeadersBase = [
      {
        prop: 'user',
        path: 'user.title',
        key: 'username',
        label: this.$t('new-dashboard.table.header.analytics-usages.user'),
        sortable: true,
        template: true,
        fixed: true,
      },
      {
        prop: 'group',
        path: 'group.title',
        key: 'group',
        label: this.$t('new-dashboard.table.header.analytics-usages.group'),
        template: true,
      },
      {
        prop: 'labels',
        // path: 'group.title',
        key: 'labels',
        width: 200,
        label: this.$t('new-dashboard.table.header.analytics-usages.labels'),
        template: true,
        className: 'labels',
      },
      {
        prop: 'views',
        key: 'total_count',
        label: this.$t('new-dashboard.table.header.analytics-usages.views'),
        sortable: true,
        template: true,
      },
      {
        prop: 'notifications',
        key: 'notification',
        label: this.$t(
          'new-dashboard.table.header.analytics-usages.notifications',
        ),
        sortable: true,
        template: true,
      },
      ...(this.hasContributions
        ? [
            {
              prop: 'userReturns',
              key: 'user_return_count',
              label: this.$t(
                'new-dashboard.table.header.analytics-usages.user-returns',
              ),
              sortable: true,
              template: true,
            },
            {
              prop: 'contributions',
              key: 'contribution_count',
              label: this.$t(
                'new-dashboard.table.header.analytics-usages.contributions',
              ),
              sortable: true,
              template: true,
            },
          ]
        : [
            {
              prop: 'feedbacks',
              key: 'total_feedback_count',
              label: this.$t(
                'new-dashboard.table.header.analytics-usages.feedbacks',
              ),
              sortable: true,
              template: true,
            },
            {
              prop: 'externalId',
              path: 'externalId',
              key: 'externalId',
              label: this.$t(
                'new-dashboard.table.header.analytics-usages.external-id',
              ),
              template: true,
            },
          ]),
    ];
  },
  data() {
    return {
      viewsOptions: [
        {
          label: this.$t(
            'new-dashboard.filter-type.analytics-usage.with-usage',
          ),
          value: true,
        },
        {
          label: this.$t(
            'new-dashboard.filter-type.analytics-usage.without-usage',
          ),
          value: false,
        },
      ],
      tableHeadersBase: [],
      externalDocAnchor: EXTERNAL_DOC_ANCHORS.usageTable,
    };
  },
  computed: {
    ...mapGetters(['hasContributions']),
    viewsFilterModel: {
      get() {
        return this.viewsFilter;
      },
      set(value) {
        this.$emit('update:viewsFilter', value);
        return value;
      },
    },
    tableHeaders() {
      return this.viewsFilter
        ? [
            ...this.tableHeadersBase,
            {
              prop: 'options',
              label: this.$t(
                'new-dashboard.table.header.analytics-usages.options',
              ),
              template: true,
            },
          ]
        : this.tableHeadersBase;
    },
    tableOptions() {
      return {
        search: false,
        showItemCount: true,
        showRowsCount: false,
        bulkActions: false,
        pagination: true,
        expandableRows: false,
        download: !this.isEmpty,
      };
    },
  },

  methods: {
    shouldDisableLanguagesPopover(languages) {
      return !languages || !Object.keys(languages).length;
    },
    handleChange(event) {
      this.$emit('update:searchFilter', event.value);
    },
    handleUpdateDataOptions(options) {
      this.$emit('update:dataOptions', options);
    },

    handleShowDetails(row) {
      const {
        user: { id },
      } = row;
      this.$emit('show-details', id);
    },

    handleDownload() {
      this.download('usage');
    },

    computeDeletedClass(title) {
      return title == null || !title.length ? 'deleted' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.view-container {
  width: fit-content;
  padding-right: 8px;
}
.label-tag {
  margin-right: 4px;
}
.analytics-usages-table-container {
  .header {
    display: flex;
  }

  .views-filter-select {
    margin-left: 10px;

    :deep() .content-filter-select {
      .el-input > .el-input__inner {
        max-width: 160px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: $grey-9-mayday;
      }

      .el-input .el-select__caret {
        color: $grey-9-mayday;
      }
    }
  }

  .analytics-usages-table-search {
    margin-left: auto;
    width: 320px;
    height: 40px;

    :deep() .el-input__prefix {
      padding-left: 5px;
      .search-icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .analytics-usages-table {
    margin-top: 20px;
    .cell {
      .content-icon,
      .collection-icon,
      .views-icon,
      .no-feedback-icon,
      .no-label-icon,
      .article-icon,
      .diagnostic-icon {
        margin-right: 10px;
        width: 16px;
        height: 16px;
        vertical-align: text-bottom;
      }

      .rating {
        display: inline-flex;
        align-items: center;
        color: $grey-5-mayday;
        font-size: 12px;
        gap: 2px;

        .rating-icon {
          width: 10px;
          height: 10px;
          color: $yellow-mayday;
        }
      }

      .analyze-icon {
        color: $grey-5-mayday;
        &:hover {
          color: $blue-mayday;
          cursor: pointer;
        }
      }

      .user-icon {
        max-width: 16px;
        color: $grey-5-mayday;
        margin-right: 8px;
      }

      .user-group-icon {
        max-width: 16px;
        color: $grey-5-mayday;
        margin-right: 8px;
      }

      .views-icon {
        &.flat {
          color: rgba($yellow-mayday, 0.8);
        }
        &.up {
          transform: rotate(-45deg);
          color: rgba($green-mayday, 0.8);
        }
        &.down {
          transform: rotate(45deg);
          color: rgba($red-mayday, 0.8);
        }
      }

      .user-name.deleted {
        color: $grey-5-mayday;
        font-style: italic;
      }

      .no-feedback {
        color: $grey-5-mayday;
      }
    }

    :deep() .labels > .cell {
      white-space: nowrap;
    }
  }
}
</style>
