var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"toast"},[_c('div',{staticClass:"toast__header"},[_c('div',{staticClass:"toast__header__title"},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'globe-stand']}}),_vm._v(" "+_vm._s(_vm.$t('translation.loading-toast.title'))+" "),_c('AiBadge',{attrs:{"icon":false}})],1),(!_vm.targetLanguageIsEditingLanguage)?_c('font-awesome-icon',{staticClass:"close",attrs:{"icon":['fal', 'times']},on:{"click":function($event){return _vm.$emit('quit-translation-toast', {
          targetLanguage: _vm.localTargetLanguage,
        })}}}):_vm._e()],1),_c('div',{staticClass:"toast__body",class:`toast__body__${_vm.status}`},[(_vm.isLoadingState)?_c('div',{staticClass:"toast__body__pending"},[_c('font-awesome-icon',{staticClass:"loading-icon",attrs:{"icon":['fal', 'spinner-third'],"spin":""}}),_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.sanitize(
            _vm.$t('translation.loading-toast.pending', {
              targetLanguage: _vm.$t(
                `knowledge.languages.${_vm.localTargetLanguage}`,
              ),
            }),
          )
        )}})],1):_vm._e(),(_vm.status === 'done')?_c('div',{staticClass:"toast__body__done"},[_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.sanitize(
            _vm.$t('translation.loading-toast.done', {
              targetLanguage: _vm.$t(
                `knowledge.languages.${_vm.localTargetLanguage}`,
              ),
            }),
          )
        )}}),_c('div',{staticClass:"button-wrapper"},[_c('div',{staticClass:"button",on:{"click":function($event){return _vm.handleGoToNewlyCreatedVersion()}}},[_vm._v(" "+_vm._s(_vm.$t('translation.loading-toast.done-button'))+" ")])])]):_vm._e(),(_vm.status === 'failed')?_c('div',{staticClass:"toast__body__failed"},[_c('div',{staticClass:"failed-title"},[_vm._v(" "+_vm._s(_vm.$t('translation.loading-toast.failed-title'))+" ")]),_c('div',{staticClass:"failed-description",domProps:{"innerHTML":_vm._s(
          _vm.sanitize(
            _vm.$t('translation.loading-toast.failed-description', {
              targetLanguage: _vm.$t(
                `knowledge.languages.${_vm.localTargetLanguage}`,
              ),
            }),
          )
        )}}),_c('div',{staticClass:"button-wrapper"},[_c('div',{staticClass:"button",on:{"click":function($event){return _vm.$emit('translation-try-again', {
              sourceLanguage: _vm.sourceLanguage,
              targetLanguage: _vm.localTargetLanguage,
              triggerSource: _vm.triggerSource,
            })}}},[_vm._v(" "+_vm._s(_vm.$t('translation.loading-toast.failed-button'))+" ")])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }