<template>
  <card class="product-viewer-item-card">
    <!-- CARDE HEADER -->
    <template slot="header">
      <div class="d-flex justify-content-between">
        <div class="card-header-title">
          {{ cardTitle }}
        </div>
        <div
          class="toggle-icon d-flex align-items-center justify-content-center p-0"
          v-if="isCollapsable"
        >
          <font-awesome-icon
            @click="collapsed = !collapsed"
            :class="{ 'is-collapsed': collapsed }"
            :icon="[
              'fa',
              collapsed ? 'caret-square-right' : 'caret-square-down',
            ]"
          />
        </div>
      </div>
    </template>

    <!-- CARDE BODY -->
    <div class="card-main" v-show="!collapsed">
      <slot></slot>
    </div>
  </card>
</template>

<script>
export default {
  name: 'product-viewer-item-card',
  props: {
    cardTitle: {
      type: String,
    },
    isCollapsable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapsed: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.card {
  border: 1px solid $grey-4-mayday;
}
.product-viewer-item-card {
  :deep() .card-header {
    padding: 16px;
    background-color: $grey-1-mayday;
    text-transform: uppercase;
    border-bottom: 0px;
    .toggle-icon {
      cursor: pointer;
      color: $blue-mayday;
      .is-collapsed {
        color: $grey-5-mayday;
      }
    }
  }
  :deep() .card-body {
    padding: 0px;
    .card-main {
      padding: 16px;
    }
  }
}
</style>
