<template>
  <div>
    <el-popover placement="top" trigger="manual" v-model="showPopover">
      <section class="text-center" v-if="disableSelect">
        <small>
          ⚠️
          <br />
          <span>{{ $t('knowledge.modals.clone-kb.popover-problem') }}</span>
          <br />
          <span>{{ $t('knowledge.modals.clone-kb.popover-solution') }}</span>
        </small>
      </section>
      <div
        slot="reference"
        class="cascader-container"
        @mouseenter="triggerPopover(true)"
        @mouseleave="triggerPopover(false)"
      >
        <div
          v-if="isInit"
          class="d-flex align-items-center justify-content-between cascader-header is-init"
        >
          <div>{{ $t('knowledge.modals.clone-kb.cascader-label') }}</div>
          <font-awesome-icon
            class="icon"
            :icon="['fas', 'times']"
            @click.prevent="$emit('close')"
          />
        </div>
        <div v-else class="d-flex align-items-center cascader-header">
          <font-awesome-icon
            class="icon"
            :icon="['fal', 'arrow-left']"
            @click.prevent="prev()"
          />
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div class="pl-2">
              {{ hasChildren ? label : focusedOption.label }}
            </div>
            <font-awesome-icon
              class="icon grey-6"
              :icon="['fas', 'times']"
              @click.prevent="$emit('close')"
            />
          </div>
        </div>
        <div class="overflow">
          <div v-if="hasChildren">
            <div
              class="d-flex align-items-center option"
              :class="{
                'is-focus':
                  focusedOption &&
                  focusedOption.id == option.id &&
                  !option.disabled,
                'is-disabled ': disableSelect || option.disabled,
              }"
              v-for="option in options"
              :key="option.id"
              @mouseenter="hoveredOptionId = option.id"
              @mouseleave="hoveredOptionId = null"
              @click.prevent="focusOption(option)"
            >
              <font-awesome-icon
                :icon="getOptionIcon(option.caseId)"
                :class="
                  !disableSelect &&
                  (hoveredOptionId == option.id ||
                    (focusedOption && focusedOption.id == option.id)) &&
                  !option.disabled
                    ? 'blue'
                    : 'grey-5'
                "
              />
              <div
                class="w-100 d-flex align-items-center justify-content-between"
              >
                <div class="pl-2">{{ option.label }}</div>
                <div
                  v-if="!option.disabled"
                  class="option-children-selector"
                  @click.prevent.stop="next(option)"
                >
                  <font-awesome-icon
                    v-if="hoveredOptionId == option.id"
                    class="icon blue"
                    :icon="['fas', 'chevron-right']"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="instruction" v-else>
            {{ $t('knowledge.modals.clone-kb.cascader-instruction') }}
          </div>
        </div>
        <div class="d-flex justify-content-end cascader-footer">
          <ModalActions
            :primaryLabel="'knowledge.modals.clone-kb.select'"
            :primaryEnabled="cloneValidation"
            @primary="$emit('select-target', focusedOption)"
          />
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import ModalActions from '@/components/Modals/ModalActions';
export default {
  name: 'base-cascader',
  components: {
    ModalActions,
  },
  props: {
    label: {
      type: String,
      default: 'Cascader',
    },
    disableSelect: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '1', //String
            caseId: null, //String
            knowledge: 'knowledge1', //String
            label: 'Ma base', //String
            hasChildren: true, //Boolean
          },
        ];
      },
    },
  },
  data() {
    return {
      showPopover: false,
      hoveredOptionId: null,
      focusedOption: null,
      hasChildren: true,
    };
  },
  computed: {
    isInit() {
      return !this.options[0].caseId;
    },
    cloneValidation() {
      return (
        (this.focusedOption !== null || !this.hasChildren) &&
        !this.focusedOption.disabled
      );
    },
  },
  methods: {
    triggerPopover(value) {
      this.showPopover = this.disableSelect && value;
    },
    focusOption(option) {
      if (this.disableSelect) return;
      this.focusedOption = option;
    },
    getOptionIcon(caseId) {
      return caseId ? ['fas', 'folder'] : ['fad', 'books'];
    },
    prev() {
      if (!this.hasChildren) {
        this.hasChildren = true;
      } else {
        this.focusedOption = null;
        this.$emit('prev');
      }
    },
    next(option) {
      if (option.hasChildren) {
        this.focusedOption = null;
        this.$emit('next', option);
      } else {
        this.focusedOption = option;
        this.hasChildren = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cascader-container {
  width: 100%;
  background: white;
  border: 1px solid $grey-4-mayday;
  border-radius: 2px;
  color: $grey-8-mayday;
  font-weight: bold;
}
.cascader-header {
  padding: 14px 10px;
  background-color: $grey-1-mayday;

  &.is-init {
    color: $grey-6-mayday;
  }
}
.option {
  padding: 12px 10px;
  margin: 1px 0px;
  cursor: pointer;

  &.is-focus {
    color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.4);
  }
}
.option:not(.is-disabled):hover {
  color: $blue-mayday !important;
  background-color: $grey-1-mayday;
}
.icon {
  width: 16px;
  height: 16px;
  cursor: pointer;

  &__selector {
    border: 1px solid $blue-mayday;
    border-radius: 1px;
  }
}
.grey-6 {
  color: $grey-6-mayday;
}
.grey-5 {
  color: $grey-5-mayday;
}
.blue {
  color: $blue-mayday;
}
.red {
  color: $red-mayday;
}
.cascader-footer {
  padding: 12px;
}
.instruction {
  padding: 12px 14px;
  font-weight: bold;
}
.overflow {
  height: 15vh;
  max-height: 150px !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
.is-disabled {
  cursor: not-allowed;
}
</style>
