<template>
  <div class="container">
    <div class="title">{{ $t(permissionItem.title) }}</div>
    <RolePermissionsState
      :sub-items="permissionItem.subItems"
      :role-permissions="permissions"
      :company-preferences="companyPreferences"
    />
  </div>
</template>

<script>
import RolePermissionsState from './RolePermissionsState';
export default {
  name: 'role-permissions-overview-item',
  components: { RolePermissionsState },
  props: {
    permissionItem: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Array,
      default: () => [],
    },
    companyPreferences: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding-inline: 0px;
}
.title {
  font-weight: 700;
  border-bottom: 1px solid $grey-4-mayday;
  width: 100%;
  margin-bottom: 8px;
}
</style>
