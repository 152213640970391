<template>
  <div>
    <div class="translation-container">
      <TranslationDropdown
        v-if="focusKnowledgeIsMultilinguale"
        class="ml-4"
        :set-languages="caseParameter.setLanguages"
        :label-translations="caseParameter.labelTranslations"
        :editable="!isArchive"
        @set-new-language="$emit('open-set-language-modal', $event)"
        @unset-language="$emit('unset-language', $event)"
        :rightDirectionDropdown="true"
      />
    </div>
    <header class="case-viewer-header col-12 pr-0 mb-4">
      <not-allowed-tooltip
        :user-permissions="userPermissions"
        :disabled="true"
        permission-key="canUpdateContent"
        placement="top"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <CaseViewerHeaderTitle
            :title="caseParameter.label"
            :editable="hasPermissions"
            @update-title="updateCase"
          />
        </template>
      </not-allowed-tooltip>
      <div class="d-flex align-items-center">
        <CaseViewerHeaderCount
          :case-count="caseParameter.children.length"
          :article-count="typedCounts['Article']"
          :diag-count="typedCounts['Diagnostic']"
        />
      </div>
      <not-allowed-tooltip
        v-if="!isParametric"
        :user-permissions="userPermissions"
        :disabled="true"
        permission-key="canUpdateContent"
        placement="top"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <CaseViewerHeaderDescription
            :body="caseParameter.body"
            :editable="hasPermissions"
            @update-body="updateCase(null, $event)"
            @remove-body="removeBodyModal"
          />
        </template>
      </not-allowed-tooltip>
    </header>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CaseViewerHeaderTitle from './CaseViewerHeaderTitle';
import CaseViewerHeaderDescription from './CaseViewerHeaderDescription';
import CaseViewerHeaderCount from './CaseViewerHeaderCount';
import TranslationDropdown from '@/components/TranslationDropdownWrapper';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

export default {
  name: 'case-viewer-header',
  props: {
    caseParameter: {
      type: Object,
      required: true,
    },
    typedCounts: {
      type: Object,
      required: true,
    },
    userPermissions: {
      type: Object,
      default: () => {},
    },
    isArchive: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    CaseViewerHeaderTitle,
    CaseViewerHeaderDescription,
    CaseViewerHeaderCount,
    TranslationDropdown,
    NotAllowedTooltip,
  },
  computed: {
    availableLanguages() {
      return this.focusKnowledgeLanguages.filter(
        (lang) => !this.caseParameter.setLanguages.includes(lang),
      );
    },
    removeCaseBodyUniqueKey() {
      return `remove-body-${this.caseParameter.id}`;
    },
    ...mapGetters(['isParametric']),
    ...mapGetters('knowledgeModule', [
      'focusKnowledgeLanguages',
      'editingLanguage',
      'focusKnowledgeIsMultilinguale',
    ]),
  },
  methods: {
    removeBodyModal() {
      const payload = {
        caseId: this.caseParameter.id,
        label: this.caseParameter.label,
        body: '',
        childrenOrder: this.caseParameter.childrenOrder,
      };
      this.openModal({
        component: 'DeleteCaseBodyModal',
        uid: this.removeCaseBodyUniqueKey,
        props: { display: true, payload },
      });
    },
    updateCase(newLabel, newBody) {
      this.$emit('update-case', {
        caseId: this.caseParameter.id,
        label: newLabel || this.caseParameter.label,
        body: newBody || this.caseParameter.body,
        childrenOrder: this.caseParameter.childrenOrder,
      });
    },
    ...mapActions('modalsModule', ['openModal']),
  },
};
</script>

<style lang="scss" scoped>
.translation-container {
  margin-left: -4px;
  margin-bottom: 16px;
}
.case-viewer-header {
  border-left: 3px solid $grey-4-mayday;
}
</style>
