<template>
  <div>
    <img v-if="src" :src="src" class="reduce-navigator-icon" />
    <font-awesome-icon
      v-else-if="faIcon"
      :icon="['fad', faIcon]"
      class="reduce-navigator-icon"
    />
  </div>
</template>

<script>
export default {
  name: 'route-type-icon',
  props: {
    src: String,
    faIcon: String,
  },
};
</script>

<style lang="scss" scoped>
.reduce-navigator-icon {
  width: 100%;
  font-size: 2em;
  color: $blue-mayday;
}
</style>
