<template>
  <div>
    <div v-for="role in paginatedData" :key="role.id">
      <settings-dropdown>
        <template slot="title">
          <settings-dropdown-capitalized-column
            class="role-name"
            :text="role.name"
            :is-default="!role.isCustom"
          >
            <template v-if="!role.isCustom" slot="badge">
              <el-badge class="col-auto default-tag" type="primary" size="mini">
                {{ $t('settings.permissions.role-table.default') }}
              </el-badge>
            </template>
          </settings-dropdown-capitalized-column>
          <div class="members-count">
            {{ role.usersCount }}
            {{ $t('settings.permissions.role-table.members') }}
          </div>
          <div class="options">
            <RoleTableRowOption
              v-if="role.isCustom"
              :can-delete="!role.usersCount && !role.hasPendingUsers"
              @edit-role="$emit('edit-role', role.id)"
              @delete-role="openDeleteModal(role.id)"
            />
            <el-tooltip
              v-else
              :content="
                $t(
                  'settings.permissions.role-table.can-not-modify-primary-roles',
                )
              "
              placement="top"
              class="group-tooltip"
            >
              <font-awesome-icon
                :icon="['far', 'ellipsis-h']"
                class="options_selection"
              />
            </el-tooltip>
          </div>
        </template>
        <template slot="dropdown-content">
          <RolePermissionsOverview
            :permissions="role.customRolePermissions"
            :company-preferences="companyPreferences"
          />
        </template>
      </settings-dropdown>
    </div>
    <el-pagination
      class="d-flex justify-content-center mt-4"
      background
      layout="prev, pager, next"
      :total="companyRoles.length"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      hide-on-single-page
    />
    <ModalEvents
      modal-name="DeleteContentModal"
      :uid="deleteModalUniqueKey"
      @choice="deleteRole"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SettingsDropdown from '@/components/Settings/SettingsDropdown';
import ModalEvents from '@/components/Modals/ModalEvents';
import SettingsDropdownCapitalizedColumn from '@/components/Settings/SettingsDropdownCapitalizedColumn';
import RolePermissionsOverview from './RolePermissionsOverview';
import RoleTableRowOption from './RoleTableRowOption';

export default {
  name: 'roles-table',
  components: {
    SettingsDropdown,
    SettingsDropdownCapitalizedColumn,
    RolePermissionsOverview,
    RoleTableRowOption,
    ModalEvents,
  },
  props: {
    companyRoles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      deletingRoleId: '',
      pageSize: 5,
      currentPage: 1,
    };
  },
  computed: {
    deleteModalUniqueKey() {
      return this.$options.name;
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.companyRoles.slice(start, end);
    },
    ...mapState(['companyPreferences']),
  },
  methods: {
    openDeleteModal(roleId) {
      this.deletingRoleId = roleId;
      this.openModal({
        component: 'DeleteContentModal',
        uid: this.deleteModalUniqueKey,
        props: { display: true, id: roleId, deleteType: 'customRole' },
      });
    },
    deleteRole(choice) {
      if (choice) this.$emit('delete-role', this.deletingRoleId);
      this.deletingRoleId = '';
    },
    ...mapActions('modalsModule', ['openModal']),
  },
};
</script>
<style lang="scss" scoped>
.options_selection {
  max-width: 15px;
  color: $grey-6-mayday;
  &:hover {
    cursor: not-allowed;
  }
}

.default-tag {
  background-color: rgba($blue-mayday, 0.1);
  border: 1px solid $blue-mayday;
  box-sizing: border-box;
  border-radius: 2px;
  color: $blue-mayday;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.5px;
}

.role-name {
  width: 250px;
}

.members-count {
  font-size: 14px;
  font-weight: 500;
  width: 100px;
}

.options {
  text-align: center;
  width: 70px;
}
</style>
