import gql from 'graphql-tag'

export const updateGroupMutation = gql`
mutation updateGroupMutation($id: ID!, $alias: String!, $rules: String!) {
    updateGroup(id: $id, alias: $alias, rules: $rules)
}
`

export const deleteGroupMutation = gql`
mutation deleteGroupMutation($id: ID!) {
    deleteGroup(id: $id)
}
`
