<template>
  <section class="content__result">
    <ul class="content__list">
      <li class="list-item" v-for="result in results" :key="result.id">
        <ContentListItem
          v-if="result.__typename === 'Content'"
          :content="result"
          :selected-contents="selectedContents"
          :item-type="itemType"
          :show-bulk-actions="showBulkActions"
          :is-read-only="isReadOnly"
          :labelSearchedIds="labelSearchedIds"
          :searchLanguage="searchLanguage"
          :popoverToDisplay="popoverToDisplay"
          @open-content="openDocument"
          @archive="$emit('archive', $event)"
          @update-selected-contents="$emit('update-selected-contents', $event)"
          @restore="$emit('restore', $event)"
          @delete="$emit('delete', $event)"
          @update-content="updateContent"
          @update-content-verification="updateContentVerification"
          @update-content-settings="
            $emit(
              'update-content-settings',
              Object.assign({}, $event, { id: result.id }),
            )
          "
          @display-explain-popover="handleDisplayPopover"
        />
        <ProductListItem
          v-else-if="result.__typename === 'Product'"
          :product="result"
          :extra-padding="showBulkActions"
          @open-product="openDocument"
        />
        <CaseListItem
          v-else-if="result.__typename === 'Case'"
          :collection="result"
          :extra-padding="showBulkActions"
          @open-case="openDocument"
        />
      </li>
    </ul>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import ContentListItem from './ContentListItem.vue';
import ProductListItem from './ProductListItem.vue';
import CaseListItem from './CaseListItem.vue';

export default {
  name: 'results-list',
  data() {
    return { popoverToDisplay: '' };
  },
  props: {
    results: Array,
    selectedContents: Array,
    itemType: {
      type: String,
      default: 'content',
    },
    showBulkActions: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    labelSearchedIds: { type: Array, default: () => [] },
    searchLanguage: {
      type: String,
      default: 'default',
    },
  },
  components: {
    ContentListItem,
    ProductListItem,
    CaseListItem,
  },

  methods: {
    openDocument(document) {
      this.$emit('open-document', document);
    },
    async updateContent({ id, payload }) {
      const mutationPayload = {
        contentIds: [id],
        key: 'published',
        value: payload.published,
      };
      await this.bulkUpdateStatus(mutationPayload);
      this.$emit('bulk-update-status', mutationPayload);
    },
    async updateContentVerification({ id, isOutdated }) {
      const payload = {
        contentIds: [id],
        key: 'isOutdated',
        value: isOutdated,
      };
      await this.bulkUpdateStatus(payload);
      this.$emit('bulk-update-status', payload);
    },
    handleDisplayPopover(id) {
      if (id === this.popoverToDisplay) this.popoverToDisplay = '';
      else this.popoverToDisplay = id;
    },
    ...mapActions('knowledgeModule', ['bulkUpdateStatus']),
  },
};
</script>

<style lang="scss" scoped>
.content__list {
  padding-inline-start: 0px;
}

.list-item {
  list-style: none;
  margin: 0px;
}
</style>
