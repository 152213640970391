<template>
  <div :class="['notification__popover-trigger', active ? 'active' : '']">
    <el-badge v-if="unreadCount" :value="unreadCount">
      <div class="notification__popover-bell">
        <font-awesome-icon :icon="['fas', 'bell']" />
      </div>
    </el-badge>
    <div v-else class="notification__popover-bell">
      <font-awesome-icon :icon="['fas', 'bell']" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'notification-web-trigger',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    unreadCount: {
      type: String,
      required: false,
    },
  },
  components: {},
  computed: {
    active() {
      return this.visible ? 'active' : '';
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep() .el-badge__content {
  background-color: $red-mayday;
}

.notification__popover-bell {
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    width: 22px;
    height: 22px;
  }
}

.notification__popover-trigger {
  color: white;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff1a;

  :deep() .el-badge__content.is-fixed {
    right: 5px;
    top: -5px;
  }

  &:hover {
    background: white;
    .notification__popover-bell {
      color: $blue-mayday;
    }
  }
}

.active {
  background: white;
  .notification__popover-bell {
    color: $blue-mayday;
  }
}
</style>
