<template>
  <div class="tree-navigator" v-if="focusKnowledge">
    <NavigatorHeader
      :label="focusKnowledgeLabel"
      :user-permissions="rootNode.userPermissions"
      @focus="focusRoot"
      @add-child="addChild"
    />
    <not-allowed-tooltip
      v-if="hasChildren"
      :user-permissions="rootNode.userPermissions"
      :disabled="true"
      permission-key="canUpdateContent"
      placement="top"
    >
      <template v-slot:main-content="{ hasPermissions }">
        <NavigatorNode
          v-loading="navigatorNodeIsLoading"
          ref="navNode"
          :node="rootNode"
          :parent-ids="[rootNode.id]"
          :is-first="true"
          :focus-path="focusPath"
          :draggable="hasPermissions"
          @component-focused="handleChildComponentFocused"
        />
      </template>
    </not-allowed-tooltip>
    <div v-else class="empty-loader" v-loading="true"></div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import NavigatorNode from './NavigatorNode/NavigatorNode';
import NavigatorHeader from './NavigatorHeader/NavigatorHeader';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';
import UtilsKnowledgeMixin from 'mixins/KnowledgeMixin/UtilsKnowledgeMixin';

export default {
  mixins: [UtilsKnowledgeMixin],
  components: { NavigatorHeader, NavigatorNode, NotAllowedTooltip },
  name: 'navigator',
  computed: {
    rootNode() {
      return this.getNavigatorLookupMapKnowledge(this.focusKnowledge.id);
    },
    hasChildren() {
      return this.rootNode.childrenOrder.length > 0;
    },
    // ...mapGetters('kbStore', ['casePath']),
    ...mapGetters('knowledgeModule', [
      'focusKnowledge',
      'focusKnowledgeLabel',
      'getNavigatorLookupMapKnowledge',
      'navigatorNodeIsLoading',
      'focusPath',
    ]),
  },
  data() {
    return {};
  },
  methods: {
    handleChildComponentFocused(childrenCasePath) {
      this.updateCasePath(childrenCasePath);
    },
    addChild(type) {
      this.$refs.navNode.newChildFocusType = type;
    },
    focusRoot() {
      this.goToTranslatedEntity({ entityType: 'Knowledge' });
    },
    ...mapActions('kbStore', ['updateCasePath']),
    ...mapActions('knowledgeModule', ['goToTranslatedEntity']),
  },
};
</script>

<style lang="scss" scoped>
.empty-loader {
  width: 100%;
  height: 200px;

  :deep() .el-loading-mask {
    background-color: transparent;
  }
}
</style>
