export default {
  methods: {
    /**
     * Sends files to upload
     * @param {HTMLFile} files File object generated by HTML input
     */
    async mxFileUpload(files, callback) {
      if (files.length) {
        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          formData.append('files', file);
        }
        try {
          const res = await this.$services.parametricContents.attachFiles(
            formData,
          );
          callback(null, res.data);
          return;
        } catch (err) {
          callback(err);
          return;
        }
      } else {
        callback(new Error('Could not upload file.'));
        return;
      }
    },
  },
};
