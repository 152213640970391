<template>
  <div class="search-imput">
    <base-input
      ref="searchInput"
      input-group-classes="input-group-sm"
      v-model="localResolutionSearch"
      addon-left-icon="fa fa-search"
      :placeholder="$t('knowledge.product.search-placeholder')"
      class="mr-0 mb-0 search-field"
      @input="$emit('input', localResolutionSearch)"
      @keyup.enter="$emit('keyup:enter')"
    >
      <template v-slot:addonRight v-if="isLoading">
        <font-awesome-icon icon="spinner" spin />
      </template>
    </base-input>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput';

export default {
  name: 'solutions-search-bar',
  components: { BaseInput },
  props: {
    resolutionSearch: String,
    isLoading: Boolean,
  },
  data() {
    return {
      localResolutionSearch: this.resolutionSearch,
    };
  },
  watch: {
    resolutionSearch() {
      if (!this.resolutionSearch) {
        this.localResolutionSearch = this.resolutionSearch;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  :deep() .form-control {
    font-size: 16px;
    font-weight: 500;
  }
  :deep() .form-control::placeholder {
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
