<template>
  <div>
    <div class="card bg-secondary shadow border-0" id="error-box">
      <div class="card-body px-md-5 py-md-5 pb-md-3 text-center">
        <div class="text-muted mb-4">
          <h1>{{ message.title }}</h1>
          <p>{{ message.body }}</p>
        </div>
        <router-link to="/login">
          <base-button type="primary" class="my-2"
            >{{ $t('landing.error.backToHome') }}</base-button
          >
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'validate',
  computed: {
    message() {
      return this.$route.query.message
        ? this.content[this.$route.query.message]
        : this.content['GENERIC_ERROR'];
    },
  },
  data() {
    return {
      content: {
        INVALID_TOKEN: {
          title: this.$t('landing.error.invalid-token-title'),
          body: this.$t('landing.error.invalid-token-body'),
        },
        GENERIC_ERROR: {
          title: this.$t('landing.error.generic-error-title'),
          body: this.$t('landing.error.generic-error-body'),
        },
      },
    };
  },
  methods: {},
};
</script>

<style scoped>
#error-box {
  margin-top: -50px;
  background-color: rgba(255, 255, 255, 0.82) !important;
  border-radius: 4px !important;
}
</style>
