<template>
  <div>
    <div v-for="item in items" :key="item.title">
      <settings-item
        v-for="child in item.children"
        :key="child.title"
        :id="`settings-item_${child.name}_${child.hash.substring(1)}`"
        :item="child"
        :active="activeRoute === child.hash"
        :ref="child.hash"
      >
        <component
          v-if="child.component"
          :is="child.component"
          v-bind="child.props"
          :general-success="success"
        />
      </settings-item>
    </div>
  </div>
</template>

<script>
import SettingsItem from '@/components/Settings/SettingsItem';
import KnowledgeManageSettings from './GeneralSettings//KnowledgeManageSettings/KnowledgeManageSettings';
import AlertsSettings from './GeneralSettings/AlertsSettings';
import IntegrationSettingsPage from './IntegrationSettings/IntegrationSettingsPage';
import PluginCatalog from './PluginsSettings/PluginCatalog';
import UsersSettings from './PermissionSettings/UsersSettings/UsersSettings';
import GroupsSettingsMain from './PermissionSettings/AccessSettings/GroupsSettingsMain';
import RolesSettingsMain from './PermissionSettings/AccessSettings/RolesSettingsMain';
import LabelCategorySettings from './PermissionSettings/AccessSettings/LabelCategorySettings/LabelCategorySettings';
import CompanySettings from './CompanySettings/CompanySettings';
import ContentParameters from './ParametricKnowledgeSettings/ContentParameters';
import ContextVariables from './ParametricKnowledgeSettings/ContextVariables';
import GlobalParameters from './ParametricKnowledgeSettings/GlobalParameters';
import SynonymsSettingsCard from './SearchSettings/SynonymsSettingsCard';
import SearchSettings from './SearchSettings/SearchSettings';
import ModuleLabelsSettings from './ModuleLabelsSettings/ModuleLabelsSettings';
import AuthenticationSettings from './SecuritySettings/AuthenticationSettings/AuthenticationSettings.vue';
import HubSettings from 'views/Settings/GeneralSettings/HubSettings.vue';
import ConfigureAi from './AiSettings/ConfigureAi.vue';

const HASH_FALLBACK = {
  general: 'knowledges',
  integrations: 'catalog',
  plugins: 'plugins',
  users: 'users',
  permissions: 'groups',
  search: 'synonyms',
  knowledges: 'contents',
  company: 'company',
  security: 'security',
  configureAi: 'ai-settings',
};

export default {
  name: 'SettingsView',
  components: {
    SettingsItem,
    // GENERAL
    KnowledgeManageSettings,
    AlertsSettings,
    HubSettings,
    // INTEGRATION
    IntegrationSettingsPage,
    // PLUGINS,
    PluginCatalog,
    // PERMISSIONS
    UsersSettings,
    GroupsSettingsMain,
    RolesSettingsMain,
    LabelCategorySettings,
    // SEARCH
    SearchSettings,
    SynonymsSettingsCard,
    // CONTENTS
    ContentParameters,
    ContextVariables,
    GlobalParameters,
    // COMPANY
    CompanySettings,
    // ACADEMY
    ModuleLabelsSettings,
    // SECURITY
    AuthenticationSettings,
    ConfigureAi,
  },
  data() {
    return {
      activeRoute: null,
      focus: false,
      scroll: false,
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    success: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    document.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    focusSetting(route, immediate = false) {
      if (this.scroll) return;
      const { path, hash } = route;
      const setting = path.split('/').pop();
      this.activeRoute = hash;
      var element = this.$refs[hash];
      if (!element || !element.length) {
        if (hash) return;
        this.$router.replace({ hash: HASH_FALLBACK[setting] || '' });
        return;
      }
      var top = element[0].$el.offsetTop;
      this.focus = true;
      window.scrollTo({
        top: top - 96,
        ...(immediate ? {} : { behavior: 'smooth' }),
      });
      setTimeout(() => {
        this.focus = false;
      }, 1000);
    },
    handleScroll() {
      if (this.focus) return;
      const top = window.scrollY + 96;
      const items = this.getItemsDiv();

      let idx = items.findIndex(
        ({ offsetTop, offsetBottom }) =>
          offsetTop <= top && top <= offsetBottom,
      );

      if (idx < 0) return;

      const [, newName, newId] = items[idx].id.split('_');

      const newHash = '#' + newId;
      const { hash } = this.$route;
      if (hash === newHash) return;

      this.scroll = true;
      this.activeRoute = newHash;
      this.$router.replace({ name: newName, hash: newHash });
      setTimeout(() => {
        this.scroll = false;
      }, 500);
    },
    getItemsDiv() {
      const itemsEl = document.querySelectorAll(`[id^="settings-item_"]`);
      return [...itemsEl].map(({ offsetTop, id, offsetHeight }) => ({
        offsetTop,
        id,
        offsetBottom: offsetTop + offsetHeight,
      }));
    },
  },

  watch: {
    $route: {
      handler(newVal) {
        this.focusSetting(newVal);
      },
      immediate: true,
    },
  },
};
</script>
