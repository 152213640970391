<template>
  <div>
    <div class="colored-background" :style="companyDynamicStyle"></div>
    <div
      class="container filter-wrapper-container d-flex align-items-center justify-content-center"
    >
      <div class="col-md-12 col-lg-10 col-xl-8">
        <WebParametricSearchWrapper class="p-3 super-search-wrapper" inline />
      </div>
    </div>
  </div>
</template>

<script>
import WebParametricSearchWrapper from '@/components/SuperSearch/WebParametricSearchWrapper';
import { mapGetters } from 'vuex';

export default {
  name: 'mayday-web-parametric-search-filter',
  components: {
    WebParametricSearchWrapper,
  },
  data() {
    return {};
  },
  computed: {
    companyDynamicStyle() {
      return {
        '--custom-color': this.companyColor,
      };
    },
    ...mapGetters(['companyLogo', 'companyColor']),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.super-search-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $grey-1-mayday;
  background-clip: border-box;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
}

.filter-wrapper-container {
  margin-top: -79px;
}

.colored-background {
  height: 15vh;
  width: 100vw;
  background-color: var(--custom-color);
}
</style>
