<template>
  <div class="col-12 px-0 d-flex align-items-center return" @click="goToPreviousPage()">
    <font-awesome-icon class="return-icon mr-2" :icon="['fas', 'long-arrow-left']" />
    <p class="mb-0 return-text">Retour</p>
  </div>
</template>

<script>
export default {
  name: 'product-viewer-return',
  methods: {
    goToPreviousPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.return {
  cursor: pointer;
  color: $grey-6-mayday;
  &:hover {
    color: $blue-mayday;
  }
}
</style>
