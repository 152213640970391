<template>
  <div class="hub-filter-container">
    <div
      v-for="(category, index) in categories"
      :key="index"
      class="hub-filter-category"
    >
      <div class="hub-filter-title">
        {{ $t(category.title) }}
      </div>
      <el-collapse class="hub-filters-container">
        <el-collapse-item
          v-for="(key, keyIndex) in category.keys"
          :key="keyIndex"
          :class="{
            disabled: !enabledFilters(category.key, key),
          }"
        >
          <template slot="title">
            <div class="hub-filter">
              <div
                :class="category.filterFunction(category.key, key)"
                class="hub-filter-indicator"
              />
              <div class="hub-filter-label">
                {{ $t(`${category.translationPath}.${key}`) }}
                <span
                  v-if="enabledFilters(category.key, key)"
                  class="hub-filter-count"
                  >({{ filterCount(category.key, key) }})</span
                >
              </div>
            </div>
          </template>
          <div class="hub-filter-values-container">
            <div
              v-for="value in filterValues(category.key, key)"
              :key="value.id"
              class="hub-filter-value"
            >
              {{ value.name }}
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HubSettingsFilters',
  props: {
    assignee: Object,
    author: Object,
    type: Object,
    source: String,
    status: String,
    filterUsers: Array,
  },
  data: () => ({
    operatorMap: {
      $nin: 'excluded',
      $in: 'included',
    },
    listKeys: ['type'],
  }),
  methods: {
    filterStatus(category, key) {
      if (!this[category] || !this[category][key]) return '';
      return this.operatorMap[this[category][key]['operator']];
    },
    filterListStatus(category, key) {
      if (!this[category]) return '';
      return this.operatorMap[this[category].values.includes(key) ? '$in' : ''];
    },
    filterCount(category, key) {
      if (!this[category] || !this[category][key]) return 0;
      return this[category][key].values.length;
    },
    filterValues(category, key) {
      if (!this[category] || !this[category][key]) return [];
      switch (key) {
        case 'groups':
          return this.groups.filter((group) =>
            this[category][key].values.includes(group.id),
          );
        case 'roles':
          return this.roles.filter((role) =>
            this[category][key].values.includes(role.id),
          );
        case 'labels':
          return this.userLabelCategories
            .flatMap((category) => category.companyUserLabels)
            .filter((label) => this[category][key].values.includes(label.id));
        case 'ids':
          return this.filterUsers
            .filter((user) => this[category][key].values.includes(user.id))
            .map((user) => ({
              id: user.id,
              name: user.username,
            }));
        default:
          return [];
      }
    },
    enabledFilters(category, key) {
      if (!this[category] || !this[category][key]) return false;
      if (this.listKeys.includes(category)) return false;
      return this[category][key].values.length > 0;
    },
  },
  computed: {
    categories() {
      return [
        {
          key: 'assignee',
          title: 'settings.general.collaborative-hub.rows.filters.assignee',
          translationPath: 'settings.general.collaborative-hub.rows.filters',
          keys: ['groups', 'roles', 'labels', 'ids'],
          filterFunction: this.filterStatus,
        },
        {
          key: 'author',
          title: 'settings.general.collaborative-hub.rows.filters.author',
          translationPath: 'settings.general.collaborative-hub.rows.filters',
          keys: ['groups', 'roles', 'labels', 'ids'],
          filterFunction: this.filterStatus,
        },
        {
          key: 'type',
          title: 'settings.general.collaborative-hub.rows.filters.type.title',
          translationPath:
            'settings.general.collaborative-hub.rows.filters.type',
          keys: [
            'FEEDBACK',
            'CONTENT_FEEDBACK',
            'FEEDBACK_ACADEMY',
            'CONTENT_FEEDBACK_ACADEMY',
            'CONTENT_OBSOLETE',
          ],
          filterFunction: this.filterListStatus,
        },
      ];
    },
    ...mapGetters('adminModule', ['groups', 'userLabelCategories', 'roles']),
  },
};
</script>
<style lang="scss" scoped>
.hub-filter-container {
  display: flex;
  flex-wrap: wrap;
}

.hub-filter-category {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 40%;
  margin-right: 10px;
  margin-bottom: 8px;
}

.hub-filter-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  padding-bottom: 4px;
  border-bottom: 1px solid $grey-4-mayday;
}

.hub-filters-container {
  border: none !important;
  :deep(.el-collapse-item) {
    .el-collapse-item__wrap {
      border: none !important;
    }
    .el-collapse-item__content {
      padding: 0 !important;
    }
    .el-collapse-item__header {
      border: none !important;
      height: 30px;
    }
    &.disabled {
      pointer-events: none !important;

      .el-collapse-item__arrow {
        display: none !important;
      }
    }
  }
}

.hub-filter {
  display: flex;
  align-items: center;
}

.hub-filter-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f0f0f0;
  margin-right: 4px;

  &.included {
    background-color: $green-5-mayday;
  }
  &.excluded {
    background-color: $red-4-mayday;
  }
}

.hub-filter-label {
  font-size: 14px;
  line-height: 17px;
  color: #333333;

  .hub-filter-count {
    font-size: 12px;
    line-height: 15px;
    color: $grey-6-mayday;
  }
}

.hub-filter-values-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .hub-filter-value {
    margin-right: 4px;
    margin-bottom: 4px;
    background-color: $grey-2-mayday;
    border-radius: 4px;
    padding: 2px 4px;
    font-size: 11px;
  }
}
</style>
