<template>
  <div class="summary-section">
    <div class="summary-section__header" v-if="!deleting">
      <div class="summary-section__label">
        <font-awesome-icon
          v-if="generating"
          :icon="['fal', 'spinner-third']"
          spin
        />
        <span v-if="generating">{{
          $t(`knowledge.ai-drawer.generating`)
        }}</span>
        <div v-else-if="!isEmpty" class="summary-section__switch">
          <div class="summary-section__switch-label">
            {{ $t('knowledge.ai-drawer.visible.title') }}
            <el-tooltip :content="$t('knowledge.ai-drawer.visible.tooltip')">
              <font-awesome-icon :icon="['fal', 'question-circle']" />
            </el-tooltip>
          </div>
          <el-switch v-model="visibility" @change="handleSave" />
        </div>
      </div>
      <div class="summary-section__actions">
        <el-tooltip
          placement="top"
          :content="$t('knowledge.ai-drawer.summary.expired')"
          v-if="lastUpdate && !generating && !deleting && originalText"
        >
          <div
            :class="['summary-section__date']"
            v-if="lastUpdate && !generating"
          >
            <font-awesome-icon :icon="['fal', 'info-circle']" />
            <span
              >{{
                $t(
                  `knowledge.ai-drawer.${
                    manualEdit ? 'edited-at' : 'generated-at'
                  }`,
                )
              }}
              {{ updatedAt }}</span
            >
          </div>
        </el-tooltip>
        <base-dropdown
          direction="down"
          v-if="originalText && originalText.length"
        >
          <div slot="title" class="summary-section__button">
            <font-awesome-icon :icon="['fal', 'ellipsis-h']" />
          </div>
          <div
            :class="['dropdown-item', { disabled: generating }]"
            @click="$emit('generate-summary')"
          >
            <font-awesome-icon
              :icon="['fal', 'undo']"
              class="dropdown-item__icon"
            />
            <span>{{ $t(`knowledge.ai-drawer.popover.regenerate`) }}</span>
          </div>
          <div class="dropdown-item" @click="handleEdit">
            <font-awesome-icon
              :icon="['fal', 'pen']"
              class="dropdown-item__icon"
            />
            <span>{{ $t(`knowledge.ai-drawer.popover.edit`) }}</span>
          </div>
          <div class="dropdown-item" @click="handleDelete">
            <font-awesome-icon
              :icon="['fal', 'trash']"
              class="dropdown-item__icon"
            />
            <span>{{ $t(`knowledge.ai-drawer.popover.delete`) }}</span>
          </div>
        </base-dropdown>
      </div>
    </div>
    <div :class="['summary-section__body-container']">
      <div
        v-if="generatingAnimation || textAnimation"
        class="summary-section__body loading"
        ref="loadingContainer"
      >
        <LoadingBar v-if="generating" />
        <LoadingBar v-if="generating" />
        <LoadingBar v-if="generating" :width="50" />
        <AnimatedText
          :text="text"
          :container="$refs.loadingContainer"
          v-if="textAnimation && !generatingAnimation"
          @text-displayed="textAnimation = false"
        />
      </div>
      <div v-else-if="deletingAnimation" class="summary-section__body">
        <div v-if="deleting" class="summary-section__deletion_container">
          <font-awesome-icon :icon="['fal', 'spinner-third']" spin />
          <span> {{ $t(`knowledge.ai-drawer.deleting.title`) }}</span>
          <button class="btn btn-sm" @click="handleCancel">
            {{ $t(`knowledge.ai-drawer.cancel`) }}
          </button>
        </div>
        <div
          v-else-if="!processInterrupted"
          class="summary-section__deletion_container"
        >
          <font-awesome-icon :icon="['fal', 'check']" />
          <span> {{ $t(`knowledge.ai-drawer.deleting.done`) }}</span>
        </div>
      </div>
      <div v-else-if="!originalText.length" class="summary-section__body empty">
        <div class="text-center flex flex-col">
          <span> {{ $t(`knowledge.ai-drawer.summary.${type}.empty`) }}</span>
          <span>
            {{ $t(`knowledge.ai-drawer.summary.empty.description`) }}</span
          >
        </div>
        <button
          class="btn btn-primary btn-sm"
          @click="$emit('generate-summary')"
        >
          <font-awesome-icon :icon="['fal', 'bolt']" />
          {{ $t(`knowledge.ai-drawer.generate`) }}
        </button>
      </div>
      <div
        v-else
        :class="['summary-section__body textarea', { editing: editing }]"
      >
        <div
          :class="[
            'summary-section__textarea-container',
            { exceeded: textSizeExceeded && editing },
            { disabled: !editing },
          ]"
        >
          <textarea
            :class="['summary-section__textarea']"
            v-model="text"
            resize="none"
            ref="textarea"
            :disabled="!editing"
          />
          <span
            class="summary-section__textarea-count"
            v-if="maxLength && editing"
          >
            <span>{{ textSize }}</span> / {{ maxLength }}
          </span>
        </div>
        <span class="summary-section__error" v-if="textSizeExceeded && editing">
          You have reached the maximum character length
        </span>
        <div v-if="editing" class="summary-section__textarea-actions">
          <button class="btn button btn-sm" @click="handleCancel">
            {{ $t(`knowledge.ai-drawer.cancel`) }}
          </button>
          <button
            class="btn btn-primary btn-sm"
            @click="handleSave"
            :disabled="text.length === 0 || textSizeExceeded"
          >
            {{ $t(`knowledge.ai-drawer.save`) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingBar from 'components/AI/LoadingBar.vue';
import AnimatedText from 'components/AI/AnimatedText.vue';

export default {
  name: 'SummarySection',
  components: { AnimatedText, LoadingBar },
  data: () => ({
    text: '',
    visibility: false,
    deletingAnimation: false,
    generatingAnimation: false,
    textAnimation: false,
    editing: false,
  }),
  created() {
    this.text = this.originalText;
    this.visibility = this.isVisible;
  },
  props: {
    deleting: {
      type: Boolean,
      default: false,
    },
    generating: {
      type: Boolean,
      default: false,
    },
    processInterrupted: {
      type: Boolean,
      default: false,
    },
    originalText: {
      type: String,
      default: '',
    },
    lastUpdate: {
      type: String,
      default: '',
    },
    docLastUpdate: {
      type: String,
      default: '',
    },
    manualEdit: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleCancel() {
      this.editing = false;
      this.text = this.originalText;
      this.$emit('cancel');
    },
    handleSave() {
      const updatedSummary = {};
      this.editing = false;
      if (this.text !== this.originalText) {
        updatedSummary.body = this.text;
        updatedSummary.manualEdit = true;
      }
      updatedSummary.isVisible = this.visibility;
      this.$emit('save', updatedSummary);
    },
    handleEdit() {
      this.editing = true;
    },
    handleDelete() {
      this.$emit('delete');
      this.text = '';
    },
  },
  computed: {
    isEmpty() {
      return (
        !this.text &&
        !this.text.length &&
        !this.originalText &&
        !this.originalText.length &&
        !this.generating &&
        !this.deleting
      );
    },
    textSize() {
      return this.text.length;
    },
    textSizeExceeded() {
      if (!this.maxLength) return false;
      return this.textSize > this.maxLength;
    },
    updatedAt() {
      if (!this.lastUpdate) return null;
      const date = new Date(parseInt(this.lastUpdate));
      return `${date.getDate() < 10 ? 0 : ''}${date.getDate()}/${
        date.getMonth() < 9 ? 0 : ''
      }${date.getMonth() + 1}/${date.getFullYear()}`;
    },
    isExpired() {
      return false;
      //We keep this code commented for now, we first need to define the expiration time
      /*if (!this.docLastUpdate) return false;
      return this.docLastUpdate - 3000 > this.lastUpdate;*/
    },
  },
  watch: {
    deleting() {
      if (this.deleting) {
        this.generatingAnimation = false;
        this.textAnimation = false;
        this.deletingAnimation = true;
      } else if (!this.processInterrupted) {
        setTimeout(() => {
          this.deletingAnimation = false;
        }, 1000);
      } else {
        this.deletingAnimation = false;
      }
    },
    generating: {
      handler(newVal, oldVal) {
        if (oldVal && !newVal) {
          if (this.lastUpdate !== this.docLastUpdate)
            setTimeout(() => {
              this.text = this.originalText;
              this.generatingAnimation = false;
              this.textAnimation = true;
            }, 1000);
          else {
            this.generatingAnimation = false;
          }
        } else if (newVal) {
          this.generatingAnimation = true;
        }
      },
      immediate: true,
    },
    originalText(newVal) {
      this.text = newVal;
    },
    isVisible(newVal) {
      this.visibility = newVal;
    },
  },
};
</script>

<style scoped lang="scss">
.summary-section {
  background-color: $grey-1-mayday;
  border-radius: 4px;
  padding: 12px 18px;
  height: 160px;
  display: flex;
  flex-direction: column;
}

.summary-section__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .summary-section__label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    color: $purple-5-mayday;
    font-size: 16px;

    .summary-section__switch {
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 12px;
      color: $grey-6-mayday;
      font-weight: 500;

      :deep() .el-switch__core {
        height: 16px;
        width: 28px !important;
      }

      :deep() .el-switch__core:after {
        width: 12px;
        height: 12px;
      }

      :deep() .el-switch.is-checked .el-switch__core::after {
        margin-left: -13px;
      }

      :deep() .is-checked .el-switch__core {
        background-color: $purple-5-mayday;
        border-color: $purple-5-mayday;
      }
    }
  }

  .summary-section__actions {
    display: flex;
    gap: 12px;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    color: $grey-6-mayday;

    .dropdown-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        color: $grey-2-mayday;
        pointer-events: none;
      }

      &:hover {
        color: $purple-5-mayday;
      }

      .dropdown-item__icon {
        font-size: 14px;
      }
    }

    .summary-section__date {
      padding: 2px 4px;
      border-radius: 4px;
      display: flex;
      gap: 4px;
      align-items: center;
      &.expired {
        color: $yellow-mayday;
        background-color: rgba($yellow-mayday, 0.1);
      }
    }

    .summary-section__button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $grey-7-mayday;
      cursor: pointer;
      font-size: 16px;
      width: 20px;
      height: 20px;
      padding: 2px;
      border-radius: 4px;

      svg {
        transition: transform 0.2s ease-in-out;
      }

      &:hover {
        color: $purple-5-mayday;
        background-color: $grey-3-mayday;
      }
    }
  }
}

.summary-section__body-container {
  overflow: hidden;
  transition: max-height 0.4s ease-in-out, height 0.4s ease-in-out;
  max-height: 200px;
  height: 100%;
}

.summary-section__body {
  transition: height 0.4s ease-in-out, min-height 0.4s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;

  &.empty {
    button {
      background-color: $purple-5-mayday;
      border-color: $purple-5-mayday;
      svg {
        margin-right: 4px;
      }
    }
    span:first-of-type {
      font-weight: 700;
      font-size: 16px;
      color: black;
    }
    span {
      font-size: 14px;
      color: $grey-6-mayday;
      font-weight: 400;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &.loading {
    overflow: auto;
  }
}

.summary-section__deletion_container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 120px;

  svg {
    font-size: 24px;
    color: $red-mayday;
  }
  span {
    font-size: 16px;
    color: $grey-6-mayday;
    font-weight: 700;
  }
}

.summary-section__textarea-container {
  position: relative;
  background-color: white;
  font-size: 14px;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  transition: border-width 0.2s ease-in-out, padding 0.2s ease-in-out;
  padding: 4px;
  height: 100%;

  &:focus-within {
    border-color: $blue-mayday;
  }

  &.disabled {
    background-color: $grey-1-mayday;
    padding: 0;
    border: none;

    .summary-section__textarea {
      background-color: $grey-1-mayday;
    }
  }

  &.exceeded {
    border-color: $red-mayday;

    .summary-section__textarea-count span {
      color: $red-mayday;
    }
  }

  .summary-section__textarea-count {
    position: absolute;
    bottom: 4px;
    right: 4px;
    font-size: 8px;
    color: $grey-5-mayday;
  }

  .summary-section__textarea {
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
    border-radius: 4px;

    .summary-section__textarea-actions {
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      .btn {
        color: $purple-5-mayday;
      }

      .btn-primary {
        background-color: $purple-5-mayday;
        border-color: $purple-5-mayday;
        color: white;
      }
    }
  }

  .summary-section__error {
    color: $red-mayday;
    font-size: 12px;
  }
}
</style>
