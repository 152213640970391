import { render, staticRenderFns } from "./RoleDrawerAcademyTab.vue?vue&type=template&id=50dd5468&scoped=true&"
import script from "./RoleDrawerAcademyTab.vue?vue&type=script&lang=js&"
export * from "./RoleDrawerAcademyTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50dd5468",
  null
  
)

export default component.exports