<template>
  <div>
    <BaseCascader
      :label="ancestorLabel"
      :options="level"
      :disable-select="disableSelect"
      @next="handleChoice($event)"
      @prev="handlePrev()"
      @select-target="handleTarget($event)"
      @close="$emit('close')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseCascader from '@/components/BaseCascader';
export default {
  name: 'cascader-wrapper',
  components: {
    BaseCascader,
  },
  props: {
    sourceType: {
      type: String,
      required: true,
    },
    sourceId: {
      type: String,
      default: '',
    },
  },
  computed: {
    disableSelect() {
      return this.sourceType === 'contents' && this.levels.length === 1;
    },
    selectableKnowledges() {
      return this.knowledges;
    },
    level() {
      return this.levels[this.levels.length - 1];
    },
    ancestorLabel() {
      return this.ancestorsLabel[this.ancestorsLabel.length - 1];
    },
    ...mapGetters('knowledgeModule', [
      'knowledges',
      'focusKnowledge',
      'editingLanguage',
    ]),
  },
  data() {
    return {
      levels: [],
      ancestorsLabel: [],
    };
  },
  methods: {
    initCascader() {
      const levelOptions = this.selectableKnowledges.map((knowledge) => {
        return {
          id: knowledge.id,
          caseId: null,
          knowledge: knowledge.value,
          knowledgeId: knowledge.id,
          label: knowledge.label,
          hasChildren: true,
        };
      });
      this.levels.push(levelOptions);
    },
    getKnowledgeId(value) {
      const kIndex = this.selectableKnowledges.findIndex(
        (k) => k.value === value,
      );
      return this.selectableKnowledges[kIndex].id;
    },
    getKnowledge(knowledgeId) {
      const knowledge = this.selectableKnowledges.find(
        (kb) => kb.id == knowledgeId,
      );
      return knowledge.value;
    },
    handleTarget(option) {
      this.$emit('clone-to-target', option);
    },
    async handleChoice(option) {
      this.ancestorsLabel.push(option.label);
      let levelOptions = [];
      if (option.caseId) {
        const collection = await this.$services.cases.getById(option.caseId);
        levelOptions = collection.children.map((child) => {
          return {
            id: child.id,
            caseId: child.id,
            knowledge: child.knowledge,
            knowledgeId: this.getKnowledgeId(child.knowledge),
            label: child.label,
            hasChildren: child.hasChildren,
            disabled: this.isDisabled(child.id),
          };
        });
        this.levels.push(levelOptions);
      } else {
        const roots = await this.$services.cases.getRoots(option.knowledge);
        levelOptions = roots.map((root) => {
          return {
            id: root.id,
            caseId: root.id,
            knowledge: root.knowledge,
            knowledgeId: this.getKnowledgeId(root.knowledge),
            label: root.label,
            hasChildren: root.hasChildren,
            disabled: this.isDisabled(root.id),
          };
        });
        this.levels.push(levelOptions);
      }
    },
    isDisabled(itemId) {
      return this.sourceId === itemId;
    },
    handlePrev() {
      this.levels.pop();
      this.ancestorsLabel.pop();
    },
  },
  created() {
    this.initCascader();
  },
};
</script>

<style></style>
