<template>
  <div>
    <StepMoverSearch
      v-if="noCascader"
      :searchedContentTypes="searchedContentTypes"
      :focusedOption="focusedOption"
      @focus-content="$emit('focus-option', $event)"
    />
    <div v-else>
      <StepMoverCascaderWrapper
        @close="$emit('close')"
        @back-search="$emit('focus-option', null)"
        @focus-option="$emit('focus-option', $event)"
        @options-update="$emit('options-update', $event)"
        :forbiddenIds="forbiddenIds"
        :parent="focusedOption"
        :rootId="focusedOption.id"
        :method="'search'"
        :focusedOption="focusedOption"
      />
    </div>
  </div>
</template>

<script>
import StepMoverSearch from './StepMoverSearch.vue';
import StepMoverCascaderWrapper from './StepMoverCascaderWrapper.vue';

export default {
  name: 'search-wrapper',
  components: {
    StepMoverSearch,
    StepMoverCascaderWrapper,
  },
  props: {
    forbiddenIds: {
      type: Object,
      default: () => {},
    },
    searchedContentTypes: {
      type: Array,
      default: () => ['Diagnostic'],
    },
    parent: {
      type: Object,
      default: () => {},
    },
    rootId: {
      type: String,
      default: () => '',
    },
    focusedOption: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    noCascader() {
      return (
        !this.focusedOption ||
        (this.focusedOption && this.focusedOption.type === 'Article')
      );
    },
  },
};
</script>

<style></style>
