var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"delete-modal"},[_c('modal',{attrs:{"show":_vm.localDisplay},on:{"update:show":function($event){_vm.localDisplay=$event}}},[_c('div',{staticClass:"row d-flex mt-3 justify-content-center"},[_c('div',{staticClass:"col-11 text-center"},[_c('modal-header',{staticClass:"mb-3",attrs:{"style-name":{
            backgroundColor: '#ede6fe',
          }}},[_c('div',{staticClass:"d-inline-flex",attrs:{"slot":"icon"},slot:"icon"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fal', 'globe-stand']}})],1),_c('div',{staticClass:"mt-3"},[_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('translation.modal.update-translations.title'))+" ")])]),_c('hr',{staticClass:"m-2 h-0.5 border-white"}),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t('translation.modal.update-translations.subtitle', { sourceLanguage: _vm.$t(`knowledge.languages.${_vm.sourceLanguage}`), }))+" ")])])],1)]),_c('div',{staticClass:"row d-flex justify-content-center mb-3"},[_c('div',{staticClass:"col-11"},[_c('div',{staticClass:"warning-section"},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'exclamation-triangle']}}),_c('div',{staticClass:"d-flex flex-col"},[_vm._v(" "+_vm._s(_vm.$t('translation.modal.update-translations.warning'))+" "),_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.sanitize(
                  _vm.$t('translation.modal.update-translations.warning-2'),
                )
              )}})])],1)])]),_c('div',{staticClass:"row d-flex justify-content-center mb-2"},[_c('div',{staticClass:"col-11 text-center"},[_c('div',{staticClass:"d-flex items-center justify-between w-full mb-2"},[_c('div',{staticClass:"input-label"},[_vm._v(" "+_vm._s(_vm.$t('translation.modal.update-translations.body-title'))+" ")]),_c('el-checkbox',{staticClass:"mb-0 check-all-checkbox",on:{"change":_vm.handleCheckAll},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}})],1),_c('el-checkbox-group',{staticClass:"d-flex flex-col",model:{value:(_vm.checkedLangs),callback:function ($$v) {_vm.checkedLangs=$$v},expression:"checkedLangs"}},_vm._l((_vm.availableSourceLanguages),function(lang){return _c('el-checkbox',{key:lang,staticClass:"lang-checkbox",attrs:{"label":lang}},[_c('div',{staticClass:"d-flex flex-row gap-1 align-items-center justify-content-center"},[_c('LanguageFlag',{staticClass:"flag-emoji mr-1",attrs:{"language":lang,"emoji":""}}),_vm._v(" "+_vm._s(_vm.$t(`knowledge.languages.${lang}`))+" ")],1)])}),1)],1)]),_c('div',{staticClass:"col-12 d-flex justify-content-between",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"ml-1",attrs:{"type":"danger","size":"small"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t('translation.modal.cancel')))]),_c('el-button',{staticClass:"mr-1",attrs:{"type":"primary","size":"small","disabled":_vm.disableSendButton},on:{"click":_vm.handleUpdateTranslations}},[_vm._v(_vm._s(_vm.$t('translation.modal.validate')))])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }