<template>
  <div v-loading="loadingGeneral">
    <!-- UPDATE -->
    <DrawerRowTitle
      :title="
        $t('knowledge.drawers.content-view-setting.settings.general.title')
      "
    />
    <div class="settings__general-user">
      <div class="title">
        {{
          $t('knowledge.drawers.content-view-setting.settings.general.author')
        }}
      </div>
      <div class="settings__general-info">
        <div class="initials-wrapper" :style="colorStyle(author)">
          <div class="initials">
            {{ getInitials(author) }}
          </div>
        </div>
        <div>
          <div class="label">
            <span>
              {{
                $t(
                  'knowledge.drawers.content-view-setting.settings.general.created',
                )
              }}
            </span>
            <b> {{ author }} </b>
          </div>
          <div class="date-ago">
            <TimeAgo :date="content.createdAt"></TimeAgo>
          </div>
        </div>
      </div>
    </div>
    <div v-if="lastVersion" class="settings__general-user">
      <div class="title">
        {{
          $t(
            'knowledge.drawers.content-view-setting.settings.general.last-update',
          )
        }}
      </div>
      <div class="settings__general-info">
        <div class="initials-wrapper" :style="colorStyle(lastVersion.username)">
          <div class="initials">
            {{ getInitials(lastVersion.username) }}
          </div>
        </div>
        <div>
          <div class="label">
            <span>
              {{
                $t(
                  'knowledge.drawers.content-view-setting.settings.general.updated',
                )
              }}
            </span>
            <b> {{ lastVersion.username }} </b>
          </div>
          <div class="date-ago">
            <TimeAgo :date="lastVersion.updatedAt"></TimeAgo>
          </div>
        </div>
      </div>
    </div>
    <div v-loading="loadingContributors">
      <ContentHistory
        :total="total"
        :contributors="contributors"
        :pages="pages"
        :page="page"
        :authorId="content.author || content.enrichedAuthor.id"
        @select-page="handleChangePage"
      />
    </div>

    <!-- VERIFICATION POLICY -->
    <DrawerRowTitle
      :title="
        $t('knowledge.drawers.content-view-setting.settings.policy.title')
      "
      :tooltip="
        $t('knowledge.drawers.content-view-setting.settings.policy.tooltip')
      "
    />
    <div class="settings__general-policy" :class="{ untrusted }">
      <div class="title">
        <font-awesome-icon v-if="untrusted" :icon="['fas', 'times-circle']" />
        <font-awesome-icon v-else :icon="['fas', 'check-circle']" />
        {{
          untrusted
            ? $t(
                'knowledge.drawers.content-view-setting.settings.policy.untrust',
              )
            : $t('knowledge.drawers.content-view-setting.settings.policy.trust')
        }}
      </div>
      <div v-if="verificationPolicy" class="text">
        {{
          untrusted
            ? $t(
                'knowledge.drawers.content-view-setting.settings.policy.to-trust-untrusted',
                verificationPolicy,
              )
            : $t(
                'knowledge.drawers.content-view-setting.settings.policy.to-trust',
                verificationPolicy,
              )
        }}
      </div>
      <div v-else class="text">
        {{ $t('knowledge.drawers.content-view-setting.settings.policy.empty') }}
      </div>
    </div>
    <DrawerRowTitle
      :title="$t(`${i18n}.content-actions.title`)"
      :tooltip="$t(`${i18n}.content-actions.tooltip`)"
      v-if="displayContentActionsSection"
    />
    <div class="settings__general-info" v-if="displayContentActionsSection">
      <ContentViewSettingsAction
        :action="el"
        :content="content"
        v-for="el in knowledgeActions"
        :key="el._id"
        @upsert-action="upsertContentAction"
        @delete-action="deleteContentAction"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import DrawerRowTitle from '@/components/Drawers/Commons/DrawerRowTitle';
import TimeAgo from '@/components/Commons/TimeAgo';
import ContentHistory from 'components/ContentHistory.vue';
import { userColor } from 'utils/utils';
import ContentViewSettingsAction from '../ContentViewSettingsComponents/ContentViewSettingsAction';

export default {
  name: 'content-view-settings-general',
  components: {
    ContentHistory,
    DrawerRowTitle,
    TimeAgo,
    ContentViewSettingsAction,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lastVersion: null,
      total: 0,
      page: 1,
      pages: 2,
      contributors: [],
      loadingGeneral: true,
      loadingContributors: true,
      i18n: 'knowledge.drawers.content-view-setting.settings',
      knowledgeActions: [],
    };
  },
  async mounted() {
    await this.handleChangePage(1);
    this.lastVersion = this.contributors[0];
    this.loadingGeneral = false;

    if (this.displayContentActionsSection) {
      this.knowledgeActions = await this.getEligibleLocations('editorDrawer');
    }
  },
  computed: {
    updatedDate() {
      return this.lastVersion.updatedAt;
    },
    author() {
      if (
        !this.content.author &&
        this.content.enrichedAuthor &&
        !this.content.enrichedAuthor.id
      )
        return this.$t('knowledge.contributors.deleted-user');
      return this.content.enrichedAuthor
        ? this.content.enrichedAuthor.username
        : this.username;
    },
    untrusted() {
      if (this.content.verificationPolicy) {
        const { verificationDueDate } = this.content.verificationPolicy;
        if (verificationDueDate !== null) {
          return Date.now() > new Date(parseInt(verificationDueDate));
        }
      }
      return this.content.isOutdated;
    },
    verificationPolicy() {
      const { verificationPolicy: policy } = this.content;
      if (!policy) return;
      const userId = policy.userId;
      const customRoleId = policy.customRoleId;
      if (!userId && !customRoleId) return null;

      let username;

      if (userId) {
        username = this.reviewers.users.find((u) => u.id === userId).username;
      } else {
        username = this.reviewers.roles.find(
          (role) => role.id === customRoleId,
        ).name;
      }

      const date =
        parseInt(policy.verificationDueDate) + policy.verificationRange;

      return { username, date: this.parseDate(date.toString()) };
    },
    displayContentActionsSection() {
      return this.hasCompanyPreferenceWithValue('ALLOW_KNOWLEDGE_ACTIONS');
    },
    ...mapState(['username']),
    ...mapGetters(['hasCompanyPreferenceWithValue']),
    ...mapGetters('adminModule', ['reviewers']),
  },
  methods: {
    parseDate(date) {
      try {
        return date.includes('-')
          ? new Date(Date.parse(date)).toLocaleDateString('fr-FR')
          : new Date(parseInt(date, 10)).toLocaleDateString('fr-FR');
      } catch {
        return new Date().toLocaleDateString('fr-FR');
      }
    },
    async handleChangePage(index) {
      this.loadingContributors = true;
      this.page = index;
      const response = await this.fetchContentHistory({
        id: this.content.id,
        limit: 5,
        page: index,
        lang: this.content.returnLanguage,
      });
      this.total = response.total;
      this.pages = response.pages;
      const contributors = response.results.sort(
        (a, b) => b.updatedAt - a.updatedAt,
      );
      if (
        this.total < 5 &&
        !contributors.find((contributor) =>
          this.content.enrichedAuthor
            ? contributor.authorId === this.content.enrichedAuthor.id
            : contributor.authorId === this.content.author,
        )
      ) {
        if (!this.content.author && !this.content.enrichedAuthor.id)
          contributors.push({
            authorId: null,
            username: this.$t('knowledge.contributors.deleted-user'),
            updatedAt: this.content.createdAt,
          });
        else
          contributors.push({
            authorId: this.content.author || this.content.enrichedAuthor.id,
            username: this.content.enrichedAuthor
              ? this.content.enrichedAuthor.username
              : this.$t('knowledge.contributors.deleted-user'),
            updatedAt: this.content.createdAt,
          });
        this.total++;
      }
      this.contributors = contributors;
      this.loadingContributors = false;
    },
    colorStyle(username) {
      return {
        background:
          username !== null && username !== '?'
            ? userColor(username, 70, 50)
            : '#0081f9',
        color: 'white',
      };
    },
    getInitials(username) {
      const fullName = username.split(' ');
      let initials;
      if (fullName.length >= 2)
        initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      else initials = fullName.shift().charAt(0);
      return initials.toUpperCase();
    },
    ...mapActions('knowledgeModule', [
      'fetchContentHistory',
      'getEligibleLocations',
      'upsertContentAction',
      'deleteContentAction',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.settings__general-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.settings__general-user {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
}

.title {
  font-weight: 700;
  font-size: 12px;
  color: $grey-9-mayday;
}
.initials-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  overflow: hidden;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.label {
  font-size: 12px;
  font-weight: 500;
  color: $grey-6-mayday;
  b {
    font-weight: 700;
    color: $grey-9-mayday;
  }
}

.date-ago {
  margin-top: -8px;
}

:deep() .time-ago {
  font-weight: 700;
}

.divider {
  margin-top: 8px;
  margin-left: 28px;
}

.settings__general-policy {
  padding: 4px 8px;
  background: rgba($green-mayday, 0.1);
  border: 1px solid $green-mayday;
  border-radius: 2px;
  font-weight: 700;
  font-size: 12px;

  .title {
    text-transform: uppercase;
    color: $green-mayday;
  }

  .text {
    font-weight: 700;
  }
}
.untrusted {
  background: rgba($red-mayday, 0.1);
  border: 1px solid $red-mayday;
  .title {
    color: $red-mayday;
  }
}

.action {
  background-color: $grey-1-mayday;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__cta {
    &__btn {
      border: none;
      background-color: $blue-mayday;
      color: white;
      border-radius: 4px;
    }
  }
}
</style>
