<template>
  <div class="atg-modal__background">
    <div class="atg-modal">
      <div class="atg-modal__close" @click="$emit('close')">
        <font-awesome-icon :icon="['fal', 'times']" />
      </div>
      <!-- HEADER -->
      <div class="atg-modal__header">
        <font-awesome-icon :icon="['fal', 'code-merge']" />
        <span>{{ $t(`${pre}.title`) }}</span>
        <AiBadge />
      </div>
      <!-- BODY -->
      <div class="atg-modal__body">
        <div>
          <div class="atg-modal__body__title">
            {{ $t(`${pre}.text.title`) }}
          </div>
          <div class="atg-modal__body__description">
            {{ $t(`${pre}.text.description`) }}
          </div>
        </div>
        <!-- INPUT -->
        <custom-drop-zone
          class="col-8 mx-auto"
          v-model="files"
          :options="options"
          :multiple="true"
          v-loading="loading"
        ></custom-drop-zone>
        <!-- SETTINGS -->
        <div class="atg-modal__body__infos" v-if="files && files.length">
          <div
            :class="[
              'atg-modal__body__infos__collapse',
              { expanded: settingsExpanded },
            ]"
          >
            <div
              class="atg-modal__body__infos__collapse__header"
              @click="settingsExpanded = !settingsExpanded"
            >
              <div>
                <font-awesome-icon :icon="['fal', 'cog']" />
                <span>
                  {{ $t(`${pre}.collapse.title`) }}
                </span>
              </div>
              <font-awesome-icon :icon="['fal', 'caret-right']" />
            </div>
            <div
              :class="[
                'atg-modal__body__infos__collapse__body',
                { expanded: settingsExpanded },
              ]"
            >
              <div
                v-for="option in settingsOptions"
                :key="option.key"
                class="atg-modal__body__infos__collapse__body__option"
              >
                <div
                  class="atg-modal__body__infos__collapse__body__option__summary"
                >
                  <div
                    class="atg-modal__body__infos__collapse__body__option__summary__title"
                  >
                    <span>{{
                      $t(`${pre}.collapse.options.${option.i18n}`)
                    }}</span>
                  </div>
                  <div
                    class="atg-modal__body__infos__collapse__body__option__summary__input"
                    v-if="option.type === 'boolean'"
                  >
                    <el-switch v-model="settings[option.key]"></el-switch>
                  </div>
                  <div
                    class="atg-modal__body__infos__collapse__body__option__summary__input"
                    v-if="option.type === 'string'"
                  >
                    <el-switch v-model="silentSettings[option.key]"></el-switch>
                  </div>
                </div>
                <div>
                  <div
                    v-if="
                      option.type === 'string' && silentSettings[option.key]
                    "
                    :class="['atg-modal__body__input']"
                  >
                    <input
                      v-model="settings[option.key]"
                      :placeholder="
                        $t(`${pre}.collapse.options.${option.i18n}-placeholder`)
                      "
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FOOTER -->
      <div class="atg-modal__footer">
        <button class="btn btn-secondary btn-sm" @click="$emit('close')">
          {{ $t(`${pre}.cancel`) }}
        </button>
        <button
          :disabled="disabledSubmission"
          class="btn btn-primary btn-sm"
          @click="$emit('input', { rawFiles: files, settings })"
        >
          {{ $t(`${pre}.create`) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AiBadge from 'components/AI/AiBadge.vue';
import CustomDropZone from 'components/CustomDropZone.vue';

const i18nPrefix = 'knowledge.modals.brain-files-merge';

export default {
  name: 'ArticleBrainFilesMergeModal',
  components: { AiBadge, CustomDropZone },
  data: () => ({
    pre: Object.freeze(i18nPrefix),
    loading: false,
    settingsExpanded: false,
    files: [],
    options: {
      acceptedFiles: 'application/pdf',
      autoProcessQueue: false,
    },
    settings: {},
    silentSettings: {},
    settingsOptions: [
      {
        i18n: 'extract-images',
        key: 'extractImages',
        type: 'boolean',
      },
      {
        i18n: 'topic-hint',
        key: 'topicHint',
        type: 'string',
      },
    ],
  }),
  computed: {
    disabledSubmission() {
      return !this.files || !this.files.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.atg-modal__background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.atg-modal {
  background-color: white;
  position: relative;
  border-radius: 12px;
  box-shadow: 1px 1px 4px rgba(112, 144, 176, 0.25),
    -3px 4px 8px rgba(112, 144, 176, 0.25);
  transition: all 0.3s ease-in-out;
  width: 600px;

  &__close {
    position: absolute;
    top: -24px;
    right: -24px;
    width: 22px;
    height: 22px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    background-color: $purple-5-mayday;
    transition: all 0.2s ease-in-out;

    &:hover {
      scale: 1.1;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $purple-5-mayday;
    background-color: $grey-1-mayday;
    font-size: 16px;
    font-weight: 700;
    padding: 12px 16px;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
  }

  &__body {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__title {
      font-size: 16px;
      font-weight: 700;
    }

    &__description {
      font-size: 14px;
      font-weight: 400;
      color: $grey-6-mayday;
    }

    &__input {
      margin: 4px;
      position: relative;
      input {
        width: 100%;
        color: $purple-5-mayday;
        border: 2px solid $purple-5-mayday;
        border-radius: 4px;
        padding: 8px 10px;
        transition: all 0.2s ease-in-out;

        &:focus {
          color: black;
          box-shadow: 0 0 0 4px rgba($purple-5-mayday, 0.2);
        }
      }

      span {
        color: $red-5-mayday;
        font-size: 12px;
        font-weight: 400;
      }

      &.error {
        input {
          border-color: $red-5-mayday;
          color: $red-5-mayday;

          &:focus {
            box-shadow: 0 0 0 4px rgba($red-5-mayday, 0.2);
          }
        }
      }

      &.loading {
        input {
          border-color: $grey-5-mayday;
          color: $grey-5-mayday;
          padding-left: 30px;
          transition: padding-left 0.5s ease-in-out;
        }
        svg {
          position: absolute;
          font-size: 18px;
          top: 12px;
          left: 10px;
          color: $purple-5-mayday;
        }
      }
    }

    &__infos {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__collapse {
        background-color: $grey-1-mayday;
        border-radius: 8px;
        overflow: hidden;
        padding: 8px 12px;

        &.expanded {
          .fa-caret-right {
            transform: rotate(90deg);
          }
          .atg-modal__body__infos__collapse__body {
            max-height: 200px;
          }
        }

        &__header {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          font-weight: 700;
          div {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .fa-caret-right {
            transition: all 0.2s ease-in-out;
          }
        }

        &__body {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.2s ease-in-out;
          font-size: 14px;
          font-weight: 400;

          &.expanded {
            padding: 8px 0 0 0;
          }

          &__option {
            &__summary {
              padding: 4px 0;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              &__title {
                display: flex;
                justify-content: start;
                align-items: center;
              }
              &__input {
                :deep() .is-checked .el-switch__core {
                  background-color: $purple-5-mayday;
                  border-color: $purple-5-mayday;
                }
              }
            }
          }
        }
      }

      &__callout {
        font-size: 14px;
        font-weight: 700;
        padding: 8px 12px;
        border-radius: 8px;
        display: flex;
        gap: 8px;
        align-items: baseline;
        background-color: rgba($yellow-5-mayday, 0.2);
        div {
          display: flex;
          flex-direction: column;
          :last-child {
            font-weight: 400;
            white-space: pre;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 12px 16px;
    border-radius: 0 0 12px 12px;
    overflow: hidden;
    background-color: $grey-1-mayday;

    .btn-secondary {
      color: $purple-5-mayday;
    }

    .btn-primary {
      background-color: $purple-5-mayday;
      border-color: $purple-5-mayday;
    }
  }
}
</style>
