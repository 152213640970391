<template>
  <div
    class="select-knowledge-parameters row justify-content-center mx-0 py-2"
    v-loading="isLoading"
  >
    <div v-for="(o, idx) in optionsType" :key="o.id" class="col-10 px-0">
      <el-divider direction="horizontal" v-if="idx > 0" class="my-2" />
      <SelectParameter
        v-if="parameters.length"
        :parameters="parameters"
        :hierarchy="o"
        :update-filter="updateFilter"
        :filters="filters"
        @update-knowledge="updateKnowledge"
      />
    </div>
  </div>
</template>

<script>
import SelectParameter from './SelectParameter';
import ProductMixin from 'mixins/KnowledgeMixin/ProductMixin';

export default {
  name: 'select-knowledge-parameters',
  mixins: [ProductMixin],
  components: { SelectParameter },
  props: {
    initFilters: {
      type: Array,
      default: () => [],
    },
    cascadeType: {
      type: String,
      default: 'settings',
    },
  },
  computed: {
    optionsType() {
      return this.cascadeType === 'settings'
        ? this.mxHierarchy.options.filter((o) => !o.filterOnly)
        : this.mxHierarchy.options;
    },
  },
  data() {
    return {
      parameters: [],
      filters: this.initFilters || [],
      isLoading: false,
    };
  },
  async created() {
    this.isLoading = true;
    this.parameters = await this.$services.hierarchies.getHierarchies();
    this.parameters = this.parameters.sort((a, b) =>
      a.label.localeCompare(b.label),
    );
    this.isLoading = false;
  },
  methods: {
    async updateKnowledge(knowledge) {
      this.$emit('update-knowledge', knowledge);
    },
    async updateFilter(newFilter) {
      const hasValues = newFilter.values.length > 0;
      const keyIndex = this.filters.findIndex((f) => f.key === newFilter.key);
      const alreadyExisting = keyIndex !== -1;
      if (alreadyExisting) {
        if (hasValues) {
          this.filters[keyIndex].values = newFilter.values;
        } else {
          if (this.filters.length > 1) {
            this.filters.splice(keyIndex, 1);
          } else {
            this.filters = [];
          }
        }
      } else {
        if (hasValues) {
          this.filters.push(newFilter);
        }
      }
      this.$emit('update-product-filters', this.filters);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-knowledge-parameters {
  background-color: $grey-1-mayday;
  border-radius: 4px;
}
</style>
