<template>
  <div class="search-sidebar-content-preview">
    <ContentPreviewHeader
      :displayAskButton="displayAskButton"
      :showAskItems="showAskItems"
      @close="$emit('close')"
      @show-ask-items="showAskItems = !showAskItems"
    ></ContentPreviewHeader>
    <Transition name="slide">
      <ContentPreviewAskItems
        v-if="showAskItems"
        :summary="content.shortSummary.body"
        :actionItems="content.actionItems.body"
      ></ContentPreviewAskItems>
    </Transition>
    <div class="content-preview-wrapper">
      <div class="d-flex flex-row align-items-center">
        <Breadcrumb
          v-if="breadcrumbItems.length"
          :items="breadcrumbItems"
          :ellipse-only="displayStepNavigator"
          class="breadcrumb-wrapper"
        ></Breadcrumb>
        <ContentPreviewStepNavigator
          class="ml-2"
          v-if="displayStepNavigator"
          :ancestor="ancestorToPass"
          :current-step-id="id"
          @redirection="handleRedirection"
          @click-on-step="handleClickOnStep"
        ></ContentPreviewStepNavigator>
      </div>

      <div class="content-preview-title d-flex flex-row align-items-start">
        <div
          class="return-button"
          v-if="displayReturn"
          @click="handleClickOnReturn"
        >
          <font-awesome-icon class="icon" :icon="['fal', 'arrow-left']" />
        </div>
        <h2 class="mb-0">{{ content.label }}</h2>
      </div>
      <mayday-editor
        ref="sidebar-article-viewer"
        v-model="content.body"
        :editable="false"
        :options="options"
        class="mayday-editor-wrapper"
      />
      <div class="steps-wrapper" v-if="displaySteps">
        <ContentPreviewDiagnosticStep
          v-for="step in content.children"
          :key="step.id"
          :id="step.id"
          :label="step.label"
          :redirection="step.redirection"
          @click-on-step="handleClickOnStep"
          @redirection="handleRedirection"
        ></ContentPreviewDiagnosticStep>
      </div>
    </div>
  </div>
</template>

<script>
import ContentPreviewHeader from './ContentPreviewHeader.vue';
import ContentPreviewAskItems from './ContentPreviewAskItems.vue';
import ContentPreviewDiagnosticStep from './ContentPreviewDiagnosticStep.vue';
import Breadcrumb from '../Breadcrumb.vue';
import ContentPreviewStepNavigator from './ContentPreviewStepNavigator.vue';

import { mapActions } from 'vuex';

export default {
  name: 'search-sidebar-content-preview',
  components: {
    ContentPreviewHeader,
    ContentPreviewAskItems,
    ContentPreviewDiagnosticStep,
    Breadcrumb,
    ContentPreviewStepNavigator,
  },
  props: {
    entityId: { type: String, required: true },
    denormalizedCaseParameters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      content: {},
      showAskItems: false,
      redirectionFrom: '',
      id: '',
      options: {
        fontOptions: true,
        extraFontOptions: true,
        colorOptions: true,
        structurationOptions: true,
        extraStructurationOptions: true,
        tableOptions: true,
        richOptions: true,
        tocClickOptions: false,
        allowToc: true,
      },
      breadcrumbItems: [],
    };
  },
  computed: {
    displayAskButton() {
      const hasSummary =
        !!this.content.shortSummary &&
        !!this.content.shortSummary.body &&
        this.content.shortSummary.body.length > 0;
      const hasActionItems =
        !!this.content.actionItems &&
        !!this.content.actionItems.body &&
        this.content.actionItems.body.length > 0;
      return hasSummary || hasActionItems;
    },
    displaySteps() {
      return (
        (this.content.type === 'Diagnostic' ||
          this.content.type === 'Step' ||
          this.content.type === 'KeyStep') &&
        this.content.children.length > 0
      );
    },
    displayReturn() {
      return (
        this.content.type === 'Step' ||
        (this.content.type === 'KeyStep' &&
          this.content.ancestors &&
          this.content.ancestors.length > 0) ||
        this.redirectionFrom
      );
    },
    displayStepNavigator() {
      return (
        this.content.ancestorsContent &&
        this.content.ancestorsContent.length > 0 &&
        (this.content.type === 'Step' || this.content.type === 'KeyStep')
      );
    },
    ancestorToPass() {
      if (
        !this.content.ancestorsContent ||
        this.content.ancestorsContent.length <= 0
      )
        return;
      return this.content.ancestorsContent[
        this.content.ancestorsContent.length - 1
      ];
    },
  },
  methods: {
    resetSidebar() {
      this.showAskItems = false;
      this.redirectionFrom = '';
    },
    setEditorContent(rawContent) {
      const maydayEditor = this.$refs['sidebar-article-viewer'];
      if (maydayEditor) {
        maydayEditor.editor.commands.setContent(rawContent);
        this.$nextTick(() => {
          const editorWrapper = maydayEditor.$el.getElementsByClassName(
            'mayday-editor__content__ProseMirror',
          );
          this.$nextTick(() => {
            if (editorWrapper.length > 0) {
              editorWrapper[0].translate = true;
            }
          });
        });
      }
    },
    async handleClickOnStep(e) {
      const { entityId } = e;
      this.id = entityId;
      this.content = await this.getContentWithAncestors(entityId);
      this.setEditorContent(this.content.body);
      this.$emit('change-content', e);
    },
    async handleClickOnReturn() {
      if (this.redirectionFrom) {
        this.handleClickOnStep({ entityId: this.redirectionFrom });
        this.resetSidebar();
        return;
      }
      if (!this.content.ancestors && this.content.ancestors.length <= 0) return;
      const directAncestorId = this.content.ancestors[0]
        .split('/')
        .slice(-1)[0];
      this.handleClickOnStep({ entityId: directAncestorId });
    },
    handleRedirection(e) {
      this.redirectionFrom = this.id;
      this.handleClickOnStep(e);
    },
    computeItems() {
      this.breadcrumbItems = this.denormalizedCaseParameters.map((casePath) => {
        return {
          label: casePath.translations[casePath.defaultLanguage].label,
          id: casePath.id,
        };
      });
    },
    ...mapActions('knowledgeModule', ['getContent', 'getContentWithAncestors']),
  },
  watch: {
    entityId: {
      async handler(newId) {
        this.$nextTick(async () => {
          if (newId !== this.id) {
            this.resetSidebar();
            this.id = newId;
            this.content = await this.getContent({ id: newId });
            this.setEditorContent(this.content.body);
          }
        });
      },
      immediate: true,
    },
    denormalizedCaseParameters: {
      handler() {
        this.computeItems();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.search-sidebar-content-preview {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.content-preview-wrapper {
  height: 100%;
  overflow-x: hidden;
}
.content-preview-title {
  padding: 0px 16px 0px 16px;
}
.mayday-editor-wrapper {
  zoom: 80%;
  padding-top: 8px !important;
  padding-left: 22px !important;
}
.steps-wrapper {
  padding: 0px 16px 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.return-button {
  width: 35px;
  height: 24px;
  background-color: $grey-3-mayday;
  color: $grey-8-mayday;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 10px;
}
.return-button:hover {
  cursor: pointer;
  background-color: $blue-mayday;
  color: white;
}
.breadcrumb-wrapper {
  padding-left: 12px;
  margin-bottom: 8px;
}
</style>
