<template>
  <div>
    <menu-collapse
      :title="knowledge.label"
      :collapse="collapse"
      @collapsing="updateFocusedKnowledge"
    >
      <el-radio-group v-model="focusedCaseId" @input="updateFocusedCase">
        <div v-for="currentCase in cases" :key="currentCase.id">
          <el-radio :label="currentCase.id">
            {{ currentCase.label }}
          </el-radio>
        </div>
      </el-radio-group>
    </menu-collapse>
  </div>
</template>
<script>
import MenuCollapse from '@/components/Collapse/MenuCollapse';

export default {
  name: 'automationKnowledgeMenu',
  components: {
    MenuCollapse,
  },
  props: {
    knowledge: Object,
    collapse: Boolean,
  },
  data() {
    return {
      cases: [],
      focusedCaseId: '',
    };
  },
  created() {
    if (this.knowledge.isDefault) {
      this.updateFocusedKnowledge();
    }
  },
  methods: {
    async updateFocusedKnowledge() {
      this.cases = await this.$services.cases.getRoots(this.knowledge.value);
      if (this.cases.length) {
        this.focusedCaseId = this.cases[0].id;
      }

      this.$emit('updateFocusedKnowledge', {
        knowledgeId: this.knowledge.id,
        caseId: this.focusedCaseId,
        caseLabel: this.cases.find((currentCase) => {
          return currentCase.id === this.focusedCaseId;
        }).label,
      });
    },
    updateFocusedCase(id) {
      this.$emit('updateFocusedCase', {
        caseId: id,
        caseLabel: this.cases.find((currentCase) => {
          return currentCase.id === id;
        }).label,
      });
    },
  },
};
</script>
