<template>
  <div class="case-node-navigator-label">
    <div
      :style="indent"
      style="text-align: horizontal; width: 100%"
      class="row mx-0 mt-1 align-items-center"
    >
      <div class="col-auto case-option">
        <el-checkbox
          v-model="checked"
          class="d-flex align-items-center my-1 px-2"
        ></el-checkbox>
        <font-awesome-icon
          v-if="hasChildren"
          class="case-icon mr-0"
          :icon="['fad', 'folder']"
          @click="$emit('toggle')"
        />
        <span class="case-option-label">{{ label }}</span>
        <div
          class="checked-children-count d-flex align-items-center"
          v-if="count"
        >
          <el-divider direction="vertical"></el-divider>{{ count }}
        </div>
      </div>
    </div>
    <!-- <div :style="indent" class="row mx-0 mt-1 align-items-center" v-else>
      <el-checkbox
        v-model="checked"
        class="d-flex align-items-center my-1 px-2"
        style="width: 100%"
        >{{ label }}</el-checkbox
      >
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'case-node-navigator-label',
  props: {
    showChildren: Boolean,
    label: String,
    hasChildren: {
      type: Boolean,
      default: true,
    },
    path: String,
    focusCases: Array,
  },
  computed: {
    count() {
      if (!this.showChildren)
        return this.focusCases.filter((p) => p.includes(this.path)).length;
      return 0;
    },
    checked: {
      get() {
        return !!this.focusCases.find((p) => p === this.path);
      },
      set() {
        this.$emit('update-focus-cases', this.path);
      },
    },
  },
  data() {
    return {
      indent: { transform: `translate(10px)` },
    };
  },
};
</script>

<style lang="scss" scoped>
.case-icon {
  min-width: 24px;
  min-height: 24px;
  padding: 4px;
  border-radius: 2px;
}
.case-option {
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  padding: 4px;
  .case-option-label {
    padding: 4px;
    line-height: 16px;
    font-size: 0.9rem;
    word-break: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.case-icon {
  &:hover {
    background-color: $grey-1-mayday;
    cursor: pointer;
    color: $blue-mayday;
  }
}

.checked-children-count {
  color: $blue-mayday;
  font-weight: 700;
  font-size: 0.9rem;
}
.case-node-navigator-label {
  :deep() .el-checkbox__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}
</style>
