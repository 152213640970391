<template>
  <a class="nav-row" :href="archiveUrl">
    <div class="nav-row-icon">
      <font-awesome-icon :icon="['fas', 'trash']" />
    </div>

    <div class="nav-row-title">
      {{ $t('knowledge.archives.label') }}
    </div>
  </a>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'archives',
  props: {
    editingLanguage: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('knowledgeModule', [
      'focusKnowledgeId',
      'focusKnowledgeDefaultLanguage',
    ]),
    archiveUrl() {
      return `/archives/${this.focusKnowledgeDefaultLanguage}/${this.focusKnowledgeId}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-row {
  padding: 2px 4px;
  color: $grey-8-mayday;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: $grey-2-mayday;
    border-radius: 4px;
  }

  .nav-row-icon {
    height: 20px;
    display: flex;
    align-items: center;
    justify-self: center;
    color: $grey-5-mayday;
    * {
      width: 20px;
    }
  }

  .nav-row-title {
    font-size: 12px;
    margin-left: 8px;
  }
}
</style>
