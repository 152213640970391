<template>
  <div ref="label" class="row justify-content-start mr-0 align-items-center">
    <div class="label d-flex col my-1">
      <SymbolEditor
        :icon="newLabel.icon"
        :color="newLabel.color"
        @update-icon="updateNewLabelIcon"
        @update-color="updateNewLabelColor"
      />
      <ItemEditor
        :value="newLabel.labelName"
        :isLabel="true"
        :placeholder="'settings.card-settings-options.content-parameters.give-label-name'"
        @edit="addLabel"
        @cancel="resetForm"
      />
    </div>
  </div>
</template>

<script>
import ItemEditor from './ItemEditor';
import SymbolEditor from './SymbolEditor';

export default {
  name: 'new-label',
  components: {
    ItemEditor,
    SymbolEditor,
  },
  data() {
    return {
      newLabel: {
        labelName: '',
        icon: 'tags',
        color: '#0081F9',
      },
    };
  },
  methods: {
    updateNewLabelIcon(event) {
      this.newLabel.icon = event;
    },
    updateNewLabelColor(event) {
      this.newLabel.color = event;
    },
    addLabel(event) {
      if (!event) return;

      this.newLabel.labelName = event;
      this.$emit('add-label', this.newLabel);
    },
    resetForm() {
      this.$emit('reset-form');
    },
  },
};
</script>

<style lang="scss" scoped></style>
