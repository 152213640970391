<template>
  <div class="nav-row" @click="goToTemplates">
    <div class="nav-row-icon">
      <font-awesome-icon :icon="['fas', 'file-certificate']" />
    </div>

    <div class="nav-row-title">
      {{ $t('knowledge.templates.list') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'templates',
  props: {
    editingLanguage: {
      type: String,
      required: true,
    },
  },
  methods: {
    goToTemplates() {
      this.$emit('back-link', this.$route.path);
      this.$router.push({
        name: 'knowledge-templates',
        params: { lang: this.editingLanguage },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-row {
  padding: 2px 4px;
  color: $grey-8-mayday;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: $grey-2-mayday;
    border-radius: 4px;
  }

  .nav-row-icon {
    height: 20px;
    display: flex;
    align-items: center;
    justify-self: center;
    color: $grey-5-mayday;
    * {
      width: 20px;
    }
  }

  .nav-row-title {
    font-size: 12px;
    margin-left: 8px;
  }
}
</style>
