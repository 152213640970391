<template>
  <div
    class="mayday-notification-editor text-edition text-left"
    :class="[{ 'mayday-contribution-editor': isContribution }]"
  >
    <mayday-editor
      v-if="showEditor"
      ref="notification-editor"
      v-model="content"
      :editable="editable"
      :options="options"
      :placeholder="$t(placeholder)"
      @update="onUpdate"
      @click:open-link="handleOpenLink"
    />
  </div>
</template>
<script>
export default {
  name: 'MaydayNotificationEditor',
  props: {
    placeholder: {
      type: String,
      default: 'knowledge.modals.notifications.message.placeholder',
    },
    message: String,
    editable: {
      type: Boolean,
      default: true,
    },
    isContribution: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: '',
      options: {
        fontOptions: true,
        structurationOptions: true,
        messageOptions: true,
      },
      showEditor: true,
    };
  },
  created() {
    this.setEditorContent(this.message);
  },
  methods: {
    handleOpenLink({ href }) {
      window.open(href, '_blank');
    },
    onUpdate(message) {
      this.$emit('input', message === '<p></p>' ? '' : message);
    },
    setEditorContent(message) {
      this.showEditor = false;
      this.$nextTick(() => {
        this.showEditor = true;
        this.$nextTick(() => {
          const maydayEditor = this.$refs['notification-editor'];
          this.content = message;
          if (maydayEditor) {
            maydayEditor.editor.commands.setContent(this.content);
          }
        });
      });
    },
    focusEditor() {
      const maydayEditor = this.$refs['notification-editor'];
      if (maydayEditor) {
        maydayEditor.editor.commands.focus();
      }
    },
  },
  watch: {
    editable() {
      this.editor.setOptions({ editable: this.editable });
    },
  },
};
</script>
<style lang="scss" scoped>
.mayday-notification-editor {
  padding: 2px 12px;

  :deep() .velmld-parent {
    position: unset !important;
  }

  :deep() .mayday-editor {
    padding: 5px;
  }

  :deep(.mayday-editor__content__ProseMirror__link) {
    padding: 0 !important;
  }

  :deep(.mayday-editor__content__ProseMirror__link-favicon) {
    width: 0 !important;
    margin: 0 !important;
  }
}

.mayday-contribution-editor {
  :deep() div[id^='tippy-'],
  :deep() .tippy-content,
  :deep() .tippy-box,
  :deep() .mayday-editor__bubble-menu-text-selection {
    visibility: hidden !important;
    z-index: 99999;
  }
}
</style>
