var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['trust-container ', _vm.isContentTrusted ? 'trusted' : 'untrusted']},[_c('el-tooltip',{staticClass:"trust-button",attrs:{"placement":"top","effect":"dark","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"content",fn:function(){return [(!!_vm.verificationPolicy.verificationDueDate && _vm.isContentTrusted)?_c('p',{staticClass:"text-small mb-0"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.verification-policy.button-tooltip-part1'))+" "),_c('strong',[_vm._v(" "+_vm._s(_vm.formatedVerificationDueDate.toLocaleDateString('fr-FR'))+" ")]),_vm._v(". "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('knowledge.verification-policy.button-tooltip-part2'))+" ")]):(
          !!_vm.verificationPolicy.verificationDueDate &&
          !_vm.isContentTrusted &&
          _vm.verificationPolicy.verificationRange === 0
        )?_c('p',{staticClass:"text-small mb-0"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.verification-policy.button-tooltip-part1'))+" "),_c('strong',[_vm._v(" "+_vm._s(_vm.formatedVerificationDueDate.toLocaleDateString('fr-FR'))+" ")]),_vm._v(". "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('knowledge.verification-policy.button-tooltip-part4'))+" ")]):(
          _vm.verificationPolicy.verificationDueDate !== null && !_vm.isContentTrusted
        )?_c('p',{staticClass:"text-small mb-0"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.verification-policy.button-tooltip-part3'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('knowledge.verification-policy.button-tooltip-part4'))+" ")]):(_vm.isContentTrusted)?_c('p',{staticClass:"text-small mb-0"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.verification-policy.button-tooltip-part5'))+" ")]):_c('p',{staticClass:"text-small mb-0"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.verification-policy.button-tooltip-part6'))+" ")]),(_vm.verificationPolicy.setToDraftAtDueDate)?_c('p',{staticClass:"text-small mb-0"},[_vm._v(" "+_vm._s(_vm.$t('components.modals.verification-policy.submit.resume.unpublish'))+" ")]):_vm._e()]},proxy:true}])},[_c('div',{staticClass:"content-stamp"},[_c('span',{staticClass:"py-1",on:{"click":_vm.updateTrust}},[_c('i',{class:[
            'fa',
            _vm.isContentTrusted ? _vm.trustIconClass : _vm.untrustIconClass,
          ]}),_vm._v(" "+_vm._s(_vm.isContentTrusted ? _vm.$t('knowledge.verification-policy.trust').toUpperCase() : _vm.$t('knowledge.verification-policy.untrust').toUpperCase())+" ")]),(_vm.trustLoading)?_c('font-awesome-icon',{staticClass:"icon-spinner",attrs:{"icon":['far', 'spinner'],"spin":""}}):_vm._e()],1)]),_c('el-tooltip',{class:[
      _vm.isContentTrusted ? 'trust-settings-success' : 'trust-settings-error',
      'd-flex',
    ],attrs:{"placement":"top","effect":"dark","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"text-small mb-0"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.verification-policy.setting-tooltip'))+" ")])]},proxy:true}])},[_c('font-awesome-icon',{staticClass:"trust-cog-icon",attrs:{"icon":['fas', 'cog']},on:{"click":function($event){return _vm.$emit('update-display-modal', true)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }