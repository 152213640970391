<template>
  <div
    class="integration-card d-flex mb-3 p-2 align-items-center"
    @click="$emit('open')"
  >
    <div class="d-flex align-items-center">
      <div v-if="isFa" class="integration-card-logo p-2">
        <font-awesome-icon class="integration-logo" :icon="logo" />
      </div>
      <div v-else class="integration-card-logo p-2">
        <img class="integration-logo" :src="logo[1]" />
      </div>
      <div class="integration-card-info d-flex flex-column ml-2">
        <p class="title mb-0">{{ label }}</p>
        <p class="description mb-0">
          {{ $t(`settings.integration.types.${type}`) }}
        </p>
      </div>
    </div>
    <div
      v-if="active"
      class="d-flex align-items-center integration-card-status"
    >
      <div v-if="active" class="active">
        <font-awesome-icon :icon="['fad', 'circle']" />
        <div>{{ $t('settings.integration.state.active') }}</div>
      </div>
      <div v-else class="inactive">
        <font-awesome-icon :icon="['fad', 'circle']" />
        <div>{{ $t('settings.integration.state.inactive') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'integration-settings-card',
  components: {},
  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    logo: {
      type: Array,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFa() {
      return this.logo[0].startsWith('fa');
    },
  },
  data() {
    return {};
  },
  methods: {
    test() {},
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.integration-card {
  background-color: #fefefe;
  border: 1px solid $grey-2-mayday;
  border-radius: 4px;
  transition: all 200ms ease;
  display: flex;
  justify-content: space-between;
}

.integration-card:hover {
  box-shadow: 0px 15px 15px $shadow-4-mayday;
  cursor: pointer;
}

.integration-card-logo {
  background-color: white;
  border-radius: 8px;
  box-shadow: 26px 26px 60px #ebebeb;
  width: 3rem;
  height: 3rem;
  .integration-logo {
    object-fit: contain;
    width: 2rem;
    height: 2rem;
  }
}

.integration-card-info {
  .title {
    color: black;
    font-size: 18px;
    font-weight: bold;
  }
  .description {
    text-transform: uppercase;
    color: $grey-6-mayday;
    font-size: 10px;
  }
}

.integration-card-status {
  display: flex;

  * {
    display: flex;
    align-items: center;
    padding: 2px;
    border-radius: 2px;
    font-size: 12px;
  }
  .active {
    background: rgba($green-mayday, 0.1);
    border: 1px solid $green-mayday;
    color: $green-mayday;
  }
  .inactive {
    background: rgba($red-mayday, 0.1);
    border: 1px solid $red-mayday;
    color: $red-mayday;
  }
}
</style>
