<template>
  <div class="empty-state">
    <i class="empty-state-icon"></i>
    <h4 class="empty-state-label">
      {{ $t('new-dashboard.empty-state.label') }}
    </h4>
    <p class="empty-state-text mb-2">
      {{ $t('new-dashboard.empty-state.description.first-line') }}<br />
      {{ $t('new-dashboard.empty-state.description.second-line') }}<br />
      {{ $t('new-dashboard.empty-state.text-before-cta') }}
      <a target="_blank" :href="externalLink">{{
        $t('new-dashboard.empty-state.cta-text')
      }}</a>
      {{ $t('new-dashboard.empty-state.text-after-cta') }}
    </p>
    <el-button
      type="primary"
      size="mini"
      @click="() => $emit('open-filters-drawer')"
      >{{ $t('new-dashboard.empty-state.open-filters-drawer-cta') }}</el-button
    >
  </div>
</template>

<script>
export default {
  props: {
    externalDocUrl: {
      type: String,
      default: () => '',
    },
    linkAnchorName: {
      type: String,
      default: () => '',
    },
  },

  computed: {
    externalLink() {
      const { linkAnchorName } = this;
      return linkAnchorName != null && linkAnchorName.length
        ? `${this.externalDocUrl}${linkAnchorName}`
        : undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-state {
  padding-top: 40px;

  &-icon {
    width: 100px;
    height: 100px;
    background-image: url('~@/assets/empty-state-data.svg');
    background-size: cover;
    background-position: center;
    display: inline-block;
  }

  &-label {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 0.02em;

    color: #000000;
  }

  &-text {
    margin-bottom: unset;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: $grey-5-mayday;
  }
}
</style>
