<template>
  <div class="tag-container" :style="{ backgroundColor: hex2rgba(color) }">
    <font-awesome-icon
      class="icon-display"
      :style="{ color }"
      :icon="['fad', icon]"
    />
    <span>{{ name }}</span>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  methods: {
    hex2rgba(hex, alpha = 0.1) {
      const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
      return `rgba(${r},${g},${b},${alpha})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-container {
  display: inline-block;
  border-radius: 2px;
  padding: 2px 6px;

  .icon-display {
    width: 12;
    height: 12px;
    margin-right: 8px;
  }
}
</style>
