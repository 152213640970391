<template>
  <div class="product__attached__item-row">
    <div
      v-for="solution in solutions"
      :key="solution.id"
      class="product__attached__link d-flex align-items-center full-width justify-content-between py-1 pl-2"
      @click="$emit('focus-content', solution.id)"
    >
      <div class="d-flex">
        <img
          v-if="solution.type === 'Article'"
          src="~assets/article-icon-v2.svg"
          class="content-icon mx-2 mt-1"
        />
        <img
          v-if="solution.type === 'Diagnostic'"
          src="~assets/diag-icon-v2.svg"
          class="content-icon mx-2 mt-1"
        />
        <span>{{ solution.label }}</span>
      </div>
      <div v-if="solution.labels" class="d-flex">
        <ContentItemLabel v-for="label in solution.labels" :key="label.id" :label="label" />
      </div>
    </div>
    <DisplayMoreBtn
      v-show="solutions.length"
      class="mt-2"
      :total-count="solutionsCount"
      :display-more="moreOptions"
      @click:more="$emit('click:more')"
      @click:less="$emit('click:less')"
    />
  </div>
</template>

<script>
import ContentItemLabel from '@/components/Commons/ContentItemLabel';
import DisplayMoreBtn from '@/components/Commons/DisplayMoreBtn';

export default {
  name: 'search-resolutions-list',
  components: { ContentItemLabel, DisplayMoreBtn },
  props: {
    solutions: Array,
    solutionsCount: Number,
    moreOptions: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.product__attached__item-row {
  width: 100%;
  .product__attached__link {
    width: 100%;
    color: $grey-9-mayday;
    cursor: pointer;
    border-left: 2px solid $grey-2-mayday;
    &:hover {
      border-color: $blue-mayday;
      background-color: $grey-1-mayday;
    }
    .content-icon {
      height: 16px;
      width: 16px;
    }
  }
}
</style>