import { mapGetters } from 'vuex';

export default {
  computed: {
    highlightedLabel() {
      if (!this.collectionSearchFilter || this.collectionsViewerIsLoading)
        return this.label;

      const regEscape = (v) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
      const strArr = this.label.split(
        new RegExp(regEscape(this.collectionSearchFilter), 'ig'),
      );

      const tmpString = strArr
        .filter(Boolean)
        .reduce((acc, stringToReplace) => {
          if (!stringToReplace) return acc;
          return acc.replace(stringToReplace, `<b>${stringToReplace}</b>`);
        }, this.label);

      return `<span>${tmpString}</span>`;
    },
    ...mapGetters('knowledgeModule', [
      'collectionSearchFilter',
      'collectionsViewerIsLoading',
    ]),
  },
};
