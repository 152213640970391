<template>
  <div>
    <StepMoverBaseCascader
      :options="level"
      :pathToDisplay="ancestors"
      :forbiddenIds="forbiddenIds"
      :method="method"
      :focusedOption="focusedOption"
      @next="handleChoice($event)"
      @prev="handlePrev()"
      @close="$emit('close')"
      @back-home="handleBackHome"
      @back-search="$emit('back-search')"
      @click:folder="handleBreadcrumbNav($event)"
      @focus-option="$emit('focus-option', $event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import StepMoverBaseCascader from './StepMoverBaseCascader';
export default {
  name: 'cascader-wrapper',
  components: {
    StepMoverBaseCascader,
  },
  props: {
    forbiddenIds: {
      type: Object,
      default: () => {},
    },
    parent: {
      type: Object,
      default: () => {},
    },
    rootId: {
      type: String,
      default: () => '',
    },
    method: {
      type: String,
      default: 'cascader',
    },
    focusedOption: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    selectableKnowledges() {
      // For the moment we only allow transfer of step to same KB
      return [this.focusKnowledge];
    },
    level() {
      return this.levels[this.levels.length - 1];
    },
    ...mapGetters('knowledgeModule', [
      'knowledges',
      'focusKnowledge',
      'editingLanguage',
    ]),
  },
  data() {
    return {
      levels: [],
      ancestors: [],
      disableSelect: true,
    };
  },
  methods: {
    initCascader() {
      this.handleChoice(this.parent);
    },
    handleTarget(option) {
      this.$emit('select-target', option);
    },
    async handleChoice(option) {
      if (option.ancestorsContent && option.ancestorsContent.length)
        this.ancestors.push(...option.ancestorsContent);
      this.ancestors.push(option);
      let levelOptions = [];
      if (option.caseId) {
        const collection =
          await this.$services.cases.getOrderedChildrenAndContents(
            option.caseId,
            this.focusKnowledge.value,
            option.childrenOrder,
          );
        levelOptions = collection.reduce((acc, child) => {
          if (child.childrenOrder)
            acc.push({
              id: child.id,
              caseId: child.id,
              label: child.label,
              hasChildren: child.hasChildren,
            });
          if (child.type === 'Diagnostic')
            acc.push({
              id: child.id,
              label: child.label,
              type: child.type,
              children: child.children,
            });
          return acc;
        }, []);
      } else if (option.type === 'Diagnostic' || option.type === 'Step') {
        const diag = await this.$services.parametricContents.getContentChildren(
          option.id,
          this.editingLanguage,
        );
        levelOptions = diag.children.reduce((acc, child) => {
          acc.push({
            id: child.id,
            label: child.label,
            type: child.type,
            children: child.children,
          });
          return acc;
        }, []);
      }

      this.levels.push(levelOptions);
    },
    handleBreadcrumbNav(optionIdx) {
      const option = this.ancestors[optionIdx];
      this.ancestors = this.ancestors.slice(0, optionIdx);
      this.handleChoice(option);
    },
    handlePrev() {
      if (this.levels.length !== 1) {
        this.levels.pop();
        this.ancestors.pop();
      }
    },
    async handleBackHome() {
      this.ancestors = this.ancestors.slice(0, 1);
      const diag = await this.$services.parametricContents.getContentChildren(
        this.rootId,
        this.editingLanguage,
      );
      const levelOptions = diag.children.reduce((acc, child) => {
        acc.push({
          id: child.id,
          label: child.label,
          type: child.type,
          children: child.children,
        });
        return acc;
      }, []);
      this.levels.push(levelOptions);
    },
  },
  created() {
    this.initCascader();
  },
  watch: {
    level() {
      this.$emit('options-update', this.level);
    },
  },
};
</script>

<style></style>
