<template>
  <div>
    <WarningCallout class="mb-2 max-width" warning="automations" />
    <div v-for="automation in automations" :key="automation.id">
      <!--  -->
      <not-allowed-tooltip
        :user-permissions="userPermissions"
        permission-key="canUpdateContent"
        class="row"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            class="col-10 mb-2 mt-2 linked-diag"
            @click="
              hasPermissions ? $emit('edit-automation', automation.id) : ''
            "
          >
            <a class="mb-0">
              <img src="~assets/rule-black.svg" width="10%" class="mr-2 mb-1" />
              <span class="rule-name">{{ automation.alias }}</span>
            </a>
          </div>
          <div
            class="col-2 my-auto p-0"
            @click="
              hasPermissions ? $emit('delete-automation', automation.id) : ''
            "
          >
            <a class="delete">
              <font-awesome-icon :icon="['fad', 'trash']" class="delete-btn" />
            </a>
          </div>
        </template>
      </not-allowed-tooltip>
      <!--  -->
    </div>
    <div class="col mt-2 text-center linking">
      <not-allowed-tooltip
        :user-permissions="userPermissions"
        permission-key="canUpdateContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <base-button
            class="linked-btn"
            outline
            type="primary"
            size="sm"
            :disabled="!hasPermissions"
            @click="$emit('create-automation')"
            >{{
              $t(
                'knowledge.diagnostic-builder.side-bar.automations.popover-button',
              )
            }}
          </base-button>
        </template>
      </not-allowed-tooltip>
    </div>
  </div>
</template>

<script>
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';
import WarningCallout from '@/views/KnowledgeEditor/ParametricContentBuilder/ParametricBuilderSibeBar/WarningCallout.vue';

export default {
  name: 'automations-menu',
  components: {
    NotAllowedTooltip,
    WarningCallout,
  },
  props: {
    automations: Array,
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-btn {
  width: 20px;
  color: $grey-5-mayday;
  &:hover {
    color: $red-mayday;
  }
}
.linking {
  min-width: 350px;
}

.link-search-bar {
  min-width: 250px;
}

.linked-btn {
  min-width: 250px;
}

.attached-file {
  word-break: break-word;
}

.max-width {
  max-width: 330px;
  margin: 0 auto;
}
</style>
