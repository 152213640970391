<template>
  <div class="case-editor">
    <CaseBreadcrumb
      v-if="parents.length"
      :parents="parents"
      @appear-parents="reducePath"
      @return-home="$emit('return-home')"
    />
    <div class="case-options-wrapper" v-if="options.length">
      <div
        v-for="o in options"
        class="row mb-2 mx-0 align-items-center case-options-row"
        :key="o.id"
        @click="focusChild(o.id)"
      >
        <div class="case-option px-1">
          <font-awesome-icon :icon="['fad', 'folder']" class="mr-2 case-icon" />
          <span class="case-option-label">{{ o.label }}</span>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="ml-2 case-count">{{ o.resolutionsCount }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import CaseBreadcrumb from '@/components/Commons/CasesNavigator/CaseBreadcrumb';

export default {
  name: 'product-viewer-case-viewer',
  components: {
    CaseBreadcrumb,
  },
  props: {
    caseObject: Object,
    cases: Array,
    path: Array,
  },
  computed: {
    step() {
      if (this.path.length)
        return this.cases.find((c) => c.id === this.path.slice(-1)[0]);
      return this.caseObject;
    },
    options() {
      if (this.cases && this.step && this.step.options) {
        return this.step.options
          .map((id) => this.cases.find((c) => c.id === id))
          .filter((c) => !!c)
          .filter((c) => c.resolutionsCount > 0)
          .sort((a, b) => b.resolutionsCount - a.resolutionsCount);
      }
      return [];
    },
    parents() {
      if (this.path.length)
        return this.path
          .map((p) => this.cases.find((c) => c.id === p))
          .filter((p) => !!p);
      return [];
    },
  },
  methods: {
    focusChild(caseId) {
      const newPath = [...this.path, caseId];
      this.$emit('get-children', {
        id: caseId,
        path: newPath,
        root: false,
      });
    },
    reducePath(idx) {
      const newPath = [...this.path.slice(0, idx)];
      this.$nextTick(() => {
        this.$emit('update-solutions', newPath);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.case-icon {
  min-width: 24px;
  min-height: 24px;
  padding: 4px;
  border-radius: 2px;
  color: $grey-5-mayday;
}
.case-option {
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  padding: 4px;
  .case-icon {
    border: 1px solid transparent;
  }
  .case-option-label {
    padding: 4px;
    line-height: 16px;
  }
  &:hover {
    .case-option-label {
      background-color: $grey-1-mayday;
    }
    .case-icon {
      background-color: $grey-1-mayday;
      cursor: pointer;
      border: 1px solid $grey-4-mayday;
    }
  }
}

.case-options-row {
  &:hover {
    .case-count {
      color: $blue-mayday;
    }
  }
}

.new-case-option {
  width: fit-content;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  color: $grey-4-mayday;
  &:hover {
    color: white;
    background-color: $blue-mayday;
    cursor: pointer;
    border-color: $blue-mayday;
  }
}
</style>
