<template>
  <modal :show.sync="localOpen" :show-close="false" headerClasses="p-0 m-0">
    <ModalHeader
      slot="header"
      :title="$t('knowledge.product.notification.main-title')"
      @close="closeModal"
    />

    <ProductNotificationEdition
      v-show="currentStep === 0"
      @change="updateProductNotificationMainContent"
      class="create-product-notification-modal__section"
    />

    <GlobalParameterSelector
      v-show="currentStep === 1"
      @update-product-filters="updateProductFilters"
      class="create-product-notification-modal__section"
    />

    <GroupSelector
      v-if="currentStep === 2"
      :group-ids="productNotification.groupIds"
      :possible-user-groups="userGroups"
      @update-group-ids="updateGroupIds"
      class="create-product-notification-modal__section"
    />

    <ProductNotificationReview
      v-if="currentStep === 3"
      :product-notification="productNotification"
      class="create-product-notification-modal__section"
    />

    <ModalFooter
      :steps="steps"
      :current-step="currentStep"
      :step-count="stepCount"
      :is-product-notification-title-invalid="
        $v.productNotification.title.$invalid
      "
      :is-product-notification-description-invalid="
        $v.productNotification.description.$invalid
      "
      :is-product-notification-parameters-invalid="
        $v.productNotification.parameters.$invalid
      "
      :is-product-notification-access-restrictions-invalid="
        $v.productNotification.accessRestrictions.$invalid
      "
      @close="closeModal"
      @goToPreviousStep="goToPreviousStep"
      @goToNextStep="goToNextStep"
      @createProductNotification="createProductNotification"
    />
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import ModalHeader from './ModalHeader';
import ProductNotificationEdition from './ProductNotificationEdition';
import GlobalParameterSelector from './GlobalParameterSelector';
import GroupSelector from './GroupSelector';
import ProductNotificationReview from './ProductNotificationReview';
import ModalFooter from './ModalFooter';

export default {
  name: 'create-product-notification-modal',
  components: {
    ModalHeader,
    ProductNotificationEdition,
    GlobalParameterSelector,
    GroupSelector,
    ProductNotificationReview,
    ModalFooter,
  },
  props: {
    open: Boolean,
  },
  data() {
    return {
      localOpen: this.open,
      steps: [0, 1, 2, 3],
      stepCount: 4,
      currentStep: 0,
      productNotification: {
        title: '',
        description: '',
        urgent: false,
        parameters: [],
        accessRestrictions: [],
      },
    };
  },
  validations: {
    productNotification: {
      title: {
        required,
      },
      description: {
        required,
      },
      parameters: {
        haveEnoughParameters: (value) =>
          (value.filter((data) => data.key === 'knowledge').length &&
            value.filter((data) => !['knowledge', 'facets'].includes(data.key))
              .length) ||
          value.filter((data) => data.key === 'facets').length,
        required,
      },
      accessRestrictions: {
        required,
      },
    },
  },
  methods: {
    closeModal() {
      this.localOpen = false;
    },
    goToPreviousStep() {
      this.currentStep -= 1;
    },
    goToNextStep() {
      this.currentStep += 1;
    },
    updateProductNotificationMainContent({ title, description, urgent }) {
      this.productNotification = Object.assign(this.productNotification, {
        title,
        description,
        urgent,
      });
    },
    updateProductFilters(updatedProductFilters) {
      this.productNotification.parameters = updatedProductFilters;
    },
    updateGroupIds(updatedGroupIds) {
      this.productNotification.accessRestrictions = updatedGroupIds;
    },
    createProductNotification() {
      this.$emit('create-product-notification', this.productNotification);
      this.localOpen = false;
    },
  },
  computed: mapState(['userGroups']),
  watch: {
    localOpen() {
      if (!this.localOpen) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss">
.overflow-modal {
  max-height: 80vh !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
</style>

<style lang="scss" scoped>
.create-product-notification-modal__section {
  max-height: 60vh;
  overflow: auto;
}
</style>
