<template>
  <div>
    <section class="collapse-section">
      <div
        class="col-12 d-flex justify-content-between align-items-center pr-md-0 my-2 product-viewer-collapsable"
        @click="caseShow = !caseShow"
      >
        <p class="section-title text-uppercase">
          <font-awesome-icon
            class="mr-1"
            :icon="['fad', 'folder-open']"
          />Collections
        </p>
        <button
          :class="[
            'product__button',
            caseShow ? null : 'product__button--collapsed',
          ]"
          :aria-expanded="caseShow ? 'true' : 'false'"
          aria-controls="caseShow"
        >
          <i
            :class="[
              'product__toogle-icon',
              'fa',
              caseShow ? 'fa-caret-square-down' : 'fa-caret-square-right',
            ]"
          ></i>
        </button>
      </div>
      <b-collapse
        v-model="caseShow"
        id="caseShow"
        class="product__manual__collapsable"
      >
        <ProductViewerKnowledgeCases
          class="col-12 px-0 knowledge-cases"
          :cases="cases"
          :path="path"
          @get-children="getChildren"
          @reset-cases="resetCases"
          @update-solutions="updateSolutions"
        />
      </b-collapse>
    </section>

    <!-- SOLUTIONS -->
    <section class="solutions collapse-section">
      <div
        class="col-12 d-flex justify-content-between pr-md-0 my-2 align-items-center product-viewer-collapsable"
      >
        <p class="section-title text-uppercase">
          <font-awesome-icon class="mr-1" :icon="['fad', 'books']" />
          {{ $t('knowledge.product.solutions') }} | {{ solutionsCount }}
        </p>
        <div>
          <el-tooltip
            :content="$t('knowledge.product.search-tooltip')"
            placement="top"
            class="group-tooltip"
          >
            <button
              :class="[
                'search__button',
                displaySearch ? 'search__button--active' : '',
              ]"
              @click.prevent="updateDisplaySearch"
            >
              <font-awesome-icon
                class="trigger-search-field"
                :icon="['fad', 'search']"
              />
            </button>
          </el-tooltip>
          <button
            :class="[
              'product__button',
              solutionShow ? null : 'product__button--collapsed',
            ]"
            :aria-expanded="solutionShow ? 'true' : 'false'"
            aria-controls="solutionShow"
            @click="solutionShow = !solutionShow"
          >
            <i
              :class="[
                'product__toogle-icon',
                'fa',
                solutionShow ? 'fa-caret-square-down' : 'fa-caret-square-right',
              ]"
            ></i>
          </button>
        </div>
      </div>
      <b-collapse
        v-model="solutionShow"
        id="solutionShow"
        class="product__manual__collapsable"
      >
        <div
          v-if="displaySearch"
          class="row justify-content-start align-items-center mb-3"
        >
          <BaseInput
            ref="searchInput"
            input-group-classes="input-group-sm"
            v-model="localResolutionSearch"
            addon-left-icon="fa fa-search"
            :placeholder="$t('knowledge.product.search-placeholder')"
            class="mx-4 mr-0 mb-0 col-lg-5 col-md-12 search-field"
            @input="launchSearchDebounce"
            @keyup.enter="updateResolutionSearch"
          />
        </div>
        <ul
          id="product__list"
          :class="['content__list', productListShow ? 'show' : '']"
          v-loading="isSolutionLoading"
        >
          <li
            v-for="(solution, idx) in solutions"
            :key="idx"
            class="content__list__item-row"
            @click="$emit('focus-content', solution.id)"
          >
            <div
              class="content__result__link col-12 d-flex align-items-center full-width justify-content-between"
              :class="[solution.type]"
            >
              <div class="d-flex align-items-center content-label-row">
                <CommonNodeStatus :content="solution" :unclickable="true" />
                <img
                  v-if="solution.type === 'Article'"
                  src="~assets/article-icon-v2.svg"
                  class="content-icon mx-2"
                />
                <img
                  v-if="solution.type === 'Diagnostic'"
                  src="~assets/diag-icon-v2.svg"
                  class="content-icon mx-2"
                />
                {{ solution.label }}
              </div>
              <div v-if="solution.labels" class="d-flex">
                <ContentItemLabel
                  v-for="label in solution.labels"
                  :key="label.id"
                  :label="label"
                />
              </div>
            </div>
          </li>
        </ul>
        <span
          v-if="!moreOptions && solutionsCount > 10"
          class="text-muted display-more"
          @click="displayMoreSolutions"
        >
          <font-awesome-icon class="mr-1 ml-4" :icon="['fad', 'eye']" />
          {{ $t('knowledge.product.display-more') }}
        </span>
        <span
          v-else-if="moreOptions && solutionsCount > 10"
          class="text-muted display-more"
          @click="displayLessSolutions"
        >
          <font-awesome-icon class="mr-1 ml-4" :icon="['fad', 'eye']" />
          {{ $t('knowledge.product.display-less') }}
        </span>
      </b-collapse>
    </section>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue';
import debounce from 'lodash.debounce';

import BaseInput from '@/components/BaseInput';
import ProductViewerKnowledgeCases from './ProductViewerKnowledgeCases';
import CommonNodeStatus from '@/views/KnowledgeEditor/NavigationBar/Navigator/NavigatorNode/CommonNode/CommonNodeStatus.vue';
import ContentItemLabel from '@/components/Commons/ContentItemLabel';

export default {
  name: 'solution-filtering',
  props: {
    solutions: {
      type: Array,
      required: true,
    },
    solutionsCount: {
      type: Number,
      required: true,
    },
    productId: {
      type: String,
    },
    knowledge: {
      type: String,
    },
    parameters: {
      type: Array,
      default: () => [],
    },
    isSolutionLoading: {
      type: Boolean,
      default: false,
    },
    isPartial: {
      type: Boolean,
      default: false,
    },
    resolutionSearch: {
      type: String,
      default: '',
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    'b-collapse': BCollapse,
    BaseInput,
    ProductViewerKnowledgeCases,
    CommonNodeStatus,
    ContentItemLabel,
  },
  data() {
    return {
      caseShow: true,
      solutionShow: true,
      selectedCases: [],
      cases: [],
      focusKnowledgeValue: 'MLEN',
      productListShow: true,
      moreOptions: false,
      localResolutionSearch: this.resolutionSearch,
      displaySearch: false,
      launchSearchDebounce: debounce(() => {
        this.moreOptions = false;
        this.$emit('update-resolution-search', this.localResolutionSearch);
      }, 500),
    };
  },
  async mounted() {
    await this.getCases();
    await Promise.all(
      this.path.map((id) =>
        this.getChildren({
          id,
          path: this.path,
        }),
      ),
    );
  },
  methods: {
    async loadContentBeforeFocus(content) {
      const fullContent = await this.$services.parametricContents.get(
        content.id,
      );
      this.$emit('update-focus-content', fullContent);
    },
    displayMoreSolutions() {
      this.$emit('display-more-solutions');
      this.moreOptions = true;
    },
    displayLessSolutions() {
      this.$emit('display-less-solutions');
      this.moreOptions = false;
    },
    async getCases() {
      const rootCases = this.isPartial
        ? await this.$services.cases.getCasesRootByPartialProduct(
            this.parameters,
            this.knowledge,
          )
        : await this.$services.cases.getRootsByProductId(
            this.productId,
            this.knowledge,
          );
      this.cases = rootCases
        .filter((rc) => rc.resolutionsCount > 0)
        .sort((a, b) => b.resolutionsCount - a.resolutionsCount);
      window.addEventListener('beforeunload', () =>
        this.launchSearchDebounce.flush(),
      );
    },
    async getChildren({ id, path }) {
      this.updateSolutions(path);

      const options = this.isPartial
        ? await this.$services.cases.getChildrenByPartialProduct(
            id,
            this.parameters,
            this.knowledge,
          )
        : await this.$services.cases.getChildrenByProductId(
            id,
            this.productId,
            this.knowledge,
          );
      // 1 . add options keys to case
      const focusCaseIdx = this.cases.map((c) => c.id).indexOf(id);
      this.cases[focusCaseIdx] = {
        ...this.cases[focusCaseIdx],
        options: options.map((o) => o.id),
      };
      // 2. add options to cases
      this.cases = this.cases.concat(options);
    },
    updateResolutionSearch() {
      this.launchSearchDebounce.flush();
    },
    updateSolutions(path, count = 10) {
      this.moreOptions = false;
      this.$emit('update-solutions', { path, count });
    },
    resetCases() {
      this.$emit('update-solutions', { path: [] });
      this.cases = this.cases.filter((c) => c.root);
    },
    updateDisplaySearch() {
      this.displaySearch = !this.displaySearch;
      if (this.displaySearch) {
        this.$nextTick(() => {
          const searchIputDom =
            this.$refs['searchInput'].$el.children[0].children[1];
          searchIputDom.focus();
        });
      }
    },
  },
  beforeDestroy() {
    this.launchSearchDebounce.flush();
  },
};
</script>

<style lang="scss" scoped>
.knowledge-cases {
  :deep() .el-collapse-item {
    background: transparent;
  }
}

.section-title {
  color: $grey-6-mayday;
  cursor: pointer;
  margin-bottom: 0px;
}

.product__list {
  border-left: 2px solid $grey-4-mayday;
  margin-left: 1.5rem;
  padding-left: 10px !important;
  list-style-type: none;
}

.product__button {
  background-color: transparent;
  border: none;
  color: $blue-mayday;
}

.product__button--collapsed {
  color: $grey-5-mayday;
}

.product__manual__collapsable,
.product__manual__item-row {
  width: 100%;

  a .product__manual__link {
    margin-top: 2px;
    margin-bottom: 2px;
    width: 100%;
    color: $grey-9-mayday;
    &:hover {
      background-color: $grey-1-mayday;
      border-radius: 2px;
    }
  }
}

.content__result__link {
  border-left: 2px solid $grey-4-mayday;
  padding-left: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
}

@media only screen and (min-device-width: 1024px) {
  .content-label-row {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
    &:hover {
      overflow: visible;
      white-space: unset;
    }
  }
}

.Article {
  &:hover {
    border-color: $yellow-mayday;
  }
}

.Diagnostic {
  &:hover {
    border-color: $blue-mayday;
  }
}

.content__list {
  margin-left: 1.5rem;
  padding-inline-start: 0px;
}

.content__list__item-row {
  list-style: none;
  &:hover {
    background-color: $grey-1-mayday;
    border-radius: 2px;
    cursor: pointer;
    & .content-icon {
      color: $blue-mayday;
    }
  }
}

.search-field {
  border-radius: 4px;

  :deep() .form-control {
    height: 26px;
  }
  :deep() .input-group-text {
    padding: 0 8px;
  }
}

.content-icon {
  max-width: 16px;
  max-height: 16px;
}

.display-more {
  cursor: pointer;
  &:hover {
    color: $blue-mayday !important;
  }
}

.label__icon {
  width: 16px;
  height: 16px;
}

.search__button {
  background-color: transparent;
  border: none;
  color: $grey-5-mayday;
}

.search__button--active {
  color: $blue-mayday;
}

.trigger-search-field {
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}
</style>
