<template>
  <div>
    <div class="mb-2 text-sm">
      {{
        $t(
          'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.access.smart-action-description',
        )
      }}
    </div>
    <div class="d-flex flex-column gap-2">
      <plugin-access-settings-drawer
        v-for="(currentPlugin, index) in currentPluginEntities"
        :key="`${currentPlugin.id}_${index}`"
        :index="index"
        :title="currentPlugin.title"
        :accessRestrictionsIds="currentPlugin.accessRestrictions"
        @update-access-restriction="
          (roleIds) => handleUpdateAccessRestriction(currentPlugin.id, roleIds)
        "
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import PluginAccessSettingsDrawer from './PluginAccessSettingsDrawer';

export default defineComponent({
  name: 'smart-action-access-settings',
  components: { PluginAccessSettingsDrawer },
  props: {
    currentPluginEntities: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleUpdateAccessRestriction(id, roleIds) {
      const payload = {
        id,
        roleIds,
      };
      this.$emit('update-access-restriction', payload);
    },
  },
});
</script>
