var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-row ml-2 quick-filter-wrapper"},[_c('el-popover',{attrs:{"popper-class":"p-0 mt-1","placement":"bottom-start"},model:{value:(_vm.datesPopover),callback:function ($$v) {_vm.datesPopover=$$v},expression:"datesPopover"}},[_c('DateFilterPicker',{attrs:{"chosen-dates":_vm.chosenDates[0],"main-options":_vm.datesMainOptions,"sub-options":_vm.datesSubOptions},on:{"set-dates":function($event){return _vm.$emit('set-dates', $event)},"quit-dates":function($event){_vm.datesPopover = false}}}),_c('div',{staticClass:"d-flex flex-row align-items-center quick-filter cursor-pointer",class:{
        'quick-filter__selected': _vm.chosenDates.length || _vm.datesPopover,
      },attrs:{"slot":"reference"},slot:"reference"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fal', 'calendar']}}),_c('div',{staticClass:"filter-title"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.search-admin.quick-filters.dates.title'))+" ")]),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fal', `chevron-${_vm.datesPopover ? 'up' : 'down'}`]}})],1)],1),_c('el-popover',{attrs:{"popper-class":"p-0 mt-1","placement":"bottom-start"},model:{value:(_vm.userPopover),callback:function ($$v) {_vm.userPopover=$$v},expression:"userPopover"}},[_c('UserFilterPicker',{attrs:{"chosen-user":_vm.chosenUser[0]},on:{"set-user":function($event){return _vm.$emit('set-user', $event)},"quit-user":function($event){_vm.userPopover = false}}}),_c('div',{staticClass:"d-flex flex-row align-items-center quick-filter cursor-pointer",class:{
        'quick-filter__selected': _vm.chosenUser.length || _vm.userPopover,
      },attrs:{"slot":"reference"},slot:"reference"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fal', 'user']}}),_c('div',{staticClass:"filter-title"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.search-admin.quick-filters.user.title'))+" ")]),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fal', `chevron-${_vm.userPopover ? 'up' : 'down'}`]}})],1)],1),_c('el-popover',{attrs:{"popper-class":"p-0 mt-1","placement":"bottom-start"},model:{value:(_vm.hasVerificationPolicyPopover),callback:function ($$v) {_vm.hasVerificationPolicyPopover=$$v},expression:"hasVerificationPolicyPopover"}},[_c('BooleanFilterPicker',{attrs:{"hasVerificationPolicy":_vm.hasVerificationPolicy[0]},on:{"set-has-verification-policy":function($event){return _vm.$emit('set-has-verification-policy', $event)},"quit-has-verification-policy":function($event){_vm.hasVerificationPolicyPopover = false}}}),_c('div',{staticClass:"d-flex flex-row align-items-center quick-filter cursor-pointer",class:{
        'quick-filter__selected':
          _vm.hasVerificationPolicy.length || _vm.hasVerificationPolicyPopover,
      },attrs:{"slot":"reference"},slot:"reference"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fal', 'cog']}}),_c('div',{staticClass:"filter-title"},[_vm._v(" "+_vm._s(_vm.$t( 'knowledge.search-admin.quick-filters.verification-policy.has.title', ))+" ")]),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":[
          'fal',
          `chevron-${_vm.hasVerificationPolicyPopover ? 'up' : 'down'}`,
        ]}})],1)],1),(_vm.displayVerificationPolicyFilters)?_c('el-popover',{attrs:{"popper-class":"p-0 mt-1","placement":"bottom-start"},model:{value:(_vm.verificationPolicyDueDatePopover),callback:function ($$v) {_vm.verificationPolicyDueDatePopover=$$v},expression:"verificationPolicyDueDatePopover"}},[_c('DateFilterPicker',{attrs:{"chosen-dates":_vm.chosenVerificationPolicyDueDate[0],"main-options":_vm.verificationDueDateMainOptions,"sub-options":_vm.dueDatesSubOptions},on:{"set-dates":function($event){return _vm.$emit('set-verification-due-date', $event)},"quit-dates":function($event){_vm.verificationPolicyDueDatePopover = false}}}),_c('div',{staticClass:"d-flex flex-row align-items-center quick-filter cursor-pointer",class:{
        'quick-filter__selected':
          _vm.chosenVerificationPolicyDueDate.length ||
          _vm.verificationPolicyDueDatePopover,
      },attrs:{"slot":"reference"},slot:"reference"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fal', 'calendar']}}),_c('div',{staticClass:"filter-title"},[_vm._v(" "+_vm._s(_vm.$t( 'knowledge.search-admin.quick-filters.verification-policy.due-date.title', ))+" ")]),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":[
          'fal',
          `chevron-${_vm.verificationPolicyDueDatePopover ? 'up' : 'down'}`,
        ]}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }