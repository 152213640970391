<template>
  <div :class="`${baseClass} my-3`">
    <ContextVariablesDrawerDeletion
      v-if="ongoingDeletion"
      :variable="variableToDelete"
      @cancel="handleCancel"
      @delete="handleDelete(variableToDelete._id)"
    />

    <div :class="`${baseClass}__editor`" v-else v-loading="mainLoader">
      <ContextVariablesHeader :class="`${baseClass}__editor__header`" />
      <div :class="`${baseClass}__editor__variables`">
        <ContextVariable
          v-for="variable in sortedVariables"
          :key="variable._id"
          :variable="variable"
          @change="handleChange(variable._id, $event)"
          @delete="handleDeletionRequest(variable._id)"
        />
        <el-button
          size="mini"
          type="primary"
          plain
          :class="`${baseClass}__editor__add`"
          @click="addContextVariable()"
          :disabled="disableNewContextVariable"
          >{{ $t(`${pre}.add`) }}</el-button
        >
      </div>
    </div>

    <div :class="`${baseClass}__submission`">
      <el-button type="secondary" size="mini" @click="$emit('close')">
        {{ $t('generic.close') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { randomString } from '@/components/stringUtils';
import ContextVariable from './ContextVariable';
import ContextVariablesHeader from './ContextVariablesHeader';
import ContextVariablesDrawerDeletion from './ContextVariablesDrawerDeletion';

const baseClass = 'context-variables-drawer';
const i18nPre = 'settings.card-settings-options.context-variables.drawer';
export default {
  name: 'context-variables-drawer',
  components: {
    ContextVariablesHeader,
    ContextVariable,
    ContextVariablesDrawerDeletion,
    // ContextVariablesDrawerVariables,
  },
  computed: {
    sortedVariables() {
      if (!this.contextVariables) return [];
      const variables = [...this.contextVariables];
      return variables.sort((a, b) => {
        if (a.isNew) return false;
        return a.name.localeCompare(b.name);
      });
    },
    disableNewContextVariable() {
      return !!this.contextVariables.find((el) => el.isNew);
    },
  },
  async mounted() {
    await this.loadContextVariables();
  },
  data() {
    return {
      baseClass,
      contextVariables: [],
      pre: i18nPre,
      loaderTimeout: null,
      mainLoader: false,
      ongoingDeletion: false,
      variableToDelete: null,
    };
  },
  methods: {
    addContextVariable() {
      this.contextVariables.push({
        _id: randomString(8),
        name: '',
        path: '',
        isNew: true,
      });
    },
    async loadContextVariables() {
      try {
        this.startMainLoader();
        const contextVariables = await this.getContextVariables();
        this.contextVariables = contextVariables;
      } finally {
        this.stopMainLoader();
      }
    },
    prepareContextVariable(contextVariable) {
      if (!contextVariable) return null;
      const { name, path } = contextVariable;
      return {
        name,
        path,
        type: 'string',
      };
    },
    hydrateContextVariables(variableId, newContextVariable) {
      if (newContextVariable) {
        this.contextVariables = this.contextVariables.map((el) => {
          if (el._id != variableId) return el;
          return newContextVariable;
        });
      } else {
        this.contextVariables = this.contextVariables.filter(
          (el) => el._id != variableId,
        );
      }
    },
    handleChange(variableId, contextVariable) {
      if (contextVariable.isNew) return this.handleCreate(contextVariable);
      return this.handleUpdate(variableId, contextVariable);
    },
    async handleCreate(contextVariable) {
      try {
        this.startLoader();
        const temporaryId = contextVariable._id;
        const newContextVariable = await this.createContextVariable(
          this.prepareContextVariable(contextVariable),
        );
        this.hydrateContextVariables(temporaryId, newContextVariable);
        this.sendMessage('success');
      } catch (err) {
        this.sendMessage('error');
      } finally {
        this.stopLoader();
      }
    },
    async handleUpdate(variableId, contextVariable) {
      try {
        this.startLoader();
        const newContextVariable = await this.updateContextVariable({
          variableId,
          contextVariable: this.prepareContextVariable(contextVariable),
        });
        this.hydrateContextVariables(variableId, newContextVariable);
        this.sendMessage('success');
      } catch (err) {
        this.sendMessage('error');
      } finally {
        this.stopLoader();
      }
    },
    handleDeletionRequest(variableId) {
      const variable = this.contextVariables.find(
        (el) => el._id === variableId,
      );
      if (variable.isNew) return this.hydrateContextVariables(variableId, null);
      this.ongoingDeletion = true;
      this.variableToDelete = variable;
    },
    handleCancel() {
      this.loadContextVariables();
    },
    async handleDelete(variableId) {
      try {
        this.startLoader();
        await this.deleteContextVariable(variableId);
        this.hydrateContextVariables(variableId, null);
        this.sendMessage('success');
      } catch (err) {
        this.sendMessage('error');
      } finally {
        this.stopLoader();
        this.ongoingDeletion = false;
        this.variableToDelete = null;
      }
    },
    startLoader() {
      this.loaderTimeout = setTimeout(() => {
        this.stopLoader();
        this.sendMessage('error');
      }, 5000);
    },
    stopLoader() {
      clearTimeout(this.loaderTimeout);
      this.loaderTimeout = null;
    },
    startMainLoader() {
      this.mainLoader = true;
      this.mainLoaderTimeout = setTimeout(() => {
        this.stopMainLoader();
        this.sendMessage('error', 'error-loading');
      }, 5000);
    },
    stopMainLoader() {
      this.mainLoader = false;
      clearTimeout(this.mainLoaderTimeout);
      this.mainLoaderTimeout = null;
    },
    sendMessage(type, key) {
      this.$message({
        duration: 2000,
        type,
        message: this.$t(`${this.pre}.${key || type}`),
      });
    },
    ...mapActions('knowledgeModule', [
      'getContextVariables',
      'createContextVariable',
      'updateContextVariable',
      'deleteContextVariable',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.context-variables-drawer {
  width: 100%;
  &__editor {
    &__variables {
      flex-direction: column;
      align-content: space-between;
      max-height: 70vh;
      overflow: auto;

      &__header {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        position: sticky;
        top: 0px;
        z-index: 1;
        background-color: white;
      }

      &__add {
        width: fit-content;
      }
    }
  }
  &__submission {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
</style>
