<template>
  <div>
    <!-- SHOW LABEL -->
    <base-button
      v-if="!edit"
      class="diag-step mt-1 mb-1"
      outline
      :disabled="redirectionIsDeleted"
      type="primary"
      @click="buttonClick()"
    >
      <div v-if="content">
        <el-tooltip
          v-if="isKeyStep && hasAgentRestrictions"
          :content="this.$t('knowledge.child.key-step.tooltip')"
          placement="top"
          class="group-tooltip"
        >
          <font-awesome-icon
            class="key-step-icon my-auto"
            :icon="['fad', 'key']"
          ></font-awesome-icon>
        </el-tooltip>
        <el-tooltip
          v-if="hasRedirection"
          :content="
            redirectionIsDeleted
              ? $t('knowledge.child.redirect.tooltip.is-deleted')
              : $t('knowledge.child.redirect.tooltip.info')
          "
          placement="top"
          class="group-tooltip"
        >
          <font-awesome-icon
            class="key-step-icon my-auto"
            :icon="['fa', 'external-link-square']"
          ></font-awesome-icon>
        </el-tooltip>
        {{ content.label }}
        <TranslationTooltip
          v-if="!isTranslated"
          class="d-inline-flex ml-2"
          is-content
          :is-translated="isTranslated"
          :has-translated-contents="false"
        />
      </div>
      <i v-else class="fas fa-spinner fa-spin"></i>
    </base-button>

    <!-- EDIT LABEL -->
    <div v-show="edit">
      <input
        class="col-auto add-step-input mt-1 mb-1"
        placeholder="Nom de la nouvelle étape"
        ref="childLabelInput"
        v-model="label"
        @blur="updateLabel()"
        @keyup.enter="$event.target.blur()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TranslationTooltip from '@/components/Commons/TranslationTooltip';

export default {
  name: 'child-button-label-editor',
  components: {
    TranslationTooltip,
  },
  props: {
    content: Object,
    isKeyStep: {
      type: Boolean,
      default: false,
    },
    hasRedirection: {
      type: Boolean,
      default: false,
    },
    edit: Boolean,
  },
  data() {
    return {
      label: '',
    };
  },
  computed: {
    isTranslated() {
      return this.content.returnLanguage === this.navigationLanguage;
    },
    isTemplate() {
      return this.$route.path.includes('templates');
    },
    redirectionIsDeleted() {
      return this.content.redirection && this.content.redirection.isDeleted;
    },
    ...mapGetters(['hasAgentRestrictions']),
    ...mapGetters('knowledgeModule', ['navigationLanguage']),
  },
  methods: {
    buttonClick() {
      if (this.content) this.$emit('click');
    },
    updateLabel() {
      this.$emit('update-content', {
        label: this.label,
        title: this.label,
      });
    },
  },
  watch: {
    edit() {
      if (this.edit) {
        this.label = this.content.label;
        this.$nextTick(() => this.$refs.childLabelInput.focus());
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.diag-steps {
  display: block;
}

.add-step-input {
  letter-spacing: 0.025em;
  display: block;
  width: 190px;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
  color: #adb5bd;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.add-step-input::placeholder {
  color: lightgrey;
}

.add-step-input:focus {
  outline: none;
  border-color: $blue-mayday;
  color: $blue-mayday;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  will-change: transform;
}
</style>
