<template>
  <div>
    <component
      v-for="subItem in permissionItem.subItems"
      :is="switchComponent(subItem.type)"
      :key="subItem.title"
      :sub-item="subItem"
      :is-opened="isOpened(subItem.relatedPermissions)"
      :is-scoped="isScoped(subItem.relatedPermissions)"
      :role-permissions="rolePermissions"
      @switch-change="$emit('update-role-permissions', $event)"
      @checkbox-change="$emit('check-role-permissions', $event)"
    />
  </div>
</template>

<script>
import RoleDrawerSimpleSwitch from '../RoleDrawerSimpleSwitch.vue';
import RoleDrawerScopedSwitch from '../RoleDrawerScopedSwitch.vue';
import RoleDrawerMultipleSwitch from '../RoleDrawerMultipleSwitch.vue';

export default {
  name: 'role-drawer-feedbacks-tab',
  components: {
    RoleDrawerSimpleSwitch,
    RoleDrawerScopedSwitch,
    RoleDrawerMultipleSwitch,
  },
  props: {
    rolePermissions: {
      type: Array,
      default: () => [],
    },
    permissionItem: {
      type: Object,
      required: true,
    },
  },
  methods: {
    switchComponent(type) {
      return `RoleDrawer${type || 'Simple'}Switch`;
    },
    isOpened(relatedPermissions) {
      return this.rolePermissions.some((permission) =>
        relatedPermissions.includes(permission.rolePermission),
      );
    },
    isScoped(relatedPermissions) {
      return this.rolePermissions.some(
        ({ rolePermission, scope }) =>
          relatedPermissions.includes(rolePermission) &&
          this.isScopeDefined(scope, ['viewIds']),
      );
    },
    isScopeDefined(scope, keysToCheck) {
      if (!scope) return false;

      return keysToCheck.some((key) => scope[key] && scope[key].length);
    },
  },
};
</script>

<style lang="scss" scoped></style>
