<template>
  <div class="update-user-label-modal">
    <modal :show.sync="localDisplay">
      <div class="row d-flex mt-5 justify-content-center">
        <div class="col-11 text-center">
          <modal-header
            :style-name="{ backgroundColor: '#0081F91A' }"
            class="mb-3"
          >
            <div slot="icon" class="d-inline-flex">
              <font-awesome-icon
                class="icon blue-mayday"
                :icon="['fad', 'tags']"
              />
            </div>
            <div class="mt-3">
              <div>
                <p class="mb-0 font-weight-bold">
                  {{
                    $tc(
                      'components.modals.update-user-label.explaination',
                      multipleUpdate,
                    )
                  }}
                </p>
              </div>
              <el-divider class="my-3"></el-divider>
              <small class="text-primary">
                {{ $t('components.modals.update-user-label.description') }}
              </small>
            </div>
          </modal-header>
          <div class="flex-column d-flex justify-content-center subcontent">
            <p class="my-3">
              {{ $t('components.modals.update-user-label.action') }}
            </p>
            <div class="mb-4 text-center">
              <el-select
                v-model="selectedValues"
                multiple
                @input="handleInputChange"
                class="col-12 label-select"
                :placeholder="
                  $t('components.modals.update-user-label.choose-placeholder')
                "
              >
                <el-option-group
                  v-for="category in categories"
                  :key="category.id"
                  :value="category.id"
                  class="label-select"
                >
                  <div class="option-group-title">
                    <ColoredIcon
                      class="option-icon"
                      :icon="category.icon"
                      :color="category.color"
                    />
                    {{ category.name }}
                  </div>
                  <el-option
                    v-for="label in category.companyUserLabels"
                    :key="label.id"
                    :label="label.name"
                    :value="label.id"
                  ></el-option>
                </el-option-group>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div class="row"></div>
      <template slot="footer">
        <el-button type="danger" size="small" @click="$emit('close')">{{
          $t('components.modals.update-user-label.cancel-button')
        }}</el-button>
        <el-button
          type="primary"
          outline
          size="small"
          @click="handleUpdateLabels"
        >
          {{ $t('components.modals.update-user-label.confirm-button') }}
        </el-button>
      </template>
    </modal>
  </div>
</template>

<script>
import ColoredIcon from 'components/Icons/ColoredIcon';
import Modal from '@/components/Modal';
import ModalHeader from '@/components/Modals/ModalHeader';
export default {
  components: {
    ColoredIcon,
    Modal,
    ModalHeader,
  },
  props: {
    display: {
      type: Boolean,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    focusLabelIds: {
      type: Array,
    },
    appendToBody: {
      type: Boolean,
      default: () => false,
    },
    multipleUpdate: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      localDisplay: this.display,
      selectedValues: [],
    };
  },
  mounted() {
    if (this.focusLabelIds) {
      this.selectedValues = this.focusLabelIds;
    }
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
  computed: {
    labelToCategoryLookup() {
      const lookup = new Map();
      this.categories.forEach((category) => {
        category.companyUserLabels.forEach((label) => {
          lookup.set(label.id, category.id);
        });
      });
      return lookup;
    },
  },
  methods: {
    handleInputChange(e) {
      this.selectedValues = [...this.uniqueValuePerCategory(e).values()];
    },
    handleUpdateLabels() {
      this.$emit('update-user-labels', this.selectedValues);
    },
    uniqueValuePerCategory(newSelectedValues) {
      const lookup = new Map();
      newSelectedValues.forEach((labelId) => {
        lookup.set(this.labelToCategoryLookup.get(labelId), labelId);
      });
      return lookup;
    },
  },
};
</script>

<style lang="scss" scoped>
.label-modal-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.1);
  margin-bottom: 16px;
  .empty-label-icon {
    max-height: 60px;
    margin-top: -30px;
  }
  .explanation {
    word-break: break-word;
    margin-top: 8px;
  }
  .assign-label-subtitle {
    word-break: break-word;
  }
}

.label-select-container {
  padding: 16px;
  border: 1px solid $blue-mayday;
  border-radius: 4px;
}
.content {
  display: flex;
  flex-direction: column;
  text-align: center;
  word-wrap: normal;
  margin: auto 24px;
}
.option-group-title {
  display: flex;
  margin-left: 12px;
  .option-icon {
    margin-right: 4px;
  }
}
.el-select-dropdown__item {
  margin-left: 16px;
}

.label-select {
  :deep() .el-tag {
    background-color: rgba($blue-mayday, 0.1);
    box-shadow: unset;
    border-color: $blue-mayday;
    border-radius: 4px;
    color: $blue-mayday;
  }
  :deep() .el-tag__close {
    color: $blue-mayday !important;
  }
}

.content-primary-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.05);
}

.blue-mayday {
  color: $blue-mayday;
}

.icon {
  width: 24px;
  height: 24px;
}

.subcontent {
  border: 1px solid $blue-mayday;
  border-radius: 2px;
}
</style>
