<template>
  <div class="concepts-list">
    <div class="empty" v-if="assignedConceptIds.length == 0">
      {{ $t('concepts.content.empty') }}
    </div>
    <div
      class="concepts-list-item"
      v-for="(concept, i) in assignedConcepts"
      :key="`${i}:${concept.conceptId}`"
    >
      <ConceptListItem
        :translations="concept.translations"
        :status="concept.status"
        :id="concept.conceptId"
        :defaultLanguage="concept.defaultLanguage"
      >
      </ConceptListItem>
    </div>
    <div
      class="concepts-list-item"
      v-for="(concept, i) in rejectedConcepts"
      :key="`${i}:${concept.conceptId}`"
    >
      <ConceptListItem
        :translations="concept.translations"
        :status="concept.status"
        :id="concept.conceptId"
        :defaultLanguage="concept.defaultLanguage"
      >
      </ConceptListItem>
    </div>
    <ConceptListAddItem :conceptIds="assignedConceptIds"></ConceptListAddItem>
    <span v-if="hasRejected" class="show" @click="toggleShowRejected">{{
      showRejected
        ? $t('concepts.content.hide-rejected')
        : $t('concepts.content.show-rejected')
    }}</span>
  </div>
</template>

<script>
import ConceptListItem from './ConceptListItem.vue';
import ConceptListAddItem from './ConceptListAddItem.vue';
import { mapGetters } from 'vuex';
import getTranslation from '@/utils/getTranslation';

export default {
  components: { ConceptListItem, ConceptListAddItem },
  data() {
    return {
      showRejected: false,
    };
  },
  methods: {
    resolveField(concept, field) {
      return getTranslation(concept, [this.editingLanguage])[field];
    },
    toggleShowRejected() {
      this.showRejected = !this.showRejected;
    },
  },
  computed: {
    ...mapGetters('knowledgeModule', [
      'focusContentConcepts',
      'editingLanguage',
    ]),
    assignedConceptIds() {
      return this.focusContentConcepts
        .filter((concept) => concept.status !== 'rejected')
        .map((d) => d.conceptId);
    },
    hasRejected() {
      return this.focusContentConcepts.some(
        (concept) => concept.status === 'rejected',
      );
    },
    assignedConcepts() {
      return this.focusContentConcepts
        .filter((concept) => concept.status !== 'rejected')
        .sort((a, b) => {
          if (a.status === 'validated' && b.status !== 'validated') {
            return -1;
          }
          if (b.status === 'validated' && a.status !== 'validated') {
            return 1;
          }
          return this.resolveField(a, 'label').localeCompare(
            this.resolveField(b, 'label'),
          );
        });
    },
    rejectedConcepts() {
      if (!this.showRejected) return [];
      return this.focusContentConcepts
        .filter((concept) => concept.status === 'rejected')
        .sort((a, b) =>
          this.resolveField(a, 'label').localeCompare(
            this.resolveField(b, 'label'),
          ),
        );
    },
  },
};
</script>

<style scoped lang="scss">
.concepts-list {
  display: inline-flex;
  gap: 5px;
  flex-wrap: wrap;
}

.show {
  font-size: 10px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: $blue-5-mayday;
  }
}

.empty {
  font-size: 10px;
}
</style>
