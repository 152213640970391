<template>
  <div class="d-flex justify-content-end">
    <!-- CANCEL -->
    <base-button @click="sendCloseEvent" type="secondary" size="sm">{{
      $t('knowledge.modals.notifications.cancel')
    }}</base-button>

    <!-- SUBMIT -->
    <base-button
      :disabled="!CanUserDeleteCase"
      @click="sendDeleteCaseEvent"
      type="primary"
      size="sm"
      >{{
        $t(
          'settings.card-settings-options.case-parameters.modals.delete.delete-bis',
        )
      }}</base-button
    >
  </div>
</template>

<script>
export default {
  name: 'delete-content-label-modal-footer',
  props: {
    CanUserDeleteCase: Boolean,
  },
  methods: {
    sendCloseEvent() {
      this.$emit('close');
    },
    sendDeleteCaseEvent() {
      this.$emit('delete');
    },
  },
};
</script>
