<template>
  <div>
    <div class="element-title">
      {{ $t('settings.card-settings-options.global-parameters.subtitle') }}
    </div>
    <div class="element-description">
      {{
        $t('settings.card-settings-options.global-parameters.subdescription')
      }}
    </div>

    <KnowledgeParameters />
  </div>
</template>

<script>
import KnowledgeParameters from '@/components/Commons/KnowledgeParameters/KnowledgeParameters';

export default {
  name: 'global-parameters',
  components: { KnowledgeParameters },
};
</script>
<style lang="scss" scoped>
.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
</style>
