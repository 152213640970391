<template>
  <div class="ask-empty-state">
    <div v-if="displaySearchInput">
      <AskBox
        :title="
          isTriggered
            ? 'ask.search.current-question'
            : 'ask.search.suggested-question'
        "
        :icon="['fal', 'comments']"
        ><template v-slot:content>
          <AskSuggestedQuestions
            :questions="[searchInput]"
            :clickable="!isTriggered"
            @trigger-ask="triggerAskFromSuggested"
          ></AskSuggestedQuestions> </template
      ></AskBox>
    </div>
    <div v-else>
      <AskBox
        v-if="lastQuestion"
        :title="'ask.search.latest-search'"
        :icon="['fal', 'clock']"
        class="mb-3"
        ><template v-slot:content>
          <AskSuggestedQuestions
            :questions="[lastQuestion]"
            :clickable="!isTriggered"
            @trigger-ask="$emit('latest-search')"
          ></AskSuggestedQuestions> </template
      ></AskBox>
      <AskBox
        v-if="
          filteredTryOutQuestions.length ||
          tryOutQuestionsLoading ||
          tryOutQuestionsError
        "
        :title="'ask.search.try-outs'"
        :icon="['fal', 'lightbulb']"
        ><template v-slot:content>
          <AskSkeletonLoader
            class="p-3"
            v-if="tryOutQuestionsLoading"
          ></AskSkeletonLoader>
          <div
            v-else-if="tryOutQuestionsError"
            class="d-flex flex-row align-items-center justify-start gap-2 p-3 source-error-icon"
          >
            <font-awesome-icon :icon="['fal', 'exclamation-triangle']" />
            <div class="error message">
              {{ $t('ask.search.try-outs-error') }}
            </div>
          </div>

          <AskSuggestedQuestions
            v-else
            :questions="filteredTryOutQuestions"
            :clickable="!isTriggered"
            @trigger-ask="triggerAskFromTryOuts"
          ></AskSuggestedQuestions> </template
      ></AskBox>
    </div>
  </div>
</template>

<script>
import AskBox from './AskBox.vue';
import AskSuggestedQuestions from './AskSuggestedQuestions.vue';
import AskSkeletonLoader from './AskSkeletonLoader.vue';

export default {
  name: 'ask-empty-state',
  components: { AskSuggestedQuestions, AskBox, AskSkeletonLoader },
  props: {
    searchInput: {
      type: String,
      default: '',
    },
    lastQuestion: { type: String, default: '' },
    isTriggered: {
      type: Boolean,
      default: false,
    },
    tryOutQuestionsLoading: {
      type: Boolean,
      default: false,
    },
    tryOutQuestionsError: {
      type: Boolean,
      default: false,
    },
    isQuestion: { type: Boolean, default: false },
    tryOutQuestions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    displaySearchInput() {
      return this.isQuestion && this.searchInput.length > 0;
    },
    filteredTryOutQuestions() {
      return this.tryOutQuestions.filter(
        (question) => this.lastQuestion !== question,
      );
    },
  },
  methods: {
    handleTriggerAsk(e) {
      this.$emit('trigger-ask', e);
    },
    triggerAskFromTryOuts(e) {
      this.handleTriggerAsk({ question: e, triggerSource: 'try_out_question' });
    },
    triggerAskFromSuggested(e) {
      this.handleTriggerAsk({
        question: e,
        triggerSource: 'suggested_question',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-empty-state {
  margin-top: 20px;
}
.source-error-icon {
  font-size: medium;
  color: $red-mayday;
}
</style>
