<template>
  <div class="side-container">
    <div class="side-title">
      {{ $t('settings.users-administration.drawer.main.informations') }}
    </div>
    <div class="date-since">
      {{ $t('settings.users-administration.drawer.side.user-since') }}
      <div class="date-since date">
        {{ formattedCreatedDate }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'user-drawer-side',
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    formattedCreatedDate() {
      var date = new Date(parseInt(this.user.createdAt));
      return (
        String(date.getDate()).padStart(2, '0') +
        '/' +
        String(date.getMonth() + 1).padStart(2, '0') +
        '/' +
        String(date.getFullYear())
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.side-container {
  width: 100%;
  padding-top: 4.5rem;
  padding-inline: 30px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.side-title {
  font-size: 16px;
  font-weight: bold;
  padding: 8px 0;
}

.side-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.side-item {
  font-size: 12px;
  font-weight: normal;
  padding: 4px 0;
  span {
    margin-left: 10px;
  }
}

.date-since {
  color: $grey-8-mayday;
  .date {
    font-weight: 700;
  }
}
</style>
