<template>
  <span class="pl-2 search-values">
    {{
      $t('knowledge.product.search-results', {
        resultCount,
        searchFilter,
      })
    }}
    <font-awesome-icon
      :icon="['fal', 'times']"
      class="delete-icon"
      @click="$emit('reset-search')"
    />
  </span>
</template>

<script>
export default {
  props: {
    resultCount: {
      type: Number,
      required: true,
    },
    searchFilter: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-icon {
  cursor: pointer;
  padding-left: 4px;
  width: 12px;
  height: 12px;
}

.delete-icon:hover {
  color: $blue-mayday;
}
</style>
