<template>
  <div class="mb-2">
    <settings-cta
      :title="`${pre}.context-variables.title`"
      :description="`${pre}.context-variables.description`"
      :cta="`${pre}.context-variables.cta`"
      @click="isOpen = true"
    ></settings-cta>
    <setting-drawer
      v-if="currentIntegration"
      :show="!!currentIntegration"
      :integration="currentIntegration"
      @close="closeDrawer()"
      @update-integration="activeIntegrations = $event"
    >
      <template v-slot:default="scope">
        <component
          :is="currentIntegration.is"
          :integration="currentIntegration"
          @close="scope.close()"
        />
      </template>
    </setting-drawer>
  </div>
</template>

<script>
import SettingsCta from '@/components/Settings/SettingsCta';
import ContextVariablesDrawer from './ContextVariables/ContextVariablesDrawer.vue';
import IntegrationSettingsDrawer from '@/components/Settings/IntegrationSettings/IntegrationSettingsDrawer/IntegrationSettingsDrawer.vue';

const pre = 'settings.card-settings-options';

export default {
  name: 'context-variables',
  props: {
    children: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    'setting-drawer': IntegrationSettingsDrawer,
    SettingsCta,
    ContextVariablesDrawer,
  },

  computed: {
    currentIntegration() {
      if (!this.isOpen) return null;
      const component = `${this.focus.component}Drawer`;
      const i18nPrefix = `${pre}.${this.focus.i18nPre}`;
      return {
        is: component,
        label: this.$t(`${i18nPrefix}.drawer.label`),
        description: this.$t(`${i18nPrefix}.drawer.description`),
        logo: this.focus.icon,
        raw: true,
        active: true,
        features: [],
        links: [],
      };
    },
  },

  data() {
    return {
      pre: Object.freeze(pre),
      isOpen: false,
      focus: {
        title: 'settings-layout.menu-data.authentication.ip-restriction.title',
        name: 'ContextVariables',
        i18nPre: 'context-variables',
        icon: ['fas', 'code'],
        component: 'ContextVariables',
      },
    };
  },

  methods: {
    closeDrawer() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
