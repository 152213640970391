<template>
  <el-select
    v-model="checkedViewIds"
    v-loading="isLoading"
    class="hub-views-viewer"
    @change="
      $emit('update-scope', $event && $event.length ? { viewIds: $event } : {})
    "
    collapse-tags
    filterable
    multiple
    :placeholder="
      $t('settings.permissions.role-drawer.feedbacks.view-placeholder')
    "
  >
    <div slot="prefix" class="icon-wrapper">
      <font-awesome-icon :icon="['fal', 'search']" />
    </div>
    <el-option
      v-for="view in hubViews"
      :key="view.id"
      :label="view.name"
      :value="view.id"
      class="view-option"
    >
      <div class="view-title">{{ view.name }}</div>
      <div v-if="view.description" class="view-description">
        {{ view.description }}
      </div>
    </el-option>
  </el-select>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'role-drawer-hub-views-viewer',
  props: {
    scope: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      checkedViewIds: [],
      isLoading: true,
      hubViews: [],
    };
  },
  async mounted() {
    this.isLoading = true;
    this.checkedViewIds = this.scope.viewIds || [];
    const { views } = await this.getTaskViews();
    this.hubViews = views || [];
    this.isLoading = false;
  },
  methods: {
    ...mapActions('taskModule', ['getTaskViews']),
  },
  watch: {
    scope() {
      this.checkedViewIds = this.scope.viewIds || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.hub-views-viewer {
  display: flex;
  flex-direction: column;
  :deep() .el-select__input {
    margin-left: 0;
  }
  :deep() .el-select__tags {
    padding-left: 32px;
  }

  :deep() .el-tag {
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.icon-wrapper {
  width: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.view-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  div {
    height: fit-content;
  }
  .view-title {
    font-size: 14px;
  }
  .view-description {
    font-size: 12px;
    margin-top: -16px;
    color: $grey-5-mayday;
  }
}
</style>
