<template>
  <div class="content-view-settings-plugins-custom">
    <!-- CUSTOM -->
    <DrawerRowTitle
      :title="
        $t(
          'knowledge.drawers.content-view-setting.settings.plugins.custom.title',
        )
      "
      :tooltip="
        $t(
          'knowledge.drawers.content-view-setting.settings.plugins.custom.tooltip',
        )
      "
    />
    <div
      class="settings__plugin-item"
      v-for="plugin in plugins"
      :key="plugin.id"
    >
      <div class="label">
        {{ plugin.settings.title }}
        <el-tooltip :content="plugin.settings.tooltip" placement="top">
          <font-awesome-icon
            :icon="['fad', 'info-circle']"
            class="info-icon mx-2"
          />
        </el-tooltip>
        <div class="toggle">
          <not-allowed-tooltip
            :user-permissions="userPermissions"
            permission-key="canUpdateContent"
            placement="top"
          >
            <template v-slot:main-content="{ hasPermissions }">
              <el-switch
                v-model="pluginsSwitch[plugin.id]"
                @input="updateContentPlugins(plugin.id)"
                :disabled="!hasPermissions"
              ></el-switch>
            </template>
          </not-allowed-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DrawerRowTitle from '@/components/Drawers/Commons/DrawerRowTitle';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

export default {
  name: 'content-view-settings-plugins-custom',
  components: {
    DrawerRowTitle,
    NotAllowedTooltip,
  },
  props: {
    plugins: {
      type: Array,
      required: true,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
    contentPlugins: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    pluginsSwitch() {
      return this.plugins.reduce((acc, plugin) => {
        acc[plugin.id] = this.contentPlugins.includes(plugin.id);
        return acc;
      }, {});
    },
  },
  methods: {
    updateContentPlugins(id) {
      this.$emit('update-content-plugins', { id });
    },
  },
};
</script>

<style lang="scss" scoped>
.settings__plugin-item {
  margin-bottom: 12px;
}
.label {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}
.info-icon {
  color: $grey-5-mayday;
  font-size: 12px;
}
.toggle {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
