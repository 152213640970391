<template>
  <div class="menu-collapse p-3">
    <div
      :class="[
        'menu-collapse-header d-flex align-items-center',
        currentCollapse ? 'menu-collapse-title-open mb-2' : '',
      ]"
      @click.prevent="collapseItems"
    >
      <span class="menu-collapse-title mr-auto noselect">
        {{ this.title }}
      </span>
      <font-awesome-icon
        class="menu-collapse-icon justify-content-end"
        :icon="['fad', getCollapseIcon]"
      ></font-awesome-icon>
    </div>
    <div class="menu-collapse-content" v-if="currentCollapse">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'menu-collapse',
  props: {
    title: {
      type: String,
    },
    collapse: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentCollapse: null,
    };
  },
  computed: {
    getCollapseIcon() {
      return this.currentCollapse
        ? 'chevron-square-down'
        : 'chevron-square-right';
    },
  },
  created() {
    this.currentCollapse = this.collapse;
  },
  watch: {
    collapse(val) {
      this.currentCollapse = val;
    },
  },

  methods: {
    collapseItems() {
      this.$emit('collapsing');
      this.currentCollapse = !this.currentCollapse;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-collapse {
  background-color: white;
  border-radius: 5px;
}
.menu-collapse-header {
  width: 100%;
  cursor: pointer;
}
.menu-collapse-header {
  .menu-collapse-title {
    color: $grey-5-mayday;
    text-transform: uppercase;
    font-size: 0.8rem;
  }
  .menu-collapse-icon {
    color: $grey-5-mayday !important;
  }
}
.menu-collapse-title-open {
  .menu-collapse-title {
    color: $blue-mayday !important;
  }
  .menu-collapse-icon {
    color: $blue-mayday !important;
  }
}
</style>
