<template>
  <div>
    <DrawerRowTitle
      :title="
        $t('knowledge.drawers.content-view-setting.settings.labels.title')
      "
      :tooltip="
        $t('knowledge.drawers.content-view-setting.settings.labels.tooltip')
      "
    />
    <div class="d-flex">
      <div v-if="!isParametersLabels" class="my-2 labels-placeholder">
        {{ $t('knowledge.drawers.content-view-setting.settings.labels.empty') }}
      </div>

      <div v-else class="overflow-item" style="width: 100%">
        <not-allowed-tooltip
          :user-permissions="userPermissions"
          permission-key="canUpdateContent"
        >
          <template v-slot:main-content="{ hasPermissions }">
            <div class="section d-flex justify-content-center">
              <el-select
                multiple
                v-model="labelSelection"
                value-key="id"
                :placeholder="
                  $t(
                    'knowledge.drawers.content-view-setting.settings.labels.placeholder',
                  )
                "
                @change="updateLabels"
                class="icon-selector__select"
                :disabled="!hasPermissions"
              >
                <font-awesome-icon
                  slot="prefix"
                  class="select-icon-label"
                  :icon="['fad', 'tags']"
                />
                <el-option-group
                  v-for="category in labelCategories"
                  :key="category.label"
                  :label="category.label"
                >
                  <el-option
                    v-for="label in category.labels"
                    :key="label.id"
                    :label="label.labelName"
                    :value="label"
                  >
                    <font-awesome-icon
                      :icon="['fad', label.icon]"
                      class="label__icon mr-2"
                      :style="{ color: label.color }"
                    />
                    {{ label.labelName }}
                  </el-option>
                </el-option-group>
              </el-select>
            </div>
          </template>
        </not-allowed-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DrawerRowTitle from '@/components/Drawers/Commons/DrawerRowTitle';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

export default {
  name: 'content-view-settings-labels',
  components: {
    DrawerRowTitle,
    NotAllowedTooltip,
  },
  props: {
    selectedLabels: Array,
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      labelSelection: [],
      labelCategories: [],
      possibleLabels: [],
    };
  },
  computed: {
    isParametersLabels() {
      return this.possibleLabels.length > 0 ? true : false;
    },
  },
  async created() {
    const labels = await this.fetchContentParameterLabels();
    this.labelCategories = labels.reduce((acc, val) => {
      const { categoryName: groupLabel, ...label } = val;
      const categoryIdx = acc.findIndex((el) => el.label === groupLabel);
      if (categoryIdx > -1) {
        acc[categoryIdx].labels.push(label);
      } else {
        acc.push({ label: groupLabel, labels: [label] });
      }
      return acc;
    }, []);
    this.possibleLabels = labels;
    this.labelSelection = this.selectedLabels;
  },
  methods: {
    updateLabels(val) {
      this.$emit('update-labels', val);
    },
    ...mapActions('knowledgeModule', ['fetchContentParameterLabels']),
  },
};
</script>

<style lang="scss" scoped>
.text-small {
  color: $grey-5-mayday;
  font-size: 0.9em;
}

.labels-placeholder {
  line-height: 1;
  font-size: 12px;
  color: $grey-7-mayday;
}

.grey-six {
  color: $grey-6-mayday;
}

.section {
  background-color: $grey-1-mayday;
  border-radius: 4px;

  :deep() .el-cascader {
    width: 90%;
  }
}

.icon-selector__select {
  width: 100%;
  cursor: pointer;
}

:deep() .el-select .el-select__tags {
  padding-left: 2rem !important;
}

:deep() .el-select .el-select__tags .el-tag {
  opacity: 1;
}

:deep() .el-input--prefix .el-input__inner {
  padding-left: 2rem !important;
}

:deep() .el-input__prefix {
  left: 0;
  width: 2.2rem;
  height: 2rem;
  padding: 0;
  margin-top: 10px;
}

:deep() .el-tag__close {
  color: $grey-6-mayday !important;
  border: 1px solid $grey-6-mayday !important;
  height: 16px;
  width: 16px;
  font-size: 12px;
  line-height: 1;
}

:deep() .el-tag__close:hover {
  color: #fff !important;
  background-color: $blue-mayday !important;
  border: 1px solid $blue-mayday !important;
}

.text-small {
  color: $grey-5-mayday;
  font-size: 0.9em;
}

.select-icon-label {
  color: $grey-5-mayday;
  font-size: 14px;
  margin-top: -4px;
  margin: auto;
  line-height: 0;
}

.overflow-item {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}
</style>
