<template>
  <div
    :class="['https-warning d-flex align-items-top w-100']"
    v-if="focusKnowledgeIsPublic"
  >
    <div class="h-100 mr-2">
      <font-awesome-icon
        :icon="['fas', 'exclamation-circle']"
      ></font-awesome-icon>
    </div>
    <span class="text-left">
      {{
        $t(`knowledge.diagnostic-builder.side-bar.public-warning.${warning}`)
      }}</span
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'WarningCallout',
  props: {
    warning: {
      type: String,
      default: 'notifications',
    },
  },
  computed: {
    ...mapGetters('knowledgeModule', ['focusKnowledgeIsPublic']),
  },
};
</script>

<style lang="scss" scoped>
.https-warning {
  border-radius: 4px;
  padding: 8px;
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
  font-size: 14px;
  word-break: normal;
}
</style>
