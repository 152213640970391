import { render, staticRenderFns } from "./ContentListItemFullLabel.vue?vue&type=template&id=7f8fc755&scoped=true&"
import script from "./ContentListItemFullLabel.vue?vue&type=script&lang=js&"
export * from "./ContentListItemFullLabel.vue?vue&type=script&lang=js&"
import style0 from "./ContentListItemFullLabel.vue?vue&type=style&index=0&id=7f8fc755&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f8fc755",
  null
  
)

export default component.exports