var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.satisfactionRates.thumbUpPercent !== 0 ||
      _vm.satisfactionRates.thumbDownPercent !== 0
  )?_c('section',{staticClass:"satisfaction-rates my-4"},[_c('h5',[_vm._v("Satisfaction")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('small',{staticClass:"satisfaction-rate-item my-2"},[_c('font-awesome-icon',{staticClass:"mr-2",class:[
          'fa',
          'fa-thumbs-up',
          _vm.satisfactionRates.thumbUpPercent >=
          _vm.satisfactionRates.thumbDownPercent
            ? 'is-success'
            : '',
        ],attrs:{"icon":['fad', 'thumbs-up']}}),_vm._v(" "+_vm._s(_vm.satisfactionRates.thumbUpPercent)+"% ")],1),_c('el-tooltip',{staticClass:"group-tooltip",attrs:{"placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.satisfactionRates.thumbUpCount)+" / "+_vm._s(_vm.satisfactionRates.totalThumbCount)+" ")]),_c('font-awesome-icon',{staticClass:"thumbs-up-count ml-2",attrs:{"icon":['fad', 'info-circle']}})],1)],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('small',{staticClass:"satisfaction-rate-item my-2"},[_c('font-awesome-icon',{staticClass:"mr-2",class:[
        'fa',
        'fa-thumbs-down',
        _vm.satisfactionRates.thumbUpPercent <
        _vm.satisfactionRates.thumbDownPercent
          ? 'is-danger'
          : '',
      ],attrs:{"icon":['fad', 'thumbs-down']}}),_vm._v(" "+_vm._s(_vm.satisfactionRates.thumbDownPercent)+"% ")],1),_c('el-tooltip',{staticClass:"group-tooltip",attrs:{"placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.satisfactionRates.thumbDownCount)+" / "+_vm._s(_vm.satisfactionRates.totalThumbCount)+" ")]),_c('font-awesome-icon',{staticClass:"thumbs-up-count ml-2",attrs:{"icon":['fad', 'info-circle']}})],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }