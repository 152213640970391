<template>
  <div class="counts-container">
    <div
      v-for="languageKey in keys"
      :key="languageKey"
      class="language-container"
    >
      <div class="left-container">
        <LanguageFlag class="flag" :language="languageKey" emoji />
        <span class="country-text"
          >{{ $t(`knowledge.languages.${languageKey}`) }}
        </span>
      </div>
      <span class="count-text">{{ languagesCounts[languageKey] }}</span>
    </div>
  </div>
</template>

<script>
import LanguageFlag from '@/components/LanguageFlag.vue';
export default {
  name: 'language-counts',
  components: {
    LanguageFlag,
  },
  props: {
    languagesCounts: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    keys() {
      return Object.keys(this.languagesCounts);
    },
  },
};
</script>

<style lang="scss" scoped>
.counts-container {
  min-width: 180px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.language-container {
  display: flex;
  text-align: center;
  justify-content: space-between;
}
.flag {
  line-height: 22px;
}
.left-container {
  display: flex;
  gap: 4px;
}
.count-text {
  font-weight: 700;
}
</style>
