<template>
  <div class="update-translations-banner">
    <font-awesome-icon :icon="['fal', 'lightbulb']"></font-awesome-icon>
    <div class="banner-text">
      <div v-html="sanitize($t('translation.banner.title-start'))"></div>
      <div class="redirect-here" @click="$emit('update-translations')">
        {{ $t('generic.here') }}
      </div>
      <div
        class="last-bit"
        v-html="sanitize($t('translation.banner.title-end'))"
      ></div>
    </div>
    <font-awesome-icon
      class="close-icon"
      :icon="['fal', 'times']"
      @click="$emit('close-banner')"
    ></font-awesome-icon>
  </div>
</template>

<script>
import { sanitize } from '@/plugins/dompurify';

export default {
  name: 'update-translations-banner',
  data() {
    return {
      sanitize: sanitize,
    };
  },
};
</script>

<style lang="scss" scoped>
.update-translations-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
  background-color: $purple-3-mayday;
  height: 30px;
  position: relative;
  border-radius: 8px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  white-space: none;
  max-width: 100%;
}
.redirect-here {
  color: $purple-5-mayday;
  font-weight: 700;
  cursor: pointer;
  margin: 0px 3px;
}
.close-icon {
  position: absolute;
  right: 20px;
  color: $grey-7-mayday;
  height: 12px;
  &:hover {
    color: $grey-9-mayday;
    cursor: pointer;
  }
}
.banner-text {
  font-size: 14px;
  max-width: calc(100% - 80px);
  display: flex;
  flex-direction: row;
}
.last-bit {
  max-width: calc(100% - 370px);
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
