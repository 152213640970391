<template>
  <div
    class="list-item-source"
    @mouseover="$emit('update-hover-link', { id })"
    @mouseleave="$emit('update-hover-link', { id: null })"
    @click="openSidebar"
  >
    <div class="header">
      <div class="icon-wrapper">
        <img :src="icon" class="icon" />
      </div>
      <div v-if="linkId" class="link-id" :class="{ hovered: isHovered }">
        {{ linkId }}
      </div>
      <div class="title ml-2">
        <TextEllipsis
          :font-size="'14px'"
          :text="translatedLabel"
        ></TextEllipsis>
        <el-tooltip :content="$t('ask.search.obsolete-document-tooltip')">
          <div
            v-if="isOutdated"
            class="d-flex align-items-center obsolete-icon-container ml-1"
          >
            <font-awesome-icon :icon="['fal', 'times']" class="obsolete-icon" />
          </div>
        </el-tooltip>
      </div>
    </div>
    <div class="actions">
      <el-tooltip :content="$t('ask.search.copy-tooltip')"
        ><div class="action-icon-wrapper" @click.stop="handleCopyDocumentUrl">
          <font-awesome-icon :icon="['fal', 'link']" class="action-icon" /></div
      ></el-tooltip>
      <el-tooltip :content="$t('ask.search.open-document-tooltip')">
        <div class="action-icon-wrapper" @click.stop="goToDocument">
          <font-awesome-icon
            :icon="['fal', 'external-link']"
            class="action-icon"
          ></font-awesome-icon></div
      ></el-tooltip>
    </div>
  </div>
</template>

<script>
import TextEllipsis from '../../TextEllipsis.vue';

export default {
  name: 'list-item-source',
  components: { TextEllipsis },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    linkId: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
    isOutdated: {
      type: Boolean,
      required: true,
    },
    denormalizedCaseParameters: {
      type: Array,
      default: () => [],
    },
    defaultLanguage: {
      type: String,
      required: true,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
    translations: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: String,
      required: true,
    },
  },
  computed: {
    icon() {
      switch (this.type) {
        case 'Article':
          return require('@/assets/article-icon-v2.svg');
        case 'Diagnostic':
          return require('@/assets/diag-icon-v2.svg');
        default:
          return require('@/assets/article-icon-v2.svg');
      }
    },
    translatedLabel() {
      if (this.translations) {
        if (
          this.language &&
          this.translations[this.language] &&
          this.translations[this.language].label
        )
          return this.translations[this.language].label;
        if (
          this.defaultLanguage &&
          this.translations[this.defaultLanguage] &&
          this.translations[this.defaultLanguage].label
        ) {
          return this.translations[this.defaultLanguage].label;
        }
      }
      return this.label;
    },
  },
  methods: {
    goToDocument() {
      this.$emit('go-to-document', {
        entityId: this.id,
        entityType: this.type,
      });
    },
    handleCopyDocumentUrl() {
      this.$emit('copy-document-url', {
        entityId: this.id,
        entityType: this.type,
      });
    },
    openSidebar() {
      this.$emit('open-sidebar', {
        entityId: this.id,
        entityType: this.type,
        denormalizedCaseParameters: this.denormalizedCaseParameters,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item-source {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 8px;
  border-radius: 4px;
  height: 38px;
  width: 100%;
  &:hover {
    box-shadow: -3px 4px 8px 0px rgba(112, 144, 176, 0.25),
      1px 1px 4px 0px rgba(112, 144, 176, 0.25);
    cursor: pointer;
    .action-icon-wrapper {
      display: block;
    }
    .link-id {
      background-color: $purple-5-mayday;
      color: white;
    }
  }
}
.hovered {
  border: 1px solid $blue-mayday;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: calc(100% - 90px);
  height: 100%;
  .icon-wrapper {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon {
    width: 18px;
    height: 18px;
  }
  .title {
    color: $grey-8-mayday;
    width: 100%;
    height: 22px;
    line-height: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  height: 100%;
}

.action-icon-wrapper {
  display: none;
  height: 100%;
  .action-icon {
    height: 14px;
    width: 14px;
  }
  & :hover {
    cursor: pointer;
    color: $blue-mayday;
  }
}

.link-id {
  font-size: 12px;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 4px;
  color: $purple-5-mayday;
  background-color: $purple-3-mayday;
  &.hovered,
  &:hover {
    background-color: $purple-5-mayday;
    color: white;
  }
}

.obsolete-icon-container {
  flex: none;
  width: 12px;
  height: 12px;
  border-radius: 8px;
  background: $red-5-mayday;
  justify-content: center;
  display: flex;
}

.obsolete-icon {
  color: white;
  height: 8px;
  width: 8px;
  cursor: pointer;
}
</style>
