<template>
  <div class="summary-tabs">
    <div class="summary-tabs__tabs">
      <div
        :class="['summary-tabs__tab', { active: activeTab === 'long' }]"
        @click="$emit('change-tab', 'long')"
      >
        <font-awesome-icon :icon="['fal', 'file-alt']" />
        {{ $t('knowledge.ai-drawer.summary.long.title') }}
        <el-tooltip
          effect="dark"
          :content="$t('knowledge.ai-drawer.summary.long.tooltip')"
          placement="top"
        >
          <font-awesome-icon
            :icon="['fal', 'info-circle']"
            class="summary-tabs__infos"
          />
        </el-tooltip>
      </div>
      <div
        :class="['summary-tabs__tab', { active: activeTab === 'short' }]"
        @click="$emit('change-tab', 'short')"
      >
        <font-awesome-icon :icon="['fal', 'file-minus']" />
        {{ $t('knowledge.ai-drawer.summary.short.title') }}
        <el-tooltip
          effect="dark"
          :content="$t('knowledge.ai-drawer.summary.short.tooltip')"
          placement="top"
        >
          <font-awesome-icon
            :icon="['fal', 'info-circle']"
            class="summary-tabs__infos"
          />
        </el-tooltip>
      </div>
    </div>
    <div class="summary-tabs__body">
      <SummarySection
        key="long"
        type="long"
        :hidden="activeTab !== 'long'"
        :original-text="long.data.body || ''"
        :manual-edit="long.data.manualEdit || false"
        :last-update="long.data.bodyUpdatedAt"
        :is-visible="long.data.isVisible"
        :deleting="long.deleting"
        :generating="long.generating"
        :process-interrupted="processInterrupted"
        :doc-last-update="docLastUpdate"
        @cancel="$emit('cancel', 'summary')"
        @save="$emit('save', $event, false)"
        @delete="$emit('delete', false)"
        @generate-summary="$emit('generate-summary', false)"
      />
      <SummarySection
        key="short"
        type="short"
        :hidden="activeTab !== 'short'"
        :original-text="short.data.body || ''"
        :manual-edit="short.data.manualEdit || false"
        :last-update="short.data.bodyUpdatedAt"
        :is-visible="short.data.isVisible"
        :deleting="short.deleting"
        :generating="short.generating"
        :process-interrupted="processInterrupted"
        :doc-last-update="docLastUpdate"
        :max-length="280"
        @cancel="$emit('cancel', 'short-summary')"
        @save="$emit('save', $event, true)"
        @delete="$emit('delete', true)"
        @generate-summary="$emit('generate-summary', true)"
      />
    </div>
  </div>
</template>

<script>
import SummarySection from 'components/AI/SummarySection.vue';

export default {
  name: 'SummaryTabs',
  components: { SummarySection },
  props: {
    long: {
      type: Object,
      default: () => ({}),
    },
    short: {
      type: Object,
      default: () => ({}),
    },
    docLastUpdate: {
      type: String,
      default: '',
    },
    processInterrupted: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: String,
      default: 'long',
    },
  },
};
</script>

<style scoped lang="scss">
.summary-tabs__tabs {
  display: flex;
  width: 100%;

  .summary-tabs__tab {
    width: 100%;
    background-color: white;
    border-bottom: 1px solid white;
    color: $grey-5-mayday;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    padding: 3px;

    &:hover {
      border-color: $purple-1-mayday;
      color: $purple-5-mayday;
    }

    &.active {
      border-color: $purple-5-mayday;
      color: $purple-5-mayday;
    }

    .summary-tabs__infos {
      font-size: 12px;
      color: $grey-5-mayday;
    }
  }
}
</style>
