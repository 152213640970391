<template>
  <div class="mayday-diagnostic-message-editor text-edition">
    <mayday-editor
      v-if="showEditor"
      ref="diagnostic-message-editor"
      v-model="content"
      :placeholder="
        $t('knowledge.action-triggers.message-form.message-placeholder')
      "
      :editable="editable"
      :options="options"
      :generate-grammar="fetchCorrectionBuffer"
      :generate-style="fetchStyleBuffer"
      :generate-translation="fetchTranslationBuffer"
      @update="onUpdate"
      @click:open-link="handleOpenLink"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MaydayDiagnosticMessageEditor',
  props: {
    contentId: String,
    body: String,
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: {
        fontOptions: true,
        structurationOptions: true,
        imageOptions: true,
        allowTranslation: false,
        allowGrammarCorrection: false,
        allowToneImprovement: false,
      },
      content: '',
      showEditor: true,
    };
  },
  created() {
    this.options.allowTranslation = this.companyAllowTranslationPreference;
    this.options.allowGrammarCorrection =
      this.companyAllowGrammarCorrectionPreference;
    this.options.allowToneImprovement =
      this.companyAllowToneImprovementPreference;

    this.setEditorContent(this.body);
  },
  computed: {
    ...mapGetters([
      'companyAllowToneImprovementPreference',
      'companyAllowTranslationPreference',
      'companyAllowGrammarCorrectionPreference',
    ]),
  },
  methods: {
    handleOpenLink({ href }) {
      window.open(href, '_blank');
    },
    onUpdate(message) {
      this.$emit('input', message === '<p></p>' ? '' : message);
    },
    setEditorContent(message) {
      this.showEditor = false;
      this.$nextTick(() => {
        this.showEditor = true;
        this.$nextTick(() => {
          const maydayEditor = this.$refs['diagnostic-message-editor'];
          this.content = message;
          if (maydayEditor) {
            maydayEditor.editor.commands.setContent(this.content);
          }
        });
      });
    },
    async *fetchTranslationBuffer(text, language, abortController) {
      for await (const chunk of await this.fetchTranslation({
        text,
        language,
        abortController,
      })) {
        yield chunk;
      }
    },
    async *fetchCorrectionBuffer(text, _, abortController) {
      for await (const chunk of await this.fetchCorrection({
        text,
        abortController,
        correctionType: 'grammar',
      })) {
        yield chunk;
      }
    },
    async *fetchStyleBuffer(text, _, abortController) {
      for await (const chunk of await this.fetchCorrection({
        text,
        abortController,
        correctionType: 'style',
      })) {
        yield chunk;
      }
    },
    ...mapActions('brainModule', ['fetchTranslation', 'fetchCorrection']),
  },
  watch: {
    editable() {
      this.editor.setOptions({ editable: this.editable });
    },
    contentId: {
      handler(val, oldVal) {
        if (oldVal !== val) {
          this.setEditorContent(this.body);
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
:deep() .mayday-editor {
  padding: 0px;
}

.text-edition {
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  word-break: break-word;
  padding-inline: 12px;
  min-height: 150px;
}
</style>
