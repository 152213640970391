<template>
  <div class="brain-task-toast">
    <BrainTaskToastHeader
      :icon="['fal', 'code-merge']"
      :title="$t(`${i18n}.title`)"
      :closable="closable"
      @close="$emit('close')"
    />

    <BrainTaskToastBody
      :status="status"
      :status-configs="statusConfigs"
      @click:cta="handleCTA"
    />
  </div>
</template>

<script>
import BrainTaskToastHeader from './BrainTaskToastHeader.vue';
import BrainTaskToastBody from './BrainTaskToastBody.vue';

const i18n = 'brain-files-merge.loading-toast';

export default {
  name: 'brain-files-merge-task-toast',
  components: {
    BrainTaskToastHeader,
    BrainTaskToastBody,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    retryable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      i18n,
      statusConfigs: {
        loading: {
          loading: true,
          description: this.$t(`${i18n}.loading`),
          closable: true,
        },
        pending: {
          loading: true,
          description: this.$t(`${i18n}.pending`),
          closable: true,
        },
        done: {
          description: this.$t(`${i18n}.done`),
          cta: this.$t(`${i18n}.done-button`),
          closable: true,
        },
        failed: {
          title: this.$t(`${i18n}.failed-title`),
          description: this.$t(`${i18n}.failed-description`),
          cta: this.retryable ? this.$t(`${i18n}.failed-button`) : undefined,
          closable: true,
        },
      },
    };
  },
  methods: {
    handleCTA(status) {
      switch (status) {
        case 'done':
          return this.$emit('click:refresh');
        case 'failed':
          return this.$emit('click:retry');
        default:
          return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.brain-task-toast {
  position: absolute;
  width: 380px;
  font-size: 14px;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
  z-index: 999;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  color: $purple-5-mayday;
}
</style>
