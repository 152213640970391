<template>
  <div>
    <UsersInvitationsRow
      v-for="user in users"
      :key="user.tempId"
      :user="user"
      :roles="roles"
      :groups="groups"
      :label-categories="labelCategories"
      :force-error="duplicateEmails.includes(user.email)"
      @change="handleChange"
      @delete="handleDelete(user.tempId)"
    />
  </div>
</template>

<script>
import UsersInvitationsRow from './UsersInvitationsRow';

export default {
  name: 'users-invitations-table',
  components: {
    UsersInvitationsRow,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    roles: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
      default: () => [],
    },
    labelCategories: {
      type: Array,
      default: () => [],
    },
    duplicateEmails: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleChange(e) {
      this.checkValidation();
      return this.$emit('change', e);
    },
    handleDelete(e) {
      this.$emit('delete', e);
      this.$nextTick(() => this.checkValidation());
    },
    checkValidation() {
      const toValidate = this.$children
        .map((c) => c.isValid)
        .filter((c) => c != undefined);
      this.$emit('validate', toValidate.every(Boolean));
    },
  },
};
</script>
