<template>
  <base-dropdown>
    <div slot="title" class="dropdown">
      <font-awesome-icon
        :icon="['far', 'ellipsis-h']"
        class="options_selection"
      />
    </div>

    <!-- EDIT -->
    <div class="dropdown-item" @click="$emit('edit-role')">
      <div>
        <font-awesome-icon :icon="['fal', 'pen']" class="option-icon" />
        <span class="ml-2">{{
          $t('settings.permissions.role-options.edit')
        }}</span>
      </div>
    </div>

    <!-- DELETE  -->
    <el-tooltip
      :content="$t('settings.permissions.role-options.delete-tooltip')"
      :disabled="canDelete"
    >
      <div
        class="dropdown-item"
        :class="{ 'disabled-button': !canDelete }"
        @click="canDelete ? $emit('delete-role') : ''"
      >
        <div class="text-overflow">
          <font-awesome-icon
            :icon="['fal', 'trash']"
            class="option-icon text-red"
          />
          <span class="ml-2">{{
            $t('settings.permissions.role-options.delete')
          }}</span>
        </div>
      </div>
    </el-tooltip>
  </base-dropdown>
</template>

<script>
export default {
  name: 'role-table-row-options',
  props: {
    canDelete: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  padding: 0px;
  border-width: 0px;
  box-shadow: none;
  background-color: white;
  margin-right: 0px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  &:hover {
    background-color: rgba($blue-mayday, 0.05);
    cursor: pointer;
  }
}

.options_selection {
  max-width: 15px;
  color: $grey-6-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.dropdown-item {
  &.disabled-button {
    color: $grey-6-mayday;
    cursor: not-allowed;
  }
  &:hover:not(.disabled-button) {
    cursor: pointer;
    background-color: rgba($blue-mayday, 0.05);
  }
}

.option-icon {
  color: $grey-7-mayday;
  max-width: 14px;
}
</style>
