var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cursor"},[_c('div',{class:[
      _vm.isFolder
        ? 'toggle-btn d-flex align-items-center justify-content-center'
        : 'd-flex align-items-center justify-content-center',
      { 'collection-focus-toggle-btn': _vm.isFocused && _vm.isFolder },
      'cursor',
    ],on:{"click":_vm.iconClick}},[_c('img',{class:[_vm.isFolder ? 'toggle-icon' : 'dropdown_icon'],attrs:{"src":_vm.nodeIcon}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }