<template>
  <el-tooltip
    effect="dark"
    :content="text"
    placement="bottom-start"
    :disabled="!tooltip"
  >
    <div
      :style="dynamicWidth"
      ref="tooltip-overflow"
      :class="[{ 'overflow-active': tooltip }, 'overflow']"
      @click="$emit('click')"
    >
      {{ text }}
    </div>
  </el-tooltip>
</template>

<script>
export default {
  name: 'text-overflow-tooltip',
  props: {
    text: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
    },
  },
  data() {
    return {
      tooltip: true,
    };
  },
  mounted() {
    this.isTooltipDisabled();
  },
  methods: {
    isTooltipDisabled() {
      this.$nextTick(() => {
        const textEl = this.$refs['tooltip-overflow'];
        if (textEl) {
          this.tooltip = textEl.scrollWidth > textEl.clientWidth;
        }
      });
    },
  },
  computed: {
    dynamicWidth() {
      return this.width ? `max-width: ${this.width}px` : 'max-width: 100%';
    },
  },
  watch: {
    text() {
      this.isTooltipDisabled();
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-container {
  position: relative !important;
  min-width: 0 !important;
  font-size: 12px !important;

  &:hover .tooltip-text {
    visibility: visible !important;
  }
}
.overflow {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  &-active {
    cursor: default !important;
  }
}

.tooltip-text {
  font-size: 10px !important;
  color: white !important;
  margin-top: 8px !important;
  border-radius: 4px !important;
  z-index: 100 !important;
  padding: 4px !important;
  background-color: black !important;
  position: fixed !important;
  visibility: hidden !important;
  box-shadow: 0px 15px 30px rgba(112, 144, 176, 0.15) !important;
  &-bottom {
    bottom: 16px !important;
  }

  &::before {
    content: ' ' !important;
    position: absolute !important;
    bottom: 100% !important; /* At the top of the tooltip */
    margin-left: -8px !important;
    border-width: 8px !important;
    border-style: solid !important;
    border-color: transparent transparent black transparent !important;
    box-shadow: 0px 15px 30px $shadow-4-mayday !important;
    &:hover .tooltip-text {
      visibility: visible !important;
    }
  }
  &-right::before {
    right: 25% !important;
  }
  &-left::before {
    left: 25% !important;
  }

  &:hover {
    visibility: visible !important;
  }
}
</style>
