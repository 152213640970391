<template>
  <div>
    <div
      class="d-flex flex-row align-items-center public-header sticky-top justify-content-between"
    >
      <div class="d-flex flex-row align-items-center">
        <el-tooltip
          :content="$t('public-knowledge.deployment.return')"
          @mouseenter.native="back = true"
          @mouseleave.native="back = false"
        >
          <router-link
            class="icon-wrapper mr-2"
            to="/settings/general#knowledges"
          >
            <font-awesome-icon
              class="icon"
              :icon="back ? ['far', 'arrow-left'] : ['fas', 'book']"
            />
          </router-link>
        </el-tooltip>
        <el-divider direction="vertical" class="menu-divider mr-3"></el-divider>
        <el-dropdown trigger="click" v-if="!localDeploying">
          <span class="el-dropdown-link">
            {{ enrichedFocusCompanyPublicConfig.label
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <router-link
              v-for="config in enrichedCompanyPublicConfigs"
              :key="config.id"
              class="dropdown-links"
              :to="{
                name: 'public-settings',
                params: {
                  knowledgeId: config.knowledgeId
                    ? config.knowledgeId
                    : 'default',
                },
              }"
            >
              <el-dropdown-item>
                {{ config.label }}
              </el-dropdown-item>
            </router-link>
          </el-dropdown-menu>
        </el-dropdown>
        <span v-else>
          {{ enrichedFocusCompanyPublicConfig.label }}
        </span>
      </div>
      <div>
        <el-popover v-model="publishChanges" class="publish-popover">
          <publish-popover
            v-if="publishChanges"
            :operations="getAllOperations"
            @cancel="publishChanges = false"
            @confirm="handlePublish"
            @delete-operation="handleDeleteOperation"
          ></publish-popover>
          <el-button
            slot="reference"
            type="primary"
            size="small"
            :disabled="!hasChanged"
            v-loading="loading"
            >{{ $t('public-knowledge.publish') }}</el-button
          >
        </el-popover>
      </div>
    </div>

    <div class="d-flex flex-row">
      <KnowledgeSettingsMenu
        v-if="!localDeploying"
        knowledge-type="public"
        :data="items"
        class="menu"
      />

      <router-view
        v-if="!localDeploying && !loading"
        class="router-view"
        :items="items"
        @new-operation="newOperation($event)"
        @new-language="addLanguage({ id: focusKnowledge.id, lang: $event })"
        @set-multilingual="
          setMultilingual({ id: focusKnowledge.id, lang: $event })
        "
      ></router-view>
      <div v-else-if="localDeploying && !loading" class="status">
        <deployment-steps
          :steps="getSteps"
          :time="formattedTime(deploymentStatus.createdAt)"
          :state="state"
          :httpsWarning="httpsWarning"
          class="deployment"
          @return="handleReturn"
        ></deployment-steps>
      </div>
      <div v-else-if="loading" class="loader" v-loading="true"></div>
    </div>
  </div>
</template>

<script>
import KnowledgeSettingsMenu from '../KnowledgeSettingsMenu.vue';
import DeploymentSteps from './commons/DeploymentSteps.vue';
import PublishPopover from './commons/PublishPopover.vue';
import { mapActions, mapGetters } from 'vuex';
import { Notification } from 'element-ui';

export default {
  name: 'public-knowledge',
  components: {
    KnowledgeSettingsMenu,
    DeploymentSteps,
    PublishPopover,
  },
  data() {
    return {
      publishChanges: false,
      localDeploying: false,
      loading: false,
      back: false,
      httpsWarning: '',
    };
  },
  async created() {
    await this.init();
  },
  destroyed() {
    this.stopStatusPolling();
    this.setDeploying(false);
    this.stopHttpsStatusPolling();
    this.setHttpsPooling(false);
    Notification.closeAll();
  },
  computed: {
    items() {
      return Object.values(this.getMenuConfig);
    },
    hasChanged() {
      return this.getAllOperations && this.getAllOperations.length > 0;
    },
    state() {
      if (!this.deploymentStatus) return;
      if (this.deploymentStatus.deploymentStatus === 'FAILED') return 'failed';
      if (this.deploymentStatus.deploymentStatus === 'RELEASED')
        return 'released';
      else return 'doing';
    },
    enrichedCompanyPublicConfigs() {
      return this.companyPublicConfigs.map((config) => {
        let label = `${config.hostname} (default)`;
        let isDefault = true;
        if (config.customHostname) label = `${config.customHostname} (default)`;
        if (config.knowledgeId) {
          const knowledgeIdx = this.publicKnowledges.findIndex(
            (k) => k.id === config.knowledgeId,
          );
          if (knowledgeIdx > -1) {
            label = `${this.publicKnowledges[knowledgeIdx].label}`;
            isDefault = false;
          }
        }
        return { ...config, label, isDefault };
      });
    },
    enrichedFocusCompanyPublicConfig() {
      return this.enrichedCompanyPublicConfigs.filter(
        (config) => config.id === this.focusCompanyPublicConfig.id,
      )[0];
    },
    focusHostname() {
      return this.enrichedFocusCompanyPublicConfig.customHostname
        ? this.enrichedFocusCompanyPublicConfig.customHostname
        : this.enrichedFocusCompanyPublicConfig.hostname;
    },
    ...mapGetters('knowledgeModule', ['publicKnowledges']),
    ...mapGetters('publicKnowledgeModule', [
      'companyPublicConfigs',
      'getAllOperations',
      'getMenuConfig',
      'focusKnowledge',
      'deploymentStatus',
      'focusCompanyPublicConfig',
      'deploying',
      'getSteps',
      'httpsPooling',
      'httpsStatus',
    ]),
  },
  methods: {
    async handlePublish() {
      this.loading = true;
      try {
        await this.publishOperations();
      } catch (e) {
        if (
          e.graphQLErrors &&
          e.graphQLErrors[0].extensions.status === 403 &&
          e.graphQLErrors[0].message === 'NO_DNS_CNAME_RECORD_FOUND'
        ) {
          const hostname = this.getAllOperations.map((op) => {
            if (op.key === 'customHostname') return op.value;
          });
          this.$notify({
            title: this.$t('public-knowledge.deployment.error.title'),
            message: this.$t('public-knowledge.deployment.error.message', {
              hostname,
            }),
            duration: 0,
            type: 'error',
            offset: 50,
          });
        }
      } finally {
        this.publishChanges = false;
        this.loading = false;
      }
    },
    handleReturn() {
      this.getFocusCompanyPublicConfig(this.focusCompanyPublicConfig.id);
      this.localDeploying = false;
    },
    async init() {
      this.loading = true;
      this.stopStatusPolling();
      this.setDeploying(false);
      this.setHttpsPooling(false);
      await this.getCompanyPublicConfigs();
      await this.getKnowledges();
      await this.getPendingDeploymentStatus(this.focusCompanyPublicConfig.id);
      this.checkCdnEndpointHttpsStatus(this.focusHostname).then(() => {
        if (this.httpsPooling && this.httpsStatus !== 'Enabled')
          this.httpsWarning = 'loading';
      });

      this.loading = false;
    },
    formattedTime(time) {
      var date = new Date(parseInt(time));
      return {
        date:
          String(date.getDate()).padStart(2, '0') +
          '/' +
          String(date.getMonth() + 1).padStart(2, '0'),
        hour:
          date.getHours() + 'h' + String(date.getMinutes()).padStart(2, '0'),
      };
    },
    handleDeleteOperation(e) {
      this.deleteOperation(e);
      if (!this.hasChanged) this.publishChanges = false;
    },
    ...mapActions('publicKnowledgeModule', [
      'getCompanyPublicConfigs',
      'newOperation',
      'deleteOperation',
      'setFocusKnowledge',
      'publishOperations',
      'getPendingDeploymentStatus',
      'stopStatusPolling',
      'setDeploying',
      'getFocusCompanyPublicConfig',
      'addLanguage',
      'setMultilingual',
      'getFocusCompanyPublicConfigByKnowledgeId',
      'checkCdnEndpointHttpsStatus',
      'setHttpsPooling',
      'stopHttpsStatusPolling',
    ]),
    ...mapActions('knowledgeModule', ['getKnowledges']),
  },
  watch: {
    '$route.params.knowledgeId': {
      handler(newVal) {
        if (newVal !== 'default')
          this.getFocusCompanyPublicConfigByKnowledgeId(newVal);
        else
          this.getFocusCompanyPublicConfig(
            this.enrichedCompanyPublicConfigs.find(
              (config) => config.isDefault === true,
            ).id,
          );
      },
      immediate: true,
    },
    deploying: {
      handler(newVal) {
        if (newVal && !this.localDeploying) {
          this.localDeploying = true;
        }
      },
      immediate: true,
    },
    httpsPooling: {
      handler(newVal, oldVal) {
        Notification.closeAll();
        if (!newVal && this.httpsStatus === 'Failed') {
          this.httpsWarning = 'error';
        } else if (oldVal && !newVal && this.httpsStatus === 'Enabled') {
          this.httpsWarning = '';
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.public-header {
  background-color: white;
  padding: 15px;
  border-bottom: 2px solid $grey-4-mayday;
  max-width: 100%;
  min-width: calc(100% - 60px);
  max-height: 60px;
  position: fixed;
  z-index: 10 !important;
}
.public-knowledge-item-container {
  max-height: 100%;
}

.menu {
  width: 20%;
  margin-left: 60px;
  margin-top: 36px;
}

.icon-wrapper {
  width: 32px;
  height: 32px;
  background-color: rgba($blue-mayday, 0.1);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon {
  width: 14px;
  height: 16px;
  color: $blue-mayday;
}

.menu-divider {
  height: 34px;
}

.el-dropdown-link:hover {
  cursor: pointer;
  color: $blue-mayday;
}

.el-dropdown-menu {
  left: 122px !important;
  top: 53px !important;
}

.status {
  position: absolute;
  left: 30%;
  width: 40%;
  top: 96px;
  background-color: white;
  width: 40%;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.dropdown-links {
  color: #606266;
}

:deep() .dropdown-links:hover {
  .el-dropdown-menu__item {
    color: $blue-mayday !important;
  }
}

:deep() .router-link-active {
  .el-dropdown-menu__item {
    color: $blue-mayday !important;
  }
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
}

:deep() .el-loading-spinner {
  height: 100%;
  margin-top: -15px;
  .circular {
    width: 100%;
    height: 100%;
  }
}
</style>
