<template>
  <div v-click-outside="handleClickOutside" @click.capture="handleClickInside">
    <SearchInput
      ref="super-search-input"
      class="search-input"
      :tags="selectedTags"
      :placeholder="$t('knowledge.search-admin.express-search')"
      :autofocus="false"
      v-model="searchInputFilter"
      @show-dropdown="handleDropdown"
      @delete-tag="deleteTag"
      @delete-all="resetSearch"
    />
    <LoaderLine :loading="loading" />
    <div
      v-show="inline || showDropdown"
      class="search-results"
      :class="inline ? 'is-inline' : 'is-dropdown'"
    >
      <SearchHeader
        :display="showToggleMoreFilters"
        :toggle="toggleMoreFilters"
        :title="$t('knowledge.search-admin.search')"
        @toggle-filters="toggleMoreFilters = !toggleMoreFilters"
      />
      <div class="overflow">
        <SearchFilters
          ref="filters"
          class="search-filters"
          is-agent
          :selected-tags="selectedTags"
          :keywords="keywords"
          :display="showFilters"
          :show-more-filters="toggleMoreFilters"
          @update-selected-tags="updateTags"
          @update-keyword="updateKeyword"
        />
        <SearchSuggestions
          v-loading="loading"
          v-if="results.length"
          class="search-suggestions"
          :results="results"
          @go-to-document="goToDocument"
        />
      </div>
      <SearchFooter
        v-if="searchInProgress"
        :loading="loading"
        :steps="searchSteps"
        :count="totalCount"
        @go-to-results-page="goToSearchResultPage"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import debounce from 'lodash.debounce';
import { v4 as uuid } from 'uuid';

import SearchInput from './SearchInput';
import LoaderLine from './LoaderLine';
import SearchHeader from './SearchHeader';
import SearchFilters from './SearchFilters';
import SearchSuggestions from './SearchSuggestions';
import SearchFooter from './SearchFooter';

export default {
  name: 'web-parametric-search-wrapper',
  components: {
    SearchInput,
    LoaderLine,
    SearchFilters,
    SearchSuggestions,
    SearchFooter,
    SearchHeader,
  },
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    productParameters: {
      type: Object,
      default: () => {},
    },
    symptom: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showFilters: this.inline ? true : false,
      showDropdown: false,
      toggleMoreFilters: false,
      searchInputFilter: '',
      selectedTags: [],
      keywords: [],
      loading: false,
      results: [],
      totalCount: 0,
      searchFrom: 0,
      size: 10,
      correlationId: '',
      debounceSearchFilters: debounce(() => {
        this.superSearch();
      }, 200),
    };
  },
  watch: {
    searchInputFilter() {
      this.handleSearch();
    },
    selectedTags() {
      this.handleSearch();
    },
  },
  computed: {
    currentFilter() {
      return {
        lang: 'default', // Hardcoded for FD
        filterType: this.entitiesSearchField,
        entityType: this.entityType,
        search: this.searchInputFilter,
        knowledge: this.knowledgeFilter,
        correlationId: this.correlationId,
        product: null,
        cases: null,
        status: [{ key: 'published', value: true }],
        accessRestrictions: null,
        labels: this.labelsFilters,
        isContribution: null,
        searchFrom: 0,
        size: this.size,
      };
    },
    chosenEntity() {
      const entity = this.selectedTags.find((tag) => tag.key === 'entity');
      return entity ? entity.value : '';
    },
    entitiesSearchField() {
      if (this.isParametric) {
        return this.chosenEntity === 'attached-contents' ||
          this.chosenEntity === 'new-products'
          ? [this.chosenEntity]
          : ['attached-contents', 'new-products'];
      } else return ['attached-contents'];
    },
    preFilters() {
      return this.selectedTags.reduce((acc, tag) => {
        if (tag.key !== 'entity') {
          if (acc[tag.key]) {
            acc[tag.key].push(tag.value);
          } else {
            acc[tag.key] = [tag.value];
          }
        }
        return acc;
      }, {});
    },
    isProductEntity() {
      return this.chosenEntity === 'new-products';
    },
    hasUniversTag() {
      return this.selectedTags.map((t) => t.key).includes('knowledge');
    },
    hasFamilyTag() {
      return this.selectedTags.map((t) => t.key).includes('family');
    },
    isUniversStep() {
      return this.isProductEntity && !this.hasUniversTag;
    },
    isfamilyStep() {
      return this.isProductEntity && this.hasUniversTag && !this.hasFamilyTag;
    },
    searchSteps() {
      return {
        univers: this.isUniversStep,
        family: this.isfamilyStep,
      };
    },
    entityType() {
      return this.preFilters.type || null;
    },
    knowledgeFilter() {
      const knowledge = this.selectedTags.find(
        (tag) => tag.key === 'knowledge',
      );
      return knowledge ? knowledge.value : '';
    },
    labelsFilters() {
      return this.selectedTags.reduce((acc, tag) => {
        if (tag.key === 'categories') {
          acc.push(tag.value);
        }
        return acc;
      }, []);
    },
    searchInProgress() {
      return this.selectedTags.length > 0 || this.searchInputFilter !== '';
    },
    queryParameters() {
      return {
        search: this.searchInputFilter,
        ...this.selectedTags.reduce((acc, tag) => {
          if (acc[tag.key]) {
            acc[tag.key].push(tag.value);
          } else {
            acc[tag.key] = [tag.value];
          }
          return acc;
        }, {}),
        init: true,
        correlation_id: this.correlationId,
      };
    },
    showToggleMoreFilters() {
      return this.chosenEntity === 'attached-contents' || !this.isParametric;
    },
    ...mapGetters(['hasAgentRestrictions', 'isParametric']),
    ...mapGetters('webKnowledgeModule', ['focusKnowledgeId']),
  },
  methods: {
    ...mapActions(['searchAction']),
    handleDropdown(val) {
      if (!this.inline) {
        this.showDropdown = val;
        this.showFilters = val;
      }
    },
    async initProductSearch() {
      let preTags = Object.keys(this.productParameters).map((param) => {
        return {
          key: param,
          value: this.productParameters[param],
          label: this.productParameters[param],
        };
      });
      preTags.push({ key: 'entity', value: 'attached-contents' });
      this.selectedTags = await this.$refs.filters.initTags(preTags);
    },
    enrichFilters(filters) {
      if (this.hasAgentRestrictions) {
        filters.type = filters.type
          ? filters.type
          : ['Article', 'Diagnostic', 'keyStep', null];
      }
      filters.published = ['true', null];
      return filters;
    },
    updateTags(tags) {
      this.selectedTags = tags;
    },
    deleteTag(tag) {
      let tags = [...this.selectedTags];
      const index = tags.findIndex((t) => t.id === tag.id && t.key === tag.key);
      tags.splice(index, 1);
      if (tag.key === 'entity') {
        tags = [];
      } else if (tag.key === 'knowledge') {
        tags = tags.filter((t) => t.key === 'entity');
      } else if (tag.key === 'family') {
        tags = tags.filter((t) => t.key === 'entity' || t.key === 'knowledge');
      }
      this.updateTags(tags);
    },
    updateKeyword(word) {
      this.searchInputFilter = `${this.searchInputFilter} ${word}`.trim();
    },
    resetSearch() {
      this.searchInputFilter = '';
      this.selectedTags = [];

      this.abortEvent();

      this.correlationId = this.getCorrelationId();
    },
    handleSearch() {
      if (this.selectedTags.length || this.searchInputFilter) {
        this.loading = true;
        this.debounceSearchFilters();
      } else {
        this.toggleMoreFilters = false;
        this.results = [];
        this.totalCount = 0;
      }
    },
    async superSearch() {
      try {
        const currentFilter = { ...this.currentFilter };
        const resp = await this.searchAction({
          searchType: 'superSearch',
          searchArgs: {
            entity: this.entitiesSearchField,
            fields: ['entity', 'type'],
            searchFrom: this.searchFrom,
            size: this.size,
            correlationId: this.correlationId,
            lang: 'default', // Hardcoded for FD
            payload: {
              queryText: this.searchInputFilter,
              pre: this.enrichFilters(this.preFilters),
              ...(this.symptom ? { extractKeywords: this.symptom } : {}),
            },
          },
        });

        this.keywords = resp.filters.keywords || [];
        this.results = resp.documents;
        this.totalCount = resp.counts.totalCount;
        this.loading = false;

        this.storeEvent({
          eventMethod: 'search',
          event: {
            search: currentFilter,
            displayedHits: this.results.length,
            totalHits: this.totalCount,
          },
        });
      } catch (e) {
        this.loading = false;
      }
    },
    storeEvent({ eventMethod, event }) {
      event.knowledgeId = this.focusKnowledgeId;
      this.$services.events.content[eventMethod](
        event,
        window.location.href,
        'WEB_PARAMETRIC',
      );
    },
    abortEvent() {
      this.storeEvent({
        eventMethod: 'searchAbort',
        event: {
          search: this.currentFilter,
          displayedHits: this.results.length,
          totalHits: this.totalCount,
        },
      });
    },
    sucessEvent(item) {
      this.storeEvent({
        eventMethod: 'searchSuccess',
        event: {
          content: item,
          search: this.currentFilter,
          itemIndex: item.index,
          displayedHits: this.results.length,
          totalHits: this.totalCount,
        },
      });
    },
    goToSearchResultPage() {
      this.$router.push({
        path: '/web-parametric/filter',
        query: this.queryParameters,
      });
    },
    goToDocument(doc) {
      const documentType = doc.__typename.toLowerCase();
      const path = `/web-parametric/${documentType}/`;

      let subPath = doc.id;
      if (
        documentType === 'content' &&
        ['Step', 'keyStep'].includes(doc.type)
      ) {
        subPath = `${doc.ancestors[0].split('/')[0]}/step/${doc.id}`;
      }

      if (documentType === 'product') {
        subPath += `?correlation_id=${this.correlationId}`;
      }

      this.sucessEvent(doc);
      this.$router.push({
        path: path + subPath,
        query: {
          from: 'search',
        },
      });
    },
    handleClickOutside() {
      this.$refs['super-search-input'].blurInput();
    },
    handleClickInside() {
      this.$refs['super-search-input'].focusInput();
    },
    getCorrelationId() {
      return 'web-parametric-' + uuid();
    },
  },
  mounted() {
    if (this.productParameters) this.initProductSearch();
    const { correlation_id, ...query } = this.$route.query;
    this.correlationId = correlation_id || this.getCorrelationId();
    this.$router.replace({ query: query });
  },
  beforeDestroy() {
    this.debounceSearchFilters.flush();
  },
};
</script>

<style lang="scss" scoped>
.search-input {
  border: 1px solid $grey-3-mayday;
  border-radius: 4px;
  padding: 8px;
}
.overflow {
  max-height: 58vh;
  overflow: auto;
}
.search-results {
  background: white;
  border: 1px solid $grey-3-mayday;
  border-radius: 4px;
}
.is-dropdown {
  position: absolute;
  width: 100%;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17);
  -webkit-box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2),
    0 5px 15px rgba(0, 0, 0, 0.17);
  z-index: 20;
}
.search-filters {
  padding: 0px 16px 16px 16px;
}
</style>
