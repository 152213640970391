<template>
  <div>
    <TemplatesListItem
      v-for="template in templates"
      :key="template.id"
      :template="template"
      @delete-template="$emit('delete-template', template.id)"
    />
  </div>
</template>

<script>
import TemplatesListItem from './TemplatesListItem.vue';
export default {
  name: 'templates-list',
  components: { TemplatesListItem },
  props: {
    templates: Array,
  },
};
</script>
