<template>
  <workflow-input-wrapper v-bind="$props">
    <template v-slot:input>
      <el-select
        :class="`${base}`"
        v-model="localValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :filterable="filterable"
        :multiple="multiple"
        :value-key="valueKey"
        @change="$emit('change', $event)"
      >
        <span :class="`${base}__options`" v-if="!groupedOptions">
          <el-option
            v-for="item in options"
            :key="parseOptionKey(item)"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </span>
        <span :class="`${base}__grouped-options`" v-else>
          <el-option-group
            v-for="group in options"
            :key="group.key"
            :label="group.label"
          >
            <el-option
              v-for="item in group.options"
              :key="parseOptionKey(item)"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-option-group>
        </span>
      </el-select>
    </template>
  </workflow-input-wrapper>
</template>
<script>
import WorkflowInputWrapper from './WorkflowInputWrapper.vue';
export default {
  name: 'workflow-select',
  components: {
    WorkflowInputWrapper,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    info: {
      type: String,
      default: null,
    },
    footerText: {
      type: String,
      default: null,
    },
    value: {
      type: String || Array,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    groupedOptions: {
      type: Boolean,
      default: false,
    },
    valueKey: {
      type: String,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      base: Object.freeze('workflow-select'),
      localValue: null,
    };
  },
  mounted() {
    this.localValue = this.value;
  },
  methods: {
    parseOptionKey(option) {
      if (!this.valueKey) return option.value;
      const key = this.valueKey.split('.').reduce((acc, key) => {
        return acc[key];
      }, option.value);
      return key;
    },
  },
  watch: {
    value() {
      this.localValue = this.value;
    },
  },
};
</script>
<style lang="scss" scoped>
.workflow-select {
  width: 100%;
}
</style>
