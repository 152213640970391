<template>
  <div class="parameter-title row align-items-center">
    <font-awesome-icon :icon="['fad', icon]" class="mx-2 title-icon" />
    <h4>{{ type }}</h4>

    <span v-if="!areTagsDisabled" class="tags">
      <el-divider
        direction="vertical"
        v-if="fieldType !== 'remote-search'"
      ></el-divider>

      <span v-if="showCount" class="m-0">
        <h4 v-if="enrichedChosenParams.length" class="children-count mr-2">
          {{ enrichedChosenParams.length }}
        </h4>
        <h4 v-else class="title-icon mr-2">{{ count }}</h4>
      </span>
      <span class="ml-2 d-flex flex-wrap col px-0">
        <el-tag
          class="mr-2 label-tag"
          v-for="({ label, value }, idx) in enrichedChosenParams"
          :key="`${type}-${value}`"
          closable
          :disable-transitions="false"
          @close="handleClose(idx)"
        >
          <small class="mr-1">{{ label }}</small>
        </el-tag>
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'parameter-title',
  props: {
    type: String,
    keyType: String,
    fieldType: String,
    icon: {
      type: String,
      default: 'books',
    },
    count: Number,
    enrichedChosenParams: [Array, String],
    disabledTags: String,
  },
  methods: {
    handleClose(idx) {
      const loc_arr = [...this.enrichedChosenParams];
      loc_arr.splice(idx, 1);
      this.$emit(
        'update-chosen-params',
        loc_arr.map((a) => a.value),
      );
    },
  },
  computed: {
    showCount() {
      return this.fieldType !== 'remote-search';
    },
    areTagsDisabled() {
      if (this.disabledTags === this.keyType) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-icon {
  color: $grey-6-mayday;
}

.children-count {
  color: $blue-mayday;
}

.parameter-title {
  h4 {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 400;
  }
}

.label-tag {
  margin-top: 2px;
  margin-bottom: 2px;
}

.tags {
  display: contents;
}
// TAGS DESIGN
.el-tag {
  height: 16px;
  line-height: 0px;
  border-radius: 2px;
  border-width: 0px;
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 0px;
  &:hover {
  }
  :deep() .el-icon-close {
    border-radius: 10%;
    position: inherit;
    &:hover {
      background-color: white;
    }
  }
  :deep() .el-tag__close {
    background-color: transparent;
    color: $grey-6-mayday;
    &:hover {
      color: $blue-mayday;
    }
  }
}
</style>
