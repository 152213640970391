<template>
  <div>
    <div
      class="container-fluid main-container d-flex align-items-center justify-content-center"
    >
      <div class="container hosting">
        <div class="set-zoom">
          <div class="row justify-content-center welcome-title">
            <div class="col-12 text-center">
              <img class="mayday-rounded" src="~assets/mayday-rounded.svg" />
              <h1 class="display-2 title my-2">
                🎉 Bienvenue sur Mayday 🎉<span class="username">{{
                  username
                }}</span>
              </h1>
              <p class="col-8 mx-auto text-muted">
                Laissez vous guider à travers ces 4 tutoriels pour découvrir
                Mayday, ses fonctionnalités et comment il va devenir votre
                compagnon de route pour vous aider à trouver toujours la bonne
                information au bon moment !
              </p>
            </div>
          </div>
          <div class="row justify-content-center my-4">
            <card
              type="frame"
              class="card welcome-card-1 col-5 mr-3"
              @click="openModal(1)"
            >
              <div class="row">
                <div class="col-9 px-0">
                  <img src="~assets/star-rect-icon.svg" />
                  <h4 class="card-title mt-2 mb-0 text-darker">
                    Bienvenue sur Mayday 🎉
                  </h4>
                  <p class="mb-0 px-0">
                    <small
                      >Quelques informations additionnelles sur le contenu de la
                      carte</small
                    >
                  </p>
                </div>
                <div class="col-3 px-0 image-col text-right">
                  <img src="~assets/party-icon.svg" />
                </div>
              </div>
            </card>
            <card
              type="frame"
              class="card welcome-card-2 col-5 ml-3"
              @click="openModal(2)"
            >
              <div class="row">
                <div class="col-9 px-0">
                  <img src="~assets/book-rect-icon.svg" />
                  <h4 class="card-title mt-2 mb-0 text-darker">
                    Les contenus sur Mayday 📕
                  </h4>
                  <p class="mb-0 px-0">
                    <small
                      >Quelques informations additionnelles sur le contenu de la
                      carte</small
                    >
                  </p>
                </div>
                <div class="col-3 px-0 image-col text-right">
                  <img src="~assets/random-icon.svg" />
                </div>
              </div>
            </card>
          </div>
          <div class="row justify-content-center mb-6">
            <card
              type="frame"
              class="card welcome-card-3 col-5 mr-3"
              @click="openModal(3)"
            >
              <div class="row">
                <div class="col-9 px-0">
                  <img src="~assets/find-rect-icon.svg" />
                  <h4 class="card-title mt-2 mb-0 text-darker">
                    La navigation dans Mayday 🔎
                  </h4>
                  <p class="mb-0 px-0">
                    <small
                      >Quelques informations additionnelles sur le contenu de la
                      carte</small
                    >
                  </p>
                </div>
                <div class="col-3 px-0 image-col text-right">
                  <img src="~assets/lightening-icon.svg" />
                </div>
              </div>
            </card>
            <card
              type="frame"
              class="card welcome-card-4 col-5 ml-3"
              @click="openModal(4)"
            >
              <div class="row">
                <div class="col-9 px-0">
                  <img src="~assets/speaker-rect-icon.svg" />
                  <h4 class="card-title mt-2 mb-0 text-darker">
                    Les outils de communication 📞
                  </h4>
                  <p class="mb-0 px-0">
                    <small
                      >Quelques informations additionnelles sur le contenu de la
                      carte</small
                    >
                  </p>
                </div>
                <div class="col-3 px-0 image-col text-right">
                  <img src="~assets/speech-icon.svg" />
                </div>
              </div>
            </card>
            <div class="col-10">
              <p class="d-flex my-4 justify-content-center">
                <small
                  >Vous êtes fin prêts à découvrir Mayday ?
                  <span v-if="hasZendesk">
                    Direction
                    <a href="https://www.zendesk.fr/login/" target="_blank"
                      >Zendesk</a
                    >
                    pour voir comment tout cela fonctionne !</span
                  >
                  <span v-else
                    >Commencez par
                    <a
                      href="https://chrome.google.com/webstore/detail/mayday/eloemnahbeeiekllcfeombpkokhinkpn?hl=fr"
                      target="_blank"
                      >télécharger notre extension chrome</a
                    >
                    si vous ne l'avez pas encore déjà fait !</span
                  >
                </small>
              </p>
            </div>
          </div>
        </div>
        <welcome-modal
          v-if="modals.welcome"
          :focused-modal="focusedModal"
          :display="modals.welcome"
          @close="modals.welcome = false"
        />
      </div>
    </div>
    <div
      class="container-fluid logout-row d-flex justify-content-end align-items-center"
    >
      <span @click="logout">
        <router-link
          class="logout col text-center integrate-link mt-3 mb-3"
          to="/logout"
        >
          <el-tooltip placement="left" content="Se déconnecter">
            <font-awesome-icon
              :icon="['fad', 'power-off']"
              class="medium-icon ml-1"
            />
          </el-tooltip>
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import WelcomeModal from 'components/Modals/WelcomeModal.vue';

export default {
  name: 'user-logged-in',
  components: {
    WelcomeModal,
  },
  data() {
    return {
      user: {},
      modals: {
        welcome: false,
      },
      focusedModal: 0,
    };
  },
  computed: {
    ...mapState(['username']),
    ...mapGetters(['hasZendesk']),
  },
  methods: {
    openModal(id) {
      this.focusedModal = id;
      this.modals.welcome = true;
    },
    ...mapActions(['logout']),
  },
};
</script>

<style lang="scss" scoped>
.logout-row {
  height: 60px;
  position: relative;
  z-index: 2;
}

.set-zoom {
  zoom: 90%;
}

.mayday-rounded {
  max-width: 100px;
}

.welcome-title {
  margin-top: -50px;
}

.username {
  color: $yellow-mayday;
}

.hosting {
  max-width: 70vw;
  max-height: 75vh;
  background: white;
  border-radius: 5px;
  z-index: 1;
  -webkit-box-shadow: 0px 0px 15px 2px rgba(222, 226, 230, 1);
  -moz-box-shadow: 0px 0px 15px 2px rgba(222, 226, 230, 1);
  box-shadow: 0px 0px 15px 2px rgba(222, 226, 230, 1);
}

.main-container {
  min-height: 100vh;
  position: absolute;
  z-index: 1;
  background-image: url('~assets/backgorund-image-layout.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.welcome-card-1 {
  background-color: rgba($blue-mayday, 0.1);
}

.welcome-card-2 {
  background-color: rgba($yellow-mayday, 0.1);
}

.welcome-card-3 {
  background-color: rgba($red-mayday, 0.1);
}

.welcome-card-4 {
  background-color: rgba($green-mayday, 0.1);
}

.card {
  border: none;
}

.card:hover {
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 20px 5px rgba(222, 226, 230, 1);
  -moz-box-shadow: 0px 0px 20px 5px rgba(222, 226, 230, 1);
  box-shadow: 0px 0px 5px 2px rgba(222, 226, 230, 1);
}

.card {
  :deep() .card-body {
    padding-top: 10px;
    padding-bottom: 15px;
  }
}

.image-col {
  padding-top: 10px;
}
</style>
