<template>
  <div>
    <el-tooltip
      class="d-flex"
      :content="translationTooltipContent"
      placement="right"
      :disabled="disableTooltip"
      :enterable="false"
    >
      <div>
        <font-awesome-icon
          :class="[iconColor, 'danger-icon']"
          :icon="['fal', 'exclamation-triangle']"
        />
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'translation-tooltip',
  props: {
    isTranslated: {
      type: Boolean,
      default: true,
    },
    isContent: {
      type: Boolean,
      default: false,
    },
    hasTranslatedContents: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconColor() {
      return this.disableTooltip
        ? 'transparent'
        : this.hasTranslatedContents
        ? 'orange'
        : 'red';
    },
    translationTooltipContent() {
      if (this.isTranslated) return '';
      if (this.isContent) return this.$t('translation.content-untranslate');
      if (this.hasTranslatedContents)
        return this.$t(
          'translation.collection-untranslate-with-content-translate',
        );
      return this.$t('translation.collection-untranslate');
    },
    disableTooltip() {
      return this.isTranslated || !this.focusKnowledgeIsMultilinguale;
    },
    ...mapGetters('knowledgeModule', ['focusKnowledgeIsMultilinguale']),
  },
};
</script>

<style lang="scss" scoped>
.danger-icon {
  font-size: 10px;
}

.orange {
  color: $yellow-mayday;
}

.red {
  color: $red-mayday;
}

.transparent {
  color: transparent;
}
</style>
