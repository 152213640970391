<template>
  <div class="drawer-footer">
    <el-button size="small" type="default" @click="$emit('close')">
      {{ $t('generic.close') }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'user-drawer-footer',
};
</script>

<style lang="scss" scoped>
.drawer-footer {
  display: flex;
  align-items: center;
  justify-content: right;
  background-color: #f8fafe;
}
</style>
