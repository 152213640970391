<template>
  <div class="more-solutions-btn">
    <span v-if="showDisplayMore" class="text-muted display-more" @click="$emit('click:more')">
      <font-awesome-icon class="mr-1 ml-4" :icon="['fad', 'eye']" />
      {{ $t('knowledge.product.display-more') }}
    </span>
    <span v-else-if="showDisplayLess" class="text-muted display-more" @click="$emit('click:less')">
      <font-awesome-icon class="mr-1 ml-4" :icon="['fad', 'eye']" />
      {{ $t('knowledge.product.display-less') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'display-more-btn',
  props: {
    totalCount: Number,
    countLimit: {
      type: Number,
      default: 10,
    },
    displayMoreLabel: {
      type: String,
      default: function() {
        return this.$t('knowledge.product.display-more');
      },
    },
    displayLessLabel: {
      type: String,
      default: function() {
        return this.$t('knowledge.product.display-less');
      },
    },
    displayMore: Boolean,
  },
  computed: {
    hasTooManyItems() {
      return this.totalCount > this.countLimit;
    },
    showDisplayMore() {
      return !this.displayMore && this.hasTooManyItems;
    },
    showDisplayLess() {
      return this.displayMore && this.hasTooManyItems;
    },
  },
};
</script>

<style lang="scss" scoped>
.display-more {
  cursor: pointer;
  &:hover {
    color: $blue-mayday !important;
  }
}
</style>