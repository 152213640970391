<template>
  <el-menu
    class="graph-type-menu"
    mode="horizontal"
    :default-active="type"
    @select="handleGraphTypeChange"
  >
    <el-menu-item index="line">
      <font-awesome-icon :icon="['fad', 'chart-line']" class="menu-icon" />
    </el-menu-item>
    <el-menu-item index="doughnut">
      <font-awesome-icon :icon="['fad', 'chart-pie']" class="menu-icon" />
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleGraphTypeChange(value) {
      this.$emit('update:type', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.graph-type-menu {
  border-bottom: unset;

  .el-menu-item,
  .el-menu-item:hover,
  .el-menu-item:focus {
    border-bottom: none;

    &:focus,
    &.is-active {
      border-bottom: 5px solid rgba($blue-mayday, 0.8);
      border-radius: 1px;
      color: $blue-mayday;
    }
  }

  .menu-icon {
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }
}
</style>
