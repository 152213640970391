<template>
  <div class="label mb-4">
    {{
      $t(
        'knowledge.drawers.content-view-setting.settings.company-favorite.title',
      )
    }}
    <el-tooltip
      :content="
        $t(
          'knowledge.drawers.content-view-setting.settings.company-favorite.tooltip',
        )
      "
      placement="top"
    >
      <font-awesome-icon
        :icon="['fad', 'info-circle']"
        class="info-icon mx-2"
      />
    </el-tooltip>
    <div class="toggle">
      <not-allowed-tooltip
        :user-permissions="userPermissions"
        :disable="true"
        permission-key="canUpdateContent"
        placement="top"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <el-switch
            v-model="switchValue"
            @change="$emit('update-company-favorite', $event)"
            :disabled="!hasPermissions"
          ></el-switch>
        </template>
      </not-allowed-tooltip>
    </div>
  </div>
</template>

<script>
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

export default {
  name: 'company-favorite',
  data() {
    return {
      switchValue: false,
    };
  },
  props: {
    isCompanyFavorite: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    NotAllowedTooltip,
  },
  watch: {
    isCompanyFavorite: {
      handler(newVal) {
        this.switchValue = newVal;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
}
:deep() .el-switch__core {
  height: 16px;
  width: 28px !important;
}

:deep() .el-switch__core:after {
  width: 12px;
  height: 12px;
}

:deep() .el-switch.is-checked .el-switch__core::after {
  margin-left: -13px;
}

.info-icon {
  color: $grey-5-mayday;
  font-size: 12px;
}
</style>
