<template>
  <div class="row justify-content-center">
    <section class="col-10">
      <p class="text-small font-weight-bold text-center">
        Sélectionnez les labels à apposer à ce contenu
      </p>

      <div>
        <div class="d-flex justify-content-lg-between">
          <p>
            <font-awesome-icon
              class="close-icon-notification mr-2"
              :icon="['fad', 'tags']"
            ></font-awesome-icon
            >Label
          </p>
          <base-switch v-model="switchIcon"></base-switch>
        </div>

        <div v-if="switchIcon" class="section d-flex justify-content-center">
          <el-select
            multiple
            v-model="iconSelection"
            value-key="id"
            placeholder="Sélectionner un type d'icône"
            @change="updateIcon"
            class="icon-selector__select"
          >
            <el-option-group
              v-for="category in labelCategories"
              :key="category.label"
              :label="category.label"
            >
              <el-option
                v-for="label in category.labels"
                :key="label.id"
                :label="label.labelName"
                :value="label"
              >
                <font-awesome-icon
                  :icon="['fad', label.icon]"
                  class="label__icon mr-2"
                  :style="{ color: label.color }"
                />
                {{ label.labelName }}
              </el-option>
            </el-option-group>
          </el-select>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BaseSwitch from '../../BaseSwitch.vue';

export default {
  name: 'icon-selector',
  components: {
    BaseSwitch,
  },
  props: {
    selectedIcons: Array,
  },
  data() {
    return {
      switchIcon: false,
      iconSelection: [],
      labelCategories: [],
    };
  },
  async created() {
    const labels =
      await this.$services.contentParameterLabels.getContentParameterLabels(
        null,
      );
    this.labelCategories = labels.reduce((acc, val) => {
      const { categoryName: groupLabel, ...label } = val;
      const categoryIdx = acc.findIndex((el) => el.label === groupLabel);
      if (categoryIdx > -1) {
        acc[categoryIdx].labels.push(label);
      } else {
        acc.push({ label: groupLabel, labels: [label] });
      }
      return acc;
    }, []);
    this.iconSelection = this.selectedIcons;
    this.switchIcon = this.iconSelection.length > 0;
  },
  methods: {
    updateIcon(val) {
      this.$emit('update-labels', val);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-small {
  color: $grey-5-mayday;
  font-size: 0.9em;
}

.grey-six {
  color: $grey-6-mayday;
}

.section {
  background-color: $grey-1-mayday;
  border-radius: 4px;

  :deep() .el-cascader {
    width: 90%;
  }
}

.icon-selector__select {
  width: 100%;
}
</style>
