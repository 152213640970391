<template>
  <div>
    <div class="parametric-home-knowledge-editor">
      <div v-loading="focusKnowledgeRootsIsLoading" class="whole-card-panel">
        <div class="folder-main-row">
          <div
            class="d-flex mx-0 align-items-center justify-content-between knowledge-header"
          >
            <div class="d-flex align-items-center">
              <div
                class="d-flex align-items-center mr-2 ml-3"
                v-if="hasCompanyLogo"
              >
                <img :src="companyLogo" class="header-logo" />
              </div>
              <el-divider
                direction="vertical"
                v-if="hasCompanyLogo"
              ></el-divider>
              <h1
                v-if="focusKnowledgeLabel"
                class="mb-0 ml-2 knowledge-label"
                :style="dynamicStyle"
              >
                {{ focusKnowledgeLabel }}
              </h1>
              <TranslationDropdown
                v-if="focusKnowledgeIsMultilinguale"
                class="mt-1 mx-2"
                :languages="focusKnowledgeLanguages"
                :current-language="editingLanguage"
                @change-language="switchEditingLanguage($event)"
              />
            </div>
          </div>

          <main class="container py-6">
            <!-- ROOT CARDS -->
            <draggable
              class="row justify-content-center mb-5"
              v-model="knowledge"
              draggable=".collection-card"
              @change="updateChildrenOrder()"
            >
              <parametric-root-folder-card
                class="px-0 m-4"
                v-for="(root, idx) in knowledge"
                :key="root.id"
                :node="root"
                :index="idx"
                @delete-node="deleteRootCollection($event)"
                @update-content="updateCollection(root, $event)"
                @update-content-icon="updateCollectionIcon(root, $event)"
                @delete-content-icon="deleteCollectionIcon(root, $event)"
                @update-content-published="handleUpdateContentPublished"
                @add-item="addCollectionItem(root, $event)"
                @focus-component="(_,newTab) => focusComponent(root,newTab)"
                @focus-sub-component="focusSubComponent(root, $event)"
                @focus-content="focusContent($event)"
                @add-child="focusComponent"
                @clone-case-to-target="handleCloneCaseToTarget(root, $event)"
              />

              <!-- ADD A CARD -->
              <not-allowed-tooltip
                v-if="!newCard"
                :user-permissions="
                  storeFocusKnowledge ? storeFocusKnowledge.userPermissions : {}
                "
                permission-key="canCreateContent"
              >
                <template v-slot:main-content="{ hasPermissions }">
                  <div
                    class="add-collection-card col-auto px-0 m-4"
                    type="frame"
                    shadow
                    @click="hasPermissions ? (newCard = true) : ''"
                  >
                    <div class="creation">
                      <font-awesome-icon
                        class="add-icon"
                        :icon="['fal', 'plus-circle']"
                      />
                      <p class="text-center my-auto text-muted mx-2">
                        {{ `${$t('knowledge.actions.add-collection')}` }}
                      </p>
                    </div>
                  </div>
                </template>
              </not-allowed-tooltip>

              <add-root-folder-card
                v-else
                class="col-auto px-0 m-4"
                @add-child="addCollection"
                @blur="newCard = false"
              />
            </draggable>
          </main>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapActions, mapGetters } from 'vuex';

import ParametricRootFolderCard from './ParametricRootFolderCard';
import AddRootFolderCard from '../HomeKnowledgeEditor/AddRootFolderCard';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';
import TranslationDropdown from '@/components/TranslationDropdown';

export default {
  name: 'parametric-home-knowledge-editor',
  components: {
    draggable,
    ParametricRootFolderCard,
    AddRootFolderCard,
    NotAllowedTooltip,
    TranslationDropdown,
  },
  data() {
    return {
      newCard: false,
      modals: {
        importFiles: false,
      },
    };
  },
  computed: {
    dynamicStyle() {
      return {
        '--company-color': this.companyColor,
      };
    },
    ...mapGetters({
      companyColor: 'companyColor',
      companyLogo: 'companyLogo',
      hasCompanyLogo: 'hasCompanyLogo',
      knowledges: 'knowledgeModule/knowledges',
      storeFocusKnowledge: 'knowledgeModule/focusKnowledge',
      knowledge: 'knowledgeModule/focusKnowledgeRoots',
      focusKnowledgeRootsIsLoading:
        'knowledgeModule/focusKnowledgeRootsIsLoading',
      focusKnowledgeLabel: 'knowledgeModule/focusKnowledgeLabel',
    }),
    ...mapGetters('knowledgeModule', [
      'focusKnowledgeLanguages',
      'editingLanguage',
      'focusKnowledgeIsMultilinguale',
    ]),
  },
  methods: {
    handleUpdateContentPublished(e) {
      this.updateContent(e);
    },
    async addCollection({ newLabel }) {
      await this.createKnowledgeRoot(newLabel);
      this.newCard = false;
    },
    async updateCollection(root, event) {
      await this.updateKnowledgeRoot({
        caseId: root.id,
        label: event.payload.label,
        childrenOrder: root.childrenOrder,
      });
    },
    async updateCollectionIcon(root, event) {
      try {
        await this.updateCaseIcon({
          caseId: root.id,
          icon: event.icon,
          field: event.field,
        });
        this.$message({
          message: this.$t('knowledge.actions.notify.update-success'),
          type: 'success',
        });
      } catch (e) {
        this.$message({
          message: this.$t('knowledge.actions.notify.update-failure'),
          type: 'error',
        });
      }
    },
    async updateCollectionMiniIcon(root, icon) {
      try {
        await this.updateCaseIcon({
          caseId: root.id,
          icon,
          mutation: 'updateCaseMiniIcon',
        });
        this.$message({
          message: this.$t('knowledge.actions.notify.update-success'),
          type: 'success',
        });
      } catch (e) {
        this.$message({
          message: this.$t('knowledge.actions.notify.update-failure'),
          type: 'error',
        });
      }
    },
    async deleteCollectionIcon(root, event) {
      await this.deleteCaseIcon({
        caseId: root.id,
        field: event.field,
      });
    },

    async addCollectionItem(root, item) {
      if (item.type == 'Diagnostic' || item.type == 'Article') {
        const payload = {
          knowledge: this.storeFocusKnowledge.value,
          ...item,
          parameters: [],
          caseParameters: [root.id],
          labels: [],
          accessRestrictions: [],
        };
        const content = await this.createContent(payload);
        this.updateCasePath([root]);
        this.focusContent({ content, root });
      } else if (item.type == 'Collection') {
        const newCaseId = await this.addCollectionChild({
          parentId: root.id,
          label: item.label,
        });
        const subCase = {
          id: newCaseId,
          label: item.label,
        };
        this.focusSubComponent(root, subCase);
      }
    },
    async focusSubComponent(root, subCase) {
      this.updateCasePath([root, subCase]);
      await this.goToTranslatedEntity({
        entityId: subCase.id,
        entityType: 'Case',
        open: subCase.newTab,
      });
    },
    async focusComponent(caseRoute, newTab) {
      this.updateCasePath([caseRoute]);
      await this.goToTranslatedEntity({
        entityId: caseRoute.id,
        entityType: 'Case',
        open: newTab,
      });
    },
    async focusContent({ content, caseRoute, newTab }) {
      this.updateCasePath([caseRoute]);
      await this.goToTranslatedEntity({
        entityId: content.id,
        entityType: 'Content',
        open: newTab
      });
    },
    async handleCloneCaseToTarget(root, payload) {
      const loadingMessage = this.$message({
        duration: 0,
        message: this.$t('knowledge.actions.clone-loading'),
      });
      try {
        await this.cloneCaseToTarget({
          caseId: root.id,
          ...payload,
        });
        loadingMessage.close();
        this.$message({
          message: this.$t('knowledge.actions.clone-success'),
          type: 'success',
        });
      } catch (e) {
        loadingMessage.close();
        this.$message({
          message: this.$t('knowledge.actions.clone-error'),
          type: 'error',
        });
      }
    },
    deleteRootCollection(event) {
      const { caseId, softDeleted } = event;
      this.deleteKnowledgeRoot({ caseId: caseId, softDeleted: softDeleted });
    },
    ...mapActions('kbStore', ['updateCasePath']),
    ...mapActions('knowledgeModule', [
      'createKnowledgeRoot',
      'updateKnowledgeRoot',
      'updateCaseIcon',
      'deleteCaseIcon',
      'deleteKnowledgeRoot',
      'createContent',
      'addCollectionChild',
      'cloneCaseToTarget',
      'updateContent',
      'switchEditingLanguage',
      'goToTranslatedEntity',
    ]),
  },
};
</script>

<style lang="scss" scoped src="./ParametricHomeKnowledgeEditor.scss"></style>
<style lang="scss" scoped>
.knowledge-header {
  height: 64px;
}
.knowledge-label {
  color: var(--company-color);
}

.import-files__icon {
  color: $grey-5-mayday;
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}

.translation-row {
  padding-right: 33px;
}
</style>
