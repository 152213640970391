var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"satisfaction-actions py-6"},[_c('div',{staticClass:"d-flex align-items-center text-muted"},[_c('el-divider'),_c('span',{staticClass:"satisfaction-title px-4"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.satisfaction-cta'))+" ")]),_c('el-divider')],1),_c('div',{staticClass:"col d-flex justify-content-around my-3"},[_c('div',{staticClass:"col-lg-3 col-md-5 col d-flex justify-content-around"},[_c('div',{class:[
          _vm.thumb === 'up' ? 'is-success' : '',
          'thumb-button',
          'thumb-up',
        ],on:{"click":_vm.addThumbUp}},[_c('font-awesome-icon',{staticClass:"thumbs-icon",attrs:{"icon":['fad', 'thumbs-up']}})],1),_c('div',{class:[
          _vm.thumb === 'down' ? 'is-danger' : '',
          'thumb-button',
          'thumb-down',
        ],on:{"click":_vm.addThumbDown}},[_c('font-awesome-icon',{staticClass:"thumbs-icon",attrs:{"icon":['fad', 'thumbs-down']}})],1)])]),(_vm.suggestFeedback)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('a',{staticClass:"satisfaction-feedback",on:{"click":_vm.openFeedbackModal}},[_vm._v(_vm._s(_vm.$t('knowledge.feedback')))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }