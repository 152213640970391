<template>
  <div class="trigger-option">
    <side-bar-item
      :description="tooltipDescription"
      :type="type"
      @click="$emit('click', !active)"
    >
      <template v-slot:icon="{ _class }">
        <font-awesome-icon
          slot="icon"
          :class="[_class, active ? 'active' : '']"
          :icon="['fad', 'plug']"
        />
      </template>
    </side-bar-item>
  </div>
</template>

<script>
import SideBarItem from './SideBarItem';

export default {
  name: 'trigger-option',
  components: {
    SideBarItem,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    type: String,
  },
  data() {
    return {
      tooltipDescription: this.$t(
        'knowledge.diagnostic-builder.side-bar.triggers.tooltip',
      ),
    };
  },
};
</script>
