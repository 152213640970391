<template>
  <div class="search-on-focus-case">
    <div class="icon-wrapper">
      <font-awesome-icon :icon="['fas', 'lightbulb']" class="icon" />
    </div>
    <div>{{ $t('knowledge.search-admin.restrict-focus-case') }}</div>
    <Tag :tag="tag" @focus="handleSearchInFocusCase" class="ml-2" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Tag from './Tag';
export default {
  name: 'search-in-focus-case',
  props: {
    focusCaseId: {
      type: String,
      default: '',
    },
  },
  components: {
    Tag,
  },
  mounted() {
    if (this.searchFocusLevel === 'case') this.searchInFocusCase = true;
  },
  methods: {
    handleSearchInFocusCase() {
      this.$emit('activate-search-focus-case', true);
    },
  },
  computed: {
    tag() {
      return {
        id: null,
        icon: ['fad', 'folder'],
        label: this.focusCaseId
          ? this.caseLabelFromNavPath(this.focusCaseId)
          : this.$t('knowledge.search-admin.current-collection'),
      };
    },
    ...mapGetters('webKnowledgeModule', [
      'searchFocusLevel',
      'caseLabelFromNavPath',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.search-on-focus-case {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}
.icon-wrapper {
  height: 20px;
  display: flex;
  align-items: center;
  width: 20px;
  background-color: $blue-mayday;
  justify-content: center;
  margin-right: 6px;
  border-radius: 4px;
}
.icon {
  height: 14px;
  width: 14px;
  color: white;
}
</style>
