<template>
  <div class="subitem-container">
    <div
      v-for="subItem in filteredSubItems"
      :key="subItem.title"
      class="subitem-title"
      :class="{ 'subitem-title-hidden': subItem.hidden }"
    >
      <el-tooltip
        :content="
          $t(
            `settings.permissions.role-dropdown.statuses.${computeState(
              subItem,
            )}`,
          )
        "
        :disabled="computeState(subItem) === 'full'"
        placement="top"
      >
        <div :class="computeState(subItem)" class="state-indicator" />
      </el-tooltip>
      {{ $t(subItem.title) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'role-permissions-state',
  props: {
    subItems: {
      type: Array,
      default: () => [],
    },
    rolePermissions: {
      type: Array,
      default: () => [],
    },
    companyPreferences: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    computeState({ relatedPermissions, type }) {
      const length = this.rolePermissions.filter((permission) =>
        relatedPermissions.includes(permission.rolePermission),
      ).length;
      if (length === 0) return 'none';
      if (length === relatedPermissions.length) {
        if (type !== 'Scoped') return 'full';
        const scopedPermission = this.rolePermissions.find(
          (permission) => permission.rolePermission === relatedPermissions[0],
        );
        const hasScope =
          scopedPermission.scope &&
          Object.keys(scopedPermission.scope).length &&
          Object.keys(scopedPermission.scope)[0].length;
        if (hasScope) return 'scoped';
        return 'full';
      }
      return 'partial';
    },
  },
  computed: {
    filteredSubItems() {
      return this.subItems.filter((subItem) => {
        if (!subItem.companyPreferences) return true;

        const everyPreferences = subItem.companyPreferences.every(
          (preference) =>
            this.companyPreferences.find((p) => p.type === preference),
        );

        return everyPreferences;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.subitem-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.state-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $grey-4-mayday;
  display: inline-block;
  &.full {
    background-color: $green-mayday;
  }
  &.partial {
    background-color: $orange-mayday;
  }
  &.scoped {
    background-color: $blue-mayday;
  }
  margin-right: 2px;
}
.subitem-title {
  font-size: 14px;
}

.subitem-title-hidden {
  filter: grayscale(1);
  opacity: 0.5;
}
</style>
