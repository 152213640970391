<template>
  <div class="navigator-node">
    <CommonNode
      v-if="!isFirst"
      :node="node"
      :isRoot="node.root"
      :position="position"
      :parent-ids="parentIds"
      :show-children="showChildren"
      :draggable="draggable"
      @focus="componentFocused"
      @add-child="triggerAddChild"
      @delete-node="deleteNode"
      @update-content="handleUpdateContent"
      @update-content-verification="updateContentVerification"
      @clone-to-target="handleCloneToTarget"
      @toggle-children="toggleChildren"
      @add-child-from-template="openTemplateModal"
    />

    <!-- NAVIGATION -->
    <div v-if="showChildren && this.isFolder" v-loading="isLoading">
      <draggable
        class="dragArea"
        :class="[
          parentIds.length === 1 ? 'collection-one' : 'collection-two',
          !allowed ? 'cursor-not-allowed' : null,
        ]"
        tag="ul"
        draggable=".tree-node"
        group="draggable-nodes"
        v-model="draggableNodes"
        :disabled="!draggable"
        @change="handleDragAndDrop"
        :swap-treshold="0.8"
        :invert-swap="true"
        :move="checkMove"
        @end="onEnd"
      >
        <!-- ALREADY EXISTING CHILDREN -->
        <li
          class="tree-node without_bullet"
          v-for="(node, idx) in draggableNodes"
          :key="node.id"
        >
          <navigator-node
            :node="node"
            :show-children="false"
            :position="idx"
            :parent-ids="parentIds.concat([node.id])"
            :focus-path="focusPath"
            :draggable="draggable"
          />
        </li>

        <!-- JUST CREATED CHILD -->
        <li v-if="newChildFocusType" class="without_bullet">
          <NewNode
            class="new-node"
            :class="{
              'new-node-collection-two': parentIds.length !== 1,
            }"
            :parent-id="node.id"
            :parent-ids="parentIds.concat([0])"
            :type="newChildFocusType"
            @blur="newChildFocusType = null"
            @add-child="addChild($event)"
          />
        </li>

        <!-- EMPTY STATE -->
        <li
          v-if="
            nodes &&
            nodes.length === 0 &&
            newChildFocusType === null &&
            !isLoading
          "
          class="without_bullet"
        >
          <p class="mb-0">
            <small>{{ $t('knowledge.root.empty-1') }}</small>
          </p>
        </li>
      </draggable>
    </div>
    <ModalEvents
      modal-name="AddTemplateModal"
      :uid="templateModalUniqueKey"
      @choice="addChildFromTemplate"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import draggable from 'vuedraggable';

import DragAndDropMixin from 'mixins/DragAndDropMixin';
import UtilsKnowledgeMixin from 'mixins/KnowledgeMixin/UtilsKnowledgeMixin';
import CommonNode from './CommonNode/CommonNode';
import NewNode from './NewNode';

import ModalEvents from '@/components/Modals/ModalEvents';

export default {
  name: 'navigator-node',
  mixins: [DragAndDropMixin, UtilsKnowledgeMixin],
  props: {
    node: {
      type: Object,
      default: () => ({}),
    },
    position: Number,
    parentIds: Array,
    isFirst: {
      type: Boolean,
      default: false,
    },
    focusPath: {
      type: Array,
      default: () => [],
    },
    draggable: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    draggable,
    CommonNode,
    NewNode,
    ModalEvents,
  },
  computed: {
    nodes() {
      if (!this.node.childrenOrder) return [];

      return this.getCaseOrderedChildren(
        this.node.childrenOrder.map(({ id, entityType }) => {
          return `${entityType}-${id}`;
        }),
      );
    },
    isFolder() {
      return !this.node.type;
    },
    isFocused() {
      return this.focusItemId(this.isFolder) === this.node.id;
    },
    shouldOpenFolder() {
      return (
        this.isFolder &&
        !this.showChildren &&
        this.focusPath &&
        this.focusPath.includes(this.node.id)
      );
    },
    templateModalUniqueKey() {
      return `${this.$options.name}-template-${this.node.id}`;
    },
    ...mapGetters('knowledgeModule', [
      'focusItemId',
      'focusKnowledgeRoots',
      'focusKnowledgeValue',
      'getCaseOrderedChildren',
      'editingLanguage',
    ]),
  },
  data() {
    return {
      newChildFocusType: null,
      showChildren: this.isFirst,
      allowed: true,
      isLoading: false,
      loadingMessage: null,
      draggableNodes: [],
      dragAndDropHandlers: {
        added: this.handleAdd,
        removed: this.handleRemove,
        moved: this.handleMove,
      },
    };
  },
  mounted() {
    this.draggableNodes = [...this.nodes];
    if (this.shouldOpenFolder) {
      this.toggleChildren();
    }
  },
  methods: {
    // DRAG AND DROP METHODS
    /**
     * checkMove verifies that contents other than Folders aren't moved
     * to the root
     */
    checkMove(evt) {
      const draggedElementType = evt.draggedContext.element.type;
      if (
        Array.from(evt.to.classList).includes('collection-one') &&
        draggedElementType
      ) {
        this.allowed = this.dragoverNodeId !== null;
        return false;
      }
      this.allowed = true;
      return true;
    },

    /**
     * onEnd we notify the user if drop is forbidden and notify opened
     * CommonNavigatorNode that drag and drop is over.
     */
    onEnd() {
      if (!this.allowed) {
        this.$message.error(
          'Vous ne pouvez pas deposer un diagnostic ou un article a la racine',
        );
      }
    },

    /**
     * once drag and drop is over, we update the nodes for the former
     * and new parents.
     */
    handleDragAndDrop(e) {
      const type = Object.keys(e)[0];
      const path = this.isFirst
        ? this.parentIds[0]
        : this.parentIds
            .filter((_id, index) => index !== 0) // 0 index is knowledge, we don't care about that
            .join('/');

      const eventHandler = this.dragAndDropHandlers[type];
      eventHandler(e, path);
    },

    handleAdd(addEvent, path) {
      const {
        added: { element, newIndex },
      } = addEvent;
      this.addNodeToCase({
        nodeId: element.id,
        nodeType: element.type,
        newIndex,
        path,
        isKnowledge: this.isFirst,
      });
    },

    handleRemove(removeEvent, path) {
      const {
        removed: { element },
      } = removeEvent;
      this.removeNodeFromCase({
        nodeId: element.id,
        nodeType: element.type,
        path,
        isKnowledge: this.isFirst,
      });
    },

    // Drag and drop in same collection, user is changing childrenOrder
    handleMove() {
      const newChildrenOrder = this.draggableNodes.map(({ id, type }) => {
        return {
          id,
          entityType: type ? 'Content' : 'Case',
        };
      });

      if (this.isFirst) {
        this.updateHierarchyChildrenOrder({
          id: this.node.id,
          childrenOrder: newChildrenOrder,
        });
      } else {
        this.updateCollection({
          caseId: this.node.id,
          label: this.node.label,
          body: this.node.body,
          childrenOrder: newChildrenOrder,
        });
      }
    },

    handleUpdateContent(e) {
      if (this.isFolder) {
        this.updateCollection({
          caseId: this.node.id,
          label: e.payload.label,
          body: this.node.body,
          childrenOrder: this.node.childrenOrder,
        });
      } else {
        this.updateContent(e);
      }
    },

    async updateContentVerification({ id, isOutdated }) {
      await this.bulkUpdateStatus({
        contentIds: [id],
        key: 'isOutdated',
        value: isOutdated,
      });
    },

    async openTemplateModal() {
      const payload = {
        component: 'AddTemplateModal',
        uid: this.templateModalUniqueKey,
        props: {
          display: true,
        },
      };
      this.openModal(payload);
    },

    addChildFromTemplate(template) {
      const { content, id } = template;

      const payload = {
        id: this.node.id,
        label: content.label,
        type: content.type,
        templateId: id,
      };
      this.addChild(payload);
    },

    triggerAddChild(e) {
      this.newChildFocusType = e;
      this.showChildren = true;
    },
    async addChild({ id, label, type, templateId }) {
      let newFocusItemId, newFocusItemType;

      if (this.isFirst) {
        const rootCase = await this.createKnowledgeRoot(label);
        newFocusItemId = rootCase.id;
        newFocusItemType = 'case';
      } else if (type === 'Folder') {
        newFocusItemId = await this.addCollectionChild({
          parentId: id,
          label,
        });
        newFocusItemType = 'case';
      } else {
        const caseParameters = this.parentIds
          .filter((_id, index) => index !== 0) // 0 index is knowledge, we don't care about that
          .join('/');
        const payload = {
          knowledge: this.focusKnowledgeValue,
          type,
          label,
          parameters: [
            { key: 'knowledge', values: [this.focusKnowledgeValue] },
          ],
          caseParameters: [caseParameters],
          accessRestrictions: [],
          labels: [],
          templateId,
        };
        const { id } = await this.createContent(payload);
        newFocusItemId = id;
        newFocusItemType = 'content';
        this.updateNavigatorCaseParents({
          caseParameters,
          lang: this.editingLanguage,
        });
      }
      this.newChildFocusType = null;
      if (newFocusItemId && newFocusItemType)
        this.focusNavigatorNode(newFocusItemId, newFocusItemType);
    },
    focusNavigatorNode(id, type) {
      const path = `/knowledge/${type}/${id}`;
      this.$router.push({
        path,
      });
    },
    async handleCloneToTarget(event) {
      const { payload, closeCallback, type } = event;
      const loadingMessage = this.$message({
        duration: 0,
        message: this.$t('knowledge.actions.clone-loading'),
      });
      try {
        if (type === 'case') {
          await this.cloneCaseToTarget(payload);
        } else {
          await this.cloneContentsToTarget(payload);
        }
        loadingMessage.close();
        this.$message({
          message: this.$t('knowledge.actions.clone-success'),
          type: 'success',
        });
      } catch (e) {
        loadingMessage.close();
        this.$message({
          message: this.$t('knowledge.actions.clone-error'),
          type: 'error',
        });
      }
      closeCallback();
    },
    async deleteNode(event) {
      const { softDeleted } = event ? event : false;
      this.setNavigatorNodeIsLoading(true);
      this.triggerLoadingMessage(this.$t('knowledge.actions.delete-loader'));

      if (this.isFolder) {
        await this.deleteCollection({
          caseId: this.node.id,
          softDeleted: softDeleted,
        });
      } else {
        await this.bulkArchiveContents({
          contentIds: [this.node.id],
          softDeleted: softDeleted,
        });
        this.flagRedirectionStepsAsDeleted(this.node.id);
      }
      if (this.isFocused) {
        this.$router.go(-1);
      }

      this.setNavigatorNodeIsLoading(false);
      this.loadingMessage.close();
    },
    triggerLoadingMessage(msg) {
      this.loadingMessage = this.$message({
        duration: 0,
        dangerouslyUseHTMLString: true,
        iconClass: 'display: none',
        showClose: true,
        message: `<i class="fas fa-spinner fa-spin" style="margin-right: 1em;"></i> ${msg}`,
      });
    },
    async componentFocused() {
      await this.goToTranslatedEntity({
        entityId: this.node.id,
        entityType: this.isFolder ? 'Case' : 'Content',
      });
      if (this.isFolder) {
        this.$emit('component-focused', [this.node]);
      } else {
        this.$emit('component-focused', []);
      }
      if (!this.showChildren) this.toggleChildren({ noFetch: true });
    },
    async toggleChildren({ noFetch } = {}) {
      if (!this.isFolder) return;
      this.showChildren = !this.showChildren;

      if (noFetch) return;

      if (this.showChildren) {
        this.isLoading = true;
        await this.getCaseById({ caseId: this.node.id });
        this.isLoading = false;
      }
    },
    ...mapActions('knowledgeModule', [
      'getCaseById',
      'updateContent',
      'bulkUpdateStatus',
      'bulkArchiveContents',
      'flagRedirectionStepsAsDeleted',
      'addCollectionChild',
      'deleteCollection',
      'updateCollection',
      'updateHierarchyChildrenOrder',
      'createKnowledgeRoot',
      'createContent',
      'cloneCaseToTarget',
      'cloneContentsToTarget',
      'addNodeToCase',
      'removeNodeFromCase',
      'setNavigatorNodeIsLoading',
      'updateNavigatorCaseParents',
      'goToTranslatedEntity',
    ]),
    ...mapActions('modalsModule', ['openModal']),
  },
  watch: {
    focusPath(newVal, oldVal) {
      if(oldVal && newVal) {
        if (oldVal.toString() === newVal.toString()) {
          return;
        }
      }
      if (this.shouldOpenFolder) {
        this.toggleChildren();
      }
    },
    nodes() {
      this.draggableNodes = [...this.nodes];
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-not-allowed {
  cursor: not-allowed;
}

.dragArea {
  min-height: 30px;
  padding-left: 28px;
}

.collection-one {
  padding-left: 0 !important;
}

.collection-two {
  padding-left: 16px !important;
}

.without_bullet {
  list-style: none;
}
</style>
