<template>
  <BaseActionCollapse
    :header-title="
      $t('knowledge.action-triggers.options.salesforce-case-update')
    "
    :header-custom-icon-path="icon"
    :auto-open="autoOpen"
    :save-disabled="!isValid"
    :is-new="isNew"
    @create="$emit('create', { actionPayload, langs, title })"
    @delete="$emit('delete')"
  >
    <template v-slot:additional-header>
      <div class="addition-header-wrapper">
        <span>{{ title }}</span>
        <div v-if="langs.length" class="languages-count-wrapper">
          <font-awesome-icon :icon="['fal', 'globe-stand']" />
          <span
            >{{ langs.length }}
            {{
              $tc('knowledge.action-triggers.forms.languages', langs.length)
            }}</span
          >
        </div>
      </div>
    </template>

    <template v-slot:body>
      <div class="salesforce-action-form">
        <WorkflowInput
          :title="$t('knowledge.action-triggers.forms.action-title')"
          :placeholder="$t('knowledge.action-triggers.forms.action-title')"
          :value="title"
          @input="title = $event"
        />
        <WorkflowSelect
          v-if="languagesOptions.length > 1"
          :title="$tc('knowledge.action-triggers.forms.languages', 2)"
          :placeholder="
            $t('knowledge.action-triggers.forms.select-placeholder')
          "
          multiple
          :options="languagesOptions"
          :value="langs"
          @change="languages = $event"
        />
        <div class="salesforce-attributes-wrapper">
          <span>{{
            $t('knowledge.action-triggers.salesforce-form.fields-title')
          }}</span>
          <template
            v-if="
              salesforceCompanyAttributes && salesforceCompanyAttributes.length
            "
          >
            <SalesforceCaseActionInput
              v-for="(attribute, i) in actionPayload.attributes"
              :key="attribute.key"
              :forbidden-keys="forbiddenKeys"
              :value="attribute"
              :attributes="salesforceCompanyAttributes"
              @delete="handleDelete(i)"
              @change="handleChange($event, i)"
            />
            <div
              class="salesforce-attributes-add-button"
              @click="addEmptyAttribute"
            >
              <font-awesome-icon :icon="['fal', 'plus-circle']" />
              <span>{{
                $t('knowledge.action-triggers.salesforce-form.field')
              }}</span>
            </div>
          </template>
          <span v-else class="text-center">{{
            $t('knowledge.action-triggers.salesforce-form.no-attributes')
          }}</span>
        </div>
      </div>
    </template>
  </BaseActionCollapse>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseActionCollapse from './Base/BaseActionCollapse';
import WorkflowSelect from '../Workflow/WorkflowSelect';
import WorkflowInput from '../Workflow/WorkflowInput';
import SalesforceCaseActionInput from './SalesforceCaseActionInput';

import salesforceIcon from 'assets/brand/salesforce.svg';

export default {
  name: 'salesforce-case-action-form',
  components: {
    BaseActionCollapse,
    WorkflowSelect,
    WorkflowInput,
    SalesforceCaseActionInput,
  },
  props: {
    languagesOptions: {
      type: Array,
      default: () => [],
    },
    autoOpen: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Object,
      required: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icon: salesforceIcon,
      langs: [],
      salesforceAttributes: [],
      actionPayload: {
        attributes: [{ key: '', value: '', type: 'text' }],
        source: 'salesforce',
        companyAttributes: true,
      },
      title: '',
    };
  },
  computed: {
    forbiddenKeys() {
      return this.actionPayload.attributes.map((attr) => attr.key);
    },
    isValid() {
      return (
        this.actionPayload.attributes.length &&
        this.actionPayload.attributes.every((attr) => attr.key && attr.value)
      );
    },
    ...mapGetters(['salesforceCompanyAttributes']),
  },
  methods: {
    addEmptyAttribute() {
      this.actionPayload.attributes = [
        ...this.actionPayload.attributes,
        { key: '', value: '', type: 'text' },
      ];
    },
    handleDelete(index) {
      this.actionPayload.attributes.splice(index, 1);
    },
    handleChange({ key, value, type }, index) {
      const copy = { ...this.actionPayload };
      copy.attributes[index] = { key, value, type };
      this.actionPayload = copy;
    },
  },
  watch: {
    action: {
      handler() {
        if (!this.action) return;
        this.actionPayload = this.action.payload;
        this.langs = this.action.langs;
        this.title = this.action.title;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.languages-count-wrapper {
  color: $grey-5-mayday;
  font-size: 12px;
  font-weight: lighter;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
  border-radius: 4px;
  background-color: $grey-1-mayday;
}

.addition-header-wrapper {
  font-weight: 500;
  color: $grey-5-mayday;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.salesforce-action-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.salesforce-attributes-wrapper {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.salesforce-attributes-add-button {
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  padding-inline: 8px;
  color: $blue-mayday;
  border: 1px solid $blue-mayday;
  background-color: rgba($blue-mayday, 0.1);
  border-radius: 4px;
  cursor: pointer;
}
</style>
