<template>
  <div class="redirect-to-mayday-web-button">
    <el-tooltip
      :content="$t('knowledge.actions.go-to-web')"
      placement="left"
      class="group-tooltip"
    >
      <a class="icon-wrapper" :href="redirectUrl" target="_blank">
        <font-awesome-icon :icon="['fal', 'external-link']" />
      </a>
    </el-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getDeskTranslatedEntityUrl,
  getDeskParametricTranslatedEntityUrl,
} from '@/utils/adminToDeskRouteMapping';

export default {
  name: 'web-redirection',
  computed: {
    redirectToDeskParametric() {
      const lang = this.editingLanguage;
      if (!this.focusKnowledge) return `/desk/parametric/${lang}`;

      const { name, query, hash, params } = this.$route;
      const url = getDeskParametricTranslatedEntityUrl({
        name,
        entityId:
          params.stepId ||
          params.contentId ||
          params.productId ||
          params.externalId,
        lang,
        hash,
        query,
      });

      return url;
    },
    redirectToDesk() {
      if (!this.focusKnowledge) return '/desk';

      const { query, hash, params } = this.$route;

      const url = getDeskTranslatedEntityUrl({
        entityId: params.stepId || params.contentId || params.caseId,
        entityType: params.caseId ? 'Case' : 'Content',
        lang: this.editingLanguage,
        hash,
        query,
        knowledgeId: this.focusKnowledge.id,
      });

      return url;
    },
    redirectUrl() {
      if (this.isParametric) return this.redirectToDeskParametric;

      return this.redirectToDesk;
    },
    ...mapGetters(['isParametric']),
    ...mapGetters('knowledgeModule', ['focusKnowledge', 'editingLanguage']),
  },
};
</script>

<style lang="scss" scoped>
.redirect-to-mayday-web-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  font-size: 14px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $grey-6-mayday;
  &:hover {
    background-color: $grey-2-mayday;
    color: $blue-mayday;
  }
}
</style>
