<template>
  <el-collapse :style="customStyle">
    <el-collapse-item
      v-for="el in parsedDocumentations"
      :class="`${baseClass}__tech-ref`"
      :key="el.code"
      :name="el.code"
      :disabled="el.documents ? !el.documents.length : true"
    >
      <template slot="title">
        <span :class="`${baseClass}__tech-ref__title`">{{ el.code }}</span>
        <font-awesome-icon
          :icon="['fal', 'times-circle']"
          :class="`${baseClass}__tech-ref__disabled`"
        ></font-awesome-icon>
      </template>
      <el-collapse>
        <el-collapse-item
          :class="`${baseClass}__category`"
          v-for="category in el.documents"
          :key="category.type"
          :name="category.type"
        >
          <template slot="title" :class="`${baseClass}__category__title`">
            <h6>
              <span :class="[categoryScrollingClass(category.type)]">{{
                category.type
              }}</span>
            </h6>
            <font-awesome-icon
              :class="`${baseClass}__category__title-plus`"
              :icon="['far', 'plus']"
            ></font-awesome-icon>
            <font-awesome-icon
              :class="`${baseClass}__category__title-minus`"
              :icon="['far', 'minus']"
            ></font-awesome-icon>
          </template>
          <a
            v-for="doc in category.documents"
            :key="doc.documentId"
            :class="`${baseClass}__category__document`"
            :href="doc.documentLink"
          >
            <small
              ><span :class="[documentScrollingClass(doc.sousType)]">{{
                doc.sousType
              }}</span></small
            >
            <font-awesome-icon
              :icon="['fal', 'external-link']"
            ></font-awesome-icon>
          </a>
        </el-collapse-item>
      </el-collapse>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
  name: 'product-agora-documentation',
  props: {
    documentations: {
      type: Array,
      default: () => [],
    },
    context: {
      type: String,
      default: 'side-bar',
    },
  },
  data() {
    return {
      baseClass: 'product-agora-documentation',
    };
  },
  computed: {
    customStyle() {
      switch (this.context) {
        case 'modal':
          return {
            '--techRefHeight': '40px',
            '--categoryHeight': '40px',
            '--categoryFontSize': '14px',
            '--documentHeight': '40px',
            '--documentFontSize': '16px',
          };
        default:
          return {
            '--techRefHeight': '36px',
            '--categoryHeight': '22px',
            '--categoryFontSize': '12px',
            '--documentHeight': '22px',
            '--documentFontSize': '10px',
          };
      }
    },

    shouldScrollOnHover(text) {
      return text && text.length > 100 ? 'scroll-on-hover' : '';
    },
    parsedDocumentations() {
      return this.documentations.map((techRef) => {
        const categories = techRef.documentations.reduce((acc, val) => {
          if (!acc[val.type]) acc[val.type] = { type: val.type, documents: [] };
          acc[val.type].documents.push(val);
          return acc;
        }, {});
        return {
          code: techRef.code,
          documents: Object.values(categories),
        };
      });
    },
    categorySizeLimit() {
      return this.context === 'modal' ? 35 : 25;
    },
    categoryScrollingClass() {
      return (text) => {
        const textIsTooLong = text && text.length > this.categorySizeLimit;
        if (!textIsTooLong) return '';
        return this.context === 'modal' ? 'scroll' : 'scroll-on-hover';
      };
    },
    documentSizeLimit() {
      return this.context === 'modal' ? 35 : 30;
    },
    documentScrollingClass() {
      return (text) => {
        const textIsTooLong = text && text.length > this.documentSizeLimit;
        if (!textIsTooLong) return '';
        return this.context === 'modal' ? 'scroll' : 'scroll-on-hover';
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.product-agora-documentation {
  .is-disabled {
    :deep() .el-collapse-item__header {
      color: $grey-6-mayday;
      background-color: $grey-1-mayday;
      display: flex;
      justify-content: space-between;
      padding-right: 9px;

      .el-collapse-item__arrow {
        display: none;
      }
      .product-agora-documentation__tech-ref__disabled {
        display: inline;
      }
    }
  }

  &__tech-ref {
    :deep() .el-collapse {
      border-top: none;
      border-bottom: none;
    }

    :deep() .el-collapse-item__header {
      height: var(--techRefHeight);
      color: $grey-8-mayday;
      font-size: 12px;
      padding-left: 4px;
    }

    &__title {
      height: var(--techRefHeight);
      line-height: 0px;
      display: flex;
      align-items: center;
    }

    &__disabled {
      display: none;
    }
  }

  &__wrapper {
  }

  &__category {
    display: flex;
    flex-direction: column;

    &.is-active {
      :deep() .product-agora-documentation__category__title {
        &-plus {
          display: none;
        }
        &-minus {
          display: inline;
        }
      }
    }

    :deep() .el-collapse-item__wrap {
      border-bottom: none;
    }

    :deep() .el-collapse-item__header {
      border-bottom: none;
      height: var(--categoryHeight);
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $grey-1-mayday;
      color: $grey-8-mayday;
      padding: 4px 9px 4px 6px;
      margin-bottom: 4px;

      .el-collapse-item__arrow {
        display: none;
      }

      h6 {
        margin: 0px;
        color: inherit;
        font-size: var(--categoryFontSize);
        width: 90%;
        overflow: hidden;

        span {
          display: inline-block;
          white-space: nowrap;
          position: relative;
        }
      }
    }

    &__title {
      &-plus {
        display: inline;
      }
      &-minus {
        display: none;
      }
    }
    &__document {
      height: var(--documentHeight);
      display: flex;
      width: 100%;
      background-color: #0081f9;
      padding: 2px 6px;
      border-radius: 4px;
      color: white;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2px;
      &:hover {
        cursor: pointer;
      }

      small {
        font-size: var(--documentFontSize);
        width: 90%;
        overflow: hidden;

        span {
          display: inline-block;
          white-space: nowrap;
          position: relative;
        }
      }
    }
  }
}

.scroll-on-hover {
  &:hover {
    animation: scroll 5s linear infinite;
  }
}

.scroll {
  animation: scroll 5s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0); /* Défilement au début */
  }
  100% {
    transform: translateX(-75%); /* Défilement complet vers le haut */
  }
}
</style>
