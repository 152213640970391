<template>
  <div class="common-node">
    <div
      class="main-row d-flex align-items-center flex-nowrap"
      :class="{
        isDraft: isContent && !content.published,
        'is-dragged-over':
          !isBeingDragged && dragover && isFolder && !showChildren,
        'is-being-dragged': isBeingDragged,
      }"
      id="parent"
      :draggable="draggable"
      @dragstart="dragStart"
      @dragend="dragEnd"
      @dragover="dragOver"
      @dragleave="dragLeave"
      @mouseenter="isHovered = true"
      @mouseleave="isHovered = false"
    >
      <CommonNodeIcon
        :class="{ 'ml-2px': isContent }"
        :node-type="node.type"
        :show-children="showChildren"
        :is-focused="isFocused"
        @toggle-children="$emit('toggle-children')"
        @focus="$emit('focus')"
      />
      <CommonNodeLabel
        class="flex-grow-1 d-flex align-items-center"
        :class="{ focus: isFocused }"
        :edit="edit_label"
        :label="content.label"
        :parent-ids="parentIds"
        :node-type="node.type"
        @focus="$emit('focus')"
        @click="handleClick"
        @escape-edit="edit_label = false"
        @edit-label="edit_label = true"
        @update-content="updateContent"
      />
      <div class="pl-2 pr-1 d-flex align-items-center">
        <CommonNodeStatus
          v-if="isContent && parentIds.length !== 2"
          :isNode="true"
          :content="content"
          @update-content="updateContent"
          @update-content-verification="updateContentVerification"
          class="mr-1 node-status d-flex align-items-center"
        />
        <CommonNodeOptions
          v-if="isHovered"
          class="d-flex align-items-center node-options"
          id="children"
          :label="content.label"
          :nodeId="node.id"
          :node-type="node.type"
          :parent-ids="parentIds"
          :is-outdated="content.isOutdated"
          :user-permissions="node.userPermissions"
          @edit-label="edit_label = true"
          @focus="$emit('focus', true)"
          @add-child="$emit('add-child', $event)"
          @add-child-from-template="$emit('add-child-from-template')"
          @clone-to-target="$emit('clone-to-target', $event)"
          @update-content="updateContent"
          @update-content-verification="updateContentVerification"
          @delete-node="$emit('delete-node', $event)"
        />
        <!-- TRANSLATION -->
        <TranslationTooltip
          class="d-flex align-items-center"
          :is-translated="isTranslated"
          :is-content="isContent"
          :has-translated-contents="hasTranslatedContents"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CommonNodeStatus from './CommonNodeStatus';
import CommonNodeIcon from './CommonNodeIcon';
import CommonNodeLabel from './CommonNodeLabel';
import CommonNodeOptions from './CommonNodeOptions/CommonNodeOptions';
import TranslationTooltip from '@/components/Commons/TranslationTooltip';

export default {
  name: 'common-node',
  props: {
    node: Object,
    position: Number,
    parentIds: Array,
    isRoot: {
      type: Boolean,
      default: false,
    },
    showChildren: Boolean,
    draggable: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    CommonNodeStatus,
    CommonNodeIcon,
    CommonNodeLabel,
    CommonNodeOptions,
    TranslationTooltip,
  },
  computed: {
    content() {
      return this.node;
    },
    label() {
      return this.content.label;
    },
    isFolder() {
      return !this.node.type;
    },
    isContent() {
      return !!this.node.type;
    },
    isFocused() {
      return this.focusItemId(this.isFolder) === this.node.id;
    },
    isTranslated() {
      return this.node.returnLanguage === this.navigationLanguage;
    },
    hasTranslatedContents() {
      return this.node.untranslatedWithTranslatedContents;
    },
    ...mapGetters('knowledgeModule', ['focusItemId', 'navigationLanguage']),
  },
  data() {
    return {
      edit_label: false,
      dragover: false,
      isBeingDragged: false,
      draggedNodeId: null,
      isHovered: false,
    };
  },
  mounted() {
    this.$root.$on('dragged-node-id', (id) => {
      this.draggedNodeId = id;
      if (!id) this.dragover = false;
    });
  },
  methods: {
    dragStart() {
      this.$root.$emit('dragged-node-id', this.node.id);
      this.isBeingDragged = true;
    },
    dragEnd() {
      this.$root.$emit('dragged-node-id', null);
      this.isBeingDragged = false;
    },
    updateContent(payload) {
      this.$emit('update-content', {
        id: this.content.id,
        payload: {
          ...payload,
          lang: this.editingLanguage,
        },
      });
      this.edit_label = false;
    },
    updateContentVerification(isOutdated) {
      this.$emit('update-content-verification', {
        id: this.content.id,
        isOutdated,
      });
    },
    dragOver() {
      if (this.isBeingDragged || this.parentIds.includes(this.draggedNodeId)) {
        return;
      }
      this.dragover = true;
      setTimeout(() => {
        if (this.dragover && !this.showChildren) {
          this.$emit('toggle-children');
        }
      }, 750);
    },
    dragLeave() {
      this.dragover = false;
    },
    handleClick() {
      const { id } = this.content;
      const type = this.isFolder ? 'case' : 'content';
      const origin = new URL(window.location.href).origin;
      const href = `${origin}/knowledge/${type}/${id}`;
      return window.open(href, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.node-status {
  position: relative;
  z-index: 1;
  right: 0;
}

.ml-2px {
  margin-left: 2px;
}
.main-row {
  height: 32px;
  padding-left: 4px;
  margin-left: -4px;
}

.main-row:hover {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 1px;
}

.isDraft {
  color: #adb5bd;
}

#children {
  position: absolute;
}

#parent #children {
  visibility: hidden;
  right: 5px;
}

#parent:hover #children {
  right: 5px;
  position: relative;
  visibility: visible;
}

.is-dragged-over {
  border: 1px solid $blue-mayday;
  background-color: rgba($blue-mayday, 0.2);
  border-radius: 2px;
}

.is-being-dragged {
  border-left: 2px solid $blue-mayday;
}
</style>
