const QUOTES = {
  fr: [
    {
      quote: 'Vous êtes un champion du service client 💪🏼',
      author: 'Oscar Wilde',
    },
    {
      quote: 'Vous êtes un as de la relation client  🤩',
      author: 'Frank Zappa',
    },
    {
      quote:
        'Vous êtes sur le point de résoudre à merveille le problème du client 💯',
      author: 'Marcus Tullius Cicero',
    },
    {
      quote: 'Encore un client satisfait grâce à vous  🎉',
      author: 'Mae West',
    },
    {
      quote: 'Voici une belle occasion de rendre le client heureux 😉',
      author: 'Mahatma Gandhi',
    },
    {
      quote: 'En résolvant le problème du client vous devenez son héros 🦸‍♂️',
      author: 'Mark Twain',
    },
    {
      quote:
        'Chaque contact avec le client est un moyen de le rendre heureux 🎯',
      author: 'Oscar Wilde',
    },
    {
      quote:
        'Un client qui arrive énervé peut repartir heureux grâce à vous 💯',
      author: 'Frank Zappa',
    },
    {
      quote:
        "Le saviez-vous : 9h37 ! C'est le record du monde de l'appel de relation client qui a duré le plus longtemps. Il est détenu par l'entreprise américaine Zappos 🤓",
      author: 'Marcus Tullius Cicero',
    },
    {
      quote:
        "Mayday vous aide à résoudre les tickets à la vitesse de l'éclair ⚡️",
      author: 'Mae West',
    },
    {
      quote:
        "Plus qu'une seconde pour connaître les process comme votre poche  ⏳",
      author: 'Mahatma Gandhi',
    },
    {
      quote:
        "N'hésitez pas à nous faire remonter toutes vos idées pour améliorer Mayday 📢",
      author: 'Mark Twain',
    },
    {
      quote: 'Mayday vous accompagne pour traiter tous les tickets 💬',
      author: 'Frank Zappa',
    },
    {
      quote: 'Pas un ticket sans Mayday 🚀',
      author: 'Marcus Tullius Cicero',
    },
    {
      quote:
        'Si vous ne trouvez pas le processus adapté dans mayday n\'hésitez pas à le dire à vos managers grâce à la fonctionnalité de "feedbacks" 💬',
      author: 'Mae West',
    },
  ],
  en: [
    {
      quote: 'You are a customer care hero 💪🏼',
      author: 'Oscar Wilde',
    },
  ],
};

export default function (lang = 'fr') {
  const quotes = QUOTES[lang];
  return quotes[Math.floor(Math.random() * Math.floor(quotes.length))];
}
