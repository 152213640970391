<template>
  <div class="side-option" @click="$emit('click')" :style="dynamicStyle">
    <el-tooltip
      v-if="tooltip"
      :content="description"
      placement="right"
      class="group-tooltip"
    >
      <template>
        <slot name="icon" :_class="'side-option-icon'"></slot>
      </template>
    </el-tooltip>
    <template v-else>
      <slot name="icon" :_class="'side-option-icon'"></slot>
    </template>
  </div>
</template>

<script>
export default {
  name: 'side-bar-item',
  props: {
    description: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'diagnostic',
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    dynamicStyle() {
      return {
        '--type-color': this.type === 'article' ? '#fcbf49' : '#0081f9',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.side-option {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 2px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  background-color: white;
  padding: 16px;
  &:hover {
    box-shadow: 0px 10px 30px $shadow-4-mayday;
  }
}

.side-option:hover .side-option-icon {
  color: var(--type-color);
}

.side-option-icon {
  width: 100%;
  height: 100%;
  color: $grey-5-mayday;
}
.side-option:active,
.side-option:focus,
.side-option,
.side-option:active:focus {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.active {
  color: var(--type-color);
}
</style>
