import { v4 as uuidv4 } from 'uuid';
import flattenObject from '../../utils/flattenObject';

const paramHierarchy = {
  id: 1,
  label: 'Root',
  value: 'root',
  options: [
    {
      id: 2,
      label: 'Référence article',
      value: 'codic',
      fullPath: 'parameters.codic',
      field: 'remote-search',
      icon: 'barcode-scan',
      hasLabel: false,
      filterOnly: true,
    },
    {
      id: 3,
      label: 'Univers produit',
      value: 'knowledge',
      field: 'checkbox',
      icon: 'books',
      hasLabel: true,
      options: [
        {
          id: 5,
          label: 'Famille',
          value: 'family',
          field: 'multi',
          icon: 'conveyor-belt',
          hasLabel: true,
          options: [
            {
              id: 6,
              label: 'Marque',
              value: 'brand',
              fullPath: 'parameters.brand',
              field: 'multi',
              icon: 'copyright',
              hasLabel: true,
              options: [
                {
                  id: 7,
                  label: 'Référence article',
                  value: 'codic',
                  fullPath: 'parameters.codic',
                  field: 'remote-search',
                  icon: 'barcode-scan',
                  hasLabel: false,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 4,
      label: 'Chassis',
      value: 'facets',
      field: 'remote-search',
      icon: 'industry-alt',
      hasLabel: false,
    },
  ],
};

export default {
  computed: {
    mxHierarchy() {
      return paramHierarchy;
    },
    mxFlatHierarchy() {
      return flattenObject(paramHierarchy, 'options');
    },
  },
  methods: {
    /**
     * Enriches raw product parameters
     * @param {[JSON]} parameters object of type { key, values }
     */
    async mxEnrichRawParameters(parameters) {
      const enrichedParamsWithLabel = await this.enrichParamsWithLabels(
        parameters,
      );
      const enrichedParamsNoLabels = await this.enrichParamsNoLabels(
        parameters,
      );
      return enrichedParamsWithLabel.concat(enrichedParamsNoLabels);
    },
    async enrichParamsWithLabels(parameters) {
      const paramsToEnrich = this.mxFlatHierarchy.filter((h) => h.hasLabel);
      return this.$services.hierarchies.getByValues(
        parameters.reduce((acc, val) => {
          if (paramsToEnrich.map((p) => p.value).includes(val.key)) {
            acc = acc.concat(val.values);
          }
          return acc;
        }, []),
      );
    },
    async enrichParamsNoLabels(parameters) {
      const paramsNoLabel = this.mxFlatHierarchy.filter((h) => !h.hasLabel);
      const parametersNoLabel = parameters.filter((p) =>
        paramsNoLabel.map((el) => el.value).includes(p.key),
      );
      if (parametersNoLabel.length) {
        return parametersNoLabel.reduce((arr, val) => {
          const enrichedValues = val.values.map((v) => ({
            id: uuidv4(),
            label: v,
            value: v,
          }));
          return arr.concat(enrichedValues);
        }, []);
      }
      return [];
    },
  },
};
