<template>
  <div class="welcome-modal">
    <modal :show.sync="localDisplay" modal-classes="modal-lg">
      <div class="row" :class="content.class">
        <div class="col-6">
          <h5 class="title text-uppercase">{{ content.title }}</h5>
          <h1 class="display-5 text-darker my-4 main-text">
            {{ content.highlight }}
          </h1>
          <h5 class="text-uppercase list-title text-center my-4">
            {{ content.listTitle }}
          </h5>
          <ul>
            <li
              v-for="listItem in content.listItems"
              :key="listItem.id"
              class="mb-2"
            >
              {{ listItem }}
            </li>
          </ul>
        </div>
        <div class="col-6 d-flex align-items-center justify-content-center">
          <img
            class="my-auto"
            v-if="focusedModal == 1"
            src="~assets/party-rounded.svg"
          />
          <img
            class="my-auto"
            v-if="focusedModal == 2"
            src="~assets/random-rounded.svg"
          />
          <img
            class="my-auto"
            v-if="focusedModal == 3"
            src="~assets/lightening-rounded.svg"
          />
          <img
            class="my-auto"
            v-if="focusedModal == 4"
            src="~assets/chat-rounded.svg"
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'welcome-modal',
  props: {
    display: Boolean,
    focusedModal: Number,
  },
  computed: {
    content() {
      return this.contents[this.focusedModal];
    },
  },
  data() {
    return {
      localDisplay: this.display,
      contents: {
        1: {
          class: 'first',
          title: 'Bienvenue sur Mayday 🎉',
          highlight:
            'Mayday est la base de connaissances intelligente qui vous délivre la bonne information au bon moment',
          listTitle: 'Comment mayday va vous aider ?',
          listItems: {
            item1: `Mayday s'intègre directement dans votre espace de travail et vous donne accès à l'ensemble de la connaissance et des processus du service client 📺`,
            item2:
              'Fini les longs moments de mise en attente pour trouver la réponse adaptée et la marche à suivre pour résoudre chaque problème ☎️',
          },
        },
        2: {
          class: 'second',
          title: 'Les contenus sur Mayday',
          highlight:
            'Mayday vous offre deux manières de consulter la connaissance : les articles et les diagnostics',
          listTitle: 'Comment exploiter les différents formats ?',
          listItems: {
            item1:
              'Les diagnostics sont des arbres de décisions qui vous guident pas à pas à travers des processus complexes de résolution des problèmes clients. Les diagnostics vous apportent aussi les bons éléments de réponse à communiquer au client 🌲',
            item2: `Les articles vous apportent toute la connaissance sur un sujet 📃`,
          },
        },
        3: {
          class: 'third',
          title: 'La navigation dans Mayday',
          highlight: `Mayday simplifie la manière de trouver les informations grâce à une navigation simple et un outil de recherche express`,
          listTitle: 'Comment naviguer dans Mayday ?',
          listItems: {
            item1:
              'En utilisant Mayday vous trouverez les articles et le diagnostics au sein des différentes collections. 🖱',
            item2: `L'outil de recherche express vous permet de taper un mot clé et de trouver l'article ou le diagnostic concerné. Ne vous inquiétez pas si vous tapez un mot clé rapidement, il corrige les fautes d'orthographe 😉`,
          },
        },
        4: {
          class: 'fourth',
          title: 'Les outils de communication',
          highlight: `Mayday est un outil collaboratif qui vous permet de recevoir des notifications des managers et de leur envoyer vos commentaires`,
          listTitle: 'Comment collaborer avec Mayday ?',
          listItems: {
            item1: `Mayday vous permet de recevoir des notifications lorsqu'il y a un changement dans un processus ou une mise à jour de document 🔔`,
            item2: `Lorsque vous remarquez une erreur, un changement, ou lorsque vous souhaitez faire remonter une information précieuse à un superviseur ou manager, utilisez l'outil de feedback 📣`,
          },
        },
      },
    };
  },
  created() {
    window.addEventListener('unload', function () {
      this.localDisplay = false;
    });
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.main-text {
  padding-left: 10px;
}

.first {
  :deep() .main-text {
    border-left: 3px solid $blue-mayday;
  }
}

.first {
  :deep() .title {
    color: $blue-mayday;
  }
}

.first {
  :deep() .list-title {
    background-color: rgba($blue-mayday, 0.2);
    padding: 3px;
    border-radius: 2px;
  }
}

.second {
  :deep() .main-text {
    border-left: 3px solid $yellow-mayday;
  }
}

.second {
  :deep() .title {
    color: $yellow-mayday !important;
  }
}

.second {
  :deep() .list-title {
    background-color: rgba($red-mayday, 0.2);
    padding: 3px;
    border-radius: 2px;
  }
}

.third {
  :deep() .main-text {
    border-left: 3px solid $red-mayday;
  }
}

.third {
  :deep() .title {
    color: $red-mayday !important;
  }
}

.third {
  :deep() .list-title {
    background-color: rgba($red-mayday, 0.2);
    padding: 3px;
    border-radius: 2px;
  }
}

.fourth {
  :deep() .main-text {
    border-left: 3px solid $green-mayday;
  }

  :deep() .title {
    color: $green-mayday !important;
  }
}

.fourth {
  :deep() .fourth {
    :deep() .list-title {
      background-color: rgba($green-mayday, 0.4);
      padding: 3px;
      border-radius: 2px;
    }
  }
}
</style>
