<template>
  <el-popover placement="right" trigger="click">
    <!-- TRIGGER -->
    <user-avatar
      slot="reference"
      class="trigger-avatar d-flex justify-content-center align-items-center"
      :username="username"
      :avatar="avatar"
    ></user-avatar>

    <!-- DROPDOWN -->
    <div class="d-flex align-items-center">
      <user-avatar
        :username="username"
        :avatar="avatar"
        size="small"
      ></user-avatar>
      <span class="ml-2">{{ username && username.toUpperCase() }}</span>
    </div>

    <el-divider class="dropdown-divider" />

    <div v-if="userAccess.settings">
      <router-link
        v-for="item in items"
        :key="item.url"
        class="integrate-link-popover d-flex align-items-center"
        :to="item.url"
      >
        <div class="item-icon">
          <font-awesome-icon :icon="item.icon" class="icon-light" />
        </div>

        <div class="item-name">{{ $t(item.name) }}</div>
      </router-link>
    </div>

    <div
      v-if="displaySwitchLanguage"
      class="integrate-link-popover d-flex align-items-center"
      @click="$emit('update-language', otherLanguage)"
    >
      <div class="item-icon">
        <font-awesome-icon :icon="['fal', 'language']" class="icon-light" />
      </div>
      <div class="item-name">
        {{ $t('dashboard-layout.dropdown.switch-language') }}
      </div>
    </div>

    <el-divider class="dropdown-divider" />

    <div @click.capture.prevent="$emit('logout')">
      <router-link
        class="integrate-link-popover_big d-flex align-items-center"
        to="/logout"
      >
        <div class="item-icon">
          <font-awesome-icon :icon="['fal', 'power-off']" class="icon-light" />
        </div>
        <div class="item-big-name">
          {{ $t('dashboard-layout.dropdown.logout') }}
        </div>
      </router-link>
    </div>
  </el-popover>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import UserAvatar from '@/components/UserAvatar';

export default {
  name: 'user-dropdown',
  components: {
    UserAvatar,
  },
  props: {
    userAccess: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      items: [
        {
          name: 'dashboard-layout.dropdown.general-settings',
          url: '/settings/general#knowledges',
          icon: ['fal', 'sliders-v-square'],
        },
        {
          name: 'dashboard-layout.dropdown.company-integrations',
          url: '/settings/integrations#catalog',
          icon: ['fal', 'plug'],
        },
        {
          name: 'dashboard-layout.dropdown.company-permissions',
          url: '/settings/users#users',
          icon: ['fal', 'unlock-alt'],
        },
        {
          name: 'dashboard-layout.dropdown.company-settings',
          url: '/settings/company#company',
          icon: ['fal', 'cog'],
        },
      ],
    };
  },
  computed: {
    otherLanguage() {
      return this.appLanguage === 'fr' ? 'en' : 'fr';
    },
    displaySwitchLanguage() {
      return !this.hasCompanyPreferenceWithValue('EXTENDED_I18N');
    },
    ...mapState(['appLanguage']),
    ...mapGetters(['username', 'avatar', 'hasCompanyPreferenceWithValue']),
  },
};
</script>

<style lang="scss" scoped>
.dropdown-divider {
  margin: 8px 0 !important;
}

.trigger-avatar {
  margin: 0 auto;
  cursor: pointer;
}

.integrate-link-popover {
  padding: 4px 2px;
  width: 100%;
  color: $grey-9-mayday;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
  .item-icon {
    display: flex;
    justify-content: center;
    width: 16px;
    margin-right: 4px;
  }
  &_big {
    padding: 4px 2px;
    width: 100%;
    color: $grey-9-mayday;
    font-size: 16px;
    &:hover {
      color: $blue-mayday;
    }
    .item-icon {
      display: flex;
      justify-content: center;
      width: 24px;
      margin-right: 4px;
    }
  }
}
</style>
