<template>
  <div class="card">
    <div class="my-2">
      <div
        v-for="group in paginatedData[currentPage - 1]"
        :key="group.id"
        class="row"
      >
        <user-groups-card
          :group="group"
          class="group-card col-11"
          @update-user-group="updateUserGroup"
        ></user-groups-card>
        <user-groups-actions
          class="col-1 cursor"
          :row="group"
          @update-user-group="updateUserGroup"
          @trigger-delete-modal="triggerDeleteModal"
        />
      </div>
      <el-pagination
        class="d-flex justify-content-center mt-4"
        background
        layout="prev, pager, next"
        :total="groups.length"
        :current-page.sync="currentPage"
        :page-size="selectedRowsCount"
        hide-on-single-page
      ></el-pagination>
    </div>
    <delete-modal
      v-if="deleteModal"
      type="userGroup"
      :display="deleteModal"
      @choice="deleteUserGroup"
      @close="deleteModal = false"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { DropdownMenu, DropdownItem, Dropdown } from 'element-ui';
import DeleteModal from 'components/Modals/DeleteModal';
import UserGroupsCard from './UserGroupsCard';
import UserGroupsActions from './Actions/UserGroupsActions';

export default {
  name: 'user-groups-table',
  props: {
    groups: Array,
  },
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    DeleteModal,
    UserGroupsCard,
    UserGroupsActions,
  },
  computed: {
    paginatedData() {
      const dataTmp = [
        ...this.groups
          .map((o) => ({ ...o }))
          .sort((a, b) => String(a.name).localeCompare(String(b.name))),
      ];
      return this.chunkArray(dataTmp, this.selectedRowsCount);
    },
    ...mapGetters(['isMultiLingual']),
  },
  data() {
    return {
      switches: {
        off: false,
        on: true,
      },
      selectedRowsCount: 5,
      currentPage: 1,
      test: null,
      deleteModal: false,
      warningModal: false,
      focusGroupId: null,
      tableHeader: [
        {
          label: this.$t(
            'settings.permissions.user-groups-table.table-header-labels.group-name',
          ),
          prop: 'name',
        },
        {
          label: `# ${this.$t(
            'settings.permissions.user-groups-table.table-header-labels.members',
          )}`,
          prop: 'usersCount',
        },
        {
          label: this.$t(
            'settings.permissions.user-groups-table.table-header-labels.languages',
          ),
          prop: 'knowledges',
          template: true,
        },
        {
          label: this.$t(
            'settings.permissions.user-groups-table.table-header-labels.feedback',
          ),
          prop: 'reachable',
          template: true,
        },
        {
          label: this.$t(
            'settings.permissions.user-groups-table.table-header-labels.actions',
          ),
          prop: 'actions',
          template: true,
        },
      ],
      fullRowsToExportCsv: [
        { label: 'name' },
        { label: 'usersCount' },
        { label: 'knowledges' },
        { label: 'reachable' },
      ],
      options: {
        search: false,
        rowsCount: false,
        download: false,
        pagination: true,
      },
    };
  },
  created() {
    this.currentPage = 1;
  },
  methods: {
    // PAGINATION METHODS
    chunkArray(array, size) {
      const chunked_arr = [];
      let index = 0;
      while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
      }
      return chunked_arr;
    },
    triggerDeleteModal(id) {
      this.focusGroupId = id;
      this.deleteModal = true;
    },
    updateUserGroup({ group, step = 1 }) {
      if (!group.users) group.users = [];
      this.$emit('update-user-group', { group, step });
    },
    deleteUserGroup(choice) {
      if (choice) {
        this.$emit('delete-user-group', { id: this.focusGroupId });
        this.focusGroupId = null;
      }
      this.deleteModal = false;
    },
    nameConfiguration(name) {
      return `Les membres du groupe ${name.toUpperCase()}, auront accès à :`;
    },
    reachableConfiguration(reachable) {
      return reachable
        ? `Et ils pourront recevoir les feedbacks des agents`
        : `Et ils ne pourront pas recevoir les feedbacks des agents`;
    },
  },
};
</script>
<style scoped>
.options_selection {
  max-width: 20px;
  cursor: pointer;
}

.center-element {
  justify-content: center;
  vertical-align: middle;
  display: flex;
}

.dropdown {
  padding: 0px;
  border-width: 0px;
  box-shadow: none;
  background-color: transparent;
  margin-right: 0px;
  margin: 5px;
}

.dropdown:hover {
  background-color: white;
}

.dropdown-item span {
  margin-left: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.col-1 {
  width: 400px;
}
th {
  font-weight: bold;
}

tr {
  align-items: baseline;
}

td {
  color: #525f7f;
  font-size: 13px !important;
}

.table-header {
  background-color: white;
  align-items: baseline;
  height: 40px;
  text-transform: uppercase;
  font-size: 0.65rem;
}

.header-col {
  color: #8898aa;
}

.no-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.group-table {
  max-height: 250px;
}
</style>
