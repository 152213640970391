<template>
  <settings-cta
    :title="`${pre}.title`"
    :description="`${pre}.description`"
    :cta="`${pre}.cta`"
    @click="$emit('click:cta')"
  ></settings-cta>
</template>

<script>
import SettingsCta from '@/components/Settings/SettingsCta';

const i18nPrefix = 'settings-layout.menu-data.authentication.ip-restriction';

export default {
  name: 'ip-restriction-settings',
  components: {
    SettingsCta,
  },
  data() {
    return {
      switchValue: true,
      pre: `${i18nPrefix}`,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
