<template>
  <div class="knowledge-parameters row">
    <div v-loading="!parameters.length" class="col-12">
      <div v-for="o in optionsType" :key="o.id">
        <ParameterRow
          v-if="parameters"
          class="my-2"
          :parameters="parameters"
          :hierarchy="o"
          :update-filter="updateFilter"
          :update-knowledge="updateKnowledge"
          :filters="filters"
        />
      </div>
      <FilteredResults
        v-if="filters.length > 0 && showResults"
        :filters="filters"
        @close="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import ParameterRow from './ParameterRow';
import FilteredResults from './FilteredResults';
import ProductMixin from 'mixins/KnowledgeMixin/ProductMixin';

export default {
  name: 'knowledge-parameters',
  mixins: [ProductMixin],
  components: { ParameterRow, FilteredResults },
  props: {
    showResults: {
      type: Boolean,
      default: true,
    },
    cascadeType: {
      type: String,
      default: 'settings',
    },
  },
  computed: {
    optionsType() {
      return this.cascadeType === 'settings'
        ? this.mxHierarchy.options.filter((o) => !o.filterOnly)
        : this.mxHierarchy.options;
    },
  },
  data() {
    return {
      parameters: [],
      filters: [],
    };
  },
  async created() {
    this.parameters = await this.$services.hierarchies.getHierarchies();
    this.parameters = this.parameters.sort((a, b) =>
      a.label.localeCompare(b.label),
    );
  },
  methods: {
    async updateFilter(newFilter) {
      const hasValues = newFilter.values.length > 0;
      const keyIndex = this.filters.findIndex((f) => f.key === newFilter.key);
      const alreadyExisting = keyIndex !== -1;
      if (alreadyExisting) {
        if (hasValues) {
          this.filters[keyIndex].values = newFilter.values;
        } else {
          if (this.filters.length > 1) {
            this.filters.splice(keyIndex, 1);
          } else {
            this.filters = [];
          }
        }
      } else {
        if (hasValues) {
          this.filters.push(newFilter);
        }
      }
      this.$emit('update-product-filters', this.filters);
    },
    updateKnowledge(knowledge) {
      this.$emit('update-knowledge', knowledge[0]);
    },
  },
};
</script>

<style></style>
