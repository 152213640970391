<template>
  <el-popover placement="right" class="my-auto" v-model="localDisplay">
    <div>
      <div class="dropdown-item px-0">
        <h5
          class="text-overflow m-0 d-flex align-items-center"
          @click="updateUserGroup"
        >
          <font-awesome-icon :icon="['fal', 'pen']" class="medium-icon ml-2" />
          <span class="action-label ml-2">{{
            $t('settings.permissions.user-groups-actions.edit')
          }}</span>
        </h5>
      </div>
      <el-tooltip
        :content="$t('settings.permissions.user-groups-actions.delete-tooltip')"
        :disabled="canDelete"
      >
        <div
          class="dropdown-item px-0"
          :class="{ 'disabled-button': !canDelete }"
        >
          <h5
            class="text-overflow m-0 d-flex align-items-center"
            :class="{ 'disabled-button': !canDelete }"
            @click="canDelete ? triggerDeleteModal() : ''"
          >
            <font-awesome-icon
              :icon="['fal', 'trash']"
              class="medium-icon ml-2 text-red"
            />
            <span
              class="action-label ml-2"
              :class="{ 'disabled-text': !canDelete }"
              >{{ $t('settings.permissions.user-groups-actions.delete') }}</span
            >
          </h5>
        </div>
      </el-tooltip>
    </div>
    <div class="col-12 side-option" slot="reference" id="add-group-popover">
      <el-tooltip
        :content="$t('settings.permissions.user-groups-actions.actions')"
        placement="right"
        class="group-tooltip"
      >
        <font-awesome-icon :icon="['fal', 'ellipsis-v']" class="option-icon" />
      </el-tooltip>
    </div>
  </el-popover>
</template>
<script>
export default {
  name: 'user-groups-actions',
  props: {
    row: Object,
  },
  data() {
    return {
      localDisplay: false,
    };
  },
  computed: {
    canDelete() {
      return !this.row.usersCount && !this.row.hasPendingUsers;
    },
  },
  methods: {
    updateUserGroup() {
      this.localDisplay = false;
      this.$emit('update-user-group', { group: this.row });
    },
    triggerDeleteModal() {
      this.localDisplay = false;
      this.$emit('trigger-delete-modal', this.row.id);
    },
  },
};
</script>
<style lang="scss" scoped>
/* FA ICONS */

.option-icon {
  height: 30px;
  width: 30px;
  color: #ced4da;
}

.medium-icon {
  width: 16px;
  height: 16px;
  padding: 2px;
  color: #4f4f4f;
}

/* END FA ICONS */

.empty-placeholder {
  color: #ced4da;
}

.options_selection {
  max-width: 20px;
  cursor: pointer;
}

.center-element {
  justify-content: center;
  vertical-align: middle;
  display: flex;
}

.dropdown {
  padding: 0px;
  border-width: 0px;
  box-shadow: none;
  background-color: transparent;
  margin-right: 0px;
  margin: 5px;
}

.dropdown:hover {
  background-color: white;
}

.dropdown-item {
  &.disabled-button {
    color: $grey-6-mayday;
    cursor: not-allowed;
  }
  &:hover:not(.disabled-button) {
    cursor: pointer;
    background-color: rgba(0, 129, 249, 0.05);
  }
}

.action-label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
}

.disabled-text {
  color: $grey-6-mayday;
}

.col-1 {
  width: 400px;
}
th {
  font-weight: bold;
}

tr {
  align-items: baseline;
}

td {
  color: #525f7f;
  font-size: 13px !important;
}

.table-header {
  background-color: white;
  align-items: baseline;
  height: 40px;
  text-transform: uppercase;
  font-size: 0.65rem;
}

.header-col {
  color: #8898aa;
}

.no-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
