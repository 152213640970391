<template>
  <div>
    <RoleDrawerSimpleSwitch
      :sub-item="subItem"
      :show-divider="!localIsOpened"
      :is-opened="localIsOpened"
      @switch-change="$emit('switch-change', $event)"
    />
    <div v-if="localIsOpened" :class="{ divider: localIsOpened }">
      <div :class="['scope-main-container', { error: hasErrors }]">
        <div
          class="actions-container"
          v-if="subItem.relatedPermissions.length > 1"
        >
          <div class="text-container">
            <h5>
              {{ $t('settings.permissions.role-drawer.scope.actions') }}
            </h5>
            <p class="description">
              {{
                $t('settings.permissions.role-drawer.scope.actions-description')
              }}
            </p>
          </div>
          <el-checkbox-group
            v-model="checkedPermissions"
            class="checkboxes-container"
            @change="handleCheckboxChange"
          >
            <el-checkbox
              v-for="relatedPermission in relatedPermissions"
              :key="relatedPermission"
              :label="relatedPermission"
              size="mini"
              class="checkbox-button"
              >{{ computeTranslation(relatedPermission) }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="scope-container">
          <RoleDrawerSimpleSwitch
            class="scope-switch"
            :sub-item="{
              switch: 'settings.permissions.role-drawer.scope.title',
              description: 'settings.permissions.role-drawer.scope.description',
            }"
            :show-divider="false"
            :is-opened="localIsScoped || showScope"
            @switch-change="handleSwitchScope"
          />
          <ContentViewSettingsCallout
            v-if="subItem.warning && (localIsScoped || showScope)"
            :trad="subItem.warning"
            :icon="'exclamation-triangle'"
            type="warning"
          />
          <ContentViewSettingsCallout
            v-if="hasErrors"
            :trad="subItem.error"
            :icon="'exclamation-triangle'"
            type="error"
          />
          <!-- RoleDrawerKnowledgeViewer | RoleDrawerGroupViewer -->
          <component
            :is="scopedComponent"
            v-if="localIsScoped || showScope"
            :scope="scope"
            @update-scope="handleUpdateScope"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoleDrawerSimpleSwitch from './RoleDrawerSimpleSwitch';
import RoleDrawerKnowledgeViewer from './RoleDrawerKnowledgeViewer';
import RoleDrawerGroupViewer from './RoleDrawerGroupViewer';
import RoleDrawerHubViewsViewer from './RoleDrawerHubViewsViewer';
import ContentViewSettingsCallout from '@/components/Drawers/ContentViewSettingDrawer/ContentViewSettingsComponents/ContentViewSettingsCallout.vue';

export default {
  name: 'role-drawer-scoped-switch',
  components: {
    RoleDrawerSimpleSwitch,
    RoleDrawerKnowledgeViewer,
    RoleDrawerGroupViewer,
    RoleDrawerHubViewsViewer,
    ContentViewSettingsCallout,
  },
  props: {
    rolePermissions: {
      type: Array,
      default: () => [],
    },
    tabName: {
      type: String,
      default: '',
    },
    subItem: {
      type: Object,
      required: true,
    },
    isOpened: {
      type: Boolean,
      default: false,
    },
    isScoped: {
      type: Boolean,
      default: false,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.checkedPermissions = this.rolePermissions
      .filter((permission) =>
        this.relatedPermissions.includes(permission.rolePermission),
      )
      .map((permission) => permission.rolePermission);
    this.localIsOpened = this.isOpened;
    this.localIsScoped = this.isScoped;
  },
  data() {
    return {
      localIsOpened: false,
      localIsScoped: false,
      showScope: false,
      checkedPermissions: [],
    };
  },
  methods: {
    computeTranslation(name) {
      const actionType = name.split('_')[0].toLowerCase();
      return this.$t(`settings.permissions.role-drawer.scope.${actionType}`);
    },
    handleCheckboxChange(e) {
      this.$emit('checkbox-change', {
        permissions: e,
        subItemPermissions: this.subItem.relatedPermissions,
        scope: this.scope,
        isScopeToggled: this.localIsScoped,
      });
    },
    handleUpdateScope(newScope) {
      this.$emit('switch-change', {
        permissions: this.checkedPermissions,
        value: true,
        scope: newScope,
        isScopeToggled: this.localIsScoped,
      });
    },
    handleSwitchScope({ value }) {
      this.localIsScoped = value;
      this.showScope = value;
      this.handleUpdateScope({});
    },
  },
  computed: {
    relatedPermissions() {
      return this.subItem.relatedPermissions;
    },
    scope() {
      const rolePermission = this.rolePermissions.find((permission) =>
        this.relatedPermissions.includes(permission.rolePermission),
      );
      return rolePermission ? rolePermission.scope : {};
    },
    scopedComponent() {
      const type = this.subItem.scopedType || 'Knowledge';
      return `RoleDrawer${type}Viewer`;
    },
  },
  watch: {
    isOpened() {
      this.localIsOpened = this.isOpened;
    },
    isScoped() {
      this.localIsScoped = this.isScoped;
      this.showScope = this.isScoped;
      this.handleUpdateScope(this.scope);
    },
    rolePermissions() {
      this.checkedPermissions = this.rolePermissions
        .filter((permission) =>
          this.relatedPermissions.includes(permission.rolePermission),
        )
        .map((permission) => permission.rolePermission);
    },
  },
};
</script>

<style lang="scss" scoped>
.scope-switch {
  padding-top: 8px;

  & :deep() h4 {
    font-size: 13px;
  }
}
.scope-main-container {
  padding: 8px;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  &.error {
    border: 1px solid $red-mayday;
  }
}

.actions-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $grey-4-mayday;
}

.divider {
  padding-bottom: 8px;
  border-bottom: 1px solid $grey-4-mayday;
}
.checkbox {
  display: flex;
  width: 70px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
label {
  font-size: 14px;
  margin-bottom: 0;
}
.checkboxes-container {
  display: flex;
  width: 100%;
  max-width: 270px;
  justify-content: space-between;
  align-items: flex-start;
}
.checkbox-button {
  display: flex;
  width: 70px;
  flex-direction: column-reverse;
  align-items: center;
  gap: 4px;
  margin: 0px;
  :deep() .el-checkbox__label {
    color: black;
    padding: 0px;
  }
  &.is-checked {
    :deep() .el-checkbox__inner {
      border-radius: 2px;
      background-color: $blue-mayday;
      box-sizing: border-box;
      box-shadow: 0 0 0 2px #f3f5f6 inset;
      border: 1px solid $blue-mayday;
      &:after {
        display: none;
      }
    }
  }
}
</style>
