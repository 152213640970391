<template>
  <div class="main-container">
    <div class="d-flex justify-content-between align-items-center p-2">
      <div class="title-container" @click="isOpen = !isOpen">
        <button :class="{ opened: isOpen }" class="open-button">
          <font-awesome-icon
            :icon="['fal', 'chevron-right']"
            class="chevron-icon"
          />
        </button>
        <div>
          {{ title }}
        </div>
        <div v-if="filterCount" class="filter-count">
          <div>{{ filterCount }}</div>
        </div>
      </div>
      <el-popover v-model="isPopoverVisible" trigger="click">
        <button slot="reference" class="open-button">
          <font-awesome-icon
            :icon="['fal', 'ellipsis-v']"
            class="popover-icon"
          />
        </button>
        <div slot="default">
          <div class="filter-option" @click="handleOptionClick">
            <font-awesome-icon :icon="['fal', 'trash']" class="button-icon" />
            <div>
              {{
                $t(
                  'settings.general.collaborative-hub.drawer.content.delete-filter',
                )
              }}
            </div>
          </div>
        </div>
      </el-popover>
    </div>
    <div :class="{ opened: isOpen }" class="dropdown-container">
      <HubCategoryFilterList
        v-if="options"
        :options="options"
        :selected="filter.values"
        :translation-path="translationPath"
        @update-filter="$emit('update-list-filter', $event)"
      />
      <HubCategoryFilterSearch
        v-else
        :category="category"
        :operator="filter.operator"
        :filters="filter"
        @update-filter="$emit('update-filter', $event)"
        @update-category="$emit('update-category', $event)"
        @remove-filter="$emit('remove-filter', $event)"
      />
    </div>
  </div>
</template>
<script>
import HubCategoryFilterList from 'views/Settings/GeneralSettings/HubSettings/HubCategoryFilterList.vue';
import HubCategoryFilterSearch from 'views/Settings/GeneralSettings/HubSettings/HubCategoryFilterSearch.vue';

export default {
  name: 'HubCategoryDropdown',
  components: { HubCategoryFilterSearch, HubCategoryFilterList },
  props: {
    title: String,
    translationPath: String,
    filter: Object,
    options: Array,
    category: String,
  },
  data() {
    return {
      isOpen: false,
      isPopoverVisible: false,
    };
  },
  methods: {
    handleOptionClick() {
      this.isPopoverVisible = false;
      this.$emit('remove-category');
    },
  },
  computed: {
    filterCount() {
      if (this.options) return this.filter.values.length;
      return Object.keys(this.filter).reduce((acc, key) => {
        if (key !== 'operator') acc++;
        return acc;
      }, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid $grey-4-mayday;
  border-radius: 8px;
  overflow: hidden;
}
.title-container {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.dropdown-container {
  transition: all 0.3s ease;
  padding: 0 8px;
  max-height: 0;
  border-top: 0 solid $grey-4-mayday;
  &.opened {
    border-top: 1px solid $grey-4-mayday;
    max-height: 500px;
    padding: 8px 8px;
    overflow: auto;
  }
}
.chevron-icon {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  transition: transform 0.3s;
}
.popover-icon {
  width: 20px;
  height: 20px;
}
.open-button {
  padding: 0;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: $grey-1-mayday;
  }
  &.opened {
    .chevron-icon {
      transform: rotate(90deg);
    }
  }
}

.filter-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px;
  &:hover {
    background-color: #f5f5f5;
    color: $blue-5-mayday;
  }
  :first-child {
    margin-right: 4px;
    height: 16px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.filter-count {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  margin-left: 4px;

  div {
    background-color: $blue-5-mayday;
    color: white;
    border-radius: 7px;
    height: 14px;
    margin-top: 2px;
    font-size: 10px;
    min-width: 14px;
    line-height: 13px;
    font-weight: bold;
  }
}
</style>
