<template>
  <div class="collections-navigator">
    <!-- BREADCRUMB -->
    <CollectionsNavigatorBreadcrumb
      :parents="parents"
      @click:home="$emit('click:home')"
      @click:parent="$emit('click:parent', $event)"
    />
    <!-- NAVIGATION -->
    <section
      class="navigation-section collection-navigation py-2"
      v-if="collections.length"
    >
      <h4 class="section-title">NAVIGATION</h4>
      <div class="collection-item-wrapper" v-loading="isCollectionLoading">
        <div
          v-for="collection in collections"
          :key="collection.id"
          class="collection-item py-1 px-2"
          @click="$emit('click:child', collection)"
        >
          <font-awesome-icon
            class="collection-icon mr-2"
            :icon="['fa', getIconValue(collection.icon)]"
            :style="getIconStyle(collection.icon)"
          />
          {{ collection.label }}
        </div>
      </div>
    </section>

    <!-- FILTERED SOLUTIONS -->
    <section class="navigation-section solution-navigation py-2">
      <h4 class="section-title">SOLUTIONS FILTREES | {{ solutionsCount }}</h4>
      <SolutionsSearchBar
        class="mb-4"
        :resolution-search="resolutionSearch"
        :is-loading="isSolutionLoading"
        @input="$emit('search-input', $event)"
        @keyup:enter="$emit('search-keyup:enter')"
      />
      <SearchResolutionsList
        v-loading="isSolutionLoading"
        :solutions="solutions"
        :solutions-count="solutionsCount"
        :more-options="moreOptions"
        @focus-content="$emit('focus-content', $event)"
        @click:more="$emit('click:more')"
        @click:less="$emit('click:less')"
      />
    </section>
  </div>
</template>

<script>
import CollectionsNavigatorBreadcrumb from './CollectionsNavigatorBreadcrumb';
import SolutionsSearchBar from '../SolutionsSearchBar';
import SearchResolutionsList from '../SearchResolutionsList';

export default {
  name: 'collections-navigator',
  components: {
    CollectionsNavigatorBreadcrumb,
    SolutionsSearchBar,
    SearchResolutionsList,
  },
  props: {
    parents: Array,
    collections: Array,
    solutions: {
      type: Array,
      required: true,
    },
    solutionsCount: {
      type: Number,
      required: true,
    },
    moreOptions: {
      type: Boolean,
    },
    isSolutionLoading: {
      type: Boolean,
      default: false,
    },
    isCollectionLoading: {
      type: Boolean,
      default: false,
    },
    resolutionSearch: {
      type: String,
      default: '',
    },
  },
  methods: {
    getIconValue(icon) {
      return icon && icon.value ? icon.value : 'folder';
    },
    getIconStyle(icon) {
      return icon && icon.color ? { color: icon.color } : {};
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-section {
  .section-title {
    color: $grey-7-mayday;
  }
}

.collection-navigation {
  .collection-item {
    cursor: pointer;
    &:hover {
      background-color: $grey-1-mayday;
    }
    .collection-icon {
      color: $grey-4-mayday;
    }
  }
}
</style>
