<template>
  <div :class="`${base}__header`">
    <font-awesome-icon
      v-if="icon"
      :class="[`${base}__header__icon`, 'mr-2']"
      :icon="['fal', icon]"
    />
    <span :class="[`${base}__header__title`, 'mr-2']">{{ title }}</span>
    <el-tooltip :content="info" placement="top">
      <font-awesome-icon
        v-if="info"
        :icon="['fal', 'info-circle']"
        :class="`${base}__header__info`"
      />
    </el-tooltip>
  </div>
</template>
<script>
export default {
  name: 'workflow-section-title',
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    info: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      base: Object.freeze('workflow-section-title'),
    };
  },
};
</script>
<style lang="scss" scoped>
.workflow-section-title {
  &__header {
    &__title {
      font-weight: bold;
      color: black;
    }
  }
}
</style>
