<template>
  <div class="template" @click="goToTemplate()">
    <div class="template-info">
      <div class="template-icon">
        <IconWrapper :icon="icons[template.content.type]" :size="24" />
      </div>
      <div class="template-label">
        <div class="name">
          {{ template.content.label }}
        </div>
        <TextOverflowTooltip
          class="description"
          :area="true"
          :text="
            template.description ||
            $t('knowledge.templates.import.empty-description')
          "
        />
      </div>
    </div>
    <div class="ml-auto d-flex items-center">
      <TranslationTooltip class="" :is-translated="isTranslated" is-content />
      <div class="template-delete" @click.stop="$emit('delete-template')">
        <font-awesome-icon :icon="['fal', 'trash']" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import articleIcon from '@/assets/article-icon-v2.1.svg';
import diagIcon from '@/assets/diag-icon-v2.1.svg';
import IconWrapper from '@/components/IconWrapper';
import TextOverflowTooltip from '@/components/Commons/TextOverflowTooltip.vue';
import TranslationTooltip from '@/components/Commons/TranslationTooltip.vue';

export default {
  name: 'templates-list-item',
  components: {
    IconWrapper,
    TextOverflowTooltip,
    TranslationTooltip,
  },
  props: {
    template: Object,
  },
  data() {
    return {
      icons: {
        Diagnostic: {
          iconType: 'url',
          value: diagIcon,
          color: null,
        },

        Article: {
          iconType: 'url',
          value: articleIcon,
          color: null,
        },
      },
    };
  },
  computed: {
    isTranslated() {
      return this.template.content.returnLanguage === this.navigationLanguage;
    },
    ...mapGetters('knowledgeModule', ['navigationLanguage']),
  },
  methods: {
    goToTemplate() {
      return this.$router.push(`templates/${this.template.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.template-disabled {
  opacity: 0.8;
  cursor: not-allowed;

  :deep() .add-template {
    pointer-events: none;
  }
}

.template {
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  background-color: transparent;
  padding: 4px 0px;
  &:hover {
    background-color: $grey-1-mayday;
    border-radius: 4px;
  }

  .template-info {
    display: flex;
    min-width: 0;
    .template-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .template-label {
      min-width: 0;
      .name {
        width: 100%;
        font-size: 14px;
      }

      .description {
        font-size: 12px;
        color: $grey-6-mayday;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .template-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    margin-right: 8px;
    cursor: pointer;
    height: 24px;
    width: 24px;
    &:hover {
      background-color: $grey-2-mayday;
      border-radius: 4px;
    }
    font-size: 14px;
  }
}
</style>
