<template>
  <div>
    <div class="settings-label d-flex align-items-center pt-2 mb-1">
      <div class="title">
        {{ title }}
      </div>
      <el-tooltip v-if="tooltip" :content="tooltip" placement="top">
        <font-awesome-icon
          :icon="['fad', 'info-circle']"
          class="info-icon mx-2"
        />
      </el-tooltip>
    </div>
    <div class="divider"></div>
  </div>
</template>

<script>
export default {
  name: 'drawer-row-title',
  inheritAttrs: false,
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: false,
    },
  },
  created() {},
  computed: {},
  data() {
    return {};
  },
  validations: {},
  methods: {},
};
</script>

<style lang="scss">
.settings-label {
  margin-top: 8px;
  .title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
  }
}
.divider {
  border-bottom: 1px solid $grey-4-mayday;
  margin-bottom: 8px;
}
.info-icon {
  color: $grey-5-mayday;
  font-size: 12px;
}
</style>
