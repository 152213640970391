<template>
  <div
    class="thread__message"
    :class="{
      'thread__message-response': !isFirst,
      'thread__message-focus': isFocused,
    }"
    :id="`message-${id}`"
  >
    <!-- HEADER -->
    <div class="thread__message-header">
      <!-- AUTHOR -->
      <ThreadAuthor :author="author" :date="displayDate" :edited="edited" />
      <!-- OPTIONS -->
      <div
        v-if="options.edit && canEdit && (!isEditing || isDeleting)"
        class="thread__options"
      >
        <!-- START EDIT -->
        <button @click="initEditMessage" class="thread__action">
          <font-awesome-icon :icon="['fal', 'pencil']" />
        </button>
        <!-- START DELETE -->
        <button
          v-if="options.delete && !deletingMessage"
          @click="initDeleteMessage"
          class="thread__action"
        >
          <font-awesome-icon :icon="['fal', 'trash']" />
        </button>
      </div>

      <!-- EDIT -->
      <div v-if="isEditing" class="thread__edit">
        <AddAttachment
          :attachment-loading="attachmentLoading"
          class="thread__action"
          :disabled="fileInputDisable"
          :class="{
            disabled: attachmentLoading || fileInputDisable,
          }"
          @disable-click="
            showDisableFileUpload(
              'task-view.task-focus-modal.upload-error.disable-file-upload',
            )
          "
          @new-attachment="handleFileChange($event), (attachmentsEdited = true)"
        >
          <font-awesome-icon slot="slot-button" :icon="['fal', 'paperclip']" />
        </AddAttachment>
        <!-- CONFIRM EDIT -->
        <button
          @click="handleEditMessage"
          :disabled="disabled"
          class="thread__action"
          :class="{ disabled: disabled }"
        >
          <font-awesome-icon :icon="['fal', 'check']" />
        </button>
        <!-- CANCEL EDIT -->
        <button @click="cancelEditMessage" class="thread__action">
          <font-awesome-icon :icon="['fal', 'times']" />
        </button>
      </div>
    </div>

    <!-- BODY -->
    <div
      class="thread__message-content"
      :class="{
        'thread__message-content-response': !isFirst,
        'thread__message-content-last': !options.edit && isLast,
      }"
    >
      <div v-if="body || isEditing" class="thread__body">
        <MessageEditor
          :key="isEditing ? `${id}-edit` : `${id}-read`"
          :body="localBody"
          :editable="isEditing"
          :autofocus="isEditing"
          @input="localBody = $event"
        />
      </div>
      <AttachmentList
        class="thread__attachments-list"
        :is-input="isEditing"
        :attachments="attachments"
        :attachment-width="'calc(33.3333% - 28px)'"
        :display-disable-file-upload="displayDisableFileUpload"
        :error-text="errorText"
        @delete-attachment="
          handleDeleteAttachment($event), (attachmentsEdited = true)
        "
        @disable-click="showDisableFileUpload()"
      />
      <!-- VOTES -->
      <el-tooltip
        :disabled="!votes.length"
        :content="userVotes"
        popper-class="small-tooltip"
      >
        <button
          v-if="options.vote || (!options.vote && votes.length)"
          @click="handleVoteMessage"
          class="thread__message-votes"
          :class="[
            options.vote
              ? 'thread__message-votes-active'
              : 'thread__message-votes-disabled',
            {
              'thread__message-votes-me': votes.includes(userId),
              'thread__message-votes-empty': !votes.length,
              'thread__message-votes-deleted': author.isDeleted,
            },
          ]"
        >
          <font-awesome-icon :icon="['fal', 'thumbs-up']" />
          <span v-if="votes.length">{{ votes.length }}</span>
        </button>
      </el-tooltip>
    </div>

    <!-- DELETE POPUP -->
    <div v-if="isDeleting" class="thread__message-delete">
      <div v-if="ongoingDelete" class="thread__message-delete-loader">
        <font-awesome-icon :icon="['fal', 'spinner-third']" spin />
      </div>
      <div v-else class="thread__message-delete-info">
        <div class="thread__message-delete-confirm">
          <font-awesome-icon :icon="['fal', 'check']" />
          <span>{{ $t('threads.messages.delete.confirm') }}</span>
        </div>
        <el-button type="primary" size="mini" @click="cancelDeleteMessage">
          {{ $t('threads.messages.delete.cancel') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import MessageEditor from '@/components/Editors/MessageEditor.vue';
import ThreadAuthor from '@/components/Threads/ThreadAuthor.vue';
import { mapState } from 'vuex';
import AddAttachment from '@/components/Attachments/AddAttachment';
import AttachmentList from '@/components/Attachments/AttachmentList';
import AttachmentsMixin from '@/mixins/AttachmentsMixin';
import resolveFilesNames from '@/utils/resolveFilesNames';

export default {
  name: 'thread-message',
  components: {
    MessageEditor,
    ThreadAuthor,
    AddAttachment,
    AttachmentList,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      default: '',
    },
    votes: {
      type: Array,
      required: true,
    },
    mentions: {
      type: Array,
      required: true,
    },
    messageAttachments: {
      type: Array,
      required: true,
    },
    authorId: {
      type: String,
      required: true,
    },
    createdAt: {
      type: String,
      required: true,
    },
    updatedAt: {
      type: String,
      required: true,
    },
    edited: {
      type: Boolean,
      required: true,
    },
    editedDate: {
      type: String,
      default: null,
    },
    contributors: {
      type: Object,
      default: () => ({}),
    },
    editingMessage: {
      type: String,
      default: null,
    },
    deletingMessage: {
      type: String,
      default: null,
    },
    options: {
      type: Object,
      default: () => ({
        delete: true,
        edit: true,
        vote: true,
      }),
    },
    length: {
      type: Number,
      default: 0,
    },
    isFocused: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localBody: this.body,
      ongoingDelete: false,
      attachmentsEdited: false,
      timeout: null,
    };
  },
  created() {
    this.attachments = this.messageAttachments;
  },
  mixins: [AttachmentsMixin()],
  computed: {
    disabled() {
      return (
        (!this.bodyEdited && !this.attachmentsEdited) ||
        (!this.localBody && !this.attachments.length) ||
        this.attachmentLoading
      );
    },
    bodyEdited() {
      return this.body !== this.localBody;
    },
    canEdit() {
      return this.userId === this.authorId;
    },
    isFirst() {
      return this.index === 0;
    },
    isLast() {
      return this.index === this.length - 1;
    },
    author() {
      return this.contributors[this.authorId];
    },
    displayDate() {
      return this.edited ? this.editedDate : this.createdAt;
    },
    isEditing() {
      return this.editingMessage === this.id;
    },
    isDeleting() {
      return this.deletingMessage === this.id;
    },
    userVotes() {
      return this.votes
        .map((id) =>
          this.contributors[id] ? this.contributors[id].username : null,
        )
        .join('\n');
    },
    ...mapState(['userId']),
  },
  methods: {
    // EDIT
    initEditMessage() {
      this.$emit('init-edit', this.id);
    },
    cancelEditMessage() {
      this.$emit('cancel-edit');
      this.localBody = this.body;
      this.attachmentsEdited = false;
    },
    handleEditMessage() {
      if (this.disabled) return;
      const payload = {
        body: this.localBody,
        attachments: this.attachments.map((at) => at.url),
      };
      this.$emit('confirm-edit', payload);
      this.localBody = this.body;
      this.attachmentsEdited = false;
    },
    // DELETE
    initDeleteMessage() {
      this.$emit('init-delete', this.id);
      this.ongoingDelete = true;

      window.setTimeout(() => {
        this.ongoingDelete = false;
      }, 1000);
    },
    cancelDeleteMessage() {
      this.$emit('cancel-delete');
      this.ongoingDelete = false;
      clearTimeout(this.timeout);
    },
    handleDeleteMessage() {
      this.$emit('confirm-delete');
      this.ongoingDelete = false;
    },
    // VOTE
    handleVoteMessage() {
      if (!this.options.vote || this.author.isDeleted) return;
      this.$emit('vote', this.id);
    },
  },
  watch: {
    isEditing(newValue) {
      if (newValue) {
        this.attachments = resolveFilesNames(this.messageAttachments);
      } else {
        this.attachments = this.messageAttachments;
      }
    },
    body(newValue) {
      this.localBody = newValue;
    },
    ongoingDelete(newValue) {
      if (!newValue && this.isDeleting) {
        this.timeout = setTimeout(() => {
          this.handleDeleteMessage();
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.thread__message {
  animation: fade-in 250ms ease-in-out;
  display: flex;
  flex-direction: column;
  position: relative;

  &-focus {
    animation: blink 1000ms linear;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
  }
  &-content {
    padding-left: 26px;
    position: relative;
    &-response::before {
      content: '';
      height: 100%;
      width: 1px;
      background-color: $grey-4-mayday;
      position: absolute;
      left: 10px;
    }
    &-last::before {
      background-color: transparent !important;
    }
  }
  &-response {
    padding-left: 26px;
  }

  &-votes {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 12px;
    line-height: 1;
    height: 12px;
    padding: 0;
    color: $grey-8-mayday;
    margin-bottom: 8px;

    &-active:hover {
      color: $blue-mayday;
    }

    &-me {
      color: $blue-mayday;
    }

    &-disabled {
      margin-bottom: 4px !important;
    }

    &-empty {
      color: $grey-6-mayday;
    }

    &-deleted:hover {
      color: $grey-8-mayday;
      cursor: not-allowed;
    }
  }

  &-delete {
    position: absolute;
    inset: 0;
    background-color: $grey-1-mayday;
    color: $red-mayday;
    animation: fade-in 250ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    &-loader {
      font-size: 24px;
    }
    &-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: fade-in 250ms ease-in-out;
    }
    &-confirm {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      font-weight: 700;
      color: black;
      margin-bottom: 8px;
    }
  }
}

.thread__body {
  padding: 8px 0;
  font-size: 12px;
  line-height: 14px;
  color: black;
}

.thread__message:hover .thread__options {
  opacity: 1;
}

.thread__options {
  display: flex;
  gap: 4px;
  align-items: center;
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.thread__edit {
  display: flex;
  gap: 4px;
  align-items: center;
  transition: opacity 250ms ease-in-out;
}

.thread__action {
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $grey-8-mayday;
  height: 24px;
  width: 24px;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 250ms ease-in-out;

  &:hover {
    background-color: $grey-3-mayday !important;
  }
  &:active {
    background-color: $grey-4-mayday !important;
  }
}

.disabled {
  cursor: not-allowed;
  background-color: $grey-2-mayday !important;
  color: $grey-7-mayday;
  opacity: 0.8;
  &:hover {
    background-color: $grey-2-mayday !important;
  }
  &:active {
    background-color: $grey-2-mayday !important;
  }
}

.thread__attachments-list {
  margin-top: -4px;
  margin-bottom: 4px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    background-color: transparent;
  }
  30% {
    background-color: $grey-2-mayday;
  }
  100% {
    background-color: transparent;
  }
}
</style>
