<template>
  <div class="p-3 rounded border">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center gap-2 w-100">
        <div
          class="flex-shrink-0 border rounded p-2 w-12 h-12 d-flex align-items-center justify-content-center"
        >
          <font-awesome-icon :icon="['fal', icon]" class="text-xl text-muted" />
        </div>
        <div class="d-flex flex-column w-100">
          <slot name="content"></slot>
        </div>
      </div>
      <div class="flex-column">
        <div class="px-3 cursor-pointer" @click="toggleCollapse">
          <font-awesome-icon
            class="collapse-icon flex-shrink-0 transition-transform duration-300"
            :class="{ 'rotate-90': !isCollapsed }"
            :icon="['fal', 'chevron-right']"
          />
        </div>
        <slot name="under-collapse-icon"></slot>
      </div>
    </div>
    <div class="collapse-content-wrapper" :class="{ opened: !isCollapsed }">
      <hr class="my-3" />
      <slot name="collapse-content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    initCollapseStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCollapsed: this.initCollapseStatus,
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
  watch: {
    initCollapseStatus(newVal) {
      this.isCollapsed = newVal;
    },
  },
};
</script>

<style scoped>
.collapse-icon {
  transition: transform 0.3s ease;
}

.rotate-90 {
  transform: rotate(90deg);
}

.collapse-content-wrapper {
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: 0;

  &.opened {
    max-height: 500px;
    overflow: auto;
  }
}
</style>
