<template>
  <div class="custom-modal">
    <modal :show.sync="localDisplay">
      <!-- HEADER -->
      <div class="modal-header">
        <div class="title">
          {{
            $t(
              'public-knowledge.appearances.items.code-custom.custom-css.modal.title',
            )
          }}
        </div>
        <div class="divider"></div>
        <div class="description">
          {{
            $t(
              'public-knowledge.appearances.items.code-custom.custom-css.modal.description',
            )
          }}
        </div>
      </div>

      <ContentViewSettingsCallout
        v-if="callout"
        :trad="callout"
        icon="lightbulb"
        type="info"
      />
      <!-- TEMPLATE -->
      <div v-if="templateUrl">
        <a class="download-link" :href="templateUrl" target="_blank">
          <font-awesome-icon :icon="['fas', 'download']" />
          {{
            $t(
              'public-knowledge.appearances.items.code-custom.custom-css.template',
            )
          }}
        </a>
      </div>

      <el-divider></el-divider>

      <!-- DROPZONE -->
      <el-form enctype="multipart/form-data" v-if="upload" v-loading="loading">
        <el-upload
          class="my-3 upload-demo"
          drag
          action=""
          :auto-upload="false"
          :show-file-list="false"
          :accept="accept"
          :on-change="setFiles"
        >
          <img src="~assets/empty-contents.svg" width="100px" />
          <div class="el-upload__text">
            {{ $t('knowledge.modals.contribution.drop-file') }}
            <em>{{ $t('knowledge.modals.contribution.upload') }}</em>
          </div>
        </el-upload>
      </el-form>

      <!-- DOWNLOAD FILE -->
      <div v-else class="d-flex mb-3">
        <!-- SELECTED FILE -->
        <div v-if="!fileUrl" :class="{ error: error }">
          {{ file ? file.name : null }}
        </div>
        <div v-else>
          <a class="download-link" :href="fileUrl" target="_blank">
            <font-awesome-icon :icon="['fas', 'download']" />
            {{
              $t(
                'public-knowledge.appearances.items.code-custom.custom-css.download',
              )
            }}
          </a>
        </div>

        <div class="ml-2 icon-wrapper" @click="deleteUrl">
          <font-awesome-icon :icon="['fas', 'trash']" />
        </div>
      </div>

      <div class="d-flex justify-content-end mb-3">
        <el-button plain type="primary" size="small" @click="$emit('close')">
          {{
            $t(
              'public-knowledge.appearances.items.code-custom.custom-css.cancel',
            )
          }}
        </el-button>
        <el-button
          v-if="!fileUrl && file"
          type="primary"
          size="small"
          @click="uploadFile"
          :loading="loading"
        >
          {{
            $t(
              'public-knowledge.appearances.items.code-custom.custom-css.confirm',
            )
          }}
        </el-button>
      </div>
    </modal>
  </div>
</template>

<script>
import ContentViewSettingsCallout from '@/components/Drawers/ContentViewSettingDrawer/ContentViewSettingsComponents/ContentViewSettingsCallout';
import { mapActions } from 'vuex';

const inputFileReader = async (file) => {
  return new Promise((res) => {
    let reader = new FileReader();
    reader.onload = function () {
      res(reader.result);
    };
    reader.readAsText(file);
  });
};

export default {
  name: 'upload-files-modal',
  components: {
    ContentViewSettingsCallout,
  },
  data() {
    return {
      file: null,
      localDisplay: this.display,
      upload: !this.url,
      fileUrl: this.url,
      loading: false,
      error: false,
    };
  },
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '*',
    },
    url: {
      type: String,
      default: '',
    },
    templateUrl: {
      type: String,
      default: '',
    },
    callout: {
      type: String,
      default: '',
    },
    fileName: {
      type: String,
      default: 'mayday-file',
    },
  },
  computed: {},
  methods: {
    async setFiles(fileData) {
      this.file = fileData.raw;
      this.upload = false;
    },
    async uploadFile() {
      if (!this.file) {
        this.upload = false;
        return;
      }
      this.loading = true;
      let readInputFile = await inputFileReader(this.file);

      const res = await this.uploadPublicFile({
        fileName: this.fileName,
        fileData: readInputFile,
      });

      if (res) {
        this.fileUrl = res;
        this.error = false;
        this.$emit('uploaded-file', res);
        return this.$emit('close');
      } else {
        this.error = true;
        this.$message({
          message: 'Ce fichier contient des erreurs',
          type: 'error',
        });
      }

      this.loading = false;
    },
    deleteUrl() {
      this.file = null;
      this.fileUrl = '';
      this.upload = true;
      this.error = false;
      this.$emit('uploaded-file', '');
    },
    ...mapActions('publicKnowledgeModule', ['uploadPublicFile']),
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-modal {
  :deep() .modal-body {
    padding-bottom: 0px;
  }
}

.upload-demo {
  :deep() .el-upload {
    width: 100%;
  }

  :deep() .el-upload-dragger {
    width: 100%;
    height: 150px;
    padding: 16px;
  }
}

.modal-header {
  padding: 12px;
  background-color: $grey-1-mayday;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  text-align: center;

  .title {
    font-size: 16px;
    font-weight: bold;
  }
  .divider {
    content: '';
    height: 1px;
    width: 80%;
    background-color: $grey-4-mayday;
    margin-bottom: 0px;
  }
  .description {
    font-size: 12px;
    color: $grey-7-mayday;
  }
}

.error {
  color: $red-mayday;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $grey-7-mayday;
  font-size: 14px;
  &:hover {
    background-color: $grey-1-mayday;
    color: $red-mayday;
  }
}

.download-link {
  font-size: 14px;
  color: $blue-mayday;
  &:hover {
    text-decoration: underline;
  }
}

:deep() .el-divider--horizontal {
  margin: 8px 0;
}
</style>
