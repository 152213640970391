<template>
  <div class="analytics-content-container">
    <header class="header">
      <AnalyticsTitle
        :title="$t('new-dashboard.header.title.analytics-views')"
        :tooltip-content="
          $t('new-dashboard.header.tooltip.analytics-views', {
            prefix: i18nPrefix,
            filterType,
          })
        "
      />
      <AnalyticsContentsViewsControls
        :filter-type.sync="filterTypeModel"
        :graph-type.sync="graphTypeModel"
      />
    </header>
    <div class="analytics-content-graph" v-loading="isLoading">
      <component
        v-if="!isLoading && !isEmpty"
        :is="chartComponent"
        :height="250"
        :chart-data="chartData"
        :extra-options="chartOptions"
      ></component>
      <EmptyState
        v-if="isEmpty"
        :external-doc-url="externalDocUrl"
        :link-anchor-name="externalDocAnchor"
        class="empty-state-container"
        @open-filters-drawer="() => $emit('open-filters-drawer')"
      />
    </div>
  </div>
</template>

<script>
import AnalyticsTitle from '../AnalyticsTitle';
import AnalyticsContentsViewsControls from './AnalyticsContentsViewsControls';
import EmptyState from '../EmptyState';
import { EXTERNAL_DOC_ANCHORS } from '@/store/dashboard/constants';

export default {
  name: 'analytics-contents-views',

  props: {
    graphType: {
      type: String,
      required: true,
    },
    filterType: {
      type: String,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    isPie: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isEmpty: {
      type: Boolean,
      required: true,
    },
    externalDocUrl: {
      type: String,
      required: true,
    },
  },

  components: {
    AnalyticsTitle,
    AnalyticsContentsViewsControls,
    EmptyState,
    // prettier-ignore
    'DoughnutChart': () => import('@/components/Charts/DoughnutChart'),
    // prettier-ignore
    'LineChart': () => import('@/components/Charts/LineChart')
  },

  data() {
    return {
      chartOptionsBase: {
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'month',
              },
            },
          ],
        },
      },
      externalDocAnchor: EXTERNAL_DOC_ANCHORS.contentChart,
    };
  },

  computed: {
    i18nPrefix() {
      return this.$t(`new-dashboard.header.tooltip.prefix.${this.graphType}`);
    },

    chartOptions() {
      const base = this.chartOptionsBase;

      return this.isPie
        ? Object.assign({}, base, {
            scales: {
              yAxes: [
                {
                  display: false,
                },
              ],
            },
          })
        : base;
    },

    chartComponent() {
      return this.isPie ? 'DoughnutChart' : 'LineChart';
    },

    filterTypeModel: {
      get() {
        return this.filterType;
      },
      set(value) {
        this.handleUpdate('filterType', value);
        return value;
      },
    },

    graphTypeModel: {
      get() {
        return this.graphType;
      },
      set(value) {
        this.handleUpdate('graphType', value);
        return value;
      },
    },
  },

  methods: {
    handleUpdate(propName, $event) {
      this.$emit(`update:${propName}`, $event);
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-content-container {
  .header {
    display: flex;
    justify-content: space-between;
  }

  .analytics-content-graph {
    height: 250px;
    position: relative;
  }

  .empty-state-container {
    text-align: center;
  }
}
</style>
