<template>
  <section class="collapse-section">
    <div
      class="col-12 d-flex product-viewer-collapsable justify-content-between pr-md-0 my-2 align-items-center"
    >
      <p
        class="text-uppercase section-title"
        @click="productNotificationsItemsShow = !productNotificationsItemsShow"
      >
        <font-awesome-icon class="mr-1" :icon="['fad', 'bells']" />
        {{ $t('knowledge.product.alerts') }}
      </p>
      <div>
        <el-tooltip
          v-if="!isMaydayWeb"
          :content="$t('knowledge.modals.notifications.tooltip')"
          placement="top"
          class="group-tooltip"
        >
          <button
            class="product__button"
            @click.prevent="sendOpenCreateProductNotificationModalEvent"
          >
            <font-awesome-icon
              class="trigger-alert-modal"
              :icon="['fad', 'plus-circle']"
            />
          </button>
        </el-tooltip>
        <button
          :class="[
            'product__button',
            productNotificationsItemsShow ? null : 'product__button--collapsed',
          ]"
          :aria-expanded="productNotificationsItemsShow ? 'true' : 'false'"
          aria-controls="productAlertsItems"
          @click="
            productNotificationsItemsShow = !productNotificationsItemsShow
          "
        >
          <i
            :class="[
              'product__toogle-icon',
              'fa',
              productNotificationsItemsShow
                ? 'fa-caret-square-down'
                : 'fa-caret-square-right',
            ]"
          ></i>
        </button>
      </div>
    </div>
    <b-collapse
      v-if="notifications"
      v-model="productNotificationsItemsShow"
      id="productAlertsItems"
    >
      <p v-if="!notifications.length" class="ml-3 empty-state-alert">
        Il n'y a pas d'alertes sur ce produit
      </p>
      <ul
        id="product__alerts__items collapse"
        :class="[
          'product__list px-2',
          productNotificationsItemsShow ? 'show' : '',
        ]"
      >
        <li
          v-for="notification in notifications"
          :key="notification.id"
          :class="[
            'product__alerts__item',
            notification.urgent ? 'product__alerts__item--urgent' : '',
          ]"
        >
          <base-alert
            ref="alert"
            :type="notification.urgent ? 'danger' : 'warning'"
            class="text-left mb-2 d-flex align-items-between product-alert"
            :icon="
              notification.urgent ? 'fa fa-exclamation-circle' : 'fa fa-bell'
            "
          >
            <template v-slot:text>
              <div
                class="d-flex inner-text-alert justify-content-between align-items-center"
              >
                <div class="alert-body">
                  <strong>{{ notification.title }}</strong>
                  <span
                    class="product__alerts__item__details mb-0"
                    v-html="sanitize(notification.description)"
                  ></span>
                </div>
                <el-tooltip placement="top" effect="dark">
                  <template #content>
                    <slot name="tooltip-content">
                      <span class="tooltip-icon">{{
                        $t('knowledge.modals.notifications.delete')
                      }}</span>
                    </slot>
                  </template>
                  <font-awesome-icon
                    class="modal-menu-icon"
                    :icon="['fad', 'trash']"
                    @click="sendDeleteNotificationEvent(notification.id)"
                  />
                </el-tooltip>
              </div>
            </template>
          </base-alert>
        </li>
      </ul>
    </b-collapse>
  </section>
</template>

<script>
import BaseAlert from '@/components/BaseAlert.vue';
import { sanitize } from '@/plugins/dompurify';
export default {
  name: 'product-notifications',
  props: {
    notifications: {
      type: Array,
    },
    isMaydayWeb: Boolean,
  },
  components: {
    BaseAlert,
  },
  data() {
    return {
      productNotificationsItemsShow: true,
      sanitize: sanitize,
    };
  },
  methods: {
    sendDeleteNotificationEvent(notificationId) {
      this.$emit('delete-notification', notificationId);
    },
    sendOpenCreateProductNotificationModalEvent() {
      this.$emit('open-create-product-notification-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-state-alert {
  color: $grey-5-mayday;
}

.product-alert {
  :deep() .alert-inner--text {
    width: 100%;
  }
}

.product__alerts__item__details {
  :deep() * {
    margin-bottom: 0px;
  }
}

.section-title {
  color: $grey-6-mayday;
  cursor: pointer;
  margin-bottom: 0px;
}

.trigger-alert-modal {
  color: $grey-5-mayday;
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}

.tooltip-icon {
  cursor: pointer;
}

.modal-menu-icon {
  color: $grey-5-mayday;
  height: 12px;
  visibility: hidden;
  cursor: pointer;
  &:hover {
    color: $red-mayday;
  }
}

.product-alert {
  &:hover {
    .modal-menu-icon {
      visibility: visible;
    }
  }
}

.product__list {
  border-left: 2px solid $grey-4-mayday;
  margin-left: 1.5rem;
  padding-left: 10px !important;
  list-style-type: none;
}

.product__button {
  background-color: transparent;
  border: none;
  color: $blue-mayday;
}

.product__button--collapsed {
  color: $grey-5-mayday;
}
</style>
