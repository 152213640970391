import { mapActions } from 'vuex';

export default () => {
  return {
    data() {
      return {
        attachments: [],
        attachmentLoading: false,
        errorText: '',
        displayDisableFileUpload: false,
      };
    },
    computed: {
      fileInputDisable() {
        return this.attachments.length >= 3;
      },
    },
    methods: {
      fileSize(sizeInBytes) {
        const sizeInKo = Math.trunc(sizeInBytes / 1000);
        if (sizeInKo < 1000) return `${sizeInKo} Ko`;
        return `${Math.trunc(sizeInKo / 1000)} Mo`;
      },
      handleDeleteAttachment(id) {
        this.attachments = this.attachments.filter(
          (attachment) => attachment.id !== id,
        );
        if (!this.attachments.length) this.attachmentLoading = false;
      },
      showDisableFileUpload(errorText) {
        this.displayDisableFileUpload = true;
        if (errorText) this.errorText = errorText;

        setTimeout(() => {
          this.displayDisableFileUpload = false;
        }, 5000);
      },
      async handleFileChange(e) {
        const previousAttachmentsLength = this.attachments.length;
        if (!e.target.files.length) return;
        if (e.target.files.length + previousAttachmentsLength > 3) {
          this.showDisableFileUpload(
            'task-view.task-focus-modal.upload-error.too-many-files',
          );
          return;
        }
        this.attachmentLoading = true;
        const filesAsArray = Array.from(e.target.files).reduce((acc, file) => {
          file.name;
          if (
            this.attachments.findIndex((at) => at.name === file.name) > -1) {
            this.showDisableFileUpload(
              'task-view.task-focus-modal.upload-error.existing-file',
            );
          } else {
            acc.push(file);
          }
          return acc;
        }, []);

        const filesToUpload = filesAsArray.map((file, index) => {
          return {
            name: file.name,
            size: this.fileSize(file.size),
            id: String(previousAttachmentsLength + index),
            loading: true,
            error: false,
          };
        });
        this.attachments.push(...filesToUpload);

        await Promise.all(
          filesAsArray.map(async (file, index) => {
            let currentIndexInAttachments = previousAttachmentsLength + index;
            try {
              const uploadedFile = await this.uploadFiles([file]);
              if (!uploadedFile[0]) {
                this.attachments[currentIndexInAttachments].loading = false;
                this.attachments[currentIndexInAttachments].error = true;
                this.showDisableFileUpload(
                  'task-view.task-focus-modal.upload-error.upload-problem',
                );
              } else {
                this.attachments[currentIndexInAttachments].loading = false;
                this.attachments[currentIndexInAttachments].id =
                  uploadedFile[0]._id;
                this.attachments[currentIndexInAttachments].url =
                  uploadedFile[0].url;
              }
            } catch (e) {
              // 413 error is payload too big
              if (e.response && e.response.status === 413) {
                this.showDisableFileUpload(
                  'task-view.task-focus-modal.upload-error.file-too-big',
                );
                this.attachments.pop();
              } else {
                this.showDisableFileUpload(
                  'task-view.task-focus-modal.upload-error.upload-problem',
                );
                this.attachments[currentIndexInAttachments].error = true;
                this.attachments[currentIndexInAttachments].loading = false;
                this.attachmentLoading = false;
              }
            }
          }),
        );
        this.attachmentLoading = false;
      },
      ...mapActions('attachmentModule', ['uploadFiles']),
    },
  };
};
