<template>
  <div class="position-relative" v-click-outside="handleClickOutside">
    <button
      type="button"
      class="d-flex justify-content-between align-items-center button-cascader"
      @click.prevent="$emit('show', !showCascader)"
    >
      <div class="d-flex justify-content-between align-items-center w-100">
        <div v-if="name" class="grey-8 name">
          <font-awesome-icon class="icon mr-2 grey-5" :icon="icon" />{{ name }}
        </div>
        <div v-else>
          <font-awesome-icon class="icon mr-2 grey-4" :icon="icon" />{{
            placeholder
          }}
        </div>
        <font-awesome-icon
          class="icon flex-end"
          :icon="['fal', 'chevron-down']"
        />
      </div>
    </button>
    <div v-if="showCascader" class="mt-2 w-100 position-absolute z-index">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-button-cascader',
  props: {
    icon: {
      type: Array,
      default: () => {
        return ['fad', 'books'];
      },
    },
    name: String,
    placeholder: {
      type: String,
      default: 'Choisir',
    },
    showCascader: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClickOutside() {
      this.$emit('show', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.button-cascader {
  width: 100%;
  background-color: white;
  padding: 10px 8px;
  border: 1px solid $grey-4-mayday;
  color: $grey-5-mayday;
  border-radius: 4px;
  font-weight: bold;
}
.button-cascader:hover {
  border-color: $grey-5-mayday;
}
.button-cascader:focus {
  border-color: $blue-mayday;
}
.z-index {
  z-index: 10;
}
.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
}
.icon {
  width: 16px;
  height: 16px;
}
.grey-4 {
  color: $grey-4-mayday;
}
.grey-5 {
  color: $grey-5-mayday;
}
.grey-8 {
  color: $grey-8-mayday;
}
</style>
