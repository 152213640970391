var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('draggable',{attrs:{"draggable":".list-item","disabled":_vm.disableDraggable},on:{"end":_vm.handleDraggable},model:{value:(_vm.draggableList),callback:function ($$v) {_vm.draggableList=$$v},expression:"draggableList"}},_vm._l((_vm.draggableList),function(item){return _c('div',{key:`${item.id}-${item.type}`,staticClass:"list-item"},[(item.type)?_c('ContentListItem',{attrs:{"content":item,"selected-contents":_vm.selectedContents,"show-bulk-actions":_vm.showBulkActions},on:{"focus-content":function($event){return _vm.$emit('focus-content', $event)},"archive":_vm.archiveContent,"update-selected-contents":function($event){return _vm.$emit('update-selected-contents', $event)},"update-content":function($event){return _vm.$emit('update-content', $event)},"update-content-verification":function($event){return _vm.$emit('update-content-verification', $event)},"open-clone-to-target-modal":function($event){return _vm.$emit('open-clone-contents-to-target-modal', [item])},"duplicate-in-same-location":function($event){return _vm.$emit('duplicate-in-same-location', {
            type: 'content',
            contents: [item],
          })}}}):_c('CaseListItem',{attrs:{"collection":item,"extra-padding":_vm.showBulkActions},on:{"open-case":function($event){return _vm.$emit('focus-child', $event)},"delete":function($event){return _vm.$emit('delete-case', $event)},"update-case":function($event){return _vm.$emit('update-case', $event)},"open-clone-to-target-modal":function($event){return _vm.$emit('open-clone-case-to-target-modal', item)},"duplicate-in-same-location":function($event){return _vm.$emit('duplicate-in-same-location', {
            type: 'case',
            case: item,
          })}}})],1)}),0),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }