var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"atg-modal__background"},[_c('div',{staticClass:"atg-modal"},[_c('div',{staticClass:"atg-modal__close",on:{"click":function($event){return _vm.$emit('close')}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'times']}})],1),_c('div',{staticClass:"atg-modal__header"},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'sync-alt']}}),_c('span',[_vm._v(_vm._s(_vm.$t(`${_vm.pre}.title`)))]),_c('AiBadge')],1),_c('div',{staticClass:"atg-modal__body"},[_c('div',[_c('div',{staticClass:"atg-modal__body__title"},[_vm._v(" "+_vm._s(_vm.$t(`${_vm.pre}.text.title`))+" ")]),_c('div',{staticClass:"atg-modal__body__description"},[_vm._v(" "+_vm._s(_vm.$t(`${_vm.pre}.text.description`))+" ")])]),_c('custom-drop-zone',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"col-8 mx-auto",attrs:{"options":_vm.options,"multiple":false},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),(_vm.files && _vm.files.length)?_c('div',{staticClass:"atg-modal__body__infos"},[_c('div',{class:[
            'atg-modal__body__infos__collapse',
            { expanded: _vm.settingsExpanded },
          ]},[_c('div',{staticClass:"atg-modal__body__infos__collapse__header",on:{"click":function($event){_vm.settingsExpanded = !_vm.settingsExpanded}}},[_c('div',[_c('font-awesome-icon',{attrs:{"icon":['fal', 'cog']}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t(`${_vm.pre}.collapse.title`))+" ")])],1),_c('font-awesome-icon',{attrs:{"icon":['fal', 'caret-right']}})],1),_c('div',{class:[
              'atg-modal__body__infos__collapse__body',
              { expanded: _vm.settingsExpanded },
            ]},_vm._l((_vm.settingsOptions),function(option){return _c('div',{key:option.key,staticClass:"atg-modal__body__infos__collapse__body__option"},[_c('div',{staticClass:"atg-modal__body__infos__collapse__body__option__title"},[_c('span',[_vm._v(_vm._s(_vm.$t(`${_vm.pre}.collapse.options.${option.i18n}`)))])]),_c('div',{staticClass:"atg-modal__body__infos__collapse__body__option__input"},[_c('el-switch',{model:{value:(_vm.settings[option.key]),callback:function ($$v) {_vm.$set(_vm.settings, option.key, $$v)},expression:"settings[option.key]"}})],1)])}),0)])]):_vm._e()],1),_c('div',{staticClass:"atg-modal__footer"},[_c('button',{staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t(`${_vm.pre}.cancel`))+" ")]),_c('button',{staticClass:"btn btn-primary btn-sm",attrs:{"disabled":_vm.disabledSubmission},on:{"click":function($event){return _vm.$emit('input', { rawFiles: _vm.files, settings: _vm.settings })}}},[_vm._v(" "+_vm._s(_vm.$t(`${_vm.pre}.create`))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }