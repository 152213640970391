<template>
  <div class="template-item">
    <div
      class="template-item-wrapper d-flex align-items-center"
      :class="{ 'template-focused': isFocused }"
    >
      <div class="mr-2">
        <div class="d-flex align-items-center justify-content-center">
          <img :src="icons[type]" />
        </div>
      </div>
      <div class="template-label flex-grow-1 d-flex align-items-center">
        <div class="mb-0 pr-1">{{ label }}</div>
      </div>
      <!-- TRANSLATION -->
      <TranslationTooltip
        class="d-flex align-items-center"
        :is-translated="isTranslated"
        is-content
      />
    </div>
  </div>
</template>

<script>
import TranslationTooltip from '@/components/Commons/TranslationTooltip';

import articleIcon from '@/assets/article-icon-v2.1.svg';
import diagIcon from '@/assets/diag-icon-v2.1.svg';

export default {
  name: 'templates-navigation-item',
  components: {
    TranslationTooltip,
  },
  props: {
    templateId: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    isTranslated: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isFocused() {
      return this.$route.params.templateId === this.templateId;
    },
  },
  data() {
    return {
      icons: {
        Diagnostic: diagIcon,
        Article: articleIcon,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.template-item {
  width: 100%;
}

.template-item-wrapper {
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
  padding: 2px 4px;
  cursor: pointer;
  &:hover {
    background-color: $grey-2-mayday;
  }
  .template-label {
    min-width: 0;
    div {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.template-focused {
  background-color: white;
}
</style>
