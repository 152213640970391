<template>
  <div class="case-breadcrumb row align-items-center pl-3 pt-2">
    <font-awesome-icon @click="$emit('return-home')" :icon="['fad', 'home']" class="home-icon mr-3" />
    <span
      v-for="(p, idx) in parents"
      :key="p.value"
      class="parent-label"
      :class="[{ 'is-last' : idx === parents.length - 1 }]"
      @click="$emit('appear-parents', idx)"
    >
      <small>{{ p.label }}</small>
      <span v-if="idx !== parents.length - 1" class="px-2">|</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'case-breadcrumb',
  props: {
    parents: Array,
  },
};
</script>

<style lang="scss" scoped>
.parent-label {
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}
.is-last {
  color: $blue-mayday;
}
.not-last {
  border-right: 1px solid black;
}

.home-icon{
  width: 12px;
  cursor: pointer;
  color: $grey-5-mayday;
  &:hover{
    color: $blue-mayday;
  }
}
</style>