<template>
  <div class="markdown-answer">
    <AskMarkdownTokenRenderer
      @md-event="$emit('md-event', $event)"
      v-for="(token, i) in tokens"
      :key="`${id}:${i}`"
      :token="token"
      :hovered="hovered"
      :contexts="contexts"
      :links="links"
    >
    </AskMarkdownTokenRenderer>
  </div>
</template>

<script>
import { marked } from 'marked';
import { v4 as uuid } from 'uuid';
import AskMarkdownTokenRenderer from 'components/Ask/AskMarkdownTokenRenderer.vue';

export default {
  name: 'AskMarkdownRenderer',
  components: { AskMarkdownTokenRenderer },
  props: {
    markdown: {
      type: String,
      default: '',
    },
    hovered: {
      type: String,
      default: '',
    },
    contexts: {
      type: Object,
      default: () => ({}),
    },
    links: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    id: uuid(),
  }),
  computed: {
    tokens() {
      if (this.markdown) {
        marked.use({ breaks: true });
        return marked.lexer(this.markdown);
      }
      return [];
    },
  },
};
</script>

<style scoped lang="scss">
.markdown-answer {
  p {
    font-size: 14px;
  }
}
</style>
