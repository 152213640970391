<template>
  <div class="item-row">
    <div
      class="col-12 d-flex align-items-center"
      :class="extraPadding ? 'case__link__extra-padding' : 'case__ink'"
    >
      <div
        class="d-flex align-items-center max-width"
        @click.prevent.exact="openCase"
        @click.meta.exact="openCase(true)"
        @click.ctrl.exact="openCase(true)"
      >
        <font-awesome-icon :icon="['fad', 'folder']" class="mr-2 case-icon" />
        <ItemLabelEditor
          :label="collection.label"
          :edit="edit_label"
          item-type="case"
          @update-label="updateCollection"
          @close="edit_label = false"
        />
      </div>
      <TranslationTooltip
        class="ml-auto mr-2"
        :is-translated="isTranslated"
        :has-translated-contents="hasTranslatedContents"
      />
      <ItemOptions
        class="option-selection-dropdown"
        :item-id="collection.id"
        :user-permissions="collection.userPermissions"
        item-type="case"
        @edit-label="edit_label = true"
        @delete="softDelete"
        @open-clone-to-target-modal="$emit('open-clone-to-target-modal')"
        @duplicate-in-same-location="$emit('duplicate-in-same-location')"
      />
    </div>
  </div>
</template>

<script>
import ItemLabelEditor from './ItemLabelEditor';
import ItemOptions from './ItemOptions';
import TranslationTooltip from '@/components/Commons/TranslationTooltip';

import { mapGetters } from 'vuex';
export default {
  name: 'case-list-item',
  components: {
    ItemLabelEditor,
    ItemOptions,
    TranslationTooltip,
  },
  props: {
    collection: Object,
    extraPadding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit_label: false,
    };
  },
  computed: {
    childrenOrder() {
      return this.collection ? this.collection.childrenOrder || [] : [];
    },
    isTranslated() {
      return this.collection.returnLanguage === this.navigationLanguage;
    },
    hasTranslatedContents() {
      return this.collection.untranslatedWithTranslatedContents;
    },
    ...mapGetters('knowledgeModule', ['navigationLanguage']),
  },
  methods: {
    softDelete({ softDeleted } = {}) {
      const payload = {
        caseId: this.collection.id,
        softDeleted,
        label: this.collection.label,
      };
      this.$emit('delete', payload);
    },
    openCase(newTab = false) {
      this.$emit('open-case', { ...this.collection , newTab });
    },
    updateCollection(label) {
      const newCollection = {
        caseId: this.collection.id,
        label,
        body: this.collection.body,
        childrenOrder: this.childrenOrder,
      };
      this.$emit('update-case', newCollection);
    },
  },
};
</script>

<style lang="scss" scoped>
.option-selection-dropdown {
  :deep() .dropdown-menu {
    box-shadow: 0px 15px 30px rgba(112, 144, 176, 0.15);
  }
}
.max-width {
  max-width: calc(100% - 60px);
}

.item-row {
  padding: 0px 0px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: $grey-1-mayday;
    cursor: pointer;
    & .product-icon {
      color: $blue-mayday;
    }
  }
  .case-icon {
    height: 16px;
    width: 16px;
  }
}

.case__link {
  padding: 0px 12px 0px 10px;

  &__extra-padding {
    padding: 0px 0px 0px 36px;
  }
}
</style>
