var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thread__dropdown",class:[
    `thread__dropdown-${_vm.type.toLowerCase()}`,
    {
      'thread__dropdown-hidden': !_vm.displayOptions,
      'thread__dropdown-focus': _vm.isFocused,
    },
  ]},[_c('div',{staticClass:"thread__dropdown-first"},[_c('ThreadStatusDropdown',{staticClass:"thread__dropdown-statuses",attrs:{"options":_vm.statusOptions[_vm.type]},on:{"update-status":function($event){return _vm.$emit('update-status', $event)},"dropdown":function($event){_vm.displayOptions = $event}}}),_c('ThreadMessage',_vm._b({attrs:{"message-attachments":_vm.messages[0].attachments,"index":0,"options":_vm.options,"contributors":_vm.contributorsDict}},'ThreadMessage',_vm.messages[0],false)),(_vm.messages.length > 1)?_c('button',{staticClass:"thread__dropdown-display",on:{"click":function($event){_vm.display = !_vm.display}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'comment-alt']}}),_c('span',{staticClass:"thread__dropdown-display-text"},[_vm._v(" "+_vm._s(_vm.$tc('threads.messages.display', _vm.messages.length - 1))+" ")]),_c('font-awesome-icon',{attrs:{"icon":['fal', _vm.display ? 'chevron-up' : 'chevron-down']}})],1):_vm._e()],1),(_vm.display)?_c('div',{staticClass:"thread__dropdown-others",class:{ 'thread__dropdown-others-display': _vm.display }},[_vm._l((_vm.messages.slice(1)),function(message,index){return _c('ThreadMessage',_vm._b({key:message.id,attrs:{"message-attachments":message.attachments,"index":index + 1,"options":_vm.options,"length":_vm.messages.length,"contributors":_vm.contributorsDict}},'ThreadMessage',message,false))}),(_vm.display)?_c('button',{staticClass:"thread__dropdown-display",on:{"click":function($event){_vm.display = false}}},[_vm._v(" "+_vm._s(_vm.$t('threads.messages.hide'))+" ")]):_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }