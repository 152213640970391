<template>
  <div class="max-width-62 position-relative">
    <div class="d-flex tags-wrapper" :class="{ 'flex-row-reverse': reverse }">
      <div
        v-if="tags.length > maxTags"
        class="px-1"
        @click.capture="showMoreTags = !showMoreTags"
      >
        <TagInput
          :tag="shortedTag"
          :show-close="showClose"
          :clickable="clickable"
          :class="{ 'border-bold': showPopover }"
        />
      </div>
      <div class="px-1 flex-none" v-for="tag in inputTags" :key="tag.id">
        <TagInput
          :tag="tag"
          :show-close="showClose"
          :clickable="clickable"
          @delete="$emit('delete', $event)"
        />
      </div>
    </div>
    <div
      class="d-flex tags-popover"
      :class="firstTags.length > 2 ? 'max-width' : 'max-content'"
      v-if="showPopover"
      v-click-outside="handleClickOutside"
    >
      <div class="flex-none tag-wrap" v-for="tag in firstTags" :key="tag.id">
        <TagInput
          :tag="tag"
          :show-close="showClose"
          :clickable="clickable"
          @delete="$emit('delete', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TagInput from './TagInput';

export default {
  name: 'tags-input-wrapper',
  components: {
    TagInput,
  },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    maxTags: {
      type: Number,
      default: 2,
    },
    maxTagsReachDisplay: {
      type: Number,
      default: 1,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showMoreTags: false,
    };
  },
  watch: {
    tags() {
      if (this.tags.length <= this.maxTags) {
        this.showMoreTags = false;
      }
    },
  },
  computed: {
    shortedTag() {
      return {
        icon: ['fad', 'sliders-v'],
        label: `+${this.tags.length - this.maxTagsReachDisplay}`,
      };
    },
    firstTags() {
      return this.tags.slice(0, this.tags.length - this.maxTagsReachDisplay);
    },
    inputTags() {
      if (this.tags.length > this.maxTags) {
        return this.tags.slice(this.firstTags.length, this.tags.length);
      } else {
        return this.tags;
      }
    },
    showPopover() {
      return this.showMoreTags && this.firstTags.length;
    },
  },
  methods: {
    handleClickOutside() {
      this.showMoreTags = !this.showMoreTags;
    },
  },
};
</script>

<style lang="scss" scoped>
.tags-wrapper {
  margin: 0px -4px;
  overflow: hidden;
  max-width: 450px;
}
.tags-popover {
  position: absolute;
  margin-top: 2px;
  padding: 2px 0px;
  background: white;
  border-radius: 4px;
  border: 0.5px solid $grey-4-mayday;
  z-index: 1000;
}
.max-width {
  width: 450px;
  flex-wrap: wrap;
}
.max-width-62 {
  max-width: 62%;
}
.max-content {
  width: max-content;
}
.tag-wrap {
  padding: 2px 4px;
}
.border-bold {
  border: 1.5px solid $grey-8-mayday !important;
}
.flex-none {
  flex: none;
}
.font-bold {
  font-weight: bold;
}
</style>
