import { render, staticRenderFns } from "./SmartActionsList.vue?vue&type=template&id=3504e55f&scoped=true&"
import script from "./SmartActionsList.vue?vue&type=script&lang=js&"
export * from "./SmartActionsList.vue?vue&type=script&lang=js&"
import style0 from "./SmartActionsList.vue?vue&type=style&index=0&id=3504e55f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3504e55f",
  null
  
)

export default component.exports