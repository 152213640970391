var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ask-empty-state"},[(_vm.displaySearchInput)?_c('div',[_c('AskBox',{attrs:{"title":_vm.isTriggered
          ? 'ask.search.current-question'
          : 'ask.search.suggested-question',"icon":['fal', 'comments']},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('AskSuggestedQuestions',{attrs:{"questions":[_vm.searchInput],"clickable":!_vm.isTriggered},on:{"trigger-ask":_vm.triggerAskFromSuggested}})]},proxy:true}],null,false,3392818122)})],1):_c('div',[(_vm.lastQuestion)?_c('AskBox',{staticClass:"mb-3",attrs:{"title":'ask.search.latest-search',"icon":['fal', 'clock']},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('AskSuggestedQuestions',{attrs:{"questions":[_vm.lastQuestion],"clickable":!_vm.isTriggered},on:{"trigger-ask":function($event){return _vm.$emit('latest-search')}}})]},proxy:true}],null,false,1402264555)}):_vm._e(),(
        _vm.filteredTryOutQuestions.length ||
        _vm.tryOutQuestionsLoading ||
        _vm.tryOutQuestionsError
      )?_c('AskBox',{attrs:{"title":'ask.search.try-outs',"icon":['fal', 'lightbulb']},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.tryOutQuestionsLoading)?_c('AskSkeletonLoader',{staticClass:"p-3"}):(_vm.tryOutQuestionsError)?_c('div',{staticClass:"d-flex flex-row align-items-center justify-start gap-2 p-3 source-error-icon"},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'exclamation-triangle']}}),_c('div',{staticClass:"error message"},[_vm._v(" "+_vm._s(_vm.$t('ask.search.try-outs-error'))+" ")])],1):_c('AskSuggestedQuestions',{attrs:{"questions":_vm.filteredTryOutQuestions,"clickable":!_vm.isTriggered},on:{"trigger-ask":_vm.triggerAskFromTryOuts}})]},proxy:true}],null,false,3353297863)}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }