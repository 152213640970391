<template>
  <span class="content-list-item-label">
    <el-tooltip :content="label.labelName" placement="top">
      <font-awesome-icon
        class="content-label-icon mr-1"
        :icon="['fad', label.icon]"
        :style="{ '--label-color': label.color }"
      />
    </el-tooltip>
  </span>
</template>

<script>
export default {
  name: 'content-item-label',
  props: {
    label: Object,
  },
};
</script>

<style lang="scss" scoped>
.content-label-icon {
  max-width: 16px;
  min-width: 16px;
  color: var(--label-color);
  border: 1px solid var(--label-color);
  background-color: rgba(var(--label-color), 0.1);
  border-radius: 2px;
  padding: 2px;
}
</style>