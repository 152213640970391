<template>
  <section class="satisfaction-actions py-6">
    <div class="d-flex align-items-center text-muted">
      <el-divider></el-divider>
      <span class="satisfaction-title px-4">
        {{ $t('knowledge.satisfaction-cta') }}
      </span>
      <el-divider></el-divider>
    </div>
    <div class="col d-flex justify-content-around my-3">
      <div class="col-lg-3 col-md-5 col d-flex justify-content-around">
        <div
          :class="[
            thumb === 'up' ? 'is-success' : '',
            'thumb-button',
            'thumb-up',
          ]"
          @click="addThumbUp"
        >
          <font-awesome-icon :icon="['fad', 'thumbs-up']" class="thumbs-icon" />
        </div>
        <div
          :class="[
            thumb === 'down' ? 'is-danger' : '',
            'thumb-button',
            'thumb-down',
          ]"
          @click="addThumbDown"
        >
          <font-awesome-icon
            :icon="['fad', 'thumbs-down']"
            class="thumbs-icon"
          />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center" v-if="suggestFeedback">
      <a class="satisfaction-feedback" @click="openFeedbackModal">{{
        $t('knowledge.feedback')
      }}</a>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';

export default {
  name: 'satisfaction-actions',
  props: {
    contentId: {
      type: String,
      required: true,
    },
    content: {
      type: Object,
    },
  },
  data() {
    return {
      thumb: '',
      suggestFeedback: false,
      updateContentSatisfactionDebounce: debounce(async (thumbType) => {
        const result =
          await this.$services.parametricContents.addSatisfactionMark({
            contentId: this.contentId,
            thumbType,
            lang: this.navigationLanguage,
          });
        this.storeEvent();
        this.$message({
          message: this.$t('knowledge.satisfaction-notify'),
          type: 'success',
        });
        return result;
      }, 1000),
    };
  },
  mounted() {
    window.addEventListener(
      'beforeunload',
      () => {
        this.flushUpdateSatisfaction();
      },
      false,
    );
  },
  computed: {
    ...mapGetters('webKnowledgeModule', ['navigationLanguage']),
  },
  methods: {
    flushUpdateSatisfaction() {
      this.updateContentSatisfactionDebounce.flush();
    },
    addThumbUp() {
      this.thumb = 'up';
      this.suggestFeedback = true;
      return this.updateContentSatisfactionDebounce(this.thumb);
    },
    addThumbDown() {
      this.thumb = 'down';
      this.suggestFeedback = true;
      return this.updateContentSatisfactionDebounce(this.thumb);
    },
    openFeedbackModal() {
      this.$emit('open-feedback-modal');
    },
    storeEvent() {
      this.$services.events.content.satisfaction(
        this.content,
        this.thumb,
        this.navigationLanguage,
        window.location.href,
        this.$route.path.includes('web-parametric')
          ? 'WEB_PARAMETRIC'
          : 'MAYDAY_WEB',
      );
    },
  },
  watch: {
    $route() {
      this.thumb = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.satisfaction-title {
  font-size: 1em !important;
  font-weight: bold;
  min-width: max-content;
}

.satisfaction-feedback {
  cursor: pointer;
  font-weight: bold;
  font-size: 0.9em;
  color: $grey-5-mayday !important;
}

.satisfaction-feedback:hover {
  text-decoration: underline !important;
  color: $blue-mayday !important;
}

.thumb-button {
  border: 1px solid lightgray;
  width: 60px !important;
  height: 60px !important;
  padding: 20px;
  border-radius: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $grey-6-mayday;
}

.thumb-up:hover {
  color: $blue-mayday;
  background-color: rgba($blue-mayday, 0.1);
  border-color: $blue-mayday;
}

.is-success {
  color: $blue-mayday;
  background-color: rgba($blue-mayday, 0.1);
  border-color: $blue-mayday;
}

.thumb-down {
  &:hover {
    color: $red-mayday;
    background-color: rgba($red-mayday, 0.1);
    border-color: $red-mayday;
  }
}

.is-danger {
  color: $red-mayday;
  background-color: rgba($red-mayday, 0.1);
  border-color: $red-mayday;
}

.thumb-button .fa {
  font-size: 1.8em;
}

.thumbs-icon {
  width: 28px;
  height: 28px;
}
</style>
