var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],class:[_vm.base]},[_c('div',{class:`${_vm.base}__wrapper`},[_c('button',{class:[
        `${_vm.base}__header`,
        { [`${_vm.base}__header-active`]: _vm.localDisplay },
      ],on:{"click":function($event){_vm.localDisplay = !_vm.localDisplay}}},[_c('div',{class:`${_vm.base}__header-info`},[(_vm.$slots.title)?_c('div',[_vm._t("title")],2):_c('div',{class:`${_vm.base}__header-title`},[_vm._v(_vm._s(_vm.title))])]),_c('font-awesome-icon',{staticClass:"thread__header-icon",class:[
          `${_vm.base}__header-icon`,
          { [`${_vm.base}__header-icon-rotate`]: _vm.localDisplay },
        ],attrs:{"icon":['fal', 'chevron-down']}})],1),(_vm.localDisplay)?_c('div',[_vm._t("body")],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }