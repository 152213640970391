var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.items.length > 0)?_c('div',{ref:"breadcrumb-container",class:['breadcrumb-container', { 'w-100': !_vm.ellipseOnly }]},[_c('div',{staticClass:"parent"},[_c('div',{ref:"breadcrumb",staticClass:"flex items-center w-100"},[(_vm.showHouse)?_c('div',{staticClass:"d-flex fex-row align-items-center",on:{"click":_vm.getBackHome}},[_c('div',{class:[
            'house-wrapper',
            { 'house-wrapper__clickable': _vm.clickable },
          ]},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'home']}})],1),_c('img',{staticClass:"case-path-navigator__separator",attrs:{"src":require("assets/breadcrumb.png")}})]):_vm._e(),(_vm.isTemplate)?_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{class:[
            'element',
            _vm.clickable ? 'element__clickable' : 'element__not-clickable',
          ],on:{"click":_vm.goToTemplates}},[_vm._v(" "+_vm._s(_vm.$t('knowledge.templates.description'))+" ")]),(_vm.items.length)?_c('img',{staticClass:"case-path-navigator__separator",attrs:{"src":require("assets/breadcrumb.png")}}):_vm._e()]):_vm._e(),_vm._l((_vm.renderedPath),function(parent,idx){return _c('div',{key:idx,staticClass:"d-flex align-items-center"},[_c('el-tooltip',{attrs:{"disabled":parent.label !== '...',"effect":'light'},scopedSlots:_vm._u([{key:"content",fn:function(){return _vm._l((_vm.hiddenParents),function(hiddenParent,hiddenIndex){return _c('div',{key:hiddenIndex,class:[
                'element',
                'element__tooltip',
                _vm.clickable ? 'element__clickable' : 'element__not-clickable',
                ,
              ],on:{"click":function($event){return _vm.sendUpdateCasePath(hiddenParent, hiddenIndex + 2)}}},[_vm._v(" "+_vm._s(hiddenParent.label)+" ")])})},proxy:true}],null,true)},[_c('div',{staticClass:"w-100 d-flex align-items-center",class:[
              'element',
              _vm.clickable && idx !== _vm.renderedPath.length - 1
                ? 'element__clickable'
                : 'element__not-clickable',
            ],on:{"click":function($event){return _vm.sendUpdateCasePath(parent, idx)}}},[_c('TextEllipsis',{attrs:{"text":parent.label,"fontSize":'14px',"width":idx === _vm.renderedPath.length - 1 ? _vm.maxWidth : null}})],1)]),(idx + 1 !== _vm.renderedPath.length || _vm.ellipseOnly)?_c('img',{staticClass:"case-path-navigator__separator ml-1",attrs:{"src":require("assets/breadcrumb.png")}}):_vm._e()],1)}),(_vm.isParametric)?_c('div',[_vm._t("default")],2):_vm._e()],2)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }