<template>
  <section class="case-viewer-children">
    <ul class="case-viewer-children__list">
      <li
        v-for="child in children"
        :key="child.id"
        class="case-viewer-children__item"
      >
        <CaseListItem
          :collection="{ ...child, userPermissions }"
          :extra-padding="extraPadding"
          @open-case="sendFocusEvent(child)"
          @delete="sendOpenDeleteModalEvent"
          @update-case="sendUpdateCaseEvent"
          @open-clone-to-target-modal="
            $emit('open-clone-case-to-target-modal', child)
          "
        />
      </li>
      <li class="case-viewer-children__item">
        <NewCaseButton
          :parent-id="caseId"
          :user-permissions="userPermissions"
          @add-case="sendAddCaseEvent"
          class="px-2"
        />
      </li>
    </ul>
  </section>
</template>

<script>
import CaseListItem from '@/components/ListItems/CaseListItem';
import NewCaseButton from '@/components/Commons/CasesNavigator/NewCaseButton';

export default {
  name: 'case-viewer-children',
  components: {
    CaseListItem,
    NewCaseButton,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    children: {
      type: Array,
      required: true,
    },
    extraPadding: {
      type: Boolean,
      default: false,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    sendFocusEvent(child) {
      this.$emit('focus-child', child);
    },
    sendUpdateCaseEvent(payload) {
      this.$emit('update-case', payload);
    },
    sendOpenDeleteModalEvent(caseId) {
      this.$emit('open-delete-modal', caseId);
    },
    sendAddCaseEvent(payload) {
      this.$emit('add-case', payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.case-viewer-children {
  .case-viewer-children__list {
    padding-inline-start: 0px;

    .case-viewer-children__item {
      list-style: none;
    }
  }
}
</style>
