<template>
  <div class="d-flex align-items-center search-input-wrapper">
    <font-awesome-icon
      :icon="['fal', 'search']"
      class="search-icon"
      :class="{
        'text-blue': isFocus,
      }"
    />
    <TagsInputWrapper
      ref="tagsInputWrapper"
      data-testid="tag-input-wrapper"
      :tags="tags"
      @delete="$emit('delete-tag', $event)"
    />
    <input
      ref="input"
      type="text"
      data-testid="search-input"
      class="pr-2 flex-grow-1"
      :class="{ 'pl-2': tags.length }"
      :value="value"
      :placeholder="placeholderToDisplay"
      :maxlength="inputMaxLength"
      v-on="listeners"
      v-bind="$attrs"
      spellcheck="false"
      size="1"
    />
    <div
      class="autocomplete-container"
      :style="autocompleteContainerDynamicStyle"
    >
      <div id="dummy" v-html="sanitize(dummyInputDisplay)"></div>
      <div
        class="autocomplete-label"
        v-html="sanitize(autocompleteSlicing)"
      ></div>
    </div>

    <button
      data-testid="delete-btn"
      class="d-flex align-items-center btn-delete"
      @click="$emit('delete-all')"
    >
      <font-awesome-icon
        :icon="['fal', 'times-circle']"
        class="btn-delete-icon"
      />{{ $t('knowledge.search-admin.delete') }}
    </button>
  </div>
</template>

<script>
import TagsInputWrapper from './TagsInputWrapper';
import { sanitize } from '@/plugins/dompurify';

export default {
  inheritAttrs: false,
  name: 'search-input',
  components: {
    TagsInputWrapper,
  },
  props: {
    value: String,
    placeholder: {
      type: String,
      default: function () {
        return this.$t('knowledge.search-admin.search-in');
      },
    },
    tags: {
      type: Array,
      default: () => [],
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
    displayAsk: {
      type: Boolean,
      default: true,
    },
    autocomplete: {
      type: Object,
      default: () => {
        return {
          label: '',
          id: '',
          type: '',
          remaining: '',
        };
      },
    },
  },
  data() {
    return {
      isFocus: false,
      inputMaxLength: 500,
      selectedTagsSize: 0,
      sanitize: sanitize,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      };
    },
    autocompleteSlicing() {
      if (!this.autocomplete || !this.autocomplete.remaining) return '';
      return this.autocomplete.remaining.replaceAll(' ', '&nbsp;');
    },
    dummyInputDisplay() {
      if (!this.value) return '';
      return this.value.replaceAll(' ', '&nbsp;');
    },
    autocompleteContainerDynamicStyle() {
      return {
        '--custom-left-position': `${46 + this.selectedTagsSize}px`,
      };
    },
    placeholderToDisplay() {
      if (this.displayAsk || !this.isFocus || !this.tags.length)
        return this.placeholder;
      return '';
    },
  },
  mounted() {
    if (this.autofocus) this.focusInput();
    document.addEventListener('keydown', this._keyListener.bind(this));
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit('input', value);
    },
    focusInput() {
      this.$nextTick(() => {
        if (this.$refs.input !== undefined) {
          this.isFocus = true;
          this.$refs.input.focus();
          this.$emit('show-dropdown', true);
        }
      });
    },
    blurInput() {
      this.$nextTick(() => {
        if (this.$refs.input !== undefined) {
          this.isFocus = false;
          this.$refs.input.blur();
          this.$emit('show-dropdown', false);
        }
      });
    },
    async getSelectedTagsSize() {
      let size = 0;
      if (!this.tags.length) return size;
      await this.$nextTick(() => {
        const el = this.$refs.tagsInputWrapper;
        if (!el) return size;
        size = el.$el.clientWidth;
      });
      return size + 7;
    },
    _keyListener(e) {
      if (
        (e.key === 'ArrowRight' || e.key === 'Tab') &&
        this.autocomplete.label
      ) {
        e.preventDefault();
        this.updateValue({ target: { value: this.autocomplete.label } });
        this.focusInput();
      }
    },
  },
  watch: {
    'tags.length': {
      async handler() {
        this.selectedTagsSize = await this.getSelectedTagsSize();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  font-size: 24px;
  line-height: 29px;
  border: none;
  z-index: 1;
  background: transparent;
}
input::placeholder {
  font-weight: 100;
  color: $grey-5-mayday;
}
.autocomplete-container {
  font-size: 24px;
  height: 29px;
  border: none;
  font-weight: 100;
  color: $grey-4-mayday;
  position: absolute;
  left: var(--custom-left-position);
  display: flex;
  align-items: center;
  max-width: calc(500px - var(--custom-left-position));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  #dummy {
    visibility: hidden;
    display: flex;
    align-items: center;
    max-height: 100%;
    white-space: nowrap;
  }
  .autocomplete-label {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.search-input-wrapper {
  background: white;
  padding: 12px;
  border-radius: 2px;
  position: relative;
}
.search-icon {
  padding-right: 8px;
  font-size: 21px;
  color: #828282;
  width: 32px;
}
.btn-delete {
  font-size: 10px;
  font-weight: bold;
  padding: 2px 4px;
  color: $grey-6-mayday;
  background: $grey-1-mayday;
  border: 0.5px solid $grey-4-mayday;
  border-radius: 2px;
}
.btn-delete-icon {
  margin-right: 3px;
}

@media only screen and (max-device-width: 768px) {
  input {
    font-size: 16px;
    line-height: 29px;
  }
}
</style>
