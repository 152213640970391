<template>
  <div :class="baseClass">
    <div
      v-for="col in ['name', 'path']"
      :key="col"
      :class="`${baseClass}__column mb-2`"
    >
      <h4>{{ $t(`${pre}.${col}`) }}</h4>
    </div>
    <div :class="`${baseClass}__actions`"></div>
  </div>
</template>

<script>
const i18nPrefix =
  'settings.card-settings-options.context-variables.drawer.headers';
const baseClass = 'context-variables-settings-drawer__header';

export default {
  name: 'context-variables-header',
  data() {
    return {
      pre: `${i18nPrefix}`,
      baseClass: baseClass,
      localVariable: {},
      editable: false,
    };
  },
  mounted() {
    this.localVariable = { ...this.variable };
  },
  props: {
    variable: {
      type: Object,
    },
  },
  watch: {
    variable() {
      this.localVariable = { ...this.variable };
    },
  },
};
</script>

<style lang="scss" scoped>
.context-variables-settings-drawer__header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  &__column {
    width: 100%;
    padding: 0 8px;
  }

  &__actions {
    min-width: 40px;
  }
}
</style>
