<template>
  <div class="clone-modal">
    <modal :show.sync="localDisplay" :closeIcon="false">
      <modal-header :style-name="{ backgroundColor: '#0081F91A' }" class="mb-3">
        <div slot="icon" class="d-inline-flex">
          <IconWrapper
            :icon="{
              iconType: 'fa',
              value: 'clone',
              color: '#0081F9',
            }"
            :size="32"
          />
        </div>
        <div>
          <p v-if="isMatchingLanguages" class="mt-3 mb-0 font-weight-bold">
            {{ $t('knowledge.modals.clone-kb.header-languages-match') }}
          </p>
          <p v-else class="mt-3 mb-0 font-weight-bold">
            {{ $t('knowledge.modals.clone-kb.header-title') }}
          </p>
        </div>
        <div slot="footer">
          <div
            v-if="isMatchingLanguages"
            class="d-flex align-items-center header-footer"
          >
            {{ $t('knowledge.modals.clone-kb.body-languages-match') }}
          </div>
          <div
            v-if="!isMatchingLanguages"
            class="d-flex align-items-center header-footer"
          >
            <div class="icon-background">
              <font-awesome-icon
                v-if="sourceType === 'case'"
                class="icon"
                :icon="['fad', 'folder-open']"
              />
              <font-awesome-icon
                v-else-if="sourceType === 'contents'"
                class="icon"
                :icon="['fal', 'file-alt']"
              />
              <img
                v-else-if="sourceType === 'Article'"
                src="~assets/article_icon.svg"
                class="icon"
              />
              <img
                v-else-if="sourceType === 'Diagnostic'"
                src="~assets/diag_icon.svg"
                class="icon"
              />
            </div>
            <div class="pl-2 text-left">
              <div class="label">{{ sourceLabel }}</div>
              <div class="sub-label">
                {{ subLabel }}
              </div>
            </div>
          </div>
        </div>
      </modal-header>

      <div v-if="isMatchingLanguages" class="clone-zone">
        <div
          class="language-match-wrapper"
          v-for="languageMatch in target.languagesMatch"
          :key="languageMatch.source"
        >
          <div class="language-match-row">
            <TranslationDropdown
              class="translation-dropdown"
              disabled
              :current-language="languageMatch.source"
            />
            <font-awesome-icon
              class="arrow-icon"
              :icon="['fal', 'arrow-right']"
            />
            <TranslationDropdown
              class="translation-dropdown"
              :languages="targetKnowledgeSupportedLanguages"
              :current-language="languageMatch.target"
              @change-language="
                handleLanguageChange(languageMatch.source, $event)
              "
            />
          </div>
        </div>
      </div>
      <div v-else class="clone-zone">
        <div class="mb-2 label">
          {{ $t('knowledge.modals.clone-kb.choose-kb') }}
        </div>
        <base-button-cascader
          :icon="optionIcon"
          :name="targetLabel"
          :placeholder="focusKnowledge.label"
          :showCascader="showCascader"
          @show="handleShow($event)"
        >
          <CascaderWrapper
            :source-type="sourceType"
            :source-id="sourceId"
            @clone-to-target="handleClone($event)"
            @close="showCascader = false"
          />
        </base-button-cascader>
      </div>
      <div
        class="language-match-error"
        v-if="isMatchingLanguages && hasErrorsInLanguagesMatch"
      >
        {{ $t('knowledge.modals.clone-kb.error-languages-match') }}
      </div>
      <div class="mt-4 d-flex justify-content-end">
        <ModalActions
          :primaryEnabled="isButtonEnabled"
          :secondary-label="
            isMatchingLanguages ? $t('knowledge.modals.clone-kb.back') : ''
          "
          @primary="cloneToTarget"
          @secondary="backLanguageMatching"
          v-loading="isLoading"
        />
      </div>
    </modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import Modal from '@/components/Modal';
import ModalHeader from '@/components/Modals/ModalHeader';
import IconWrapper from '@/components/IconWrapper';
import ModalActions from '@/components/Modals/ModalActions';
import CascaderWrapper from '@/components/CascaderWrapper';
import BaseButtonCascader from '@/components/BaseButtonCascader';
import TranslationDropdown from '@/components/TranslationDropdown';

export default {
  name: 'clone-knowledge-base-modal',
  components: {
    Modal,
    ModalHeader,
    IconWrapper,
    ModalActions,
    CascaderWrapper,
    BaseButtonCascader,
    TranslationDropdown,
  },
  props: {
    display: Boolean,
    sourceType: {
      type: String,
      default: 'case', // 'case' || 'contents'
    },
    sourceLabel: String,
    sourceId: String,
  },
  data() {
    return {
      localDisplay: this.display,
      showCascader: false,
      target: null,
      targetLabel: null,
      switchCaseName: false,
      newCaseName: '',
      isLoading: false,
      isMatchingLanguages: false,
    };
  },
  computed: {
    ...mapGetters('knowledgeModule', ['focusKnowledge', 'knowledges']),
    optionIcon() {
      return this.target && this.target.caseId
        ? ['fas', 'folder']
        : ['fad', 'books'];
    },
    subLabel() {
      if (this.sourceType === 'case') {
        return this.$t('knowledge.modals.clone-kb.header-sub-label');
      } else if (this.sourceType === 'contents') {
        return '';
      } else {
        return this.sourceType.toUpperCase();
      }
    },
    targetKnowledge() {
      if (!this.target || !this.target.targetKnowledgeId) return null;

      return this.knowledges.find(
        (knowledge) => knowledge.id === this.target.targetKnowledgeId,
      );
    },
    targetKnowledgeSupportedLanguages() {
      if (!this.targetKnowledge) return null;

      const targetLanguages = [
        ...this.targetKnowledge.supportedLanguages,
        'none',
      ];

      return targetLanguages;
    },
    hasErrorsInLanguagesMatch() {
      if (
        !this.target ||
        !this.target.languagesMatch ||
        !this.target.languagesMatch.length
      ) {
        return true;
      }

      const hasErrors = this.target.languagesMatch.every(
        (languageMatch) => languageMatch.target === 'none',
      );

      return hasErrors;
    },
    isButtonEnabled() {
      if (!this.isMatchingLanguages) return !!this.target;

      return !this.hasErrorsInLanguagesMatch;
    },
  },
  methods: {
    backLanguageMatching() {
      this.target = {
        ...this.target,
        languagesMatch: [],
      };
      this.isMatchingLanguages = false;
    },
    handleLanguageChange(sourceLang, targetLang) {
      // No need to check if targetLang already exists in languagesMatch
      if (targetLang === 'none') {
        const newLanguagesMatch = this.target.languagesMatch.map(
          (languageMatch) => {
            if (languageMatch.source === sourceLang) {
              return { source: sourceLang, target: 'none' };
            }
            return languageMatch;
          },
        );
        this.target = {
          ...this.target,
          languagesMatch: newLanguagesMatch,
        };
        return;
      }

      const newLanguagesMatch = [...this.target.languagesMatch];

      const existingTargetLangMatch = newLanguagesMatch.findIndex(
        (languageMatch) => languageMatch.target === targetLang,
      );

      const sourceLangMatch = newLanguagesMatch.findIndex(
        (languageMatch) => languageMatch.source === sourceLang,
      );

      if (existingTargetLangMatch >= 0) {
        newLanguagesMatch[existingTargetLangMatch] = {
          source: newLanguagesMatch[existingTargetLangMatch].source,
          target: 'none',
        };
      }

      newLanguagesMatch[sourceLangMatch] = {
        source: sourceLang,
        target: targetLang,
      };

      this.target = {
        ...this.target,
        languagesMatch: newLanguagesMatch,
      };
    },
    computeLanguageInitialState() {
      // Special case when we have a single language for both focus and target knowledge
      if (
        this.focusKnowledge.supportedLanguages.length === 1 &&
        this.targetKnowledge.supportedLanguages.length === 1
      ) {
        this.target = {
          ...this.target,
          languagesMatch: [
            {
              source: this.focusKnowledge.supportedLanguages[0],
              target: this.targetKnowledgeSupportedLanguages[0],
            },
          ],
        };
        return;
      }

      this.target = {
        ...this.target,
        languagesMatch: this.focusKnowledge.supportedLanguages.reduce(
          (acc, lang) => {
            if (this.targetKnowledgeSupportedLanguages.includes(lang)) {
              acc.push({ source: lang, target: lang });
            } else {
              acc.push({ source: lang, target: 'none' });
            }
            return acc;
          },
          [],
        ),
      };
    },
    shouldDisplayLanguagesMatching() {
      const focusLanguages = this.focusKnowledge.supportedLanguages;
      const targetLanguages = this.targetKnowledge.supportedLanguages;

      // If all focus languages are supported by target knowledge, no need to display language matching
      return !focusLanguages.every((lang) => targetLanguages.includes(lang));
    },
    cloneToTarget() {
      if (!this.targetKnowledge) {
        throw new Error('Target knowledge is not defined');
      }

      if (this.isMatchingLanguages) {
        this.$emit('clone-to-target', this.target);
        this.isLoading = true;
        return;
      }

      this.computeLanguageInitialState();

      if (this.shouldDisplayLanguagesMatching()) {
        this.isMatchingLanguages = true;
        return;
      }

      this.$emit('clone-to-target', this.target);
      this.isLoading = true;
    },
    handleClone(target) {
      this.targetLabel = target.label;
      this.target = {
        caseName: this.newCaseName ? this.newCaseName : this.sourceLabel,
        targetCaseId: target.caseId,
        targetKnowledge: target.knowledge,
        targetKnowledgeId: target.knowledgeId,
      };
      this.showCascader = false;
    },
    handleShow(show) {
      this.showCascader = show;
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
    switchCaseName() {
      this.newCaseName = '';
      this.$nextTick(() => this.$refs.input.focus());
    },
  },
};
</script>
<style lang="scss" scoped>
.clone-modal {
  :deep() .modal-dialog {
    max-width: 550px;
  }
}
.language-match-error {
  padding: 18px;
  border: 1px solid $red-mayday;
  border-radius: 2px;
  font-weight: bold;
  color: $red-mayday;
  margin-top: 12px;
  text-align: center;
}
.language-match-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.language-match-row {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.arrow-icon {
  flex: 0 1 10%;
}

.translation-dropdown {
  flex: 0 1 45%;
}
.header-footer {
  padding: 8px;
  background: white;
  border-radius: 2px;

  .icon {
    width: 24px;
    height: 24px;
    color: $grey-5-mayday;
  }

  .icon-background {
    padding: 16px 16px;
    background-color: $grey-1-mayday;
    border: 0.5px solid $grey-4-mayday;
    border-radius: 2px;
  }

  .label {
    font-weight: bold;
  }

  .sub-label {
    font-size: 8px;
    font-weight: bold;
    color: $grey-5-mayday;
  }
}
.clone-zone {
  padding: 18px;
  border: 1px solid $blue-mayday;
  border-radius: 2px;

  .label {
    font-weight: bold;
  }
}
.case-input {
  :deep() .form-control {
    font-size: 1rem;
    font-weight: bold;
    color: $grey-8-mayday;
  }
  :deep() .form-control::placeholder {
    font-weight: bold;
    color: $grey-5-mayday;
  }
}
</style>
