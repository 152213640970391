<template>
  <div>
    <div class="mb-1">
      <div class="element-title">
        {{ $t(title) }}
      </div>
      <div class="element-description" v-if="description">
        {{ $t(description) }}
      </div>
    </div>
    <div class="d-flex flex-row align-items-center">
      <el-color-picker
        class="mr-2"
        v-model="colorValue"
        @change="newValue"
        color-format="hex"
      ></el-color-picker>
      <div class="ml-2 placeholder-value">
        {{ colorValue }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'settings-color',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      colorValue: this.value,
    };
  },
  methods: {
    newValue() {
      this.$emit('update', this.colorValue);
    },
  },
  watch: {
    value(newVal) {
      this.colorValue = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
:deep() .el-color-picker {
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep() .el-color-picker__trigger {
  border-radius: 50%;
  padding: 0px;
  width: 30px;
  height: 30px;
}

:deep() .el-color-picker__color {
  border-radius: 50%;
  border: none;
}

:deep() .el-color-picker__color-inner {
  border-radius: 50%;
}

.placeholder-value {
  font-size: 12px;
  color: $grey-6-mayday;
}
</style>
