var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"case-path-navigator d-flex"},[_c('span',[_c('font-awesome-icon',{staticClass:"case-path-navigator__icon",attrs:{"icon":['fad', 'home']},on:{"click":function($event){return _vm.$emit('click:home')}}}),(_vm.ancestors.length)?_c('img',{staticClass:"case-path-navigator__separator",attrs:{"src":require("assets/breadcrumb.png")}}):_vm._e()],1),_vm._l((_vm.ancestors),function(ancestor,idx){return _c('span',{key:ancestor.id,staticClass:"d-flex"},[_c('p',{class:[
        'mb-0',
        'case-path-navigator__item',
        'd-flex',
        'align-items-center',
        _vm.ancestors.length === idx + 1 && _vm.routeType === 'Case'
          ? 'case-path-navigator__item--last-step'
          : '',
      ],on:{"click":function($event){return _vm.$emit('click:ancestor', { ancestor, idx })}}},[_vm._v(" "+_vm._s(ancestor.label)+" ")]),(_vm.ancestors.length > idx + 1)?_c('img',{staticClass:"case-path-navigator__separator",attrs:{"src":require("assets/breadcrumb.png")}}):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }