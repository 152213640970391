<template>
  <div :class="base">
    <base-button type="primary" @click="clicked = true" v-if="!clicked">
      <span>
        <font-awesome-icon class="mr-2" :icon="['fal', 'plus-square']" />{{
          $t(`${pre}.main.add`)
        }}
      </span>
    </base-button>
    <!-- ACTION SELECTION -->
    <div :class="`${base}__form`" v-else>
      <div :class="`${base}__form__action-selection`">
        <WorkflowSelect
          class="col-6"
          icon="plug"
          :title="$t(`${inputPre}.category.title`)"
          :placeholder="$t(`${inputPre}.category.placeholder`)"
          :footer-text="$t(categoryFooter)"
          :value="category"
          :options="categories"
          @change="category = $event"
        />
        <WorkflowSelect
          v-if="category"
          class="col-6"
          icon="plug"
          :title="$t(`${inputPre}.action.title.${category}`)"
          :placeholder="$t(`${inputPre}.action.placeholder`)"
          :grouped-options="category === 'library'"
          :value-key="category === 'library' ? 'payload.id' : 'type'"
          :filterable="category === 'library'"
          :value="action"
          :options="actions[category]"
          @change="action = $event"
        />
      </div>
      <!-- SUBMISSION -->
      <div :class="`${base}__form__cta`">
        <base-button type="secondary" size="sm" @click="clicked = false">
          {{ $t(`${pre}.main.cancel`) }}
        </base-button>
        <base-button
          type="primary"
          size="sm"
          :disabled="!validChoice"
          @click="createAction()"
        >
          {{ $t(`${pre}.main.create`) }}
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import WorkflowSelect from 'components/Workflow/WorkflowSelect.vue';
import { mapActions } from 'vuex';
const i18nPrefix = 'knowledge.diagnostic-builder.side-bar.workflow';

export default {
  name: 'add-workflow-action',
  components: {
    WorkflowSelect,
  },
  data() {
    return {
      base: Object.freeze('add-workflow-action'),
      pre: Object.freeze(i18nPrefix),
      inputPre: Object.freeze(`${i18nPrefix}.inputs.action-selection`),
      clicked: false,
      action: null,
      category: null,
      categories: [
        {
          label: this.$t(`${i18nPrefix}.main.categories.enrichment`),
          value: 'enrichment',
        },
        {
          label: this.$t(`${i18nPrefix}.main.categories.trigger`),
          value: 'trigger',
        },
        {
          label: this.$t(`${i18nPrefix}.main.categories.library`),
          value: 'library',
        },
      ],
      actions: {
        enrichment: [
          {
            label: this.$t(
              `${i18nPrefix}.main.actions.SET_CONTEXT_FROM_CONTEXT_SELECTION`,
            ),
            value: { type: 'SET_CONTEXT_FROM_CONTEXT_SELECTION' },
          },
          {
            label: this.$t(`${i18nPrefix}.main.actions.SET_CONTEXT_FROM_INPUT`),
            value: { type: 'SET_CONTEXT_FROM_INPUT' },
          },
          {
            label: this.$t(
              `${i18nPrefix}.main.actions.SET_CONTEXT_FROM_SELECT`,
            ),
            value: { type: 'SET_CONTEXT_FROM_SELECT' },
          },
          {
            label: this.$t(`${i18nPrefix}.main.actions.SET_CONTEXT_FROM_FORM`),
            value: { type: 'SET_CONTEXT_FROM_FORM' },
          },
          {
            label: this.$t(
              `${i18nPrefix}.main.actions.SET_CONTEXT_FROM_RESOURCE_FUNCTION`,
            ),
            value: { type: 'SET_CONTEXT_FROM_RESOURCE_FUNCTION' },
          },
        ],
        trigger: [
          {
            label: this.$t(`${i18nPrefix}.main.actions.INIT_SESSION`),
            value: { type: 'INIT_SESSION' },
          },
          {
            label: this.$t(`${i18nPrefix}.main.actions.AUTO_STEP_SELECTION`),
            value: { type: 'AUTO_STEP_SELECTION' },
          },
        ],
        library: [],
      },
    };
  },
  computed: {
    categoryFooter() {
      if (!this.category) return null;
      return `${this.inputPre}.category.footer.${this.category}`;
    },
    validChoice() {
      return this.category && this.action;
    },
  },
  methods: {
    // Library Helpers
    parseLibraryItem(libraryItem) {
      return {
        label: libraryItem.title,
        value: {
          type: 'LIBRARY_ACTION',
          payload: {
            id: libraryItem._id,
          },
        },
      };
    },
    parseLibraryItemsToGoupedOptions(libraryItems) {
      if (!libraryItems) return [];
      return Object.values(
        libraryItems.reduce((acc, el) => {
          if (!acc[el.type])
            acc[el.type] = {
              label: this.$t(`${i18nPrefix}.main.actions.${el.type}`),
              key: el.type,
              options: [],
            };

          acc[el.type].options.push(this.parseLibraryItem(el));

          return acc;
        }, {}),
      );
    },
    createAction() {
      this.$emit('change', this.action);
      this.clicked = false;
      this.category = null;
      this.action = null;
    },
    ...mapActions('knowledgeModule', ['getWorkflowActionLibrary']),
  },
  watch: {
    async category() {
      this.action = null;
      if (this.category === 'library') {
        const library = await this.getWorkflowActionLibrary();
        this.actions.library = this.parseLibraryItemsToGoupedOptions(library);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.add-workflow-action {
  &__form {
    margin: 14px;
    border: 1px solid $grey-5-mayday;
    border-radius: 8px;
    padding: 8px;
    &__action-selection {
      display: flex;
      flex-direction: row;
    }
    &__cta {
      display: flex;
      justify-content: flex-end;
      margin-top: 8px;
    }
  }
}
</style>
