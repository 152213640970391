<template>
  <div class="d-flex justify-content-center mt-4">
    <span
      v-for="(step, index) in steps"
      :key="index"
      :class="[
        'notification-step-item',
        step === currentStep ? 'notification-step-item-selected' : '',
      ]"
    ></span>
  </div>
</template>

<script>
export default {
  name: 'step-indicator',
  props: {
    steps: Array,
    currentStep: Number,
  },
};
</script>

<style lang="scss" scoped>
.notification-step-item {
  height: 10px;
  width: 10px;
  background-color: $grey-5-mayday;
  border-radius: 50%;
  &:not(:last-child) {
    margin-right: 5px;
  }
}
.notification-step-item-selected {
  background-color: $blue-mayday;
}
</style>
