<template>
  <div class="navigator-node-wrapper">
    <transition name="slide-fade">
      <div v-if="largeEditorBar" class="div navigation-bar">
        <div class="left-fixed-bar">
          <Navigator v-if="!isTemplate" :key="editingLanguage" />
          <TemplatesNavigation v-else :back-link="backLink" />

          <div v-if="displayOtherEntities">
            <hr class="col-10" />
            <Archives class="mb-1" :editing-language="editingLanguage" />
            <Templates
              style="mb-3"
              @back-link="backLink = $event"
              :editing-language="editingLanguage"
            />
          </div>
        </div>
        <div
          v-if="showToggleButton"
          class="enlarge-navigation-bar position-reduce d-flex align-items-center justify-content-center"
          @click="$emit('toggle-nav-bar')"
        >
          <font-awesome-icon
            class="enlarge-navigation-bar-icon"
            :icon="['far', 'chevron-left']"
          />
        </div>
      </div>
    </transition>
    <!-- // END LARGE NAVIGATOR NODE \\ -->
    <!-- // SMALL NAVIGATOR NODE \\ -->
    <transition name="slide-fade">
      <div v-if="!largeEditorBar" class="div small-navigation-bar">
        <div class="focus-element-icon mx-auto">
          <img :src="focusNavNodeIcon" class="reduce-navigator-icon" />
        </div>
        <div
          v-if="showToggleButton"
          class="enlarge-navigation-bar position-enlarge d-flex align-items-center justify-content-center"
          @click="$emit('toggle-nav-bar')"
        >
          <font-awesome-icon :icon="['far', 'chevron-right']" />
        </div>
      </div>
    </transition>
    <el-popover
      placement="top-start"
      width="250"
      trigger="click"
      @after-enter="isAddItemPopoverOpen = true"
      @after-leave="isAddItemPopoverOpen = false"
    >
      <transition name="slide-fade">
        <div class="align-items-center mt-2 mb-0">
          <div class="d-flex align-items-center floating-button-option my-3">
            <div
              class="icon-wrapper d-flex align-items-center justify-content-center"
            >
              <img src="~assets/close-folder.svg" class />
            </div>
            <p class="ml-2 mb-0">Créer une collection</p>
          </div>
          <div class="d-flex align-items-center floating-button-option my-3">
            <div
              class="icon-wrapper d-flex align-items-center justify-content-center"
            >
              <img src="~assets/diag-icon-v2.svg" class />
            </div>
            <p class="ml-2 mb-0">Créer un diagnostic</p>
          </div>
          <div class="d-flex align-items-center floating-button-option my-3">
            <div
              class="icon-wrapper d-flex align-items-center justify-content-center"
            >
              <img src="~assets/article-icon-v2.svg" class />
            </div>
            <p class="ml-2 mb-0">Créer un article</p>
          </div>
        </div>
      </transition>
      <!-- FLOATING BUTTON COMMENTED WAITING FOR FIGMAS -->
      <!-- <div
        slot="reference"
        class="floating-button d-flex align-items-center plus-icon"
        @click="displayFloatingButtonOptions = !displayFloatingButtonOptions"
      >
        <font-awesome-icon
          :icon="['fal', 'ellipsis-h']"
          class="mx-auto"
          v-if="isAddItemPopoverOpen"
        ></font-awesome-icon>
        <font-awesome-icon :icon="['fal', 'plus']" class="mx-auto" v-else></font-awesome-icon>
      </div> -->
    </el-popover>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';

// COMPONENTS
import Navigator from './Navigator/Navigator';
import Archives from './Archives/Archives';
import Templates from './Templates/Templates';
import TemplatesNavigation from '@/views/KnowledgeEditor/NavigationBar/Templates/TemplatesNavigation';

// IMAGES
const diagIcon = require('assets/diag-icon-v2.svg');
const articleIcon = require('assets/article-icon-v2.svg');
const folderIcon = require('assets/open-folder-focused.svg');

export default {
  name: 'navigation-bar',
  props: {
    largeEditorBar: Boolean,
    isSettingsDrawerOpen: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isAddItemPopoverOpen: false,
      backLink: '',
    };
  },
  components: {
    Navigator,
    Archives,
    Templates,
    TemplatesNavigation,
  },
  computed: {
    focusNavNodeIcon() {
      if (this.$route.path.includes('case') || !this.focusContent) {
        return folderIcon;
      } else {
        return this.focusContent.type === 'Article' ? articleIcon : diagIcon;
      }
    },
    isFirstStep() {
      if (this.focusPath) {
        return this.focusPath.length < 2;
      } else {
        return false;
      }
    },
    displayOtherEntities() {
      return !this.isTemplate;
    },
    isTemplate() {
      return this.$route.path.includes('templates');
    },
    showToggleButton() {
      return this.isSettingsDrawerOpen ? false : !this.isTemplate;
    },
    ...mapState('kbStore', ['focusPath']),
    ...mapGetters('knowledgeModule', [
      'focusKnowledge',
      'focusContent',
      'editingLanguage',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.navigator-node-wrapper {
  width: fit-content;
  overflow: auto;
  height: 100vh;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  img {
    max-width: 20px;
    height: 20px;
  }
}

.navigation-bar {
  padding: 16px;
  width: 320px;
  min-height: 100vh;
  background-color: #f8f9fe;
  border-left: 1px solid $grey-2-mayday;
}

// SMALL
.small-navigation-bar {
  width: 60px;
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  background-color: #f8f9fe;
}

.position-reduce {
  left: 368px;
}

.focus-element-icon {
  margin-top: calc(3rem + 52px);
  width: 32px;
}

.reduce-navigator-icon {
  width: 100%;
}

// ENLARGE
.enlarge-navigation-bar {
  cursor: pointer;
  position: fixed;
  z-index: 10;
  top: 54px;
  background-color: white;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  font-size: 16px;
  border: 1px solid $grey-1-mayday;
  box-shadow: 0px 1px 10px $shadow-4-mayday;
  color: $grey-4-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.position-enlarge {
  left: 108px;
}

// TRANSITION EFFECT

.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.floating-button {
  cursor: pointer;
  position: fixed;
  color: white;
  width: 60px;
  height: 60px;
  bottom: 30px;
  left: 90px;
  background-color: $blue-mayday;
  border-radius: 50px;
  text-align: center;
  font-size: 40px;
  z-index: 10000;
}
.floating-button-options {
  position: fixed;
  color: white;
  bottom: 120px;
  left: 90px;
  text-align: center;
  font-size: 40px;
  z-index: 10000;
}

.floating-button-option {
  cursor: pointer;
  color: $grey-5-mayday;
}

.floating-button-option:hover {
  cursor: pointer;
  color: $blue-mayday;
}
</style>
