<template>
  <div>
    <p class="text-small font-weight-bold text-center">
      {{ $t(description) }}
    </p>
    <SelectKnowledgeParameters
      class="section"
      :init-filters="initFilters"
      @update-product-filters="sendUpdateProductFiltersEvent"
    />
  </div>
</template>

<script>
import SelectKnowledgeParameters from '@/components/Commons/KnowledgeParameters/SelectKnowledgeParameters';

export default {
  name: 'global-parameter-selector',
  props: {
    description: {
      type: String,
      default:
        'knowledge.product.notification.global-parameter-selector-description',
    },
    initFilters: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    SelectKnowledgeParameters,
  },
  methods: {
    sendUpdateProductFiltersEvent(updatedProductFilters) {
      this.$emit('update-product-filters', updatedProductFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-small {
  color: $grey-5-mayday;
  font-size: 0.9em;
}

.grey-six {
  color: $grey-6-mayday;
}

.section {
  min-height: 150px;
}
</style>
