<template>
  <div class="synonyms-table">
    <!-- TABLE HEADER  -->
    <div class="synonyms-table-header">
      <div class="synonyms-table-header-col-main">
        {{ $t('settings.search.synonyms.main') }}
      </div>
      <div class="synonyms-table-header-col-synonyms">
        {{ $t('settings.search.synonyms.synonyms') }}
      </div>
      <div class="synonyms-table-header-col-actions">
        {{ $t('settings.search.synonyms.actions') }}
      </div>
    </div>

    <!-- NEW SYNONYM -->
    <CompanySynonymsTableRow
      class="synonyms-table-new-row"
      v-if="creating"
      :forceEditing="true"
      @created="$emit('created')"
      @abort="$emit('created')"
      v-bind="{ aSynonyms: '', bSynonyms: [] }"
    />
    <!-- SYNONYMS -->
    <div class="synonyms-table-list" ref="test">
      <CompanySynonymsTableRow
        v-for="synonym in synonyms"
        :key="synonym.id"
        :search="search"
        v-bind="synonym"
      />
    </div>

    <!-- SEARCH EMPTY STATE -->
    <div
      v-if="!creating && (noResults || !synonyms.length)"
      class="synonyms-table-empty-state"
    >
      <div class="synonyms-table-empty-state-wrapper">
        <div class="title">
          {{ $t('settings.search.synonyms.empty-state.title') }}
        </div>
        <div class="icon">
          <img src="~assets/empty-states/empty-state-templates.png" />
        </div>
        <div class="description">
          {{ $t('settings.search.synonyms.empty-state.description') }}
        </div>
        <div>
          <el-button
            v-if="!synonyms.length"
            @click="$emit('create')"
            type="primary"
            size="small"
            :disabled="creating"
          >
            <font-awesome-icon :icon="['fal', 'plus-square']" class="mr-2" />
            {{ $t('settings.search.synonyms.add') }}
          </el-button>
          <el-button
            v-else
            type="primary"
            size="small"
            @click="$emit('search', '')"
          >
            {{ $t('settings.search.synonyms.empty-state.cta-search') }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanySynonymsTableRow from './CompanySynonymsTableRow.vue';

export default {
  name: 'company-synonyms-table',
  components: {
    CompanySynonymsTableRow,
  },
  props: {
    synonyms: {
      type: Array,
      default: () => [],
    },
    creating: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      noResults: false,
    };
  },
  computed: {
    updatedSynonym() {
      return {
        id: this.editingId,
        aSynonym: this.aSynonym,
        bSynonym: this.bSynonym,
      };
    },
  },
  methods: {
    triggerUpdate({ id, aSynonym, bSynonym }) {
      this.editingId = id;
      this.aSynonym = aSynonym;
      this.bSynonym = bSynonym;
    },
    resetUpdate() {
      this.editingId = '';
      this.aSynonym = '';
      this.bSynonym = '';
    },
    handleUpdate() {
      this.$emit('update', this.updatedSynonym);
      this.resetUpdate();
    },
  },
  watch: {
    search: {
      handler() {
        if (!this.search || !this.search.length) {
          this.noResults = false;
          return;
        }
        this.$nextTick(() => {
          this.noResults = !this.$refs.test.children.length;
        });
      },
      immediate: true,
    },
    creating() {
      this.noResults = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.synonyms-table {
  position: relative;
  &-header {
    height: 38px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    display: flex;
    gap: 8px;
    background-color: $grey-3-mayday;
    font-size: 14px;
    font-weight: 700;
    color: black;
    padding: 12px 34px;
    &-col-main {
      width: 200px;
      flex: 0 0 auto;
    }
    &-col-synonyms {
      width: 100%;
    }
    &-col-actions {
      justify-content: flex-end;
    }
  }
  &-empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    background-color: white;
    border-bottom: 1px solid $grey-5-mayday;
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        margin-bottom: 8px;
        font-size: 14px;
        color: black;
      }
      .description {
        margin-bottom: 8px;
        font-size: 12px;
        color: $grey-7-mayday;
      }
      .icon {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
