const isTypeObjectOrString = (type) =>
  ['object', 'string', 'multiselect'].includes(type.toLowerCase());
const isTypeDateRange = (type) =>
  ['daterange', 'timerange'].includes(type.toLowerCase());
const isTypeDate = (type) => type.toLowerCase() === 'date';
const isTypeMatchingSingleValue = (type) =>
  isTypeObjectOrString(type) || isTypeDate(type);

export const facetPredicates = {
  SingleValue: {
    name: 'SingleValue',
    condition: ({ field, type }) =>
      field === 'value' && isTypeMatchingSingleValue(type),
    fields: [
      {
        name: 'Radio',
        condition: () => true,
      },
    ],
  },
  MultipleValues: {
    name: 'MultipleValues',
    condition: ({ field, type }) =>
      field === 'values' && isTypeObjectOrString(type),
    fields: [
      {
        name: 'MultiSelect',
        condition: ({ label }) => label === 'status',
      },
      {
        name: 'Checkboxes',
        condition: ({ label }) => label !== 'label',
      },
      {
        name: 'LabelCheckboxes',
        condition: ({ label }) => label === 'label',
      },
    ],
  },
  RangeValue: {
    name: 'RangeValue',
    condition: ({ field, type }) => field === 'range' && isTypeDateRange(type),
    fields: [
      {
        name: 'DateRange',
        condition: () => true,
      },
      {
        name: 'TimeRange',
        condition: () => true,
      },
    ],
  },
};

export const filterFamilies = Object.values(facetPredicates);

export const components = filterFamilies.flatMap(({ name, fields }) => {
  return fields.map(({ name: filterCompName }) => {
    return {
      component: () => import(`./Filter/${name}/${filterCompName}`),
      name: filterCompName,
    };
  });
});
