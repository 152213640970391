<template>
  <div class="diag-archi-modal">
    <modal
      :show.sync="localDisplay"
      size="100px"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-xl"
    >
      <template slot="header">
        <h3 class="modal-title" id="exampleModalLabel">
          {{
            $t(
              'knowledge.diagnostic-builder.side-bar.show-architecture.modal.title',
            )
          }}
        </h3>
      </template>
      <template v-if="displayOptions.showFooterSwitches" slot="footer">
        <div class="flex flex-row justify-content-end">
          <div class="switch mr-4">
            <el-switch
              v-model="hasBody"
              :active-text="
                $t(
                  'knowledge.diagnostic-builder.side-bar.show-architecture.modal.detailed',
                )
              "
            ></el-switch>
          </div>

          <not-allowed-tooltip
            :user-permissions="userPermissions"
            permission-key="canUpdateContent"
            placement="top"
          >
            <template v-slot:main-content="{ hasPermissions }">
              <div class="switch">
                <el-switch
                  v-model="options.isDraggable"
                  :disabled="!hasPermissions"
                  :active-text="
                    $t(
                      'knowledge.diagnostic-builder.side-bar.show-architecture.modal.editable',
                    )
                  "
                />
              </div>
            </template>
          </not-allowed-tooltip>
        </div>
      </template>

      <div
        class="diagram-tree flex-column d-flex align-items-center"
        style="text-align: center"
        v-loading="!organisationTree.length"
      >
        <div v-if="isLoading" class="to-display-loader"></div>
        <flowy
          v-else
          :nodes="organisationTree"
          :options="options"
          class="tree-node-by-flowy"
          @click:node="clickOnNode"
          @drag-start="onDragStart"
          @move="move"
          :beforeAdd="beforeAdd"
          :beforeMove="beforeMove"
          :onEnterDragFn="onEnter"
          :fetchAutomations="
            displayOptions.showAutomations ? getNodeAutomations : null
          "
          :fetchContent="getNodeContent"
          :fetchLightContent="getLightContent"
        >
          <template v-slot:node-title="{ node }">
            <div class="flex flex-row items-center">
              <div class="d-flex flex-shrink mr-2">
                <div
                  v-if="node.data.content.type === 'Diagnostic'"
                  class="img-root-wrapper"
                >
                  <img src="~assets/diag-icon-v2.svg" class="img-root" />
                </div>

                <font-awesome-icon
                  v-if="node.data.content.redirection"
                  :icon="['fa', 'external-link-square']"
                  class="icon-redirected-content"
                />
              </div>
              <div class="header-title">
                <span class="font-bold text-md text-dark-blue">{{
                  node.data.title
                }}</span>
              </div>
            </div>
          </template>

          <template v-slot:node-body="{ node }">
            <div
              class="step-node-body d-flex flex-column justify-center"
              v-if="hasBody || node.data.content.redirection.body"
            >
              <el-popover
                v-if="findBody(node) && firstBodyTag(findBody(node)).length"
                placement="right"
                width="400"
                trigger="click"
                class="popover-content px-2"
                popper-class="flowy-popover-container"
                @show="setContent(findBody(node))"
              >
                <div style="word-break: break-word">
                  <mayday-editor
                    ref="web-article-viewer"
                    v-if="toggleMaydayEditor"
                    v-model="bodyContent"
                    :editable="false"
                    @click:open-link="handleOpenLink"
                    class="mayday-editor p-3"
                  />
                </div>
                <el-button class="body-content" slot="reference">
                  {{ firstBodyTag(findBody(node)) }}
                </el-button>
              </el-popover>
              <div class="flex flex-row items-center body-footer px-2 pb-1">
                <div class="body-notifications flex flex-row items-center">
                  <el-tooltip
                    v-if="displayOptions.showAliases"
                    popper-class="label-tooltip"
                    effect="light"
                    :visible-arrow="false"
                  >
                    <div
                      v-if="node.data.content.tags.length"
                      slot="content"
                      class="flex flex-row items-center label-tooltip"
                    >
                      <p
                        v-for="(tag, index) in node.data.content.tags"
                        :key="index"
                        class="label px-2 mr-1"
                      >
                        {{ tag }}
                      </p>
                    </div>
                    <div
                      v-else
                      slot="content"
                      class="flex flex-row items-center"
                    >
                      {{
                        $t(
                          'knowledge.diagnostic-builder.side-bar.show-architecture.modal.label-tooltip-empty',
                        )
                      }}
                    </div>
                    <div class="flex flex-row items-center">
                      <div class="icon-wrapper">
                        <font-awesome-icon
                          icon="radar"
                          :class="[
                            node.data.content.tags.length
                              ? 'notifications-icon-active'
                              : 'notifications-icon-inactive',
                          ]"
                        />
                      </div>
                      <div class="count-wrapper">
                        <div
                          class="notifications-count"
                          v-if="node.data.content.tags.length"
                        >
                          {{ node.data.content.tags.length }}
                        </div>
                      </div>
                    </div>
                  </el-tooltip>
                  <el-tooltip
                    v-if="displayOptions.showAutomations"
                    class="automations-tooltip"
                    effect="light"
                    :visible-arrow="false"
                  >
                    <div
                      v-if="node.data.automations.length"
                      slot="content"
                      class="flex flex-row items-center"
                    >
                      <p
                        v-for="automation in node.data.automations"
                        :key="automation.id"
                        class="label px-2"
                      >
                        {{ automation.alias }}
                      </p>
                    </div>
                    <div
                      v-else
                      slot="content"
                      class="flex flex-row items-center"
                    >
                      {{
                        $t(
                          'knowledge.diagnostic-builder.side-bar.show-architecture.modal.automation-tooltip-empty',
                        )
                      }}
                    </div>
                    <div class="flex flex-row items-center">
                      <div class="icon-wrapper">
                        <font-awesome-icon
                          :icon="['fad', 'magic']"
                          :class="[
                            node.data.automations.length
                              ? 'notifications-icon-active'
                              : 'notifications-icon-inactive',
                          ]"
                        />
                      </div>
                      <div class="count-wrapper">
                        <div
                          class="notifications-count"
                          v-if="node.data.automations.length"
                        >
                          {{ node.data.automations.length }}
                        </div>
                      </div>
                    </div>
                  </el-tooltip>
                  <el-tooltip
                    v-if="
                      displayOptions.showTriggers &&
                      node.data.content.actions.length > 0
                    "
                    :content="
                      $t(
                        'knowledge.diagnostic-builder.side-bar.show-architecture.modal.response',
                      )
                    "
                    effect="light"
                    :visible-arrow="false"
                  >
                    <div class="flex flex-row items-center">
                      <div class="icon-wrapper">
                        <font-awesome-icon
                          icon="envelope"
                          class="notifications-icon-active"
                        />
                      </div>
                    </div>
                  </el-tooltip>
                </div>
                <el-tooltip effect="light" :content="tooltipContent(node)">
                  <el-button
                    type="primary"
                    class="icon-redirect"
                    :disabled="
                      node.redirection && !node.data.content.redirection
                    "
                    @click="clickOnNode(node)"
                  >
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                  </el-button>
                </el-tooltip>
              </div>
            </div>
          </template>
        </flowy>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

export default {
  name: 'diag-archi-modal',
  components: {
    NotAllowedTooltip,
  },
  props: {
    display: Boolean,
    diagnosticId: String,
    displayOptions: {
      type: Object,
      default: () => ({
        showFooterSwitches: true,
        showAliases: true,
        showAutomations: true,
        showTriggers: false,
      }),
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      bodyContent: '',
      toggleMaydayEditor: false,
      isLoading: false,
      diagnostic: {},
      localDisplay: this.display,
      organisationTree: [],
      options: {
        hasStats: false,
        isDraggable: false,
        hasMinimap: true,
        isZoomable: true,
      },
      hasBody: true,
      dragging: false,
      routeMapping: {
        knowledge: {
          content: 'knowledge-content',
          step: 'knowledge-content-step',
        },
        web: {
          content: 'mayday-web-by-content',
          step: 'mayday-web-by-step',
        },
        parametric: {
          content: 'web-content',
          step: 'web-content-step',
        },
      },
    };
  },
  async created() {
    if (!this.diagnosticId) return;
    this.init();
  },
  methods: {
    tooltipContent(node) {
      if (!node.redirection) {
        return this.$t(
          'knowledge.diagnostic-builder.side-bar.show-architecture.modal.tooltip-action',
        );
      }
      if (!node.data.content.redirection)
        return this.$t(
          'knowledge.diagnostic-builder.side-bar.show-architecture.modal.tooltip-empty',
        );
      return (
        this.$t(
          'knowledge.diagnostic-builder.side-bar.show-architecture.modal.tooltip-redirect',
        ) + node.data.content.redirection.label
      );
    },
    handleOpenLink({ href }) {
      window.open(href, '_blank');
    },
    onDragStartNewBlock(event) {
      this.newDraggingBlock = event;
    },
    onDragStopNewBlock() {
      this.newDraggingBlock = null;
    },
    beforeAdd() {
      return true;
    },
    afterAdd() {},
    onEnterDrop() {
      return true;
    },
    onEnter() {},
    beforeMove({ from }) {
      if (from && from.id === this.diagnosticId) {
        return false;
      }
      return true;
    },
    move(event) {
      const { dragged, to } = event;
      if (
        dragged.data.content.path.length &&
        dragged.data.content.path[0].split('/').includes(to.data.content.id)
      ) {
        return this.$message({
          message: this.$t(
            'knowledge.diagnostic-builder.side-bar.show-architecture.modal.parent-error',
          ),
          type: 'error',
        });
      }
      if (to.data.content.redirection) {
        return this.$message({
          message: this.$t('knowledge.child.redirect.move'),
          type: 'error',
        });
      }
      this.$emit('relocate-child', {
        childId: dragged.id,
        formerParentId: dragged.parentId,
        newParentId: to.id,
      });
      this.organisationTree.forEach((el) => {
        if (el.id == dragged.id) {
          el.parentId = to.id;
        }
      });
    },
    onDragStart() {
      this.dragging = true;
    },
    async init() {
      this.isLoading = true;
      this.organisationTree = await this.getOrgTree(this.diagnosticId);
      this.isLoading = false;
    },
    firstBodyTag(body) {
      const domParser = new DOMParser().parseFromString(body, 'text/html');
      const elements = Array.from(
        domParser.querySelectorAll(
          'p:not(:has(span.inline-mention)):not(:has(span.linked-node)):not(:empty), h1:not(:empty), h2:not(:empty), h3:not(:empty), h4:not(:empty), h5:not(:empty), h6:not(:empty), div:not(:empty)',
        ),
      );

      return elements.length > 0
        ? elements[0].textContent.replace(/<\/?[^>]+(>|$)/g, '')
        : '';
    },
    getRouteMappingKey() {
      const { path } = this.$route;
      if (path.includes('web-parametric')) {
        return 'parametric';
      } else if (path.includes('web')) {
        return 'web';
      } else {
        return 'knowledge';
      }
    },
    clickOnNode(event) {
      let entityId = event.id;
      let entityType = event.type;
      if (event.redirection) {
        entityId = event.redirection.entityId;
        entityType = event.redirection.entityType;
      }

      const payload = {
        entityId,
        entityType,
        query: { from: 'flowy' },
      };

      const { name } = this.$route;

      if (name.includes('mayday-web')) {
        this.goToWebTranslatedEntity(payload);
      } else {
        this.goToTranslatedEntity(payload);
      }

      this.localDisplay = false;
    },
    setContent(rawContent) {
      this.bodyContent = rawContent;
      this.toggleMaydayEditor = false;
      this.$nextTick(() => {
        this.toggleMaydayEditor = true;
        this.$nextTick(() => {
          const maydayEditor = this.$refs['web-article-viewer'];
          maydayEditor.editor.commands.setContent(this.bodyContent);
        });
      });
    },
    findBody(node) {
      const redirection = node.data.content.redirection;
      if (redirection) return redirection.body || '';
      else return node.data.content.body || '';
    },
    ...mapActions('knowledgeModule', [
      'getOrgTree',
      'getNodeAutomations',
      'getNodeContent',
      'getLightContent',
      'goToTranslatedEntity',
    ]),
    ...mapActions('webKnowledgeModule', ['goToWebTranslatedEntity']),
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.diagram-tree {
  max-height: 60vh;
  overflow: auto;
  border: 0.5px solid $grey-4-mayday;

  .tree-node-by-flowy {
    background-image: url('../../../src/assets/backgrounds/tile.png');
    background-repeat: repeat;
    background-size: 30px 30px;
    background-color: #fbfbfb;
    width: 100%;
    padding: 24px;
    display: flex;
    justify-content: center;

    :deep() .header-content {
      padding: 2px;
    }

    :deep() #flowy-tree {
      max-width: 100%;
    }

    :deep() .icon {
      max-width: 16px;
      max-height: 16px;
      color: $grey-5-mayday;

      &:hover {
        color: $blue-mayday;
      }
    }

    :deep() .icon-grip {
      cursor: grab;
    }

    :deep() button {
      border: unset;

      &:focus {
        border: unset;
        outline: none;
      }
    }
  }
}

:deep() .modal-header {
  align-items: center !important;
}

.to-display-loader {
  width: 300px;
  height: 300px;
}

.step-node-body {
  width: 100%;
  border-top: 1px solid $grey-4-mayday;
  background-color: $grey-1-mayday;
}

.body-content {
  border: solid 1px $grey-4-mayday !important;
  background-color: white;
  border-radius: 4px;
  padding: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 8px;
  max-width: 282px;
  min-width: 100%;
  font-size: small;
  text-align: left;
}

.body-footer {
  margin-top: 8px;
  justify-content: space-between;
}

.count-wrapper {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  margin-left: 2px;
}

.notifications-count {
  font-family: 'Circular-Loom';
  font-weight: bold;
  font-size: 12px;
  line-height: 10px;
}

.icon-wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notifications-icon-active {
  color: $blue-mayday;
  max-width: 16px;
  max-height: 16px;
  cursor: pointer;
}

.notifications-icon-inactive {
  color: $grey-4-mayday;
  max-width: 16px;
  max-height: 16px;
  cursor: pointer;
}

.app-header {
  justify-content: space-between;
}

:deep() .el-tooltip__popper {
  border-style: none;
  padding: 3px !important;
}

.label {
  background-color: rgba($blue-mayday, 0.1);
  padding: 2px;
  margin: 1px;
  margin-right: 3px;
  border-radius: 4px;
  border: solid 1px $blue-mayday;
  color: $blue-mayday;
  font-size: small;
  max-width: 85px;
  overflow: hidden;
  max-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.label:last-child() {
  margin-right: 0px;
}

.icon-redirect {
  width: 16px;
  height: 16px;
  padding: 3px;
  font-size: 10px;
}

.icon-redirected-content {
  color: $blue-mayday;
}

.switch {
  background-color: white;
  border-radius: 4px;
  padding: 8px;
  border: 0.5px solid $grey-4-mayday;
  align-items: center;
  display: flex;
}

.icon-keyStep {
  color: $yellow-mayday;
  border-radius: 4px;
  background-color: rgba($yellow-mayday, 0.1);
  width: 16px;
  height: 16px;
  padding: 7px;
  margin-right: -2px;
}

.icon-step {
  color: $blue-mayday;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.1);
  width: 16px;
  height: 16px;
  padding: 7px;
  margin-left: -0.5px !important;
  margin-right: -2px !important;
}

.header-title {
  justify-content: start;
  text-align: left;
  width: 100%;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  // Addition lines for 2 line or multiline ellipsis
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  font-weight: bold;
}

.img-root-wrapper {
  width: 30px;
  height: 30px;
  background-color: rgba($blue-mayday, 0.1);
  border-radius: 4px;
}

.img-root {
  max-height: 24px;
  max-width: 24px;
}

.mayday-editor {
  max-height: 40vh;
  overflow: auto;
}
</style>
