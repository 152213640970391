<template>
  <div class="template-navigation">
    <div class="templates-navigation-header">
      <div class="d-flex align-items-center">
        <div class="title">
          {{ $tc('knowledge.templates.header', templates.length) }}
        </div>
        <div class="count ml-2">{{ templates.length }}</div>
      </div>
      <div class="d-flex align-items-center">
        <TranslationDropdown
          v-if="focusKnowledgeIsMultilinguale"
          class="mx-1"
          :languages="focusKnowledgeLanguages"
          :current-language="editingLanguage"
          tight
          @change-language="switchEditingLanguage($event)"
        />
        <div class="add-button ml-2" @click="openCreateTemplate">
          <font-awesome-icon :icon="['fas', 'plus']" />
        </div>
      </div>
    </div>

    <TemplatesNavigationList
      class="templates-navigation-list"
      v-loading="loading"
    />

    <div class="templates-navigation-footer">
      <div class="back-knowledge" @click="goToKnowledge">
        <div class="back-icon-wrapper">
          <font-awesome-icon class="back-icon" :icon="['far', 'arrow-left']" />
          <div>{{ $t('knowledge.templates.back') }}</div>
        </div>
      </div>
    </div>
    <ModalEvents
      modal-name="CreateTemplateModal"
      :uid="createTemplateUniqueKey"
      @choice="handleCreateTemplate"
    />
  </div>
</template>

<script>
import TemplatesNavigationList from '@/views/KnowledgeEditor/NavigationBar/Templates/TemplatesNavigationList';
import TranslationDropdown from '@/components/TranslationDropdown';
import ModalEvents from '@/components/Modals/ModalEvents';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'templates-navigation',
  components: {
    TemplatesNavigationList,
    TranslationDropdown,
    ModalEvents,
  },
  props: {
    backLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    createTemplateUniqueKey() {
      return `${this.$options.name}-create-template`;
    },
    ...mapGetters('knowledgeModule', [
      'templates',
      'focusKnowledgeLanguages',
      'editingLanguage',
      'focusKnowledgeIsMultilinguale',
    ]),
  },
  methods: {
    goToKnowledge() {
      if (!this.backLink) return this.$router.push('/knowledge');
      return this.$router.push(this.backLink);
    },
    goToTemplates() {
      return this.$router.push('/knowledge/templates');
    },
    async openCreateTemplate() {
      const payload = {
        component: 'CreateTemplateModal',
        uid: this.createTemplateUniqueKey,
        props: { display: true, name: '', type: true },
      };
      this.openModal(payload);
    },
    async handleCreateTemplate(template) {
      this.loading = true;
      await this.createTemplate(template);
      this.loading = false;
    },
    ...mapActions('knowledgeModule', [
      'createTemplate',
      'switchEditingLanguage',
    ]),
    ...mapActions('modalsModule', ['openModal']),
  },
};
</script>

<style lang="scss" scoped>
.templates-navigation-header {
  display: flex;
  justify-content: space-between;
  height: 24px;
  margin-bottom: 16px;
  .title {
    font-size: 18px;
    font-weight: bold;
    color: black;
  }
  .count {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $grey-7-mayday;
    background-color: white;
    border-radius: 4px;
    width: 24px;
    height: 24px;
  }
}

.templates-navigation-list {
  height: calc(100vh - 136px);
}

.templates-navigation-footer {
  height: 78px;
}
.back-knowledge {
  display: block;
  .back-icon-wrapper {
    display: flex;
    width: min-content;
    padding-right: 4px;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background-color: $grey-2-mayday;
      border-radius: 4px;
    }
    .back-icon {
      width: 20px;
      height: 20px;
      padding: 4px;
      margin-right: 8px;
    }
  }
}

.add-button {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $grey-7-mayday;
  font-size: 14px;
  &:hover {
    background-color: white;
    color: $blue-mayday;
  }
}
</style>
