<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <template>
        <slot name="subtitle"></slot>
      </template>
    </div>

    <div class="d-flex justify-content-lg-between">
      <template>
        <slot name="main-action"></slot>
      </template>
    </div>
    <CaseNodeNavigator
      class="col-12"
      v-for="c in roots"
      :key="c.id"
      :case-params="c"
      :path="c.id"
      :focus-cases="caseSelection"
      :update-focus-cases="updateFocusCases"
      :knowledge="knowledge"
    />
  </div>
</template>

<script>
import CaseNodeNavigator from './CaseNodeNavigator/CaseNodeNavigator';
// import BaseSwitch from '../../BaseSwitch.vue';

export default {
  name: 'case-parameter-selector',
  components: {
    // BaseSwitch,
    CaseNodeNavigator,
  },
  props: {
    caseParameters: {
      type: Array,
      default: () => [],
    },
    knowledge: {
      type: String,
    },
    multiSelect: {
      type: Boolean,
      default: true,
    },
  },
  async created() {
    await this.initCaseParameters();
  },
  data() {
    return {
      switchValue: false,
      cases: [],
      roots: [],
      caseSelection: [],
    };
  },
  methods: {
    async initCaseParameters() {
      this.roots = await this.$services.cases.getRoots(this.knowledge);
      this.caseSelection = this.caseParameters;
      this.switchValue = this.caseSelection.length > 0;
    },
    updateFocusCases(val) {
      if (this.multiSelect) {
        const valIdx = this.caseSelection.indexOf(val);
        if (valIdx === -1)
          this.caseSelection = this.caseSelection.concat([val]);
        else this.caseSelection.splice(valIdx, 1);
      } else {
        this.caseSelection = [val];
      }
      this.updateCaseParameters();
    },
    updateCaseParameters() {
      this.$emit('update-case-parameters', {
        caseSelection: this.caseSelection,
        cases: this.cases,
      });
    },
  },
  watch: {
    knowledge() {
      this.initCaseParameters();
    },
  },
};
</script>

<style lang="scss" scoped>
.text-small {
  color: $grey-5-mayday;
  font-size: 0.9em;
}

.grey-six {
  color: $grey-6-mayday;
}

.section {
  background-color: $grey-1-mayday;
  border-radius: 4px;

  :deep() .el-cascader {
    width: 100%;
  }
}

.case-cascader {
  :deep() .el-checkbox {
    margin-bottom: 0px;
  }
}
</style>
