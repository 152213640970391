<template>
  <div class="analytics-views-controls-container">
    <ContentFilterSelect
      v-model="filterTypeModel"
      :options="filterTypeOptions"
    />
    <AnalyticsGraphTypeMenu
      class="graph-type-menu"
      :type.sync="graphTypeModel"
    />
  </div>
</template>

<script>
import ContentFilterSelect from './ContentFilterSelect';
import AnalyticsGraphTypeMenu from '../AnalyticsGraphTypeMenu';

export default {
  props: {
    graphType: {
      type: String,
      required: true,
    },
    filterType: {
      type: String,
      required: true,
    },
  },

  components: {
    ContentFilterSelect,
    AnalyticsGraphTypeMenu,
  },

  data() {
    return {
      filterTypeOptions: [
        {
          label: this.$t('new-dashboard.filter-type.analytics-views.source'),
          value: 'source',
        },
        {
          label: this.$t(
            'new-dashboard.filter-type.analytics-views.collection',
          ),
          value: 'collection',
        },
      ],
    };
  },

  computed: {
    filterTypeModel: {
      get() {
        return this.filterType;
      },
      set(value) {
        this.handleFilterTypeChange(value);
        return value;
      },
    },

    graphTypeModel: {
      get() {
        return this.graphType;
      },
      set(value) {
        this.handleGraphTypeChange(value);
        return value;
      },
    },
  },

  methods: {
    handleFilterTypeChange(value) {
      this.$emit('update:filterType', value);
    },
    handleGraphTypeChange(value) {
      this.$emit('update:graphType', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-views-controls-container {
  display: flex;
  align-items: center;

  .graph-type-menu {
    margin-left: 33px;
  }
}
</style>
