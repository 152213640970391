<template>
  <div class="content-access-restriction-review">
    <small>
      <font-awesome-icon :icon="['fad', 'user']" class="content-view-setting-review__icon mr-2" />
      <span
        v-if="accessRestrictions.length === 1"
      >{{ $t('knowledge.modals.notifications.review.group') }}</span>
      <span v-else>{{ $t('knowledge.modals.notifications.review.groups') }}</span>
      <ul>
        <li v-for="access in accessRestrictions" :key="access">{{ retrieveGroupLabel(access) }}</li>
      </ul>
    </small>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'content-access-restriction-review',
  props: {
    accessRestrictions: Array,
  },
  computed: mapState(['userGroups']),
  methods: {
    retrieveGroupLabel(id) {
      const locGroup = this.userGroups.find((ug) => ug.id === id);
      return locGroup ? locGroup.name : '';
    },
  },
};
</script>

<style>
</style>