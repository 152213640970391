<template>
  <div>
    <div class="d-flex flex-column header">
      <div class="d-flex flex-row align-items-center justify-content-between">
        <div class="header-title">
          {{ $t('public-knowledge.deployment.title') }}
        </div>
        <el-badge :class="['col-auto tag', state]" type="primary" size="mini">
          {{ $t(`public-knowledge.deployment.${state}`) }}
        </el-badge>
      </div>
      <div class="header-date">
        {{
          $t('public-knowledge.deployment.date', {
            date: time.date,
            hour: time.hour,
          })
        }}
      </div>
    </div>
    <div
      v-if="httpsWarning"
      :class="[
        'https-warning d-flex flex-row align-items-top',
        contentHttpsWarning.class,
      ]"
    >
      <div class="icon-wrapper h-100 mr-2">
        <font-awesome-icon
          :icon="['fal', contentHttpsWarning.icon]"
          :class="['https-warning-icon', contentHttpsWarning.class]"
        ></font-awesome-icon>
      </div>

      {{ $t(contentHttpsWarning.text) }}
    </div>
    <div class="steps">
      <div v-for="step in steps" :key="step.label" class="step">
        <div class="d-flex flex-row align-items-top">
          <div class="mr-2 icon-wrapper">
            <font-awesome-icon
              :icon="icon(step.state)"
              :class="['icon', step.state]"
              v-bind="{ spin: step.state === 'loading' }"
            ></font-awesome-icon>
          </div>
          <div class="d-flex flex-column justify-content-start">
            <div class="step-title">{{ $t(step.title) }}</div>
            <div class="step-description">{{ $t(step.description) }}</div>
          </div>
        </div>
      </div>
      <div v-if="!deploying" class="mt-3 d-flex justify-content-end">
        <el-button @click="$emit('return')" type="primary" size="mini">{{
          $t('public-knowledge.deployment.return')
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
const iconMapping = {
  todo: ['fal', 'circle'],
  loading: ['fal', 'spinner-third'],
  passed: ['fas', 'check-circle'],
  failed: ['fas', 'times-circle'],
  impossible: ['fas', 'times-circle'],
};
import { mapGetters } from 'vuex';

export default {
  name: 'DeploymentSteps',
  data() {
    return {};
  },
  props: {
    steps: Array,
    time: {
      type: Object,
      default: () => {
        return {
          date: '22/01',
          hour: '22h43',
        };
      },
    },
    state: String,
    httpsWarning: {
      type: String,
      default: '',
    },
  },
  computed: {
    contentHttpsWarning() {
      if (this.httpsWarning === 'loading')
        return {
          text: 'public-knowledge.https-pooling.warning.description',
          icon: 'info-circle',
          class: 'loading',
        };
      if (this.httpsWarning === 'error')
        return {
          text: `public-knowledge.https-pooling.failure.description`,
          icon: 'times-circle',
          class: 'error',
        };
      return {};
    },
    ...mapGetters('publicKnowledgeModule', ['deploying']),
  },
  methods: {
    icon(state) {
      return iconMapping[state];
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-wrapper {
  margin-top: 1px;
}
.icon {
  width: 20px;
  height: 20px;
}
.step-description {
  font-size: 14px;
  color: $grey-6-mayday;
}
.step-title {
  font-weight: bold;
}
.steps {
  padding: 15px;
}
.step {
  margin-bottom: 10px;
}
.todo {
  color: $grey-4-mayday;
}
.failed {
  color: $red-mayday;
}
.passed {
  color: $blue-mayday;
}
.loading {
  color: $blue-mayday;
}
.impossible {
  color: $grey-4-mayday;
}
.header {
  padding: 11px;
  border-bottom: 1px solid $grey-4-mayday;
  margin-bottom: 10px;
}
.header-date {
  font-size: 12px;
  color: $grey-6-mayday;
}
.header-title {
  font-size: 16px;
  font-weight: bold;
}
.tag {
  box-sizing: border-box;
  border-radius: 2px;

  font-weight: bold;
  font-size: 10px;
  line-height: 129.8%;
  text-align: center;
  letter-spacing: 0.5px;
  padding: 2px 8px;
}
.doing {
  background-color: rgba($blue-mayday, 0.1);
  border: 1px solid $blue-mayday;
  color: $blue-mayday;
}
.failed {
  background-color: rgba($red-mayday, 0.1);
  border: 1px solid $red-mayday;
  color: $red-mayday;
}
.released {
  background-color: rgba($green-mayday, 0.1);
  border: 1px solid $green-mayday;
  color: $green-mayday;
}
.https-warning {
  margin: 0px 15px;
  border-radius: 4px;
  padding: 8px;
  &.error {
    background-color: rgba($red-mayday, 0.1);
    color: $red-mayday;
  }
  &.loading {
    background-color: rgba($blue-mayday, 0.1);
    color: $blue-mayday;
  }
}

.https-warning-icon {
  &.error {
    color: $red-mayday;
  }
  &.loading {
    color: $blue-mayday;
  }
}
</style>
