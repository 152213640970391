<template>
  <div :class="['notification__popover-trigger', active ? 'active' : '']">
    <el-badge v-if="unreadCount" :value="unreadCount">
      <div
        class="notification__popover-bell"
        :class="{ 'red-mayday': unreadCount }"
      >
        <font-awesome-icon :icon="['fal', 'bell']" />
      </div>
    </el-badge>
    <div v-else class="notification__popover-bell">
      <font-awesome-icon :icon="['fal', 'bell']" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'notification-admin-trigger',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    unreadCount: {
      type: String,
      required: false,
    },
  },
  computed: {
    active() {
      return this.visible ? 'active' : '';
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep() .el-badge__content {
  background-color: $red-mayday;
}

.notification__popover-bell {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $grey-6-mayday;
  * {
    width: 22px;
    height: 22px;
  }
}

.notification__popover-trigger-web {
  color: white;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff1a;

  :deep() .el-badge__content.is-fixed {
    right: 5px;
    top: -5px;
  }

  &:hover {
    background: white;
    .notification__popover-bell {
      color: $blue-mayday;
    }
  }
}

.notification__popover-trigger {
  color: $grey-4-mayday;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff1a;
  margin: 8px auto;

  &:hover {
    background: $grey-2-mayday;
    .notification__popover-bell {
      color: $blue-mayday;
    }
  }

  :deep() .el-badge__content {
    font-size: 10px;
    line-height: 14px;
  }

  :deep() .notification__popover-bell {
    * {
      width: 16px;
      height: 16px;
    }
  }
}

.active {
  background: $grey-2-mayday;
  .notification__popover-bell {
    color: $blue-mayday;
  }
}

.red-mayday {
  color: $red-mayday !important;
}
</style>
