<template>
  <div
    class="col-12 title-col d-flex align-items-center"
    :class="{ isDraft: !isPublished && editable }"
  >
    <el-tooltip
      v-if="!isPublished && editable"
      content="Version brouillon"
      placement="top"
      class="group-tooltip"
    >
      <div class="indicator mr-2"></div>
    </el-tooltip>
    <input
      v-if="edit"
      alternative
      placeholder="Ajouter une branche"
      :maxlength="inputMaxLength"
      class="inputNodeLabel ml-0"
      ref="label"
      v-autowidth="{ maxWidth: '960px', minWidth: '50px', comfortZone: 0 }"
      v-model="newLabel"
      @blur="updateLabel()"
      @keyup.enter="$event.target.blur()"
      @input="notifyUserLength()"
    />
    <p v-else class="card-title" v-html="sanitize(highlightedLabel)"></p>
  </div>
</template>

<script>
import HighlightLabelMixin from '@/mixins/KnowledgeMixin/HighlightLabelMixin';
import { sanitize } from '@/plugins/dompurify';

export default {
  name: 'folder-item-label-editor',
  mixins: [HighlightLabelMixin],
  props: {
    label: {
      type: String,
      default: '',
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    nodeType: {
      type: String,
      default: 'Article',
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      newLabel: '',
      maxCharacter: 35,
      sanitize: sanitize,
    };
  },
  computed: {
    inputMaxLength() {
      if (this.nodeType === 'Folder') return this.maxCharacter;
      return undefined;
    },
  },
  methods: {
    updateLabel() {
      if (this.newLabel !== this.label)
        this.$emit('update-content', this.newLabel);
      this.$emit('close');
    },
    notifyUserLength() {
      if (this.inputMaxLength && this.newLabel.length === this.maxCharacter)
        this.$notify({
          title: 'Attention',
          message: 'Ne donnez pas des titres trop longs à vos collections',
          type: 'info',
        });
    },
  },
  watch: {
    edit() {
      if (this.edit) {
        this.newLabel = this.label;
        this.$nextTick(() => this.$refs['label'].focus());
      }
    },
  },
};
</script>

<style scoped>
.title-col {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
}

.inputNodeLabel:focus,
.inputNodeLabel,
.inputNodeLabel:active {
  outline: none;
  border: none;
  color: #4a4a4a;
  margin-bottom: 0px !important;
}

.card-title {
  color: #4a4a4a;
  margin-bottom: 0px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.isDraft .card-title {
  color: #adb5bd;
}

.indicator {
  height: 8px;
  width: 8px;
  margin-right: 8px;
  background-color: transparent;
  border-radius: 1px;
  background-color: #ced4d4;
}
</style>
