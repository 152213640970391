<template>
  <div class="base-action-wrapper">
    <!-- LEFT -->
    <div class="left-container">
      <div class="icon-container" @click="localDisplay = !localDisplay">
        <font-awesome-icon v-if="headerIcon" :icon="headerIcon" />
        <img v-else :src="headerCustomIconPath" />
      </div>
      <div class="icon-divider" v-show="localDisplay" />
    </div>
    <!-- RIGHT -->
    <div class="right-container">
      <div
        class="base-action-create-form-header"
        @click="localDisplay = !localDisplay"
      >
        <span class="header-title">{{ headerTitle }}</span>
        <slot name="additional-header" />
      </div>
      <div class="base-action-create-form-body" v-show="localDisplay">
        <div class="form-body">
          <slot name="body" />
        </div>
        <div class="divider" />
        <div class="base-action-create-form-footer">
          <button
            type="button"
            class="btn btn-danger btn-sm"
            size="mini"
            @click="$emit('delete')"
          >
            {{ $t('knowledge.action-triggers.delete.label') }}
          </button>
          <button
            type="button"
            class="btn btn-outline-primary btn-sm"
            size="mini"
            @click="$emit('create')"
            :disabled="saveDisabled"
          >
            {{
              $t(
                `knowledge.action-triggers.${
                  isNew ? 'create' : 'update'
                }.label`,
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-action-collapse',
  props: {
    headerTitle: {
      type: String,
      required: true,
    },
    headerIcon: {
      type: Array,
      required: false,
    },
    headerCustomIconPath: {
      type: String,
      required: false,
    },
    autoOpen: {
      type: Boolean,
      default: false,
    },
    saveDisabled: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localDisplay: false,
    };
  },
  mounted() {
    this.localDisplay = this.autoOpen;
  },
};
</script>

<style lang="scss" scoped>
.base-action-wrapper {
  display: flex;
  gap: 8px;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 8px;
  background-color: $grey-1-mayday;
}
.left-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon-container {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
}

.icon-divider {
  border-left: 1px solid black;
  height: 100%;
}

.right-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.base-action-create-form-header {
  display: flex;
  gap: 8px;
  width: 100%;

  background-color: white;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  height: 32px;
  font-size: 14px;
  font-weight: 600;
  padding-block: 4px;
  padding-inline: 12px;
  cursor: pointer;
}

.header-title {
  min-width: fit-content;
}

.base-action-create-form-body {
  background-color: white;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 12px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;

  :deep() .workflow-input-wrapper {
    padding: 0px;
  }

  :deep() .workflow-section-title__header {
    margin-bottom: 4px;
  }
}

.divider {
  margin-inline: -12px;
  margin-top: 16px;
}

.base-action-create-form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
