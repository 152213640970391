var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thread__tabs-container"},[_c('div',{staticClass:"thread__tabs"},_vm._l((_vm.statuses),function(status){return _c('button',{key:status.value,staticClass:"thread__tab",class:{
        'thread__focus-tab': _vm.focusStatus === status.value,
        'thread__tab-disabled':
          !_vm.filteredContentThreads(status.value).length &&
          status.value !== 'OPEN',
      },attrs:{"disabled":!_vm.filteredContentThreads(status.value).length &&
        status.value !== 'OPEN'},on:{"click":function($event){return _vm.$emit('focus-status', status.value)}}},[_c('div',{staticClass:"thread__tab-icon"},[_c('font-awesome-icon',{attrs:{"icon":status.icon}})],1),_c('div',{staticClass:"thread__tab-label"},[_vm._v(_vm._s(_vm.$t(status.label)))]),(status.value === 'OPEN')?_c('div',{staticClass:"thread__tab-badge thread__tab-badge-open"},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'comment-alt']}}),_c('span',[_vm._v(" "+_vm._s(_vm.openContentThread ? _vm.openContentThread.messages.length : 0)+" ")])],1):(status.value !== 'OPEN')?_c('div',{staticClass:"thread__tab-badge"},[_vm._v(" "+_vm._s(_vm.filteredContentThreads(status.value).length)+" ")]):_vm._e()])}),0),_c('div',{staticClass:"thread__tabs-actions"},[(
        _vm.focusStatus === 'OPEN' &&
        _vm.openContentThread &&
        _vm.openContentThread.messages.length
      )?_c('ThreadStatusDropdown',{on:{"update-status":function($event){return _vm.$emit('update-status', $event)}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }