<template>
  <div class="d-flex flex-column publish-content">
    <div class="delete-popover-title">
      {{ $t('public-knowledge.deployment.popover.title') }}
    </div>
    <div class="delete-popover-description">
      {{ $t('public-knowledge.deployment.popover.description') }}
    </div>
    <div class="changes">
      <publish-popover-row
        v-for="operation in operations"
        :key="
          operation.direction === 'TANSLATIONS'
            ? `${operation.key}.${operation.path.split('.')[0]}`
            : operation.key
        "
        v-bind="operation"
        :label="operation.key"
        @delete-operation="deleteOperation"
      ></publish-popover-row>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-end">
      <el-button
        @click="$emit('cancel')"
        size="mini"
        plain
        class="cancel-button"
        >{{ $t('public-knowledge.deployment.popover.cancel') }}</el-button
      >
      <el-button @click="confirm" type="primary" size="mini">{{
        $t('public-knowledge.deployment.popover.confirm')
      }}</el-button>
    </div>
  </div>
</template>

<script>
import PublishPopoverRow from './PublishPopoverRow.vue';

export default {
  name: 'PublishPopover',
  props: {
    operations: Array,
  },
  components: {
    PublishPopoverRow,
  },
  methods: {
    deleteOperation(key) {
      this.$emit('delete-operation', key);
    },
    confirm() {
      let sendable = true;
      this.operations.forEach((op) => {
        if (
          (op.key === 'favIconPath' || op.key === 'companyIconUri') &&
          op.operationType === 'delete'
        ) {
          sendable = false;
        }
      });
      if (sendable) this.$emit('confirm');
      else
        this.$message({
          message: this.$t(`public-knowledge.deployment.popover.error`),
          type: 'error',
          showClose: true,
          duration: 0,
          center: true,
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.publish-content {
  margin: 8px;
}

.delete-popover-title {
  font-size: 16px;
  font-weight: bold;
}
.delete-popover-description {
  font-size: 12px;
  color: $grey-7-mayday;
  margin-bottom: 14px;
}
.changes {
  margin-bottom: 10px;
}

.cancel-button {
  border: none;
}
</style>
