<template>
  <div class="wrapper">
    <div class="content-preview-ask-items">
      <div class="header-wrapper">
        <div
          :class="['header', { 'header-activated': isSummary }]"
          v-if="summary"
          @click="isSummary = true"
        >
          <font-awesome-icon
            class="ask-item-header-icon mr-2"
            :icon="['fal', 'file-minus']"
          ></font-awesome-icon>
          {{ $t('knowledge.search-admin.sidebar.content-preview.summary') }}
        </div>
        <div
          :class="['header', { 'header-activated': !isSummary }]"
          v-if="actionItems.length"
          @click="isSummary = false"
        >
          <font-awesome-icon
            class="ask-item-header-icon mr-2"
            :icon="['fal', 'file-check']"
          ></font-awesome-icon>
          {{
            $t('knowledge.search-admin.sidebar.content-preview.action-items')
          }}
        </div>
      </div>
      <div class="content" v-if="isSummary" @click="handleSelectAskItems">
        {{ summary }}
      </div>
      <div class="content" v-else @click="handleSelectAskItems">
        <ul>
          <li v-for="(action, index) in actionItems" :key="index">
            {{ action }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content-preview-ask-items',
  props: {
    summary: {
      type: String,
      default: '',
    },
    actionItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSummary: true,
    };
  },
  mounted() {
    if (!this.summary) this.isSummary = false;
  },
  methods: {
    handleSelectAskItems() {
      if (!window.getSelection() && !window.getSelection().toString()) return;
      navigator.clipboard.writeText(window.getSelection().toString());
      this.$message(this.$t('knowledge.actions.copy-to-clipboard'));
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 16px;
}
.content-preview-ask-items {
  padding: 4px 18px 12px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background-color: $grey-1-mayday;
  border-radius: 4px;
}
.header-wrapper {
  width: 100%;
  display: flex;
}
.header {
  width: 50%;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  border-bottom: 2px solid $grey-5-mayday;
  padding: 4px;
  color: $grey-8-mayday;
  font-weight: 700;
  text-wrap: nowrap;
}
.header:hover {
  cursor: pointer;
}
.header-activated {
  border-bottom: 2px solid $purple-5-mayday;
  color: $purple-5-mayday;
}
.content {
  text-align: justify;
  font-size: 14px;
  ul {
    padding-left: 20px;
  }
}
</style>
