<template>
  <BaseDropBox icon="hand-pointer">
    <template #content>
      <input
        class="border-0 text-base text-truncate"
        :class="!!errors && errors.title ? 'borderless-input-error' : ''"
        v-model="currentPlugin.title"
        @input="updatePlugin('title', $event.target.value)"
        :placeholder="
          $t(
            'settings-layout.menu-data.plugins.smart-actions.advanced-settings.title.placeholder',
          )
        "
      />
      <input
        v-if="!isDescriptionFocused"
        class="border-0 text-sm text-truncate"
        :class="!!errors && errors.title ? 'borderless-input-error' : ''"
        v-model="currentPlugin.description"
        @click="focusDescriptionTextarea()"
        :placeholder="
          $t(
            'settings-layout.menu-data.plugins.smart-actions.advanced-settings.description.placeholder',
          )
        "
      />
      <div v-else class="d-flex w-100 align-items-center gap-1">
        <textarea
          ref="descriptionTextarea"
          class="border-0 text-sm w-100"
          :rows="isDescriptionFocused ? undefined : 1"
          :class="{
            'text-truncate': !isDescriptionFocused,
            'borderless-input-error': !!errors && errors.description,
          }"
          v-model="currentPlugin.description"
          :maxlength="maxDescriptionLength"
          @input="updatePlugin('description', $event.target.value)"
          :placeholder="
            $t(
              'settings-layout.menu-data.plugins.smart-actions.advanced-settings.description.placeholder',
            )
          "
          style="resize: none"
          @blur="isDescriptionFocused = false"
        />
        <div
          class="text-xs"
          :class="{
            'text-red': descriptionLength === maxDescriptionLength,
          }"
        >
          {{ `(${descriptionLength}/${maxDescriptionLength})` }}
        </div>
      </div>
    </template>
    <template #collapse-content>
      <div>
        <div class="d-flex flex-column gap-2">
          <div class="d-flex gap-2 justify-content-between">
            <plugin-advanced-settings-option
              :field="fields.label"
              :error="!!errors ? errors.label : undefined"
              @update-plugin="updatePlugin('label', $event)"
            />
            <plugin-advanced-settings-option
              :field="fields.color"
              :error="!!errors ? errors.color : undefined"
              @update-plugin="updatePlugin('color', $event)"
            />
          </div>
          <plugin-advanced-settings-option
            :field="fields.subtype"
            :error="!!errors ? errors.subtype : undefined"
            @update-plugin="updateSubtype($event)"
          />
          <plugin-advanced-settings-option
            v-for="property in additionnalProperties"
            :key="`plugin-advanced-settings-additional-property-${property}`"
            :field="fields[property]"
            :error="!!errors ? errors[property] : undefined"
            @update-plugin="updatePlugin(property, $event)"
          />
        </div>
        <div class="d-flex justify-content-end mt-3">
          <el-tooltip
            :content="
              $t(
                'settings-layout.menu-data.plugins.smart-actions.advanced-settings.disable-delete-tooltip',
              )
            "
            effect="light"
            :visible-arrow="false"
            :disabled="!disableDelete"
            placement="right-end"
          >
            <el-button
              outline
              :disabled="disableDelete"
              type="danger"
              size="mini"
              @click="$emit('delete-plugin')"
            >
              {{ $t('generic.delete') }}
            </el-button>
          </el-tooltip>
        </div>
      </div>
    </template>
  </BaseDropBox>
</template>

<script>
import BaseDropBox from './BaseDropBox.vue';
import PluginAdvancedSettingsOption from './PluginAdvancedSettingsOption.vue';

export default {
  components: {
    BaseDropBox,
    PluginAdvancedSettingsOption,
  },
  props: {
    currentPlugin: Object,
    config: Object,
    errors: Object,
    disableDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDescriptionFocused: false,
      maxDescriptionLength: 140,
      additionnalProperties: [],
      fields: {
        label: {
          type: 'input',
          model: this.currentPlugin.label,
          label:
            'settings-layout.menu-data.plugins.smart-actions.advanced-settings.label.label',
          placeholder:
            'settings-layout.menu-data.plugins.smart-actions.advanced-settings.label.placeholder',
        },
        color: {
          type: 'color',
          model: this.currentPlugin.color,
          label:
            'settings-layout.menu-data.plugins.smart-actions.advanced-settings.color.label',
          placeholder:
            'settings-layout.menu-data.plugins.smart-actions.advanced-settings.color.placeholder',
        },
        subtype: {
          type: 'select',
          model: this.currentPlugin.subtype,
          label:
            'settings-layout.menu-data.plugins.smart-actions.advanced-settings.subtype.label',
          placeholder:
            'settings-layout.menu-data.plugins.smart-actions.advanced-settings.subtype.placeholder',
          options: Object.entries(this.config.subtypes).map(
            ([key, subtype]) => ({ label: subtype.label, value: key }),
          ),
        },
        url: {
          type: 'input',
          prepend: 'https://',
          model: (this.currentPlugin.url || '').startsWith('https://')
            ? this.currentPlugin.url.slice(8)
            : this.currentPlugin.url,
          label:
            'settings-layout.menu-data.plugins.smart-actions.advanced-settings.url.label',
          placeholder:
            'settings-layout.menu-data.plugins.smart-actions.advanced-settings.url.placeholder',
        },
      },
    };
  },
  computed: {
    descriptionLength() {
      return this.currentPlugin.description.length;
    },
  },
  mounted() {
    this.updateAdditionalProperties(this.currentPlugin.subtype);
  },
  methods: {
    focusDescriptionTextarea() {
      this.isDescriptionFocused = true;
      this.$nextTick(() => {
        this.$refs.descriptionTextarea.focus();
      });
    },
    updatePlugin(key, value) {
      this.$emit('update-plugin', { [key]: value });
    },
    updateSubtype(subtype) {
      this.updatePlugin('subtype', subtype);
      this.updateAdditionalProperties(subtype);
    },
    updateAdditionalProperties(subtype) {
      if (subtype && this.config.subtypes[subtype]) {
        this.additionnalProperties = Object.entries(
          this.config.subtypes[subtype].properties,
        ).reduce((acc, [key, activated]) => {
          if (activated) {
            acc.push(key);
          }
          return acc;
        }, []);
      } else {
        this.additionnalProperties = [];
      }
    },
  },
  watch: {
    errors(newValue) {
      if (newValue) {
        this.isCollapsed = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.borderless-input-error {
  &::placeholder {
    color: $red-5-mayday;
    opacity: 0.7;
  }
  &::-webkit-input-placeholder {
    /* Chrome */
    color: $red-5-mayday;
    opacity: 0.7;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $red-5-mayday;
    opacity: 0.7;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $red-5-mayday;
    opacity: 0.7;
  }
  &:-moz-placeholder {
    /* Firefox 4 - 18 */
    color: $red-5-mayday;
    opacity: 0.7;
  }

  &::-ms-input-placeholder {
    color: $red-5-mayday;
    opacity: 0.7;
  }
}

.btn-cancel {
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
}

.btn-create {
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
</style>
