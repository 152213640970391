<template>
  <div class="pt-4 mx-4">
    <div class="row" v-loading="isLoading">
      <div class="col-3">
        <AutomationsMenu
          :knowledges="knowledges"
          :grouped-attributes="companyAttributesGroupedByIntegration"
          @updateFocusedKnowledge="updateFocusedKnowledge"
          @updateFocusedCase="updateFocusedCase"
          class="automation-menu"
        />
      </div>

      <div class="col-9">
        <AutomationsMain
          :automations="selectedAutomations"
          :label="getLabel"
          @open-automation="openAutomation"
          @automation-delete="deleteGroup"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import AutomationsMenu from './AutomationsMenu';
import AutomationsMain from './AutomationsMain';

export default {
  name: 'Automations',
  components: {
    AutomationsMenu,
    AutomationsMain,
  },
  data() {
    return {
      automations: {},
      knowledges: [],
      caseLabel: '',
      isLoading: false,
      focusedKnowledgeId: null,
      modals: {
        attributesImport: false,
        redirection: false,
      },
      displayFloatingButtonOptions: false,
      caseId: null,
      loadingMessage: null,
    };
  },
  computed: {
    getLabel() {
      return this.caseLabel;
    },
    integration() {
      if (this.hasZendesk) return 'zendesk';
      if (this.hasIntercom) return 'intercom';
      if (this.hasSalesforce) return 'salesforce';
      return null;
    },
    selectedAutomations() {
      if (this.focusedKnowledgeId && this.caseId) {
        return this.automations[this.caseId];
      }
      return [];
    },
    ...mapState(['companyAttributes']),
    ...mapGetters([
      'hasZendesk',
      'hasIntercom',
      'hasSalesforce',
      'companyAttributesGroupedByIntegration',
    ]),
  },
  async created() {
    this.isLoading = true;
    try {
      this.knowledges = await this.$services.hierarchies.getKnowledgeDetails();
      await this.getCompanyAttributes();
      const focusedKnowledge = this.knowledges.find(
        (knowledge) => knowledge.isDefault,
      );
      if (focusedKnowledge) {
        this.focusedKnowledgeId = focusedKnowledge.id;
      } else {
        this.focusedKnowledgeId = this.knowledges[0].id;
      }
      await this.updateFocusedKnowledge({
        knowledgeId: this.focusedKnowledgeId,
      });
      this.caseId = Object.keys(this.automations)[0];
      this.caseLabel = (await this.$services.cases.getById(this.caseId)).label;
    } catch (err) {
      return err;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async openAutomation({ contentId, contentType }) {
      const enrichedContent =
        await this.$services.parametricContents.getWithEnriched(
          contentId,
          contentType === 'Diagnostic',
        );

      const automationsCopy = { ...this.automations };
      automationsCopy[this.caseId].map((automation) => {
        if (automation.content._id === contentId) {
          automation.content = { ...enrichedContent, _id: enrichedContent.id };
        }
        return automation;
      });
      this.automations = automationsCopy;
    },
    ...mapActions(['setCompanyAttributes', 'getCompanyAttributes']),
    ...mapActions('webKnowledgeModule', ['getKnowledges']),

    async updateFocusedKnowledge({ knowledgeId, caseId, caseLabel }) {
      this.automations =
        await this.$services.automationRecollection.getGroupsByKnowledge({
          knowledgeId,
        });
      this.updateFocusedCase({ caseId, caseLabel });
    },

    async updateFocusedCase({ caseId, caseLabel }) {
      this.caseId = caseId;
      this.caseLabel = caseLabel;
    },
    updateSelectedContent(event) {
      this.automationLabel = event.label;
      this.focusedKnowledgeId = event.knowledgeId;
      this.caseId = event.id;
    },
    deleteGroup({ id }) {
      const automationIdx = this.automations[this.caseId].indexOf(
        (automationsInCase) => (automationsInCase.group.id = id),
      );
      this.automations[this.caseId].splice(automationIdx, 1);
      return automationIdx;
    },
    async importUserChoice(choice) {
      this.loadingMessage = this.$message({
        duration: 0,
        dangerouslyUseHTMLString: true,
        iconClass: 'display: none',
        showClose: true,
        message: `<i class="fas fa-spinner fa-spin" style="margin-right: 1em;"></i> ${this.$t(
          'automations.set-attributes.loading',
        )}`,
      });
      this.modals.attributesImport = false;
      if (choice)
        await this.setCompanyAttributes({
          integration: null,
          companyAttributes: choice,
        });
      this.loadingMessage.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.floating-button-options {
  position: fixed;
  color: white;
  bottom: 120px;
  left: 90px;
  text-align: center;
  font-size: 40px;
  z-index: 10000;
}

.floating-button-option {
  cursor: pointer;
  color: $grey-5-mayday;

  &:hover {
    color: $blue-mayday;
  }
}

.automation-menu {
  padding-bottom: 120px;
}
</style>
