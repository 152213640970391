<template>
  <section
    v-if="
      satisfactionRates.thumbUpPercent !== 0 ||
        satisfactionRates.thumbDownPercent !== 0
    "
    class="satisfaction-rates my-4"
  >
    <h5>Satisfaction</h5>
    <div class="d-flex align-items-center">
      <small class="satisfaction-rate-item my-2">
        <font-awesome-icon
          class="mr-2"
          :class="[
            'fa',
            'fa-thumbs-up',
            satisfactionRates.thumbUpPercent >=
            satisfactionRates.thumbDownPercent
              ? 'is-success'
              : '',
          ]"
          :icon="['fad', 'thumbs-up']"
        />
          {{ satisfactionRates.thumbUpPercent }}%
      </small>
      <el-tooltip placement="top" class="group-tooltip">
        <div slot="content">
          {{ satisfactionRates.thumbUpCount }} /
          {{ satisfactionRates.totalThumbCount }}
        </div>
        <font-awesome-icon
          class="thumbs-up-count ml-2"
          :icon="['fad', 'info-circle']"
        />
      </el-tooltip>
    </div>
    <div class="d-flex align-items-center">
      <small class="satisfaction-rate-item my-2">
      <font-awesome-icon
        class="mr-2"
        :class="[
          'fa',
          'fa-thumbs-down',
          satisfactionRates.thumbUpPercent <
          satisfactionRates.thumbDownPercent
            ? 'is-danger'
            : '',
        ]"
        :icon="['fad', 'thumbs-down']"
      />
        {{ satisfactionRates.thumbDownPercent }}%
      </small>
      <el-tooltip placement="top" class="group-tooltip">
        <div slot="content">
          {{ satisfactionRates.thumbDownCount }} /
          {{ satisfactionRates.totalThumbCount }}
        </div>
        <font-awesome-icon
          class="thumbs-up-count ml-2"
          :icon="['fad', 'info-circle']"
        />
      </el-tooltip>
    </div>
  </section>
</template>

<script>
export default {
  name: 'satisfaction-rates',
  props: {
    satisfactionRates: {
      type: Object,
      default: () => ({
        thumbUpPercent: 0,
        thumbUpCount: 0,
        thumbDownPercent: 0,
        thumbDownCount: 0,
        totalThumbCount: 0,
      }),
    },
  },
};
</script>

<style lang="scss" scoped>
.satisfaction-rates {
  border-left: 2px solid $grey-4-mayday;
  display: grid;
  padding: 0px 10px;
  color: $grey-6-mayday;

  .fa {
    color: $grey-5-mayday;
  }
}

.normal-text {
  font-size: 1em;
}

.is-success {
  color: $blue-mayday !important;
}

.is-danger {
  color: $red-mayday !important;
}

.thumbs-up-count{
  font-size: 70%;
  cursor: pointer;
  &:hover{
    color: $blue-mayday;
  }
}

</style>
