var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navigator-node"},[(!_vm.isFirst)?_c('CommonNode',{attrs:{"node":_vm.node,"isRoot":_vm.node.root,"position":_vm.position,"parent-ids":_vm.parentIds,"show-children":_vm.showChildren,"draggable":_vm.draggable},on:{"focus":_vm.componentFocused,"add-child":_vm.triggerAddChild,"delete-node":_vm.deleteNode,"update-content":_vm.handleUpdateContent,"update-content-verification":_vm.updateContentVerification,"clone-to-target":_vm.handleCloneToTarget,"toggle-children":_vm.toggleChildren,"add-child-from-template":_vm.openTemplateModal}}):_vm._e(),(_vm.showChildren && this.isFolder)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}]},[_c('draggable',{staticClass:"dragArea",class:[
        _vm.parentIds.length === 1 ? 'collection-one' : 'collection-two',
        !_vm.allowed ? 'cursor-not-allowed' : null,
      ],attrs:{"tag":"ul","draggable":".tree-node","group":"draggable-nodes","disabled":!_vm.draggable,"swap-treshold":0.8,"invert-swap":true,"move":_vm.checkMove},on:{"change":_vm.handleDragAndDrop,"end":_vm.onEnd},model:{value:(_vm.draggableNodes),callback:function ($$v) {_vm.draggableNodes=$$v},expression:"draggableNodes"}},[_vm._l((_vm.draggableNodes),function(node,idx){return _c('li',{key:node.id,staticClass:"tree-node without_bullet"},[_c('navigator-node',{attrs:{"node":node,"show-children":false,"position":idx,"parent-ids":_vm.parentIds.concat([node.id]),"focus-path":_vm.focusPath,"draggable":_vm.draggable}})],1)}),(_vm.newChildFocusType)?_c('li',{staticClass:"without_bullet"},[_c('NewNode',{staticClass:"new-node",class:{
            'new-node-collection-two': _vm.parentIds.length !== 1,
          },attrs:{"parent-id":_vm.node.id,"parent-ids":_vm.parentIds.concat([0]),"type":_vm.newChildFocusType},on:{"blur":function($event){_vm.newChildFocusType = null},"add-child":function($event){return _vm.addChild($event)}}})],1):_vm._e(),(
          _vm.nodes &&
          _vm.nodes.length === 0 &&
          _vm.newChildFocusType === null &&
          !_vm.isLoading
        )?_c('li',{staticClass:"without_bullet"},[_c('p',{staticClass:"mb-0"},[_c('small',[_vm._v(_vm._s(_vm.$t('knowledge.root.empty-1')))])])]):_vm._e()],2)],1):_vm._e(),_c('ModalEvents',{attrs:{"modal-name":"AddTemplateModal","uid":_vm.templateModalUniqueKey},on:{"choice":_vm.addChildFromTemplate}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }