<template>
  <div>
    <p
      class="text-small font-weight-bold text-center"
    >{{ $t('knowledge.modals.notifications.review-description') }}</p>

    <div class="product-notification-review__area mx-4 my-4">
      <p>
        <small>
          <font-awesome-icon
            :icon="['fad', 'check-circle']"
            class="product-notification-review__icon"
          />
          {{ $t('knowledge.modals.notifications.review.notification') }}
          <strong>{{ productNotification.title }}</strong>
          {{ $t('knowledge.modals.notifications.review.product-page') }}
        </small>
      </p>
      <!-- PRODUCT PARAMETERS -->
      <ContentParametersReview
        v-if="productNotification.parameters.length"
        :parameters="productNotification.parameters"
      />
      <p class="mb-0" v-if="productNotification.accessRestrictions.length">
        <small>
          <font-awesome-icon :icon="['fad', 'user']" class="product-notification-review__icon" />
          <span
            v-if="productNotification.accessRestrictions.length === 1"
          >{{ $t('knowledge.modals.notifications.review.group') }}</span>
          <span v-else>{{ $t('knowledge.modals.notifications.review.groups') }}</span>
          {{
          userGroups
          .filter((userGroup) =>
          productNotification.accessRestrictions.includes(userGroup.id),
          )
          .map((userGroup) => userGroup.name)
          .join(', ')
          }}
        </small>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContentParametersReview from '@/components/Commons/ContentParametersReview';

export default {
  name: 'product-notification-review-modal',
  components: { ContentParametersReview },
  props: {
    productNotification: {
      type: Object,
      default: () => ({
        title: '',
        description: '',
        urgent: false,
        parameters: [],
        accessRestrictions: [],
      }),
    },
  },
  computed: {
    selectedGroups() {
      return this.userGroups.filter((g) =>
        this.notification.accessRestrictions.includes(g.id),
      );
    },
    ...mapState(['userGroups']),
  },
};
</script>

<style lang="scss" scoped>
.text-small {
  color: $grey-5-mayday;
  font-size: 0.9em;
}

.product-notification-review__area {
  background-color: $grey-1-mayday;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 16px;
}

.product-notification-review__icon {
  margin-right: 4px;
  color: $grey-6-mayday;
}
</style>
