export const createSkeleton = (source) => {
  if (!source) {
    return null;
  }
  const skeleton = {};
  const defaultValues = { string: '', number: 0, boolean: false };
  Object.entries(source).forEach(([key, value]) => {
    if (source.hasOwnProperty(key)) {
      var type = typeof value;
      skeleton[key] =
        type == 'object' ? createSkeleton(source[key]) : defaultValues[type];
    }
  });
  return skeleton;
};
