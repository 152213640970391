<template>
  <div class="case-node-navigator">
    <!-- CASE LABEL -->
    <CaseNodeNavigatorLabel
      :show-children="showChildren"
      :label="caseParams.label"
      :has-children="caseParams.hasChildren"
      :path="path"
      :focus-cases="focusCases"
      @toggle="toggle"
      @update-focus-cases="updateFocusCases"
    />
    <!-- CASE CHILDREN -->
    <div v-if="showChildren" class="case-children">
      <ul>
        <li class="without_bullet" v-for="child in children" :key="child.id">
          <case-node-navigator
            :case-params="child"
            :path="updatePath(child.id)"
            :focus-cases="focusCases"
            :update-focus-cases="updateFocusCases"
            :knowledge="knowledge"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CaseNodeNavigatorLabel from './CaseNodeNavigatorLabel';

export default {
  name: 'case-node-navigator',
  components: { CaseNodeNavigatorLabel },
  props: {
    caseParams: Object,
    path: String,
    focusCases: Array,
    updateFocusCases: Function,
    knowledge: String,
  },
  data() {
    return {
      children: [],
      showChildren: false,
    };
  },
  methods: {
    async toggle() {
      if (!this.showChildren) {
        this.$services.cases
          .getChildren(this.caseParams.id, this.knowledge, this.editingLanguage)
          .then((res) => {
            this.children = res;
            this.showChildren = true;
          });
      } else {
        this.showChildren = false;
      }
    },
    updatePath(id) {
      return `${this.path}/${id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.case-children {
  overflow-x: hidden;
}
.without_bullet {
  list-style: none;
}
.case-icon {
  &:hover {
    color: $blue-mayday;
    cursor: pointer;
  }
}
</style>
