<template>
  <div class="d-flex align-items-center flex-row justify-content-between">
    <div>
      <span class="preference-label">{{
        $t('settings.card-settings-options.emails-language.title')
      }}</span>
      <div class="preference-description">
        {{ $t('settings.card-settings-options.emails-language.description') }}
      </div>
    </div>
    <el-select
      size="small"
      class="pl-2 select-language"
      v-model="selectedLanguage"
      @change="handleChange"
    >
      <template #prefix>
        <LanguageFlag :language="selectedLanguage" emoji />
      </template>
      <el-option
        v-for="lang in options"
        :key="lang"
        :value="lang"
        :label="$t(`settings.card-settings-options.emails-language.${lang}`)"
      >
        <div class="option-container">
          <LanguageFlag :language="lang" emoji />
          <span>
            {{ $t(`settings.card-settings-options.emails-language.${lang}`) }}
          </span>
        </div>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import LanguageFlag from '@/components/LanguageFlag';

export default {
  name: 'email-language-select',
  components: { LanguageFlag },
  props: {
    emailLanguagePreference: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedLanguage: '',
      isCustom: false,
      options: ['fr-fr', 'en-us'],
    };
  },
  created() {
    const splittedValue = this.emailLanguagePreference.value
      ? this.emailLanguagePreference.value.split('_')
      : ['fr-fr', ''];
    this.selectedLanguage = splittedValue[0];
    this.isCustom = splittedValue[1] === 'custom';
  },
  methods: {
    handleChange(e) {
      const preferenceValue = `${e}${this.isCustom ? '_custom' : ''}`; // Example: fr-fr_custom or fr-fr
      return this.$emit('update-preference', {
        preferenceValue,
        preferenceId: this.emailLanguagePreference.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.preference-label {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
}
.preference-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
.select-language {
  :deep() .el-input__inner {
    height: 30px;
    width: 140px;
    padding-left: 48px;
  }
  :deep() .el-input__prefix {
    margin-top: 6px;
    padding-left: 16px;
  }
}

.option-container {
  display: flex;
  column-gap: 16px;
  letter-spacing: 0.02rem;
}
</style>
