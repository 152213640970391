<template>
  <div v-loading="loading" :class="[base]">
    <div :class="`${base}__wrapper`">
      <!-- TITLE -->
      <button
        @click="localDisplay = !localDisplay"
        :class="[
          `${base}__header`,
          { [`${base}__header-active`]: localDisplay },
        ]"
      >
        <div :class="`${base}__header-info`">
          <div v-if="$slots.title">
            <slot name="title"></slot>
          </div>
          <div :class="`${base}__header-title`" v-else>{{ title }}</div>
        </div>
        <font-awesome-icon
          :icon="['fal', 'chevron-down']"
          class="thread__header-icon"
          :class="[
            `${base}__header-icon`,
            { [`${base}__header-icon-rotate`]: localDisplay },
          ]"
        />
      </button>

      <!-- BODY -->
      <div v-if="localDisplay">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-collapse',
  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.localDisplay = this.display;
  },
  data() {
    return {
      localDisplay: false,
      base: Object.freeze('base-collapse'),
    };
  },
  watch: {
    display() {
      this.localDisplay = this.display;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-collapse {
  margin-bottom: 16px;
}

.base-collapse__wrapper {
  border: 1px solid $grey-3-mayday;
  border-radius: 4px;
}

.base-collapse__header {
  margin: 0;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: $grey-1-mayday;
  color: $grey-8-mayday;
  transition: background-color 250ms ease-in-out;
  width: 100%;

  &-icon {
    font-size: 12px;
    transition: transform 250ms ease-in-out;
    &:hover {
      color: $blue-mayday;
    }
    &-rotate {
      transform: rotate(-180deg);
      color: $blue-mayday;
    }
  }

  &:hover,
  &-active {
    color: black;
  }

  &-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &-title {
    font-weight: 700;
    font-size: 14px;
  }
}
</style>
