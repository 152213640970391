<template>
  <div class="export-container">
    <span class="type-name">{{
      $t(`new-dashboard.exports.${type}.title`)
    }}</span>
    <p class="type-description">
      {{ $t(`new-dashboard.exports.${type}.description`) }}
    </p>
    <div class="picker-container">
      <el-dropdown @command="handleSelectKnowledge">
        <span class="el-dropdown-link">
          {{ $t('new-dashboard.exports.button.empty')
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-bind:key="knowledge.id"
            v-for="knowledge in knowledges"
            :command="knowledge.id"
          >
            {{ knowledge.label }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-if="knowledgeId">
      <el-button
        v-if="!link && !isGenerating"
        type="primary"
        class="start-button"
        @click="triggerExport"
      >
        {{
          $t('new-dashboard.exports.selected', {
            knowledge: selectedKnowledgeLabel,
          })
        }}
      </el-button>
      <el-button
        v-else
        type="primary"
        class="start-button"
        @click="handleDownload"
        v-loading="isGenerating"
      >
        {{
          $t('new-dashboard.exports.link', {
            knowledge: selectedKnowledgeLabel,
          })
        }}
        <font-awesome-icon :icon="['fas', 'download']" />
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AnalyticsDownloadKB',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedKnowledgeId: null,
      timeout: null,
      timeoutTime: 500,
      link: null,
      isGenerating: false,
      knowledgeId: null,
    };
  },
  computed: {
    ...mapGetters('knowledgeModule', ['knowledges']),
    selectedKnowledgeLabel() {
      const knowledge = this.knowledges.find(
        (knowledge) => knowledge.id === this.knowledgeId,
      );
      if (knowledge) {
        return knowledge.label;
      }
      return '';
    },
  },
  methods: {
    ...mapActions('kbExportModule', ['requestExport', 'getExportStatus']),
    handleDownload() {
      window.open(this.link, '_blank');
    },
    async handleSelectKnowledge(knowledgeId) {
      this.knowledgeId = knowledgeId;
      this.link = null;
      await this.checkExportStatus();
    },

    async triggerExport() {
      await this.requestExport({knowledgeId: this.knowledgeId, type: this.type});
      this.link = null;
      this.triggerPoller();
    },

    triggerPoller() {
      this.timeout = setTimeout(async () => {
        await this.checkExportStatus();
      }, this.timeoutTime);
    },

    clearPoller() {
      this.timeout = null;
      this.timeoutTime = 500;
    },

    setLinkIsAvailable(exportLink) {
      this.link = exportLink;
      this.isGenerating = false;
    },

    async checkExportStatus() {
      if (!this.knowledgeId) return;

      this.isGenerating = true;

      try {
        const result = await this.getExportStatus({knowledgeId: this.knowledgeId, type: this.type});
        if (result.state == 'PENDING') {
          this.timeoutTime = Math.min(this.timeoutTime * 2, 10000);
          this.triggerPoller();
          return;
        }
        if (result.state == 'DONE') {
          this.setLinkIsAvailable(result.exportLink);
        }
        this.clearPoller();
      } catch (error) {
        this.clearPoller();
      }
      this.isGenerating = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.export-container {
  border: solid lightgray 1px;
  border-radius: 5px;
  padding: 16px;
}

.type-name {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: $grey-8-mayday;
  margin-bottom: unset;
}

.type-description {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: $grey-6-mayday;
}

.picker-container {
  max-width: 250px;
  margin-bottom: 16px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.title-wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
}

.start-button {
  padding: 10px;
}
</style>
