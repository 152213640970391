<template>
  <div class="notifications-option">
    <side-bar-item :description="tooltipDescription" :type="type" @click="$emit('click')">
      <template v-slot:icon="{ _class }">
        <font-awesome-icon slot="icon" :class="_class" :icon="['fad', 'paper-plane']" />
      </template>
    </side-bar-item>
  </div>
</template>

<script>
import SideBarItem from './SideBarItem';

export default {
  name: 'historization-option',
  components: {
    SideBarItem,
  },
  props: {
    type: {
      type: String,
      default: 'diagnostic',
    },
  },
  data() {
    return {
      tooltipDescription: 'Retourner au CRM',
    };
  },
  methods: {},
};
</script>
