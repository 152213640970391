<template>
  <base-dropdown :disabled="!canCreateContent">
    <div
      slot="title"
      class="dropdown dropdown-button d-flex align-items-center"
    >
      <font-awesome-icon
        :icon="['fal', 'plus-square']"
        class="medium-icon options_selection"
      />
    </div>
    <div>
      <div
        class="dropdown-item cursor"
        style="display: inline-block"
        v-if="isNotRoot"
        @click="addChildren('Article')"
      >
        <h5 class="text-overflow m-0">
          <img src="~assets/article-icon-v2.1.svg" class="dropdown_icon" />
          <small>{{ $t('knowledge.actions.add-article') }}</small>
        </h5>
      </div>
      <div
        class="dropdown-item cursor"
        style="display: inline-block"
        v-if="isNotRoot"
        @click="addChildren('Diagnostic')"
      >
        <h5 class="text-overflow m-0">
          <img src="~assets/diag-icon-v2.1.svg" class="dropdown_icon" />
          <small>{{ $t('knowledge.actions.add-diagnostic') }}</small>
        </h5>
      </div>
      <div
        class="dropdown-item pointer"
        style="display: inline-block"
        v-if="isNotRoot"
        @click="$emit('add-child-from-template')"
      >
        <h5 class="text-overflow m-0">
          <span class="dropdown_icon fai_icon">
            <font-awesome-icon :icon="['fas', 'file-certificate']" />
          </span>
          <small>{{ $t('knowledge.templates.import.options.title') }}</small>
        </h5>
      </div>
      <div class="dropdown-divider" v-if="isNotRoot"></div>
      <div
        class="dropdown-item cursor"
        style="display: inline-block"
        @click="addChildren('Folder')"
      >
        <h5 class="text-overflow m-0">
          <img src="~assets/close-folder.svg" class="dropdown_icon p-1" />
          <small>{{ $t('knowledge.actions.add-collection') }}</small>
        </h5>
      </div>
    </div>
  </base-dropdown>
</template>
<script>
export default {
  name: 'add-node-options',
  props: {
    parentIds: {
      type: Array,
      default: () => [],
    },
    canCreateContent: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isNotRoot() {
      return this.parentIds.length > 1;
    },
  },
  methods: {
    addChildren(nodeType) {
      this.$emit('add-child', nodeType);
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown {
  padding: 0px;
}

.dropdown_icon {
  max-width: 20px;
  margin-right: 5px;
}

.fai_icon {
  width: 20px;
  color: $grey-5-mayday;
  * {
    font-size: 20px;
    height: 16px;
  }
}

.options_selection {
  max-width: 15px;
  color: #adb5bd;
}

.options_selection:hover {
  color: $blue-mayday;
}

.dropdown-button {
  border-width: 0px;
  box-shadow: none;
  margin: auto;
}
</style>
