var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"activation-settings-container"},[_c('div',{staticClass:"item__header d-flex align-items-center justify-content-between"},[_c('span',{staticClass:"item__title font-bold"},[_vm._v(_vm._s(_vm.integration.name))]),_c('el-tooltip',{attrs:{"content":_vm.$t(
          'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.integrations.disable',
          {
            name: _vm.$t(
              `settings-layout.menu-data.plugins.${_vm.pluginTranslationKey}.title`,
            ),
          },
        ),"effect":"light","visible-arrow":false,"disabled":_vm.isPreferenceActive,"placement":"right-end"}},[_c('el-switch',{attrs:{"disabled":!_vm.isPreferenceActive},on:{"change":function($event){return _vm.$emit('update-plugin-integration', {
            ..._vm.integration,
          })}},model:{value:(_vm.integration.isActive),callback:function ($$v) {_vm.$set(_vm.integration, "isActive", $$v)},expression:"integration.isActive"}})],1)],1),_c('p',{staticClass:"item__description text-sm text-gray"},[_vm._v(" "+_vm._s(_vm.$t( 'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.integrations.description', ) + ' ' + (_vm.integration.name === 'Zendesk App' ? _vm.$t( 'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.integrations.zendesk', ) : _vm.integration.name))+" ")]),_c('div',{staticClass:"item__status d-flex align-items-center"},[_c('font-awesome-icon',{class:['item__icon', { active: _vm.integration.isActive }],attrs:{"icon":['fal', 'check-circle']}}),_c('div',{staticClass:"item-status-description"},[_vm._v(" "+_vm._s(_vm.$t( `settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.integrations.${ _vm.integration.isActive ? 'active' : 'disable' }`, { name: _vm.$t( `settings-layout.menu-data.plugins.${_vm.pluginTranslationKey}.title`, ), }, ))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }