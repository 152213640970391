<template>
  <div>
    <!-- EMPTY STATE -->
    <div v-if="!labels.length && !newLabel">
      <div>
        {{
          $t('settings.card-settings-options.content-parameters.empty-category')
        }}
      </div>

      <el-button size="small" @click="$emit('new-label')">
        {{
          $t('settings.card-settings-options.content-parameters.create-label')
        }}
      </el-button>
    </div>
    <!-- LABELS -->
    <div v-for="label in labels" :key="label.id">
      <LabelCategorySettingsOverviewItem
        v-bind="label"
        @delete-label="$emit('delete-label', $event)"
        @update-label="$emit('update-label', $event)"
      />
    </div>
    <NewLabel
      v-if="newLabel"
      @cancel-new-label-input="$emit('cancel-new-label-input')"
      @edit="$emit('create-label', $event)"
    />
  </div>
</template>

<script>
import LabelCategorySettingsOverviewItem from './LabelCategorySettingsOverviewItem';
import NewLabel from './NewLabel';
export default {
  name: 'label-category-settings-overview',
  components: {
    LabelCategorySettingsOverviewItem,
    NewLabel,
  },
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    newLabel: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
