<template>
  <div class="content-case-parameters-review">
    <small>
      <font-awesome-icon
        :icon="['fad', 'project-diagram']"
        class="content-view-setting-review__icon"
      />
      <span>{{ $t('knowledge.modals.content-view-setting.review.cases') }}</span>
      <ul>
        <li v-for="c in caseParameters" :key="c">{{ retrieveLabel(c)}}</li>
      </ul>
    </small>
  </div>
</template>

<script>
export default {
  name: 'content-case-parameters-review',
  props: {
    caseParameters: Array,
  },
  data() {
    return {
      casesLabel: [],
    };
  },
  async created() {
    this.casesLabel = await this.$services.cases.getByIds(
      this.caseParameters.map((cp) => cp.split('/').slice(-1)[0]),
    );
  },
  methods: {
    retrieveLabel(path) {
      const id = path.split('/').slice(-1)[0];
      const locCases = this.casesLabel.find((cl) => cl.id === id);
      return locCases ? locCases.label : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.content-view-setting-review__icon {
  margin-right: 4px;
  color: $grey-6-mayday;
}
</style>