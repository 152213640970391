import gqlService from 'utils/gqlService';

const updateContent = ({ newContent }) => {
  const localContent = { ...newContent };
  const { attachedObjects, body } = localContent;
  if (attachedObjects.length > 0) {
    const doc = new DOMParser().parseFromString(body, 'text/html');
    const remainingLinkedObjects = Array.from(
      doc.getElementsByClassName('linked-node'),
    );
    localContent.attachedObjects = remainingLinkedObjects.map(
      (lo) => lo.attributes['node-id'].nodeValue,
    );
  }
  return gqlService.knowledge.updateNodeContent(localContent);
};

const dragndrop = ({ new_tree_node }) => {
  return gqlService.knowledge.dragndrop(new_tree_node);
};

const updateTreeNode = ({ new_tree_node }) => {
  return gqlService.knowledge.updateTreeNode(new_tree_node);
};

export { updateContent, updateTreeNode, dragndrop };
