<template>
  <div class="text-right">
    <base-dropdown>
      <base-button
        slot="title"
        type="secondary"
        class="dropdown ml-2 mb-1"
        id="dropdown-button"
      >
        <img class="options_selection" src="~assets/options_selection.png" />
      </base-button>
      <div>
        <!-- RENAME -->
        <div
          class="dropdown-item"
          style="display: inline-block"
          @click="$emit('edit-label')"
        >
          <h5 class="text-overflow m-0">
            <font-awesome-icon :icon="['fad', 'edit']" class="edit-node-icon" />
            <small class="ml-2">Renommer</small>
          </h5>
        </div>

        <!-- DELETE CASE -->
        <div
          class="dropdown-item"
          style="display: inline-block"
          @click="deleteItem"
        >
          <h5 v-if="nodeType === 'Folder'" class="text-overflow m-0">
            <font-awesome-icon
              :icon="['fad', 'trash']"
              class="edit-node-icon text-red"
            />
            <small class="ml-2">Supprimer</small>
          </h5>
          <h5 v-else class="text-overflow m-0">
            <font-awesome-icon
              :icon="['fad', 'archive']"
              class="edit-node-icon text-red"
            />
            <small class="ml-2">Archiver</small>
          </h5>
        </div>
      </div>
    </base-dropdown>
  </div>
</template>

<script>
export default {
  name: 'folder-item-options',
  components: {},
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    nodeType: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
  },
  methods: {
    editLabel() {
      this.$emit('edit-label');
    },
    deleteItem() {
      this.$emit('delete-item', { id: this.nodeId, type: this.nodeType });
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  padding: 0px;
}

#dropdown-button {
  visibility: hidden;
  border-width: 0px;
  box-shadow: none;
  background-color: transparent;
  margin: auto;
  margin-top: 4px;
}

.dropdown-item {
  span {
    margin-left: 6px;
  }
  p {
    margin: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
  &:hover {
    background-color: rgba($blue-mayday, 0.05);
    cursor: pointer;
  }
}

.options_selection {
  max-width: 15px;
}

.dropdown {
  padding: 0px;
}

.item-row:hover {
  &:hover {
    #dropdown-button {
      visibility: visible;
    }
  }
}

.edit-node-icon {
  color: #adb5bd;
  max-width: 14px;
}
</style>
