<template>
  <div>
    <div class="card-header">
      <h1 class="display-3 text-center">🍾 Vous êtes fin prêt 🍾</h1>
      <hr class="my-4" />
        <h2 class="subtitle text-center">
          N'hésitez pas à nous contacter si vous avez le moindre problème lors de la mise en place de Mayday ou de son utilisation
          <br> 
          <br> 
          <br> 
          <div class="row align-items-center">
            <div class="container">
              <base-button type="secondary" @click="toggle">☎️</base-button>
              <div>
                <br>
                <p >‭+33 6 18 95 07 00‬</p>
              </div>
            </div>
          </div>
          <br>
          <br>
            <div class="container"></div>
            <base-button type="danger"><router-link to="/dashboard" style="color:white;">Retour au dashboard</router-link></base-button>
        </h2>
  </div>
    
  </div>
</template>

<script>
  import gql from 'graphql-tag';
  
  const user_query = gql `
    query GetUser($userId: ID!) {
        user: getUser(id: $userId) {
            username,
            jobtitle
        }
    }
  `;

  export default {
    data() {
      return {
        user: {},
        isOpen: false
      }
    },
    apollo: {
      user: {
        query: user_query,
        variables : {
            userId: 1,
        }
      }
    },
    methods: {
      toggle () {
        this.isOpen = !this.isOpen
      }
      
    }
  }
</script>

<style scoped>
</style>