var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row justify-content-around mb-3"},[_c('div',{staticClass:"col-4"},[_c('button',{class:[
          'contribution-button',
          _vm.type === 'Article' ? 'contribution-button--selected' : '',
        ],on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.updateType('Article')}}},[_c('img',{staticClass:"col-9",attrs:{"src":require("assets/article-icon-v2.1.svg")}}),_c('h4',{staticClass:"px-2 pt-2 text-small"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.actions.add-article'))+" ")])])]),_c('div',{staticClass:"col-4"},[_c('button',{class:[
          'contribution-button',
          _vm.type === 'Diagnostic' ? 'contribution-button--selected' : '',
        ],on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.updateType('Diagnostic')}}},[_c('img',{staticClass:"col-9",attrs:{"src":require("assets/diag-icon-v2.1.svg")}}),_c('h4',{staticClass:"px-2 pt-2 text-small"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.actions.add-diagnostic'))+" ")])])])]),_c('div',{staticClass:"row justify-content-center my-4"},[_c('el-input',{staticClass:"col-10",attrs:{"placeholder":_vm.$t('knowledge.actions.name-content')},on:{"input":_vm.sendUpdateLabelEvent},model:{value:(_vm.label),callback:function ($$v) {_vm.label=$$v},expression:"label"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }