<template>
  <section class="content__result">
    <ul
      id="content__list"
      :class="['content__list', contentListShow ? 'show' : '']"
    >
      <ContentListItem
        v-for="content in contents"
        :key="content.id"
        :content="content"
        :selected-contents="selectedContents"
        :item-type="itemType"
        :showBulkActions="showBulkActions"
        @focus-content="$emit('focus-content', $event)"
        @archive="$emit('archive', $event)"
        @update-selected-contents="$emit('update-selected-contents', $event)"
        @restore="$emit('restore', $event)"
        @delete="$emit('delete', $event)"
        @update-content="$emit('update-content', $event)"
        @update-content-settings="
          $emit(
            'update-content-settings',
            Object.assign({}, $event, { id: content.id }),
          )
        "
        @open-clone-to-target-modal="
          $emit('open-clone-contents-to-target-modal', [content])
        "
        @duplicate-in-same-location="
          $emit('duplicate-in-same-location', {
            type: 'content',
            contents: [content],
          })
        "
      />
    </ul>
    <span
      v-if="!moreOptions && contents.length > 20"
      class="text-muted display-more"
      @click="displayMoreSolutions"
      >{{ $t('knowledge.product.display-more') }}</span
    >
  </section>
</template>

<script>
import ContentListItem from '@/components/ListItems/ContentListItem.vue';

export default {
  name: 'content-list',
  props: {
    contents: {
      type: Array,
      required: true,
    },
    selectedContents: {
      type: Array,
      required: true,
    },
    contentListShow: Boolean,
    moreOptions: Boolean,
    itemType: {
      type: String,
      default: 'content',
    },
    showBulkActions: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ContentListItem,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    displayMoreSolutions() {
      this.$emit('display-more-solutions');
    },
  },
};
</script>

<style lang="scss" scoped>
.content__list {
  padding-inline-start: 0px;
}

.display-more {
  cursor: pointer;
  &:hover {
    color: $blue-mayday !important;
  }
}
</style>
