var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-popover',{attrs:{"placement":"top","width":200,"trigger":"click"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('button',{staticClass:"color-selector-button",style:({ borderColor: _vm.color }),attrs:{"slot":"trigger"},slot:"trigger"},[_c('font-awesome-icon',{staticClass:"label__icon",style:({ color: _vm.color }),attrs:{"icon":['fad', _vm.icon]}})],1)]},proxy:true}])},[_c('h6',{staticClass:"text-muted text-uppercase"},[_vm._v("Général")]),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"class"},_vm._l((_vm.icons.General.iconCodes),function(i){return _c('span',{key:i[1]},[_c('font-awesome-icon',{class:[
            i[1] === _vm.icon ? 'icon-selector--selected' : '',
            'm-1',
            'p-1',
            'icon-selector',
          ],attrs:{"icon":i,"size":"2x"},on:{"click":function($event){return _vm.$emit('update-icon', i[1])}}})],1)}),0)]),_c('hr',{staticClass:"my-2"}),_c('h6',{staticClass:"text-muted text-uppercase"},[_vm._v("Couleur")]),_c('v-swatches',{attrs:{"row-length":"5","swatch-style":{
      width: '20px',
      height: '20px',
      borderRadius: '40px',
      margin: '5px',
    },"swatches":_vm.swatches,"inline":true},on:{"input":function($event){return _vm.$emit('update-color', $event)}},model:{value:(_vm.newColor),callback:function ($$v) {_vm.newColor=$$v},expression:"newColor"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }