<template>
  <div>
    <div
      class="d-flex flex-row align-items-center settings-header sticky-top justify-content-between"
    >
      <div class="d-flex flex-row align-items-center">
        <div class="settings-header-icon-wrapper mr-2">
          <font-awesome-icon
            class="settings-header-icon"
            :icon="['fas', 'cog']"
          />
        </div>
        <el-divider direction="vertical" class="menu-divider mr-3"></el-divider>
        <span class="uppercase">{{ $t('settings.title') }}</span>
      </div>
    </div>
    <div class="settings-container">
      <SettingsMenu :data="menuData" class="settings-menu" />

      <router-view
        class="settings-view"
        :items="menuData"
        :success="success"
      ></router-view>
    </div>
  </div>
</template>
<script>
import SettingsMenu from 'components/Settings/SettingsMenu';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SettingsLayout',
  components: {
    SettingsMenu,
  },
  async created() {
    this.success = await this.getCompanyDetails();
  },
  data() {
    return {
      success: true,
    };
  },
  computed: {
    hasWorkflowActivated() {
      return this.hasCompanyPreferenceWithValue('WORKFLOW_ACTIVATED');
    },
    hasBrainFeatures() {
      return this.hasCompanyPreferenceLike('BRAIN_');
    },
    menuData() {
      return [
        {
          title: 'settings-layout.menu-data.general',
          icon: ['fad', 'sliders-v'],
          children: [
            {
              title: 'settings-layout.menu-data.kb-manage',
              name: 'generalSettings',
              hash: '#knowledges',
              icon: ['fas', 'folder'],
              component: 'KnowledgeManageSettings',
            },
            {
              title: 'settings-layout.menu-data.alerts-notifications',
              name: 'generalSettings',
              hash: '#alerts',
              icon: ['fas', 'bell'],
              component: 'AlertsSettings',
            },
            {
              title: 'settings-layout.menu-data.collaborative-hub',
              name: 'generalSettings',
              hash: '#collaborative-hub',
              icon: ['fas', 'sliders-v-square'],
              component: 'HubSettings',
            },
          ],
        },
        {
          title: 'settings-layout.menu-data.integrations',
          icon: ['fad', 'layer-group'],
          children: [
            {
              title: 'settings-layout.menu-data.integration-list',
              name: 'integrationSettings',
              hash: '#catalog',
              icon: ['fas', 'chart-network'],
              component: 'IntegrationSettingsPage',
            },
          ],
        },
        {
          title: 'settings-layout.menu-data.plugins.title',
          icon: ['fad', 'puzzle-piece'],
          children: [
            {
              title: 'settings-layout.menu-data.plugins.plugins-catalog',
              name: 'pluginsCatalog',
              hash: '#plugin-catalog',
              component: 'PluginCatalog',
              icon: ['fad', 'plug'],
              props: {
                children: [
                  {
                    title:
                      'settings-layout.menu-data.plugins.company-favorites',
                    name: 'pluginsSetting',
                    hash: '#company-favorites',

                    component: 'PluginSetting',
                    props: {
                      label:
                        'settings-layout.menu-data.plugins.company-favorites.title',
                      description:
                        'settings-layout.menu-data.plugins.company-favorites.description',
                      activeText:
                        'settings-layout.menu-data.plugins.company-favorites.active-text',
                      disableText:
                        'settings-layout.menu-data.plugins.company-favorites.disable-text',
                      companyPreference: 'ALLOW_COMPANY_FAVORITES',
                      getter: 'companyAllowCompanyFavoritesPreference',
                      pluginType: 'CompanyFavoritesEntity',
                    },
                  },
                  {
                    title: 'settings-layout.menu-data.plugins.embedded-iframe',
                    name: 'pluginsSetting',
                    hash: '#embedded-iframe',

                    component: 'PluginSetting',
                    props: {
                      label:
                        'settings-layout.menu-data.plugins.embedded-iframe.title',
                      description:
                        'settings-layout.menu-data.plugins.embedded-iframe.description',
                      shortDescription:
                        'settings-layout.menu-data.plugins.embedded-iframe.short-description',
                      activeText:
                        'settings-layout.menu-data.plugins.embedded-iframe.active-text',
                      disableText:
                        'settings-layout.menu-data.plugins.embedded-iframe.disable-text',
                      companyPreference: 'ALLOW_EMBEDDED_IFRAME',
                      getter: 'companyAllowEmbeddedIframePreference',
                      advancedSettingsConfig: {
                        integrations: true,
                        title: true,
                        icon: true,
                        description: true,
                        url: true,
                      },
                      icon: ['fal', 'code'],
                      pluginType: 'EmbeddedIframeEntity',
                    },
                  },
                  {
                    title: 'settings-layout.menu-data.plugins.trending-content',
                    name: 'pluginsSetting',
                    hash: '#trending-content',

                    component: 'PluginSetting',
                    props: {
                      label:
                        'settings-layout.menu-data.plugins.trending-content.title',
                      description:
                        'settings-layout.menu-data.plugins.trending-content.description',
                      shortDescription:
                        'settings-layout.menu-data.plugins.trending-content.short-description',
                      activeText:
                        'settings-layout.menu-data.plugins.trending-content.active-text',
                      disableText:
                        'settings-layout.menu-data.plugins.trending-content.disable-text',
                      companyPreference: 'ALLOW_TRENDING_CONTENT',
                      getter: 'companyAllowTrendingContentPreference',
                      advancedSettingsConfig: {
                        integrations: true,
                      },
                      icon: ['fal', 'chart-line'],
                      pluginType: 'TrendingContentEntity',
                    },
                  },
                  {
                    title: 'settings-layout.menu-data.plugins.new-content',
                    name: 'pluginsSetting',
                    hash: '#new-content',

                    component: 'PluginSetting',
                    props: {
                      label:
                        'settings-layout.menu-data.plugins.new-content.title',
                      description:
                        'settings-layout.menu-data.plugins.new-content.description',
                      shortDescription:
                        'settings-layout.menu-data.plugins.new-content.short-description',
                      activeText:
                        'settings-layout.menu-data.plugins.new-content.active-text',
                      disableText:
                        'settings-layout.menu-data.plugins.new-content.disable-text',
                      companyPreference: 'DESK_NEW_CONTENTS_PLUGIN',
                      getter: 'companyAllowNewContentPreference',
                      advancedSettingsConfig: {
                        integrations: true,
                      },
                      icon: ['fal', 'gift'],
                      pluginType: 'NewContentEntity',
                    },
                  },
                  {
                    title: 'settings-layout.menu-data.plugins.smart-actions',
                    name: 'pluginsSetting',
                    hash: '#smart-actions',

                    component: 'PluginSetting',
                    props: {
                      label:
                        'settings-layout.menu-data.plugins.smart-actions.title',
                      description:
                        'settings-layout.menu-data.plugins.smart-actions.description',
                      shortDescription:
                        'settings-layout.menu-data.plugins.smart-actions.short-description',
                      activeText:
                        'settings-layout.menu-data.plugins.smart-actions.active-text',
                      disableText:
                        'settings-layout.menu-data.plugins.smart-actions.disable-text',
                      companyPreference: 'ALLOW_SMART_ACTIONS',
                      getter: 'companyAllowSmartActionsPreference',
                      advancedSettingsConfig: {
                        integrations: true,
                        title: true,
                        color: true,
                        subtypes: {
                          RedirectionSmartAction: {
                            label:
                              'settings-layout.menu-data.plugins.smart-actions.subtypes.redirection',
                            value: 'RedirectionSmartAction',
                            properties: {
                              url: true,
                            },
                          },
                        },
                        allowMultiple: true,
                      },
                      icon: ['fal', 'external-link'],
                      pluginType: 'SmartActionEntity',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          title: 'settings-layout.menu-data.permissions',
          icon: ['fad', 'unlock'],
          children: [
            {
              title: 'settings-layout.menu-data.users',
              name: 'usersSettings',
              hash: `#users`,
              icon: ['fas', 'users'],
              component: 'UsersSettings',
            },
            {
              title: 'settings-layout.menu-data.invitations',
              name: 'usersSettings',
              hash: `#pending-users`,
              icon: ['fas', 'paper-plane'],
              component: 'UsersSettings',
              props: {
                pending: true,
              },
            },
            {
              title: 'settings-layout.menu-data.groups',
              name: 'permissionsSettings',
              hash: `#groups`,
              icon: ['fas', 'user-friends'],
              component: 'GroupsSettingsMain',
            },
            {
              title: 'settings-layout.menu-data.roles',
              name: 'permissionsSettings',
              hash: `#roles`,
              icon: ['fas', 'medal'],
              component: 'RolesSettingsMain',
            },
            {
              title: 'settings-layout.menu-data.labels',
              name: 'permissionsSettings',
              hash: `#labels`,
              icon: ['fas', 'tags'],
              component: 'LabelCategorySettings',
            },
          ],
        },
        {
          ...(this.hasBrainFeatures
            ? {
                title: 'settings-layout.menu-data.ai',
                children: [
                  {
                    title: 'settings-layout.menu-data.ai-settings.title',
                    name: 'aiSettings',
                    hash: `#ai-settings`,
                    icon: ['fal', 'brain'],
                    component: 'ConfigureAi',
                  },
                ],
              }
            : {}),
        },
        {
          title: 'settings-layout.menu-data.search',
          children: [
            {
              title: 'settings-layout.menu-data.synonyms',
              name: 'searchSettings',
              hash: '#search-settings',
              icon: ['fas', 'search'],
              component: 'SearchSettings',
              props: {
                children: [
                  {
                    title: 'settings-layout.menu-data.synonyms',
                    name: 'searchSettings',
                    icon: ['fas', 'search'],
                    component: 'SynonymsSettingsCard',
                  },
                  {
                    title: 'settings-layout.menu-data.exclusive-search.title',
                    name: 'searchSettings',
                    hash: '#exclusive-search',
                    icon: ['fas', 'union'],
                    component: 'SearchLogicSelect',
                    props: {
                      label: 'settings-layout.menu-data.exclusive-search.title',
                      description:
                        'settings-layout.menu-data.exclusive-search.description',
                      options: [
                        {
                          value: 'OR',
                          label:
                            'settings-layout.menu-data.exclusive-search.or',
                          description:
                            'settings-layout.menu-data.exclusive-search.or-description',
                          subDescription:
                            'settings-layout.menu-data.exclusive-search.or-sub-description',
                        },
                        {
                          value: 'AND',
                          label:
                            'settings-layout.menu-data.exclusive-search.and',
                          description:
                            'settings-layout.menu-data.exclusive-search.and-description',
                          subDescription:
                            'settings-layout.menu-data.exclusive-search.and-sub-description',
                        },
                      ],
                      placeholder:
                        'settings-layout.menu-data.exclusive-search.placeholder',
                      companyPreference: 'SEARCH_CATEGORIES_AND_FILTER',
                      getter: 'companyApplyExclusiveSearchPreference',
                    },
                  },
                  {
                    title: 'settings-layout.menu-data.search-boost.title',
                    name: 'searchSettings',
                    hash: '#search-boost',

                    component: 'PluginSetting',
                    props: {
                      label: 'settings-layout.menu-data.search-boost.title',
                      description:
                        'settings-layout.menu-data.search-boost.description',
                      activeText:
                        'settings-layout.menu-data.search-boost.active-text',
                      disableText:
                        'settings-layout.menu-data.search-boost.disable-text',
                      companyPreference: 'SEARCH_USER_CONSULTATION_BOOST',
                      getter: 'companySearchUserBoostPreference',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          title: 'settings-layout.menu-data.parametric-knowledge',
          icon: ['fad', 'project-diagram'],
          children: [
            ...(this.isParametric
              ? [
                  {
                    title: 'settings-layout.menu-data.global-parameters',
                    name: 'knowledgesSettings',
                    hash: '#global',
                    icon: ['fas', 'server'],
                    component: 'GlobalParameters',
                  },
                ]
              : []),
            {
              title: 'settings-layout.menu-data.content-parameters',
              name: 'knowledgesSettings',
              hash: '#contents',
              icon: ['fas', 'tags'],
              component: 'ContentParameters',
            },
            ...(this.hasWorkflowActivated
              ? [
                  {
                    title: 'settings-layout.menu-data.context-variables',
                    name: 'knowledgesSettings',
                    hash: '#context-variables',
                    icon: ['fas', 'code'],
                    component: 'ContextVariables',
                  },
                ]
              : []),
          ],
        },
        ...(this.hasAcademy
          ? [
              {
                title: 'settings-layout.menu-data.academy',
                icon: ['fad', 'graduation-cap'],
                children: [
                  {
                    title: 'settings-layout.menu-data.module-labels',
                    name: 'academySettings',
                    hash: '#module-labels',
                    icon: ['fas', 'graduation-cap'],
                    component: 'ModuleLabelsSettings',
                  },
                ],
              },
            ]
          : []),
        {
          title: 'settings-layout.menu-data.security',
          icon: ['fad', 'cog'],
          children: [
            {
              title: 'settings-layout.menu-data.authentication.title',
              name: 'securitySettings',
              hash: '#security',
              icon: ['fas', 'fingerprint'],
              component: 'AuthenticationSettings',
              props: {
                children: [
                  {
                    title:
                      'settings-layout.menu-data.authentication.ip-restriction.title',
                    name: 'IpRestriction',
                    i18nPre: 'ip-restriction',
                    icon: ['fas', 'fingerprint'],
                    component: 'IpRestrictionSettings',
                  },
                ],
              },
            },
          ],
        },
        {
          title: 'settings-layout.menu-data.mayday-account',
          icon: ['fad', 'cog'],
          children: [
            {
              title: 'settings-layout.menu-data.general-settings',
              name: 'companySettings',
              hash: '#company',
              icon: ['fas', 'cogs'],
              component: 'CompanySettings',
            },
          ],
        },
      ];
    },
    hasAcademy() {
      return this.companyAllowAcademyPreference && this.userAccess.academy;
    },
    ...mapGetters([
      'processedCompanyLanguages',
      'isParametric',
      'companyAllowCompanyFavoritesPreference',
      'companyAllowTrendingContentPreference',
      'companyAllowNewContentPreference',
      'companyAllowSmartActionsPreference',
      'companyAllowToastSoundPreference',
      'companyAllowAcademyPreference',
      'hasCompanyPreferenceWithValue',
      'hasCompanyPreferenceLike',
      'userAccess',
    ]),
  },
  methods: {
    ...mapActions('adminModule', ['getCompanyDetails']),
  },
};
</script>

<style lang="scss" scoped>
.settings-header {
  background-color: white;
  padding: 15px;
  border-bottom: 2px solid $grey-4-mayday;
  max-width: 100%;
  min-width: calc(100% - 60px);
  max-height: 60px;
  position: fixed;
  z-index: 10 !important;
}

.settings-menu {
  width: 20%;
  margin-left: 60px;
}

.settings-header-icon-wrapper {
  width: 32px;
  height: 32px;
  background-color: rgba($blue-mayday, 0.1);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-header-icon {
  width: 14px;
  height: 16px;
  color: $blue-mayday;
  font-size: 16px;
}

.settings-container {
  position: relative;
}

.settings-view {
  top: 96px;
  position: absolute;
  left: 25%;
  width: 50%;
}

.uppercase {
  text-transform: uppercase;
}
</style>
