<template>
  <div
    class="d-flex align-items-center tag"
    :class="{ 'selected-tag': selected }"
    @click.stop="$emit('focus', tag)"
  >
    <img v-if="tag.iconUrl" :src="tag.iconUrl" class="tag-icon" />
    <font-awesome-icon
      :icon="tag.icon"
      class="tag-icon"
      :style="iconColor"
      v-else-if="tag.icon"
    />
    <span :class="{ 'ml-2': (tag.label && tag.icon) || tag.iconUrl }">{{
      computedLabel
    }}</span>
  </div>
</template>

<script>
export default {
  name: 'tag',
  props: {
    tag: {
      type: Object,
      default: () => {
        return {
          id: null,
          value: 'content',
          type: 'type',
          label: 'knowledge.search-admin.content',
          icon: ['fad', 'barcode-scan'],
        };
      },
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconColor() {
      return {
        color: this.tag.color,
      };
    },
    computedLabel() {
      return this.tag.needTrad ? this.$t(this.tag.label) : this.tag.label;
    },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  font-size: 14px;
  padding: 5px 8px;
  line-height: 0.93;
  color: $grey-8-mayday;
  background: $grey-1-mayday;
  border-radius: 2px;
  border: 1px solid transparent;
  cursor: pointer;
}
.selected-tag {
  border: 1px solid $blue-mayday;
  background: rgba($blue-mayday, 0.1);
}

.tag-icon {
  font-size: 13px;
  width: 13px;
  height: 13px;
}
</style>
