<template>
  <div>
    <div class="row justify-content-around mb-3">
      <div class="col-4">
        <button
          :class="[
            'contribution-button',
            type === 'Article' ? 'contribution-button--selected' : '',
          ]"
          @click.prevent.stop="updateType('Article')"
        >
          <img src="~assets/article-icon-v2.1.svg" class="col-9" />
          <h4 class="px-2 pt-2 text-small">
            {{ $t('knowledge.actions.add-article') }}
          </h4>
        </button>
      </div>

      <div class="col-4">
        <button
          :class="[
            'contribution-button',
            type === 'Diagnostic' ? 'contribution-button--selected' : '',
          ]"
          @click.prevent.stop="updateType('Diagnostic')"
        >
          <img src="~assets/diag-icon-v2.1.svg" class="col-9" />
          <h4 class="px-2 pt-2 text-small">
            {{ $t('knowledge.actions.add-diagnostic') }}
          </h4>
        </button>
      </div>
    </div>

    <div class="row justify-content-center my-4">
      <el-input
        class="col-10"
        :placeholder="$t('knowledge.actions.name-content')"
        v-model="label"
        @input="sendUpdateLabelEvent"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'admin-contribution-edition',
  data() {
    return {
      type: '',
      label: '',
    };
  },
  methods: {
    sendUpdateLabelEvent(label) {
      this.$emit('update-main-content', { type: this.type, label });
    },
    updateType(type) {
      this.type = type;
      this.sendUpdateLabelEvent(this.label);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-small {
  color: $grey-5-mayday;
  font-size: 0.9em;
}

.grey-six {
  color: $grey-6-mayday;
}

.section {
  background-color: $grey-1-mayday;
  border-radius: 4px;

  :deep() .el-cascader {
    width: 90%;
  }
}

.contribution-button {
  background-color: transparent;
  border: 1px solid transparent;
  padding: 16px 0px;
  &:hover {
    background-color: $grey-1-mayday;
    border: 1px solid $grey-3-mayday;
    border-radius: 4px;
  }
}

.contribution-button--selected {
  background-color: $grey-1-mayday;
  border: 1px solid $grey-3-mayday;
  border-radius: 4px;
  h4 {
    color: $blue-mayday;
  }
}
</style>
