<template>
  <div class="content-list-item-label px-2" :style="customStyle">
    <font-awesome-icon
      class="content-label-icon mr-1"
      :icon="['fad', label.icon]"
    />
    <div class="label-name">
      {{ label.labelName }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'content-list-item-full-label',
  props: {
    label: {
      type: Object,
      default: () => {
        return { labelName: '', icon: '', color: '' };
      },
    },
  },
  computed: {
    customStyle() {
      return {
        '--label-color': this.label.color,
        '--label-background-color': `${this.label.color}1a`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.content-list-item-label {
  color: var(--label-color);
  background-color: var(--label-background-color);
  border-radius: 2px;
  display: flex;
  align-items: center;
  font-size: 10px;
  height: 17px;
}
.content-label-icon {
  width: 10px;
  height: 10px;
}
.label-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
