<template>
  <section class="collections-navigator-breadcrumb">
    <span class="mr-3 cursor">
      <font-awesome-icon
        :icon="['fad', iconValue]"
        :style="iconStyle"
        @click="$emit('click:home')"
      />
    </span>
    <span
      v-for="(parent, idx) in parents"
      :key="parent.id"
      class="mr-3 cursor parent-breadcrumb"
      :class="{ 'last-parent': idx === parents.length - 1 }"
      @click="$emit('click:parent', idx)"
    >
      <font-awesome-icon
        :icon="['fa', 'chevron-right']"
        class="chevron-icon mr-3"
      />
      {{ parent.label }}
    </span>
  </section>
</template>

<script>
export default {
  name: 'collections-navigator-breadcrumb',
  props: {
    parents: Array,
  },
  computed: {
    collection() {
      return this.parents[0];
    },
    iconValue() {
      return this.collection.icon && this.collection.icon.value
        ? this.collection.icon.value
        : 'home-lg-alt';
    },
    iconStyle() {
      return this.collection.icon && this.collection.icon.color
        ? { color: this.collection.icon.color }
        : {};
    },
  },
};
</script>

<style lang="scss" scoped>
.collections-navigator-breadcrumb {
  font-size: 16px;
  color: $grey-6-mayday;
  .chevron-icon {
    font-size: 12px;
  }
  .last-parent {
    color: $blue-mayday;
  }
}

.parent-breadcrumb {
  &:hover {
    text-decoration: underline;
  }
}
</style>
