<template>
  <div class="overview-container">
    <RolePermissionsOverviewItem
      v-for="permissionItem in filteredPermissionItems"
      :key="permissionItem.title"
      :permission-item="permissionItem"
      :permissions="permissions"
      :company-preferences="companyPreferences"
    >
    </RolePermissionsOverviewItem>
  </div>
</template>

<script>
import RolePermissionsOverviewItem from './RolePermissionsOverviewItem';
import { PERMISSION_ITEMS } from './utils';
import { mapGetters } from 'vuex';

export default {
  name: 'role-permissions-overview',
  components: {
    RolePermissionsOverviewItem,
  },
  props: {
    permissions: {
      type: Array,
      default: () => [],
    },
    companyPreferences: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      permissionItems: PERMISSION_ITEMS,
    };
  },
  computed: {
    ...mapGetters(['hasCompanyPreferenceWithValue']),
    filteredPermissionItems() {
      return this.permissionItems
        .filter((item) => {
          if (!item.companyPreferences) return true;
          return item.companyPreferences.every((preference) =>
            this.hasCompanyPreferenceWithValue(preference),
          );
        })
        .map((item) => {
          return {
            ...item,
            subItems: item.subItems.filter((subItem) => {
              if (!subItem.companyPreferences) return true;
              return subItem.companyPreferences.every((preference) =>
                this.hasCompanyPreferenceWithValue(preference),
              );
            }),
          };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.overview-container {
  display: flex;
  width: 100%;
  gap: 12px;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
}
</style>
