<template>
  <div class="product container" v-if="product">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-10 col-sm-12 product-search">
        <WebParametricSearchWrapper
          class="position-relative"
          :product-parameters="{
            ...product.parameters,
            knowledge: product.knowledge,
          }"
          :symptom="$route.query.symptom"
        />
      </div>
    </div>
    <div class="row mt-4" v-if="product.id">
      <ProductViewerAside
        class="d-none d-md-flex"
        :content-title="product.reference"
        :slide-show="showSlideShow"
        :images="product.pictures"
        :image-index="imageIndex"
        :routes="tableOfContents"
        @update-image-index="updateImageIndex"
        @open-carousel="showSlideShow = true"
      />
      <main class="col-lg-8 col-md-10 col-sm-12 mt-2 mb-8">
        <ProductViewerHeader
          :product="product"
          :enriched-parameters="enrichedParameters"
          class="mb-3"
        />
        <div
          class="d-lg-none justify-content-center mb-2 characteristic-button"
        >
          <base-button
            v-if="hasPictures"
            type="primary"
            size="md"
            class="mb-2 col-12 d-flex align-items-center justify-content-center"
            style="font-size: 12px !important"
            @click="showSlideShow = true"
          >
            <font-awesome-icon
              class="mr-2 icon-picture"
              :icon="['fas', 'images']"
            />
            VOIR LES PHOTOS</base-button
          >
          <div class="d-flex justify-content-between">
            <div
              class="pr-1"
              :class="[hasFacets ? 'w-50' : 'w-100']"
              v-if="hasParameters"
            >
              <base-button
                type="primary"
                size="md"
                class="w-100"
                style="font-size: 12px !important"
                @click="modals.parameters = true"
                >PARAMÈTRES</base-button
              >
            </div>
            <div class="pl-1 w-50" v-if="hasFacets">
              <base-button
                type="primary"
                size="md"
                class="w-100"
                style="font-size: 12px !important"
                @click="modals.characteristics = true"
                >CARACTÉRISTIQUES</base-button
              >
            </div>
          </div>
          <base-button
            v-if="showAgoraDocumentation"
            type="primary"
            size="md"
            class="mt-2 col-12 d-flex align-items-center justify-content-center"
            style="font-size: 12px !important"
            @click="modals.documentation = true"
          >
            <font-awesome-icon
              class="mr-2 icon-picture"
              :icon="['fas', 'info-circle']"
            />
            AGORA</base-button
          >
        </div>
        <MaydayWebProductNotificationsCard
          class="is-anchor"
          id="notifications"
          v-if="product.notifications.results.length"
          :notifications="product.notifications.results"
        />
        <MaydayWebProductAttachedFilesCard
          class="is-anchor"
          id="attached-files"
          v-loading="areAttachedFilesLoading"
          :contents-by-labels="contentsByLabels"
          @focus-content="focusContent"
        />

        <MaydayWebProductSolutionsCard
          class="is-anchor"
          id="solutions"
          :solutions="refinedResolutions"
          :solutions-count="refinedResolutionsCount"
          :product-id="product.id"
          :knowledge="product.knowledge"
          :is-solution-loading="isSolutionLoading"
          :is-partial="isPartial"
          :parameters="parameters"
          :resolution-search="contentSearch"
          @update-resolution-search="updateResolutionSearch"
          @reset-search="contentSearch = ''"
          @update-solutions="loadSolutions"
          @focus-content="focusContent"
        />
      </main>
      <aside class="col-lg-2 px-0 side-pannel d-none d-lg-block">
        <div class="sticky-top">
          <ProductParameterSideBar
            v-if="hasParameters"
            :enriched-parameters="enrichedParameters"
          />
          <ProductCharacteristicSideBar
            v-if="hasFacets"
            :enriched-characteristics="product.facets"
          />
          <ProductAgoraDocumentationSideBar
            v-if="showAgoraDocumentation"
            :documentations="agoraDocumentation"
            :error="agoraDocumentationError"
            @click:retry="loadProductDocumentation(true)"
          />
        </div>
      </aside>
    </div>

    <CarouselZoomerModal
      v-if="showSlideShow && hasPictures"
      :content-title="product.reference"
      :open="showSlideShow"
      :image-index="imageIndex"
      :images="product.pictures"
      @close="showSlideShow = false"
      @update-image-index="updateImageIndex"
    />

    <ParametersModal
      v-if="modals.parameters"
      :enriched-parameters="product.enrichedParameters"
      :display="modals.parameters"
      @close="modals.parameters = false"
    />
    <CharacteristicsModal
      v-if="modals.characteristics"
      :enriched-characteristics="product.facets"
      :display="modals.characteristics"
      @close="modals.characteristics = false"
    />
    <ProductAgoraDocumentationModal
      v-if="modals.documentation && showAgoraDocumentation"
      :documentations="agoraDocumentation"
      :error="agoraDocumentationError"
      :display="modals.documentation"
      @click:retry="loadProductDocumentation(true)"
      @close="modals.documentation = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import WebParametricSearchWrapper from '@/components/SuperSearch/WebParametricSearchWrapper';
import ProductViewerAside from './ProductViewerAside';
import ProductViewerHeader from './ProductViewerHeader';
import ProductParameterSideBar from './ProductParameterSideBar';
import CarouselZoomerModal from './CarouselZoomerModal';
import ParametersModal from './ParametersModal';
import CharacteristicsModal from './CharacteristicsModal';
import ProductCharacteristicSideBar from './ProductCharacteristicSideBar';
import MaydayWebProductNotificationsCard from './MaydayWebProductNotificationsCard';
import MaydayWebProductAttachedFilesCard from './MaydayWebProductAttachedFilesCard';
import MaydayWebProductSolutionsCard from './MaydayWebProductSolutionsCard/MaydayWebProductSolutionsCard';
import ProductAgoraDocumentationSideBar from './ProductAgoraDocumentationSideBar.vue';
import ProductAgoraDocumentationModal from './ProductAgoraDocumentationModal.vue';

export default {
  name: 'mayday-web-product-viewer',
  components: {
    WebParametricSearchWrapper,
    ProductViewerAside,
    ProductViewerHeader,
    ProductParameterSideBar,
    ProductCharacteristicSideBar,
    MaydayWebProductNotificationsCard,
    MaydayWebProductAttachedFilesCard,
    MaydayWebProductSolutionsCard,
    CarouselZoomerModal,
    ParametersModal,
    CharacteristicsModal,
    ProductAgoraDocumentationSideBar,
    ProductAgoraDocumentationModal,
  },
  computed: {
    hasFacets() {
      return this.product.facets && this.product.facets.length;
    },
    hasParameters() {
      return this.parameters && this.parameters.length;
    },
    hasPictures() {
      return this.product.pictures ? this.product.pictures.length > 0 : false;
    },
    enrichedParameters() {
      if (!this.uniqueIds.length) return this.product.enrichedParameters;

      const newEnrichedParameters = [...this.product.enrichedParameters];
      const codicIndex = newEnrichedParameters.findIndex(
        ({ key }) => key === 'codic',
      );

      newEnrichedParameters[codicIndex].values = this.uniqueIds;
      return newEnrichedParameters;
    },
    uniqueIds() {
      if (!this.product.uniqueIds) return [];
      return Object.keys(this.product.uniqueIds).map((uniqueId) => ({
        id: uniqueId,
        label: `${uniqueId.toUpperCase()} : ${
          this.product.uniqueIds[uniqueId]
        }`,
      }));
    },
    showAgoraDocumentation() {
      return this.agoraDocumentation || this.agoraDocumentationError;
    },
    ...mapGetters('webModule', {
      product: 'product',
      productLoading: 'productLoading',
      parameters: 'parameters',
      isPartial: 'isPartial',
      refinedResolutions: 'resolutions',
      refinedResolutionsCount: 'resolutionsCount',
      isSolutionLoading: 'resolutionsLoading',
      refinedAttachedResolutions: 'attachedResolutions',
      refinedAttachedResolutionsCount: 'attachedResolutionsCount',
      areAttachedFilesLoading: 'attachedResolutionsLoading',
      contentsByLabels: 'attachedResolutionsByLabels',
      tableOfContents: 'tableOfContents',
    }),
    ...mapGetters(['hasCompanyPreferenceWithValue']),
  },
  async beforeCreate() {
    const product = await this.$store.dispatch(
      'webModule/loadProduct',
      this.$route,
    );

    if (product) {
      this.storeEvent();
      this.$emit('update-default-knowledge', product.knowledge);
      this.$emit('update-default-parameters', product.parameters);
      this.$emit('update-default-external-id', product.external_id);
      this.$emit('change-route-type', 'Product');
      this.$emit('update-has-prictures', this.hasPictures);
    }
  },
  async created() {
    this.setLoadingMessage();
    const { sessionId } = this.$route.query;
    this.updateSession(sessionId ? sessionId : null);
    await this.loadProductDocumentation();
  },
  data() {
    return {
      modals: {
        slideShow: false,
        parameters: false,
        characteristics: false,
        documentation: false,
      },
      imageIndex: 0,
      loadingMessage: null,
      showSlideShow: false,
      showGallery: false,
      contentSearch: '',
      agoraDocumentation: null,
      agoraDocumentationError: null,
    };
  },
  mounted() {
    this.$root.$on('open-product-viewer-carousel', () => {
      this.showSlideShow = true;
    });
  },
  methods: {
    async loadProductData(skipLogEvent = false) {
      const product = await this.loadProduct(this.$route);

      if (product) {
        if (!skipLogEvent) {
          //to avoid duplicate call when route query is replaced and correlation_id removed in WebParametricSearchWrapper
          this.storeEvent();
        }
        this.$emit('update-default-knowledge', product.knowledge);
        this.$emit('update-default-parameters', product.parameters);
        this.$emit('update-default-external-id', product.external_id);
        this.$emit('change-route-type', 'Product');
        this.$emit('update-has-prictures', this.hasPictures);
      }
    },
    setLoadingMessage() {
      if (this.productLoading) {
        this.loadingMessage = this.$message({
          duration: 0,
          dangerouslyUseHTMLString: true,
          iconClass: 'display: none',
          showClose: true,
          message: `<i class="fas fa-spinner fa-spin" style="margin-right: 1em;"></i> Fiche produit en cours de chargement ...`,
        });
      }
    },
    focusContent(id) {
      const path = this.$route.path.includes('web-parametric')
        ? '/web-parametric/content/'
        : '/knowledge/content/';

      this.$router.push({
        path: path + id,
      });
    },
    updateImageIndex(imageIndex) {
      this.imageIndex = imageIndex;
    },
    async loadProductDocumentation(retry = false) {
      if (
        !this.product ||
        !this.product.external_id ||
        !this.hasCompanyPreferenceWithValue('CUSTOM_AGORA_DOCUMENTATION', '1')
      )
        return;

      if (!retry) {
        this.agoraDocumentation = null;
        this.agoraDocumentationError = null;
      }
      try {
        const agora = await this.getProductDocumentation(
          this.product.external_id,
        );
        this.agoraDocumentation = agora.documents;
        this.agoraDocumentationError = null;
      } catch (err) {
        if (err.response) {
          const { status } = err.response;
          if (status !== 404) this.agoraDocumentationError = { status, retry };
          else this.agoraDocumentationError = null;
        } else {
          this.agoraDocumentationError = {
            status: 500,
            retry,
          };
        }
      }
    },

    // SOLUTIONS METHODS
    async updateResolutionSearch({ caseId, search }) {
      await this.loadSolutions({ caseId, search });
      this.contentSearch = search;
    },
    storeEvent() {
      if (this.$route.path.includes('web-parametric')) {
        this.$services.events.product.read(
          this.product,
          window.location.href,
          'WEB_PARAMETRIC',
        );
      }
    },
    ...mapActions(['updateSession']),
    ...mapActions('webModule', [
      'loadProductAttachedResolutions',
      'loadSolutions',
      'loadProduct',
      'getProductDocumentation',
    ]),
  },
  watch: {
    productLoading() {
      this.loadingMessage.close();
    },
    $route(route) {
      const skipLogEvent = route.query.correlation_id;
      this.loadProductData(skipLogEvent);
      this.setLoadingMessage();
    },
    async product() {
      await this.loadProductDocumentation();
    },
  },
};
</script>
<style lang="scss" scoped>
.product-search {
  margin-top: -64px;
  position: relative;
}

.characteristic-button {
  button {
    font-size: 14px;
  }
}

.icon-picture {
  font-size: 16px;
}
</style>
