var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"thread__input",class:{
      'thread__input-focused': _vm.isFocus,
      'thread__input-disabled': _vm.disabled,
    }},[_c('div',{staticClass:"thread__input-info"},[_c('div',{staticClass:"thread__input-username"},[_c('ThreadAvatar',{attrs:{"username":_vm.username}})],1),_c('div',{staticClass:"thread__input-editor"},[_c('MessageEditor',{ref:"message-editor",attrs:{"autofocus":_vm.autofocus,"body":_vm.newBody,"editable":true},on:{"input":function($event){_vm.newBody = $event},"focus":function($event){_vm.isFocus = true},"blur":function($event){_vm.isFocus = false}}})],1)]),_c('div',{staticClass:"thread__input-actions"},[(_vm.hasOptions)?_c('div',{staticClass:"thread__input-actions-buttons",class:{
          'thread__input-actions-buttons-disabled': _vm.disabled,
        }},[(_vm.options.attachment)?_c('AddAttachment',{staticClass:"thread__input-actions-button",class:{
            'thread__input-actions-button-disabled':
              _vm.attachmentLoading || _vm.fileInputDisable,
          },attrs:{"attachment-loading":_vm.attachmentLoading,"disabled":_vm.fileInputDisable},on:{"disable-click":function($event){return _vm.showDisableFileUpload(
              'task-view.task-focus-modal.upload-error.disable-file-upload',
            )},"new-attachment":_vm.handleFileChange}},[_c('font-awesome-icon',{attrs:{"slot":"slot-button","icon":['fal', 'paperclip']},slot:"slot-button"})],1):_vm._e(),(_vm.options.mention)?_c('button',{staticClass:"thread__input-actions-button",on:{"click":_vm.insertMentions}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'at']}})],1):_vm._e(),(_vm.options.emoji)?_c('button',{staticClass:"thread__input-actions-button",on:{"click":_vm.insertEmoji}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'smile']}})],1):_vm._e()],1):_vm._e(),_c('button',{staticClass:"thread__input-actions-send",class:{
          'thread__input-actions-send-disabled': _vm.disabledSend,
        },attrs:{"disabled":_vm.disabledSend},on:{"click":_vm.handleSendMessage}},[(_vm.loading)?_c('font-awesome-icon',{attrs:{"icon":['fal', 'spinner-third'],"spin":""}}):(_vm.success)?_c('font-awesome-icon',{attrs:{"icon":['fal', 'check']}}):_c('font-awesome-icon',{attrs:{"icon":['fal', 'arrow-up']}})],1)])]),_c('AttachmentList',{attrs:{"attachments":_vm.attachments,"is-input":"","display-disable-file-upload":_vm.displayDisableFileUpload,"error-text":_vm.errorText,"display-header":false,"attachment-width":'33.33333%'},on:{"delete-attachment":_vm.handleDeleteAttachment,"disable-click":function($event){return _vm.showDisableFileUpload()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }