<template>
  <div>
    <BaseCascaderMulti
      :label="ancestorLabel"
      :options="level"
      :disable-select="disableSelect"
      @next="handleChoice($event)"
      @prev="handlePrev()"
      @select-target="handleTarget($event)"
      @close="$emit('close')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseCascaderMulti from '@/components/BaseCascaderMulti';
export default {
  name: 'content-parameters-cascader-wrapper',
  components: {
    BaseCascaderMulti,
  },
  props: {
    sourceType: {
      type: String,
      required: true,
    },
    knowledge: {
      type: String,
      required: false,
    },
  },
  computed: {
    disableSelect() {
      return this.sourceType === 'contents' && this.levels.length === 1;
    },
    level() {
      return this.levels[this.levels.length - 1];
    },
    ancestorLabel() {
      return this.ancestorsLabel[this.ancestorsLabel.length - 1];
    },
    ...mapGetters('knowledgeModule', [
      'focusKnowledgeRoots',
      'focusKnowledgeValue',
    ]),
  },
  data() {
    return {
      levels: [],
      ancestorsLabel: [],
    };
  },
  methods: {
    async initCascader() {
      // change root if knowledge changed
      const roots =
        !this.knowledge || this.knowledge === this.focusKnowledgeValue
          ? this.focusKnowledgeRoots
          : await this.$services.cases.getRoots(this.knowledge);
      const levelOptions = roots.map((root) => {
        return {
          isFirst: true,
          id: root.id,
          caseId: root.id,
          knowledge: root.knowledge,
          label: root.label,
          hasChildren: this.rootHasChildren(root),
          ancestors: [],
        };
      });
      this.levels.push(levelOptions);
    },
    handleTarget(options) {
      this.$emit('clone-to-target', options);
    },
    rootHasChildren(root) {
      if (root.hasChildren !== undefined) {
        return root.hasChildren;
      }
      if (root.children) {
        return root.children.length > 0;
      }
      return false;
    },
    async handleChoice(option) {
      if (option.hasChildren) {
        this.ancestorsLabel.push(option.label);
        const collection = await this.$services.cases.getById(option.caseId);
        const levelOptions = collection.children.map((child) => {
          return {
            isFirst: false,
            id: child.id,
            caseId: child.id,
            knowledge: child.knowledge,
            label: child.label,
            hasChildren: child.hasChildren,
            ancestors: this.ancestorsLabel,
          };
        });
        this.levels.push(levelOptions);
      }
    },
    handlePrev() {
      this.levels.pop();
      this.ancestorsLabel.pop();
    },
  },
  created() {
    this.initCascader();
  },
  watch: {
    knowledge(newValue, oldValue) {
      if (newValue === oldValue) return;

      this.levels = [];
      this.ancestorsLabel = [];
      this.initCascader();
    },
  },
};
</script>
