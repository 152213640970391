<template>
  <section>
    <draggable
      v-model="draggableList"
      draggable=".list-item"
      :disabled="disableDraggable"
      @end="handleDraggable"
    >
      <div
        class="list-item"
        v-for="item in draggableList"
        :key="`${item.id}-${item.type}`"
      >
        <ContentListItem
          v-if="item.type"
          :content="item"
          :selected-contents="selectedContents"
          :show-bulk-actions="showBulkActions"
          @focus-content="$emit('focus-content', $event)"
          @archive="archiveContent"
          @update-selected-contents="$emit('update-selected-contents', $event)"
          @update-content="$emit('update-content', $event)"
          @update-content-verification="
            $emit('update-content-verification', $event)
          "
          @open-clone-to-target-modal="
            $emit('open-clone-contents-to-target-modal', [item])
          "
          @duplicate-in-same-location="
            $emit('duplicate-in-same-location', {
              type: 'content',
              contents: [item],
            })
          "
        />
        <CaseListItem
          v-else
          :collection="item"
          :extra-padding="showBulkActions"
          @open-case="$emit('focus-child', $event)"
          @delete="$emit('delete-case', $event)"
          @update-case="$emit('update-case', $event)"
          @open-clone-to-target-modal="
            $emit('open-clone-case-to-target-modal', item)
          "
          @duplicate-in-same-location="
            $emit('duplicate-in-same-location', {
              type: 'case',
              case: item,
            })
          "
        />
      </div>
    </draggable>
    <slot></slot>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ContentListItem from '@/components/ListItems/ContentListItem';
import CaseListItem from '@/components/ListItems/CaseListItem';

export default {
  components: {
    ContentListItem,
    CaseListItem,
  },
  props: {
    contents: {
      type: Array,
      required: true,
    },
    focusCollection: {
      type: Object,
      required: true,
    },
    focusKnowledgeValue: {
      type: String,
      required: true,
    },
    caseParameters: {
      type: Array,
      required: true,
    },
    showBulkActions: {
      type: Boolean,
      default: false,
    },
    selectedContents: {
      type: Array,
      required: true,
    },
    disableDraggable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      draggableList: [...this.contents],
    };
  },
  computed: {
    ...mapGetters('knowledgeModule', ['getCaseOrderedChildren']),
  },
  methods: {
    handleDraggable() {
      const newChildrenOrder = this.draggableList.map(({ id, type }) => {
        return {
          id,
          entityType: type ? 'Content' : 'Case',
        };
      });
      this.$emit('update-case', {
        caseId: this.focusCollection.id,
        label: this.focusCollection.label,
        body: this.focusCollection.body,
        childrenOrder: newChildrenOrder,
      });
    },
    focusItem(item, itemType) {
      switch (itemType) {
        case 'Article':
        case 'Diagnostic':
          this.$emit('focus-content', item);
          break;
        default:
          this.$emit('focus-child', item);
          break;
      }
    },

    async archiveContent({ contentIds, softDeleted } = {}) {
      if (!contentIds || !contentIds.length) return;

      this.loadingMessage = this.$message({
        duration: 0,
        dangerouslyUseHTMLString: true,
        iconClass: 'display: none',
        showClose: true,
        message: `<i class="fas fa-spinner fa-spin" style="margin-right: 1em;"></i> Archivage des contenus sélectionnés en cours ...`,
      });
      await this.bulkArchiveContents({ contentIds, softDeleted: softDeleted });
      this.loadingMessage.close();
    },
    ...mapActions('knowledgeModule', ['bulkArchiveContents']),
  },
  watch: {
    contents() {
      this.draggableList = [...this.contents];
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  display: unset !important;
}
</style>
