<template>
  <div><ConceptsSearch></ConceptsSearch></div>
</template>

<script>
import ConceptsSearch from '../components/Concepts/ConceptsSearch.vue';

export default {
  name: 'Concept',
  components: { ConceptsSearch },
};
</script>

<style scoped lang="scss"></style>
