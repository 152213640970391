<template>
  <settings-dropdown>
    <template slot="title">
      <settings-dropdown-capitalized-column
        :text="''"
        :is-editing="true"
        can-edit
        icon="tags"
        color="#0081F9"
        @cancel="$emit('cancel-category')"
        @update-text="handleUpdateCategory"
      >
      </settings-dropdown-capitalized-column>
    </template>
  </settings-dropdown>
</template>

<script>
import SettingsDropdown from '@/components/Settings/SettingsDropdown';
import SettingsDropdownCapitalizedColumn from '@/components/Settings/SettingsDropdownCapitalizedColumn';

export default {
  name: 'NewModuleLabelsSettings',
  components: {
    SettingsDropdown,
    SettingsDropdownCapitalizedColumn,
  },
  methods: {
    handleUpdateCategory(title) {
      if (!title && !title.trim()) return;

      return this.$emit('create-category', {
        title: title.trim(),
        icon: {
          type: 'fa',
          value: 'tags',
          color: '#0081F9',
        },
      });
    },
  },
};
</script>
