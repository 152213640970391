<template>
  <div class="delete-modal">
    <modal :show.sync="localDisplay" modal-classes="modal-lg">
      <div
        v-if="loading"
        class="d-flex align-items-center justify-content-center loader"
      >
        <div class="row mt-5">
          <div class="col-12 text-center">
            <font-awesome-icon icon="spinner" spin class="fa-4x mb-4 yellow" />
          </div>
          <div class="col-12 text-center my-2">
            <h3 class="text-muted">{{ `${randomQuote.quote}` }}</h3>
          </div>
        </div>
      </div>
      <iframe
        ref="mayday-editor-onpen-link-modal"
        v-show="!loading"
        :src="src"
        frameborder="0"
        allowfullscreen="true"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        width="750px"
        height="500px"
      ></iframe>
    </modal>
  </div>
</template>
<script>
import generateRandomQuote from '@/utils/generateRandomQuote';

export default {
  name: 'MaydayEditorOpenLinkModal',
  props: {
    display: Boolean,
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      localDisplay: this.display,
      randomQuote: {},
    };
  },
  created() {
    this.randomQuote = generateRandomQuote(this.$i18n.locale);
    window.addEventListener('unload', function () {
      this.localDisplay = false;
    });
  },
  mounted() {
    this.iframeLoadingHandler();
  },
  methods: {
    iframeLoadingHandler() {
      const iframe = this.$refs['mayday-editor-onpen-link-modal'];
      this.loading = true;
      const handleLoad = () => {
        this.loading = false;
      };
      iframe.addEventListener('load', handleLoad, true);
    },
  },
  beforeDestroy() {
    this.$emit('close');
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>
