<template>
  <div
    :ref="'newCategory'"
    v-if="displayNewCategoryForm"
    class="row justify-content-start mr-0 mt-2 pt-2 align-items-center"
  >
    <ItemEditor
      :reference="'newCategoryInput'"
      :value="newCategory"
      :placeholder="'settings.card-settings-options.content-parameters.give-category-name'"
      @edit="addCategory"
      @cancel="resetNewCategoryForm"
      class="category"
    />
  </div>
</template>

<script>
import ItemEditor from './ItemEditor';

export default {
  name: 'new-category',
  components: {
    ItemEditor,
  },
  props: {
    displayNewCategoryForm: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      newCategory: '',
    };
  },
  methods: {
    addCategory(event) {
      this.$emit('add-category', event);
    },
    resetNewCategoryForm() {
      this.$emit('reset-new-category-form');
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  text-transform: uppercase;
  color: $grey-5-mayday;
}

.category {
  :deep() .item-editor__input--category {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: inherit;
    text-transform: none;
    text-align: left;
  }
}
</style>
