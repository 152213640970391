<template>
  <el-card class="analytics-card-container" v-bind="props" v-on="$listeners">
    <template v-if="title || $scopedSlots['header-menu']" v-slot:header>
      <div class="analytics-card-header-wrapper">
        <AnalyticsTitle
          v-if="showTitle"
          :title="title"
          :tooltip-content="tooltipContent"
        >
          <template v-slot:tooltip-content="{ content }">
            <slot name="tooltip-content" :content="content"></slot>
          </template>
        </AnalyticsTitle>
        <slot name="header-menu"></slot>
      </div>
    </template>
    <template v-slot:default>
      <div v-loading="loading" class="analytics-card-body-container">
        <slot></slot>
      </div>
    </template>
  </el-card>
</template>

<script>
import AnalyticsTitle from './AnalyticsTitle';

export default {
  inheritAttrs: false,

  props: {
    title: {
      type: String,
      default: null,
    },
    tooltipContent: {
      type: String,
      default: () => '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    AnalyticsTitle,
  },

  computed: {
    props() {
      // eslint-disable-next-line no-unused-vars
      const { header, ...attrs } = this.$attrs;

      return attrs;
    },
    showTitle() {
      return this.title && this.title.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-card-container {
  :deep() .el-card__header,
  :deep() .el-card__body {
    padding: unset;
  }
  .analytics-card-body-container {
    position: relative;
    min-height: 200px;

    :deep() canvas {
      padding: 16px;
    }
  }

  .analytics-card-header-wrapper {
    padding: 0px 15px;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .analytics-card-header {
      margin-left: 23px;
      flex-basis: 70%;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 55px;
      letter-spacing: 0.02em;
    }
  }
}
</style>
