import { render, staticRenderFns } from "./CommonNode.vue?vue&type=template&id=7220ba21&scoped=true&"
import script from "./CommonNode.vue?vue&type=script&lang=js&"
export * from "./CommonNode.vue?vue&type=script&lang=js&"
import style0 from "./CommonNode.vue?vue&type=style&index=0&id=7220ba21&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7220ba21",
  null
  
)

export default component.exports