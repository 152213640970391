export default function getTranslation(object, hints=null) {
    if (hints && hints.length > 0) {
        for (let hint of hints) {
            for (let lang of Object.keys(object.translations)) {
                if (lang.startsWith(hint)) {
                    return object.translations[lang];
                }
            }
        }
    }
    const navigatorLanguage = navigator.language.toLowerCase()
    if (object.translations[navigatorLanguage]) {
        return object.translations[navigatorLanguage]
    }
    if (object.translations[object.defaultLanguage]) {
        return object.translations[object.defaultLanguage]
    }
    for (let lang of Object.keys(object.translations)) {
        return object.translations[lang];
    }
}