<template>
  <modal
    :show.sync="localOpen"
    modal-classes="modal-dialog-centered modal align-items-center"
  >
    <h4 slot="header" class="modal-title" id="modal-title-default">
      {{ $t('knowledge.product.parameters') }}
    </h4>
    <div v-for="family in enrichedParameters" :key="family.key">
      <el-divider class="my-1"></el-divider>
      <h6 class="row align-items-center mx-0 mb-0">
        <font-awesome-icon
          class="collapse-parameter-icon mr-1"
          :icon="['fad', getHierarchyByValue(family.key).icon]"
        />
        {{ getHierarchyByValue(family.key).label }}
      </h6>
      <div
        class="d-flex align-items-center"
        v-for="value in family.values"
        :key="value.id"
      >
        <small class="collapse-parameter-label" :key="value.id">
          {{ value.label }}
        </small>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import ProductMixin from 'mixins/KnowledgeMixin/ProductMixin';

export default {
  name: 'parameters-modal',
  mixins: [ProductMixin],
  props: {
    display: {
      type: Boolean,
      required: true,
    },
    enrichedParameters: {
      type: Array,
      required: true,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      localOpen: this.display,
    };
  },
  methods: {
    getHierarchyByValue(value) {
      const hierarchy = this.mxFlatHierarchy.find((h) => h.value === value);
      return hierarchy || { label: '', icon: 'books' };
    },
  },
  watch: {
    localOpen() {
      if (!this.localOpen) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-main {
  max-height: 60vh;
  overflow: auto;
}

:deep() .modal-header {
  align-items: center;
}

:deep() .modal-body {
  padding-top: 0px;
}

.trigger-slideshow {
  cursor: pointer;
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.carousel-slide {
  cursor: crosshair;
}
</style>
