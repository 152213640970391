<template>
  <section class="collapse-section">
    <product-viewer-item-card
      card-title="Alertes et notifications"
      :is-collapsable="false"
    >
      <div class="notifications-wrapper pl-2">
        <base-alert
          v-for="notification in notifications"
          :key="notification.id"
          ref="alert"
          :type="notification.urgent ? 'danger' : 'warning'"
          class="text-left mb-2 d-flex align-items-between product-alert"
          :icon="
            notification.urgent ? 'fa fa-exclamation-circle' : 'fa fa-bell'
          "
        >
          <template v-slot:text>
            <div
              class="d-flex inner-text-alert justify-content-between align-items-center"
            >
              <div class="alert-body">
                <strong>{{ notification.title }}</strong>
                <MaydayFeedbackEditor
                  class="product__alerts__item__details mb-0"
                  :message="notification.description"
                  :editable="false"
                />
              </div>
            </div>
          </template>
        </base-alert>
      </div>
    </product-viewer-item-card>
  </section>
</template>

<script>
import BaseAlert from '@/components/BaseAlert.vue';
import ProductViewerItemCard from './ProductViewerItemCard';
import MaydayFeedbackEditor from '@/components/Editors/MaydayFeedbackEditor';

export default {
  name: 'mayday-web-product-notifications-card',
  props: {
    notifications: {
      type: Array,
    },
  },
  components: {
    BaseAlert,
    ProductViewerItemCard,
    MaydayFeedbackEditor,
  },
};
</script>

<style lang="scss" scoped>
.empty-state-alert {
  color: $grey-5-mayday;
}

.product-alert {
  :deep() .alert-inner--text {
    width: 100%;
  }
}

.product__alerts__item__details {
  :deep() * {
    margin-bottom: 0px;
  }
}

.tooltip-icon {
  cursor: pointer;
}

.notifications-wrapper {
  border-left: 4px solid $grey-2-mayday;
}
</style>
