export const getSafe = (value, path, defaultValue) => {
  return String(path)
    .split('.')
    .reduce((acc, v) => {
      try {
        acc = acc[v];
      } catch (e) {
        return defaultValue;
      }
      return acc;
    }, value);
};
