<template>
  <div class="action w-100">
    <div class="action__primary w-100">
      <div class="action__primary__name">{{ action.name }}</div>
      <div class="action__primary__cta" v-if="!!configuredAction">
        <font-awesome-icon
          :class="[loader.class, 'mr-2']"
          :icon="loader.icon"
          :spin="loader.spin"
          v-if="loader"
        />
        <button class="action__primary__cta__btn" @click="triggerAction()">
          {{ $t(`${i18n}.cta`) }}
        </button>
        <button
          class="action__primary__cta__btn grey-btn"
          @click="edit = !edit"
        >
          <font-awesome-icon :icon="['fad', 'pencil']" />
        </button>
      </div>
      <div class="action__primary__cta" v-else>
        <el-switch v-model="edit"></el-switch>
      </div>
    </div>
    <el-divider class="w-100 my-2" v-if="errMessage"></el-divider>
    <div class="action__secondary" v-if="errMessage">
      <div class="action__secondary__cta" @click="collapseErr = !collapseErr">
        {{ $t(`${i18n}.error`) }}
      </div>
      <div class="action__secondary__message px-2 py-2 my-1" v-if="collapseErr">
        {{ errMessage }}
      </div>
    </div>
    <el-divider class="w-100 my-2" v-if="edit"></el-divider>
    <ContentViewSettingsActionSettings
      v-if="edit"
      class="w-100"
      :action="action"
      :content-action="configuredAction"
      @save="handleSave"
      @delete="
        $emit('delete-action', {
          actionId: configuredAction.mongoId,
          contentId: content.id,
        })
      "
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ContentViewSettingsActionSettings from './ContentViewSettingsActionSettings.vue';

export default {
  name: 'content-view-settings-action',
  props: {
    content: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      i18n: 'knowledge.drawers.content-view-setting.settings.content-actions',
      errMessage: null,
      collapseErr: false,
      loading: false,
      icon: ['far', 'spinner'],
      status: '',
      edit: false,
    };
  },
  computed: {
    configuredAction() {
      return this.content.actions.find((a) => a.id === this.action._id);
    },
    loader() {
      if (this.loading)
        return { class: 'grey-5', icon: ['far', 'spinner'], spin: true };
      if (!this.status) return null;
      return this.status === 'success'
        ? { class: 'green-1', icon: ['fas', 'check-circle'] }
        : { class: 'red-1', icon: ['fas', 'exclamation-circle'] };
    },
  },
  methods: {
    resetActionStatus() {
      this.errMessage = null;
      this.collapseErr = false;
      this.status = null;
    },
    async triggerAction() {
      this.loading = true;
      this.resetActionStatus();
      try {
        const res = await this.triggerKnowledgeAction({
          contentId: this.content.id,
          actionId: this.configuredAction.id,
        });
        if (res.statusCode > 399) {
          this.errMessage = res.message;
          this.status = 'failure';
        } else {
          this.status = 'success';
        }
      } catch (err) {
        this.status = 'failure';
      } finally {
        this.loading = false;
      }
    },
    handleSave(newAction) {
      this.$emit('upsert-action', {
        contentId: this.content.id,
        actionId: newAction.mongoId,
        actionPayload: newAction.payload,
        type: newAction.id,
      });
    },
    handleDelete() {
      const { actions } = this.content;
      const newActions = actions.filter((el) => el.id !== this.action._id);
      this.$emit('update-actions', newActions);
    },
    ...mapActions('knowledgeModule', ['triggerKnowledgeAction']),
  },
  components: { ContentViewSettingsActionSettings },
  watch: {
    configuredAction() {
      this.edit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  background-color: $grey-1-mayday;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;

  &__primary {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__cta {
      display: flex;
      align-items: center;

      .green-1 {
        color: $green-mayday;
      }

      .red-1 {
        color: $red-mayday;
      }

      .grey-btn {
        cursor: pointer;
        color: $grey-5-mayday;
        background-color: transparent;
        &:hover {
          color: $blue-mayday;
        }
      }

      &__btn {
        border: none;
        background-color: $blue-mayday;
        color: white;
        border-radius: 4px;
      }
    }
  }

  &__secondary {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    &__cta {
      cursor: pointer;
      &:hover {
        color: $blue-mayday;
      }
    }

    &__message {
      font-style: italic;
      border-left: 1px solid black;
    }
  }
}
</style>
