<template>
  <div class="product__header col-12 pr-lg-0">
    <h1 class="col-12 product__title display-3 px-0 ph-0 mb-0">
      {{ product.reference }}
    </h1>
    <h2 class="col-12 product__subtitle px-0 ls-1">{{ parametersTitle }}</h2>
    <div class="d-flex align-items-center">
      <font-awesome-icon
        class="collapse-parameter-icon mr-1"
        :icon="['fad', getHierarchyByValue('codic').icon]"
      />
      <div
        class="pl-2"
        :class="{ 'border-codic': reference.values.length > 1 }"
      >
        <div
          v-for="value in reference.values"
          :key="value.id"
          class="d-flex flex-col gap-2 small"
        >
          <span :key="value.id">
            {{ value.label }}
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="hasDescription"
      class="justify-content-between align-items-start pt-2 descrition-section d-none d-lg-flex"
      @click="productDescriptionShow = !productDescriptionShow"
    >
      <p class="product__description section-title text-uppercase my-0">
        {{ $t('knowledge.product.description') }}
      </p>
      <button
        :class="[
          'product__button',
          productDescriptionShow ? null : 'product__button--collapsed',
        ]"
        :aria-expanded="productDescriptionShow ? 'true' : 'false'"
        aria-controls="productDescription"
      >
        <i
          :class="[
            'product__toogle-icon',
            'fa',
            productDescriptionShow
              ? 'fa-caret-square-down'
              : 'fa-caret-square-right',
          ]"
        ></i>
      </button>
    </div>
    <b-collapse
      v-model="productDescriptionShow"
      id="productDescription"
      class="product__manual__collapsable mt-2"
    >
      <ul class="mb-0 description-list">
        <li
          class="description-item"
          v-for="description in product.strengths"
          :key="description"
        >
          {{ description }}
        </li>
      </ul>
    </b-collapse>
  </div>
</template>

<script>
import ProductMixin from 'mixins/KnowledgeMixin/ProductMixin';

export default {
  name: 'product-viewer-header',
  mixins: [ProductMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
    enrichedParameters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      productDescriptionShow: false,
    };
  },
  computed: {
    reference() {
      const codic = this.enrichedParameters.filter(
        (parameter) => parameter.key === 'codic',
      );
      return codic[0] ? codic[0] : { values: [] };
    },
    parametersTitle() {
      const brand = this.product.enrichedParameters.find(
        (p) => p.key === 'brand',
      ).values;
      const family = this.product.enrichedParameters.find(
        (p) => p.key === 'family',
      ).values;
      return `${brand[0].label} | ${family[0].label}`;
    },
    hasDescription() {
      return this.product.strengths && this.product.strengths.length > 0;
    },
  },
  methods: {
    getHierarchyByValue(value) {
      const hierarchy = this.mxFlatHierarchy.find((h) => h.value === value);
      return hierarchy || { label: '', icon: 'books' };
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  color: $grey-6-mayday;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.description-list {
  padding-inline-start: 16px;
}

.description-item {
  font-size: 80%;
  list-style-type: circle;
}

@media only screen and (max-device-width: 768px) {
  .product__header {
    background-color: rgba($blue-mayday, 0.1);
    border-radius: 16px;
    border: none;
    text-align: center;
    padding: 16px;
    word-break: break-word;
  }
  .product__subtitle {
    padding: 8px;
  }
}

@media only screen and (min-device-width: 768px) {
  .product__header {
    border-left: 3px solid $grey-4-mayday;
    .collapse-parameter-icon {
      color: $blue-mayday;
    }
    .product__subtitle {
      font-size: 32px;
    }
  }
}

.product__subtitle {
  font-weight: 500;
  color: $grey-7-mayday;
}

.product__button {
  background-color: transparent;
  border: none;
  color: $blue-mayday;
}

.product__button--collapsed {
  color: $grey-5-mayday;
}

.product__manual__collapsable,
.product__manual__item-row {
  width: 100%;

  a .product__manual__link {
    margin-top: 2px;
    margin-bottom: 2px;
    width: 100%;
    color: $grey-9-mayday;

    &:hover {
      background-color: $grey-1-mayday;
      border-radius: 2px;
    }
  }
}

.border-codic {
  border-left: 1px solid $blue-mayday;
}
</style>
