<template>
  <div>
    <div class="d-flex align-items-center flex-row justify-content-between">
      <div>
        <span class="select-label">{{ $t(label) }}</span>
        <div class="select-description">{{ $t(description) }}</div>
        <div class="select-sub-description">{{ $t(subDescription) }}</div>
      </div>
      <el-select
        v-model="value"
        :placeholder="$t(placeholder)"
        @change="handleChange"
        class="selector"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="$t(item.label)"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'select-setting',
  data() {
    return {
      value: '',
    };
  },
  props: {
    label: {
      type: String,
      default: () => '',
    },
    companyPreference: {
      type: String,
      default: () => '',
    },
    getter: {
      type: String,
      default: () => '',
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.value = this[this.getter] && !!this[this.getter].type ? 'AND' : 'OR';
  },
  computed: {
    description() {
      const currentOption = this.options.find((op) => op.value === this.value);
      return currentOption.description;
    },
    subDescription() {
      const currentOption = this.options.find((op) => op.value === this.value);
      return currentOption.subDescription;
    },
    ...mapGetters(['companyApplyExclusiveSearchPreference']),
  },
  methods: {
    async handleChange(val) {
      this.$notify.info({
        title: this.$t(
          'settings-layout.menu-data.exclusive-search.toast-title',
        ),
        message: this.$t(
          'settings-layout.menu-data.exclusive-search.toast-description',
        ),
        showClose: true,
        duration: 5000,
        customClass: 'custom-notification custom-notification-info',
      });
      return val === 'AND'
        ? this.addCompanyPreferences({
            preferenceType: this.companyPreference,
            preferenceValue: '',
          })
        : this.deleteCompanyPreferences({
            preferenceIds: [this[this.getter].id],
          });
    },
    ...mapActions(['addCompanyPreferences', 'deleteCompanyPreferences']),
  },
};
</script>

<style lang="scss" scoped>
.select-label {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}
.select-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
.select-sub-description {
  font-size: 12px;
  color: $grey-7-mayday;
  font-style: italic;
}
.selector {
  :deep() .el-input__inner {
    height: 28px;
    width: 80px;
  }
  :deep() .el-input__suffix {
    height: 28px;
    display: flex;
    align-items: center;
  }
}
</style>
