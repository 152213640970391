<template>
  <div class="my-2 drawer-header d-flex">
    <div class="icon-wrapper" :class="iconClass">
      <img :src="icon" />
    </div>
    <div class="info mx-2">
      <div class="title">
        {{ content.label }}
      </div>
    </div>
  </div>
</template>

<script>
// IMAGES
const diagIcon = require('assets/diag-icon-v2.svg');
const articleIcon = require('assets/article-icon-v2.svg');

export default {
  name: 'content-view-settings-header',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    icon() {
      return this.content.type === 'Article' ? articleIcon : diagIcon;
    },
    iconClass() {
      return this.content.type === 'Article'
        ? 'icon-wrapper_article'
        : 'icon-wrapper_diag';
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer-header {
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 2px;
    padding: 4px;
    &_diag {
      border: 1px solid $blue-mayday;
      background-color: rgba($blue-mayday, 0.1);
    }

    &_article {
      border: 1px solid $yellow-mayday;
      background-color: rgba($yellow-mayday, 0.1);
    }

    img {
      width: 16px !important;
      height: 16px !important;
    }
  }
  .info {
    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 1.2;
    }
  }
}
</style>
