<template>
  <div class="row diag-main-row mx-0">
    <div class="col-lg-2 d-flex justify-content-center">
      <BuilderSideBar
        v-if="step.id && editable"
        type="diagnostic"
        :knowledge-id="focusKnowledge.id"
        :root-id="root.id"
        :node-id="step.id"
        :is-key-step="step.type === 'keyStep'"
        :is-last="step.path && step.path.length === 0"
        :content="step"
        :user-permissions="root.userPermissions"
        :is-published="root.published"
        :is-hidden="root.isHidden"
        :automations="automations"
        :new-automation-id="newAutomationId"
        :trigger-active="edit.message"
        :workflow-active="
          Boolean(step.workflowActions && step.workflowActions.length)
        "
        :is-template="isTemplate"
        :generating-summary="generatingSummary"
        @click:trigger="handleClickTrigger"
        @update-content="updateContent"
        @toggle-key-step="toggleKeyStep"
        @update-node="updateNode"
        @create-automation="$emit('create-automation', $event)"
        @update-automation="$emit('update-automation', $event)"
        @delete-automation="$emit('delete-automation', $event)"
        @add-notification="$emit('add-notification', $event)"
        @relocate-child="$emit('relocate-child', $event)"
        @generate-summary="$emit('generate-summary')"
        @generate-content="handleDisplayAiDrawer"
      />
    </div>

    <div class="diagnostic-builder-main col-lg-8 col-md-10 col-sm-12">
      <ParametricContentThreads
        v-if="!isTemplate && !isArchive && editable"
        :content-id="root.id"
      />

      <DiagnosticBuilderMain
        v-if="step.id"
        :root-id="root.id"
        :parents="parents"
        :node="step"
        :content="step"
        :editMessage="edit.message"
        :editable="isContentEditable"
        :template-id="templateId"
        :options="options"
        @focus-node="focusNode"
        @update-parents="updateParents"
        @add-child="$emit('add-child', $event)"
        @delete-child="$emit('delete-child', $event)"
        @relocate-child="$emit('relocate-child', $event)"
        @update-node="$emit('update-node', $event)"
        @unlink-node="$emit('unlink-node', $event)"
        @delayed-update-node="$emit('delayed-update-node', $event)"
        @flush-update-node="$emit('flush-update-node')"
        @delete-node="$emit('delete-node', $event)"
        @update-content="$emit('update-content', $event)"
        @update-children-order="$emit('update-children-order', $event)"
        @delayed-update-content="$emit('update-written-content', $event)"
        @flush-update-content="$emit('flush-update-content')"
        @generate-summary="$emit('generate-summary')"
        @local-update-content="$emit('local-update-content', $event)"
      />
      <SatisfactionActions
        v-if="!editable && step.id && !isArchive && !isTemplate"
        :content-id="step.id"
        :content="step"
        @open-feedback-modal="openFeedbackModal"
      />
    </div>
    <div class="col-lg-2 px-0 side-pannel" v-if="editable">
      <ParameterSideBar
        :content="root"
        v-if="$route.name.includes('knowledge') && !isTemplate"
      />
    </div>
    <ArticleBrainImportModal
      v-if="displayBrainImportModal"
      @close="displayBrainImportModal = false"
      @input="handleBrainImport"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import debounce from 'lodash.debounce';

import BuilderSideBar from '../ParametricBuilderSibeBar/BuilderSideBar';
import DiagnosticBuilderMain from './DiagnosticBuilderMain/DiagnosticBuilderMain';
import SatisfactionActions from '../SatisfactionActions';
import ParameterSideBar from '../ParametricParameterSideBar/ParameterSideBar';
import ParametricContentThreads from '../ParametricContentThreads/ParametricContentThreads';
import ArticleBrainImportModal from 'components/AI/ArticleBrainImportModal.vue';

const FROM_SOURCES = ['search', 'notification', 'flowy', 'backlink'];

export default {
  name: 'parametric-diagnostic-builder',
  components: {
    ArticleBrainImportModal,
    DiagnosticBuilderMain,
    BuilderSideBar,
    SatisfactionActions,
    ParameterSideBar,
    ParametricContentThreads,
  },
  props: {
    root: Object,
    step: Object,
    newAutomationId: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    isContentEditable: {
      type: Boolean,
      default: true,
    },
    isArchive: {
      type: Boolean,
      default: false,
    },
    templateId: {
      type: String,
      required: false,
    },
    options: Object,
    generatingSummary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit: {
        message: false,
        actions: false,
      },
      isLoading: false,
      displayModal: false,
      debounceUpdateContent: debounce(function (variables) {
        this.updateContent(variables);
      }, 2000),
      focusedContent: {},
      displayBrainImportModal: false,
    };
  },
  computed: {
    automations() {
      return this.step.automations;
    },
    attachedTreeNodes() {
      return [];
    },
    parentChildren() {
      return this.parents.reduce((acc, val) => {
        acc.push(...val.children);
        return acc;
      }, []);
    },
    isTemplate() {
      return !!this.templateId;
    },
    ...mapState('kbStore', {
      parents: (state) => state.diagPath,
    }),
    ...mapGetters(['isParametric']),
    ...mapGetters('knowledgeModule', ['focusKnowledge', 'editingLanguage']),
  },
  mounted() {
    this.$root.$on('step-restored', async () => {
      await this.loadStep();
      this.$root.$emit('content-restored');
    });
  },
  async created() {
    this.loadStep();
    this.edit.message = !!(this.step.actions && this.step.actions.length);
  },
  methods: {
    handleClickTrigger() {
      this.edit.message = !this.edit.message;
    },
    async loadStep() {
      const { contentId, stepId } = this.$route.params;
      const { from } = this.$route.query;

      if (!this.$route.params.stepId) {
        this.updateDiagPath([]);
        return;
      }
      let newContent = await this.$services.parametricContents.getWithAncestors(
        stepId,
        this.editingLanguage,
        contentId,
      );
      if (newContent.redirection) {
        await this.handleRedirection(newContent.redirection);
        return;
      }
      const automations =
        await this.$services.automations.getContentAutomations(stepId);
      newContent.automations = automations;
      const { ancestorsContent } = newContent;

      if (
        !this.cmpDiagPath(ancestorsContent, this.parents) ||
        FROM_SOURCES.includes(from)
      ) {
        this.updateDiagPath(ancestorsContent);
      }
      this.$emit('focus-node', newContent);
    },
    cmpDiagPath(ancestors, parents) {
      if (!ancestors || !parents || ancestors.length !== parents.length) {
        return false;
      }

      for (let i = 0; i < ancestors.length; i++) {
        if (ancestors[i].id !== parents[i].id) return false;
      }
      return true;
    },
    async handleRedirection(redirection) {
      const { entityId, entityType, isDeleted, lang } = redirection;
      if (isDeleted) return;

      await this.goToTranslatedEntity({
        entityId,
        entityType,
        lang,
        query: {
          from: 'redirection',
        },
      });
    },
    focusNode(contentId) {
      const redirectionStep = this.step.children.find(
        (c) => c.id === contentId && c.redirection,
      );
      const redirectionStepFromParents = this.parentChildren.find(
        (c) => c.id === contentId && c.redirection,
      );

      if (redirectionStep || redirectionStepFromParents)
        return this.handleRedirection(
          redirectionStep
            ? redirectionStep.redirection
            : redirectionStepFromParents.redirection,
        );
      this.$emit('focus-node', contentId);
      const formerStep = { ...this.step };
      this.updateDiagPath(this.parents.concat([formerStep]));
      this.updateRoute(contentId);
    },
    updateNode(payload) {
      this.$emit('update-node', {
        id: this.step.id,
        payload,
      });
    },
    updateContent(payload) {
      this.$emit('update-content', {
        id: this.step.id,
        payload,
      });
    },
    toggleKeyStep(type) {
      this.$emit('toggle-key-step', {
        id: this.step.id,
        ...type,
      });
    },
    updateParents(parents) {
      const content = parents.pop();
      this.$emit('focus-node', content.id);
      this.updateDiagPath(parents);
      this.updateRoute(content.id);
    },
    async updateRoute(contentId) {
      if (this.isArchive || this.isTemplate) return;

      const rootId = this.root.id;
      const isStep = contentId !== rootId;

      const payload = isStep
        ? { parentId: rootId, entityId: contentId, entityType: 'Step' }
        : { entityId: rootId, entityType: 'Content' };

      await this.goToTranslatedEntity(payload);
    },
    openFeedbackModal() {
      this.$emit('open-feedback-modal');
    },
    handleDisplayAiDrawer(payload) {
      const { target } = payload;

      if (target === 'brain-import') {
        this.displayBrainImportModal = true;
        return;
      }
    },
    handleBrainImport({ rawFiles, settings }) {
      this.$emit('brain-import', { importType: 'pdf', rawFiles, settings });
      this.displayBrainImportModal = false;
    },
    ...mapActions('knowledgeModule', ['goToTranslatedEntity']),
    ...mapActions('kbStore', ['updateDiagPath']),
  },
  watch: {
    async $route(to, from) {
      let newContent;

      if (FROM_SOURCES.includes(to.query.from)) {
        return this.loadStep();
      }

      if (to.params.stepId) {
        const { contentId, stepId, lang } = to.params;
        newContent = await this.$services.parametricContents.getWithAncestors(
          stepId,
          lang || this.editingLanguage,
          contentId,
        );
        const automations =
          await this.$services.automations.getContentAutomations(stepId);
        newContent.automations = automations;

        const { ancestorsContent } = newContent;

        if (from.params.stepId) {
          const { contentId: formerContentId } = from.params;
          const { contentId } = to.params;
          if (contentId === formerContentId) {
            const stepIdx = this.parents.findIndex((p) => p.id === stepId);
            if (stepIdx !== -1) {
              this.updateDiagPath(this.parents.slice(0, stepIdx));
            } else {
              // Fix breadcrumb when switch editingLanguage
              this.updateDiagPath(ancestorsContent);
            }
          } else {
            this.updateDiagPath(ancestorsContent);
          }
        }
        // In case we come from a redirection
        if (to.query.from === 'redirection') {
          this.updateDiagPath(ancestorsContent);
        }
      } else {
        newContent = this.root;
        this.updateDiagPath([]);
      }
      this.$emit('focus-node', newContent);
    },

    step: {
      handler(newVal, oldVal) {
        if (newVal.id === oldVal.id) return;

        this.edit.message = !!(newVal.actions && newVal.actions.length);
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy() {
    this.$emit('flush-update-content');
    this.$emit('flush-update-node');
  },
};
</script>

<style lang="scss" scoped>
.side-pannel {
  z-index: 0;
}

.diagnostic-message-editor-empty-state {
  width: 100%;
  &:hover {
    box-shadow: 0px 10px 30px #7090b026;
    cursor: pointer;
  }
}

.grey-icon-hover-red {
  color: $grey-4-mayday;
  &:hover {
    color: $red-mayday;
    cursor: pointer;
  }
}

// TRANSITION

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<style lang="scss" scoped>
.answer-editor-card {
  min-height: 250px;
}

.answer-editor-card {
  :deep() .card-body {
    padding-left: 5px;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 15px;
  }
}

.answer-editor-card {
  :deep() .card-header {
    padding: 15px;
  }
}

.diagnostic-editor-card {
  :deep() .card-body {
    padding: 0px;
  }
}

.diagnostic-editor-card {
  :deep() .card-header {
    padding: 15px;
  }
}

.title-input {
  font-size: 2.1875rem;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 8px;
}

.title-input:focus {
  outline: none;
  border: none;
}

.subject-input {
  font-size: 24px;
  line-height: 1.5;
  background-color: transparent;
  border: none;
}

.subject-input:focus {
  outline: none;
}

.add-step-button {
  border: 1px solid lightgrey;
  border-radius: 2px;
  color: #adb5bd;
}

.add-step-button:hover {
  color: white;
}

.add-step-button:hover img {
  filter: invert(99%) sepia(0%) saturate(2%) hue-rotate(201deg) brightness(104%)
    contrast(100%);
}

.add-step-btn-icon {
  max-width: 15px;
  filter: invert(85%) sepia(12%) saturate(189%) hue-rotate(169deg)
    brightness(84%) contrast(88%);
  margin-right: 15px;
  margin-bottom: 2px;
}

.add-step-input {
  letter-spacing: 0.025em;
  display: block;
  width: 190px;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
  color: #adb5bd;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.add-step-input::placeholder {
  color: lightgrey;
}
.add-step-input:focus {
  outline: none;
  border-color: $blue-mayday;
  color: $blue-mayday;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  will-change: transform;
}

.modify-step {
  max-width: 20px;
  cursor: pointer;
  padding-right: 2px;
}

.modify-step:hover {
  border-radius: 3px;
  background-color: #e9ecef;
}
.diag-steps {
  display: block;
}

.diag-title:hover .edit-title {
  visibility: visible;
}

.edit-title {
  visibility: hidden;
  margin-top: auto;
  margin-bottom: auto;
}

.diagnostic-builder-main {
  margin-bottom: 40vh;
}
</style>
