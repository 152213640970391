<template>
  <div
    :class="['list-item-question', { clickable }]"
    @click.prevent.stop="clickable ? $emit('trigger-ask', question) : {}"
  >
    <font-awesome-icon :icon="['fal', 'level-up']" class="fa-rotate-90 mr-2" />
    <div class="title ml-2">{{ question }}</div>
  </div>
</template>

<script>
export default {
  name: 'list-item-question',
  props: {
    question: {
      type: String,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.list-item-question {
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba($purple-5-mayday, 0.1);
  color: $purple-5-mayday;
  display: flex;
  align-items: center;
  justify-content: left;
  height: fit-content;
  width: fit-content;
  border-radius: 4px;
  .title {
    font-size: 14px;
  }
}
.list-item-question.clickable:hover {
  cursor: pointer;
  background-color: $purple-5-mayday;
  color: white;
}
</style>
