<template>
  <div class="content__list__item-row">
    <div
      class="content__result__link d-flex align-items-center justify-content-between pl-2 full-width"
    >
      <div :style="itemWidth" class="d-flex align-items-center">
        <div class="pr-2" v-if="showBulkActions">
          <el-checkbox
            v-if="content.type !== 'Step'"
            v-model="checked"
            class="mb-0 d-flex checkbox"
          ></el-checkbox>
          <div v-else class="check-space"></div>
        </div>
        <div
          class="d-flex align-items-start col pl-0"
          @click.prevent="$emit('open-content', content)"
        >
          <img
            src="~assets/article-icon-v2.svg"
            v-if="content.type === 'Article'"
            class="content-icon mr-2"
          />
          <img
            src="~assets/diag_icon.svg"
            v-else-if="content.type === 'Diagnostic'"
            class="content-icon mr-2"
          />
          <font-awesome-icon
            v-else-if="content.type === 'Step'"
            :icon="['fad', 'level-up']"
            class="content-icon blue fa-rotate-90 mr-2"
          />
          <font-awesome-icon
            v-else-if="content.type === 'keyStep'"
            :icon="['fad', 'key']"
            class="content-icon blue mr-2"
          />
          <el-tooltip
            v-if="content.isHidden"
            placement="top"
            effect="light"
            :content="$t('knowledge.search-admin.hidden-tooltip')"
            ><div class="is-hidden-sticker"></div
          ></el-tooltip>
          <div class="min-width-0 overflow w-100">
            <div class="label-row">
              <div class="content-label-span" v-html="label"></div>
              <ExplainPromptPopover
                :explain="content.explain"
                :searchLanguage="searchLanguage"
                :visible="popoverToDisplay === content.id"
                class="explain-prompt"
                @display-popover="$emit('display-explain-popover', content.id)"
                @close-popover="$emit('display-explain-popover', '')"
              />
            </div>
            <breadcrumb class="content-breadcrumb" :items="breadcrumbItems">
              <ProductParameters
                v-if="content.denormalizedParameters"
                :parameters="content.denormalizedParameters"
              />
            </breadcrumb>
            <div
              class="d-flex flex-wrap align-items-center"
              v-if="content.labels && content.labels.length"
            >
              <ContentListItemFullLabelWrapper
                :labels="content.labels"
                :labelSearchedIds="labelSearchedIds"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="content-label d-flex justify-content-end">
        <CommonNodeStatus
          v-if="!isReadOnly"
          :content="content"
          :isNode="false"
          @update-content="updateStatus"
          @update-content-verification="updateContentVerification"
          class="d-flex flex-row align-items-center"
        />
        <ContentViewSettingModal
          v-if="displayContentViewParametersModal"
          :open="displayContentViewParametersModal"
          :content="content"
          :contentTitle="content.contentLabel"
          @update-content-view-setting="updateContentSettings"
          @close="displayContentViewParametersModal = false"
        />
        <DangerDeleteModal
          v-if="displayDeleteModal"
          :display="displayDeleteModal"
          type="archive"
          to-delete-name="SELECTED-CONTENTS"
          @choice="bulkDelete"
          @close="displayDeleteModal = false"
        />
        <base-dropdown class="nav-item" position="right" v-if="!isReadOnly">
          <font-awesome-icon
            slot="title"
            :icon="['fas', 'ellipsis-h']"
            class="options-selection ml-1 mr-2"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <span v-if="itemType === 'content'">
            <a class="dropdown-item cursor" @click="archive">{{
              $t('knowledge.search-admin.archive')
            }}</a>
            <div v-if="isParametric" class="dropdown-divider"></div>
            <a
              v-if="isParametric"
              class="dropdown-item cursor"
              @click="openContentViewParametersModal"
              >{{ $t('knowledge.search-admin.edit') }}</a
            >
          </span>
          <span v-if="itemType === 'archive'">
            <a
              class="dropdown-item cursor"
              @click="$emit('restore', [content.contentId])"
              >{{ $t('knowledge.search-admin.restore') }}</a
            >
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item cursor dropdown-item-danger"
              @click="displayDeleteModal = true"
            >
              <strong>{{
                $t('knowledge.search-admin.delete-definitively')
              }}</strong>
            </a>
          </span>
        </base-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/SuperSearch/Breadcrumb';
import ProductParameters from '@/components/SuperSearch/ProductParameters';
import CommonNodeStatus from '@/views/KnowledgeEditor/NavigationBar/Navigator/NavigatorNode/CommonNode/CommonNodeStatus';
import ContentViewSettingModal from '@/components/Modals/ContentViewSettingModal/ContentViewSettingModal';
import DangerDeleteModal from '@/components/Modals/DangerDeleteModal';
import ExplainPromptPopover from '@/components/SuperSearch/ExplainPromptPopover';
import ContentListItemFullLabelWrapper from '@/components/ListItems/ContentListItemFullLabelWrapper';
import { sanitize } from '@/plugins/dompurify';

export default {
  name: 'content-list-item',
  components: {
    Breadcrumb,
    ProductParameters,
    CommonNodeStatus,
    ContentViewSettingModal,
    DangerDeleteModal,
    ExplainPromptPopover,
    ContentListItemFullLabelWrapper,
  },
  props: {
    content: Object,
    selectedContents: {
      type: Array,
      default: () => [],
    },
    itemType: String,
    showBulkActions: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    isContentPreviewed: {
      type: Boolean,
      default: false,
    },
    labelSearchedIds: { type: Array, default: () => [] },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    searchLanguage: {
      type: String,
      default: 'default',
    },
    popoverToDisplay: {
      type: String,
      default: '',
    },
  },
  computed: {
    checked: {
      get() {
        return this.selectedContents.some((sc) => sc.id === this.content.id);
      },
      set(val) {
        let selectedContents = [...this.selectedContents];
        if (val) {
          selectedContents.push({
            id: this.content.id,
            userPermissions: this.content.userPermissions,
            labels: this.content.labels || [],
          });
        } else {
          selectedContents = this.selectedContents.filter(
            (sc) => sc.id !== this.content.id,
          );
        }
        this.$emit('update-selected-contents', selectedContents);
      },
    },
    breadcrumbItems() {
      return this.content.breadcrumb.map((el, idx) => {
        return { label: el, id: idx };
      });
    },
    itemWidth() {
      return this.fullWidth ? 'width: 100%' : 'width: calc(100% - 120px)';
    },
    label() {
      const { explain } = this.content;
      if (explain && explain.length) {
        const labelIdx = explain.findIndex(
          (el) => el.field === 'label' && el.lang === this.searchLanguage,
        );
        if (labelIdx > -1) {
          const labelHighLights = explain[labelIdx].highlights;
          if (labelHighLights && labelHighLights.length) {
            const highlightedLabel = explain[labelIdx].highlights[0];
            if (highlightedLabel) return sanitize(highlightedLabel);
          }
        }
      }

      return this.content.label;
    },
    ...mapGetters(['isParametric']),
  },
  data() {
    return {
      displayContentViewParametersModal: false,
      displayDeleteModal: false,
    };
  },

  methods: {
    updateStatus(event) {
      this.$emit('update-content', {
        id: this.content.id,
        payload: event,
      });
    },
    updateContentVerification(isOutdated) {
      this.$emit('update-content-verification', {
        id: this.content.id,
        isOutdated,
      });
    },
    updateContentSettings(evt) {
      this.$emit('update-content-settings', evt);
    },
    archive() {
      this.$emit('archive', [this.content.id]);
    },
    openContentViewParametersModal() {
      this.displayContentViewParametersModal = true;
    },
    bulkDelete(e) {
      if (e) this.$emit('delete', [this.content.contentId]);
      this.displayDeleteModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-label-span {
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.label-row {
  display: flex;
  align-items: center;
}

.explain-prompt {
  display: inline-flex;
  line-height: 1px;
}

.content-breadcrumb {
  margin-top: 0px;
}

.content__list__item-row {
  padding: 4px 0px;
  &:hover {
    background-color: $grey-1-mayday;
    border-radius: 2px;
    cursor: pointer;
    & .content-icon {
      color: $blue-mayday;
    }
  }
}

.content-icon {
  width: 16px;
  max-height: 16px;

  &.blue {
    color: $blue-mayday;
  }
}

.content-label-icon {
  color: $red-mayday;
  border: 1px solid $red-mayday;
  background-color: rgba($red-mayday, 0.1);
  border-radius: 2px;
  padding: 2px;
}
.options-selection {
  color: $grey-5-mayday;
  max-width: 12px;
  visibility: hidden;
  &:hover {
    color: $blue-mayday;
  }
}

.content__list__item-row {
  &:hover {
    .options-selection {
      visibility: visible;
    }
    .checkbox {
      :deep() .el-checkbox__input {
        visibility: visible;
      }
    }
  }
}

.checkbox {
  :deep() .el-checkbox__input {
    margin: auto;
    width: 14px;
    height: 14px;
  }
  :deep() .is-checked {
    visibility: visible;
  }
  :deep() .el-checkbox__inner {
    height: 14px;
    width: 14px;
  }
}

.check-space {
  width: 14px;
}

.dropdown-item {
  font-size: 12px !important;
  &:hover {
    color: $blue-mayday !important;
  }
}

.dropdown-item-danger {
  color: $red-mayday;
  background-color: rgba($yellow-mayday, 0.1);
}

.min-width-0 {
  min-width: 0;
}

.overflow {
  overflow: hidden;
  white-space: nowrap;
}

.is-hidden-sticker {
  background-color: red;
  height: 6px;
  border-radius: 100%;
  position: absolute;
  left: 12px;
  top: -3px;
  width: 5px;
}
</style>
