<template>
  <div class="ask-answer-sources">
    <AskListItemSource
      v-for="source in sources"
      :key="source.id"
      :id="source.id"
      :label="source.label"
      :type="source.type"
      :translations="source.translations"
      :defaultLanguage="source.defaultLanguage"
      :isOutdated="source.isOutdated"
      :language="language"
      :denormalized-case-parameters="source.denormalizedCaseParameters[0]"
      :isHovered="hoveredLink === source.id"
      :link-id="citationLabels[source.id]"
      @go-to-document="$emit('go-to-document', $event)"
      @copy-document-url="$emit('copy-document-url', $event)"
      @open-sidebar="$emit('open-sidebar', $event)"
      @update-hover-link="$emit('update-hover-link', $event)"
    ></AskListItemSource>
  </div>
</template>

<script>
import AskListItemSource from './AskListItemSource.vue';

export default {
  name: 'ask-answer-sources',
  components: { AskListItemSource },
  props: {
    sources: {
      type: Array,
      default: () => [],
    },
    citationLabels: {
      type: Object,
      default: () => ({}),
    },
    hoveredLink: {
      type: String,
      default: '',
    },
    language: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-answer-sources {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  justify-content: flex-start;
  width: 100%;
}
</style>
