<template>
  <span class="icon-selector-wrapper">
    <font-awesome-icon
      :icon="['fad', icon]"
      class="label__icon"
      :style="{ color: color }"
    />
  </span>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-selector-wrapper {
  height: 24px;
  width: 24px;
  padding: 2px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: $grey-1-mayday;
    border: 1px solid $grey-2-mayday;
  }
}
</style>
