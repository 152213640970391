const initEditorInGlobalWindow = (editorId) => {
  if (!window['___MAYDAY_CE___']) window['___MAYDAY_CE___'] = {};

  if (!window['___MAYDAY_CE___']['editors'])
    window['___MAYDAY_CE___']['editors'] = {};

  if (!window['___MAYDAY_CE___']['editors'][editorId])
    window['___MAYDAY_CE___']['editors'][editorId] = {};
};

export default initEditorInGlobalWindow;
