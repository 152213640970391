var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"explain-occurrence",domProps:{"innerHTML":_vm._s(
      _vm.sanitize(
        _vm.$tc(
          `knowledge.search-admin.prompt-popover.subtitle`,
          _vm.highlights.length,
        ),
      )
    )}}),_c('div',{staticClass:"prompt-callout"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.sanitize(_vm.highlights[_vm.step]))}})]),(_vm.highlights.length > 1)?_c('div',{staticClass:"prompt-footer"},[_c('div',{staticClass:"prompt-count"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.search-admin.prompt-popover.occurrence', { counter: `${_vm.step + 1}/${_vm.highlights.length}`, }))+" ")]),_c('div',[(_vm.step > 0)?_c('el-button',{attrs:{"size":"mini","plain":"","type":"secondary"},on:{"click":function($event){_vm.step -= 1}}},[_vm._v(_vm._s(_vm.$t('knowledge.search-admin.prompt-popover.previous')))]):_vm._e(),_c('el-button',{attrs:{"size":"mini","type":"primary","disabled":_vm.step === _vm.highlights.length - 1},on:{"click":function($event){_vm.step += 1}}},[_vm._v(_vm._s(_vm.$t('knowledge.search-admin.prompt-popover.next')))])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }