<template>
  <div
    :class="[
      'category',
      'd-flex',
      'mb-0',
      'align-items-center',
      isLabel ? '' : 'col',
    ]"
  >
    <input
      ref="itemEditorInput"
      type="text"
      size="25"
      v-model="newValue"
      :placeholder="$t(placeholder)"
      :class="[
        'item-editor__input',
        isLabel ? 'item-editor__input--label' : 'item-editor__input--category',
      ]"
      @blur="$emit('edit', newValue)"
      @keyup.enter="$event.target.blur()"
      @keyup.esc="$emit('cancel')"
    />
  </div>
</template>

<script>
export default {
  name: 'item-editor',
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    isLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newValue: this.value,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs['itemEditorInput'].focus();
    });
  },
};
</script>

<style lang="scss" scoped>
.item-editor__input {
  border: none;
  padding: 0;

  &::placeholder {
    color: $grey-5-mayday;
  }
}

.item-editor__input--label {
  color: $grey-9-mayday;
}

.item-editor__input--category {
  color: $grey-5-mayday;
  text-transform: uppercase;
  font-size: 0.9375rem;
  font-weight: 600;
}
</style>
