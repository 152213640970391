<template>
  <div v-loading="loading">
    <!-- URL COPY -->
    <div v-if="token" class="settings__public-item">
      <div class="label">
        {{ $t('knowledge.drawers.content-view-setting.settings.public.url') }}
      </div>
      <el-input :value="url" disabled>
        <template slot="append">
          <div class="copy-icon" v-clipboard:copy="url" @click="copyUrl()">
            <el-tooltip
              :content="
                $t(
                  'knowledge.drawers.content-view-setting.settings.public.copy',
                )
              "
              placement="top"
            >
              <font-awesome-icon :icon="['fad', 'clipboard-check']" />
            </el-tooltip>
          </div>
        </template>
      </el-input>
      <div class="mt-2">
        <el-button @click="handleRevokeToken">{{
          $t(
            'knowledge.drawers.content-view-setting.settings.public.revoke-token',
          )
        }}</el-button>
      </div>
    </div>

    <div v-else>
      <el-button @click="handleAddToken">{{
        $t('knowledge.drawers.content-view-setting.settings.public.add-token')
      }}</el-button>
    </div>

    <!-- ADD TOKEN -->
    <div v-if="FOR_LATER" class="settings__public-item">
      <div class="label">
        {{
          $t('knowledge.drawers.content-view-setting.settings.public.add-token')
        }}
        <el-tooltip
          :content="
            $t(
              'knowledge.drawers.content-view-setting.settings.public.add-token-tooltip',
            )
          "
          placement="top"
        >
          <font-awesome-icon
            :icon="['fad', 'info-circle']"
            class="info-icon mx-2"
          />
        </el-tooltip>
      </div>
      <el-input
        :rows="3"
        :placeholder="
          $t(
            'knowledge.drawers.content-view-setting.settings.public.add-token-placeholder',
          )
        "
        v-model="newToken.name"
      ></el-input>
      <div class="settings__public-add">
        <font-awesome-icon
          :icon="['fas', 'plus']"
          @click="handleAddToken"
        ></font-awesome-icon>
      </div>
    </div>
    <!-- TOKEN LIST -->
    <div v-if="FOR_LATER" class="settings__public-item">
      <div class="label">
        {{
          $t('knowledge.drawers.content-view-setting.settings.public.tokens')
        }}
        <el-tooltip
          :content="
            $t(
              'knowledge.drawers.content-view-setting.settings.public.meta-tooltip',
            )
          "
          placement="top"
        >
          <font-awesome-icon
            :icon="['fad', 'info-circle']"
            class="info-icon mx-2"
          />
        </el-tooltip>
      </div>
      <div v-for="token in tokens" :key="token.accessToken">
        {{ token.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content-view-settings-link',
  props: {
    publicData: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      FOR_LATER: false,
    };
  },
  computed: {
    loading() {
      return !this.publicData;
    },
    token() {
      if (!this.publicData) return null;

      const { tokens } = this.publicData;
      if (!tokens.length) return null;
      return tokens[0];
    },
    url() {
      if (!this.publicData) return '';

      const { slug, id } = this.publicData;
      const url = new URL(window.location.href);
      return `${url.origin}/public/${slug}-${id}?accessToken=${this.token.accessToken}`;
    },
  },
  methods: {
    copyUrl() {
      this.$message({
        message: this.$t(
          'knowledge.actions.copy-to-clipboard-confirmation-message',
        ),
        type: 'info',
      });
    },
    handleAddToken() {
      this.$emit('add-token', {
        name: 'mayday',
        expirationDate: (new Date().getTime() + 31557600).toString(),
      });
    },
    handleRevokeToken() {
      this.$emit('revoke-token', this.token);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .el-input__inner {
  padding: 0 2px;
  height: 28px;
}

:deep() .el-textarea__inner {
  padding: 0 2px;
}

:deep() .el-input-group__append {
  padding: 0;
  width: 28px;
  height: 28px;
  background-color: white;
}

.copy-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  * {
    width: 14px;
    height: 18px;
  }
}

.settings__public-item {
  margin-bottom: 8px;
}

.label {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
}

.info-icon {
  color: $grey-5-mayday;
  font-size: 12px;
}

.settings__public-cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}

:deep() .el-button {
  padding: 4px 8px;
  font-size: 12px;
}

.settings__public-add {
  cursor: pointer;
  align-self: right;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: transparent;
  color: $grey-5-mayday;
  padding: 4px;
  i {
    width: 12px;
    height: 12px;
  }

  &:hover {
    color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.1);
  }
}
</style>
