<template>
  <div class="table-of-contents mt-5">
    <div class="table-of-contents-header text-muted">
      <h5>{{ title }}</h5>
    </div>
    <el-divider direction="horizontal" class="mb-2 mt-0"/>
    <div class="routes-list-item" v-for="(item) in routes" :key="item.label">
      <router-link :to="{ hash: item.value }" class="route-title">
        <h4 :class="{'is-focused': focusedHash === item.value }" class="my-1">{{ item.label }}</h4>
      </router-link>
      <div id="sub-routes-list">
        <router-link
          v-for="subItem in item.options"
          :key="subItem.label"
          :to="{ hash: item.value, query: { tab: subItem.value } }"
        >
          <div class="sub-route-item">
            <small :class="{'is-focused': focusedSubHash === subItem.value }">{{ subItem.label }}</small>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'table-of-contents',
  props: {
    title: {
      type: String,
      default: 'Table des matières',
    },
    routes: {
      type: Array,
      default: () => [
        {
          label: 'Alertes et Notifications',
          value: 'notifications',
        },
        {
          label: 'Fichiers Attachés',
          value: 'attached-files',
          options: [
            {
              value: 'notice',
              label: 'Notice',
            },
            {
              value: 'documentations-generiques',
              label: 'Documentations Génériques',
            },
            {
              value: 'documentations-techniques',
              label: 'Documentations Techniques',
            },
            {
              value: 'liens-constructeurs',
              label: 'Liens constructeurs',
            },
          ],
        },
        {
          label: 'Solutions',
          value: 'solutions',
        },
      ],
    },
  },
  computed: {
    focusedHash() {
      return this.$route.hash && !this.$route.query['tab']
        ? this.$route.hash.replace('#', '')
        : null;
    },
    focusedSubHash() {
      return this.$route.query ? this.$route.query['tab'] : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-of-contents-header {
  text-transform: uppercase;
  h5 {
    color: $grey-5-mayday;
  }
}

.routes-list-item {
  width: 100%;
}

.route-title {
  color: $grey-9-mayday;
  line-height: 24px;
  &:hover,
  .is-focused {
    color: $blue-mayday;
  }
}
.sub-routes-list {
  padding-top: 8px;
  padding-bottom: 8px;
}

.sub-route-item {
  color: $grey-9-mayday;
  padding-left: 10px;
  border-left: 1px solid $grey-4-mayday;
  line-height: 24px;
  &:hover,
  .is-focused {
    color: $blue-mayday;
    border-color: $blue-mayday;
  }
  &:hover{
    background-color: $grey-1-mayday;
  }
}
</style>