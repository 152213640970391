import { render, staticRenderFns } from "./PluginAdvancedSettingsDrawerMain.vue?vue&type=template&id=5b9f7d7f&scoped=true&"
import script from "./PluginAdvancedSettingsDrawerMain.vue?vue&type=script&lang=js&"
export * from "./PluginAdvancedSettingsDrawerMain.vue?vue&type=script&lang=js&"
import style0 from "./PluginAdvancedSettingsDrawerMain.vue?vue&type=style&index=0&id=5b9f7d7f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b9f7d7f",
  null
  
)

export default component.exports