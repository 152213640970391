<template>
  <div id="set-group-name" class="py-3">
    <p class="mb-3">
      {{ $t('components.modals.add-company-group.set-group-name.description') }}
    </p>
    <base-input
      :label="$t('components.modals.add-company-group.set-group-name.label')"
      addon-left-icon="fa fa-user"
      class="col-12 mx-auto mb-2 input-group-alternative"
      ref="autofocus"
      :tooltip="true"
      :valid="isValid()"
      :placeholder="
        $t(
          'components.modals.add-company-group.set-group-name.input-placeholder',
        )
      "
      v-model="groupName"
      @input="onInput"
    >
      <template v-slot:tooltip-content>
        <h5 class="my-2">
          {{ $t('components.modals.add-company-group.group-name-tooltip') }}
        </h5>
      </template>
    </base-input>
  </div>
</template>

<script>
export default {
  name: 'set-group-name',
  props: {
    formerName: {
      type: String,
      default: '',
    },
    registeredNames: {
      required: true,
      type: Array,
      default: () => [],
    },
    currentName: {
      required: true,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      groupName: '',
    };
  },
  mounted() {
    this.groupName = this.currentName;
    this.focusInput();
  },
  methods: {
    isValid() {
      if (this.groupName.length >= 3) {
        return (
          !this.registeredNames.includes(this.groupName) ||
          (this.formerName !== '' && this.groupName === this.formerName)
        );
      }
    },
    onInput() {
      this.$emit('input', this.groupName);
    },
    focusInput() {
      this.$refs.autofocus.$el.children[0].focus();
    },
  },
  watch: {
    currentName() {
      this.groupName = this.currentName;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-item {
  border: 1px solid #e9ecef;
  border-radius: 3px;
}

.vertical-divider {
  max-height: 18px;
}

.flag-icon {
  height: 18px;
  width: 18px;
}

.input-group-alternative {
  :deep() .main-input {
    border: 1px solid #e9ecef;
    border-radius: 4px;
  }
}
</style>
