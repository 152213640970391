<template>
  <footer>
    <StepIndicator :steps="steps" :current-step="currentStep" class="mb-3" />

    <ContentViewSettingModalActions
      :last-step-value="steps[stepCount - 1]"
      :current-step="currentStep"
      :isGroupIdsInvalid="isGroupIdsInvalid"
      @close="sendCloseEvent"
      @go-to-previous-step="sendGoToPreviousStepEvent"
      @go-to-next-step="sendGoToNextStep"
      @update-content-view-setting="sendUpdateContentViewSettingEvent"
    />
  </footer>
</template>

<script>
import StepIndicator from '@/components/Modals/CreateProductNotificationModal/StepIndicator.vue';
import ContentViewSettingModalActions from './ContentViewSettingModalActions';

export default {
  name: 'modal-footer',
  components: {
    StepIndicator,
    ContentViewSettingModalActions,
  },
  props: {
    steps: Array,
    currentStep: Number,
    stepCount: Number,
    isNotificationTitleInvalid: Boolean,
    isNotificationMessageInvalid: Boolean,
    isGroupIdsInvalid: Boolean,
  },
  methods: {
    sendCloseEvent() {
      this.$emit('close');
    },
    sendGoToPreviousStepEvent() {
      this.$emit('go-to-previous-step');
    },
    sendGoToNextStep() {
      this.$emit('go-to-next-step');
    },
    sendUpdateContentViewSettingEvent() {
      this.$emit('update-content-view-setting');
    },
  },
};
</script>
