import { Line, mixins } from 'vue-chartjs';
import globalOptionsMixin from '@/components/Charts/globalOptionsMixin';
export default {
  name: 'line-chart',

  extends: Line,

  mixins: [mixins.reactiveProp, globalOptionsMixin],

  props: {
    extraOptions: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      ctx: null,
      unwatchOptions: null,
    };
  },

  mounted() {
    this.unwatchOptions = this.$watch(
      'chartData',
      (newVal, oldVal) => {
        if (!oldVal) {
          this.renderChart(this.chartData, this.extraOptions);
        } else {
          this.$data._chart.update();
        }
      },
      { immediate: true },
    );
  },

  beforeDestroy() {
    this.unwatchOptions && this.unwatchOptions();
  },
};
