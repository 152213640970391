var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'category',
    'd-flex',
    'mb-0',
    'align-items-center',
    _vm.isLabel ? '' : 'col',
  ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newValue),expression:"newValue"}],ref:"itemEditorInput",class:[
      'item-editor__input',
      _vm.isLabel ? 'item-editor__input--label' : 'item-editor__input--category',
    ],attrs:{"type":"text","size":"25","placeholder":_vm.$t(_vm.placeholder)},domProps:{"value":(_vm.newValue)},on:{"blur":function($event){return _vm.$emit('edit', _vm.newValue)},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return $event.target.blur()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.$emit('cancel')}],"input":function($event){if($event.target.composing)return;_vm.newValue=$event.target.value}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }