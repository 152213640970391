import { render, staticRenderFns } from "./PluginCatalog.vue?vue&type=template&id=583c6494&scoped=true&"
import script from "./PluginCatalog.vue?vue&type=script&lang=js&"
export * from "./PluginCatalog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "583c6494",
  null
  
)

export default component.exports