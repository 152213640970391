<template>
  <div class="w-100">
    <div class="search-user-input">
      <el-input
        :placeholder="
          $t('settings.users-administration.filters.search-placeholder')
        "
        v-model="search"
        class="search"
        clearable
        @input="launchHandleSearchDebounce(false)"
        @clear="launchHandleSearchDebounce(false)"
      >
        <i slot="prefix" class="el-input__icon" v-if="!search"
          ><font-awesome-icon
            :icon="['fad', 'search']"
            class="search-icon"
          ></font-awesome-icon
        ></i>
      </el-input>
    </div>

    <div class="user-list-wrapper" v-if="!loading">
      <div v-for="user in users" :key="user.id">
        <div
          class="user-list-item"
          :class="{ 'user-list-item__selected': selectedUserId === user.id }"
          @click="$emit('set-user', { key: user.username, value: user.id })"
        >
          <div class="icon-wrapper">
            <font-awesome-icon :icon="['fal', 'user']" class="icon" />
          </div>
          <div class="username">
            <TextEllipsis :text="user.username" :fontSize="'14px'" />
          </div>
        </div>
      </div>
      <div
        v-if="currentPage < pages"
        class="show-more-button-wrapper mt-1"
        @click="handleSeeMore"
      >
        <el-button size="mini">
          {{
            $t('knowledge.search-admin.quick-filters.user.see-more')
          }}</el-button
        >
      </div>
    </div>
    <div v-else class="user-loading">
      <font-awesome-icon :icon="['fal', 'spinner-third']" spin />
    </div>
    <div class="bottom" v-if="!loading">
      <div class="page-counter" v-if="pages > 1">{{ pageCounter }}</div>
      <div class="total" v-if="total > 0">Total : {{ total }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import debounce from 'lodash.debounce';
import TextEllipsis from '@/components/TextEllipsis';

const permissionsMapping = {
  authorId: 'CREATE_CONTENT',
  contentUpdatedBy: 'UPDATE_CONTENT',
};

export default {
  name: 'user-search',
  props: {
    userPermissionKey: {
      type: String,
      default: '',
    },
    selectedUserId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: '',
      launchHandleSearchDebounce: debounce(
        (isSeeMore) => this.handleSearch(isSeeMore),
        200,
      ),
      total: 0,
      pages: 0,
      users: [],
      currentPage: 1,
      loading: false,
    };
  },
  components: { TextEllipsis },
  mounted() {
    this.launchHandleSearchDebounce(false);
  },
  computed: {
    pageCounter() {
      return `${this.currentPage}/${this.pages}`;
    },
  },
  methods: {
    async handleSearch(isSeeMore) {
      this.loading = true;
      const searchResults = await this.searchEditorUsers({
        page: this.currentPage,
        pageSize: 10,
        filters: [],
        sort: {},
        search: this.search,
        permissions: [permissionsMapping[this.userPermissionKey]],
      });
      this.total = searchResults.total;
      this.pages = searchResults.pages;
      isSeeMore
        ? this.users.push(...searchResults.results)
        : (this.users = searchResults.results);
      this.loading = false;
    },
    handleSeeMore() {
      this.currentPage += 1;
      this.launchHandleSearchDebounce(true);
    },
    ...mapActions('adminModule', ['searchEditorUsers']),
  },

  watch: {
    search() {
      this.currentPage = 1;
      this.total = 0;
      this.pages = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-user-input {
  padding: 8px;
  padding-bottom: 0;
  margin-bottom: 4px;
}
:deep() .el-input__inner {
  border-radius: 8px;
}

.user-list-wrapper {
  max-height: 200px;
  overflow: scroll;
}
.user-list-item {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  &:hover {
    background-color: $grey-3-mayday;
    cursor: pointer;
  }
  &__selected {
    background-color: $grey-3-mayday;
    color: $blue-mayday;
  }
}
.icon-wrapper {
  border-radius: 50%;
  border: 1px solid $grey-2-mayday;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  margin-right: 8px;
}
.icon {
  height: 20px;
}
.username {
  max-width: calc(100% - 32px);
  height: 100%;
  display: flex;
  justify-content: center;
}
.bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
  color: $grey-5-mayday;
  font-size: 12px;
}
.show-more-button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
}
.user-loading {
  width: 100%;
  justify-content: center;
  display: flex;
  color: $blue-mayday;
  height: 50px;
  align-items: center;
  font-size: 25px;
}
.el-input__icon {
  margin-left: 4px;
}
</style>
