import { render, staticRenderFns } from "./BulkContentsWrapper.vue?vue&type=template&id=e559b932&scoped=true&"
import script from "./BulkContentsWrapper.vue?vue&type=script&lang=js&"
export * from "./BulkContentsWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e559b932",
  null
  
)

export default component.exports