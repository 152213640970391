<template functional>
  <div>
    <component
      :is="$options.components.Group"
      v-for="(group, i) in $options.filtersToDisplay(props.filters)"
      :key="$options.generateUniqueGroupKey(group, i)"
      :name="group.label"
    >
      <component
        :is="$options.determineFilterComponent(filter)"
        v-for="(filter, j) in group.filters"
        :key="$options.generateUniqueFilterKey(group, filter)"
        :filter="filter"
        :value="props.values[i][j]"
        :condition-value="props.additionalValues[`${filter.label}Condition`]"
        :remote-value="props.additionalValues[`${filter.label}Remote`]"
        v-bind="$options.computeAdditionalProps(filter, props)"
        @change="(value) => props.handleChange({ i, j, value })"
        @change-additional="
          ({ key, value }) =>
            props.handleChangeAdditional({
              i,
              j,
              key: `${filter.label}${key}`,
              value,
            })
        "
        @reset="() => props.handleReset({ i, j, label: filter.label })"
        @unselect-all="() => props.handleReset({ i, j, unselect: true })"
      />
    </component>
  </div>
</template>

<script>
import Group from './Filter/Group';
import { filterFamilies, components } from './FilterDefinitions';

const generateUniqueGroupKey = (group, index) =>
  `filters-group-${group.label}-${index}`;
const generateUniqueFilterKey = (group, filter) =>
  `filters-group-${group.label}-filter-${filter.label}`;
export const determineFilterComponent = (filter) => {
  const filterType = filterFamilies.find(({ condition }) => condition(filter));
  if (!filterType) return;
  const field = filterType.fields.find(({ condition }) => condition(filter));
  if (!field) return;
  const comp = components.find(({ name }) => name === field.name);
  if (!comp) return;
  return comp.component;
};
const computeAdditionalProps = (filter, props) => {
  if (filter.type === 'DateRange' || filter.type === 'TimeRange') {
    return {
      shortcutValues: props.daterangeShortcutValues,
    };
  }
  return {};
};

const filtersToDisplay = (filters) =>
  filters.filter((filter) => !filter.shouldHide);

export default {
  props: {
    filters: {
      type: Array,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
    additionalValues: {
      type: Object,
      required: true,
    },
    handleChange: {
      type: Function,
      required: true,
    },
    handleChangeAdditional: {
      type: Function,
      required: true,
    },
    handleReset: {
      type: Function,
      required: true,
    },
    daterangeShortcutValues: {
      type: Object,
      required: true,
    },
  },

  components: {
    Group,
  },

  generateUniqueGroupKey,
  generateUniqueFilterKey,
  determineFilterComponent,
  computeAdditionalProps,
  filtersToDisplay,
};
</script>
