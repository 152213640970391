<template>
  <modal :show.sync="localOpen">
    <div class="close-icon">
      <font-awesome-icon
        class="icon close"
        :icon="['fa', 'times']"
        @click="$emit('close')"
      ></font-awesome-icon>
    </div>
    <div class="main">
      <div class="carousel-main">
        <div @click="prevCursor" :class="{ disable: previewCursor === 0 }">
          <font-awesome-icon class="icon cursor" :icon="['fa', 'caret-up']" />
        </div>
        <div class="carousel-wrapper">
          <div v-for="pos in previewItem" :key="pos">
            <img
              :class="{
                selected: previewCursor + pos === imageSelected,
              }"
              :src="largeImages[(previewCursor + pos) % images.length]"
              @click="goToImage(pos)"
            />
          </div>
        </div>
        <div
          @click="nextCursor"
          :class="{ disable: previewCursor + 4 >= images.length }"
        >
          <font-awesome-icon class="icon cursor" :icon="['fa', 'caret-down']" />
        </div>
      </div>

      <div class="gallery-main">
        <div class="d-flex justify-content-center gallery">
          <v-zoomer-gallery
            ref="gallery"
            style="background-color: transparent; width: 100%; height: 100%"
            :list="largeImages"
            v-model="imageSelected"
          ></v-zoomer-gallery>
        </div>
        <div class="options">
          <div>
            <div class="d-flex justify-content-center align-items-center pt-2">
              <font-awesome-icon
                class="icon"
                :icon="['fa', 'caret-left']"
                @click="goToPreviousSlide"
              />
              <div class="mb-0 px-2">
                {{ imageSelected + 1 }}/{{ imageCount + 1 }}
              </div>
              <font-awesome-icon
                class="icon"
                :icon="['fa', 'caret-right']"
                @click="goToNextSlide"
              />
            </div>
            <div
              class="d-flex justify-content-center align-items-center pt-2 zoom"
            >
              <div>
                <font-awesome-icon
                  class="icon zoom-icon"
                  :icon="['fas', 'minus']"
                  @click="$refs.gallery.zoomOut()"
                />
              </div>
              <div>
                <font-awesome-icon
                  class="icon zoom-icon"
                  :icon="['fas', 'plus']"
                  @click="$refs.gallery.zoomIn()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'carousel-zoomer-modal',
  props: {
    contentTitle: {
      type: String,
    },
    imageIndex: {
      type: Number,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    largeImages() {
      return this.images.map((image) => this.getLargestImage(image));
    },
  },
  data() {
    return {
      localOpen: this.open,
      imageCount: this.images.length - 1,
      imageSelected: this.imageIndex,
      previewCursor: 0,
      previewItem: [0, 1, 2, 3].slice(0, this.images.length),
    };
  },
  mounted() {
    document.addEventListener('keydown', this.componentEventsListeners);
  },
  methods: {
    componentEventsListeners(e) {
      if (e.key === 'ArrowRight') {
        e.preventDefault();
        this.goToNextSlide();
      } else if (e.key === 'ArrowLeft') {
        e.preventDefault();
        this.goToPreviousSlide();
      }
    },
    prevCursor() {
      this.previewCursor -= 1;
    },
    nextCursor() {
      this.previewCursor += 1;
    },
    async goToPreviousSlide() {
      this.imageSelected =
        this.imageSelected === 0 ? this.imageCount : this.imageSelected - 1;

      this.$emit('update-image-index', this.imageSelected);
    },
    async goToNextSlide() {
      this.imageSelected = (this.imageSelected + 1) % this.images.length;
      this.$emit('update-image-index', this.imageSelected);
    },
    goToImage(pos) {
      this.imageSelected = (this.previewCursor + pos) % this.images.length;
      this.$emit('update-image-index', this.imageSelected);
    },
    getLargestImage(image) {
      return ['large', 'medium', 'small']
        .map((key) => image[key])
        .filter((el) => !!el)[0];
    },
  },
  watch: {
    localOpen() {
      if (!this.localOpen) {
        this.$emit('close');
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.componentEventsListeners);
  },
};
</script>

<style lang="scss" scoped>
:deep() .modal-dialog {
  margin: 1rem 2rem;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep() .modal-content {
  height: 80vh;
}

:deep() .modal-body {
  padding: 0.8rem;
}

.main {
  display: flex;
  flex-direction: row;
  height: 70vh;
}

.icon {
  cursor: pointer;
  color: $grey-5-mayday;
  width: 1.5rem;
  height: 1.5rem;
  &:hover {
    color: $blue-mayday;
  }
}

.disable {
  pointer-events: none;
  opacity: 0;
}

.selected {
  opacity: 1 !important;
}

.carousel-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 75vh;
  .carousel-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    * {
      max-height: 50%;
      height: 15vh;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        cursor: pointer;
        height: 100%;
        object-fit: contain;
        transition: transform 300ms ease;
        opacity: 0.75;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

.gallery-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 75vh;
  .gallery {
    width: 100%;
    height: 100%;
    :deep() .vue-zoomer-gallery {
      height: 100%;
    }
  }
}

.close-icon {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  .close {
    width: 2rem;
    height: 2rem;
  }
}

.zoom {
  div + div {
    margin-left: 20px;
  }
}

@media only screen and (max-device-width: 768px) {
  :deep() .modal-dialog {
    margin: 0.5rem 1rem;
  }

  .main {
    flex-direction: column;
    height: 70vh;
    margin-top: 24px;
  }
  .carousel-main {
    flex-direction: row;
    width: 100%;
    height: 20vh;
    .carousel-wrapper {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      * {
        min-width: 20%;
        img {
          cursor: pointer;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .cursor {
    transform: rotate(-90deg);
  }

  .gallery-main {
    height: 100%;
  }
}
</style>
