import { parseDate } from './parse';

const TIME_SCALE = {
  minutes: 60 * 1000,
  hours: 60 * 60 * 1000,
  days: 24 * 60 * 60 * 1000,
  months: 30.44 * 24 * 60 * 60 * 1000,
  years: 365 * 24 * 60 * 60 * 1000,
};

export const dateBetween = (date1, date2, scale = 'days') => {
  try {
    const msDiff = Math.abs(
      parseDate(date2).getTime() - parseDate(date1).getTime(),
    );

    return Math.ceil(msDiff / TIME_SCALE[scale]);
  } catch (err) {
    return this.fallbackValue;
  }
};

export const daysInMs = (day) => {
  return day * TIME_SCALE['days'];
};

export const dateRangeOptions = [
  {
    key: 1,
    label: 'knowledge.search-admin.quick-filters.dates.values.1-day',
    value: () => ({
      gte: new Date(new Date(Date.now()).setUTCHours(0, 0, 0, 0)).toISOString(),
      lte: new Date(Date.now()).toISOString(),
    }),
  },
  {
    key: 7,
    label: 'knowledge.search-admin.quick-filters.dates.values.7-days',
    value: () => ({
      gte: new Date(Date.now() - daysInMs(7)).toISOString(),
      lte: new Date(Date.now()).toISOString(),
    }),
  },
  {
    key: 30,
    label: 'knowledge.search-admin.quick-filters.dates.values.30-days',
    value: () => ({
      gte: new Date(Date.now() - daysInMs(30)).toISOString(),
      lte: new Date(Date.now()).toISOString(),
    }),
  },
  {
    key: 90,
    label: 'knowledge.search-admin.quick-filters.dates.values.90-days',
    value: () => ({
      gte: new Date(Date.now() - daysInMs(90)).toISOString(),
      lte: new Date(Date.now()).toISOString(),
    }),
  },
];
export const dueDateRangeOptions = [
  {
    key: 0,
    label: 'knowledge.search-admin.quick-filters.dates.values.-1-day',
    value: () => ({
      lte: new Date(Date.now()).toISOString(),
    }),
  },
  {
    key: 1,
    label: 'knowledge.search-admin.quick-filters.dates.values.1-day',
    value: () => ({
      gte: new Date(new Date(Date.now()).setUTCHours(0, 0, 0, 0)).toISOString(),
      lte: new Date(Date.now()).toISOString(),
    }),
  },
  {
    key: +7,
    label: 'knowledge.search-admin.quick-filters.dates.values.+7-days',
    value: () => ({
      lte: new Date(Date.now() + daysInMs(7)).toISOString(),
      gte: new Date(Date.now()).toISOString(),
    }),
  },
  {
    key: +30,
    label: 'knowledge.search-admin.quick-filters.dates.values.+30-days',
    value: ()=>({
      lte: new Date(Date.now() + daysInMs(30)).toISOString(),
      gte: new Date(Date.now()).toISOString(),
    }),
  },
  {
    key: +90,
    label: 'knowledge.search-admin.quick-filters.dates.values.+90-days',
    value: () => ({
      lte: new Date(Date.now() + daysInMs(90)).toISOString(),
      gte: new Date(Date.now()).toISOString(),
    }),
  },
];

export const getDurationFromDateRange = (gte, lte, scale) => {
  const date1 = new Date(lte);
  const date2 = new Date(gte);
  const timeDif = date1.getTime() - date2.getTime();
  const scaledDif = Math.round(timeDif / TIME_SCALE[scale]);
  return scaledDif;
};
