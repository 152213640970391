<template>
  <div class="lightbox">
    <div class="close-lightbox">
      <font-awesome-icon
        class="close-lightbox__icon"
        :icon="['fas', 'times']"
        @click="close"
      />
    </div>
    <div
      class="h-100 flex flex-col justify-center items-center"
      @click.self="close"
    >
      <v-zoomer v-if="imageUrl" class="v-zoomer" ref="gallery">
        <img :src="imageUrl" />
      </v-zoomer>
      <div class="flex justify-center items-center pt-2 zoom">
        <div>
          <font-awesome-icon
            class="icon"
            :icon="['fas', 'minus']"
            @click="$refs.gallery.zoomOut()"
          />
        </div>
        <div>
          <font-awesome-icon
            class="icon"
            :icon="['fas', 'plus']"
            @click="$refs.gallery.zoomIn()"
          />
        </div>
        <div>
          <font-awesome-icon
            class="icon"
            :icon="['fas', 'external-link']"
            @click="openInNewTab()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'lightbox-component',
  props: {
    imageUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imageSelected: 0,
    };
  },
  watch: {
    imageUrl: {
      handler: (val) => {
        let documentClasses = document.body.classList;
        if (val) {
          documentClasses.add('modal-open');
        } else {
          documentClasses.remove('modal-open');
        }
      },
      immediate: true,
    },
  },
  methods: {
    openInNewTab() {
      window.open(this.imageUrl, '_blank');
    },
    close() {
      this.$emit('update:imageUrl', '');
    },
  },
};
</script>

<style lang="scss" scoped>
.lightbox {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1em;
  background: rgba(0, 0, 0, 0.8);
}

.v-zoomer {
  width: 750px;
  height: 750px;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.zoom {
  div + div {
    margin-left: 20px;
  }
}

.icon {
  cursor: pointer;
  color: white;
  width: 1.5rem;
  height: 1.5rem;
  &:hover {
    color: $blue-mayday;
  }
}

.close-lightbox {
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;

  &__icon {
    cursor: pointer;
    color: white;
    width: 2rem;
    height: 2rem;

    &:hover {
      color: $blue-mayday;
    }
  }
}
</style>
