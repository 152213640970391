<template>
  <div class="side-container">
    <div :class="{ 'side-toggle': true, disabled }">
      <el-tooltip
        :content="
          this.$t('settings.integration.side-bar.switch-tooltip.disabled', {
            integration: this.name,
          })
        "
        effect="light"
        :visible-arrow="false"
        :disabled="!disabled"
        placement="right-end"
      >
        <el-switch v-model="activeSwitch" :disabled="disabled"></el-switch>
      </el-tooltip>
    </div>
    <div v-if="featuresSelected.length > 0">
      <div class="side-title">
        {{ $t('settings.integration.side-bar.features.title') }}
      </div>
      <div
        class="side-item"
        v-for="feature in featuresSelected"
        :key="feature.type"
      >
        <font-awesome-icon :icon="['fas', 'check']" color="#8CB369" />
        <span>{{ feature.name }}</span>
      </div>
    </div>
    <div v-if="links.length > 0">
      <div class="side-title">
        {{ $t('settings.integration.side-bar.links.title') }}
      </div>

      <div class="side-item" v-for="link in links" :key="link.name">
        <a :href="link.href" target="_blank">
          {{ link.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'integration-settings-drawer-side',
  components: {},
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    types: {
      type: Array,
      required: true,
    },
    links: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  created() {},
  computed: {
    featuresSelected() {
      return this.features.filter(({ type }) => {
        return this.types.includes(type);
      });
    },
    activeSwitch: {
      get() {
        return this.active;
      },
      set(value) {
        this.$emit('switched', value);
      },
    },
    ...mapGetters('knowledgeModule', ['focusKnowledge', 'editingLanguage']),
  },
  data() {
    return {
      features: [
        {
          name: this.$t('settings.integration.side-bar.features.app'),
          type: 'APP',
        },
        {
          name: this.$t('settings.integration.side-bar.features.automation'),
          type: 'AUTOMATION',
        },
        {
          name: this.$t('settings.integration.side-bar.features.import'),
          type: 'IMPORT',
        },
        {
          name: this.$t('settings.integration.side-bar.features.preferences'),
          type: 'PREFERENCES',
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.side-container {
  width: max-content;
}

.side-title {
  font-size: 16px;
  font-weight: bold;
  padding: 8px 0;
}

.side-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.side-item {
  font-size: 12px;
  font-weight: normal;
  padding: 4px 0;

  span {
    margin-left: 10px;
  }
}

.disabled {
  :deep() .el-switch.is-checked .el-switch__core {
    background-color: $grey-4-mayday;
    border-color: $grey-4-mayday;
  }

  :deep() .el-switch__core:after {
    background-color: #fff;
    font-family: 'Font Awesome 5 Pro';
    color: $blue-mayday;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    content: '\f00c'; //Font Awesome check code
  }
}
</style>
