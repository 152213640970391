<template>
  <div>
    <div class="d-flex flex-column justify-content-left">
      <!-- KNOWLEDGE LANGUAGE -->
      <div class="category">
        <div class="title">
          {{ $t('settings.new-knowledge.choose-type.public.form.lang-title') }}
        </div>
        <div class="description">
          {{
            $t(
              'settings.new-knowledge.choose-type.public.form.lang-description',
            )
          }}
        </div>
        <el-select
          v-model="models.lang"
          class="select"
          :class="[{ 'chosen-language': models.lang }]"
        >
          <div slot="prefix" class="flag-wrapper" v-show="models.lang">
            <LanguageFlag class="d-inline-flex" :language="models.lang" />
          </div>
          <el-option
            v-for="lang in langOptions"
            :key="lang"
            :value="lang"
            :label="$t(`knowledge.languages.${lang}`)"
          >
          </el-option>
        </el-select>
      </div>

      <!-- KNOWLEDGE SUBDOMAIN-->
      <div class="category mt-3">
        <div class="title">
          {{
            $t('settings.new-knowledge.choose-type.public.form.domain-title')
          }}
        </div>
        <div class="description">
          {{
            $t(
              'settings.new-knowledge.choose-type.public.form.domain-description',
            )
          }}
        </div>
        <el-input
          :placeholder="
            $t(
              'settings.new-knowledge.choose-type.public.form.domain-placeholder',
            )
          "
          v-model="models.subdomain"
          @input="launchHandleCheckHostnameDebounce"
          :class="{
            'is-not-valid':
              (hostnameAlreadyExist ||
                (!isWellFormatted && models.subdomain)) &&
              !localValidityLoading,
          }"
        >
          <template slot="append">.mayday.cx</template>
          <template
            v-if="models.subdomain && localValidityLoading"
            slot="suffix"
            class="mr-1"
          >
            <font-awesome-icon
              :icon="['fal', 'spinner-third']"
              spin
              class="validity-loader"
          /></template>
        </el-input>
        <div class="d-flex validity-message">
          <div v-if="!localValidityLoading && models.subdomain">
            <div v-if="hostnameAlreadyExist" class="error-hostname">
              <font-awesome-icon :icon="['fas', 'times-circle']" class="mr-1" />
              {{
                $t(
                  'settings.new-knowledge.choose-type.public.form.hostname-validity.already-exist',
                )
              }}
            </div>
            <div v-else>
              <div v-if="isWellFormatted" class="valid-hostname">
                <font-awesome-icon
                  :icon="['fas', 'check-circle']"
                  class="mr-1"
                />
                {{
                  $t(
                    'settings.new-knowledge.choose-type.public.form.hostname-validity.ready',
                  )
                }}
              </div>
              <div v-else class="error-hostname">
                <div v-if="models.subdomain">
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  {{
                    $t(
                      'settings.new-knowledge.choose-type.public.form.hostname-validity.wrong-format',
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- KNOWLEDGE BASE CONFIGURATION -->
      <div class="category mt-3">
        <div class="title">
          {{ $t('settings.new-knowledge.choose-type.public.form.conf-title') }}
        </div>
        <div class="description">
          {{
            $t(
              'settings.new-knowledge.choose-type.public.form.conf-description',
            )
          }}
        </div>
        <div class="subtitle">
          {{
            $t('settings.new-knowledge.choose-type.public.form.primary-title')
          }}
        </div>
        <div class="d-flex flex-row align-items-center">
          <el-color-picker
            class="mr-2"
            v-model="models.primary"
            color-format="hex"
            @active-change="activeChange('primary', $event)"
          ></el-color-picker>
          <div class="ml-2 placeholder-value">
            {{ models.primary }}
          </div>
        </div>
        <div class="subtitle">
          {{
            $t('settings.new-knowledge.choose-type.public.form.secondary-title')
          }}
        </div>
        <div class="d-flex flex-row align-items-center">
          <el-color-picker
            class="mr-2"
            v-model="models.secondary"
            color-format="hex"
            @active-change="activeChange('secondary', $event)"
          ></el-color-picker>
          <div class="ml-2 placeholder-value">
            {{ models.secondary }}
          </div>
        </div>
      </div>

      <!-- KNOWLEDGE ACCESS RESTRICTIONS -->
      <div class="category mt-3">
        <div class="title">
          {{ $t('settings.new-knowledge.choose-type.groups-access.title') }}
        </div>
        <div class="description">
          {{
            $t('settings.new-knowledge.choose-type.groups-access.description')
          }}
        </div>
        <ContentViewSettingsCallout
          v-if="!models.groups.length"
          trad="settings.new-knowledge.choose-type.groups-access"
          icon="exclamation-triangle"
          type="warning"
        />
        <el-select
          class="select"
          v-model="models.groups"
          multiple
          collapse-tags
        >
          <el-option
            v-for="group in groupOptions"
            :key="group.id"
            :value="group.id"
            :label="group.name"
          >
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageFlag from '@/components/LanguageFlag.vue';
import ContentViewSettingsCallout from '@/components/Drawers/ContentViewSettingDrawer/ContentViewSettingsComponents/ContentViewSettingsCallout.vue';

import { convertRGBtoHex } from '@/utils/colorConverter.js';
import { langOptions } from '@/utils/langOptions.js';
import debounce from 'lodash.debounce';
import { isValidHostname } from 'utils/isValidHostname';

export default {
  name: 'new-knowledge-public',
  components: {
    LanguageFlag,
    ContentViewSettingsCallout,
  },
  data() {
    return {
      models: {
        lang: 'fr-fr',
        subdomain: '',
        primary: '#006450',
        secondary: '#FFDA85',
        groups: [],
      },
      langOptions: langOptions,
      launchHandleCheckHostnameDebounce: debounce(
        () => this.handleCheck(),
        200,
      ),
      isWellFormatted: false,
      localValidityLoading: true,
    };
  },
  props: {
    hostnameAlreadyExist: {
      type: Boolean,
      default: true,
    },
    validityLoading: {
      type: Boolean,
      default: true,
    },
    groupOptions: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    activeChange(key, val) {
      this.models[key] = convertRGBtoHex(val);
    },
    handleCheck() {
      this.localValidityLoading = true;
      this.isWellFormatted = isValidHostname(this.models.subdomain, false);
      if (this.isWellFormatted) {
        this.$emit('check-hostname', this.models.subdomain);
      } else this.localValidityLoading = false;
    },
  },
  watch: {
    models: {
      handler(newVal) {
        this.$emit('new-kb-models', newVal);
      },
      deep: true,
    },
    validityLoading() {
      this.localValidityLoading = this.validityLoading;
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  .title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 6px;
  }
  .description {
    font-size: 16px;
    color: $grey-8-mayday;
    margin-bottom: 17px;
    max-width: 350px;
  }
  .select {
    width: 60%;
  }
  .el-select {
    height: 32px;
  }
  :deep() .el-input {
    height: 100%;
  }
  :deep() .el-input__inner {
    height: 100%;
  }
  :deep() .el-input__suffix {
    display: flex;
    align-items: center;
  }
  :deep() .el-input__prefix {
    display: flex;
    align-items: center;
  }
  .flag-wrapper {
    width: 23px;
    height: 21px;
    background-color: $grey-4-mayday;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
  }
  .el-input {
    height: 32px;
    width: 350px;
  }
  :deep() .el-color-picker {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :deep() .el-color-picker__trigger {
    border-radius: 50%;
    padding: 0px;
    width: 30px;
    height: 30px;
  }

  :deep() .el-color-picker__color {
    border-radius: 50%;
    border: none;
  }

  :deep() .el-color-picker__color-inner {
    border-radius: 50%;
  }
  .placeholder-value {
    font-size: 12px;
    color: $grey-6-mayday;
  }
}

.chosen-language {
  :deep() .el-input__inner {
    padding-left: (50px);
  }
}

.validity-message {
  height: 14px;
  font-size: 12px;
  .error-hostname {
    color: $red-mayday;
  }

  .valid-hostname {
    color: $green-mayday;
  }
}

.is-not-valid {
  :deep() .el-input__inner {
    border-color: $red-mayday;
  }
}
</style>
