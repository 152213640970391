<template>
  <footer>
    <StepIndicator :steps="steps" :current-step="currentStep" class="mb-3" />

    <ModalActions
      :step-count="stepCount"
      :current-step="currentStep"
      :is-product-notification-title-invalid="isProductNotificationTitleInvalid"
      :is-product-notification-description-invalid="
        isProductNotificationDescriptionInvalid
      "
      :is-product-notification-parameters-invalid="
        isProductNotificationParametersInvalid
      "
      :is-product-notification-access-restrictions-invalid="
        isProductNotificationAccessRestrictionsInvalid
      "
      @close="sendCloseEvent"
      @goToPreviousStep="sendGoToPreviousStepEvent"
      @goToNextStep="sendGoToNextStep"
      @createProductNotification="sendCreateProductNotificationEvent"
    />
  </footer>
</template>

<script>
import StepIndicator from './StepIndicator.vue';
import ModalActions from './ModalActions';

export default {
  name: 'modal-footer',
  components: {
    StepIndicator,
    ModalActions,
  },
  props: {
    steps: Array,
    currentStep: Number,
    stepCount: Number,
    isProductNotificationTitleInvalid: Boolean,
    isProductNotificationDescriptionInvalid: Boolean,
    isProductNotificationParametersInvalid: Boolean,
    isProductNotificationAccessRestrictionsInvalid: Boolean,
  },
  methods: {
    sendCloseEvent() {
      this.$emit('close');
    },
    sendGoToPreviousStepEvent() {
      this.$emit('goToPreviousStep');
    },
    sendGoToNextStep() {
      this.$emit('goToNextStep');
    },
    sendCreateProductNotificationEvent() {
      this.$emit('createProductNotification');
    },
  },
};
</script>
