<template>
  <settings-dropdown :force-open="forceOpen" @close="forceOpen = false">
    <template slot="title">
      <settings-dropdown-capitalized-column
        class="category-name"
        :text="name"
        :icon="icon"
        :color="color"
        :is-editing="editCategoryName"
        can-edit
        @edit-text="editCategoryName = true"
        @cancel="editCategoryName = false"
        @update-text="handleUpdateCategory"
        @update-icon="handleUpdateIconCategory"
        @update-color="handleUpdateColorCategory"
      >
      </settings-dropdown-capitalized-column>

      <div class="labels-count">
        {{ $tc('settings.permissions.user-labels.count', count, { count }) }}
      </div>

      <!-- OPTIONS -->
      <div class="d-flex align-items-center">
        <!-- ADD LABEL -->
        <el-tooltip
          :content="
            $t(
              'settings.card-settings-options.content-parameters.create-tooltip',
            )
          "
          placement="top"
        >
          <el-button
            size="small"
            @click="handleNewLabel"
            class="m-0 p-2 plus-button-wrapper"
          >
            <font-awesome-icon :icon="['far', 'plus']" />
          </el-button>
        </el-tooltip>
        <!-- CATEGORY OPTIONS -->
        <LabelCategorySettingsActions
          class="ml-2"
          :displayModifyIcon="true"
          @edit-label="editCategoryName = true"
          @trigger-delete="$emit('trigger-delete', category)"
          @edit-icon="$emit('edit-icon', category)"
        />
      </div>
    </template>
    <template slot="dropdown-content">
      <LabelCategorySettingsOverview
        :labels="companyUserLabels || []"
        :new-label="newLabel"
        @new-label="newLabel = true"
        @cancel-new-label-input="newLabel = false"
        @delete-label="$emit('delete-label', $event)"
        @create-label="handleCreateLabel"
        @update-label="$emit('update-label', $event)"
      />
    </template>
  </settings-dropdown>
</template>

<script>
import SettingsDropdown from '@/components/Settings/SettingsDropdown';
import SettingsDropdownCapitalizedColumn from '@/components/Settings/SettingsDropdownCapitalizedColumn';
import LabelCategorySettingsOverview from './LabelCategorySettingsOverview';
import LabelCategorySettingsActions from './LabelCategorySettingsActions';

export default {
  name: 'label-category-settings-dropdown',
  components: {
    LabelCategorySettingsOverview,
    LabelCategorySettingsActions,
    SettingsDropdown,
    SettingsDropdownCapitalizedColumn,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    companyUserLabels: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    count() {
      return this.companyUserLabels ? this.companyUserLabels.length : 0;
    },
    category() {
      return {
        id: this.id,
        name: this.name,
        icon: this.icon,
        color: this.color,
        companyUserLabels: this.companyUserLabels,
      };
    },
  },
  data() {
    return {
      editCategoryName: false,
      newLabel: false,
      forceOpen: false,
    };
  },
  methods: {
    handleNewLabel() {
      this.forceOpen = true;
      this.newLabel = true;
    },
    handleCreateLabel({ name }) {
      this.$emit('create-label', { companyUserLabelCategoryId: this.id, name });
      this.newLabel = false;
    },
    handleUpdateCategory(newName) {
      this.editCategoryName = false;
      if (newName === this.name) return;

      return this.$emit('update-category', { ...this.category, name: newName });
    },

    handleUpdateIconCategory(icon) {
      return this.$emit('update-category', { ...this.category, icon });
    },
    handleUpdateColorCategory(color) {
      return this.$emit('update-category', { ...this.category, color });
    },
  },
};
</script>

<style lang="scss" scoped>
.plus-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.category-name {
  width: 250px;
}

.labels-count {
  font-size: 14px;
  font-weight: 500;
  width: 100px;
}
</style>
