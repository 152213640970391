<template>
  <div :class="`${base}`">
    <WorkflowSectionTitle
      :class="`${base}__header`"
      v-if="title"
      :title="title"
      :icon="icon"
      :info="info"
    />
    <div :class="`${base}__input`">
      <slot name="input"></slot>
    </div>
    <div :class="[`${base}__footer`, 'mt-1']" v-if="footerText">
      {{ footerText }}
    </div>
  </div>
</template>
<script>
import WorkflowSectionTitle from './WorkflowSectionTitle.vue';

export default {
  name: 'workflow-input-wrapper',
  components: {
    WorkflowSectionTitle,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    info: {
      type: String,
      default: null,
    },
    footerText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      base: Object.freeze('workflow-input-wrapper'),
    };
  },
};
</script>
<style lang="scss" scoped>
.workflow-input-wrapper {
  padding: 0 4px;
  &__header {
    &__title {
      font-weight: bold;
      color: black;
    }
  }
  &__footer {
    font-size: 12px;
    font-weight: 700;
    color: $grey-8-mayday;
  }
}
</style>
