<template>
  <div class="update-user-group-modal">
    <modal :show.sync="localDisplay">
      <div class="row d-flex mt-5 justify-content-center">
        <div class="col-11 text-center">
          <modal-header
            :style-name="{ backgroundColor: '#0081F91A' }"
            class="mb-3"
          >
            <div slot="icon" class="d-inline-flex">
              <font-awesome-icon
                class="icon blue-mayday"
                :icon="['fad', 'users']"
              />
            </div>
            <div class="mt-3">
              <div>
                <p class="mb-0 font-weight-bold">
                  {{
                    $tc(
                      'components.modals.update-user-group.explaination',
                      multipleUpdate,
                    )
                  }}
                </p>
              </div>
              <el-divider class="my-3"></el-divider>
              <small class="text-primary">
                {{ $t('components.modals.update-user-group.description') }}
              </small>
            </div>
          </modal-header>
          <div class="flex-column d-flex justify-content-center subcontent">
            <p class="my-3">
              {{ $t('components.modals.update-user-group.action') }}
            </p>
            <div class="mb-4 text-center w-100" v-if="groups.length">
              <el-select
                class="large-select"
                v-model="localGroupId"
                :placeholder="
                  $t(
                    'components.modals.update-user-group.group-select-placeholder',
                  )
                "
              >
                <el-option
                  v-for="group in groups"
                  :key="group.id"
                  :label="group.name"
                  :value="group.id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div class="row"></div>
      <template slot="footer">
        <el-button type="danger" size="small" @click="$emit('close')">{{
          $t('components.modals.update-user-group.cancel-button')
        }}</el-button>
        <el-button
          type="primary"
          outline
          size="small"
          @click="$emit('update-user-group', [localGroupId])"
          >{{
            $t('components.modals.update-user-group.confirm-button')
          }}</el-button
        >
      </template>
    </modal>
  </div>
</template>
<script>
import Modal from '@/components/Modal';
import ModalHeader from '@/components/Modals/ModalHeader';

export default {
  name: 'update-user-group-modal',
  components: {
    Modal,
    ModalHeader,
  },
  props: {
    groups: Array,
    focusGroup: { type: Array },
    display: Boolean,
    multipleUpdate: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    if (this.focusGroup) {
      this.localGroupId = this.focusGroup.id;
    }
  },
  data() {
    return {
      localDisplay: this.display,
      localGroupId: null,
      isLoading: false,
    };
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.content-primary-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.05);
}

.blue-mayday {
  color: $blue-mayday;
}

.icon {
  width: 24px;
  height: 24px;
}

.large-select {
  width: 90%;
}

.subcontent {
  border: 1px solid $blue-mayday;
  border-radius: 2px;
}
</style>
