<template>
  <div>
    <div class="items-wrapper">
      <div
        class="settings-menu-list mb-4"
        v-for="item in data"
        :key="item.title"
      >
        <div class="flex-column mb-2 item-title">
          <h4 class="text-muted settings-menu-list-title">
            {{ $t(item.title) }}
          </h4>
        </div>
        <div id="menu-list d-flex">
          <ul style="padding-left: 1px">
            <li
              v-for="children in item.children"
              :key="children.title"
              class="settings-menu-list-item"
            >
              <router-link
                :to="{
                  name: children.name,
                  hash: children.hash,
                }"
              >
                <font-awesome-icon
                  class="children-icon col-3"
                  :icon="children.icon"
                ></font-awesome-icon>
                <span class="col-9">
                  <small>{{ $t(children.title) }}</small>
                </span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'settings-layout-menu',
  props: {
    data: Array,
  },
  data() {
    return {
      activeBlock: null,
      blockEls: [],
      blockBreakpoints: [],
      idBlocks: [],
    };
  },
  computed: {
    ...mapGetters('publicKnowledgeModule', ['focusCompanyPublicConfig']),
  },
};
</script>

<style lang="scss" scoped>
.settings-menu-list {
  color: $grey-7-mayday;
  ul {
    list-style-type: none;
  }
}
.settings-menu-list-title {
  text-transform: uppercase;
  left: -15px;
  top: -2px;
}
.title-icon {
  width: 18px;
  height: 18px;
}

.children-icon {
  padding: 0;
  margin: 0;
  width: 15px;
  height: 15px;
}

a {
  color: inherit;
  display: block;
  padding-left: 8px;
  padding-top: 3px;
}

.settings-menu-list-item {
  border-left: 2px solid $grey-2-mayday;
  color: $grey-8-mayday;
  &:hover {
    color: $blue-mayday;
  }
  .active {
    display: block;
    border-left: 2px solid $blue-mayday;
    padding-left: 8px;
    color: $blue-mayday;
    margin-left: -2px;
  }
}

.settings-menu-list-item:hover {
  border-left: 2px solid $grey-5-mayday;
}

.children-icon {
  color: $grey-5-mayday;
}

.items-wrapper {
  position: fixed;
  top: 96px;
  max-height: 85vh;
  overflow-y: auto;
}
</style>
