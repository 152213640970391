<template>
  <div class="actions-card">
    <div class="actions-header">
      <font-awesome-icon :icon="headerIcon" />
      <span>{{ headerTitle }}</span>
    </div>
    <div class="actions-body">
      <slot name="body" />
    </div>
    <div class="actions-footer">
      <NewActionButton v-if="displayAdd" @click.native="$emit('add-action')" />
    </div>
  </div>
</template>

<script>
import NewActionButton from '../NewActionButton';

export default {
  name: 'base-action-card',
  components: {
    NewActionButton,
  },
  props: {
    headerIcon: {
      type: Array,
      default: () => ['fal', 'comments-alt'],
    },
    headerTitle: {
      type: String,
      required: true,
    },
    displayAdd: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.actions-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.actions-card {
  font-size: 14px;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.actions-header {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
