<template>
  <div>
    <settings-dropdown icon-position="right" :with-row-divider="false">
      <template slot="title">
        <span class="title">{{ $t(permissionItem.title) }}</span>
      </template>
      <template slot="dropdown-content">
        <RolePermissionsState
          :sub-items="permissionItem.subItems"
          :role-permissions="rolePermissions"
          :company-preferences="companyPreferences"
          class="states-container"
        />
      </template>
    </settings-dropdown>
  </div>
</template>

<script>
import SettingsDropdown from '@/components/Settings/SettingsDropdown';
import RolePermissionsState from './RolePermissionsState';

export default {
  name: 'roles-drawer-side-overview-item',
  components: {
    SettingsDropdown,
    RolePermissionsState,
  },
  props: {
    permissionItem: {
      type: Object,
      default: () => ({}),
    },
    rolePermissions: {
      type: Array,
      default: () => [],
    },
    companyPreferences: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
}

.states-container {
  padding-inline: 4px;
}

:deep() .dropdown-container {
  padding-left: 4px;
  padding-top: 0;
}
</style>
