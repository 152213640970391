var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product__header col-12 pr-lg-0"},[_c('h1',{staticClass:"col-12 product__title display-3 px-0 ph-0 mb-0"},[_vm._v(" "+_vm._s(_vm.product.reference)+" ")]),_c('h2',{staticClass:"col-12 product__subtitle px-0 ls-1"},[_vm._v(_vm._s(_vm.parametersTitle))]),_c('div',{staticClass:"d-flex align-items-center"},[_c('font-awesome-icon',{staticClass:"collapse-parameter-icon mr-1",attrs:{"icon":['fad', _vm.getHierarchyByValue('codic').icon]}}),_c('div',{staticClass:"pl-2",class:{ 'border-codic': _vm.reference.values.length > 1 }},_vm._l((_vm.reference.values),function(value){return _c('div',{key:value.id,staticClass:"d-flex flex-col gap-2 small"},[_c('span',{key:value.id},[_vm._v(" "+_vm._s(value.label)+" ")])])}),0)],1),(_vm.hasDescription)?_c('div',{staticClass:"justify-content-between align-items-start pt-2 descrition-section d-none d-lg-flex",on:{"click":function($event){_vm.productDescriptionShow = !_vm.productDescriptionShow}}},[_c('p',{staticClass:"product__description section-title text-uppercase my-0"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.product.description'))+" ")]),_c('button',{class:[
        'product__button',
        _vm.productDescriptionShow ? null : 'product__button--collapsed',
      ],attrs:{"aria-expanded":_vm.productDescriptionShow ? 'true' : 'false',"aria-controls":"productDescription"}},[_c('i',{class:[
          'product__toogle-icon',
          'fa',
          _vm.productDescriptionShow
            ? 'fa-caret-square-down'
            : 'fa-caret-square-right',
        ]})])]):_vm._e(),_c('b-collapse',{staticClass:"product__manual__collapsable mt-2",attrs:{"id":"productDescription"},model:{value:(_vm.productDescriptionShow),callback:function ($$v) {_vm.productDescriptionShow=$$v},expression:"productDescriptionShow"}},[_c('ul',{staticClass:"mb-0 description-list"},_vm._l((_vm.product.strengths),function(description){return _c('li',{key:description,staticClass:"description-item"},[_vm._v(" "+_vm._s(description)+" ")])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }