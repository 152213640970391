var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DrawerRowTitle',{attrs:{"title":_vm.$t(
        'knowledge.drawers.content-view-setting.settings.access.title-private',
      ),"tooltip":_vm.$t(
        'knowledge.drawers.content-view-setting.settings.access.tooltip-private',
      )}}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"content-view-setting__groups-area w-100"},[(_vm.groupInfos.length === 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('knowledge.drawers.content-view-setting.settings.access.empty'))+" ")]):_vm._l((_vm.groupInfos),function(group){return _c('div',{key:group.id},[_c('font-awesome-icon',{staticClass:"read-icon",attrs:{"slot":"prefix","icon":['fad', 'users']},slot:"prefix"}),_vm._v(" "+_vm._s(group.name)+" ")],1)})],2),(_vm.FOR_LATER)?_c('div',{staticClass:"overflow-group w-100"},[_c('el-select',{staticClass:"icon-selector__select",staticStyle:{"width":"100%"},attrs:{"multiple":"","placeholder":_vm.$t(
            'knowledge.drawers.content-view-setting.settings.access.placeholder',
          )},on:{"change":_vm.sendUpdateGroupIdsEvent},model:{value:(_vm.newGroupIds),callback:function ($$v) {_vm.newGroupIds=$$v},expression:"newGroupIds"}},[_c('font-awesome-icon',{staticClass:"select-icon-group",attrs:{"slot":"prefix","icon":['fa', 'user']},slot:"prefix"}),_vm._l((_vm.possibleUserGroups),function(group){return _c('el-option',{key:group.id,attrs:{"label":group.name,"value":group.id}})})],2)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }