<template>
  <div class="content-view-settings-plugins">
    <DrawerRowTitle
      :title="
        $t('knowledge.drawers.content-view-setting.settings.plugins.title')
      "
      :tooltip="
        $t('knowledge.drawers.content-view-setting.settings.plugins.tooltip')
      "
    />
    <!-- RELATED CONTENT -->
    <ContentViewSettingsPublicRelated
      v-if="focusKnowledgeIsPublic && hasPlugins('RELATED_CONTENTS')"
      :related-contents="relatedContents"
      :user-permissions="userPermissions"
      @update-related-contents="$emit('update-related-contents', $event)"
    />

    <!-- PINNED CONTENT -->
    <div
      class="settings__plugin-item"
      v-if="focusKnowledgeIsPublic && hasPlugins('PINNED_CONTENTS')"
    >
      <div class="label">
        {{
          $t(
            'knowledge.drawers.content-view-setting.settings.public.pinned-content',
          )
        }}
        <el-tooltip
          :content="
            $t(
              'knowledge.drawers.content-view-setting.settings.public.pinned-content-tooltip',
            )
          "
          placement="top"
        >
          <font-awesome-icon
            :icon="['fad', 'info-circle']"
            class="info-icon mx-2"
          />
        </el-tooltip>
        <div class="toggle">
          <not-allowed-tooltip
            :user-permissions="userPermissions"
            permission-key="canUpdateContent"
            placement="top"
          >
            <template v-slot:main-content="{ hasPermissions }">
              <el-switch
                v-model="switchPinnedContent"
                @input="handlePinnedContent"
                :disabled="!hasPermissions"
              ></el-switch>
            </template>
          </not-allowed-tooltip>
        </div>
      </div>
    </div>

    <!-- CUSTOM -->
    <ContentPreviewSettingsPluginsCustom
      v-if="customPlugins.length"
      :plugins="customPlugins"
      :user-permissions="userPermissions"
      :content-plugins="contentPlugins"
      @update-content-plugins="handleUpdateContentPlugins"
    ></ContentPreviewSettingsPluginsCustom>
  </div>
</template>

<script>
import ContentViewSettingsPublicRelated from '@/components/Drawers/ContentViewSettingDrawer/ContentViewSettingsComponents/ContentViewSettingsPublic/ContentViewSettingsPublicRelated.vue';
import ContentPreviewSettingsPluginsCustom from './ContentPreviewSettingsPluginsCustom.vue';

import DrawerRowTitle from '@/components/Drawers/Commons/DrawerRowTitle';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'content-view-settings-plugins',
  components: {
    ContentViewSettingsPublicRelated,
    ContentPreviewSettingsPluginsCustom,
    NotAllowedTooltip,
    DrawerRowTitle,
  },
  props: {
    relatedContents: {
      type: Array,
      required: false,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      switchPinnedContent: false,
      pinnedContents: [],
    };
  },
  async mounted() {
    const { pinnedContents } = await this.getPinnedContents(
      this.focusKnowledgeId,
    );

    this.pinnedContents = pinnedContents;
    this.contentId = this.$route.params.contentId;
    this.switchPinnedContent = this.pinnedContents.includes(this.contentId);
  },
  computed: {
    customPlugins() {
      let customPlugins = this.focusKnowledge.plugins.filter((p) =>
        p.type.includes('CUSTOM'),
      );
      customPlugins = customPlugins.reduce((acc, val) => {
        if (this.isFocusContentEligible(val)) {
          acc.push(val);
        }
        return acc;
      }, []);
      return customPlugins;
    },

    contentPlugins() {
      return this.$route.params.stepId
        ? this.focusStep.plugins
        : this.focusContent.plugins;
    },
    ...mapGetters('knowledgeModule', [
      'focusKnowledge',
      'focusKnowledgeIsPublic',
      'focusContent',
      'focusKnowledgeId',
      'hasPlugins',
      'focusStep',
    ]),
  },
  methods: {
    handlePinnedContent() {
      const pos = this.pinnedContents.indexOf(this.contentId);
      if (pos > -1) this.pinnedContents.splice(pos, 1);
      else this.pinnedContents.push(this.contentId);

      this.updatePinnedContents({
        knowledgeId: this.focusKnowledgeId,
        pinnedContents: this.pinnedContents,
      });
    },
    handleUpdateContentPlugins({ id }) {
      this.updateContentPlugins({ id, isStep: !!this.$route.params.stepId });
    },
    isFocusContentEligible(plugin) {
      const currentContent = this.$route.params.stepId
        ? this.focusStep
        : this.focusContent;
      return (
        !plugin.settings.eligibleScope ||
        plugin.settings.eligibleScope.length === 0 ||
        plugin.settings.eligibleScope.includes(currentContent.type)
      );
    },
    ...mapActions('knowledgeModule', [
      'getPinnedContents',
      'updatePinnedContents',
      'updateContentPlugins',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.settings__plugin-item {
  margin-bottom: 12px;
}
.label {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}
.info-icon {
  color: $grey-5-mayday;
  font-size: 12px;
}
.toggle {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
