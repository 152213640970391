<template>
  <div class="knowledge-cases">
    <div class="case-section">
      <div v-for="c in rootCases" :key="c.id">
        <div
          v-if="
            activeCases === '' ||
            !path.length ||
            (path.length && activeCases === c.id)
          "
          class="case-collapse"
          :name="c.id"
        >
          <div
            slot="title"
            class="case-title d-flex align-items-center"
            :class="[c.id === activeCases ? 'active-case' : '']"
            @click="toggleCase({ id: c.id, path: [c.id], root: true })"
          >
            <font-awesome-icon
              v-if="c.id === activeCases"
              :icon="['fad', 'folder-open']"
              class="mr-2 case-icon"
            />
            <font-awesome-icon
              v-else
              :icon="['fad', 'folder']"
              class="mr-2 case-icon"
            />
            <span class="case-label">{{ `${c.label}` }}</span>
            <el-divider direction="vertical"></el-divider>
            <span class="case-count">{{ c.resolutionsCount }}</span>
          </div>
          <div class="el-collapse__main">
            <ProductViewerCaseViewer
              v-if="activeCases"
              :case-object="c"
              :cases="cases"
              :path="path"
              @return-home="toggleCase({ id: c.id, path: [c.id], root: true })"
              @get-children="loadItemOptions"
              @update-solutions="updateSolutions"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductViewerCaseViewer from './ProductViewerCaseViewer';

export default {
  name: 'product-viewer-knowledge-cases',
  components: {
    ProductViewerCaseViewer,
  },
  props: {
    cases: Array,
    path: Array,
  },
  computed: {
    rootCases() {
      return this.cases ? this.cases.filter((c) => c.root) : [];
    },
  },
  data() {
    return {
      activeCases: '',
    };
  },
  mounted() {
    this.activeCases =
      this.path.length &&
      this.rootCases.some((rootCase) => rootCase.id === this.path[0])
        ? this.path[0]
        : '';
  },
  methods: {
    toggleCase({ id, path }) {
      if (this.activeCases === id) {
        this.activeCases = '';
        this.loadItemOptions({ id, path, root: true });
      } else {
        this.activeCases = id;
        this.loadItemOptions({ id, path, root: false });
      }
    },
    loadItemOptions({ id, path, root }) {
      if (id && id !== '') {
        if (root) {
          this.$emit('reset-cases');
        } else {
          let locPath = [...path];
          this.$emit('get-children', { id, path: locPath });
        }
      } else {
        this.$emit('reset-cases');
      }
    },
    updateSolutions(path) {
      let locPath = [...path];
      this.$emit('update-solutions', locPath);
    },
  },
};
</script>

<style lang="scss" scoped>
.case-title {
  cursor: pointer;
  padding: 4px;
  &:hover {
    .case-icon {
      background-color: $grey-2-mayday;
    }
    .case-label {
      background-color: $grey-2-mayday;
    }
    .case-count {
      color: $blue-mayday;
      background-color: rgba($blue-mayday, 0.1);
    }
  }
}
.case-count {
  padding: 2px;
  line-height: 20px;
  border-radius: 4px;
}

.case-label {
  border-radius: 2px;
  padding: 2px;
  line-height: 20px;
}

.case-section {
  margin-left: 1.5rem;
}

.case-icon {
  color: $grey-6-mayday;
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 2px;
}

.active-case {
  background-color: rgba($blue-mayday, 0.1);
  .case-icon {
    color: $blue-mayday;
  }
}

.case-collapse {
  padding: 0px 0px;
  border-left: 2px solid $grey-4-mayday;
  &:hover {
    border-color: $blue-mayday;
    background-color: $grey-1-mayday;
  }
  :deep() .el-collapse-item__header {
    border-bottom: 1px solid transparent !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  :deep() .el-collapse__main {
    border: none;
  }
  :deep() .text-edition {
    padding: 8px !important;
  }
  :deep() .editor__content {
    margin: 16px 0px;
    border: 1px solid $grey-2-mayday;
    border-radius: 4px;
    background-color: $grey-1-mayday;
  }
}

.el-collapse__main {
  border-left: 2px solid $grey-2-mayday;
  padding-left: 16px;
}
</style>
