<template>
  <div ref="skeleton" class="skeleton" :style="skeletonWidth"></div>
</template>

<script>
export default {
  name: 'case-viewer-header',
  data() {
    return {
      width: 100,
    };
  },
  mounted() {
    const el = this.$refs.skeleton;
    this.width = el.clientWidth;
  },
  computed: {
    skeletonWidth() {
      return {
        '--skeletonWidth': `${this.width}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.skeleton {
  background-image: linear-gradient(
    90deg,
    $grey-2-mayday calc(40% - 12px),
    $grey-1-mayday 50%,
    $grey-2-mayday calc(60% + 12px)
  );
  background-size: var(--skeletonWidth) 100%;
  background-position-x: -12px;
  animation: 1s loading ease-in infinite;
  border-radius: 2px;
}

@keyframes loading {
  to {
    background-position-x: calc(var(--skeletonWidth) - 12px);
  }
}
</style>
