const flat = (obj, childKey) => {
  let result = [];
  const children = obj[childKey];
  children.forEach(function(a) {
    result.push(a);
    if (Array.isArray(a[childKey])) {
      result = result.concat(flat(a, childKey));
    }
  });
  return result;
};

export default flat;
