<template>
  <div class="date-filter-picker">
    <div
      v-if="mainOptions.length > 1"
      class="d-flex flex-row align-items-center justify-content-between header"
    >
      <div class="main-option-picker">
        <el-select
          v-model="filter.key"
          popper-class="mt-0"
          :placeholder="$t('knowledge.search-admin.quick-filters.choose')"
        >
          <el-option
            class="main-option-item"
            v-for="option in mainOptions"
            :key="option.key"
            :label="
              $t(
                `knowledge.search-admin.quick-filters.dates.main-options.${option.key}`,
              )
            "
            :value="option.key"
          ></el-option>
        </el-select>
      </div>
      <div class="clear-button" @click="resetFilter">
        {{ $t('knowledge.search-admin.quick-filters.clear') }}
      </div>
    </div>

    <hr class="m-0 mb-1" v-if="filter.key && mainOptions.length > 1" />
    <div class="sub-options-wrapper mb-1" v-if="filter.key">
      <div
        v-for="option in subOptions"
        :key="option.key"
        class="sub-option"
        :class="{ 'sub-option__selected': filter.value.key === option.key }"
        @click="filter.value = { key: option.key, value: option.value }"
      >
        <font-awesome-icon :icon="['fal', 'calendar-alt']" class="icon mr-2" />
        {{ $t(option.label) }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'date-filter-picker',
  props: {
    chosenDates: {
      type: Object,
      default: () => {},
    },
    mainOptions: {
      type: Array,
      default: () => [],
    },
    subOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filter: {
        key: this.mainOptions[0].key,
        value: {
          key: '',
          value: {},
        },
      },
    };
  },
  components: {},
  created() {
    if (
      this.chosenDates &&
      this.chosenDates.key &&
      this.chosenDates.value &&
      this.chosenDates.value.gte &&
      this.chosenDates.value.lte
    ) {
      this.filter.key = this.chosenDates.key;
    }
  },
  computed: {},
  methods: {
    handleFilterChange() {
      if (
        this.filter.key != null &&
        this.filter.value &&
        this.filter.value.key != null &&
        this.filter.value.value
      ) {
        this.$emit('set-dates', this.filter);
        this.$emit('quit-dates');
      }
    },
    resetFilter() {
      this.filter = {
        key: '',
        value: {
          key: '',
          value: {},
        },
      };
      this.$emit('set-dates', this.filter);
    },
  },

  watch: {
    'filter.key': {
      handler() {
        this.handleFilterChange();
      },
      deep: true,
    },
    'filter.value': {
      handler() {
        this.handleFilterChange();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.date-filter-picker {
  background-color: white;
}
.header {
  padding: 4px 8px;
}
.sub-options-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.sub-option {
  padding: 12px 12px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 24px;
  font-size: 14px;
  color: black;
  gap: 4px;
  &:hover {
    background-color: $grey-3-mayday;
    cursor: pointer;
  }
  &__selected {
    background-color: $grey-3-mayday;
    color: $blue-mayday;
  }
}
:deep() .el-select {
  .el-input .el-input__inner {
    height: 24px;
    border: none;
    display: flex;
    align-items: center;
    padding-left: 4px;
    font-weight: 600;
    font-size: 12px;
  }
}

:deep() .el-input__suffix {
  display: flex;
  align-items: center;
}

.clear-button {
  color: $grey-4-mayday;
  cursor: pointer;
}

.main-option-item {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 24px;

  padding: 4px 0px 4px 4px;
}
</style>
