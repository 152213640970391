<template>
  <div @click="handleClickOnStep" class="content-preview-diagnostic-step">
    <div v-if="redirection" class="icon-wrapper">
      <font-awesome-icon
        class="key-step-icon my-auto mr-2"
        :icon="['fa', 'external-link-square']"
      ></font-awesome-icon>
    </div>

    <TextEllipsis :text="label" :font-size="'14px'"></TextEllipsis>
  </div>
</template>

<script>
import TextEllipsis from '../../TextEllipsis.vue';

export default {
  name: 'content-preview-diagnostic-step',
  components: { TextEllipsis },
  props: {
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    redirection: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    handleClickOnStep() {
      if (this.redirection) {
        this.$emit('redirection', {
          entityId: this.redirection.entityId,
        });
      } else {
        this.$emit('click-on-step', { entityId: this.id });
      }
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.content-preview-diagnostic-step {
  padding: 8px;
  border: 1px solid $blue-mayday;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $blue-mayday;
  &:hover {
    background-color: $blue-mayday;
    color: white;
    cursor: pointer;
  }
}
</style>
