<template>
  <div
    class="md-avatar d-flex align-items-center"
    :style="[colorStyle, shadow ? shadowStyle : '', avatarSize]"
  >
    <span v-if="!avatar" class="mx-auto">
      {{ initial }}
    </span>
    <img v-else :src="avatar" alt="User Avatar" class="md-avatar-img mx-auto" />
  </div>
</template>

<script>
import { userColor } from 'utils/utils';

export default {
  name: 'UserAvatar',
  props: {
    color: {
      type: String,
      default: null,
    },
    imgSrc: {
      type: String,
      default: null,
    },
    username: {
      type: String,
      default: null,
    },
    size: {
      type: String || Number,
      default: 'large',
    },
    shadow: {
      type: Boolean,
      default: true,
    },
    avatar: {
      type: String,
      default: null,
    },
  },
  computed: {
    colorStyle() {
      if (this.color) {
        return {
          background: this.color,
          color: 'white',
        };
      }
      return {
        background:
          this.username !== null && this.username !== '?'
            ? userColor(this.username, 70, 50)
            : '#0081f9',
      };
    },
    initial() {
      if (!this.username) return '?';
      return this.username[0].toUpperCase();
    },
    avatarSize() {
      let sizes = {
        width: '40px',
        height: '40px',
      };
      switch (this.size) {
        case 'medium':
          sizes = {
            width: '35px',
            height: '35px',
          };
          break;
        case 'small':
          sizes = {
            width: '30px',
            height: '30px',
          };
          break;
        case 'xsmall':
          sizes = {
            width: '18px',
            height: '18px',
            'font-size': '10px',
          };
          break;
        default:
          sizes = { ...sizes, border: '4px solid white' };
          break;
      }

      return sizes;
    },
    shadowStyle() {
      return {
        'box-shadow': `0 0 10px ${this.color}50`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.md-avatar {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  background: #c0c4cc;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-weight: bold;
}
.md-avatar-img {
  border-radius: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
