const articleOptions = [
  'import',
  'labels',
  'linked',
  'attachments',
  'notifications',
];

const diagnosticOptions = [
  'logigram',
  'labels',
  'keyStep',
  'trigger',
  'linked',
  'attachments',
  'notifications',
  'automations',
];

const publishedOptions = ['labels', 'keyStep', 'notifications'];

export const sideBarDisplayOptions = ({
  contentType,
  isFirst,
  isKeyStep,
  isPublished,
  hasRestriction,
  isParametric = false,
}) => {
  if (!contentType) return {};
  const options =
    contentType === 'article' ? articleOptions : diagnosticOptions;
  let displayOptions = options.reduce((acc, option) => {
    acc[option] = true;
    return acc;
  }, {});
  if (!isPublished) {
    displayOptions = publishedOptions.reduce((acc, option) => {
      acc[option] = false;
      return acc;
    }, displayOptions);
    displayOptions['labels'] = true;
  } else if (contentType === 'diagnostic') {
    if (!(isFirst || isKeyStep || !hasRestriction)) {
      displayOptions['labels'] = false;
    } else if (!((!isFirst || isKeyStep) && hasRestriction)) {
      displayOptions['keyStep'] = false;
    }
  }
  if (isParametric) {
    [
      // 'labels',
      // 'notifications',
      'import',
      'automations',
      'trigger',
    ].forEach((option) => (displayOptions[option] = false));
  }
  return displayOptions;
};
