<template>
  <div class="integration-header d-flex">
    <div class="holder">
      <div class="integration-header-logo">
        <font-awesome-icon v-if="isFa" class="integration-icon" :icon="logo" />
        <img v-else class="integration-icon" :src="logo[1]" />
      </div>
    </div>

    <div
      class="
        integration-header-info
        d-flex
        flex-column
        justify-content-center
        py-4
      "
    >
      <p class="title mb-0">{{ title }}</p>
      <p class="description mb-0">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'integration-settings-drawer-header',
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    logo: {
      type: Array,
      required: true,
      default() {
        return ['fa', 'comments'];
      },
    },
  },
  computed: {
    isFa() {
      return this.logo[0].startsWith('fa');
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.integration-header {
  max-height: 8.5rem;
  background-color: #f8fafe;
  border-bottom: 1px solid $grey-4-mayday;
  position: relative;
}

.holder {
  width: 200px;
  flex: 0 0 auto;
}

.integration-header-info {
  margin-right: 30px;
  .title {
    color: black;
    font-size: 28px;
    font-weight: bold;
  }
  .description {
    color: $grey-8-mayday;
    font-size: 75%;
  }
}

.integration-header-logo {
  position: absolute;
  background-color: white;
  border-radius: 8px;
  box-shadow: 15px 17px 40px #ebebeb;
  width: 8.5rem;
  height: 8.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 25px;
  left: 30px;
  z-index: 1;

  .integration-icon {
    object-fit: contain;
    width: 5.5rem;
    height: 5.5rem;
  }
}
</style>
