<template>
  <!-- RELATED CONTENT -->
  <div class="search-other-diag p-2">
    <div class="search-container" v-if="displayRelated">
      <!-- DIAGNOSTICS SEARCH -->
      <el-input
        v-model="query"
        @input="debounceSearch"
        :placeholder="$t('knowledge.actions.step-mover.search.placeholder')"
        prefix-icon="el-icon-search"
        size="mini"
      />
      <div class="search-wrapper">
        <div
          v-if="query && searchLoading"
          class="flex justify-content-center h-100 w-100 align-items-center"
        >
          <font-awesome-icon
            :icon="['fal', 'spinner-third']"
            spin
            class="loader"
          />
        </div>
        <div v-else-if="query" class="result-section">
          <div
            v-for="(content, index) in results"
            :key="content.id"
            class="search-item pointer py-2"
            :class="[
              index === hovered ? 'search-item-hovered' : '',
              { 'is-focus': focusedOption && focusedOption.id === content.id },
            ]"
            @mouseenter="onHover(index)"
            @mouseleave="hovered = -1"
            @click="$emit('focus-content', content)"
          >
            <div class="d-flex flex-row align-items-center">
              <div class="mx-2 content-icon">
                <img
                  v-if="content.type === 'Article'"
                  src="~assets/article-icon-v2.svg"
                  class="content-search-img"
                />
                <img
                  v-else-if="content.type === 'Diagnostic'"
                  src="~assets/diag-icon-v2.svg"
                  class="content-search-img"
                />
                <img
                  v-else
                  slot="prefix"
                  src="~assets/diag-step-v2.svg"
                  class="content-search-img"
                />
              </div>
              <TextEllipsis :text="content.label" font-size="12px" />
            </div>
            <div class="chevron-wrapper">
              <font-awesome-icon
                v-if="content.children.length"
                :icon="['fal', 'chevron-right']"
                class="chevron mr-2"
              />
            </div>
          </div>
          <div v-if="!results.length && query.length">
            {{ $t('knowledge.actions.step-mover.search.empty') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapGetters, mapActions } from 'vuex';
import TextEllipsis from '@/components/TextEllipsis.vue';

export default {
  name: 'step-mover-search',
  components: {
    TextEllipsis,
  },
  props: {
    relatedContents: {
      type: Array,
      required: false,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
    searchedContentTypes: {
      type: Array,
      default: () => ['Diagnostic'],
    },
    focusedOption: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      displayRelated: true,
      query: '',
      relatedList: this.relatedContents,
      results: [],
      searchLoading: false,
      drag: false,
      dragId: null,
      hovered: -1,
    };
  },
  computed: {
    relatedContentIds() {
      return this.relatedList.map((content) => content.id);
    },
    ...mapGetters('knowledgeModule', ['focusKnowledgeId', 'focusContent']),
  },
  watch: {
    relatedList() {
      this.$emit('update-related-contents', this.relatedContentIds);
    },
    query() {
      if (this.query === '') this.hovered = -1;
    },
  },
  mounted() {
    document.addEventListener('keydown', this._keyListener.bind(this));
  },
  methods: {
    _keyListener(e) {
      if (e.key === 'ArrowUp') {
        this.hovered =
          this.hovered > 0 ? this.hovered - 1 : this.results.length - 1;
      } else if (e.key === 'ArrowDown') {
        this.hovered =
          this.hovered < this.results.length - 1 ? this.hovered + 1 : 0;
      }
    },
    onHover(index) {
      this.hovered = index;
    },
    debounceSearch: debounce(function () {
      return this.handleSearch();
    }, 200),
    async handleSearch() {
      if (this.query === '') return;
      this.searchLoading = true;
      this.results = await this.handleSearchContents({
        query: this.query,
        contentTypes: this.searchedContentTypes,
      });
      this.searchLoading = false;
    },
    handleStart() {},
    ...mapActions('knowledgeModule', ['handleSearchContents']),
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}

.search-wrapper {
  overflow: auto;
  height: 200px;
  padding: 4px 0px;
}

.search-item {
  max-width: 100%;
  min-width: 0;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  padding: 2px 0 2px 0;
  border-radius: 4px;

  .content-icon {
    width: 14px;
    height: 14px;
    align-items: center;
    justify-content: center;
    img {
      width: 14px;
      height: 14px;
    }
    .content-img {
      display: none;
    }
    .content-search-img {
      display: flex;
    }
  }

  &.is-focus {
    color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.4);
  }
}

.search-item-hovered {
  background-color: rgba($blue-mayday, 0.1);
}

.pointer {
  cursor: pointer;
}

.published-tooltip {
  cursor: default;
  margin-top: 6px;
}

.settings__public-item {
  margin-bottom: 12px;
}

.settings__public-cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}

.loader {
  width: 32px;
  height: 32px;
  color: $blue-mayday;
}

.chevron-wrapper:hover {
  color: $blue-mayday;
  cursor: pointer;
}

.chevron {
  width: 3.75px;
  height: 7, 5px;
}
</style>
