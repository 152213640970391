<template>
  <section class="collapse-section">
    <div
      class="col-12 d-flex product-viewer-collapsable justify-content-between align-items-center pr-md-0 my-2"
      @click="productManualShow = !productManualShow"
    >
      <p class="section-title text-uppercase">
        <font-awesome-icon class="mr-1" :icon="['fad', 'link']" />
        {{ $t('knowledge.product.manuals') }} | {{ count }}
      </p>
      <button
        :class="[
          'product__button',
          productManualShow ? null : 'product__button--collapsed',
        ]"
        :aria-expanded="productManualShow ? 'true' : 'false'"
        aria-controls="productManual"
      >
        <i
          :class="[
            'product__toogle-icon',
            'fa',
            productManualShow
              ? 'fa-caret-square-down'
              : 'fa-caret-square-right',
          ]"
        ></i>
      </button>
    </div>
    <b-collapse
      v-model="productManualShow"
      id="productManual"
      class="product__attached__collapsable"
    >
      <p v-if="!attachedFiles.length" class="ml-3 empty-state-alert">
        Il n'y a pas de fichiers attachés sur ce produit
      </p>
      <ul
        id="product__attached"
        :class="['product__list', productManualShow ? 'show' : '']"
        v-loading="areAttachedFilesLoading"
      >
        <li
          v-for="attachedFile in attachedFiles"
          :key="attachedFile.id"
          class="product__attached__item-row"
        >
          <div
            class="product__attached__link d-flex align-items-center full-width justify-content-between"
            @click="$emit('focus-content', attachedFile.id)"
          >
            <div class="d-flex align-items-center">
              <CommonNodeStatus :content="attachedFile" :unclickable="true" />
              <img
                v-if="attachedFile.type === 'Article'"
                src="~assets/article-icon-v2.svg"
                class="content-icon mx-2"
              />
              <img
                v-if="attachedFile.type === 'Diagnostic'"
                src="~assets/diag-icon-v2.svg"
                class="content-icon mx-2"
              />
              {{ attachedFile.label }}
            </div>
            <div v-if="attachedFile.labels">
              <ContentItemLabel
                v-for="label in attachedFile.labels"
                :key="label.id"
                :label="label"
              />
            </div>
          </div>
        </li>
      </ul>
      <span
        v-if="!moreOptions && count > 20"
        class="text-muted display-more"
        @click="displayMoreSolutions"
        >
        <font-awesome-icon class="mr-1 ml-4" :icon="['fad', 'eye']" />
        {{ $t('knowledge.product.display-more') }}
        </span
      >
      <span
        v-else-if="moreOptions && count > 20"
        class="text-muted display-more"
        @click="displayLessSolutions"
        >
        <font-awesome-icon class="mr-1 ml-4" :icon="['fad', 'eye']" />
        {{ $t('knowledge.product.display-less') }}
        </span
      >
    </b-collapse>
  </section>
</template>

<script>
import CommonNodeStatus from '@/views/KnowledgeEditor/NavigationBar/Navigator/NavigatorNode/CommonNode/CommonNodeStatus.vue';
import ContentItemLabel from '@/components/Commons/ContentItemLabel';

export default {
  name: 'attached-files',
  components: {
    CommonNodeStatus,
    ContentItemLabel,
  },
  props: {
    attachedFiles: {
      type: Array,
      required: true,
    },
    count: {
      type: Number,
      default: 0,
    },
    areAttachedFilesLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productManualShow: true,
      moreOptions: false,
    };
  },
  methods: {
    displayMoreSolutions() {
      this.$emit('display-more-attached-files');
      this.moreOptions = true;
    },
    displayLessSolutions() {
      this.$emit('display-less-attached-files');
      this.moreOptions = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  color: $grey-6-mayday;
  cursor: pointer;
  margin-bottom: 0px;
}

.product__list {
  margin-left: 24px;
  padding-left: 0px !important;
  list-style-type: none;
}

.product__button {
  background-color: transparent;
  border: none;
  color: $blue-mayday;
}

.product__button--collapsed {
  color: $grey-5-mayday;
}

.product__attached__collapsable,
.product__attached__item-row {
  width: 100%;
  .product__attached__link {
    border-left: 2px solid $grey-4-mayday;
    padding: 4px 12px;
    width: 100%;
    color: $grey-9-mayday;
    cursor: pointer;
    &:hover {
      border-color: $yellow-mayday;
      background-color: $grey-1-mayday;
    }
  }
}

.content-icon {
  max-height: 16px;
  max-width: 16px;
}

.empty-state-alert {
  color: $grey-5-mayday;
}

.display-more {
  cursor: pointer;
  &:hover {
    color: $blue-mayday !important;
  }
}
</style>
