<template>
  <el-checkbox-group
    v-model="checkedGroupIds"
    class="group-viewer"
    @change="
      $emit('update-scope', $event && $event.length ? { groupIds: $event } : {})
    "
  >
    <div class="row-container" v-for="group in groups" :key="group.id">
      <el-checkbox :label="group.id" size="mini" class="checkbox-button">
        <div class="icon-wrapper">
          <font-awesome-icon :icon="['fas', 'users']" class="icon" />
        </div>
        <strong>{{ group.name }}</strong>
      </el-checkbox>
    </div>
  </el-checkbox-group>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'role-drawer-group-viewer',
  props: {
    scope: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.checkedGroupIds = this.scope.groupIds || [];
  },
  data() {
    return {
      checkedGroupIds: [],
    };
  },
  computed: {
    ...mapGetters('adminModule', ['groups']),
  },
  watch: {
    scope() {
      this.checkedGroupIds = this.scope.groupIds || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.group-viewer {
  display: flex;
  flex-direction: column;
  padding-block: 4px;
  padding-inline: 8px;
  background-color: $grey-1-mayday;
}
.icon {
  color: white;
  width: 11px;
}
.icon-wrapper {
  background-color: $grey-4-mayday;
  border-radius: 2px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

label {
  margin-bottom: 0;
  font-size: 13px;
}
.row-container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  &:not(:last-child) {
    border-bottom: 1px solid $grey-4-mayday;
  }
}
.checkbox-button {
  display: flex;
  align-items: center;
  margin: 0px;
  :deep() .el-checkbox__label {
    display: flex;
    gap: 8px;
    color: black;
  }
  &.is-checked {
    :deep() .el-checkbox__inner {
      border-radius: 2px;
      background-color: $blue-mayday;
      box-sizing: border-box;
      box-shadow: 0 0 0 2px #f3f5f6 inset;
      border: 1px solid $blue-mayday;
      &:after {
        display: none;
      }
    }
  }
}
</style>
