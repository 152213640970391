<template>
  <div class="company-synonyms-page">
    <!-- HEADER -->
    <div
      class="company-synonyms-header d-flex align-items-center justify-content-between sticky-top"
    >
      <div class="d-flex flex-row align-items-center">
        <div
          class="header-icon-wrapper mr-3"
          @click="$router.push('/settings/search')"
        >
          <font-awesome-icon
            class="invitations-header-icon"
            :icon="['fal', 'arrow-left']"
          />
        </div>
        <span class="company-synonyms-title">
          {{ $t('settings.search.synonyms.title') }}
        </span>
      </div>
      <div class="company-synonym-header-end">
        <div v-if="synonymsAggregation.length" class="company-synonym-search">
          <font-awesome-icon :icon="['fal', 'search']" />
          <input v-model="search" class="company-synonym-search-input" />
          <font-awesome-icon
            v-if="search"
            class="company-synonym-search-close"
            :icon="['fal', 'times']"
            @click="search = ''"
          />
        </div>

        <el-button
          @click="(creating = true), (search = '')"
          type="primary"
          size="small"
          :disabled="creating"
        >
          <font-awesome-icon :icon="['fal', 'plus-square']" class="mr-2" />
          {{ $t('settings.search.synonyms.add') }}
        </el-button>
      </div>
    </div>

    <div class="company-synonym-main">
      <CompanySynonymsTable
        class="company-synonym-body"
        :synonyms="synonymsAggregation"
        :creating="creating"
        :search="search"
        @created="creating = false"
        @create="(creating = true), (search = '')"
        @search="search = $event"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CompanySynonymsTable from './CompanySynonymsTable';

export default {
  name: 'synonyms-settings',
  components: {
    CompanySynonymsTable,
  },
  data() {
    return {
      search: '',
      creating: false,
    };
  },
  computed: {
    synonymsAggregation() {
      return this.companySynonyms.reduce((acc, val) => {
        const idx = acc.findIndex(({ aSynonym }) => aSynonym === val.aSynonym);
        if (idx > -1) {
          acc[idx].bSynonyms.push(val);

          return acc;
        }
        acc.push({ ...val, bSynonyms: [val] });
        return acc;
      }, []);
    },
    ...mapGetters('adminModule', ['companySynonyms']),
  },
  async created() {
    await this.getCompanySynonyms();
  },
  methods: {
    ...mapActions('adminModule', ['getCompanySynonyms']),
  },
};
</script>

<style lang="scss" scoped>
.company-synonyms-title {
  font-size: 24px;
  font-weight: bold;
}

.company-synonyms-header {
  background-color: white;
  padding: 16px;
  max-width: 100%;
  min-width: calc(100% - 60px);
  max-height: 64px;
  position: fixed;
  z-index: 10 !important;
}

.header-icon-wrapper {
  width: 36px;
  height: 36px;
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: $blue-mayday;
    color: white;
  }
  transition: all 150ms ease-in-out;
}

.invitations-header-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.company-synonyms-footer {
  background-color: white;
  padding: 16px;
  max-width: 100%;
  min-width: calc(100% - 60px);
  max-height: 64px;
  position: fixed;
  bottom: 0;
  z-index: 10 !important;
}

.company-synonym-main {
  padding: 64px 0;
  margin: 0 68px;
  overflow-y: auto;
}

.company-synonym-infos {
  margin-top: 16px;
  max-width: 512px;
  .title {
    font-size: 20px;
    font-weight: bold;
  }
  .description {
    color: $grey-5-mayday;
    font-size: 12px;
  }
}

.company-synonym-body {
  margin-top: 16px;
}

.company-synonym-header-end {
  display: flex;
  gap: 8px;
}

.company-synonym-search {
  display: flex;
  align-items: center;
  width: 300px;
  gap: 4px;
  border: 1px solid $grey-3-mayday;
  border-radius: 4px;
  font-size: 14px;
  padding: 4px 8px;
  opacity: 0.8;

  &:focus-within {
    opacity: 1;
    box-shadow: 1px 0 2px 2px rgba(157, 157, 157, 0.1);
  }

  &-input {
    border: none;
    outline: none;
    width: 100%;
  }

  &-close {
    cursor: pointer;
    color: $grey-7-mayday;
    &:hover {
      color: black;
    }
  }
}
</style>
