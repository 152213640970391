<template>
  <div class="d-flex flex-column">
    <div class="element-title">
      {{ $t('settings.search.synonyms.element-title') }}
    </div>
    <div class="mb-2 element-description">
      {{ $t('settings.search.synonyms.element-description') }}
    </div>
    <!-- USER CARD -->

    <div class="d-flex flex-row align-items-end">
      <router-link to="/settings/synonyms">
        <el-button class="invite-button" type="primary" size="small">
          {{ $t('settings.search.synonyms.manage') }}
        </el-button>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'synonyms-settings-card',
};
</script>

<style lang="scss" scoped>
.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
</style>
