<template>
  <div>
    <div
      class="sub-collection composition"
      v-for="(child, idx) in childrenCount"
      :key="child.type"
    >
      <img :src="child.icon" class="icon-w-16 mr-2" />
      <small class="text-muted">{{ `${child.count} ${child.label}` }}</small>
      <el-divider
        direction="vertical"
        class="mx-2"
        v-if="idx !== 2"
      ></el-divider>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

const articleIcon = require('assets/article-icon-v2.svg');
const diagIcon = require('assets/diag-icon-v2.svg');
const folderIcon = require('assets/close-folder.svg');

export default {
  name: 'folder-children-count',
  props: {
    folder: {
      type: Object,
      default: () => ({ path: [] }),
    },
  },
  computed: {
    childrenCount() {
      const children = this.folder.path.map((id) =>
        this.treeNodes.find((o) => String(o.id) === id),
      );
      return children.length > 0
        ? children.reduce(
            (count, child) => {
              const childObj = count.find((c) => c.type === child.type);
              childObj['count']++;
              return count;
            },
            [
              {
                type: 'Folder',
                label: this.$tc('main.collection', 2),
                count: 0,
                icon: folderIcon,
              },
              {
                type: 'Article',
                label: this.$tc('main.article', 2),
                count: 0,
                icon: articleIcon,
              },
              {
                type: 'Diagnostic',
                label: this.$tc('main.diagnostic', 2),
                count: 0,
                icon: diagIcon,
              },
            ],
          )
        : [
            {
              type: 'Folder',
              label: this.$tc('main.collection', 2),
              count: 0,
              icon: folderIcon,
            },
            {
              type: 'Article',
              label: this.$tc('main.article', 2),
              count: 0,
              icon: articleIcon,
            },
            {
              type: 'Diagnostic',
              label: this.$tc('main.diagnostic', 2),
              count: 0,
              icon: diagIcon,
            },
          ];
    },
    ...mapState('kbStore', ['treeNodes']),
  },
};
</script>
<style lang="scss" scoped>
.icon-w-16 {
  max-width: 16px;
}
</style>
