<template>
  <div>
    <el-form v-if="mode.input" enctype="multipart/form-data">
      <el-upload
        class="my-3 upload-demo"
        drag
        action=""
        :on-change="setImage"
        :auto-upload="false"
        :show-file-list="false"
        accept="image/*"
      >
        <img src="~assets/empty-contents.svg" width="100px" />
        <div class="el-upload__text">
          {{ $t('knowledge.modals.contribution.drop-file') }}
          <em>{{ $t('knowledge.modals.contribution.upload') }}</em>
        </div>
      </el-upload>
    </el-form>
    <div class="content">
      <section class="cropper-area">
        <div class="img-cropper">
          <vue-cropper
            ref="cropper"
            v-bind="{ 'aspect-ratio': aspectRatio, 'auto-crop-area': 1 }"
            :src="imgSrc"
            :class="[{ hidden: !mode.crop }, 'cropper-display']"
            :zoomOnWheel="false"
          />
        </div>
        <div v-if="mode.crop" class="actions d-flex flex-column">
          <div
            class="d-flex flex-row align-items-center justify-content-center image-manipulation"
          >
            <el-tooltip
              :content="$t('knowledge.modals.edit-icon.tooltip-zoom')"
              placement="bottom"
              ><el-slider
                v-model="zoomValue"
                @input="handleZoom"
                :show-tooltip="false"
                :max="200"
                class="zoom-slider"
              ></el-slider>
            </el-tooltip>

            <el-tooltip
              :content="$t('knowledge.modals.edit-icon.tooltip-rotate-left')"
              placement="top"
              ><font-awesome-icon
                :icon="['fas', 'undo']"
                @click.prevent="rotate(-90)"
                role="button"
                class="mx-1 action-icons"
              ></font-awesome-icon>
            </el-tooltip>

            <el-tooltip
              :content="$t('knowledge.modals.edit-icon.tooltip-rotate-right')"
              placement="top"
            >
              <font-awesome-icon
                :icon="['fas', 'redo']"
                @click.prevent="rotate(90)"
                role="button"
                class="mx-1 action-icons"
              ></font-awesome-icon>
            </el-tooltip>
          </div>
          <div
            class="d-flex flex-row align-items-center justify-content-end mt-3"
          >
            <el-tooltip
              :content="$t('knowledge.modals.edit-icon.tooltip-trash')"
              placement="bottom"
            >
              <el-button @click.prevent="reset" size="mini">
                {{ $t('knowledge.modals.edit-icon.button-return') }}
              </el-button>
            </el-tooltip>
            <el-tooltip
              :content="$t('knowledge.modals.edit-icon.tooltip-validate')"
              placement="bottom"
            >
              <el-button type="primary" @click.prevent="cropImage" size="mini">
                {{ $t('knowledge.modals.edit-icon.button-continuate') }}
              </el-button>
            </el-tooltip>
          </div>
        </div>
      </section>
    </div>

    <div v-if="mode.display" class="d-flex flex-column">
      <div
        class="d-flex flex-row justify-content-center align-items-baseline cropped-img-display"
        @dblclick="cropImg = ''"
      >
        <el-tooltip content="double cliquez pour éditer">
          <img :src="cropImg" alt="Cropped Image" class="cropper-display" />
        </el-tooltip>
      </div>
      <div class="d-flex flex-row justify-content-end mt-3">
        <el-tooltip content="refaire">
          <el-button size="mini" @click.prevent="cropImg = ''">
            {{ $t('knowledge.modals.edit-icon.button-return') }}
          </el-button>
        </el-tooltip>
        <el-tooltip content="valider">
          <el-button
            type="primary"
            size="mini"
            @click.prevent="$emit('validate-cropped-attachment')"
          >
            <span v-if="!loading">{{
              $t('knowledge.modals.edit-icon.button-validate')
            }}</span>
            <font-awesome-icon v-else :icon="['far', 'spinner']" spin />
          </el-button>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.min.css';

export default {
  name: 'image-cropper',
  components: {
    VueCropper,
  },
  data() {
    return {
      fileList: [],
      cropImg: '',
      imgSrc: '',
      cropImgBlob: null,
      zoomValue: 0,
      oldZoomValue: 0,
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      required: false,
    },
  },
  computed: {
    mode() {
      return {
        input: !this.imgSrc.length > 0 && !this.cropImg.length > 0,
        crop: !this.cropImg.length > 0 && this.imgSrc.length > 0,
        display: this.cropImg.length > 0 && this.imgSrc.length > 0,
      };
    },
  },
  methods: {
    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.cropImgBlob = this.$refs.cropper.getCroppedCanvas({
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
      });
      this.cropImgBlob.toBlob(
        (blob) => {
          this.$emit('update-attachments', [blob]);
        },
        'image/png',
        1,
      );
    },
    reset() {
      this.cropImg = '';
      this.imgSrc = '';
      this.$emit('update-attachments', []);
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setImage(e) {
      const file = e.raw;
      if (file.type.indexOf('image/') === -1) {
        this.$message({
          type: 'error',
          text: this.$t('knowledge.modals.edit-icon.not-image-file'),
        });
        return;
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        this.$message({
          type: 'error',
          text: this.$t('knowledge.modals.edit-icon.filereader-error'),
        });
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    handleZoom(val) {
      this.zoom((val - this.oldZoomValue) / 100);
      this.oldZoomValue = val;
    },
  },
  watch: {
    mode() {
      this.$emit('display-validate', this.mode.crop ? false : true);
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  width: 1024px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
}

.header h2 {
  margin: 0;
}

.header a {
  text-decoration: none;
  color: black;
}

.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}

.actions {
  margin-top: 1rem;
}
.image-manipulation {
  width: 100%;
}
:deep() .el-slider {
  width: 50%;
  margin-right: 10px;
  margin-left: 55px;
}

.action-icons {
  color: $grey-6-mayday;
  &:hover {
    color: $blue-mayday;
    cursor: pointer;
  }
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}

.upload-demo {
  :deep() .el-upload {
    width: 100%;
  }

  :deep() .el-upload-dragger {
    width: 100%;
    height: 150px;
    padding: 16px;
  }
}

.cropped-img-display {
  cursor: pointer;
}

.cropper-display {
  width: 100%;
  max-height: 50vh;
}
</style>
