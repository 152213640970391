<template>
  <div class="mayday-feedback-editor text-edition text-left">
    <mayday-editor
      v-if="showEditor"
      ref="feedback-editor"
      v-model="content"
      :editable="editable"
      :options="options"
      @update="onUpdate"
      @click:open-link="handleOpenLink"
    />
  </div>
</template>
<script>
export default {
  name: 'MaydayFeedbackEditor',
  props: {
    placeholder: {
      type: String,
      default: 'knowledge.modals.notifications.message.placeholder',
    },
    message: String,
    editable: {
      type: Boolean,
      default: true,
    },
    isContribution: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: '',
      options: {
        fontOptions: true,
        structurationOptions: true,
        tableOptions: false,
        richOptions: false,
        messageOptions: true,
        extraStructurationOptions: false,
        extraFontOptions: true,
        tocClickOptions: false,
        allowToc: false,
        colorOptions: true,
        hideDragHandle: true,
      },
      showEditor: true,
    };
  },
  created() {
    this.setEditorContent(this.message);
  },
  methods: {
    handleOpenLink({ href }) {
      window.open(href, '_blank');
    },
    onUpdate(message) {
      this.$emit('input', message === '<p></p>' ? '' : message);
    },
    setEditorContent(message) {
      this.showEditor = false;
      this.$nextTick(() => {
        this.showEditor = true;
        this.$nextTick(() => {
          const maydayEditor = this.$refs['feedback-editor'];
          this.content = message;
          if (maydayEditor) {
            maydayEditor.editor.commands.setContent(this.content);
          }
        });
      });
    },
    focusEditor() {
      const maydayEditor = this.$refs['feedback-editor'];
      if (maydayEditor) {
        maydayEditor.editor.commands.focus();
      }
    },
  },
  watch: {
    editable() {
      const maydayEditor = this.$refs['feedback-editor'];
      if (maydayEditor)
        maydayEditor.editor.setOptions({ editable: this.editable });
    },
    message() {
      if (!this.editable) this.setEditorContent(this.message);
    },
  },
};
</script>
<style lang="scss" scoped>
.mayday-feedback-editor {
  :deep() .velmld-parent {
    position: unset !important;
  }

  :deep() .mayday-editor {
    padding: 5px 0px;
  }
}

.mayday-contribution-editor {
  :deep() div[id^='tippy-'],
  :deep() .tippy-content,
  :deep() .tippy-box,
  :deep() .mayday-editor__bubble-menu-text-selection {
    visibility: hidden !important;
    display: none !important;
  }
}
</style>
