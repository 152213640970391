<template>
  <div class="content-parameters-review">
    <ul>
      <li v-for="p in refinedParams" :key="p.id">
        <font-awesome-icon :icon="['fad', p.icon]" class="mr-2 review-icon" />
        <strong class="mr-1">{{ p.label }} :</strong>
        <small>{{ p.values.map((v) => retrieveLabel(v)).join(', ')}}</small>
      </li>
    </ul>
  </div>
</template>

<script>
import ProductMixin from 'mixins/KnowledgeMixin/ProductMixin';

export default {
  name: 'content-parameters-review',
  mixins: [ProductMixin],
  props: {
    parameters: Array,
  },
  computed: {
    refinedParams() {
      return this.mxFlatHierarchy.map((p) => {
        const nthParam = this.parameters.find((pa) => pa.key === p.value);
        return {
          ...p,
          values: nthParam ? nthParam.values : [],
        };
      });
    },
  },
  data() {
    return {
      hierarchiesLabel: [],
    };
  },
  async created() {
    this.hierarchiesLabel = await this.mxEnrichRawParameters(this.parameters);
  },
  methods: {
    retrieveLabel(value) {
      const hierarchy = this.hierarchiesLabel.find((eh) => eh.value === value);
      return hierarchy ? hierarchy.label : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.review-icon{
  max-width: 16px;
  max-height: 16px;
}

ul {
  list-style: none;
}
</style>