var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SlideYUpTransition',{attrs:{"duration":_vm.animationDuration}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"modal fade",class:[
      {
        'show d-flex flex-column justify-content-center': _vm.show,
      },
      { 'd-none': !_vm.show },
      { 'modal-mini': _vm.type === 'mini' },
    ],attrs:{"tabindex":"-1","role":"dialog","aria-hidden":!_vm.show},on:{"mousedown":function($event){if($event.target !== $event.currentTarget)return null;return _vm.closeModal.apply(null, arguments)}}},[_c('div',{staticClass:"w-100 modal-dialog",class:[{ 'modal-notice': _vm.type === 'notice' }, _vm.modalClasses]},[_c('div',{staticClass:"modal-content",class:[
          _vm.gradient ? `bg-gradient-${_vm.gradient}` : '',
          _vm.modalContentClasses,
        ]},[(_vm.$slots.header)?_c('div',{staticClass:"modal-header",class:[_vm.headerClasses]},[_vm._t("header"),_vm._t("close-button",function(){return [(_vm.showClose)?_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.closeModal}},[(_vm.closeIcon)?_c('span',{attrs:{"aria-hidden":!_vm.show}},[_vm._v("×")]):_vm._e()]):_vm._e()]})],2):_vm._e(),_c('div',{staticClass:"modal-body",class:_vm.bodyClasses},[_vm._t("default")],2),(_vm.$slots.footer)?_c('div',{staticClass:"modal-footer",class:_vm.footerClasses},[_vm._t("footer")],2):_vm._e()]),(_vm.$slots['sub-modal'])?_vm._t("sub-modal"):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }