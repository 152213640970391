<template>
  <div class="horizontal-element mb-3">
    <div>
      <div class="element-title">{{ $t(title) }}</div>
      <div class="element-description">{{ $t(description) }}</div>
    </div>

    <div class="input">
      <el-button type="primary" size="mini" @click="$emit('click')">{{
        $t(cta)
      }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'settings-cta',
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    cta: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.horizontal-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}

.state-info {
  font-size: 12px;
}

.icon-state-info {
  color: $grey-4-mayday;
}

.active {
  color: $green-mayday !important;
}
</style>
