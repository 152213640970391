<template>
  <div class="analytics-content-container">
    <div class="analytics-views-stats-container">
      <div class="analytics-views-container">
        <AnalyticsStatsCard
          v-if="isPublicSelected"
          class="analytics-contents-public-stats-card"
          :loading="contentPublicStatCardsLoading"
          :stats="contentPublicKnowledgeStats"
          flex
        >
          <template v-slot:trendText="{ footerStat }">
            <p class="stat-footer">
              {{
                $t(
                  `new-dashboard.stat-card.additional-stats-usage.stat-trend-${footerStat}`,
                )
              }}
              {{
                $t(
                  'new-dashboard.stat-card.additional-stats-usage.stat-trend-timeframe',
                )
              }}
            </p>
          </template>
          <template v-slot:trendBadge="{ additionalStat }">
            <font-awesome-icon
              :class="additionalStat"
              class="trend-badge"
              :icon="['fad', 'arrow-right']"
            />
          </template>
        </AnalyticsStatsCard>
        <AnalyticsContentsViews
          v-if="additionalViewsGraphFiltersArePopulated"
          class="analytics-views-graph"
          :graph-type="viewsGraphDrawType"
          :filter-type="viewsGraphFilterType"
          :is-pie="viewsGraphIsPie"
          :chart-data="contentChartData"
          :is-loading="contentChartDataIsLoading"
          :is-empty="contentChartIsEmpty"
          :external-doc-url="externalDocUrl"
          @update:filterType="updateViewsFilterType"
          @update:graphType="updateViewsGraphType"
          @open-filters-drawer="() => $emit('open-filters-drawer')"
        />
      </div>
      <div class="stat-cards-container">
        <AnalyticsStatsCard
          v-if="contentStatsAreReady"
          :loading="contentStatsLoading"
          class="analytics-knowledge-stats-card"
          :stats="contentKnowledgeStats"
          :title="$t('new-dashboard.header.title.analytics-contents-stats')"
          :tooltip-content="
            $t(
              'new-dashboard.header.tooltip.analytics-contents-stats.knowledge',
            )
          "
          :external-doc-url="externalDocUrl"
        >
          <template v-slot:articleIcon="{ baseClass }">
            <i class="article-icon" :class="baseClass"></i>
          </template>
          <template v-slot:diagnosticIcon="{ baseClass }">
            <i class="diagnostic-icon" :class="baseClass"></i>
          </template>
          <template v-slot:draftCount="{ footerStat }">
            <p class="footer-text">
              {{
                $t(
                  'new-dashboard.stat-card.additional-stats-content.analytics-contents-stats',
                  { draftNumber: footerStat },
                )
              }}
            </p>
          </template>
          <template v-slot:additional-text="{ footerStat }">
            <p class="footer-text">
              {{
                $t(
                  `new-dashboard.stat-card.additional-stats-content.analytics-confidence-${footerStat}`,
                )
              }}
            </p>
          </template>
        </AnalyticsStatsCard>
        <AnalyticsStatsCard
          :loading="contentStatsLoading"
          class="analytics-knowledge-stats-card"
          :stats="contentConfidenceScoreStat"
          :title="
            $t(
              'new-dashboard.stat-card.name.analytics-contents-stats.confidence-score',
            )
          "
          :tooltip-content="
            $t(
              'new-dashboard.header.tooltip.analytics-contents-stats.confidence-score',
            )
          "
          :external-doc-url="externalDocUrl"
        >
          <template v-slot:stat="{ stat }">
            {{ stat | percentage }}
          </template>
          <template v-slot:additional-text="{ footerStat }">
            <p class="footer-text">
              {{
                $t(
                  `new-dashboard.stat-card.additional-stats-content.analytics-confidence-${footerStat}`,
                )
              }}
            </p>
          </template>
        </AnalyticsStatsCard>
      </div>
    </div>
    <AnalyticsContentModal
      v-if="showContentModal"
      :show="showContentModal"
      :is-diagnostic="contentModalIsDiagnostic"
      :diagnostic-chart="contentModalDiagnosticChart"
      :diagnostic-chart-is-loading="contentModalDiagnosticChartIsLoading"
      :diagnostic-chart-is-empty="contentModalDiagnosticChartIsEmpty"
      :chart-type.sync="contentModalChartTypeModel"
      :chart-type-is-line="contentModalChartTypeIsLine"
      :chart-is-empty="contentModalChartIsEmpty"
      :chart-is-loading="contentModalChartIsLoading"
      :chart-data="contentModalChartData"
      :content-stats="contentModalOverviewStats"
      :content-stats-is-loading="contentModalDetailsIsLoading"
      :content-metadata="contentModalMetadata"
      :external-doc-url="externalDocUrl"
      @close="hideContentModal"
    />
    <AnalyticsContentsTable
      v-if="contentTableIsReady"
      class="analytics-content-table"
      :views-filter.sync="contentTableIsWithViewsModel"
      :search-filter.sync="contentTableSearchModel"
      :data-options.sync="contentTableDataOptionsModel"
      :data="contentTableData"
      :total-count="contentTableTotalCount"
      :total-pages="contentTableTotalPages"
      :current-page="contentTableCurrentPage"
      :is-loading="contentTableDataIsLoading"
      :is-empty="contentTableIsEmpty"
      :external-doc-url="externalDocUrl"
      :download="downloadTable"
      @show-details="prepareContentModalDetails"
      @open-filters-drawer="() => $emit('open-filters-drawer')"
    />
    <AnalyticsContentsSearchStats
      v-if="!isParametric && contentSearchStatisticsAreReady"
      :is-loading="contentSearchLoading"
      :content-search-results="contentSearchStatistics"
      :external-doc-url="externalDocUrl"
      :exportSearchLoading="exportSearchLoading"
      :merge-terms.sync="mergeTermsModel"
      class="analytics-contents-search-stats"
      @get-search-stats="getContentSearchStatistics"
      @export-search-stats="exportContentSearchStatistics($event)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AnalyticsContentsViews from './AnalyticsContentsViews';
import AnalyticsContentsTable from './AnalyticsContentsTable';
import AnalyticsContentModal from './AnalyticsContentModal/AnalyticsContentModal';
import AnalyticsStatsCard from '../AnalyticsStatsCard';
import AnalyticsContentsSearchStats from './AnalyticsContentsSearchStats';

export default {
  props: {
    externalDocUrl: {
      type: String,
      required: true,
    },
  },

  components: {
    AnalyticsContentsViews,
    AnalyticsContentsTable,
    AnalyticsStatsCard,
    AnalyticsContentModal,
    AnalyticsContentsSearchStats,
  },

  created() {
    this.initContentModalGraphType();
    this.initAdditionalViewsGraphFilters();
    this.initContentTableSortOptions();
    this.initAdditionalContentTableFilters();
  },

  computed: {
    ...mapGetters(['isParametric']),
    ...mapGetters('dashboard', [
      'viewsGraphDrawType',
      'viewsGraphFilterType',
      'viewsGraphIsPie',
      'contentChartData',
      'contentChartDataIsLoading',
      'contentChartIsEmpty',
      'contentTableIsWithViews',
      'contentTableSearchFilter',
      'contentTableData',
      'contentTableDataIsLoading',
      'contentTableTotalCount',
      'contentTableTotalPages',
      'contentTableCurrentPage',
      'contentKnowledgeStats',
      'contentConfidenceScoreStat',
      'contentTableDataOptions',
      'contentTableIsEmpty',
      'contentSearchStatistics',
      'contentSearchLoading',
      'showContentModal',
      'contentModalIsDiagnostic',
      'contentModalDetails',
      'contentModalDetailsIsLoading',
      'contentModalChartData',
      'contentModalChartIsLoading',
      'contentModalChartType',
      'contentModalChartTypeIsLine',
      'contentModalChartIsEmpty',
      'contentModalDiagnosticChartIsEmpty',
      'contentModalDiagnosticChart',
      'contentModalDiagnosticChartIsLoading',
      'contentModalOverviewStats',
      'contentModalMetadata',
      'contentStatsLoading',
      'additionalViewsGraphFiltersArePopulated',
      'contentStatsAreReady',
      'contentTableIsReady',
      'contentSearchStatisticsAreReady',
      'isPublicSelected',
      'contentPublicKnowledgeStats',
      'contentPublicStatCardsLoading',
      'exportSearchLoading',
      'mergeTerms',
    ]),

    contentTableIsWithViewsModel: {
      get() {
        return this.contentTableIsWithViews;
      },
      set(value) {
        this.updateContentTableIsWithViews(value);
        return value;
      },
    },

    contentTableSearchModel: {
      get() {
        return this.contentTableSearchFilter;
      },
      set(value) {
        this.updateContentTableSearch(value);
        return value;
      },
    },

    contentTableDataOptionsModel: {
      get() {
        return this.contentTableDataOptions;
      },
      set(value) {
        this.updateContentTableDataOptions(value);
        return value;
      },
    },

    contentModalChartTypeModel: {
      get() {
        return this.contentModalChartType;
      },
      set(value) {
        this.updateContentModalChartType(value);
        return value;
      },
    },

    mergeTermsModel: {
      get() {
        return this.mergeTerms;
      },
      set(value) {
        this.updateMergeTerms(value);
        return value;
      },
    },
  },

  methods: mapActions('dashboard', [
    'initAdditionalViewsGraphFilters',
    'initAdditionalContentTableFilters',
    'initContentTableSortOptions',
    'initContentModalGraphType',
    'updateViewsFilterType',
    'updateViewsGraphType',
    'updateContentTableIsWithViews',
    'updateContentTableSearch',
    'updateContentTableDataOptions',
    'prepareContentModalDetails',
    'hideContentModal',
    'updateContentModalChartType',
    'downloadTable',
    'getContentSearchStatistics',
    'exportContentSearchStatistics',
    'updateMergeTerms',
  ]),
};
</script>

<style lang="scss" scoped>
.stat-footer {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: $grey-6-mayday;
}
.trend-badge {
  width: 90%;
  height: 90%;

  &.up {
    transform: rotate(-45deg);
  }
  &.down {
    transform: rotate(45deg);
  }
}

.analytics-contents-public-stats-card {
  :deep() .stat-cards-wrapper.flex-wrapper .stat-container {
    flex-basis: 33%;
  }
}

.content-title-container {
  margin-right: 15px;
}

.analytics-views-stats-container {
  display: flex;
}

.analytics-views-container {
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
}

.analytics-views-graph {
  padding-right: 16px;
}

.footer-text {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: $grey-6-mayday;
}

.analytics-knowledge-stats-card:last-child {
  border-top: 2px solid $grey-2-mayday;
  padding-top: 15px;
}

.stat-cards-container {
  width: 25%;
  padding: 0px 16px;
  border-left: 2px solid $grey-2-mayday;
}

.article-icon {
  background-image: url('~@/assets/article-icon-v2.1.svg');
  background-size: cover;
}
.diagnostic-icon {
  background-image: url('~@/assets/diag-icon-v2.1.svg');
  background-size: cover;
}

.analytics-content-table,
.analytics-contents-search-stats {
  margin-top: 32px;
}
</style>
