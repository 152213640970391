<template>
  <div class="imported-articles-options">
    <!-- UNLINK ARTICLE -->
    <side-bar-item
      v-if="isReadOnly"
      :description="delinkDesc"
      :type="type"
      @click="unlinkArticleFromSource()"
    >
      <template v-slot:icon="{ _class }">
        <!-- <img slot="icon" :class="_class" src="~assets/delink-article.png" /> -->
        <font-awesome-icon
          slot="icon"
          :class="_class"
          :icon="['fad', 'lock-open']"
        />
      </template>
    </side-bar-item>

    <!-- REFRESH ARTICLE -->
    <side-bar-item
      v-if="isReadOnly"
      :description="refreshDesc"
      :type="type"
      @click="updateArticleFromSource()"
    >
      <template v-slot:icon="{ _class }">
        <!-- <img slot="icon" :class="_class" src="~assets/refresh-article.png" /> -->
        <font-awesome-icon
          slot="icon"
          :class="_class"
          :icon="['fad', 'sync-alt']"
        />
        <!-- <img class="import-icon" width="30px" src="~assets/refresh-article.png" /> -->
      </template>
    </side-bar-item>

    <ImportedArticle
      v-if="importModal"
      :display="importModal"
      @import-article="importArticle"
      @close="importModal = false"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import SideBarItem from './SideBarItem';
import ImportedArticle from 'components/Modals/ImportedArticle';

export default {
  name: 'imported-article-options',
  components: {
    SideBarItem,
    ImportedArticle,
  },
  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    contentId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'diagnostic',
    },
  },
  data() {
    return {
      i18nPath: 'knowledge.article-builder.side-bar',
      importedDesc: this.$t(
        'knowledge.article-builder.side-bar.imported-articles.tooltip',
      ),
      delinkDesc: this.$t(
        'knowledge.article-builder.side-bar.delink-articles.tooltip',
      ),
      refreshDesc: this.$t(
        'knowledge.article-builder.side-bar.refresh-articles.tooltip',
      ),
      importModal: false,
    };
  },
  methods: {
    async updateArticleFromSource() {
      try {
        await this.syncContent(this.contentId);
        this.$message({
          message: this.$t(
            `${this.i18nPath}.imported-articles.notification.content`,
          ),
          type: 'success',
          center: true,
        });
        this.$root.$emit('content-restored');
      } catch (err) {
        this.$message({
          message: this.$t(
            `${this.i18nPath}.imported-articles.notification.error-content`,
          ),
          type: 'error',
          center: true,
        });
      }
    },

    async unlinkArticleFromSource() {
      try {
        await this.unlinkSyncContent(this.contentId);
        this.$message({
          message: this.$t(
            `${this.i18nPath}.delink-articles.notification.content`,
          ),
          type: 'success',
          center: true,
        });
      } catch (err) {
        this.$message({
          message: this.$t(
            `${this.i18nPath}.imported-articles.notification.error-content`,
          ),
          type: 'error',
          center: true,
        });
      }
    },
    ...mapActions('knowledgeModule', ['syncContent', 'unlinkSyncContent']),
  },
};
</script>
