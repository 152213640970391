<template>
    <div class="settings">
        <div class="settings__title">{{ $t(`${i18n}.parameters`) }}</div>
        <div class="settings__model w-100 ml-2">
            <div class="settings__model__attribute w-100 mt-2" v-for="data in action.metadataModel" :key="data.key">
                <div class="settings__model__attribute__label">{{ data.label }} :</div>
                <input class="settings__model__attribute__input mt-1" v-model="model[data.key]">
            </div>
        </div>
        <div class="settings__submission mt-4 w-100">
            <button class="settings__submission__delete" @click="onDelete()">
                <font-awesome-icon class="mr-1" :icon="['far', 'spinner']" spin v-if="loader.delete"/>
                {{ $t(`${i18n}.delete`) }}
            </button>
            <button class="settings__submission__save ml-2" @click="onSave()">
                <font-awesome-icon class="mr-1" style="color: white" :icon="['far', 'spinner']" spin v-if="loader.save"/>
                {{ $t(`${i18n}.save`) }}
            </button>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'content-view-settings-action-settings',
    props: {
        action: {
            type: Object,
            required: true,
        },
        contentAction: {
            type: Object,
        },
    },
    data() {
        return {
            model: {},
            i18n: "knowledge.drawers.content-view-setting.settings.content-actions",
            loader: {
                delete: false,
                save: false
            }
        };
    },
    mounted() {
        if (this.alreadyConfigured) {
            this.model = Object.assign({}, this.model, this.contentAction.payload)
        } else {
            this.model = this.action.metadataModel.reduce((acc, val) => {
                acc[val.key] = '';
                return acc;
            }, {})
        }
    },  
    computed: {
        alreadyConfigured() {
            return !!this.contentAction;
        },
        editedContentAction() {
            return {
                id: this.action._id,
                payload: this.model
            }
        }
    },
    methods: {
        onSave() {
            this.$emit('save', this.editedContentAction);
            this.load('save');
        },
        onDelete() {
            this.$emit('delete');
            this.load('delete');
        },
        load(key) {
            this.loader[key] = true;
            setTimeout(() => {
                this.loader[key] = false;
            }, 2000);
        }
    }
};
</script>

<style lang="scss" scoped>
.settings {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;

    &__title {
        font-weight: bold;
    }

    &__model {
        &__attribute {
            &__input {
                padding: 4px 6px;
                border: 1px solid $grey-5-mayday;
                border-radius: 4px;
                width: 95%;
            }
        }
    }

    &__submission {
        display: flex;
        justify-content: flex-end;
        &__delete {
            border: 1px solid transparent;
            background-color: transparent;
            color: $red-mayday;
            border-radius: 4px;
            &:hover {
                border: 1px solid $red-mayday;
            }
        }
        &__save {
            border: none;
            background-color: $blue-mayday;
            color: white;
            border-radius: 4px;
        }
    }

}
</style>
