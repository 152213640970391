<template>
  <div class="case-path-navigator d-flex">
    <span>
      <font-awesome-icon
        @click="$emit('click:home')"
        :icon="['fad', 'home']"
        class="case-path-navigator__icon"
      />
      <img
        v-if="ancestors.length"
        src="~assets/breadcrumb.png"
        class="case-path-navigator__separator"
      />
    </span>
    <span
      v-for="(ancestor, idx) in ancestors"
      :key="ancestor.id"
      class="d-flex"
    >
      <p
        @click="$emit('click:ancestor', { ancestor, idx })"
        :class="[
          'mb-0',
          'case-path-navigator__item',
          'd-flex',
          'align-items-center',
          ancestors.length === idx + 1 && routeType === 'Case'
            ? 'case-path-navigator__item--last-step'
            : '',
        ]"
      >
        {{ ancestor.label }}
      </p>
      <img
        v-if="ancestors.length > idx + 1"
        src="~assets/breadcrumb.png"
        class="case-path-navigator__separator"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: 'base-breadcrumb',
  props: {
    ancestors: {
      type: Array,
      required: true,
    },
    routeType: String,
  },
};
</script>

<style lang="scss" scoped>
.case-path-navigator__icon {
  cursor: pointer;
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.case-path-navigator__item {
  font-size: 14px;
  color: $grey-5-mayday;

  &:hover {
    color: $grey-7-mayday;
    cursor: pointer;
  }
}

.case-path-navigator__item--last-step {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: text !important;
  color: $grey-7-mayday;
}

.case-path-navigator__separator {
  max-width: 15px;
  max-height: 20px;
}
</style>
