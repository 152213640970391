<template>
  <div class="case-viewer-header-count">
    <div class="row search-result-count">
      <div class="d-flex align-items-center">
        <font-awesome-icon :icon="['fad', 'folder']" class="icon" />
        <p class="mb-0 text-muted">
          {{ caseCount }}
          {{ $tc('knowledge.search.cases', caseCount) }}
        </p>
      </div>

      <div class="d-flex align-items-center" v-if="!isSplitKnowledgeViewer">
        <el-divider direction="vertical" class="mx-2" />
        <img :src="articleIcon" class="icon mr-2" />
        <p class="mb-0 text-muted">
          {{ articleCount }}
          {{ $tc('knowledge.search.articles', articleCount) }}
        </p>
      </div>

      <div class="d-flex align-items-center" v-if="!isSplitKnowledgeViewer">
        <el-divider direction="vertical" class="mx-2" />
        <img :src="diagIcon" class="icon mr-2" />
        <p class="mb-0 text-muted">
          {{ diagCount }} {{ $tc('knowledge.search.diagnostics', diagCount) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'case-viewer-header-count',
  props: {
    caseCount: {
      type: Number,
      default: () => 0,
    },
    articleCount: {
      type: Number,
      default: () => 0,
    },
    diagCount: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      articleIcon: require('assets/article-icon-v2.svg'),
      diagIcon: require('assets/diag-icon-v2.svg'),
    };
  },
  computed: {
    ...mapGetters(['isSplitKnowledgeViewer']),
  },
};
</script>

<style lang="scss" scoped>
.case-viewer-header-count {
  margin-left: 16px;

  .icon {
    color: $grey-5-mayday;
    margin-right: 8px;
    max-width: 16px;
    max-height: 16px;
    cursor: pointer;
  }
}
</style>
