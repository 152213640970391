<template>
  <div>
    <div
      v-if="showBulk"
      class="d-flex align-items-baseline justify-content-between"
    >
      <div v-if="showSelectAll" class="pl-2 select-options">
        <el-checkbox v-model="checked" />
        <small @click="checked = !checked" class="pl-3 cursor">{{
          $t('knowledge.bulk-actions.select-all')
        }}</small>
      </div>
      <!-- Empty div for flex justify-content-between -->
      <div v-else></div>
      <div class="d-flex align-items-center">
        <small class="pr-2 cursor active" @click="toggleShowBulk">
          {{ $t('knowledge.bulk-actions.select') }}
        </small>

        <el-dropdown size="small" type="primary" trigger="click">
          <el-button
            type="primary"
            size="small"
            class="px-2"
            :disabled="disableActions"
          >
            Actions<i class="el-icon-arrow-down el-icon--right ml-1"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown" class="pb-0">
            <not-allowed-tooltip
              :user-permissions="userPermissions"
              :content="isResultList ? 'generic.not-allowed-list' : undefined"
              permission-key="canUpdateContent"
            >
              <template v-slot:main-content="{ hasPermissions }">
                <el-dropdown-item
                  @click.native="hasPermissions ? updateStatus('publish') : ''"
                  :disabled="!hasPermissions"
                  >{{ $t('knowledge.bulk-actions.publish') }}</el-dropdown-item
                >
              </template>
            </not-allowed-tooltip>

            <not-allowed-tooltip
              :user-permissions="userPermissions"
              :content="isResultList ? 'generic.not-allowed-list' : undefined"
              permission-key="canUpdateContent"
            >
              <template v-slot:main-content="{ hasPermissions }">
                <el-dropdown-item
                  @click.native="hasPermissions ? updateStatus('draft') : ''"
                  :disabled="!hasPermissions"
                >
                  {{ $t('knowledge.bulk-actions.draft') }}
                </el-dropdown-item>
              </template>
            </not-allowed-tooltip>

            <not-allowed-tooltip
              :user-permissions="userPermissions"
              :content="isResultList ? 'generic.not-allowed-list' : undefined"
              permission-key="canVerifyContent"
            >
              <template v-slot:main-content="{ hasPermissions }">
                <el-dropdown-item
                  @click.native="hasPermissions ? updateStatus('verify') : ''"
                  :disabled="!hasPermissions"
                >
                  {{ $t('knowledge.bulk-actions.verify') }}
                </el-dropdown-item>
              </template>
            </not-allowed-tooltip>

            <not-allowed-tooltip
              :user-permissions="userPermissions"
              :content="isResultList ? 'generic.not-allowed-list' : undefined"
              permission-key="canVerifyContent"
            >
              <template v-slot:main-content="{ hasPermissions }">
                <el-dropdown-item
                  @click.native="hasPermissions ? updateStatus('outdate') : ''"
                  :disabled="!hasPermissions"
                >
                  {{ $t('knowledge.bulk-actions.unverify') }}
                </el-dropdown-item>
              </template>
            </not-allowed-tooltip>

            <not-allowed-tooltip
              :user-permissions="userPermissions"
              :content="isResultList ? 'generic.not-allowed-list' : undefined"
              permission-key="canCreateContent"
            >
              <template v-slot:main-content="{ hasPermissions }">
                <el-dropdown-item
                  v-if="displayClone"
                  @click.native="hasPermissions ? $emit('clone') : ''"
                  :disabled="!hasPermissions"
                >
                  {{ $t('knowledge.bulk-actions.clone') }}
                </el-dropdown-item>
              </template>
            </not-allowed-tooltip>

            <not-allowed-tooltip
              :user-permissions="userPermissions"
              permission-key="canCreateContent"
            >
              <template v-slot:main-content="{ hasPermissions }">
                <el-dropdown-item
                  v-if="displayClone"
                  @click.native="
                    hasPermissions ? $emit('duplicate-in-same-location') : ''
                  "
                  :disabled="!hasPermissions"
                >
                  {{ $t('knowledge.actions.duplicate-in-same-location') }}
                </el-dropdown-item>
              </template>
            </not-allowed-tooltip>

            <not-allowed-tooltip
              :user-permissions="userPermissions"
              :content="isResultList ? 'generic.not-allowed-list' : undefined"
              permission-key="canUpdateContent"
            >
              <template v-slot:main-content="{ hasPermissions }">
                <el-dropdown-item
                  @click.native="
                    hasPermissions
                      ? $emit('edit-contents', { option: 'labels' })
                      : ''
                  "
                  :disabled="!hasPermissions"
                >
                  {{ $t('knowledge.bulk-actions.edit.labels') }}
                </el-dropdown-item>
              </template>
            </not-allowed-tooltip>

            <not-allowed-tooltip
              v-if="isParametric"
              :user-permissions="userPermissions"
              :content="isResultList ? 'generic.not-allowed-list' : undefined"
              permission-key="canUpdateContent"
            >
              <template v-slot:main-content="{ hasPermissions }">
                <el-dropdown-item
                  @click.native="
                    hasPermissions
                      ? $emit('edit-contents', { option: 'collections' })
                      : ''
                  "
                  :disabled="!hasPermissions"
                >
                  {{ $t('knowledge.bulk-actions.edit.collections') }}
                </el-dropdown-item>
              </template>
            </not-allowed-tooltip>
            <not-allowed-tooltip
              v-if="isParametric"
              :user-permissions="userPermissions"
              :content="isResultList ? 'generic.not-allowed-list' : undefined"
              permission-key="canUpdateContent"
            >
              <template v-slot:main-content="{ hasPermissions }">
                <el-dropdown-item
                  @click.native="
                    hasPermissions
                      ? $emit('edit-contents', { option: 'restrictions' })
                      : ''
                  "
                  :disabled="!hasPermissions"
                >
                  {{ $t('knowledge.bulk-actions.edit.restrictions') }}
                </el-dropdown-item>
              </template>
            </not-allowed-tooltip>

            <el-dropdown-item
              v-if="isGranularAccessRestrictions"
              divided
              class="dropdown-item-danger"
              @click.native="$emit('edit-product-parameters')"
            >
              <strong>
                {{ $t('knowledge.bulk-actions.product-parameters') }}
              </strong>
            </el-dropdown-item>

            <not-allowed-tooltip
              :user-permissions="userPermissions"
              :content="isResultList ? 'generic.not-allowed-list' : undefined"
              permission-key="canDeleteContent"
            >
              <template v-slot:main-content="{ hasPermissions }">
                <el-dropdown-item
                  divided
                  class="dropdown-item-danger w-100"
                  @click.native="$emit('archive')"
                  :disabled="!hasPermissions"
                >
                  <font-awesome-icon
                    :icon="['fad', 'archive']"
                    class="option-icon text-red mr-2"
                  />
                  <strong>{{ $t('knowledge.bulk-actions.archive') }}</strong>
                </el-dropdown-item>
              </template>
            </not-allowed-tooltip>
            <not-allowed-tooltip
              :user-permissions="userPermissions"
              :content="isResultList ? 'generic.not-allowed-list' : undefined"
              permission-key="canDeleteContent"
            >
              <template v-slot:main-content="{ hasPermissions }">
                <el-dropdown-item
                  class="w-100"
                  :class="hasPermissions ? 'soft-delete-item' : 'disabled'"
                  @click.native="$emit('bulk-delete', { softDelete: true })"
                  :disabled="!hasPermissions"
                >
                  <font-awesome-icon
                    :icon="['fad', 'trash']"
                    class="option-icon mr-2"
                  />
                  <strong>{{ $t('knowledge.actions.soft-delete') }}</strong>
                </el-dropdown-item>
              </template>
            </not-allowed-tooltip>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div v-else class="d-flex justify-content-end pb-2">
      <small
        v-if="showSelect"
        class="cursor inactive"
        @click="toggleShowBulk"
        >{{ $t('knowledge.bulk-actions.select') }}</small
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

export default {
  components: {
    NotAllowedTooltip,
  },
  props: {
    selectedCount: {
      type: Number,
      default: 0,
    },
    showBulk: {
      type: Boolean,
      default: false,
    },
    showSelectAll: {
      type: Boolean,
      default: true,
    },
    displayClone: {
      type: Boolean,
      default: true,
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
    disableActions: {
      type: Boolean,
      default: true,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
    isResultList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  computed: {
    ...mapGetters(['isParametric', 'isGranularAccessRestrictions']),
  },
  methods: {
    toggleShowBulk() {
      this.$emit('show-bulk');
    },
    updateStatus(action) {
      let payload;
      if (['publish', 'draft'].includes(action))
        payload = { key: 'published', value: action === 'publish' };
      if (['verify', 'outdate'].includes(action))
        payload = { key: 'isOutdated', value: action === 'outdate' };
      this.$emit('update-status', payload);
    },
  },
  watch: {
    checked(val) {
      if (val) {
        this.$emit('select-all');
      } else {
        this.$emit('unselect-all');
      }
    },
    selectedCount(val) {
      if (!val) this.checked = false;
    },
  },
};
</script>

<style lang="scss" scoped>
small {
  color: $grey-5-mayday;
}
.inactive:hover {
  color: $blue-mayday;
}
.active {
  color: $blue-mayday;
}
.dropdown-item-danger:hover {
  color: $red-mayday;
  background-color: rgba($yellow-mayday, 0.1);
}
.soft-delete-item {
  background-color: rgba($red-5-mayday, 0.1);
  color: red;
  &:hover {
    background-color: $red-5-mayday;
    color: white;
    .option-icon {
      color: white;
    }
  }
}

.disabled {
  background-color: $grey-1-mayday;
  color: $grey-5-mayday;
  & .option-icon {
    color: red;
  }
}
</style>
