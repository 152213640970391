<template>
  <div class="knowledgeManageSettingsGroupRow row align-items-center mt-2">
    <div class="group-icon mr-2 rounded-circle d-flex align-items-center">
      <font-awesome-icon :icon="['fad', icon]" class="title-icon" />
    </div>
    <div class="group-label">{{ label }}</div>
    <el-divider v-if="isRestricted" direction="vertical"></el-divider>
    <div v-if="isRestricted" class="tag mr-2 mb-0">
      {{ $t('settings.general.kb-manage.with-restrictions') }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'knowledgeManageSettingsGroupRow',
  components: {},
  props: {
    group: Object,
    icon: {
      type: String,
      default: 'users',
    },
  },
  computed: {
    label() {
      return this.group.label;
    },
    isRestricted() {
      return this.group.isRestricted;
    },
  },
};
</script>
<style lang="scss" scoped>
.title-icon {
  color: $grey-6-mayday;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 2px;
  max-width: 16px;
  max-height: 16px;
}

.tag {
  color: $grey-6-mayday;
  font-size: xx-small;
}

.group-icon {
  border: 1.5px solid $grey-6-mayday;
  margin-right: 2px;
  width: 24px;
  height: 24px;
}

.group-label {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  letter-spacing: 0.02em;
}
</style>
