<template>
  <div id="knowledge-restrictions">
    <p class="my-3">
      {{ $t('components.modals.add-company-group.group-summary.description') }}
    </p>
    <div class="summary-container p-3 mb-3 text-left">
      <p>
        <strong>
          <small>
            {{
              $t('components.modals.add-company-group.group-summary.access', {
                groupName: group.name.toUpperCase(),
              })
            }}
          </small>
        </strong>
      </p>
      <div v-for="knowledge in group.knowledges" :key="knowledge.knowledgeId">
        <div class="col-12 px-0 d-flex align-items-center text-left">
          <p class="mb-1 text-uppercase text-muted">
            <small>{{ knowledge.knowledgeName }}</small>
          </p>
        </div>
        <div class="restriction-details text-left">
          <div v-if="knowledge.isRestricted">
            <p class="mb-1">
              <small
                >{{
                  $t(
                    'components.modals.add-company-group.group-summary.restricted',
                  )
                }}
                :</small
              >
            </p>
            <ul class="p-0">
              <li
                class="authorized-collection"
                v-for="node in knowledge.restrictedNodes"
                :key="node.id"
              >
                <small>{{ node.label }}</small>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'group-summary',
  props: {
    group: Object,
  },
  computed: {
    reachableConfiguration() {
      return this.group.reachable
        ? `Et ils pourront recevoir les feedbacks des agents`
        : `Et ils ne pourront pas recevoir les feedbacks des agents`;
    },
  },
};
</script>

<style lang="scss" scoped>
.flag-icon {
  width: 10px;
  height: 10px;
}
.summary-container {
  background-color: $grey-1-mayday;
  border-radius: 5px;
}

.restriction-details {
  margin-left: 24px;
}

.authorized-collection {
  list-style: georgian inside url('~assets/folder-open-do.svg');
}
</style>
