<template>
  <div
    class="d-flex align-items-center justify-content-center icon-div"
    :class="{
      'icon-div-chosen': iconCode[1] === focusedIcon,
    }"
    @click="$emit('change', iconCode[1])"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <font-awesome-icon class="icon-edit-icon-modal" :icon="iconCode" />
  </div>
</template>

<script>
export default {
  name: 'icon-edition-item',
  props: {
    iconCode: Array,
    focusedIcon: String,
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.icon-edit-icon-modal {
  width: 32px;
  height: 32px;
  color: $grey-7-mayday;
}

.icon-edit-hover {
  color: var(--company-color);
}

.chosen-icon {
  color: var(--company-color);
}

.icon-div {
  margin: 9px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid white;
  &:hover {
    background-color: $grey-1-mayday;
    cursor: pointer;
  }
}
.icon-div-chosen {
  background-color: $grey-1-mayday;
  border-color: $grey-5-mayday;
}
</style>
