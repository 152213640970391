<template>
  <div class="upload-tab">
    <imageCropper
      :loading="loading"
      v-bind="{ aspectRatio: aspectRatio }"
      @update-attachments="$emit('update-attachments', $event)"
      @display-validate="$emit('display-validate', $event)"
      @validate-cropped-attachment="$emit('validate-cropped-attachment')"
    ></imageCropper>
  </div>
</template>

<script>
import ImageCropper from '@/components/ImageCropper.vue';
export default {
  name: 'dropzone-file-upload',
  components: {
    ImageCropper,
  },
  data() {
    return {
      attachments: [],
      fileList: [],
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      required: false,
    },
  },
  methods: {
    updateAttachments(file, fileList) {
      this.attachments = fileList.map((file) => file.raw);
      this.fileList = fileList;
      this.$emit('update-attachments', this.attachments);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-tab {
  max-height: calc(90vh - 150px);
  overflow: auto;
}
.upload-demo {
  :deep() .el-upload {
    width: 100%;
  }

  :deep() .el-upload-dragger {
    width: 100%;
    height: 150px;
    padding: 16px;
  }
}
</style>
