<template>
  <div>
    <div class="mb-3 w-100" v-if="allowTitleEdit">
      <plugin-advanced-settings-option
        :field="fields.label"
        :error="!!errors ? errors.label : undefined"
        @update-plugin="$emit('update-plugin', { label: $event })"
      />
    </div>
    <div class="mb-3" v-if="allowDescriptionEdit">
      <plugin-advanced-settings-option
        :field="fields.description"
        :error="!!errors ? errors.description : undefined"
        @update-plugin="$emit('update-plugin', { description: $event })"
      />
    </div>
    <div class="mb-3" v-if="allowUrlEdit">
      <plugin-advanced-settings-option
        :field="fields.url"
        :error="!!errors ? errors.url : undefined"
        @update-plugin="$emit('update-plugin', { url: $event })"
      />
    </div>
    <div class="mb-3" v-if="allowIconEdit">
      <icon-picker
        :value="currentPlugin.icon.value"
        @update="$emit('update-plugin', $event)"
      />
    </div>
  </div>
</template>
<script>
import IconPicker from '../../../../components/IconPicker.vue';
import PluginAdvancedSettingsOption from './PluginAdvancedSettingsOption.vue';

export default {
  name: 'plugin-advanced-settings-options',
  components: {
    PluginAdvancedSettingsOption,
    IconPicker,
  },
  data() {
    return {
      subtype: null,
      title: '',
      description: '',
      url: '',
      fields: {
        label: {
          type: 'input',
          model: this.currentPlugin.label,
          label:
            'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.title.label',
          placeholder:
            'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.title.placeholder',
        },
        description: {
          type: 'input',
          model: this.currentPlugin.description,
          label:
            'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.description.label',
          placeholder:
            'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.description.placeholder',
        },
        url: {
          type: 'input',
          model: this.currentPlugin.url,
          label:
            'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.url.label',
          placeholder:
            'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.url.placeholder',
        },
      },
    };
  },
  props: {
    currentPlugin: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
    },
    index: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
    },
  },
  computed: {
    allowTitleEdit() {
      return !!this.config.title;
    },
    allowDescriptionEdit() {
      return !!this.config.description;
    },
    allowUrlEdit() {
      return !!this.config.url;
    },
    allowIconEdit() {
      return !!this.config.icon;
    },
  },
};
</script>
