<template>
  <div :class="['content-stamp', buttonProps.class]">
    <el-tooltip placement="top" effect="dark" :disabled="disabled">
      <template #content>
        <!-- Date of publication -->
        <p
          v-if="formattedPublicationDate && !published"
          class="text-small mb-0"
        >
          {{ buttonProps['tooltip-date'] }}
          <strong> {{ formattedPublicationDate }} </strong> .
        </p>

        <p class="text-small mb-0">
          {{ buttonProps.tooltip }}
        </p>
      </template>

      <div>
        {{ buttonProps.label }}
      </div>
    </el-tooltip>

    <el-tooltip placement="top" effect="dark" :disabled="disabled">
      <template #content>
        <p class="text-small mb-0">
          {{ buttonProps['icon-tooltip'] }}
        </p>
      </template>
      <font-awesome-icon
        v-if="!published"
        :icon="['fas', 'cog']"
        class="trust-cog-icon"
        @click.stop="$emit('update-display-modal', true)"
      />
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'publication-policy-button',
  props: {
    publicationDate: {
      type: String,
      required: false,
    },
    published: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    buttonProps() {
      return this.published
        ? {
            label: this.$t('knowledge.published').toUpperCase(),
            class: 'published',
            tooltip: this.$t(
              'knowledge.publication-policy.button-tooltip-published',
            ),
          }
        : {
            label: this.$t('knowledge.draft').toUpperCase(),
            class: 'draft',
            tooltip: this.$t(
              `knowledge.publication-policy.button-tooltip-draft${
                this.formattedPublicationDate ? '' : '-no-policy'
              }`,
            ),
            'tooltip-date': this.$t(
              'knowledge.publication-policy.button-tooltip-date',
            ),
            'icon-tooltip': this.$t(
              'knowledge.publication-policy.icon-tooltip-draft',
            ),
          };
    },
    formattedPublicationDate() {
      if (!this.publicationDate) return '';

      return new Date(parseInt(this.publicationDate)).toLocaleDateString(
        'fr-FR',
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.draft {
  color: $grey-5-mayday;
  background-color: $grey-2-mayday;
  border-radius: 2px;
  border-color: 1px solid $grey-4-mayday;
}
.published {
  color: $blue-mayday;
  background-color: rgba($blue-mayday, 0.1);
  border-radius: 2px;
  border: 1px solid $blue-mayday;
}

.content-stamp {
  border: 1px solid;
  font-size: 10px;
  font-weight: 700;
  padding: 2px 4px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.trust-cog-icon {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: $grey-5-mayday;
  &:hover {
    color: $grey-7-mayday;
  }
}

.text-small {
  font-size: 1.1em;
}
</style>
