<template>
  <el-tooltip
    :content="$t(this.content)"
    :placement="placement"
    :disabled="disabled || hasPermissions"
    :enterable="false"
  >
    <div :class="[disabled || hasPermissions ? 'cursor' : 'not-allowed']">
      <slot name="main-content" :has-permissions="hasPermissions" />
    </div>
  </el-tooltip>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: 'generic.not-allowed',
    },
    placement: {
      type: String,
      default: 'right',
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
    permissionKey: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasPermissions() {
      return Boolean(this.userPermissions[this.permissionKey]);
    },
  },
};
</script>

<style lang="scss" scoped>
.not-allowed {
  &:hover {
    cursor: not-allowed;
  }
}
</style>
