<template>
  <div class="delete-modal">
    <modal :show.sync="localDisplay">
      <div class="row d-flex mt-3 justify-content-center">
        <div class="col-11 text-center">
          <modal-header
            :style-name="{
              backgroundColor: '#ede6fe',
            }"
            class="mb-3"
          >
            <div slot="icon" class="d-inline-flex">
              <font-awesome-icon class="icon" :icon="['fal', 'globe-stand']" />
            </div>
            <div class="mt-3">
              <p class="mb-0 font-weight-bold">
                {{ $t('translation.modal.update-translations.title') }}
              </p>
            </div>
            <hr class="m-2 h-0.5 border-white" />
            <div class="subtitle">
              {{
                $t('translation.modal.update-translations.subtitle', {
                  sourceLanguage: $t(`knowledge.languages.${sourceLanguage}`),
                })
              }}
            </div>
          </modal-header>
        </div>
      </div>
      <div class="row d-flex justify-content-center mb-3">
        <div class="col-11">
          <div class="warning-section">
            <font-awesome-icon :icon="['fal', 'exclamation-triangle']" />
            <div class="d-flex flex-col">
              {{ $t('translation.modal.update-translations.warning') }}
              <span
                v-html="
                  sanitize(
                    $t('translation.modal.update-translations.warning-2'),
                  )
                "
              ></span>
            </div>
          </div>
        </div>
      </div>

      <div class="row d-flex justify-content-center mb-2">
        <div class="col-11 text-center">
          <div class="d-flex items-center justify-between w-full mb-2">
            <div class="input-label">
              {{ $t('translation.modal.update-translations.body-title') }}
            </div>
            <el-checkbox
              class="mb-0 check-all-checkbox"
              @change="handleCheckAll"
              v-model="checkAll"
            ></el-checkbox>
          </div>

          <el-checkbox-group v-model="checkedLangs" class="d-flex flex-col">
            <el-checkbox
              class="lang-checkbox"
              v-for="lang in availableSourceLanguages"
              :label="lang"
              :key="lang"
            >
              <div
                class="d-flex flex-row gap-1 align-items-center justify-content-center"
              >
                <LanguageFlag class="flag-emoji mr-1" :language="lang" emoji />
                {{ $t(`knowledge.languages.${lang}`) }}
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div slot="footer" class="col-12 d-flex justify-content-between">
        <el-button
          class="ml-1"
          type="danger"
          size="small"
          @click="$emit('close')"
          >{{ $t('translation.modal.cancel') }}</el-button
        >
        <el-button
          class="mr-1"
          type="primary"
          size="small"
          :disabled="disableSendButton"
          @click="handleUpdateTranslations"
          >{{ $t('translation.modal.validate') }}</el-button
        >
      </div>
    </modal>
  </div>
</template>
<script>
import ModalHeader from '@/components/Modals/ModalHeader';
import Modal from '@/components/Modal';
import LanguageFlag from '@/components/LanguageFlag.vue';
import { sanitize } from 'dompurify';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'update-translations-modal',
  props: {
    display: Boolean,
    sourceLanguage: String,
    availableSourceLanguages: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Modal,
    ModalHeader,
    LanguageFlag,
  },
  data() {
    return {
      localDisplay: this.display,
      checkedLangs: [],
      checkAll: true,
      sanitize: sanitize,
    };
  },
  mounted() {
    document.addEventListener('keydown', this._keyListener.bind(this));
    this.checkedLangs = this.availableSourceLanguages;
    this.storeVersionEvent({
      eventName: 'updateOpenModal',
      event: {
        knowledgeId: this.focusContent.knowledgeId,
        contentId: this.focusContent.id,
        contentType: this.focusContent.type,
        sourceLanguage: this.sourceLanguage,
        availableSourceLanguages: this.availableSourceLanguages,
      },
    });
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener.bind(this));
  },
  computed: {
    disableSendButton() {
      return this.checkedLangs.length === 0;
    },
    ...mapGetters('knowledgeModule', ['focusContent']),
  },
  methods: {
    handleUpdateTranslations() {
      this.$emit('update-translations', {
        targetLanguages: this.checkedLangs,
      });
    },
    handleCheckAll() {
      if (this.checkedLangs.length < this.availableSourceLanguages.length)
        this.checkedLangs = this.availableSourceLanguages;
      else this.checkedLangs = [];
    },
    _keyListener(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (!this.disableSendButton) this.handleUpdateTranslations();
      }
    },
    ...mapActions('knowledgeModule', ['storeVersionEvent']),
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
    checkedLangs(newVal) {
      this.checkAll = newVal.length === this.availableSourceLanguages.length;
    },
  },
};
</script>
<style lang="scss" scoped>
.delete-modal {
  :deep() .modal-body {
    padding-bottom: 0px;
  }
}
.content-primary-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.05);
}

.blue-mayday {
  color: $blue-mayday;
}

.icon {
  width: 26px;
  height: 26px;
  color: $purple-5-mayday;
}

.input-label {
  font-weight: 700;
  text-align: left;
  color: black;
  display: flex;
  align-items: center;
  gap: 8px;
}

.rounded-input {
  border: 1px solid $grey-5-mayday;
  padding: 4px 0px;
  border-radius: 4px;
  font-size: 14px;
  input {
    width: 100%;
    outline: none;
    border: none;
    padding: 4px 8px;
  }
}
:deep() .el-select {
  .el-input .el-input__inner {
    height: 24px;
    width: 100px;
    display: flex;
    align-items: center;
    padding-left: 4px;
    font-size: 12px;
  }
}
:deep() .el-input__suffix {
  display: flex;
  align-items: center;
}

.warning-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  background-color: rgba(#fcbf49, 0.1);
  padding: 12px;
  font-size: 14px;
}
.lang-checkbox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-right: 0;
}
.check-all-checkbox {
  height: 14px;
}
</style>
