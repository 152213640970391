<template>
  <div class="d-flex align-items-center min-w-0">
    <input
      v-if="edit"
      alternative
      placeholder="Choisissez un nom"
      class="inputNodeLabel ml-0"
      ref="label"
      v-autowidth="{ maxWidth: '960px', minWidth: '50px', comfortZone: 0 }"
      v-model="newLabel"
      @blur="updateLabel()"
      @keyup.enter="$event.target.blur()"
    />
    <div v-else class="w-100">
      <el-tooltip
        :disabled="tooltipDisabled"
        :content="label"
        placement="top"
        effect="light"
      >
        <p
          :ref="contentId"
          class="card-title text-overflow w-100"
          :class="{ 'is-draft': isDraft }"
          v-html="sanitize(highlightedLabel)"
        ></p>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import HighlightLabelMixin from '@/mixins/KnowledgeMixin/HighlightLabelMixin';
import { sanitize } from '@/plugins/dompurify';
export default {
  name: 'item-label-editor',
  mixins: [HighlightLabelMixin],
  props: {
    isDraft: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: false,
    },
    itemType: {
      type: String,
      default: 'content',
    },
    contentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newLabel: '',
      sanitize: sanitize,
      tooltipDisabled: true,
    };
  },
  created() {
    this.isTooltipDisabled();
  },
  methods: {
    updateLabel() {
      if (this.newLabel !== this.label)
        this.$emit('update-label', this.newLabel);
      this.$emit('close');
    },
    isTooltipDisabled() {
      this.$nextTick(() => {
        const textEl = this.$refs[this.contentId];
        if (!textEl) return true;
        this.tooltipDisabled = textEl.scrollWidth <= textEl.clientWidth;
      });
    },
  },
  watch: {
    edit() {
      if (this.edit) {
        this.newLabel = this.label;
        this.$nextTick(() => this.$refs['label'].focus());
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.min-w-0 {
  min-width: 0;
}

.inputNodeLabel:focus,
.inputNodeLabel,
.inputNodeLabel:active {
  outline: none;
  border: none;
  color: $grey-7-mayday;
  margin-bottom: 0px !important;
}

.card-title {
  color: $black-mayday;
  margin-bottom: 0px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  line-height: 29px;
}

.is-draft :deep() {
  color: $grey-5-mayday;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
