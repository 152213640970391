var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex flex-row align-items-center public-header sticky-top justify-content-between"},[_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('el-tooltip',{attrs:{"content":_vm.$t('public-knowledge.deployment.return')},nativeOn:{"mouseenter":function($event){_vm.back = true},"mouseleave":function($event){_vm.back = false}}},[_c('router-link',{staticClass:"icon-wrapper mr-2",attrs:{"to":"/settings/general#knowledges"}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":_vm.back ? ['far', 'arrow-left'] : ['fas', 'book']}})],1)],1),_c('el-divider',{staticClass:"menu-divider mr-3",attrs:{"direction":"vertical"}}),(!_vm.localDeploying)?_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('span',{staticClass:"el-dropdown-link"},[_vm._v(" "+_vm._s(_vm.enrichedFocusCompanyPublicConfig.label)),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.enrichedCompanyPublicConfigs),function(config){return _c('router-link',{key:config.id,staticClass:"dropdown-links",attrs:{"to":{
              name: 'public-settings',
              params: {
                knowledgeId: config.knowledgeId
                  ? config.knowledgeId
                  : 'default',
              },
            }}},[_c('el-dropdown-item',[_vm._v(" "+_vm._s(config.label)+" ")])],1)}),1)],1):_c('span',[_vm._v(" "+_vm._s(_vm.enrichedFocusCompanyPublicConfig.label)+" ")])],1),_c('div',[_c('el-popover',{staticClass:"publish-popover",model:{value:(_vm.publishChanges),callback:function ($$v) {_vm.publishChanges=$$v},expression:"publishChanges"}},[(_vm.publishChanges)?_c('publish-popover',{attrs:{"operations":_vm.getAllOperations},on:{"cancel":function($event){_vm.publishChanges = false},"confirm":_vm.handlePublish,"delete-operation":_vm.handleDeleteOperation}}):_vm._e(),_c('el-button',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"slot":"reference","type":"primary","size":"small","disabled":!_vm.hasChanged},slot:"reference"},[_vm._v(_vm._s(_vm.$t('public-knowledge.publish')))])],1)],1)]),_c('div',{staticClass:"d-flex flex-row"},[(!_vm.localDeploying)?_c('KnowledgeSettingsMenu',{staticClass:"menu",attrs:{"knowledge-type":"public","data":_vm.items}}):_vm._e(),(!_vm.localDeploying && !_vm.loading)?_c('router-view',{staticClass:"router-view",attrs:{"items":_vm.items},on:{"new-operation":function($event){return _vm.newOperation($event)},"new-language":function($event){return _vm.addLanguage({ id: _vm.focusKnowledge.id, lang: $event })},"set-multilingual":function($event){return _vm.setMultilingual({ id: _vm.focusKnowledge.id, lang: $event })}}}):(_vm.localDeploying && !_vm.loading)?_c('div',{staticClass:"status"},[_c('deployment-steps',{staticClass:"deployment",attrs:{"steps":_vm.getSteps,"time":_vm.formattedTime(_vm.deploymentStatus.createdAt),"state":_vm.state,"httpsWarning":_vm.httpsWarning},on:{"return":_vm.handleReturn}})],1):(_vm.loading)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(true),expression:"true"}],staticClass:"loader"}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }