<template>
  <div class="filters-group-container">
    <div class="title-wrapper">
      <span class="title">
        {{ $t(`filters.groups.${name}`) }}
      </span>
      <el-tooltip :content="$t(`filters.tooltip.${name}`)">
        <font-awesome-icon :icon="['fad', 'info-circle']" class="info-icon" />
      </el-tooltip>
    </div>
    <hr class="group-underline" />

    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.filters-group-container {
  .title-wrapper {
    display: flex;
    align-items: center;

    .title {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.02em;
      color: $grey-6-mayday;
      text-transform: uppercase;
    }

    .info-icon {
      margin-left: 7px;
      color: $grey-5-mayday;
    }
  }

  .group-underline {
    height: 1px;
    background-color: $grey-2-mayday;
    border: none;
    margin: 6px 0 12px;
  }
}
</style>
