<template>
  <div
    :class="['trust-container ', isContentTrusted ? 'trusted' : 'untrusted']"
  >
    <el-tooltip
      class="trust-button"
      placement="top"
      effect="dark"
      :disabled="disabled"
    >
      <template #content>
        <p
          v-if="!!verificationPolicy.verificationDueDate && isContentTrusted"
          class="text-small mb-0"
        >
          {{ $t('knowledge.verification-policy.button-tooltip-part1') }}
          <strong>
            {{
              formatedVerificationDueDate.toLocaleDateString('fr-FR')
            }} </strong
          >.
          <br />
          {{ $t('knowledge.verification-policy.button-tooltip-part2') }}
        </p>
        <p
          v-else-if="
            !!verificationPolicy.verificationDueDate &&
            !isContentTrusted &&
            verificationPolicy.verificationRange === 0
          "
          class="text-small mb-0"
        >
          {{ $t('knowledge.verification-policy.button-tooltip-part1') }}
          <strong>
            {{
              formatedVerificationDueDate.toLocaleDateString('fr-FR')
            }} </strong
          >.
          <br />
          {{ $t('knowledge.verification-policy.button-tooltip-part4') }}
        </p>
        <p
          v-else-if="
            verificationPolicy.verificationDueDate !== null && !isContentTrusted
          "
          class="text-small mb-0"
        >
          {{ $t('knowledge.verification-policy.button-tooltip-part3') }}
          <br />
          {{ $t('knowledge.verification-policy.button-tooltip-part4') }}
        </p>
        <p v-else-if="isContentTrusted" class="text-small mb-0">
          {{ $t('knowledge.verification-policy.button-tooltip-part5') }}
        </p>
        <p v-else class="text-small mb-0">
          {{ $t('knowledge.verification-policy.button-tooltip-part6') }}
        </p>
        <p
          v-if="verificationPolicy.setToDraftAtDueDate"
          class="text-small mb-0"
        >
          {{
            $t('components.modals.verification-policy.submit.resume.unpublish')
          }}
        </p>
      </template>
      <div class="content-stamp">
        <span class="py-1" @click="updateTrust">
          <i
            :class="[
              'fa',
              isContentTrusted ? trustIconClass : untrustIconClass,
            ]"
          ></i>
          {{
            isContentTrusted
              ? $t('knowledge.verification-policy.trust').toUpperCase()
              : $t('knowledge.verification-policy.untrust').toUpperCase()
          }}
        </span>
        <font-awesome-icon
          v-if="trustLoading"
          class="icon-spinner"
          :icon="['far', 'spinner']"
          spin
        />
      </div>
    </el-tooltip>

    <!-- SETTINGS COG -->
    <el-tooltip
      placement="top"
      effect="dark"
      :class="[
        isContentTrusted ? 'trust-settings-success' : 'trust-settings-error',
        'd-flex',
      ]"
      :disabled="disabled"
    >
      <template #content>
        <p class="text-small mb-0">
          {{ $t('knowledge.verification-policy.setting-tooltip') }}
        </p>
      </template>
      <font-awesome-icon
        :icon="['fas', 'cog']"
        class="trust-cog-icon"
        @click="$emit('update-display-modal', true)"
      />
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'verification-policy-button',
  props: {
    verificationPolicy: {
      type: Object,
      default: () => {
        return {
          userId: '',
          verificationDueDate: '',
          verificationRange: '',
          customRoleId: '',
        };
      },
    },
    isOutdated: {
      type: Boolean,
    },
    contentId: {
      type: String,
      required: true,
    },

    reviewers: Object,
    disabled: {
      type: Boolean,
      required: true,
    },
    trustLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      trustIconClass: 'fa-check-circle',
      untrustIconClass: 'fa-times-circle',
    };
  },
  computed: {
    formatedVerificationDueDate() {
      if (this.verificationPolicy) {
        return new Date(parseInt(this.verificationPolicy.verificationDueDate));
      }
      return new Date(Date.now());
    },
    isContentTrusted() {
      return !this.isOutdated;
    },
    reviewerOptions() {
      return [
        {
          label: 'user',
          options: this.reviewers.users.map((reviewer) => ({
            label:
              reviewer.username === this.username
                ? `${this.$t(
                    'components.modals.verification-policy.reviewer.you',
                  )} (${reviewer.username})`
                : reviewer.username,
            value: { type: 'user', value: reviewer.username, id: reviewer.id },
          })),
        },
        {
          label: 'role',
          options: this.reviewers.roles.map((groupReviewer) => ({
            label: groupReviewer.name,
            value: {
              type: 'role',
              value: groupReviewer.name,
              id: groupReviewer.id,
            },
          })),
        },
      ];
    },
    reviewerModel() {
      if (this.verificationPolicy) {
        if (
          this.verificationPolicy.userId &&
          this.verificationPolicy.userId !== 'null'
        ) {
          return {
            type: 'user',
            id: this.verificationPolicy.userId,
          };
        } else {
          return {
            type: 'role',
            id: this.verificationPolicy.customRoleId,
          };
        }
      }
      return {};
    },
  },
  methods: {
    updateTrust() {
      if (this.verificationPolicy) {
        this.$emit('set-content-trust', {
          contentId: this.contentId,
          trust: !this.isContentTrusted,
        });
      }
      this.$emit('update-content', { isOutdated: this.isContentTrusted });
    },
  },
};
</script>

<style lang="scss" scoped>
.trust-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 2px 4px;
  height: 22px;
  width: fit-content;
  flex-shrink: 0;
  white-space: nowrap;
}

.trusted {
  color: $green-mayday;
  background-color: $green-1-mayday;
  border-radius: 2px;
  border: 1px solid $green-mayday;
}

.untrusted {
  color: $red-mayday;
  background-color: rgba($red-mayday, 0.1);
  border-radius: 2px;
  border: 1px solid $red-mayday;
}

.content-stamp {
  font-size: 10px;
  font-weight: 700;
  width: fit-content;
}

.trust-settings {
  visibility: visible;
  &-error:hover {
    color: $red-mayday;
  }
  &-success:hover {
    color: $green-mayday;
  }
}

.trust-cog-icon {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: $grey-5-mayday;
}

.content-stamp,
.trust-button {
  display: flex;
  align-items: center;
  line-height: normal;
}

.text-small {
  font-size: 1.1em;
}

.icon-spinner {
  color: black;
  margin-left: 8px;
}
</style>
