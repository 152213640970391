<template>
  <div
    v-loading="isLoading"
    class="col-11 mx-auto mt-2 filtered-result-card d-flex product-viewer-collapsable justify-content-between align-items-center"
  >
    <small class>
      {{ $t('knowledge.product.filtered-results.there-is') }}
      <span
        class="filtered-results-number"
      >{{ productCount }}</span>
      {{ $t('knowledge.product.filtered-results.linked-results') }}
      {{ flatFilters.length }}
      {{ $t('knowledge.product.filtered-results.filters') }}
    </small>
    <el-button size="mini" @click="seeResult">
      {{ $t('knowledge.product.filtered-results.display') }} {{ productCount }}
      {{ $t('knowledge.product.filtered-results.results') }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'FilteredResults',
  props: {
    filters: Array,
  },
  methods: {
    async countProducts(filters) {
      this.isLoading = true;
      this.productCount = await this.$services.products.filterProductsByHierarchyCount(
        filters,
      );
      this.isLoading = false;
    },
    seeResult() {
      const query = this.filters.reduce((acc, val) => {
        acc[val.key] = val.values;
        return acc;
      }, {});

      const path = this.$route.path.includes('web-parametric')
        ? '/web-parametric/filter'
        : '/knowledge/filter';

      this.$router.push({
        path,
        query: { type: 'product', ...query },
      });

      this.$emit('close');
    },
  },
  data() {
    return {
      productCount: 0,
      isLoading: false,
    };
  },
  computed: {
    flatFilters() {
      return this.filters.map((filter) => filter.values).flat();
    },
  },
  created() {
    this.countProducts(this.filters);
  },
  watch: {
    flatFilters: function() {
      this.countProducts(this.filters);
    },
  },
};
</script>

<style lang="scss" scoped>
.filtered-results-number {
  color: $blue-mayday;
  font-weight: 900;
}

.filtered-result-card {
  padding: 16px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid $grey-4-mayday;
  background-color: $grey-1-mayday;
}
</style>
