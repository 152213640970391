<template>
  <div class="parameter-row col-12">
    <el-collapse v-model="activeNames" accordion>
      <el-collapse-item name="1">
        <template slot="title">
          <ParameterTitle
            :type="paramType"
            :key-type="keyType"
            :field-type="fieldType"
            :icon="hierarchy.icon"
            :count="parameters.length"
            :enriched-chosen-params="enrichedChosenParams"
            @update-chosen-params="chosenParams = $event"
          />
        </template>
        <div class="el-collapse__main">
          <!-- IF FIELD IS MULTI -->
          <el-checkbox-group
            v-model="chosenParams"
            v-if="fieldType === 'multi'"
          >
            <el-checkbox
              v-for="({ label, value }, idx) in paramValues"
              :key="`${paramTypeLowercase}-${idx}-${label}`"
              :label="value"
              border
              size="mini"
              >{{ label }}</el-checkbox
            >
          </el-checkbox-group>

          <!-- IF FIELD IS CHECKBOX -->
          <el-radio-group
            v-model="chosenParams[0]"
            v-else-if="fieldType === 'checkbox'"
          >
            <el-radio
              v-for="({ label, value }, idx) in paramValues"
              :key="`${paramTypeLowercase}-${idx}-${label}`"
              :label="value"
              size="mini"
              class="radio-button"
              >{{ label }}</el-radio
            >
          </el-radio-group>

          <!-- IF FIELD IS REMOTE SEARCH -->
          <el-select
            v-else-if="fieldType === 'remote-search'"
            v-model="chosenParams"
            multiple
            collapse-tags
            remote
            filterable
            placeholder="Rechercher"
            style="width: 100%"
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="({ label, value }, idx) in remoteOptions"
              :key="`${paramTypeLowercase}-${idx}-${label}`"
              :label="label"
              :value="value"
            ></el-option>
          </el-select>
          <p
            v-if="parameters.length > 10 && fieldType !== 'remote-search'"
            @click="showMore = !showMore"
            class="show-more-text my-0"
          >
            {{ showMore ? 'Afficher moins' : 'Afficher plus' }}
          </p>
        </div>
      </el-collapse-item>
      <div v-for="(o, idx) in optionsType" :key="o.id">
        <ParameterRow
          v-if="
            paramsChildren.length ||
            hierarchy.options[idx].field === 'remote-search'
          "
          :parameters="paramsChildren"
          :hierarchy="hierarchy.options[idx]"
          :update-filter="updateFilter"
          :update-knowledge="updateKnowledge"
          :filters="filters"
        />
      </div>
    </el-collapse>
  </div>
</template>

<script>
import ParameterTitle from './ParameterTitle';

export default {
  name: 'ParameterRow',
  components: { ParameterTitle },
  props: {
    parameters: Array,
    hierarchy: Object,
    updateFilter: Function,
    updateKnowledge: Function,
    filters: Array,
  },
  computed: {
    enrichedChosenParams() {
      return this.chosenParams.map((param) => {
        if (this.fieldType === 'remote-search') {
          return {
            label: param,
            value: param,
          };
        }
        const paramValue = this.paramValues.find((p) => p.value === param);
        return {
          id: paramValue.id,
          label: paramValue.label,
          value: param,
        };
      });
    },
    paramType() {
      return this.hierarchy.label;
    },
    keyType() {
      return this.hierarchy.value;
    },
    fieldType() {
      return this.hierarchy.field;
    },
    optionsType() {
      return this.hierarchy.options;
    },
    paramTypeLowercase() {
      return this.paramType.toLowerCase().split(' ').join('-');
    },
    paramValues() {
      const loc_params = this.showMore
        ? this.parameters
        : this.parameters.slice(0, 10);
      return loc_params.map((p) => ({
        id: p.id,
        label: p.label,
        value: p.value,
      }));
    },
  },
  data() {
    return {
      showMore: false,
      chosenParams: [],
      activeNames: '1',
      paramsChildren: [],
      loading: false,
      remoteOptions: [],
    };
  },
  methods: {
    async remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        let results = [];
        if (this.keyType === 'facets') {
          results = await this.$services.products.regexProductFacets(query);
        } else {
          results = await this.$services.products.regexProductParameters(
            this.hierarchy.fullPath,
            query.toUpperCase(),
            this.filters.filter((f) => f.key !== 'knowledge'),
          );
        }
        this.remoteOptions = results.map((r) => ({
          label: r,
          value: r,
        }));
      } else {
        this.remoteOptions = [];
      }
      this.loading = false;
    },
  },
  watch: {
    parameters() {
      this.chosenParams = [];
    },
    chosenParams() {
      if (this.keyType !== 'knowledge') {
        this.updateFilter({
          key: this.keyType,
          values: this.chosenParams,
        });
      } else {
        this.updateKnowledge(this.chosenParams);
      }
    },
    async enrichedChosenParams() {
      if (this.enrichedChosenParams.length) {
        const paramsChildren =
          await this.$services.hierarchies.getAggregatedOptions(
            this.chosenParams,
          );
        this.paramsChildren = paramsChildren.sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
      } else {
        this.paramsChildren = [];
      }
    },
  },
  beforeDestroy() {
    this.chosenParams = [];
    this.updateFilter({
      key: this.keyType,
      values: this.chosenParams,
    });
  },
};
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0px;
}
.parameter-row {
  padding-left: 16px;
  padding-right: 0px;
}

@media only screen and (max-device-width: 768px) {
  .parameter-row {
    padding-left: 0px !important;
  }
  .el-collapse__main {
    padding-left: 12px;
    border-left: 2px solid $grey-2-mayday;
  }
}

.show-more-text {
  cursor: pointer;
  color: $grey-6-mayday;
  font-size: 12px;
  &:hover {
    color: $blue-mayday;
  }
}

.el-collapse__main {
  padding-left: 12px;
  border-left: 4px solid $grey-2-mayday;
}
// EL-COLLAPSE DESIGN

.el-collapse {
  border-top: none;
  border-bottom: none;
}
:deep() .el-collapse-item__header,
:deep() .el-collapse-item__wrap {
  border-bottom: none;
}

:deep() .el-collapse-item__content {
  padding-bottom: 0px !important;
}

// RADIO DESIGN
.radio-button {
  border: 1px solid $grey-4-mayday;
  border-radius: 2px;
  padding: 2px 4px;
  &.is-checked {
    border-color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.1);
  }
}

.el-radio {
  margin-left: 0px !important;
  margin-right: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  :deep() .el-radio__label {
    font-size: 12px;
  }
}

// CHECKBOX DESIGN
.el-checkbox {
  height: unset;
  margin-left: 0px !important;
  margin-right: 8px;
  margin-bottom: 4px;
  margin-top: 4px;
  padding: 2px 4px;
  :deep() .el-checkbox__input {
    margin-bottom: 1px;
    // .el-checkbox__inner {
    //   border-radius: 8px;
    // }
  }
  :deep() .el-checkbox__label {
    font-size: 12px;
  }
}
</style>
