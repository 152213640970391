export const UserAdministrationColumns = [
  {
    key: 'username',
    label: 'settings.users-administration.table.headers.users',
    style: 'width: 20%',
  },
  {
    key: 'email',
    label: 'settings.users-administration.table.headers.email',
    style: 'width: 20%',
  },
  {
    key: 'roles',
    label: 'settings.users-administration.table.headers.role',
    style: 'width: 10%',
  },
  {
    key: 'group',
    label: 'settings.users-administration.table.headers.group',
    style: 'width: 10%',
  },
  {
    key: 'labels',
    label: 'settings.users-administration.table.headers.label',
    style: 'width: 10%',
  },
  {
    key: 'helpdeskId',
    label: 'settings.users-administration.table.headers.helpdesk',
    style: 'width: 10%',
  },
  {
    key: 'externalId',
    label: 'settings.users-administration.table.headers.external',
    style: 'width: 10%',
  },
  {
    key: 'invitation',
    label: 'settings.users-administration.table.headers.invitation',
    style: 'width: 10%',
  },
  {
    key: 'action',
    label: 'settings.users-administration.table.headers.action',
    style: 'width: 10%; margin-left: auto ',
  },
];
