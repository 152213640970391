export const getDeskTranslatedEntityUrl = ({
  entityId,
  entityType,
  lang,
  hash = '',
  query,
  knowledgeId,
}) => {
  if (!entityId) return `/desk/${lang}/knowledges/${knowledgeId}`;

  const type = entityType === 'Case' ? 'cases' : 'contents';

  const href = `/desk/${lang}/knowledges/${knowledgeId}/${type}/${entityId}`;
  const queryString =
    Object.keys(query).length > 0
      ? `?${new URLSearchParams(query).toString()}`
      : '';

  return `${href}${queryString}${hash}`;
};

const parametricRouteMapping = {
  'knowledge-home': () => '',
  'knowledge-filter': () => '',
  'knowledge-product': (productId) => `/products/${productId}`,
  'knowledge-content': (contentId) => `/contents/${contentId}`,
  'knowledge-product-external': (externalId) =>
    `/products/external_id/${externalId}`,
};

export const getDeskParametricTranslatedEntityUrl = ({
  name,
  entityId,
  lang,
  hash = '',
  query,
}) => {
  if (!entityId) return `/desk/parametric/${lang}`;

  const href = `/desk/parametric/${lang}${parametricRouteMapping[name](
    entityId,
  )}`;
  const queryString =
    Object.keys(query).length > 0
      ? `?${new URLSearchParams(query).toString()}`
      : '';

  return `${href}${queryString}${hash}`;
};
