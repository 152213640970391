<template>
  <div
    class="position-relative d-flex suggestion-item"
    :class="[
      {
        'background-grey': hovered,
        'is-hovered': hovered,
      },
      isRecentlyConsultedSuggestion
        ? 'align-items-center'
        : 'align-items-start',
    ]"
    role="button"
    @click="$emit('go-to-document', item)"
  >
    <div :class="[{ marker: hovered }, markerColor]"></div>
    <article-type-icon
      :item="item"
      :class="{ 'd-flex': isRecentlyConsultedSuggestion }"
    ></article-type-icon>
    <el-tooltip
      v-if="item.isHidden"
      placement="top"
      effect="light"
      :content="$t('knowledge.search-admin.hidden-tooltip')"
      ><div class="is-hidden-sticker"></div
    ></el-tooltip>

    <div class="pr-2 overflow w-100">
      <div class="label-row">
        <el-tooltip :disabled="tooltipDisabled" placement="top" effect="light">
          <div slot="content" v-html="label"></div>
          <div class="label" :ref="item.id" v-html="label"></div
        ></el-tooltip>

        <ExplainPromptPopover
          :explain="item.explain"
          :searchLanguage="searchLanguage"
          :visible="popoverToDisplay === item.id"
          class="explain-prompt"
          @display-popover="$emit('display-explain-popover', item.id)"
          @close-popover="$emit('display-explain-popover', '')"
        />
      </div>
      <Breadcrumb
        v-if="item.breadcrumb"
        :items="breadcrumbItems"
        :showHouse="false"
      >
        <ProductParameters
          v-if="item.denormalizedParameters"
          :parameters="item.denormalizedParameters"
        />
      </Breadcrumb>
      <div
        class="d-flex align-items-center full-labels mr-1"
        v-if="item.labels && item.labels.length"
      >
        <ContentListItemFullLabelWrapper
          :labelSearchedIds="labelSearchedIds"
          :labels="item.labels"
        />
      </div>
    </div>
    <div
      v-if="!hovered && isRecentlyConsultedSuggestion"
      class="ml-auto d-none d-md-block align-self-center"
    >
      <TimeAgo :date="`${item.addedAt}`" class="ml-2 time-ago"></TimeAgo>
    </div>
    <button
      v-if="hovered"
      class="ml-auto d-none d-md-block btn-enter align-self-center"
    >
      {{ $t('knowledge.search-admin.enter') }}
    </button>
  </div>
</template>

<script>
import Breadcrumb from './Breadcrumb';
import ProductParameters from './ProductParameters';
import ExplainPromptPopover from './ExplainPromptPopover';
import ContentListItemFullLabelWrapper from '@/components/ListItems/ContentListItemFullLabelWrapper';
import ArticleTypeIcon from 'components/SuperSearch/ArticleTypeIcon.vue';
import TimeAgo from '@/components/Commons/TimeAgo.vue';
import { sanitize } from '@/plugins/dompurify';

export default {
  name: 'suggestion-item',
  components: {
    ArticleTypeIcon,
    Breadcrumb,
    ProductParameters,
    ExplainPromptPopover,
    ContentListItemFullLabelWrapper,
    TimeAgo,
  },
  data() {
    return {
      tooltipDisabled: true,
    };
  },
  created() {
    this.isTooltipDisabled();
  },
  props: {
    item: Object,
    hovered: {
      type: Boolean,
      default: false,
    },
    isContentPreviewed: {
      type: Boolean,
      default: false,
    },
    labelSearchedIds: { type: Array, default: () => [] },
    isRecentlyConsultedSuggestion: {
      type: Boolean,
      default: false,
    },
    searchLanguage: {
      type: String,
      default: 'default',
    },
    popoverToDisplay: {
      type: String,
      default: '',
    },
  },
  methods: {
    isTooltipDisabled() {
      this.$nextTick(() => {
        const textEl = this.$refs[this.item.id];
        if (!textEl) return true;
        this.tooltipDisabled = textEl.scrollWidth <= textEl.clientWidth;
      });
    },
  },
  computed: {
    isProduct() {
      return this.item.__typename === 'Product';
    },
    isArticle() {
      return this.item.type === 'Article';
    },
    isDiag() {
      return this.item.type === 'Diagnostic';
    },
    isStep() {
      return this.item.type === 'Step';
    },
    isKeyStep() {
      return this.item.type === 'keyStep';
    },
    isCase() {
      return this.item.__typename === 'Case';
    },
    markerColor() {
      if (this.isProduct) return 'bg-black';
      else if (this.isCase) return 'bg-grey';
      else if (this.isArticle) return 'bg-yellow';
      return 'bg-blue';
    },
    label() {
      const { explain } = this.item;
      if (explain && explain.length) {
        const labelIdx = explain.findIndex(
          (el) => el.field === 'label' && el.lang === this.searchLanguage,
        );
        if (labelIdx > -1) {
          const labelHighLights = explain[labelIdx].highlights;
          if (labelHighLights && labelHighLights.length) {
            const highlightedLabel = explain[labelIdx].highlights[0];
            if (highlightedLabel) return sanitize(highlightedLabel);
          }
        }
      }

      return this.isProduct ? this.item.reference : this.item.label;
    },
    breadcrumbItems() {
      return this.item.breadcrumb.map((el, idx) => {
        return { label: el, id: idx };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.suggestion-item {
  padding: 6px 16px;
  cursor: pointer;
  min-height: 40px;
}
.background-grey {
  background: $grey-1-mayday;
}
.marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
}
.bg-yellow {
  background: $yellow-mayday;
}
.bg-blue {
  background: $blue-mayday;
}
.bg-grey {
  background: $grey-5-mayday;
}
.bg-black {
  background: $grey-8-mayday;
}
.btn-enter {
  font-size: 8px;
  font-weight: bold;
  color: $grey-6-mayday;
  background: $grey-1-mayday;
  border: 0.5px solid $grey-4-mayday;
  border-radius: 2px;
  cursor: pointer;
}
.hidden {
  visibility: hidden;
}
.visible {
  visibility: visible;
}
.overflow {
  overflow: hidden;
  white-space: nowrap;
}
.label {
  font-size: 14px;
  font-weight: 500;
  color: $black-mayday;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;
}
.label-row {
  display: flex;
  align-items: center;
}

.full-labels {
  margin-top: 4px;
}

.explain-prompt {
  display: inline-flex;
  line-height: 0.1px;
}

.time-ago {
  white-space: nowrap;
}

.is-hidden-sticker {
  background-color: red;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  position: absolute;
  left: 29px;
  top: 9px;
}
</style>
