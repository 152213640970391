<template>
  <div class="notification__popover-footer">
    <div class="notification__popover-info_icon">
      <font-awesome-icon slot="prefix" :icon="['fa', 'info-circle']" />
    </div>
    <div class="info">
      {{ $t('notifications.footer') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'notification-footer',
};
</script>

<style lang="scss" scoped>
.notification__popover-footer {
  display: flex;
  width: 100%;
  min-width: 350px;
  padding: 6px 4px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e4e7ed;
  font-size: 12px;
  color: $blue-mayday;
  word-break: break-word;
}

.notification__popover-info_icon {
  margin: 0 10px;
  color: $blue-mayday;
}

.info {
  margin-right: 4px;
}
</style>
