<template>
  <div class="redirection-view">
    <font-awesome-icon
      v-if="!isBlocked"
      :icon="['fas', 'spinner-third']"
      class="redirection-view-icon"
      spin
    />
    <button v-else class="redirection-view-btn" @click="handleRefresh">
      {{ $t('mayday-web.avatar-dropdown.refresh-knowledge') }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MaydayWebV2Redirection',
  props: {
    isParametric: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBlocked: false,
    };
  },
  mounted() {
    // add source=admin to avoid infinite loop
    if (this.$route.query.source === 'admin') {
      this.isBlocked = true;
      return;
    }

    if (this.isParametric) return this.redirectToParametric();

    if (this.$route.path === '/') {
      window.location.href = `${window.location.origin}/desk?source=admin`;
      return;
    }

    const path = this.$route.path.replace('/web', '/desk');

    window.location.href = `${window.location.origin}${path}?source=admin`;
  },
  computed: mapGetters('knowledgeModule', ['navigationLanguage']),
  methods: {
    redirectToParametric() {
      const deskParametricBasePath = `/desk/parametric/${
        this.navigationLanguage || 'default'
      }`;

      if (this.$route.name === 'web-filter-results') {
        const path = this.$route.fullPath.replace(
          '/web-parametric',
          deskParametricBasePath,
        );
        window.location.href = `${window.location.origin}${path}&source=admin`;
        return;
      }

      let path;
      if (this.$route.path.includes('/case/')) {
        path = deskParametricBasePath;
      } else {
        path = this.$route.path.replace(
          '/web-parametric',
          deskParametricBasePath,
        );
      }
      window.location.href = `${window.location.origin}${path}?source=admin`;
    },
    handleRefresh() {
      const url = window.location.href.replace('?source=admin', '');
      window.location.href = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.redirection-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.redirection-view-icon {
  font-size: 5rem;
  color: $blue-mayday;
}

.redirection-view-btn {
  font-size: 16px;
  color: white;
  background: $blue-mayday;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  outline: none;
}
</style>
