/* eslint-disable no-unused-vars */
import gqlService from 'utils/gqlService';

export default {
  methods: {
    /**
     ************************* GROUPS *************************
     */
    createKnowledgeGroup(newGroup) {
      return gqlService.knowledge.createKnowledgeGroup(newGroup);
      // .then((groups) => this.$store.dispatch('updateKnowledgeGroups', groups))
    },
    updateKnowledgeGroup(newGroup) {
      gqlService.knowledge.updateKnowledgeGroup(newGroup);
      // .then((groups) => this.$store.dispatch('updateKnowledgeGroups', groups))
    },
    deleteKnowledgeGroup(groupId) {
      gqlService.knowledge.deleteKnowledgeGroup(groupId);
      // .then((groups) => this.$store.dispatch('updateKnowledgeGroups', groups))
    },
    /**
     ************************* MAGIC ANSWERS *************************
     */
    createKnowledgeMagicAnswer(newMagicAnswer) {
      gqlService.knowledge.createKnowledgeMagicAnswer(newMagicAnswer);
      // .then((magicAnswers) =>
      //   this.$store.dispatch('updateMagicAnswers', magicAnswers),
      // );
    },
    deleteKnowledgeMagicAnswer(magicAnswerId) {
      gqlService.knowledge.deleteKnowledgeMagicAnswer(magicAnswerId);
      // .then((magicAnswers) =>
      //   this.$store.dispatch('updateMagicAnswers', magicAnswers),
      // );
    },
  },
};
