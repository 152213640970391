var render = function render(_c,_vm){return _c('div',_vm._l((_vm.$options.filtersToDisplay(_vm.props.filters)),function(group,i){return _c(_vm.$options.components.Group,{key:_vm.$options.generateUniqueGroupKey(group, i),tag:"component",attrs:{"name":group.label}},_vm._l((group.filters),function(filter,j){return _c(_vm.$options.determineFilterComponent(filter),_vm._b({key:_vm.$options.generateUniqueFilterKey(group, filter),tag:"component",attrs:{"filter":filter,"value":_vm.props.values[i][j],"condition-value":_vm.props.additionalValues[`${filter.label}Condition`],"remote-value":_vm.props.additionalValues[`${filter.label}Remote`]},on:{"change":(value) => _vm.props.handleChange({ i, j, value }),"change-additional":({ key, value }) =>
          _vm.props.handleChangeAdditional({
            i,
            j,
            key: `${filter.label}${key}`,
            value,
          }),"reset":() => _vm.props.handleReset({ i, j, label: filter.label }),"unselect-all":() => _vm.props.handleReset({ i, j, unselect: true })}},'component',_vm.$options.computeAdditionalProps(filter, _vm.props),false))}),1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }