<template>
  <div class="case__list__item-row" @click.prevent="openCase">
    <div
      class="col-12 d-flex align-items-start full-width"
      :class="
        extraPadding
          ? 'case__result__link__extra-padding'
          : 'case__result__link'
      "
    >
      <font-awesome-icon :icon="['fad', 'folder']" class="mr-2 case-icon" />

      <div class="min-width-0 overflow">
        <div class="collection-label-span">{{ collection.label }}</div>
        <breadcrumb :items="collection.breadcrumb" />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/SuperSearch/Breadcrumb';

export default {
  name: 'case-list-item',
  components: {
    Breadcrumb,
  },
  props: {
    collection: Object,
    extraPadding: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openCase() {
      this.$emit('open-case', this.collection);
    },
  },
};
</script>

<style lang="scss" scoped>
.case__list__item-row {
  padding: 4px 0px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: $grey-1-mayday;
    cursor: pointer;
    & .product-icon {
      color: $blue-mayday;
    }
  }
  .case-icon {
    height: 16px;
    width: 16px;
  }
}

.case__result__link {
  padding: 0px 12px 0px 8px;

  &__extra-padding {
    padding: 0px 24px 0px 30px;
  }
}

.collection-label-span {
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.min-width-0 {
  min-width: 0;
}

.overflow {
  overflow: hidden;
  white-space: nowrap;
}
</style>
