<template>
  <div class="mb-2 flex flex-col space-y-2">
    <PluginsSetting
      v-for="child in children"
      :key="child.label"
      v-bind="child.props"
    >
    </PluginsSetting>
  </div>
</template>

<script>
import PluginsSetting from './PluginSetting';

export default {
  name: 'plugin-catalog',
  props: {
    children: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    PluginsSetting,
  },
};
</script>

<style lang="scss" scoped></style>
