<template>
  <div class="product container" v-loading="productLoading">
    <div class="row mt-4" v-if="product.id">
      <ProductViewerAside
        v-if="!productLoading"
        class="d-none d-md-flex"
        :content-title="product.reference"
        :slide-show="showSlideShow"
        :images="product.pictures"
        :image-index="imageIndex"
        @update-image-index="updateImageIndex"
        @open-carousel="showSlideShow = true"
      />
      <main class="col-lg-8 col-md-10 col-sm-12 mt-2 mb-8">
        <ProductViewerHeader
          :product="product"
          :enriched-parameters="enrichedParameters"
        />
        <ProductNotifications
          :notifications="product.notifications.results"
          @delete-notification="deleteProductNotification"
          @open-create-product-notification-modal="
            openCreateProductNotificationModal
          "
        />

        <AttachedFiles
          :attached-files="refinedAttachedResolutions"
          :count="refinedAttachedResolutionsCount"
          :are-attached-files-loading="areAttachedFilesLoading"
          @display-more-attached-files="displayMoreAttachedFiles"
          @display-less-attached-files="displayLessAttachedFiles"
          @focus-content="focusContent"
        />

        <SolutionFiltering
          ref="solution-filtering"
          :solutions="refinedResolutions"
          :solutions-count="refinedResolutionsCount"
          :product-id="product.id"
          :knowledge="product.knowledge"
          :is-solution-loading="isSolutionLoading"
          :resolution-search="contentSearch"
          :path="collectionParents"
          @update-resolution-search="updateResolutionSearch"
          @display-more-solutions="displayMoreSolutions"
          @display-less-solutions="displayLessSolutions"
          @update-solutions="updateSolutions"
          @focus-content="focusContent"
        />
      </main>
      <aside class="col-lg-2 px-0 side-pannel">
        <ProductParameterSideBar :enriched-parameters="enrichedParameters" />
      </aside>
    </div>

    <CreateProductNotificationModal
      v-if="isCreateProductNotificationModalOpen"
      :open="isCreateProductNotificationModalOpen"
      nodeId="1"
      @close="isCreateProductNotificationModalOpen = false"
      @create-product-notification="createProductNotification"
    />
    <CarouselModal
      v-if="showSlideShow"
      :content-title="product.reference"
      :modals="modals"
      :slide-show="showSlideShow"
      :image-index="imageIndex"
      :images="product.pictures"
      @close="showSlideShow = false"
      @update-image-index="updateImageIndex"
    />
  </div>
</template>

<script>
import ProductViewerAside from './ProductViewerAside';
import ProductViewerHeader from './ProductViewerHeader';
import ProductNotifications from './ProductNotifications';
import AttachedFiles from './AttachedFiles';
import SolutionFiltering from './SolutionFiltering';
import ProductParameterSideBar from './ProductParameterSideBar';
import CreateProductNotificationModal from '@/components/Modals/CreateProductNotificationModal/CreateProductNotificationModal.vue';
import CarouselModal from './CarouselModal';
import { mapGetters, mapActions } from 'vuex';

const defaultData = {
  product: {},
  AttachedFiles: {},
  modals: {
    slideShow: false,
  },
  isCreateProductNotificationModalOpen: false,
  imageIndex: 0,
  cases: [],
  solutions: null,
  solutionsCount: null,
  attachedResolutions: null,
  attachedResolutionsCount: null,
  productLoading: false,
  showSlideShow: false,
  isSolutionLoading: false,
  areAttachedFilesLoading: false,
  contentSearch: '',
  loadingMessage: null,
};

export default {
  name: 'product-viewer',
  components: {
    ProductViewerAside,
    ProductViewerHeader,
    ProductNotifications,
    AttachedFiles,
    SolutionFiltering,
    ProductParameterSideBar,
    CreateProductNotificationModal,
    CarouselModal,
  },
  computed: {
    refinedResolutions() {
      return this.solutions && this.solutions.length
        ? this.solutions
        : this.product.resolutionsFilter.results;
    },
    refinedResolutionsCount() {
      return this.solutionsCount || this.product.resolutionsFilter.total;
    },
    refinedAttachedResolutions() {
      return (
        this.attachedResolutions || this.product.attachedResolutions.results
      );
    },
    refinedAttachedResolutionsCount() {
      return (
        this.attachedResolutionsCount || this.product.attachedResolutions.total
      );
    },
    enrichedParameters() {
      if (!this.uniqueIds.length) return this.product.enrichedParameters;

      const newEnrichedParameters = [...this.product.enrichedParameters];
      const codicIndex = newEnrichedParameters.findIndex(
        ({ key }) => key === 'codic',
      );

      newEnrichedParameters[codicIndex].values = this.uniqueIds;
      return newEnrichedParameters;
    },
    uniqueIds() {
      if (!this.product.uniqueIds) return [];
      return Object.keys(this.product.uniqueIds).map((uniqueId) => ({
        id: uniqueId,
        label: `${uniqueId.toUpperCase()} : ${
          this.product.uniqueIds[uniqueId]
        }`,
      }));
    },
    ...mapGetters('knowledgeModule', ['collectionParents']),
  },
  async beforeCreate() {
    this.productLoading = true;
    const { productId, externalId } = this.$route.params;
    if (productId) {
      this.product = await this.$services.products.get(
        productId,
        this.contentSearch,
      );
    }
    if (externalId)
      this.product = await this.$services.products.getByExternalId(externalId);
    this.$emit('change-route-type', 'Product');
    this.productLoading = false;
  },
  created() {
    this.loadingMessage = this.$message({
      duration: 0,
      dangerouslyUseHTMLString: true,
      iconClass: 'display: none',
      showClose: true,
      message: `<i class="fas fa-spinner fa-spin" style="margin-right: 1em;"></i> Fiche produit en cours de chargement ...`,
    });
  },
  data() {
    return {
      ...defaultData,
    };
  },
  mounted() {
    this.$root.$on('open-product-viewer-carousel', () => {
      this.showSlideShow = true;
    });
  },
  methods: {
    focusContent(id) {
      const path = this.$route.path.includes('web-parametric')
        ? '/web-parametric/content/'
        : '/knowledge/content/';

      this.$router.push({
        path: path + id,
      });
    },
    updateImageIndex(imageIndex) {
      this.imageIndex = imageIndex;
    },
    deleteProductNotification(productNotificationId) {
      this.$services.productNotifications
        .deleteProductNotification(productNotificationId)
        .then((result) => {
          if (result) {
            this.product.notifications.results =
              this.product.notifications.results.filter(
                (notification) => notification.id !== productNotificationId,
              );
            this.product.notifications.total =
              this.product.notifications.results.length;
            this.$message(this.$t('knowledge.product.notification.deleted'));
          }
        });
    },
    openCreateProductNotificationModal() {
      this.isCreateProductNotificationModalOpen = true;
    },
    areParametersMatching(productNotification) {
      const parameters = { ...this.product.parameters };
      const facets = [...this.product.facets];

      const parametersMatch = Object.keys(parameters).every((parameterKey) => {
        if (productNotification.parameters[parameterKey]) {
          return productNotification.parameters[parameterKey].includes(
            parameters[parameterKey],
          );
        }
        return true;
      });

      const facetsMatch = productNotification.parameters['facets']
        ? productNotification.parameters['facets'].some((f) =>
            facets.includes(f),
          )
        : true;

      return parametersMatch && facetsMatch;
    },
    createProductNotification(productNotification) {
      this.$services.productNotifications
        .createProductNotification(productNotification)
        .then((newProductNotification) => {
          if (this.areParametersMatching(newProductNotification)) {
            this.product.notifications.results.push(newProductNotification);
            this.product.notifications.total =
              this.product.notifications.results.length;
          }
          this.$message(this.$t('knowledge.product.notification.created'));
        });
    },
    async displayMoreAttachedFiles() {
      this.areAttachedFilesLoading = true;
      this.attachedResolutions = await this.$services.products
        .getAttachedFilesByProductId(
          this.product.id,
          this.attachedResolutionsCount,
        )
        .then((res) => res.results);
      this.areAttachedFilesLoading = false;
    },
    displayLessAttachedFiles() {
      this.attachedResolutions = this.attachedResolutions.splice(0, 19);
    },
    async displayMoreSolutions() {
      if (this.collectionParents.length) {
        return this.updateSolutions({
          path: this.collectionParents,
          count: this.refinedResolutionsCount,
        });
      }
      this.isSolutionLoading = true;
      await this.$services.parametricContents
        .getContentsByProductId(
          this.product.id,
          this.refinedResolutionsCount,
          this.contentSearch,
        )
        .then((response) => {
          this.solutions = response.results;
          this.solutionsCount = response.total;
        });
      this.isSolutionLoading = false;
    },
    displayLessSolutions() {
      this.solutions = this.solutions.splice(0, 9);
    },
    async updateSolutions({ path, count = 10, search = '' }) {
      this.isSolutionLoading = true;
      this.setCollectionParents(path);
      if (path && path.length) {
        await this.updateSolutionsWithPath(path, count, search);
      } else {
        await this.updateSolutionsWithoutPath(count, search);
      }
      this.isSolutionLoading = false;
    },
    async updateSolutionsWithPath(path, count, search) {
      const caseId = path.slice(-1)[0];
      const filter = await this.$services.cases.getResolutionsById(
        caseId,
        this.product.id,
        count,
        search,
      );
      this.solutions = filter.resolutions.results;
      this.solutionsCount = filter.resolutions.total;
    },
    async updateSolutionsWithoutPath(count, search) {
      await this.$services.parametricContents
        .getContentsByProductId(this.product.id, count, search)
        .then((response) => {
          this.solutions = response.results;
          this.solutionsCount = response.total;
        });
    },
    async updateResolutionSearch(search) {
      await this.updateSolutions({ path: this.collectionParents, search });
      this.contentSearch = search;
    },
    async loadProduct() {
      Object.assign(this.$data, defaultData);
      this.setCollectionParents([]);
      this.productLoading = true;
      const { productId, externalId } = this.$route.params;
      if (productId) {
        this.product = await this.$services.products.get(
          productId,
          this.contentSearch,
        );
      }
      if (externalId)
        this.product = await this.$services.products.getByExternalId(
          externalId,
        );
      this.$emit('change-route-type', 'Product');
      this.productLoading = false;
    },
    ...mapActions('knowledgeModule', ['setCollectionParents']),
  },
  watch: {
    product() {
      if (this.loadingMessage) this.loadingMessage.close();
    },
    async $route() {
      await this.loadProduct();
      this.$refs['solution-filtering'].getCases();
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-device-width: 992px) {
  .side-pannel {
    display: none;
  }
}

.side-pannel {
  z-index: 0;
}
</style>
