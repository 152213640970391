<template>
  <div class="content-preview-step-navigator">
    <el-dropdown
      split-button
      plain
      type="primary"
      trigger="click"
      size="mini"
      @click="handleClick"
      @command="handleCommand"
    >
      <div class="label-wrapper">
        <TextEllipsisVue :text="ancestor.label" :fontSize="'12px'" />
      </div>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="step in toDisplaySteps"
          :key="step.id"
          :command="step.id"
          >{{ step.label }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import TextEllipsisVue from '../../TextEllipsis.vue';

export default {
  name: 'content-preview-step-navigator',
  components: { TextEllipsisVue },
  props: {
    ancestor: {
      type: Object,
      required: true,
    },
    currentStepId: { type: String, default: '' },
  },
  methods: {
    handleCommand(e) {
      const step = this.toDisplaySteps.find((step) => step.id === e);
      if (step.redirection) {
        this.$emit('redirection', { entityId: step.redirection.entityId });
      } else {
        this.$emit('click-on-step', { entityId: e });
      }
    },
    handleClick() {
      this.$emit('click-on-step', { entityId: this.ancestor.id });
    },
  },
  computed: {
    toDisplaySteps() {
      return this.ancestor.children.filter(
        (step) => step.id !== this.currentStepId,
      );
    },
  },
};
</script>

<style lang="scss">
.content-preview-step-navigator {
  max-width: calc(100% - 120px);
  white-space: nowrap;
}
.label-wrapper {
  max-width: 300px;
  height: 12px;
}
.el-dropdown {
  width: 100%;
  .el-button-group {
    display: flex;
    width: 100%;
    .el-button {
      width: calc(100% - 30px);
    }
  }
}
</style>
