<template>
  <div class="d-flex justify-content-center">
    <div class="section col-11">
      <p
        class="text-small font-weight-bold text-center"
      >{{ $t('knowledge.modals.notifications.groups.description') }}</p>
      <label class="group-selector__label">
        <i class="fa fa-user mr-1 group-selector__label__icon"></i>
        {{ $t('knowledge.modals.notifications.groups.label') }}
      </label>
      <el-select
        v-model="newGroupIds"
        multiple
        :placeholder="$t('knowledge.modals.notifications.groups.placeholder')"
        style="width: 100%"
        @change="sendUpdateGroupIdsEvent"
      >
        <el-option
          v-for="group in possibleUserGroups"
          :key="group.id"
          :label="group.name"
          :value="group.id"
        ></el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'group-selector',
  props: {
    groupIds: Array,
    possibleUserGroups: Array,
  },
  data() {
    return {
      newGroupIds: this.groupIds,
    };
  },
  methods: {
    sendUpdateGroupIdsEvent(val) {
      this.$emit('update-group-ids', val);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-small {
  color: $grey-5-mayday;
  font-size: 0.9em;
}

.group-selector__label__icon {
  color: $grey-4-mayday;
}
</style>
