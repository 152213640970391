<template>
  <div class="automation-integration-attributes-menu">
    <menu-collapse :title="title">
      <div v-for="attr in attributes" :key="attr.id">
        <small>{{ attr.label }}</small>
      </div>
    </menu-collapse>
  </div>
</template>

<script>
import MenuCollapse from '@/components/Collapse/MenuCollapse';

export default {
  name: 'AutomationIntegrationAttributesMenu',
  components: {
    MenuCollapse,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    attributes: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
