var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"element-title"},[_vm._v(" "+_vm._s(_vm.$t('settings.general.alerts-notifs.general-settings'))+" ")]),_c('div',{staticClass:"element-description mb-3"},[_vm._v(" "+_vm._s(_vm.$t('settings.general.alerts-notifs.description'))+" ")]),_vm._l((_vm.notificationPreferenceCategories),function(preferenceCategory){return _c('div',{key:preferenceCategory.title},[(preferenceCategory.input === 'checkbox')?_c('settings-dropdown',[_c('template',{slot:"title"},[_c('settings-dropdown-capitalized-column',{attrs:{"text":_vm.$t(preferenceCategory.title)}})],1),_c('template',{slot:"dropdown-content"},[_c('table',{staticClass:"preferences-table"},[_c('thead',[_c('tr',{staticClass:"space-under"},[_c('th',{staticClass:"first-column-header"}),_vm._l((_vm.channels),function(channel){return _c('th',{key:channel.value,staticClass:"text-center"},[_c('div',[_c('font-awesome-icon',{staticClass:"channel-icon",attrs:{"icon":['fal', channel.icon]}})],1)])})],2)]),_c('tbody',_vm._l((preferenceCategory.children),function(child){return _c('tr',{key:child.value},[_c('td',[_c('div',{staticClass:"element-title"},[_vm._v(_vm._s(_vm.$t(child.title)))]),_c('div',{staticClass:"element-description"},[_vm._v(" "+_vm._s(_vm.$t(child.description))+" ")])]),_vm._l((child.checkboxes),function(checkbox){return _c('td',{key:checkbox.type},[_c('div',{staticClass:"checkbox-wrapper"},[_c('el-checkbox',{staticClass:"checkbox-button",attrs:{"size":"mini","value":!!checkbox.value,"disabled":checkbox.disabled},on:{"change":function($event){return _vm.updatePreferences(
                        $event,
                        checkbox.type,
                        preferenceCategory.value,
                      )}}})],1)])})],2)}),0)])])],2):(preferenceCategory.input === 'switch')?_c('plugin-setting',{staticClass:"mt-3",attrs:{"label":preferenceCategory.children[0].title,"description":preferenceCategory.children[0].description,"active-text":preferenceCategory.children[0].activeText,"disable-text":preferenceCategory.children[0].disableText,"company-preference":preferenceCategory.children[0].value,"getter":preferenceCategory.children[0].getter}}):_vm._e()],1)}),_c('EmailLanguageSelect',{staticClass:"mt-3",attrs:{"email-language-preference":_vm.emailLanguagePreference},on:{"update-preference":function($event){$event.preferenceId
        ? _vm.updateCompanyPreferencesValue($event)
        : _vm.handleAdd('EMAIL_PREFERRED_LANGUAGE', $event.preferenceValue)}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }