<template>
  <div class="user-filter-picker">
    <div
      class="d-flex flex-row align-items-center justify-content-between header"
    >
      <div class="main-option-picker">
        <el-select
          v-model="filter.key"
          popper-class="mt-0"
          :placeholder="$t('knowledge.search-admin.quick-filters.choose')"
        >
          <el-option
            class="main-option-item"
            v-for="option in mainOptions"
            :key="option.key"
            :label="
              $t(
                `knowledge.search-admin.quick-filters.user.main-options.${option.key}`,
              )
            "
            :value="option.key"
          ></el-option>
        </el-select>
      </div>
      <div class="clear-button" @click="resetFilter">
        {{ $t('knowledge.search-admin.quick-filters.clear') }}
      </div>
    </div>

    <hr class="m-0 mb-1" v-if="filter.key" />
    <div class="sub-options-wrapper mb-1" v-if="filter.key">
      <UserSearch
        :user-permission-key="filter.key"
        :selected-user-id="selectedUserId"
        @set-user="filter.value = $event"
      ></UserSearch>
    </div>
  </div>
</template>

<script>
import UserSearch from './UserSearch.vue';
export default {
  name: 'user-filter-picker',
  props: {
    chosenUser: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mainOptions: [{ key: 'authorId' }, { key: 'contentUpdatedBy' }],

      filter: {
        key: 'authorId',
        value: {
          key: '',
          value: {},
        },
      },
    };
  },
  components: { UserSearch },
  created() {
    if (this.chosenUser && this.chosenUser.key)
      this.filter.key = this.chosenUser.key;
  },
  computed: {
    selectedUserId() {
      if (!this.chosenUser || !this.chosenUser.key || !this.chosenUser.value)
        return '';
      return this.chosenUser.value;
    },
  },
  methods: {
    handleFilterChange() {
      if (
        this.filter.key &&
        this.filter.value &&
        this.filter.value.key &&
        this.filter.value.value
      ) {
        this.$emit('set-user', this.filter);
        this.$emit('quit-user');
      }
    },
    resetFilter() {
      this.filter = {
        key: '',
        value: {
          key: '',
          value: {},
        },
      };
      this.$emit('set-user', this.filter);
    },
  },

  watch: {
    'filter.key': {
      handler() {
        this.handleFilterChange();
      },
      deep: true,
    },
    'filter.value': {
      handler() {
        this.handleFilterChange();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.user-filter-picker {
  background-color: white;
  width: 260px;
}
.header {
  padding: 4px 8px;
}
.sub-options-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.sub-option {
  padding: 8px 12px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 24px;
  font-size: 14px;
  color: black;
  gap: 4px;
  &:hover {
    background-color: $grey-3-mayday;
    cursor: pointer;
  }
  &__selected {
    background-color: $grey-3-mayday;
    color: $blue-mayday;
  }
}
:deep() .el-select {
  .el-input .el-input__inner {
    height: 24px;
    border: none;
    display: flex;
    align-items: center;
    padding-left: 4px;
    font-weight: 600;
    font-size: 12px;
  }
}

:deep() .el-input__suffix {
  display: flex;
  align-items: center;
}

.clear-button {
  color: $grey-4-mayday;
  cursor: pointer;
}

.main-option-item {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 24px;
  padding: 4px 0px 4px 4px;
}
</style>
