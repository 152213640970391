<template>
  <div class="mb-3">
    <div class="mb-1">
      <div class="element-title">
        {{ $t('settings.company.user-settings.language') }}
      </div>
    </div>

    <div class="d-flex flex-row align-items-center">
      <el-select
        size="small"
        class="select-language"
        v-model="selectedLanguage"
        @change="changeAppLanguage"
      >
        <template #prefix>
          <LanguageFlag :language="selectedLanguage" emoji />
        </template>
        <el-option
          v-for="lang in options"
          :key="lang"
          :value="lang"
          :label="$t(`settings.company.user-settings.languages.${lang}`)"
        >
          <div class="option-container">
            <LanguageFlag :language="lang" emoji />
            <span>
              {{ $t(`settings.company.user-settings.languages.${lang}`) }}
            </span>
          </div>
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LanguageFlag from '@/components/LanguageFlag';

export default {
  name: 'settings-language-select',
  components: { LanguageFlag },
  props: {},
  data() {
    return {
      options: ['fr', 'en', 'es', 'de', 'it', 'pt', 'nl'],
      selectedLanguage: 'fr',
    };
  },
  mounted() {
    this.selectedLanguage = this.$i18n.locale;
  },
  methods: {
    changeAppLanguage(lang) {
      this.$i18n.locale = lang;
      this.updateAppLanguage(lang);
      // Set in window for web-components when rendered and eventListener for web-components already rendered
      window.___MAYDAY_CE___.locale = lang;
      window.dispatchEvent(
        new CustomEvent('md-updateAppLanguage', { detail: lang }),
      );
    },
    ...mapActions(['updateAppLanguage']),
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.preference-label {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
}
.preference-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
.select-language {
  :deep() .el-input__inner {
    height: 30px;
    width: 180px;
    padding-left: 48px;
  }
  :deep() .el-input__prefix {
    margin-top: 6px;
    padding-left: 16px;
  }
}

.option-container {
  display: flex;
  column-gap: 16px;
  letter-spacing: 0.02rem;
}
</style>
