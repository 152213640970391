<template>
  <div class="analytics-contents-table-container">
    <LazyCustomTable
      v-loading="isLoading"
      class="analytics-contents-table"
      :options="tableOptions"
      :headers="tableHeaders"
      :data="data"
      :init-data-options="dataOptions"
      :max-height="maxHeight"
    >
      <template v-slot:empty>
        <EmptyState
          v-if="isEmpty"
          :external-doc-url="externalDocUrl"
          :link-anchor-name="externalDocAnchor"
          @open-filters-drawer="() => $emit('open-filters-drawer')"
        />
      </template>
      <template v-slot:function="{ row: { functionName } }">
        <span>{{ functionName }}</span>
      </template>
      <template v-slot:url="{ row: { url } }">
        <span>{{ url }}</span>
      </template>
      <template v-slot:min="{ row: { min } }">
        <span>{{ min.toFixed(0) }}</span>
      </template>
      <template v-slot:avg="{ row: { avg } }">
        <span>{{ avg.toFixed(0) }}</span>
      </template>
      <template v-slot:median="{ row: { median } }">
        <span>{{ median.toFixed(0) }}</span>
      </template>
      <template v-slot:p50="{ row: { p50 } }">
        <span>{{ p50.toFixed(0) }}</span>
      </template>
      <template v-slot:p75="{ row: { p75 } }">
        <span>{{ p75.toFixed(0) }}</span>
      </template>
      <template v-slot:p90="{ row: { p90 } }">
        <span>{{ p90.toFixed(0) }}</span>
      </template>
      <template v-slot:p95="{ row: { p95 } }">
        <span>{{ p95.toFixed(0) }}</span>
      </template>
      <template v-slot:p99="{ row: { p99 } }">
        <span>{{ p99.toFixed(0) }}</span>
      </template>
      <template v-slot:max="{ row: { max } }">
        <span>{{ max.toFixed(0) }}</span>
      </template>
      <template v-slot:s2xx="{ row: { s2xx } }">
        <span>{{ s2xx.toFixed(0) }}</span>
      </template>
      <template v-slot:s3xx="{ row: { s3xx } }">
        <span>{{ s3xx.toFixed(0) }}</span>
      </template>
      <template v-slot:s4xx="{ row: { s4xx } }">
        <span>{{ s4xx.toFixed(0) }}</span>
      </template>
      <template v-slot:s5xx="{ row: { s5xx } }">
        <span>{{ s5xx.toFixed(0) }}</span>
      </template>
      <template v-slot:total="{ row: { total } }">
        <span>{{ total.toFixed(0) }}</span>
      </template>
    </LazyCustomTable>
  </div>
</template>

<script>
import LazyCustomTable from '@/components/Tables/LazyCustomTable';
import EmptyState from '../EmptyState';

export default {
  components: {
    LazyCustomTable,
    EmptyState,
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
    dataOptions: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    tableHeaders: {
      type: Array,
      required: true,
    },
    maxHeight: {
      type: [String, Number],
      default: 200,
    },
  },
  mounted() {},

  data() {
    return {
      tableOptions: {
        search: false,
        selectRowsCount: false,
        showItemCount: false,
        bulkActions: false,
        pagination: false,
        expandableRows: false,
      },
    };
  },

  methods: {
    handleChange(event) {
      this.$emit('update:searchFilter', event.value);
    },
    shouldDisableLanguagesPopover(languages) {
      return !languages || !Object.keys(languages).length;
    },

    computeDeletedClass(title) {
      return title == null || !title.length ? 'deleted' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.view-container {
  width: fit-content;
  padding-right: 8px;
}
.analytics-contents-table-container {
  .header {
    display: flex;
  }

  .analytics-contents-table {
    margin-top: 20px;

    :deep() .el-table__body-wrapper {
      max-height: 40vh;
    }

    .cell {
      .content-icon,
      .collection-icon,
      .views-icon,
      .no-feedback-icon,
      .article-icon,
      .diagnostic-icon,
      .analyze-icon {
        margin-right: 10px;
        width: 16px;
        height: 16px;
        vertical-align: text-bottom;
      }

      .content-icon {
        display: inline-block;
      }
      .article-icon {
        background-image: url('~@/assets/article-icon-v2.1.svg');
        background-size: cover;
        background-position: center;
      }
      .diagnostic-icon {
        background-image: url('~@/assets/diag-icon-v2.1.svg');
        background-size: cover;
        background-position: center;
      }

      .analyze-icon {
        color: $grey-5-mayday;
        &:hover {
          color: $blue-mayday;
          cursor: pointer;
        }
      }

      .collection-title.deleted {
        color: $grey-5-mayday;
        font-style: italic;
      }

      .views-icon {
        &.flat {
          color: rgba($yellow-mayday, 0.8);
        }
        &.up {
          transform: rotate(-45deg);
          color: rgba($green-mayday, 0.8);
        }
        &.down {
          transform: rotate(45deg);
          color: rgba($red-mayday, 0.8);
        }
      }

      .no-feedback {
        color: $grey-5-mayday;
      }
    }
  }
  :deep() .collections > .cell {
    white-space: nowrap;
  }
}
</style>
