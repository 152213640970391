<template>
  <modal :show.sync="localOpen" :show-close="false" headerClasses="p-0 m-0">
    <ModalHeader
      slot="header"
      :title="
        $t('settings.card-settings-options.case-parameters.modals.delete.title')
      "
      @close="closeModal"
    />

    <section class="delete-cases-modal__section mb-4">
      <img
        src="@/assets/deletion-warning.svg"
        alt="Deletion warning"
        class="mb-4"
      />
      <div v-if="relatedContentsCount > 0">
        <p>
          {{
            $t(
              'settings.card-settings-options.case-parameters.modals.delete.warning-part1',
            )
          }}
          <strong class="text-red">{{
            $t(
              'settings.card-settings-options.case-parameters.modals.delete.warning-part2',
            )
          }}</strong>
          {{
            $t(
              'settings.card-settings-options.case-parameters.modals.delete.warning-part3-bis',
            )
          }}
          {{ relatedContentsCount }}
          {{
            $t(
              'settings.card-settings-options.case-parameters.modals.delete.warning-part4',
            )
          }}
        </p>

        <div
          v-if="relatedContentsCount > 0"
          class="wrapper row justify-content-center mx-0 py-2 verification-form"
        >
          <BaseInput
            :required="true"
            :placeholder="'Taper le nom du paramètre'"
            :value="verificationValue"
            @input="updateVerificationValue"
          />
        </div>
      </div>
      <div v-else>
        <p>
          {{
            $t(
              'settings.card-settings-options.case-parameters.modals.delete.delete-part1',
            )
          }}
          <strong class="text-red"
            >{{
              $t(
                'settings.card-settings-options.case-parameters.modals.delete.delete-part2',
              )
            }} </strong
          >{{
            $t(
              'settings.card-settings-options.case-parameters.modals.delete.delete-part3-bis',
            )
          }}
        </p>
      </div>
    </section>

    <DeleteContentLabelModalFooter
      :can-user-delete-case="isValid"
      @close="closeModal"
      @delete="deleteLabel"
    />
  </modal>
</template>

<script>
import ModalHeader from './ModalHeader';
import BaseInput from '@/components/BaseInput';
import DeleteContentLabelModalFooter from './DeleteContentLabelModalFooter';

export default {
  name: 'delete-content-label-modal',
  components: {
    ModalHeader,
    BaseInput,
    DeleteContentLabelModalFooter,
  },
  props: {
    open: Boolean,
    relatedContentsCount: Number,
    valueToHave: String,
  },
  data() {
    return {
      localOpen: this.open,
      verificationValue: '',
    };
  },
  methods: {
    searchFilteredContents() {
      this.$router.push({
        path: '/knowledge/filter',
        query: {
          type: 'content',
        },
      });
    },
    closeModal() {
      this.localOpen = false;
    },
    deleteLabel() {
      this.$emit('delete');
      this.localOpen = false;
    },
    updateVerificationValue(event) {
      this.verificationValue = event;
    },
  },
  computed: {
    isValid() {
      return (
        this.relatedContentsCount === 0 ||
        this.valueToHave === this.verificationValue
      );
    },
  },
  watch: {
    localOpen() {
      if (!this.localOpen) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss">
.overflow-modal {
  max-height: 80vh !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
</style>

<style lang="scss" scoped>
.delete-cases-modal__section {
  text-align: center;
  max-height: 60vh;
  overflow: auto;
}

.text-red {
  color: $red-mayday !important;
}

.blue-link {
  color: $blue-mayday;
  background-color: transparent;
  border: none;
  font-size: 1rem;
  font-weight: bold;

  &:hover {
    color: $grey-9-mayday;
  }
}

.wrapper {
  background-color: $grey-1-mayday;
  border-radius: 4px;
}

.verification-form :deep() .form-group {
  margin-bottom: 0 !important;
  width: 90%;
}
</style>
