<template>
  <div :class="`${base}`">
    <div :class="`${base}__title`">
      <font-awesome-icon :icon="icon" />
      {{ title }}
      <AiBadge :icon="false"></AiBadge>
    </div>
    <font-awesome-icon
      :class="`${base}__close-icon`"
      v-show="closable"
      @click="$emit('close')"
      class="close"
      :icon="['fal', 'times']"
    />
  </div>
</template>

<script>
import AiBadge from '@/components/AI/AiBadge.vue';

export default {
  name: 'brain-task-toast-header',
  components: {
    AiBadge,
  },
  props: {
    icon: {
      type: Array,
      default: () => ['fal', 'bolt'],
    },
    title: {
      type: String,
      required: true,
    },
    closable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      base: 'brain-task-toast__header',
    };
  },
};
</script>

<style lang="scss" scoped>
.brain-task-toast__header {
  padding: 0px 8px 0px 8px;
  background: $grey-1-mayday;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  height: 40px;
  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
  }
  &__close-icon {
    height: 16px;
    cursor: pointer;
  }
}
</style>
