<template>
  <button
    v-if="hasOptions"
    class="thread__status-dropdown"
    :class="{ 'thread__status-dropdown-active': dropdown }"
    @click="toggleDropdown"
    v-click-outside="closeDropdown"
  >
    <font-awesome-icon :icon="['fal', 'ellipsis-h']" />
    <div
      class="thread__statuses"
      :class="{ 'thread__statuses-active': dropdown }"
    >
      <button
        v-for="status in updateStatuses"
        :key="status.value"
        @click="$emit('update-status', status.value)"
        class="thread__statuses-action"
      >
        <font-awesome-icon :icon="status.icon" />
        <span>{{ $t(status.label) }}</span>
      </button>
    </div>
  </button>
</template>

<script>
export default {
  name: 'thread-status-dropdown',
  props: {
    options: {
      type: Object,
      default: () => ({
        SOLVED: true,
        ARCHIVED: true,
      }),
    },
  },
  data() {
    return {
      updateStatuses: [
        ...(this.options.SOLVED
          ? [
              {
                value: 'SOLVED',
                icon: ['fal', 'check'],
                label: 'threads.update-statuses.SOLVED',
              },
            ]
          : []),
        ...(this.options.ARCHIVED
          ? [
              {
                value: 'ARCHIVED',
                icon: ['fal', 'archive'],
                label: 'threads.update-statuses.ARCHIVED',
              },
            ]
          : []),
      ],
      dropdown: false,
    };
  },
  computed: {
    hasOptions() {
      return Object.values(this.options).some(Boolean);
    },
  },
  methods: {
    // DROPDOWN
    toggleDropdown() {
      this.dropdown = !this.dropdown;
      this.$emit('dropdown', this.dropdown);
    },
    closeDropdown() {
      this.dropdown = false;
      this.$emit('dropdown', this.dropdown);
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  background: transparent;
  border: none;
  outline: none;
}

.thread__status-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 18px;
  width: 18px;
  font-size: 16px;
  background-color: transparent;
  transition: background-color 250ms ease-in-out;
  color: $grey-8-mayday;

  &:hover {
    background-color: $grey-1-mayday;
  }

  &-active {
    color: $blue-mayday;
  }
}

.thread__statuses {
  position: absolute;
  top: calc(100% + 8px);
  filter: drop-shadow(1px 1px 4px $shadow-4-mayday)
    drop-shadow(-3px 4px 8px $shadow-4-mayday);
  background-color: white;
  right: -8px;
  padding: 6px 0;
  border-radius: 2px;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transform: translateY(48px);
  transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;

  &-active {
    pointer-events: auto;
    opacity: 1;
    transform: translateY(0px);
  }

  &::before {
    content: ' ' !important;
    position: absolute !important;
    bottom: 100% !important; /* At the top of the tooltip */
    margin-left: -8px !important;
    border-width: 8px !important;
    border-style: solid !important;
    border-color: transparent transparent white transparent !important;
    box-shadow: 0px 15px 30px $shadow-4-mayday !important;
    right: 8px;
  }

  &-action {
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 4px;
    white-space: nowrap;
    padding: 4px 6px;
    background-color: transparent;
    transition: background-color 250ms ease-in-out;
    color: $blue-9-mayday;

    &:hover {
      background-color: rgba($blue-mayday, 0.1);
    }

    &:focus {
      color: white;
      background-color: $blue-mayday;
    }
  }
}
</style>
