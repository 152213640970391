<template>
  <div class="flex flex-row items-center justify-space-between p-2 gap-2">
    <el-select
      v-model="filter"
      popper-class="mt-0"
      @change="handleFilterChange"
      :placeholder="$t('knowledge.search-admin.quick-filters.choose')"
    >
      <el-option
        class="main-option-item"
        v-for="option in options"
        :key="option.key"
        :label="
          $t(
            `knowledge.search-admin.quick-filters.verification-policy.has.${option.key}`,
          )
        "
        :value="option.value"
      ></el-option>
    </el-select>
    <div class="clear-button" @click="resetFilter">
      {{ $t('knowledge.search-admin.quick-filters.clear') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'boolean-filter-picker',
  props: {
    hasVerificationPolicy: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      options: [
        {
          key: 'activated',
          value: true,
        },
        { key: 'deactivated', value: false },
      ],
      filter: null,
    };
  },

  created() {
    if (
      this.chosenHasVerificationPolicy &&
      this.chosenHasVerificationPolicy.key
    ) {
      this.filter = this.chosenHasVerificationPolicy.value;
    }
  },
  computed: {},
  methods: {
    handleFilterChange() {
      this.$emit('set-has-verification-policy', this.filter);
      this.$emit('quit-has-verification-policy');
    },
    resetFilter() {
      this.filter = null;
      this.$emit('set-has-verification-policy', this.filter);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .el-select {
  .el-input .el-input__inner {
    width: 110px;
    height: 24px;
    border: none;
    display: flex;
    align-items: center;
    padding-left: 4px;
    font-weight: 600;
    font-size: 12px;
  }
}

:deep() .el-input__suffix {
  display: flex;
  align-items: center;
}

.clear-button {
  color: $grey-4-mayday;
  cursor: pointer;
}

.main-option-item {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 24px;

  padding: 4px 0px 4px 4px;
}
</style>
