export function* aiClientStreamParser(chunkAsJson) {
  if (chunkAsJson.type === 'error') {
    throw new Error('STREAM_GENERATED_ERROR');
  }
  if (chunkAsJson.type === 'documents') {
    const linkedDocuments = parseLinkedDocuments(chunkAsJson.content);
    chunkAsJson.content = linkedDocuments;

    yield chunkAsJson;
  }
  if (
    (chunkAsJson.type === 'answer' || chunkAsJson.type === 'full-answer') &&
    chunkAsJson.content.text
  ) {
    for (let chunk of parseAnswerChunk(chunkAsJson)) {
      yield chunk;
    }
  }
  if (chunkAsJson.type === 'done') {
    for (let chunk of parseAnswerChunk(chunkAsJson)) {
      yield chunk;
    }
  }
  if (chunkAsJson.type === 'answer-type') {
    yield chunkAsJson;
  }
  if (chunkAsJson.type === 'meta') {
    yield chunkAsJson;
  }
}

const parseLinkedDocuments = (documents) => {
  const linkedDocuments = [];
  if (documents) {
    documents.forEach((linkedDocument) => {
      if (!linkedDocument) return;
      linkedDocuments.push({
        ...linkedDocument,
      });
    });
  }
  return linkedDocuments;
};

function* parseAnswerChunk(chunk) {
  for (let val of chunk.content.chunks) {
    if (!val.link) {
      yield { type: chunk.type, content: val.text };
    } else {
      if (val.quote) {
        yield {
          type: chunk.type,
          content: `[quote://${val.text}](${val.link})`,
          isLink: true,
          sourceId: val.link,
          linkId: val.text,
        };
      } else {
        yield {
          type: chunk.type,
          content: `[${val.text}](${val.link})`,
          isLink: true,
          sourceId: val.link,
          linkId: val.text,
        };
      }
    }
  }
}
