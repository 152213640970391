<template>
  <div>
    <p class="empty-category-info my-0">
      {{
        $t('settings.card-settings-options.content-parameters.empty-category')
      }}
    </p>

    <el-button class="mt-2" size="small" @click="$emit('new-label')">
      {{ $t('settings.card-settings-options.content-parameters.create-label') }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'empty-category',
};
</script>

<style scoped>
.empty-category-info {
  font-size: 14px;
}
</style>
