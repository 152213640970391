export const labelMapping = {
  favIconPath: {
    icon: ['fal', 'icons'],
    title: 'public-knowledge.deployment.popover.favicon-title',
  },
  companyIconUri: {
    icon: ['fal', 'copyright'],
    title: 'public-knowledge.deployment.popover.logo-title',
  },
  primary: {
    icon: ['fal', 'tint'],
    title: 'public-knowledge.deployment.popover.primary-title',
  },
  secondary: {
    icon: ['fal', 'tint'],
    title: 'public-knowledge.deployment.popover.secondary-title',
  },
  headerColor: {
    icon: ['fal', 'tint'],
    title: 'public-knowledge.deployment.popover.header-color-title',
  },
  bodyColor: {
    icon: ['fal', 'tint'],
    title: 'public-knowledge.deployment.popover.body-color-title',
  },
  customRadius: {
    icon: ['fal', 'rectangle-landscape'],
    title: 'public-knowledge.deployment.popover.radius-title',
  },
  font: {
    icon: ['fal', 'text'],
    title: 'public-knowledge.deployment.popover.font-title',
  },
  mainLayout: {
    icon: ['fal', 'pager'],
    title: 'public-knowledge.deployment.popover.main-layout-title',
  },
  headerLayout: {
    icon: ['fal', 'pager'],
    title: 'public-knowledge.deployment.popover.header-layout-title',
  },
  navigationLayout: {
    icon: ['fal', 'pager'],
    title: 'public-knowledge.deployment.popover.navigation-layout-title',
  },
  footerHtml: {
    icon: ['fal', 'code'],
    title: 'public-knowledge.deployment.popover.footer-code.html',
  },
  footerCss: {
    icon: ['fal', 'code'],
    title: 'public-knowledge.deployment.popover.footer-code.css',
  },
  footerVisibility: {
    icon: ['fal', 'code'],
    title: 'public-knowledge.deployment.popover.footer-code.visibility',
  },
  headerHtml: {
    icon: ['fal', 'code'],
    title: 'public-knowledge.deployment.popover.header-code.html',
  },
  headerCss: {
    icon: ['fal', 'code'],
    title: 'public-knowledge.deployment.popover.header-code.css',
  },
  headerVisibility: {
    icon: ['fal', 'code'],
    title: 'public-knowledge.deployment.popover.header-code.visibility',
  },
  customCss: {
    icon: ['fal', 'code'],
    title: 'public-knowledge.deployment.popover.custom-css.visibility',
  },
  customCssPath: {
    icon: ['fal', 'code'],
    title: 'public-knowledge.deployment.popover.custom-css.file',
  },
  customJs: {
    icon: ['fal', 'code'],
    title: 'public-knowledge.deployment.popover.custom-js.visibility',
  },
  customJsPath: {
    icon: ['fal', 'code'],
    title: 'public-knowledge.deployment.popover.custom-js.file',
  },
  gtmVisibility: {
    icon: ['fal', 'code'],
    title: 'public-knowledge.deployment.popover.gtm.visibility',
  },
  gtmId: {
    icon: ['fal', 'code'],
    title: 'public-knowledge.deployment.popover.gtm.id',
  },
  customHostname: {
    icon: ['fal', 'brackets-curly'],
    title: 'public-knowledge.deployment.popover.custom-domain-title',
  },
  displayStep: {
    icon: ['fal', 'search'],
    title: 'public-knowledge.deployment.popover.display-step-title',
  },
  displayKeyStep: {
    icon: ['fal', 'search'],
    title: 'public-knowledge.deployment.popover.display-key-step-title',
  },
  useFilters: {
    icon: ['fal', 'search'],
    title: 'public-knowledge.deployment.popover.use-labels-title',
  },
  displayContent: {
    icon: ['fal', 'search'],
    title: 'public-knowledge.deployment.popover.display-content-title',
  },
  metaTagGoogle: {
    icon: ['fal', 'globe'],
    title: 'public-knowledge.deployment.popover.meta-tag-google-title',
  },
  metaTagTitle: {
    icon: ['fal', 'globe'],
    title: 'public-knowledge.deployment.popover.meta-tag-title-title',
  },
  metaTagDescription: {
    icon: ['fal', 'globe'],
    title: 'public-knowledge.deployment.popover.meta-tag-description-title',
  },
  publicVisibility: {
    icon: ['fal', 'globe'],
    title: 'public-knowledge.deployment.popover.public-visibility-title',
  },
  generateSitemap: {
    icon: ['fal', 'globe'],
    title: 'public-knowledge.deployment.popover.generate-sitemap-title',
  },
  opengraph: {
    icon: ['fal', 'link'],
    title: 'public-knowledge.deployment.popover.opengraph-title',
  },
  opengraphImage: {
    icon: ['fal', 'image-polaroid'],
    title: 'public-knowledge.deployment.popover.opengraph-image-title',
  },
  relatedContents: {
    icon: ['fal', 'dumbbell'],
    title: 'public-knowledge.deployment.popover.related-contents-title',
  },
  pinnedContents: {
    icon: ['fal', 'dumbbell'],
    title: 'public-knowledge.deployment.popover.pinned-contents-title',
  },
  satisfaction: {
    icon: ['fal', 'dumbbell'],
    title: 'public-knowledge.deployment.popover.satisfaction-title',
  },
  images: {
    icon: ['fal', 'images'],
    title: 'public-knowledge.deployment.popover.images-title',
  },
  contactForm: {
    icon: ['fal', 'dumbbell'],
    title: 'public-knowledge.deployment.popover.contact-form-title',
  },
  contactFormHref: {
    icon: ['fal', 'dumbbell'],
    title: 'public-knowledge.deployment.popover.contact-form-title',
  },
  metaTagTranslationTitle: {
    icon: ['fal', 'globe'],
    title: 'public-knowledge.deployment.popover.meta-title-versioning-title',
  },
  metaTagTranslationDescription: {
    icon: ['fal', 'globe'],
    title:
      'public-knowledge.deployment.popover.meta-description-versioning-title',
  },
  newLanguage: {
    icon: ['fal', 'globe'],
    title: 'public-knowledge.deployment.popover.new-language',
  },
  askInSearch: {
    icon: ['fal', 'brain'],
    title: 'public-knowledge.deployment.popover.ask-in-search',
  },
};
