<template>
  <div>
    <modal-double :show.sync="localDisplay" :closeIcon="false">
      <modal-header :style-name="{ backgroundColor: '#0081F91A' }" class="mb-3">
        <div slot="icon" class="d-inline-flex">
          <font-awesome-icon
            class="icon blue-mayday"
            :icon="['fal', 'icons']"
          />
        </div>
        <div>
          <p class="mt-3 mb-0 font-weight-bold">
            {{ $t(title) }}
          </p>
        </div>
      </modal-header>
      <div class="mt-3 relative">
        <el-tabs
          v-model="focusedTab"
          @tab-click="handleClick"
          :class="{ 'one-tab-display': hasOneTab }"
        >
          <el-tab-pane name="fa" v-if="editionOptions.fa">
            <span slot="label"
              ><font-awesome-icon class="icon-tab" :icon="['fal', 'icons']" />
              {{ $t('knowledge.modals.edit-icon.fa-label') }}
            </span>
            <IconEditionModalFaTab
              :focusedIconValue="focusedIcon.value"
              @update="focusedIcon.value = $event"
            />
          </el-tab-pane>
          <el-tab-pane name="upload" v-if="editionOptions.upload">
            <span slot="label"
              ><font-awesome-icon
                class="icon-tab"
                :icon="['fal', 'cloud-upload']"
              />
              {{ $t('knowledge.modals.edit-icon.upload-label') }}
            </span>
            <DropzoneFileUpload
              :loading="loading"
              v-bind="{ aspectRatio: aspectRatio }"
              @update-attachments="updateAttachments"
              @validate-cropped-attachment="userChoice()"
            />
          </el-tab-pane>
          <el-tab-pane name="link" v-if="editionOptions.link">
            <span slot="label"
              ><font-awesome-icon class="icon-tab" :icon="['fal', 'link']" />
              {{ $t('knowledge.modals.edit-icon.url-label') }}
            </span>
            <IconEditionModalLinkTab
              ref="link"
              v-model="focusedIcon.value"
              @update="focusedIcon.value = $event"
            />
          </el-tab-pane>
        </el-tabs>
        <div
          :class="[
            'position-absolute top-0 right-0 tab-delete',
            { 'one-tab-display-delete': hasOneTab },
          ]"
          role="button"
          @click.prevent="deleteIcon()"
        >
          <font-awesome-icon class="icon-tab grey-5" :icon="['fal', 'trash']" />
          {{ $t('knowledge.modals.edit-icon.delete') }}
        </div>
      </div>
      <div v-if="focusedTab !== 'upload'" class="mt-4 row justify-content-end">
        <el-button
          :disabled="!(focusedIcon.value || attachments.length)"
          @click="userChoice()"
          size="mini"
          type="primary"
          class="mr-3"
          ><span v-if="!loading">{{
            $t('knowledge.modals.actions.validate')
          }}</span>
          <font-awesome-icon v-else :icon="['far', 'spinner']" spin />
        </el-button>
      </div>
      <div
        v-if="focusedTab == 'fa'"
        slot="sub-modal"
        class="mt-3 p-4 modal-content d-block"
      >
        <modal-header :style-name="headerStyle" class="mb-4">
          <div slot="icon" class="d-inline-flex">
            <font-awesome-icon
              class="icon"
              :style="iconColor"
              :icon="[iconStyle, focusedIcon.value]"
            />
          </div>
          <div>
            <p class="mt-3 mb-4 font-weight-bold">
              {{ $t('knowledge.modals.edit-icon.choose-color') }}
            </p>
            <ColorPicker
              class="color-wrapper justify-content-center"
              :focusedColor="focusedIcon.color"
              @focus-color="focusedIcon.color = $event"
            />
          </div>
        </modal-header>
      </div>
    </modal-double>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ModalDouble from '@/components/ModalDouble';
import IconEditionModalFaTab from '@/components/Modals/IconEditionModal/IconEditionModalFaTab';
import IconEditionModalLinkTab from '@/components/Modals/IconEditionModal/IconEditionModalLinkTab';
import ModalHeader from '@/components/Modals/ModalHeader';
import ColorPicker from '@/components/Modals/IconEditionModal/ColorPicker';
import DropzoneFileUpload from '@/components/DropzoneFileUpload';
import FileUploadMixin from '@/mixins/KnowledgeMixin/FileUploadMixin';

export default {
  name: 'icon-edition-modal',
  mixins: [FileUploadMixin],
  components: {
    ModalDouble,
    IconEditionModalFaTab,
    IconEditionModalLinkTab,
    ModalHeader,
    ColorPicker,
    DropzoneFileUpload,
  },
  props: {
    display: Boolean,
    aspectRatio: {
      type: Number,
      required: false,
    },
    currentIcon: {
      type: Object,
      default: () => {
        return {
          iconType: 'fa',
          value: '',
          color: '#DEE2E6',
        };
      },
    },
    editionOptions: {
      type: Object,
      default: () => {
        return {
          emoji: false,
          fa: true,
          upload: true,
          link: true,
        };
      },
    },
    title: {
      type: String,
      default: 'knowledge.modals.edit-icon.header-title',
    },
  },
  computed: {
    hasOneTab() {
      return (
        Object.values(this.editionOptions).filter((el) => el === true)
          .length === 1
      );
    },
    headerStyle() {
      return {
        backgroundColor: this.focusedIcon.color
          ? `${this.focusedIcon.color}0D`
          : '#F6F9FC',
      };
    },
    iconColor() {
      return {
        color: this.focusedIcon.color ? this.focusedIcon.color : '#CED4DA',
      };
    },
    iconStyle() {
      return this.focusedIcon.value === 'folder' ? 'fas' : 'fad';
    },
    ...mapGetters(['companyPrimaryColorPreference']),
  },
  data() {
    return {
      localDisplay: this.display,
      focusedIcon: { ...this.currentIcon },
      focusedTab: 'fa',
      attachments: [],
      loading: false,
    };
  },
  mounted() {
    if (!this.editionOptions.fa) this.focusedTab = 'upload';
  },
  methods: {
    userChoice() {
      if (this.focusedTab == 'fa')
        this.$emit('choice', { ...this.focusedIcon, iconType: 'fa' });
      else if (this.focusedTab == 'upload') this.uploadAttachment();
      else if (this.focusedTab == 'link' && this.$refs.link.urlValidation())
        this.$emit('choice', this.focusedIcon);
    },
    deleteIcon() {
      this.$emit('delete');
      this.$emit('close');
    },
    updateAttachments(attachments) {
      this.attachments = attachments;
    },
    async uploadAttachment() {
      if (this.attachments.length) {
        this.loading = true;
        await this.mxFileUpload([this.attachments[0]], (error, response) => {
          if (error) {
            const { message } = error;
            alert(message);
          } else {
            this.focusedIcon.value = response[0].url;
            this.$emit('choice', { ...this.focusedIcon, iconType: 'url' });
          }
          this.loading = false;
        });
      } else {
        this.$emit('close');
      }
    },
    handleClick() {
      if (this.focusedTab == 'fa') {
        this.focusedIcon = this.currentIcon;
      } else {
        this.focusedIcon = {
          iconType: 'url',
          value: '',
          color: null,
        };
      }
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  width: 21px;
  height: 21px;
}
.tab-delete {
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  color: $grey-5-mayday;
  cursor: pointer;
}
.blue-mayday {
  color: $blue-mayday;
}
.grey-5 {
  color: $grey-5-mayday;
}
.el-tabs__item span {
  color: $grey-5-mayday;
}
.el-tabs__item.is-active span {
  color: #409eff;
}
.color-wrapper {
  position: absolute;
  bottom: -38%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.one-tab-display {
  :deep() .el-tabs__header {
    display: none;
  }
}

.one-tab-display-delete {
  display: none;
}
</style>
