<template>
  <div class="cascader-container">
    <div
      v-if="isFirstLevel"
      class="d-flex align-items-center justify-content-between cascader-header is-init"
    >
      <div>{{ $t('components.base-cascader-multi.select-cases') }}</div>
      <font-awesome-icon
        class="icon"
        :icon="['fas', 'times']"
        @click.prevent="$emit('close')"
      />
    </div>
    <div v-else class="d-flex align-items-center cascader-header">
      <font-awesome-icon
        class="icon"
        :icon="['fal', 'arrow-left']"
        @click.prevent="prev()"
      />
      <div class="w-100 d-flex align-items-center justify-content-between">
        <div class="pl-2">
          {{ label }}
        </div>
        <font-awesome-icon
          class="icon grey-6"
          :icon="['fas', 'times']"
          @click.prevent="$emit('close')"
        />
      </div>
    </div>
    <div class="overflow" v-loading="options.length === 0">
      <div v-if="hasChildren">
        <div
          class="d-flex align-items-center option"
          :class="{
            'is-focus': isFocusOption(option.id),
            'is-disabled ': disableSelect,
          }"
          v-for="option in options"
          :key="option.id"
          @mouseenter="hoveredOptionId = option.id"
          @mouseleave="hoveredOptionId = null"
          @click.prevent="focusOption(option)"
        >
          <font-awesome-icon
            :icon="getOptionIcon(option.caseId)"
            :class="
              !disableSelect && isFocusOption(option.id) ? 'blue' : 'grey-5'
            "
          />
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div class="pl-2">{{ option.label }}</div>
            <div
              class="option-children-selector"
              @click.prevent.stop="next(option)"
            >
              <font-awesome-icon
                v-if="hoveredOptionId == option.id"
                class="icon blue"
                :icon="['fas', 'chevron-right']"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="instruction" v-else>
        {{ $t('knowledge.modals.clone-kb.cascader-instruction') }}
      </div>
    </div>
    <div class="d-flex align-items-center cascader-footer">
      <el-popover
        v-if="focusedOptions.length"
        placement="bottom"
        trigger="click"
        width="350"
        :append-to-body="false"
      >
        <div
          class="m-1 d-flex flex-wrap align-items-center option-path"
          v-for="option in focusedOptions"
          :key="option.id"
        >
          <font-awesome-icon
            class="option-path__icon"
            :icon="['fad', 'home']"
          />
          <span class="mx-1">></span>
          <div v-for="ancestor in option.ancestors" :key="ancestor">
            {{ ancestor }}
            <span class="mx-1">></span>
          </div>
          <div>{{ option.label }}</div>
        </div>
        <div
          slot="reference"
          class="d-flex align-items-center show-focus-collections"
        >
          {{ focusedOptions.length }}
          {{ focusedOptions.length > 1 ? 'sélectionnés' : 'sélectionné' }}
          <font-awesome-icon class="ml-2" :icon="['fad', 'eye']" />
        </div>
      </el-popover>
      <ModalActions
        class="ml-auto"
        :primaryLabel="'generic.confirm'"
        :primaryEnabled="cloneValidation"
        @primary="$emit('select-target', focusedOptions)"
      />
    </div>
  </div>
</template>

<script>
import ModalActions from '@/components/Modals/ModalActions';
export default {
  name: 'base-cascader-multi',
  components: {
    ModalActions,
  },
  props: {
    label: {
      type: String,
      default: 'Cascader',
    },
    disableSelect: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Array,
      default: () => {
        return [
          // {
          //   isFirst: true, //Boolean
          //   id: '1', //String
          //   caseId: null, //String
          //   knowledge: 'knowledge1', //String
          //   label: '', //String
          //   hasChildren: true, //Boolean
          // },
        ];
      },
    },
  },
  data() {
    return {
      showPopover: false,
      hoveredOptionId: null,
      focusedOptions: [],
      hasChildren: true,
    };
  },
  computed: {
    cloneValidation() {
      return this.focusedOptions.length > 0;
    },
    isFirstLevel() {
      return this.options.length > 0 ? this.options[0].isFirst : true;
    },
  },
  methods: {
    getOptionPath(option) {
      return (
        (option.ancestors.length ? ' > ' : '') +
        option.ancestors.join(' > ') +
        ` > ${option.label}`
      );
    },
    isFocusOption(optionId) {
      return this.focusedOptions.findIndex(({ id }) => id === optionId) > -1;
    },
    focusOption(option) {
      if (this.disableSelect) return;
      const pos = this.focusedOptions.findIndex(({ id }) => id === option.id);
      if (pos === -1) this.focusedOptions.push(option);
      else this.focusedOptions.splice(pos, 1);
    },
    getOptionIcon(caseId) {
      return caseId ? ['fas', 'folder'] : ['fad', 'books'];
    },
    prev() {
      if (!this.hasChildren) {
        this.hasChildren = true;
      } else {
        this.$emit('prev');
      }
    },
    next(option) {
      if (!option.hasChildren) {
        this.hasChildren = false;
      }
      this.$emit('next', option);
    },
  },
};
</script>

<style lang="scss" scoped>
.cascader-container {
  width: 100%;
  background: white;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  color: $grey-8-mayday;
  font-weight: bold;
}
.cascader-header {
  padding: 10px 8px;
  background-color: $grey-1-mayday;

  &.is-init {
    color: $grey-6-mayday;
  }
}
.option {
  padding: 12px 10px;
  margin: 1px 0px;
  cursor: pointer;

  &.is-focus {
    color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.4);
  }
}
.option:not(.is-disabled):hover {
  color: $blue-mayday !important;
  background-color: $grey-1-mayday;
}
.icon {
  width: 16px;
  height: 16px;
  cursor: pointer;

  &__selector {
    border: 1px solid $blue-mayday;
    border-radius: 1px;
  }
}
.grey-6 {
  color: $grey-6-mayday;
}
.grey-5 {
  color: $grey-5-mayday;
}
.blue {
  color: $blue-mayday;
}
.red {
  color: $red-mayday;
}
.cascader-footer {
  padding: 12px;
}
.instruction {
  padding: 12px 14px;
  font-weight: bold;
}
.overflow {
  height: 15vh;
  max-height: 150px !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
.is-disabled {
  cursor: not-allowed;
}
.show-focus-collections {
  font-size: 14px;
  font-weight: 500;
  color: $grey-6-mayday;
  line-height: 1.2;
  cursor: pointer;
}
.option-path {
  font-size: 12px;
  font-weight: 500;
  color: $grey-6-mayday;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &__icon {
    font-size: 14px;
  }
}
</style>
