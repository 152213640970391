<template>
  <div class="case-viewer container" :key="focusCaseArchive.id">
    <BaseBreadcrumb
      v-if="focusCaseArchiveBreadcrumb.length"
      class="mt-4"
      :ancestors="focusCaseArchiveBreadcrumb"
      route-type="Case"
      @click:home="appearParent({ idx: -1 })"
      @click:ancestor="appearParent"
    />
    <div
      class="d-flex flex-row align-items-center pt-2 navigator"
      @click="backToArchive"
      v-else
    >
      <font-awesome-icon
        :icon="['fad', 'arrow-to-left']"
        class="navigator-icon"
      />
      <p class="navigator-text pl-2 mb-0">
        {{ $t('knowledge.archives.back-to-archive') }}
      </p>
    </div>

    <div
      class="row justify-content-center mt-4"
      v-loading="caseArchiveViewerIsLoading"
      v-if="!showNestedContent"
    >
      <main class="col-md-8 col-sm-12 mt-2 pb-8">
        <CaseViewerHeader
          :case-parameter="caseParameter"
          :typed-counts="typedCounts"
          :editable="false"
          is-archive
        />
        <div
          class="list-item ml-3"
          v-for="item in children"
          :key="`${item.id}-${item.type}`"
        >
          <CollectionContentRowItem
            :node="item"
            :editable="false"
            @click="focusItem(item)"
            class="ml-3"
          />
        </div>
      </main>
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseBreadcrumb from '@/components/BaseBreadcrumb';
import CaseViewerHeader from '@/views/KnowledgeEditor/KnowledgeEditorMain/CaseViewer/CaseViewerHeader';
import CollectionContentRowItem from '@/views/KnowledgeEditor/KnowledgeEditorMain/FolderBuilder/CollectionContentRowItem';

export default {
  name: 'case-archive-viewer',
  components: {
    BaseBreadcrumb,
    CaseViewerHeader,
    CollectionContentRowItem,
  },
  data() {
    return {
      preparedCaseFilters: [],
      relatedContentsCount: {},
      onDelete: false,
      path: [],
      caseToDeleteId: '',
    };
  },
  async created() {
    this.getArchive();
  },
  computed: {
    caseParameter() {
      return {
        ...this.focusCaseArchive.case,
        children: this.children.filter((el) => el.__typename === 'Case'),
      };
    },
    children() {
      return this.focusCaseArchiveChildren.map((c) => ({
        ...c.content,
      }));
    },
    typedCounts() {
      return this.children
        ? this.children.reduce(
            (acc, val) => {
              const type = val.type;
              if (type) acc[type] += 1;
              return acc;
            },
            { Article: 0, Diagnostic: 0 },
          )
        : { Article: 0, Diagnostic: 0 };
    },
    showNestedContent() {
      return !!this.$route.params.archiveId;
    },
    ...mapGetters('knowledgeModule', [
      'mainCaseArchiveId',
      'caseArchiveViewerIsLoading',
      'focusCaseArchive',
      'focusCaseArchiveChildren',
      'focusCaseArchiveAncestors',
      'focusCaseArchiveBreadcrumb',
      'editingLanguage',
      'focusKnowledgeId',
    ]),
  },
  watch: {
    editingLanguage() {
      this.getArchive();
    },
  },
  methods: {
    async getArchive() {
      const { caseArchiveId } = this.$route.params;
      const { target } = this.$route.query;
      await this.loadFocusCaseArchiveById({
        caseArchiveId,
        isMain: true,
        target,
      });
    },
    backToArchive() {
      window.location.href = `/archives/${this.editingLanguage}/${this.focusKnowledgeId}`;
    },
    async focusItem(item) {
      const { id, __typename } = item;
      const formerFocus = { ...this.focusCaseArchive };
      const archive = this.findArchiveByEntityId(id);
      if (!archive) return;

      if (__typename === 'Case') {
        await this.loadFocusCaseArchiveById({ caseArchiveId: archive.id });
        this.$nextTick(() => {
          this.setFocusArchiveAncestors(
            this.focusCaseArchiveAncestors.concat([formerFocus]),
          );
        });
      } else {
        this.$router.push({
          name: 'knowledge-case-archive-content',
          params: { archiveId: archive.id },
        });
      }
    },
    findArchiveByEntityId(entityId) {
      try {
        return this.focusCaseArchiveChildren.find(
          (archive) => archive.content.id === entityId,
        );
      } catch (_e) {
        return null;
      }
    },
    appearParent({ idx }) {
      if (idx === -1) {
        this.resetFocusCaseArchiveAncestors();
      } else {
        this.setFocusCaseArchiveFromAncestors(idx + 1);
      }
      this.$router.push({
        name: 'knowledge-case-archive',
        params: { caseArchiveId: this.mainCaseArchiveId },
      });
    },
    ...mapActions('knowledgeModule', [
      'loadFocusCaseArchiveById',
      'resetFocusCaseArchiveAncestors',
      'setFocusCaseArchiveFromAncestors',
      'setFocusArchiveAncestors',
      'loadFocusCaseArchiveById',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.navigator {
  cursor: pointer;
  color: $grey-5-mayday;
  margin-left: 17%;
  &:hover {
    color: $blue-mayday;
  }
}
</style>
