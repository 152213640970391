<template>
  <div class="ask-answer-stream" ref="loadingContainer">
    <AskMarkdownRenderer
      :markdown="answerStream"
      :hovered="hoveredLink"
      @md-event="handleMarkdownEvent"
    ></AskMarkdownRenderer>
  </div>
</template>

<script>
import AskMarkdownRenderer from 'components/Ask/AskMarkdownRenderer.vue';

export default {
  name: 'ask-answer-stream',
  data: () => ({
    textAnimation: false,
  }),
  components: { AskMarkdownRenderer },
  props: {
    answerStream: {
      type: String,
      default: '',
    },
    hoveredLink: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleMarkdownEvent(event) {
      if (event.type == 'leave') {
        this.$emit('update-hover-link', null);
      } else if (event.type == 'hover') {
        this.$emit('update-hover-link', event.id);
      } else if (event.type == 'go-to-document') {
        this.$emit('open-sidebar', { entityId: event.id });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-answer-stream {
  text-align: justify;
  padding-right: 22px;
  color: $grey-8-mayday;
  font-size: 14px;
  max-height: fit-content;
  overflow: scroll;
}
</style>
