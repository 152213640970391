<template>
  <div class="d-flex align-items-center">
    <div
      v-if="!displayAll && labelsToDisplay.length"
      class="d-flex flex-wrap align-items-center first-display"
    >
      <ContentListItemFullLabel
        v-for="label in labelsToDisplay"
        :key="label ? label.id : ''"
        :label="label"
      />
      <div v-if="orderedLabels.length > 3" class="plus-display px-1">
        +{{ orderedLabels.length - 3 }}
      </div>
      <el-button
        v-if="orderedLabels.length > 3"
        type="text"
        @click.stop="displayAll = true"
        size="mini"
        class="py-0"
        >{{ $t('generic.show-more') }}</el-button
      >
    </div>

    <div v-else class="d-flex flex-wrap align-items-center entire-label-list">
      <ContentListItemFullLabel
        v-for="label in orderedLabels"
        :key="label.id"
        :label="label"
      />
      <el-button
        v-if="orderedLabels.length > 3"
        type="text"
        size="mini"
        class="py-0"
        @click.stop="displayAll = false"
        >{{ $t('generic.show-less') }}</el-button
      >
    </div>
  </div>
</template>

<script>
import ContentListItemFullLabel from '@/components/ListItems/ContentListItemFullLabel';

export default {
  name: 'content-list-item-full-label',
  components: { ContentListItemFullLabel },
  props: {
    labels: { type: Array, default: () => [] },
    labelSearchedIds: { type: Array, default: () => [] },
  },
  data() {
    return {
      displayAll: false,
    };
  },
  computed: {
    orderedLabels() {
      if (!this.labels) return null;
      return this.labelSearchedIds.reduce((acc, id) => {
        const foundIdx = acc.findIndex((label) => label.id === id);
        if (foundIdx === -1) return acc;
        const label = acc[foundIdx];
        acc.splice(foundIdx, 1);
        acc.unshift(label);
        return acc;
      }, this.labels);
    },
    labelsToDisplay() {
      if (this.labels.length > 3) return this.labels.slice(0, 3);
      return this.labels;
    },
  },
};
</script>

<style lang="scss" scoped>
.plus-display {
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
  border-radius: 4px;
  height: 18px;
  font-size: 10px;
  display: flex;
  align-items: center;
}
.entire-label-list {
  width: 100%;
  gap: 4px;
}
.first-display {
  gap: 4px;
  width: 100%;
}
</style>
