<template>
  <base-dropdown
    :class="['nav-item', `${base}__dropdown`]"
    position="left"
    direction="down"
  >
    <div :class="[`${base}`, 'text-muted']" slot="title" data-toggle="dropdown">
      <font-awesome-icon
        :class="`${base}__icon`"
        :icon="['far', 'long-arrow-left']"
      />
      <p class="ml-1 my-0">{{ backlinkText }}</p>
    </div>
    <div :class="['dropdown-item', `${base}__dropdown__title`]">
      <small class="text-muted">{{
        $t('knowledge.actions.backlinks.desc')
      }}</small>
    </div>
    <ContentListItem
      class="dropdown-item cursor"
      v-for="content in backlinks"
      :content="content"
      :itemType="content.type"
      :is-read-only="true"
      :key="content.id"
      :full-width="true"
      @click.native.exact.stop="handleOnClick(content)"
      @click.native.meta.exact="handleOnClick(content, true)"
      @click.native.ctrl.exact="handleOnClick(content, true)"
    />
  </base-dropdown>
</template>

<script>
import ContentListItem from '@/components/SuperSearch/Results/ContentListItem';
const base = 'backlink-button';
export default {
  name: 'backlink-button',
  components: {
    ContentListItem,
  },
  props: {
    backlinks: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    count() {
      return this.backlinks ? this.backlinks.length : 0;
    },
    backlinkText() {
      return this.$tc(
        'knowledge.actions.backlinks.trigger',
        this.count > 1 ? 0 : 1,
        {
          count: this.count,
        },
      );
    },
  },
  data() {
    return {
      base,
    };
  },
  methods: {
    handleOnClick(content,newTab) {
      const { type, id, ancestors } = content;

      const path = '/knowledge/content/';
      let subPath = id;
      if (['Step', 'keyStep'].includes(type)) {
        subPath = `${ancestors[0].split('/')[0]}/step/${id}`;
      }

      if(newTab){
        window.open(path + subPath + '?from=backlink', '_blank');
        return;
      }
      this.$router.push({
        path: path + subPath,
        query: {
          from: 'backlink',
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.backlink-button {
  display: flex;
  align-items: center;
  padding: 4px 6px;
  &:hover {
    cursor: pointer;
    background-color: $grey-2-mayday;
    border-radius: 4px;
  }
  &__icon {
    font-size: 14px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &__dropdown {
    &__title {
      padding: 2px 8px;
      top: 0px;
      position: sticky;
      border-bottom: 1px solid $grey-4-mayday;
      background-color: white;
      z-index: 1;
    }
    :deep() #dropdown {
      padding: 0 0;
      left: 0px;
      right: auto;
      max-width: 350px;
      max-height: 280px;
      overflow: scroll;
      // width: fit-content;
    }
    :deep() .dropdown-divider {
      margin: 0px;
    }
    :deep() .content__list__item-row {
      padding-top: 8px;
    }

    // :deep() .content__result__link {
    //   width: 50%;
    // }
  }
}
</style>
