<template>
  <div>
    <div
      v-loading="isLoading"
      class="analytics-contents-search-stats__container"
    >
      <SearchStatCard
        :main-stat="contentSearchResults.countSuccess"
        :main-title="'analytics-knowledge-performance.nb-search-in-mayday.title'"
        :stat-trend="contentSearchResults.successTrend"
        :has-tooltip="true"
        :tooltip-content="'analytics-knowledge-performance.nb-search-in-mayday.helper'"
        :data="contentSearchResults.success"
        :icon-source="'check-circle'"
        :external-doc-url="externalDocUrl"
        :external-doc-anchor="externalDocAnchor"
        :options="searchFilterOptions"
        :exportSearchLoading="exportSearchLoading.success"
        :merge-terms="mergeTerms"
        class="col-5"
        @smart-fusion="$emit('update:mergeTerms', $event)"
        @export-search-stats="handleExportSearch(false)"
      />

      <SearchStatCard
        :main-stat="contentSearchResults.countFail"
        :main-title="'analytics-knowledge-performance.nb-failed-search-in-mayday.title'"
        :stat-trend="contentSearchResults.failTrend"
        :has-tooltip="true"
        :tooltip-content="'analytics-knowledge-performance.nb-failed-search-in-mayday.helper'"
        :data="contentSearchResults.fail"
        :icon-source="'times-hexagon'"
        :external-doc-url="externalDocUrl"
        :external-doc-anchor="externalDocAnchor"
        :options="searchFilterOptions"
        :exportSearchLoading="exportSearchLoading.fail"
        :merge-terms="mergeTerms"
        class="col-5"
        @smart-fusion="$emit('update:mergeTerms', $event)"
        @export-search-stats="handleExportSearch(true)"
      />
    </div>
    <div class="bottom-spacer"></div>
  </div>
</template>

<script>
import SearchStatCard from '@/components/SearchStatCard';
import { EXTERNAL_DOC_ANCHORS } from '@/store/dashboard/constants';

export default {
  name: 'analytics-contents-search-stats',
  components: {
    SearchStatCard,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    contentSearchResults: {
      type: Object,
      default: () => ({}),
    },
    externalDocUrl: {
      type: String,
      required: true,
    },
    exportSearchLoading: {
      type: Object,
      default: () => {
        return { success: false, fail: false };
      },
    },
    mergeTerms: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      externalDocAnchor: EXTERNAL_DOC_ANCHORS.contentSearchStats,
      searchFilterOptions: { mergeTerms: true },
    };
  },
  methods: {
    handleExportSearch(isMissed) {
      this.$emit('export-search-stats', { isMissed });
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-contents-search-stats__container {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
  height: 100%;
}
.bottom-spacer {
  height: 32px;
}
</style>
