<template>
  <div class="search-stat-card">
    <div class="row justify-content-between">
      <h1 class="search-stat-card__title h3">
        {{ $t(mainTitle) }}
        <el-tooltip
          v-if="hasTooltip"
          :content="$t(tooltipContent)"
          placement="right"
          class="group-tooltip"
        >
          <font-awesome-icon
            :icon="['fad', 'info-circle']"
            class="search-stat-card__info-icon text-grey mx-1"
          />
        </el-tooltip>
      </h1>

      <div
        :class="[
          isDisplayed
            ? 'search-stat-card__button-wrapper--active'
            : 'search-stat-card__button-wrapper',
        ]"
      >
        <font-awesome-icon
          :icon="['fad', 'th-list']"
          @click="displaySearch"
          :class="[
            data.length ? 'search-stat-card__display-table-button' : '',
            isDisplayed ? 'search-stat-card__display-table-button--active' : '',
            'text-grey',
            'mx-1',
          ]"
        />
      </div>
    </div>
    <div class="row justify-content-between align-items-center">
      <div>
        <p
          class="mt-2"
          :class="mainStat === 0 ? 'search-stat-card__main-empty' : ''"
        >
          <template v-if="mainStat === 0"
            ><i class="empty-state-icon"></i>
            <div>
              <span class="text-default search-stat-card__main-stat__text">
                {{ $t('new-dashboard.empty-state.label') }}
              </span>
              <p class="empty-state-text">
                {{ $t('new-dashboard.empty-state.text-before-cta') }}
                <a target="_blank" :href="externalDocLink">{{
                  $t('new-dashboard.empty-state.cta-text')
                }}</a>
                {{ $t('new-dashboard.empty-state.text-after-cta') }}
              </p>
            </div></template
          >
          <span
            v-else
            class="display-2 text-default search-stat-card__main-stat__text"
          >
            {{ mainStat }}
          </span>
          <span class="search-stat-card__trend-icon-wrapper">
            <font-awesome-icon
              v-if="statTrend === 'up'"
              :icon="['fad', 'arrow-up']"
              class="text-grey mx-1 search-stat-card__trend-icon--success"
            />
            <font-awesome-icon
              v-else-if="statTrend === 'down'"
              :icon="['fad', 'arrow-down']"
              class="text-grey mx-1 search-stat-card__trend-icon--fail"
            />
            <font-awesome-icon
              v-else-if="mainStat !== 0"
              :icon="['fad', 'equals']"
              class="text-grey mx-1 search-stat-card__trend-icon"
            />
          </span>
        </p>
        <button
          v-if="data.length"
          @click="displaySearch"
          class="search-stat-card__link"
        >
          <span v-if="!isDisplayed">
            {{
              $t(
                'analytics-knowledge-performance.search-results.display-search-details',
              )
            }}
          </span>
          <span v-else>
            {{
              $t(
                'analytics-knowledge-performance.search-results.hide-search-details',
              )
            }}
          </span>
        </button>
      </div>
      <font-awesome-icon
        :icon="['fad', iconSource]"
        class="search-stat-card__icon text-grey"
      />
    </div>

    <div
      class="search-stat-card__smart-fusion d-flex justify-content-between"
      v-if="isDisplayed"
    >
      <div class="d-flex align-items-center">
        {{ $t('analytics-knowledge-performance.search-results.smart-fusion') }}
        <el-tooltip
          :content="
            $t(
              'analytics-knowledge-performance.search-results.smart-fusion-tooltip',
            )
          "
          placement="top"
        >
          <font-awesome-icon
            :icon="['fad', 'info-circle']"
            class="search-stat-card__info-tooltip"
          />
        </el-tooltip>
      </div>
      <el-switch
        :value="mergeTerms"
        @change="$emit('smart-fusion', $event)"
      ></el-switch>
    </div>

    <SearchTable
      v-if="isDisplayed"
      ref="searchTable"
      class="row mb-3"
      :data="data"
      :exportSearchLoading="exportSearchLoading"
      @export-search-stats="$emit('export-search-stats')"
    />
  </div>
</template>

<script>
import SearchTable from '@/views/Analytics/AnalyticCards/SearchTable';

export default {
  name: 'search-stat-card',
  components: {
    SearchTable,
  },
  props: {
    mainStat: Number,
    mainTitle: String,
    statTrend: String,
    hasTooltip: {
      type: Boolean,
      required: true,
    },
    data: Array,
    tooltipContent: String,
    iconSource: String,
    externalDocUrl: {
      type: String,
      default: () => '',
    },
    externalDocAnchor: {
      type: String,
      default: () => '',
    },
    exportSearchLoading: {
      type: Boolean,
      default: false,
    },
    mergeTerms: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isDisplayed: false,
    };
  },
  methods: {
    async displaySearch() {
      if (this.data.length) {
        this.isDisplayed = !this.isDisplayed;
        if (this.isDisplayed) {
          await this.$nextTick();
          window.scroll({
            top: window.pageYOffset + this.$refs.searchTable.$el.offsetHeight,
            behavior: 'smooth',
          });
        }
      }
    },
  },
  computed: {
    externalDocLink() {
      const { externalDocAnchor } = this;
      return externalDocAnchor != null && externalDocAnchor.length
        ? `${this.externalDocUrl}${externalDocAnchor}`
        : undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-stat-card__main-stat {
  margin: 0;

  .search-stat-card__main-stat__text {
    line-height: 1;
  }
}

.search-stat-card__main-empty {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  .empty-state-icon {
    width: 40px;
    height: 40px;
    background-image: url('~@/assets/empty-state-search.svg');
    background-size: cover;
    background-position: center;
    display: inline-block;
  }

  .empty-state-text {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.02em;
    color: $grey-6-mayday;
  }
}

.search-stat-card__info-icon {
  width: 16px;
  &:hover {
    color: $blue-mayday;
  }
}

.search-stat-card__trend-icon--success {
  color: $green-mayday;
  transform: rotate(45deg);
}
.search-stat-card__trend-icon--fail {
  color: $red-mayday;
  transform: rotate(-45deg);
}
.search-stat-card__trend-icon {
  color: $blue-mayday;
}
.search-stat-card__trend-icon-wrapper {
  margin: auto;
}

.search-stat-card__link {
  background: transparent;
  border: none;
  text-decoration: underline;
  color: $blue-mayday;
  font-size: 0.8em;
  padding: 0px;
}

.search-stat-card__icon {
  font-size: 2em;
}

.search-stat-card__button-wrapper--active {
  border-bottom: 4px solid $blue-mayday;
}

.search-stat-card__display-table-button {
  font-size: 1.3em;
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}

.search-stat-card__display-table-button--active {
  font-size: 1.3em;
  cursor: pointer;
  color: $blue-mayday;
}

.search-stat-card__smart-fusion {
  margin-top: 8px;
  margin-left: -16px;
}

.search-stat-card__info-tooltip {
  color: $grey-5-mayday;
  font-size: 12px;
  margin-left: 8px;
}
</style>
