<template>
  <div>
    <div class="d-flex flex-column justify-content-left">
      <!-- KNOWLEDGE NAME -->
      <div class="category">
        <div class="title">
          {{
            $t('settings.new-knowledge.choose-type.private.form.label-title')
          }}
        </div>
        <div class="description">
          {{
            $t(
              'settings.new-knowledge.choose-type.private.form.label-description',
            )
          }}
        </div>
        <el-input
          :placeholder="
            $t(
              'settings.new-knowledge.choose-type.private.form.label-placeholder',
            )
          "
          v-model="models.label"
        >
        </el-input>
      </div>

      <!-- KNOWLEDGE LANGUAGE -->
      <div class="category mt-3">
        <div class="title">
          {{ $t('settings.new-knowledge.choose-type.private.form.lang-title') }}
        </div>
        <div class="description">
          {{
            $t(
              'settings.new-knowledge.choose-type.private.form.lang-description',
            )
          }}
        </div>
        <el-select v-model="models.lang" class="select">
          <div slot="prefix" class="flag-wrapper" v-if="models.lang">
            <LanguageFlag class="d-inline-flex" :language="models.lang" />
          </div>
          <el-option
            v-for="lang in langOptions"
            :key="lang"
            :value="lang"
            :label="$t(`knowledge.languages.${lang}`)"
          >
          </el-option>
        </el-select>
      </div>

      <!-- KNOWLEDGE ACCESS RESTRICTIONS -->
      <div class="category mt-3">
        <div class="title">
          {{ $t('settings.new-knowledge.choose-type.groups-access.title') }}
        </div>
        <div class="description">
          {{
            $t('settings.new-knowledge.choose-type.groups-access.description')
          }}
        </div>
        <ContentViewSettingsCallout
          v-if="!models.groups.length"
          trad="settings.new-knowledge.choose-type.groups-access"
          icon="exclamation-triangle"
          type="warning"
        />
        <el-select
          class="select"
          v-model="models.groups"
          multiple
          collapse-tags
        >
          <el-option
            v-for="group in groupOptions"
            :key="group.id"
            :value="group.id"
            :label="group.name"
          >
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageFlag from '@/components/LanguageFlag.vue';
import ContentViewSettingsCallout from '@/components/Drawers/ContentViewSettingDrawer/ContentViewSettingsComponents/ContentViewSettingsCallout.vue';
import { langOptions } from '@/utils/langOptions.js';

export default {
  name: 'new-knowledge-private',
  components: {
    LanguageFlag,
    ContentViewSettingsCallout,
  },
  props: {
    groupOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      models: {
        label: '',
        lang: 'fr-fr',
        groups: [],
      },
      langOptions: langOptions,
    };
  },
  watch: {
    models: {
      handler(newVal) {
        this.$emit('new-kb-models', newVal);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  .title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 6px;
    max-width: 390px;
  }
  .description {
    font-size: 16px;
    color: $grey-8-mayday;
    margin-bottom: 18px;
    max-width: 350px;
  }

  .select {
    width: 60%;
  }

  .el-select {
    height: 32px;
  }
  :deep() .el-input {
    height: 100%;
  }
  :deep() .el-input__inner {
    height: 100%;
  }
  :deep() .el-input__suffix {
    display: flex;
    align-items: center;
  }
  :deep() .el-input__prefix {
    display: flex;
    align-items: center;
  }

  :deep() .el-select__tags {
    max-width: 100% !important;
  }
  .flag-wrapper {
    width: 23px;
    height: 21px;
    background-color: $grey-4-mayday;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
  }
  .el-input {
    height: 32px;
    width: 350px;
  }
  .placeholder-value {
    font-size: 12px;
    color: $grey-6-mayday;
  }
}
</style>
