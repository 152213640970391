<template>
  <div class="content-version-modal">
    <modal
      :show.sync="localDisplay"
      modal-classes="modal-dialog-centered modal-dialog-scrollable modal-xl"
    >
      <div class="row d-flex">
        <content-version-history
          class="col-4 version-left-side"
          :content-versions="contentVersions"
          :total="total"
          :focused-version-idx="versionIdx"
          @choose="versionIdx = $event"
          @show-more="showMore"
        >
          <TranslationDropdown
            v-if="focusKnowledgeIsMultilinguale"
            class="mt-2 mb-3 w-100"
            :languages="focusKnowledgeLanguages"
            :currentLanguage="lang"
            @change-language="lang = $event"
          />
        </content-version-history>
        <ContentVersionDetail
          class="col-8"
          v-if="contentVersions.length > 0"
          :focus-version="focusVersion"
        />
        <div
          v-else-if="isLoading"
          class="col-8 loader-state"
          v-loading="isLoading"
        ></div>
        <div v-else class="text-center my-auto mx-auto" v-loading="isLoading">
          <img
            src="~assets/empty-states/empty_animation.gif"
            class="w-100 my-2"
          />
          <h2>{{ $t('components.modals.content-version.empty-state') }}</h2>
        </div>
      </div>
      <template slot="footer">
        <div class="d-flex justify-content-end">
          <el-button size="mini" @click="$emit('close')">{{
            $t('components.modals.content-version.cancel')
          }}</el-button>
          <not-allowed-tooltip
            :user-permissions="userPermissions"
            permission-key="canUpdateContent"
          >
            <template v-slot:main-content="{ hasPermissions }">
              <el-button
                class="ml-2"
                type="primary"
                :disabled="!hasPermissions"
                @click.prevent="$emit('restore-version', focusVersion)"
                v-if="contentVersions.length > 0"
                size="mini"
              >
                {{ $t('components.modals.content-version.change') }}
              </el-button>
            </template>
          </not-allowed-tooltip>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
// COMPONENTS
import { mapGetters } from 'vuex';
import moment from 'moment';
import ContentVersionDetail from './ContentVersionDetail';
import ContentVersionHistory from './ContentVersionHistory';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';
import TranslationDropdown from '@/components/TranslationDropdown';

export default {
  name: 'content-version-modal',
  components: {
    ContentVersionDetail,
    ContentVersionHistory,
    NotAllowedTooltip,
    TranslationDropdown,
  },
  props: {
    contentId: String,
    display: Boolean,
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    focusVersion() {
      return this.contentVersions[this.versionIdx];
    },
    ...mapGetters('knowledgeModule', [
      'focusKnowledgeLanguages',
      'focusKnowledgeIsMultilinguale',
      'editingLanguage',
    ]),
  },
  data() {
    return {
      versionIdx: 0,
      contentVersions: [],
      total: 0,
      page: 1,
      isLoading: false,
      localDisplay: this.display,
      lang: '',
    };
  },
  watch: {
    lang() {
      this.isLoading = true;
      this.page = 1;
      this.versionIdx = 0;
      this.contentVersions = [];
      this.fetchVersions();
    },
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
  created() {
    this.lang = this.editingLanguage;
  },
  methods: {
    showMore() {
      this.page++;
      this.fetchVersions();
    },
    async fetchVersions() {
      this.isLoading = true;
      const { results, total, page } =
        await this.$services.contentVersions.getContentVersions(
          this.contentId,
          5,
          this.page,
          this.lang,
        );
      results.forEach(
        (contentVersion) =>
          (contentVersion.formattedDate = this.convertTimestampToDate(
            contentVersion.createdAt,
          )),
      );
      this.contentVersions = [...this.contentVersions, ...results];
      this.total = total;
      this.page = page;
      this.isLoading = false;
    },
    convertTimestampToDate(timestamp) {
      var date = new Date(parseInt(timestamp));
      moment.locale('fr');
      return (
        moment(date).format('LLLL').charAt(0).toUpperCase() +
        moment(date).format('LLLL').slice(1)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.version-left-side {
  border-right: 1px solid $grey-4-mayday;
  padding-left: 35px;
  padding-right: 35px;
}

.loader-state {
  height: 60vh;
  width: 100%;
}

.content-version-modal {
  :deep() .modal-body {
    overflow-y: hidden;
  }
}
</style>
