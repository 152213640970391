<template>
  <div class="thread__author-avatar" :class="sizeClass" :style="colorStyle">
    {{ getInitials }}
  </div>
</template>

<script>
import { userColor } from 'utils/utils';
import { blueMayday, grey5Mayday } from '@/assets/scss/_colors.scss';

export default {
  name: 'thread-avatar',
  props: {
    username: {
      type: String,
      required: true,
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
  },
  computed: {
    colorStyle() {
      if (this.isDeleted)
        return { backgroundColor: grey5Mayday, color: 'white' };
      return {
        backgroundColor:
          this.username !== null && this.username !== '?'
            ? userColor(this.username, 70, 50)
            : blueMayday,
        color: 'white',
      };
    },
    getInitials() {
      const fullName = this.username.split(' ');
      let initials;
      if (fullName.length >= 2)
        initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      else initials = fullName.shift().charAt(0);
      return initials.toUpperCase();
    },
    sizeClass() {
      const baseClass = 'thread__author-avatar';
      return `${baseClass}-${this.size}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.thread__author {
  &-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    border-radius: 2px;
    flex: none;
    &-sm {
      width: 20px;
      height: 20px;
      font-size: 10px;
    }
    &-md {
      width: 24px;
      height: 24px;
      font-size: 14px;
    }
  }
}
</style>
