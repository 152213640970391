<template>
  <div
    :class="baseClass"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <div
      v-for="col in ['name', 'path']"
      :key="col"
      :class="`${baseClass}__column p-0 mb-2`"
    >
      <el-input
        :class="`${baseClass}__input`"
        :placeholder="$t(`${pre}.${col}-placeholder`)"
        v-model="localVariable[col]"
        :disabled="!editable"
      ></el-input>
    </div>
    <div
      :class="[
        `${baseClass}__actions`,
        { [`${baseClass}__actions-edit`]: editable },
        { [`${baseClass}__actions-hover`]: hovered },
      ]"
    >
      <font-awesome-icon
        v-if="!editable"
        :class="`${baseClass}__actions__btn-delete`"
        :icon="['fal', 'trash']"
        @click="$emit('delete')"
      />
      <font-awesome-icon
        v-if="!editable"
        :class="`${baseClass}__actions__btn-edit`"
        :icon="['fal', 'pen']"
        @click="editable = true"
      />
      <font-awesome-icon
        v-if="editable"
        :class="`${baseClass}__actions__btn-edit`"
        :icon="['fal', 'times']"
        @click="editable = false"
      />
      <font-awesome-icon
        v-if="editable && !loading"
        :class="`${baseClass}__actions__btn-edit`"
        :icon="['fal', 'save']"
        @click="handleChange()"
      />
      <font-awesome-icon
        v-if="editable && loading"
        :class="`${baseClass}__actions__btn-edit`"
        :icon="['fas', 'spinner-third']"
        spin
      ></font-awesome-icon>
    </div>
  </div>
</template>

<script>
const i18nPrefix =
  'settings.card-settings-options.context-variables.drawer.headers';
const baseClass = 'context-variables-settings-drawer__item';

export default {
  name: 'context-variable',
  data() {
    return {
      pre: `${i18nPrefix}`,
      baseClass: baseClass,
      localVariable: {},
      editable: false,
      hovered: false,
      loading: false,
    };
  },
  mounted() {
    if (this.variable.isNew) {
      this.editable = true;
    }
    this.localVariable = { ...this.variable };
  },
  props: {
    variable: {
      type: Object,
    },
  },
  methods: {
    setLocalVariable(variable) {
      const { isNew, ...newVariable } = variable;
      if (isNew) this.editable = true;
      else this.editable = false;
      this.localVariable = newVariable;
    },
    handleChange() {
      this.loading = true;
      this.$emit('change', this.localVariable);
    },
  },
  watch: {
    variable() {
      this.setLocalVariable(this.variable);
    },
  },
};
</script>

<style lang="scss" scoped>
.context-variables-settings-drawer__item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  &__column {
    width: 100%;
    :deep() .el-input.is-disabled {
      .el-input__inner {
        background-color: white;
        border-color: white;
        color: black;
        cursor: default;
      }
    }
  }

  &__input {
    width: 100%;
    padding: 0 8px 0 0;
    :deep() .el-input__inner {
      padding: 0 8px;
    }
  }
  &__actions {
    visibility: hidden;
    min-width: 40px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-edit,
    &-hover {
      visibility: visible;
    }

    &__btn {
      &-delete {
        &:hover {
          cursor: pointer;
          color: $red-mayday;
        }
      }
      &-edit {
        &:hover {
          cursor: pointer;
          color: $blue-mayday;
        }
      }
    }
  }
}
</style>
