<template>
  <AnalyticsModal :visible="show" @close="hideUsageModal">
    <template v-slot:header>
      <div v-loading="statsIsLoading">
        <font-awesome-icon class="header-icon" :icon="['fas', 'user']" />
        <span class="username-title">{{ userName }}</span>
        <span v-if="deletedAt" style="font-style: italic"> {{ $t('new-dashboard.table.body.deleted-user') }}</span>
      </div>
    </template>
    <template v-slot:body>
      <AnalyticsUsageModalBody
        v-if="show"
        :group-name="groupName"
        :user-name="userName"
        :deleted-at="deletedAt"
        :labels="labels"
        :stats-is-loading="statsIsLoading"
        :stats="stats"
        :table-is-loading="tableIsLoading"
        :table-data-options.sync="tableDataOptionsModel"
        :table-data="tableData"
        :table-total-count="tableTotalCount"
        :table-current-page="tableCurrentPage"
        :table-total-pages="tableTotalPages"
        :table-is-ready="tableIsReady"
        :chart-data="chartData"
        :chart-is-loading="chartIsLoading"
        :chart-is-empty="chartIsEmpty"
        :feedbacks="feedbacks"
        :external-doc-url="externalDocUrl"
      />
    </template>
  </AnalyticsModal>
</template>

<script>
import AnalyticsModal from '../../AnalyticsModal';
import AnalyticsUsageModalBody from './AnalyticsUsageModalBody';

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    externalDocUrl: {
      type: String,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    userName: {
      type: String,
      required: true,
    },
    deletedAt: {
      type: String,
    },
    stats: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    tableDataOptions: {
      type: Object,
      required: true,
    },
    tableTotalCount: {
      type: Number,
      required: true,
    },
    tableTotalPages: {
      type: Number,
      required: true,
    },
    tableCurrentPage: {
      type: Number,
      required: true,
    },
    tableIsReady: {
      type: Boolean,
      required: true,
    },
    statsIsLoading: {
      type: Boolean,
      required: true,
    },
    tableIsLoading: {
      type: Boolean,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    chartIsLoading: {
      type: Boolean,
      required: true,
    },
    chartIsEmpty: {
      type: Boolean,
      required: true,
    },
    feedbacks: {
      type: Array,
      default: () => [],
    },
  },

  model: {
    prop: 'show',
    event: 'close',
  },

  components: {
    AnalyticsModal,
    AnalyticsUsageModalBody,
  },

  computed: {
    tableDataOptionsModel: {
      get() {
        return this.tableDataOptions;
      },
      set(value) {
        this.$emit('update:tableDataOptions', value);
        return value;
      },
    },
  },

  methods: {
    hideUsageModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.username-title {
  margin-left: 16px;
}
</style>
