<template>
  <div class="mb-4">
    <DrawerRowTitle
      :title="
        $t(
          'knowledge.drawers.content-view-setting.settings.access.title-public',
        )
      "
      :tooltip="
        $t(
          'knowledge.drawers.content-view-setting.settings.access.tooltip-public',
        )
      "
    />
    <div v-loading="loading" v-if="companyHostname">
      <!-- URL COPY -->
      <div class="settings__public-item">
        <div class="label">
          {{ $t('knowledge.drawers.content-view-setting.settings.public.url') }}
        </div>
        <el-input :value="url" readonly @focus="$event.target.select()">
          <template slot="append">
            <div
              class="copy-icon"
              v-clipboard:copy="url"
              @click="copyUrl('url')"
              :class="{ 'click-bounce': animate.url }"
            >
              {{
                $t(
                  'knowledge.drawers.content-view-setting.settings.public.copy',
                )
              }}
            </div>
          </template>
        </el-input>
      </div>

      <!-- META -->
      <div class="settings__public-item">
        <div v-if="knowledgeIsPublic" class="label">
          {{
            $t(
              'knowledge.drawers.content-view-setting.settings.public.meta-label',
            )
          }}
          <el-tooltip
            :content="
              $t(
                'knowledge.drawers.content-view-setting.settings.public.meta-tooltip',
              )
            "
            placement="top"
          >
            <font-awesome-icon
              :icon="['fad', 'info-circle']"
              class="info-icon mx-2"
            />
          </el-tooltip>
          <div class="toggle">
            <button
              class="reveal"
              @click="displayMetaLabel = !displayMetaLabel"
            >
              <div :class="{ 'open-reveal': displayMetaLabel }">
                <font-awesome-icon :icon="['fal', 'chevron-right']" />
              </div>
            </button>
          </div>
        </div>

        <not-allowed-tooltip
          :user-permissions="userPermissions"
          permission-key="canUpdateContent"
        >
          <template v-slot:main-content="{ hasPermissions }">
            <el-input
              v-if="displayMetaLabel"
              type="textarea"
              :disabled="!hasPermissions"
              :rows="3"
              :placeholder="
                $t(
                  'knowledge.drawers.content-view-setting.settings.public.meta-label-placeholder',
                )
              "
              v-model="newMetaLabel"
            />
          </template>
        </not-allowed-tooltip>

        <div v-if="knowledgeIsPublic" class="label">
          {{
            $t('knowledge.drawers.content-view-setting.settings.public.meta')
          }}
          <el-tooltip
            :content="
              $t(
                'knowledge.drawers.content-view-setting.settings.public.meta-tooltip',
              )
            "
            placement="top"
          >
            <font-awesome-icon
              :icon="['fad', 'info-circle']"
              class="info-icon mx-2"
            />
          </el-tooltip>
          <div class="toggle">
            <button
              class="reveal"
              @click="displayDescription = !displayDescription"
            >
              <div :class="{ 'open-reveal': displayDescription }">
                <font-awesome-icon :icon="['fal', 'chevron-right']" />
              </div>
            </button>
          </div>
        </div>

        <not-allowed-tooltip
          :user-permissions="userPermissions"
          permission-key="canUpdateContent"
        >
          <template v-slot:main-content="{ hasPermissions }">
            <el-input
              v-if="displayDescription"
              type="textarea"
              :disabled="!hasPermissions"
              :rows="3"
              :placeholder="
                $t(
                  'knowledge.drawers.content-view-setting.settings.public.meta-placeholder',
                )
              "
              v-model="newDescription"
            />
          </template>
        </not-allowed-tooltip>
        <div v-if="modified" class="settings__public-cta">
          <el-button @click="handleCancel">{{
            $t('knowledge.drawers.content-view-setting.settings.public.cancel')
          }}</el-button>
          <el-button @click="handleUpdate" type="primary">{{
            $t('knowledge.drawers.content-view-setting.settings.public.update')
          }}</el-button>
        </div>
      </div>

      <!-- COVER -->
      <ContentViewSettingsImageCover
        :cover="cover"
        :userPermissions="userPermissions"
        :content="content"
        @open-cover-modal="$emit('open-cover-modal', $event)"
        @set-cover-image="$emit('set-cover-image', $event)"
        @delete-cover="$emit('delete-cover', $event)"
      />
      <!-- IFRAME -->
      <div class="settings__public-item">
        <div class="label">
          {{
            $t('knowledge.drawers.content-view-setting.settings.public.iframe')
          }}
          <el-tooltip
            :content="
              $t(
                'knowledge.drawers.content-view-setting.settings.public.iframe-tooltip',
              )
            "
            placement="top"
          >
            <font-awesome-icon
              :icon="['fad', 'info-circle']"
              class="info-icon mx-2"
            />
          </el-tooltip>
          <div class="toggle">
            <button class="reveal" @click="displayIframe = !displayIframe">
              <div :class="{ 'open-reveal': displayIframe }">
                <font-awesome-icon :icon="['fal', 'chevron-right']" />
              </div>
            </button>
          </div>
        </div>

        <div v-if="displayIframe">
          <el-input :value="iframeUrl" readonly @focus="$event.target.select()">
            <template slot="append">
              <div
                class="copy-icon"
                v-clipboard:copy="iframeUrl"
                @click="copyUrl('iframeUrl')"
                :class="{ 'click-bounce': animate.iframeUrl }"
              >
                {{
                  $t(
                    'knowledge.drawers.content-view-setting.settings.public.copy',
                  )
                }}
              </div>
            </template>
          </el-input>
          <div class="label">
            {{
              $t(
                'knowledge.drawers.content-view-setting.settings.public.iframe-code',
              )
            }}
          </div>

          <el-input
            :value="iframeCode"
            readonly
            @focus="$event.target.select()"
          >
            <template slot="append">
              <div
                class="copy-icon"
                v-clipboard:copy="iframeCode"
                @click="copyUrl('iframeCode')"
                :class="{ 'click-bounce': animate.iframeCode }"
              >
                {{
                  $t(
                    'knowledge.drawers.content-view-setting.settings.public.copy',
                  )
                }}
              </div>
            </template>
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DrawerRowTitle from '@/components/Drawers/Commons/DrawerRowTitle';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';
import ContentViewSettingsImageCover from './ContentViewSettingsImageCover';

export default {
  name: 'content-view-settings-public',
  components: {
    DrawerRowTitle,
    NotAllowedTooltip,
    ContentViewSettingsImageCover,
  },
  props: {
    publicData: {
      type: Object,
      required: false,
    },
    cover: {
      type: String,
      required: false,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
    knowledgeIsPublic: {
      type: Boolean,
      required: true,
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      newDescription: '',
      newMetaLabel: '',
      animate: { url: false, iframeUrl: false, iframeCode: false },
      displayDescription: false,
      displayMetaLabel: false,
      displayIframe: false,
      hover: false,
    };
  },
  computed: {
    hasRelatedPlugins() {
      return (
        this.focusKnowledge.plugins.findIndex(
          (p) => p.type === 'RELATED_CONTENTS',
        ) > -1
      );
    },
    loading() {
      return !this.publicData;
    },
    modified() {
      return (
        this.newDescription !== this.description ||
        this.newMetaLabel !== this.metaLabel
      );
    },
    lang() {
      return `${this.editingLanguage}/`;
    },
    url() {
      if (!this.publicData) return '';

      const { slug, id } = this.publicData;
      return `https://${this.companyHostname}/${this.lang}contents/${slug}-${id}`;
    },
    iframeUrl() {
      if (!this.publicData) return '';

      const { slug, id } = this.publicData;
      return `https://${this.companyHostname}/${this.lang}embed/${slug}-${id}`;
    },
    iframeCode() {
      if (!this.publicData) return '';

      const { slug, id } = this.publicData;
      return `<iframe src="https://${this.companyHostname}/${this.lang}embed/${slug}-${id}"></iframe>`;
    },
    description() {
      if (!this.publicData) return '';

      const { description } = this.publicData;
      return description;
    },
    metaLabel() {
      if (!this.publicData) return '';

      const { metaLabel } = this.publicData;
      return metaLabel;
    },
    relatedContentIds() {
      return this.relatedList.map((content) => content.id);
    },
    ...mapGetters('adminModule', ['companyHostname']),
    ...mapGetters('knowledgeModule', [
      'focusKnowledge',
      'focusKnowledgeId',
      'focusKnowledgeIsPublic',
      'focusContent',
      'editingLanguage',
      'focusKnowledgeIsMultilinguale',
    ]),
  },
  watch: {
    publicData() {
      this.newDescription = this.publicData ? this.publicData.description : '';
      this.newMetaLabel = this.publicData ? this.publicData.metaLabel : '';
    },
  },
  async mounted() {
    const { pinnedContents } = await this.getPinnedContents(
      this.focusKnowledgeId,
    );

    this.pinnedContents = pinnedContents;
    this.contentId = this.$route.params.contentId;
    this.switchPinnedContent = this.pinnedContents.includes(this.contentId);

    this.newDescription = this.publicData ? this.publicData.description : '';
  },
  methods: {
    copyUrl(type) {
      this.animate[type] = true;
      this.$message({
        message: this.$t(
          'knowledge.actions.copy-to-clipboard-confirmation-message',
        ),
        type: 'info',
      });
      setTimeout(() => {
        this.animate[type] = false;
      }, 500);
    },
    handleUpdate() {
      this.$emit('update-public-description', {
        description: this.newDescription,
        metaLabel: this.newMetaLabel,
      });
    },
    handleCancel() {
      this.newDescription = this.description;
    },
    handleModifyCover() {
      this.$emit('open-cover-modal');
    },
    handleDeleteCover() {
      this.$emit('delete-cover');
    },
    handlePinnedContent() {
      const pos = this.pinnedContents.indexOf(this.contentId);
      if (pos > -1) this.pinnedContents.splice(pos, 1);
      else this.pinnedContents.push(this.contentId);

      this.updatePinnedContents({
        knowledgeId: this.focusKnowledgeId,
        pinnedContents: this.pinnedContents,
      });
    },
    ...mapActions('knowledgeModule', [
      'getPinnedContents',
      'updatePinnedContents',
    ]),
  },
};
</script>

<style lang="scss" scoped>
:deep() .el-input__inner {
  padding: 4px 8px;
  height: 28px;
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: $grey-7-mayday;
}

:deep() .el-textarea__inner {
  padding: 4px 8px;
}

:deep() .el-input-group__append {
  padding: 0;
  width: 28px;
  height: 28px;
  background-color: white;
}

.copy-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $grey-7-mayday;
  width: 100%;
  padding: 0 8px;
  font-size: 12px;
  * {
    width: 14px;
    height: 18px;
  }
  transition: transform 200ms ease;

  &:hover {
    transform: scale(1.1);
    color: $blue-mayday;
  }
}

.click-bounce {
  animation: bounce 500ms ease;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.settings__public-item {
  margin-bottom: 12px;
}

.label {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
}

:deep() .el-switch__core {
  height: 16px;
  width: 28px !important;
}

:deep() .el-switch__core:after {
  width: 12px;
  height: 12px;
}

:deep() .el-switch.is-checked .el-switch__core::after {
  margin-left: -13px;
}

.info-icon {
  color: $grey-5-mayday;
  font-size: 12px;
}

.settings__public-cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}

:deep() .el-button {
  padding: 4px 8px;
  font-size: 12px;
}

.cover-wrapper {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  .cover-img {
    height: 100px;
    object-fit: contain;
  }
}

.img-placeholder-wrapper {
  height: 100px;
  display: flex;
  align-items: center;
  border: 1px $grey-4-mayday solid;
}
.img-placeholder-wrapper-empty {
  height: 100px;
  background-color: $grey-1-mayday;
  border: 1px $grey-4-mayday dashed;
  justify-content: center;
  &:hover {
    border: 1px $blue-mayday dashed;
    background-color: $grey-1-mayday;
    filter: drop-shadow(0px 15px 15px rgba(112, 144, 176, 0.2));
  }
}

.img-placeholder {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 12px 0;
  margin-left: -6px;
}

.img-placeholder-hover {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.img-placeholder-wrapper-preview {
  max-width: 100%;
  display: flex;
  align-items: center;
}

.reveal {
  width: 18px;
  height: 18px;
  transform-origin: center;

  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: $grey-1-mayday;
  &:hover {
    color: $blue-mayday;
    background: rgba($blue-mayday, 0.1);
  }

  * {
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 200ms ease;
  }
  .open-reveal {
    transform: rotate(90deg);
  }
}
</style>
