<template>
  <el-drawer
    class="user-drawer"
    :visible.sync="localDisplay"
    :with-header="false"
    size="50%"
  >
    <UserDrawerHeader
      :username="
        user.username && user.username !== ' ' ? user.username : user.email
      "
      @update-focused-user="$emit('update-focused-user', $event)"
    />
    <div class="container p-0">
      <UserDrawerSide class="side" :user="user" />
      <UserDrawerMain
        class="main"
        :user="user"
        :loading="loading"
        :pending="pending"
        @update-focused-user="$emit('update-focused-user', $event)"
        @delete-user="$emit('delete-user', $event)"
        @close="$emit('close')"
        @refresh-helpdesk-id="$emit('refresh-helpdesk-id', $event)"
      />
    </div>
    <UserDrawerFooter class="drawer-footer" @close="$emit('close')" />
  </el-drawer>
</template>
<script>
import UserDrawerHeader from './UserDrawerHeader';
import UserDrawerSide from './UserDrawerSide';
import UserDrawerMain from './UserDrawerMain';
import UserDrawerFooter from './UserDrawerFooter';

export default {
  name: 'user-drawer',
  components: {
    UserDrawerHeader,
    UserDrawerSide,
    UserDrawerMain,
    UserDrawerFooter,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localDisplay: false,
    };
  },
  created() {
    this.localDisplay = this.show;
  },
  watch: {
    localDisplay(val) {
      if (!val) this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.body-container {
  overflow: auto;
}
.container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 190px);
}
.side {
  width: 250px;
}
.main {
  flex: 0 0 auto;
  padding-right: 30px;
  width: calc(100% - 250px);
  overflow: auto;
}

.drawer-footer {
  height: 60px;
  padding: 20px;
}
</style>
