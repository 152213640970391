<template>
  <div class="content-version-detail">
    <div class="version-content-header sticky-top">
      <h2>{{ focusVersion.label }}</h2>
      <p class="mb-2">
        <small>⏱️{{ focusVersion.formattedDate }}</small>
      </p>
      <p class="mb-4">
        <small>🖊️{{ focusVersion.author }}</small>
      </p>
    </div>
    <mayday-editor
      class="version-text"
      ref="content-versioning-reader"
      v-model="focusVersion.body"
      :editable="false"
      @click:inline-content="handleOnClickInlineContent"
      @click:attached-file="handleClickAttachedFile"
      @click:open-link="handleOpenLink"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'content-version-detail',
  props: {
    focusVersion: Object,
  },
  methods: {
    handleOpenLink({ href }) {
      window.open(href, '_blank');
    },
    handleClickAttachedFile(evt) {
      if (evt && evt.href) {
        window.open(evt.href, '_blank');
      }
    },
    ...mapActions('knowledgeModule', ['handleOnClickInlineContent']),
  },
  watch: {
    focusVersion() {
      const editor = this.$refs['content-versioning-reader'];
      editor.editor.commands.setContent(this.focusVersion.body);
    },
    handleClickAttachedFile(evt) {
      if (evt && evt.href) {
        window.open(evt.href, '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.version-content-header {
  background-color: white;
  padding-bottom: 8px;
}

.content-version-detail {
  height: 60vh;
  overflow: auto;
}

.version-text {
  border-left: 2px solid $blue-mayday;
  padding: 5px 15px;
}
</style>
