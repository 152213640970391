<template>
  <div class="popover-step-mover">
    <ChildButtonOptionsPopover
      :state="state"
      :radioButtons="radioButtons"
      @methodChange="setMethod"
      @close="close()"
      @cancel-quit="state = ''"
    >
      <template v-slot:title>
        {{ $t('knowledge.child.redirect.popover.title') }}
      </template>
      <template v-slot:state-over>
        {{ $t(`knowledge.child.redirect.popover.state-${state}`) }}
      </template>
      <template v-slot:state-cancel>
        {{ $t('knowledge.child.redirect.popover.state-cancel') }}
      </template>
      <template v-slot:state-cancel-button-keep-going>
        {{
          $t('knowledge.child.redirect.popover.state-cancel-button.keep-going')
        }}
      </template>
      <template v-slot:state-cancel-button-quit>
        {{ $t('knowledge.child.redirect.popover.state-cancel-button.quit') }}
      </template>
      <template v-slot:popover-content>
        <div class="w-100" v-if="method === 'link'">
          <RedirectByLinkWrapper
            :child="child"
            @update-redirection="handleRedirectionByLink"
          />
        </div>
        <div v-if="method === 'search'">
          <StepMoverSearchWrapper
            :forbiddenIds="forbiddenIds"
            :parent="parent"
            :rootId="rootId"
            :searchedContentTypes="['Diagnostic', 'Article', 'Step']"
            :focusedOption="focusedOption"
            @focus-option="setFocusOption"
            @close="state = 'quit'"
            @options-update="currentOptions = $event"
          />
        </div>
      </template>
      <template v-slot:validation-buttons v-if="method === 'search'">
        <div class="d-flex justify-content-end footer">
          <el-button
            @click="state = 'quit'"
            plain
            size="mini"
            class="cancel-button"
          >
            {{ $t('knowledge.actions.step-mover.cancel') }}
          </el-button>
          <el-button
            @click="handleRedirectionBySearch"
            type="primary"
            :disabled="!redirectValidation"
            size="mini"
          >
            {{
              focusedOptionAtLevel
                ? $t('knowledge.child.redirect.popover.redirect')
                : $t('knowledge.child.redirect.popover.redirect-here')
            }}
          </el-button>
        </div>
      </template>
    </ChildButtonOptionsPopover>
  </div>
</template>

<script>
import ChildButtonOptionsPopover from '../../views/KnowledgeEditor/ParametricContentBuilder/ParametricDiagnosticBuilder/DiagnosticBuilderMain/DiagnosticBuilderChildren/ChildButton/ChildButtonOptionsPopover';
import StepMoverSearchWrapper from '../StepMover/StepMoverSearchWrapper';
import RedirectByLinkWrapper from './RedirectByLinkWrapper';

import { mapActions } from 'vuex';

export default {
  props: {
    child: {
      type: Object,
      default: () => {},
    },
    rootId: String,
    forbiddenIds: {
      type: Object,
      default: () => {},
    },
    parent: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    StepMoverSearchWrapper,
    ChildButtonOptionsPopover,
    RedirectByLinkWrapper,
  },
  name: 'popover-redirect',
  data() {
    return {
      path: [],
      state: '',
      method: 'link',
      radioButtons: [
        { label: 'link', title: 'knowledge.child.redirect.popover.link' },
        { label: 'search', title: 'knowledge.child.redirect.popover.search' },
      ],
      focusedOption: null,
      currentOptions: [],
    };
  },
  computed: {
    redirectValidation() {
      return this.focusedOption !== null && Boolean(this.focusedOption.type);
    },
    focusedOptionAtLevel() {
      return (
        this.focusedOption &&
        (this.optionIds.includes(this.focusedOption.id) ||
          this.focusedOption.type === 'Article')
      );
    },
    optionIds() {
      return this.currentOptions.map((op) => op.id);
    },
  },
  methods: {
    setMethod($event) {
      this.method = $event;
      this.focusedOption = null;
    },
    async handleRedirectionByLink(e) {
      await this.setContentRedirection(e.redirection);
    },
    async handleRedirectionBySearch() {
      await this.setContentRedirection({
        entityId: this.focusedOption.id,
        entityType: this.focusedOption.type,
      });
    },
    async setContentRedirection({ entityId, entityType, lang }) {
      this.state = 'loading';
      try {
        const success = await this.setRedirection({
          entityType,
          entityId,
          lang,
          child: this.child,
        });
        if (!success) throw new Error('redirection');
        this.$message({
          message: this.$t(`knowledge.child.redirect.success`),
          type: 'success',
        });

        this.$emit('update-redirection', {
          redirection: { entityId, entityType, lang },
        });

        this.state = entityId ? 'over' : 'over-removed';
      } catch (err) {
        this.state = 'error';
        this.$message({
          message: this.$t(`knowledge.child.redirect.error.redirection`),
          type: 'error',
        });
      }
    },
    setFocusOption(option) {
      if (
        option === null ||
        ((option.type === 'Diagnostic' ||
          option.type === 'Step' ||
          option.type === 'keyStep' ||
          option.type === 'Article') &&
          !this.isForbidden(option.id))
      )
        this.focusedOption = option;
    },
    isForbidden(optionId) {
      return Object.values(this.forbiddenIds).includes(optionId);
    },
    close() {
      this.$emit('close');
      this.state = '';
    },
    ...mapActions('knowledgeModule', ['relocateChild', 'setRedirection']),
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: $grey-1-mayday;
  border-bottom: 2px solid $grey-4-mayday;
  font-weight: bold;
}

.footer {
  padding: 8px 16px 8px 16px;
  background-color: $grey-2-mayday;
}

:deep() .el-radio-button__inner {
  width: 100% !important;
  background-color: rgba($blue-mayday, 0.1);
  border-color: $blue-mayday !important;
}

.user-guiding {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 32px;
  height: 32px;
  color: $blue-mayday;
}

.quit {
  width: 32px;
  height: 32px;
  color: $red-mayday;
}

.cancel-button {
  background-color: $grey-2-mayday;
  border: none;
  &:hover {
    color: $blue-mayday !important;
    background-color: $grey-2-mayday !important;
    border: none;
  }
}
</style>
