<template>
  <div :class="`${base} ${base}__${status}`">
    <font-awesome-icon
      v-if="config.loading"
      class="loading-icon"
      :icon="['fal', 'spinner-third']"
      spin
    />
    <div :class="`${base}__${status}__title`" v-if="config.title">
      {{ config.title }}
    </div>
    <div
      :class="`${base}__${status}__description`"
      v-if="config.description"
      v-html="sanitize(config.description)"
    ></div>
    <div class="button-wrapper" v-if="config.cta">
      <div class="button" @click="$emit('click:cta', status)">
        {{ config.cta }}
      </div>
    </div>
  </div>
</template>

<script>
import { sanitize } from '@/plugins/dompurify';

export default {
  name: 'brain-task-toast-body',
  props: {
    status: {
      type: String,
      required: true,
    },
    statusConfigs: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      base: 'brain-task-toast__body',
      sanitize: sanitize,
    };
  },
  computed: {
    config() {
      return this.statusConfigs[this.status] || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.brain-task-toast__body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  min-height: 64px;
  height: auto;
  &__done {
    color: $grey-8-mayday;
    flex-direction: column;
  }
  &__pending,
  &__loading {
    gap: 8px;
    padding: 12px 0px 12px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &__description {
      max-width: 75%;
    }
  }
  &__failed {
    width: 100%;
    flex-direction: column;
    &__title {
      width: 100%;
      color: $red-mayday;
    }
  }
}

.button {
  border-radius: 4px;
  height: 100%;
  line-height: 20px;
  background-color: $purple-5-mayday;
  cursor: pointer;
  color: white;
  padding: 4px 8px 4px 8px;
  display: flex;
  align-items: center;
}

.loading-icon {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.button-wrapper {
  display: flex;
  justify-content: end;
  width: 100%;
}
</style>
