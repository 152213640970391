<template>
  <div class="drawer-footer">
    <el-button
      v-if="!hideButtons"
      type="danger"
      size="mini"
      @click="$emit('cancel')"
    >
      {{ $t('generic.cancel') }}
    </el-button>
    <el-button
      v-if="!hideButtons"
      type="primary"
      size="mini"
      @click="$emit('save')"
    >
      {{ $t('generic.confirm') }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'roles-drawer-footer',
  props: {
    hideButtons: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8fafe;
}
</style>
