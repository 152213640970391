<template>
  <div class="container text-center">
    <div class="row">
      <div class="col-lg-8 col-xl-6 col-sm-11 col-md-11 mx-auto">
        <h1 class="display-3 my-4">{{ $t('landing.404') }}</h1>
        <img id="gif" src="../assets/404.gif" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>

<style scoped>
#gif {
  max-width: 70vh;
  border-radius: 5px;
}
</style>
