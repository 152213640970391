<template>
  <div>
    <div class="d-flex flex-row justify-content-between align-items-stretch">
      <div>
        <span class="plugin-label">{{ $t(label) }}</span>
        <div class="plugin-description">{{ $t(description) }}</div>
        <div class="d-flex align-items-center">
          <font-awesome-icon
            :icon="['fas', 'check-circle']"
            :class="['icon-state-info mr-1', { active: switchValue }]"
          ></font-awesome-icon>
          <div class="state-info">
            {{ switchValue ? $t(activeText) : $t(disableText) }}
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column align-items-end justify-content-between h-auto"
      >
        <el-switch
          v-model="switchValue"
          @change="handleChange"
          size="mini"
          class="pl-2 pt-3 mb-2"
        ></el-switch>
        <el-button
          class="mt-2"
          v-if="hasConfig"
          outline
          type="primary"
          size="mini"
          @click="openAdvancedSettingsDrawer()"
        >
          {{ $t('settings-layout.menu-data.plugins.configure') }}
        </el-button>
      </div>
    </div>

    <PluginAdvancedSettingsDrawer
      v-if="isOpen"
      :show="isOpen"
      :currentPluginEntities="currentPluginEntities"
      :label="label"
      :description="description"
      :shortDescription="shortDescription"
      :confirmationModal="confirmationModal"
      :config="advancedSettingsConfig"
      :isActive="switchValue"
      :pluginType="pluginType"
      :icon="icon"
      :errors="errors"
      @close="handleClose()"
      @confirm-close="confirmClose"
      @update-plugin="(payload) => updateCurrentPluginEntities(payload)"
      @save-plugin="savePlugin"
      @switched="handleChange"
      @update-access-restriction="(payload) => updateAccessRestriction(payload)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PluginAdvancedSettingsDrawer from './AdvancedSettings/PluginAdvancedSettingsDrawer.vue';

export default {
  name: 'plugin-setting',
  components: {
    PluginAdvancedSettingsDrawer,
  },
  data() {
    return {
      switchValue: true,
      isOpen: false,
      confirmationModal: false,
      currentPluginEntities: [],
      errors: [],
    };
  },
  props: {
    label: {
      type: String,
      default: () => '',
    },
    description: {
      type: String,
      default: () => '',
    },
    shortDescription: {
      type: String,
      default: () => '',
    },
    activeText: {
      type: String,
      default: () => '',
    },
    disableText: {
      type: String,
      default: () => '',
    },
    companyPreference: {
      type: String,
      default: () => '',
    },
    getter: {
      type: String,
      default: () => false,
    },
    advancedSettingsConfig: {
      type: Object,
      default: () => {},
    },
    pluginType: {
      type: String,
      default: () => '',
    },
    icon: {
      type: Array,
    },
  },
  async created() {
    this.switchValue = this[this.getter] && !!this[this.getter].type;
  },
  computed: {
    hasConfig() {
      return Object.values(this.advancedSettingsConfig || {}).some(
        (value) => !!value,
      );
    },
    pluginTranslationKey() {
      switch (this.pluginType) {
        case 'EmbeddedIframeEntity':
          return 'embedded-iframe';
        case 'TrendingContentEntity':
          return 'trending-content';
        case 'NewContentEntity':
          return 'new-content';
        case 'SmartActionEntity':
          return 'smart-actions';
        default:
          return 'default';
      }
    },
    // When implementing a new plugin for a company preference, add your getter here
    ...mapGetters([
      'companyAllowCompanyFavoritesPreference',
      'companyApplyExclusiveSearchPreference',
      'companyAllowToastSoundPreference',
      'companySearchUserBoostPreference',
      'companyAllowEmbeddedIframePreference',
      'companyAllowTrendingContentPreference',
      'companyAllowNewContentPreference',
      'companyAllowSmartActionsPreference',
      'isPluginPreferenceAllowed',
    ]),
  },
  methods: {
    async handleChange(val) {
      this.switchValue = val;
      return (await val)
        ? this.addCompanyPreferences({
            preferenceType: this.companyPreference,
            preferenceValue: '1',
          })
        : this.deleteCompanyPreferences({
            preferenceIds: [this[this.getter].id],
          });
    },

    async openAdvancedSettingsDrawer() {
      const freshPluginEntities = await this.getPluginEntities(this.pluginType);
      if (!(freshPluginEntities && freshPluginEntities.length)) {
        return;
      }
      this.currentPluginEntities = freshPluginEntities;
      this.isOpen = true;
    },

    closeAdvancedSettingsDrawer() {
      this.isOpen = false;
    },

    async handleClose() {
      const savedPluginEntities = await this.getPluginEntities(this.pluginType);
      const isSaved = this.areLocalEntitiesSaved(
        savedPluginEntities,
        this.currentPluginEntities,
      );
      if (!isSaved) {
        this.confirmationModal = true;
      } else {
        this.closeAdvancedSettingsDrawer();
      }
    },

    updateCurrentPluginEntities(payload) {
      this.currentPluginEntities = payload;
    },

    async confirmClose(save) {
      if (save) {
        const saveResult = await this.savePlugin();
        if (!saveResult) {
          this.confirmationModal = false;
          return;
        }
      }
      this.confirmationModal = false;
      this.closeAdvancedSettingsDrawer();
    },

    areLocalEntitiesSaved(entities1, entities2) {
      if (entities1.length !== entities2.length) {
        return false;
      }
      return !entities1.some(
        (entity1, index) => !this.isLocalEntitySaved(entity1, entities2[index]),
      );
    },

    isLocalEntitySaved(obj1, obj2) {
      if ((obj1 || obj2) && (!obj1 || !obj2)) {
        return false;
      }
      return (
        obj1.id === obj2.id &&
        obj1.label === obj2.label &&
        obj1.description === obj2.description &&
        obj1.url === obj2.url &&
        obj1.color === obj2.color &&
        obj1.title === obj2.title &&
        (!this.advancedSettingsConfig.icon ||
          (obj1.icon && obj2.icon && obj1.icon.value === obj2.icon.value)) &&
        JSON.stringify(obj1.integrations) === JSON.stringify(obj2.integrations)
      );
    },

    validateEntities(entities) {
      return entities.map(({ type, title, label, url, color, subtype }) => {
        let errors = null;
        switch (type) {
          case 'SmartActionEntity':
            errors = {
              ...(!subtype && {
                subtype:
                  'settings-layout.menu-data.plugins.smart-actions.advanced-settings.subtype.error',
              }),
              ...(this.advancedSettingsConfig.subtypes &&
                this.advancedSettingsConfig.subtypes[subtype] &&
                this.advancedSettingsConfig.subtypes[subtype].properties.url &&
                !this.checkUrl(url) && {
                  url: 'settings-layout.menu-data.plugins.smart-actions.advanced-settings.url.error',
                }),
              ...(!(title && title.trim()) && {
                title:
                  'settings-layout.menu-data.plugins.smart-actions.advanced-settings.title.error',
              }),
              ...(!(label && label.trim()) && {
                label:
                  'settings-layout.menu-data.plugins.smart-actions.advanced-settings.label.error',
              }),
              ...(!(color && color.trim()) && {
                color:
                  'settings-layout.menu-data.plugins.smart-actions.advanced-settings.color.error',
              }),
            };
            break;
          case 'EmbeddedIframeEntity':
            errors = {
              ...(!this.checkUrl(url) && {
                url: 'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.url.error',
              }),
            };
            break;
          default:
        }
        return Object.values(errors || {}).length ? errors : null;
      });
    },

    checkUrl(url) {
      const safeUrl =
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.|:[\w.-]+|\/)+([\w\-._~:/?#[\]@!$&'()*+%,;=.]|)+$/i;
      return safeUrl.test(url);
    },

    showMessage(messageKey, type) {
      this.$message({
        message: this.$t(messageKey, {
          name: this.$t(
            `settings-layout.menu-data.plugins.${this.pluginTranslationKey}.title`,
          ),
        }),
        type: type,
      });
    },

    async savePlugin() {
      const errors = this.validateEntities(this.currentPluginEntities);

      if (
        !this.currentPluginEntities.length ||
        errors.some((error) => !!error)
      ) {
        this.errors = errors;
        this.showMessage(
          'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.generic.error',
          'error',
        );
        return false;
      }
      this.errors = [];
      const result = await this.updatePluginEntities(
        this.currentPluginEntities,
      );
      if (result) {
        this.currentPluginEntities = result;
      }
      const messageKey = `settings-layout.menu-data.plugins.advanced-settings.drawer.header.${
        result ? 'success' : 'error'
      }`;
      this.showMessage(messageKey, result ? 'success' : 'error');
      return true;
    },

    async updateAccessRestriction(payload) {
      const result = await this.updateAccessRestrictions(payload);

      if (result) {
        const { id, accessRestrictions } = result;
        this.currentPluginEntities = this.currentPluginEntities.map((entity) =>
          entity.id === id ? { ...entity, accessRestrictions } : entity,
        );
      }

      const messageKey = `settings-layout.menu-data.plugins.advanced-settings.drawer.header.${
        result ? 'success' : 'error'
      }`;
      this.showMessage(messageKey, result ? 'success' : 'error');
    },
    ...mapActions(['addCompanyPreferences', 'deleteCompanyPreferences']),
    ...mapActions('adminUserDirectoryModule', [
      'getPluginEntities',
      'updatePluginEntities',
      'updateAccessRestrictions',
    ]),
  },
  watch: {
    companyAllowPreference() {
      return this.isPluginPreferenceAllowed(this.companyPreference);
    },
  },
};
</script>

<style lang="scss" scoped>
.plugin-label {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}
.plugin-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
.icon-state-info {
  color: $grey-4-mayday;
}
.active {
  color: $green-mayday !important;
}
.state-info {
  font-size: 12px;
}
</style>
