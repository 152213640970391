<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="element-title">
        {{ $t('settings.permissions.user-labels.title') }}
      </div>
      <el-button
        class="mb-2"
        type="primary"
        size="mini"
        :disabled="userLabelCategories === null"
        @click="newCategory = true"
      >
        {{ $t('settings.permissions.user-labels.create-category-button') }}
      </el-button>
    </div>
    <div class="element-description">
      {{ $t('settings.permissions.user-labels.description') }}
    </div>
    <div v-if="success">
      <!-- EMPTY STATE -->
      <div
        class="empty-state-user-labels"
        v-if="!userLabelCategories.length && !newCategory"
        @click="newCategory = true"
      >
        <img
          class="empty-state-labels-img"
          src="~assets/empty-states/empty-state-labels.svg"
        />
        <div class="">
          <p class="mb-0">
            {{
              $t(
                'settings.permissions.user-labels.empty-state-user-label-placeholder',
              )
            }}
          </p>
          <small class="text-muted">
            <a
              href="https://documentation.getmayday.co/tableaux-de-bords/les-tableaux-de-bords-mayday"
              target="_blank"
            >
              {{
                $t(
                  'settings.permissions.user-labels.empty-state-user-label-subplaceholder',
                )
              }}
            </a></small
          >
        </div>
      </div>

      <LabelCategorySettingsTable
        class="mt-2"
        v-loading="userLabelCategoriesIsLoading"
        :user-label-categories="userLabelCategories"
        @delete-category="confirmDeleteCategory"
        @update-category="updateCategory"
        @update-label="updateLabel"
        @create-label="createLabel"
        @delete-label="deleteLabel"
      />

      <NewLabelCategorySettingsDropdown
        v-if="newCategory"
        @create-category="addNewCategory"
        @cancel-category="newCategory = false"
      />
    </div>
    <!-- FAILED RETRY BUTTON -->
    <div
      v-else
      class="d-flex justify-content-center mt-2"
      v-loading="userLabelCategoriesIsLoading"
    >
      <el-button size="small" type="primary" @click="getCategories">{{
        $t('settings.reload')
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NewLabelCategorySettingsDropdown from './NewLabelCategorySettingsDropdown';

import LabelCategorySettingsTable from './LabelCategorySettingsTable';
export default {
  name: 'LabelCategorySettings',
  components: {
    NewLabelCategorySettingsDropdown,
    LabelCategorySettingsTable,
  },
  props: {
    generalSuccess: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localSuccess: true,
      newCategory: false,
    };
  },
  computed: {
    success() {
      return this.generalSuccess && this.localSuccess;
    },
    ...mapGetters('adminModule', [
      'userLabelCategories',
      'userLabelCategoriesIsLoading',
    ]),
  },
  methods: {
    /** RETRY */
    async getCategories() {
      this.localSuccess = await this.getCompanyUserLabelCategories();
    },
    /** ADD NEW CATEGORY */
    async addNewCategory({ name, icon, color }) {
      this.newCategory = false;
      this.localSuccess = await this.createCompanyUserLabelCategory({
        name,
        icon,
        color,
      });
    },

    /** DELETE CATEGORY */
    async confirmDeleteCategory(id) {
      if (!id) return;
      this.localSuccess = await this.deleteCompanyUserLabelCategory(id);
    },

    /** UPDATE CATEGORY */
    async updateCategory(newCategory) {
      this.localSuccess = await this.updateCompanyUserLabelCategory(
        newCategory,
      );
    },

    /** ADD NEW LABEL */
    async createLabel({ companyUserLabelCategoryId, name }) {
      this.localSuccess = await this.createCompanyUserLabel({
        companyUserLabelCategoryId,
        name,
      });
    },

    /** UPDATE LABEL */
    async updateLabel({ id, name, companyUserLabelCategoryId }) {
      this.localSuccess = await this.updateCompanyUserLabel({
        id,
        name,
        companyUserLabelCategoryId,
      });
    },

    /** DELETE LABEL */
    async deleteLabel({ id, companyUserLabelCategoryId }) {
      this.localSuccess = await this.deleteCompanyUserLabel({
        id,
        companyUserLabelCategoryId,
      });
    },
    ...mapActions('adminModule', [
      // CATEGORIES
      'getCompanyUserLabelCategories',
      'createCompanyUserLabelCategory',
      'deleteCompanyUserLabelCategory',
      'updateCompanyUserLabelCategory',
      // LABELS
      'createCompanyUserLabel',
      'deleteCompanyUserLabel',
      'updateCompanyUserLabel',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
.empty-state-user-labels {
  margin-left: 20px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  .empty-state-labels-img {
    cursor: pointer;
    max-width: 40px;
    margin-right: 16px;
  }
}
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon-title {
    color: black;
    margin-right: 1px;
  }
}
.settings-card-header-description {
  color: #8898aa;
  font-size: 80%;
}
.label-item-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.label-category {
  margin-top: 8px;
}
.new-category-input {
  margin-top: 24px;
}
</style>
