<template>
  <workflow-input-wrapper v-bind="$props">
    <template v-slot:input>
      <component
        :class="`${base}`"
        :is="elComponent"
        v-model="localValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :type="type"
        :rows="4"
        :fetch-suggestions="fetchSuggestions"
        @change="$emit('change', $event)"
        @input="$emit('input', $event)"
        @select="$emit('select', $event)"
      >
        <el-select
          v-if="options.length"
          slot="prepend"
          class="select-input"
          :value="selectValue"
          @change="$emit('change-select', $event)"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </component>
    </template>
  </workflow-input-wrapper>
</template>
<script>
import { Input, Autocomplete } from 'element-ui';

import WorkflowInputWrapper from './WorkflowInputWrapper.vue';
export default {
  name: 'workflow-input',
  components: {
    WorkflowInputWrapper,
    Input,
    Autocomplete,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'text',
    },
    info: {
      type: String,
      default: null,
    },
    footerText: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    selectValue: {
      type: String,
      default: 'name',
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fetchSuggestions: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      base: Object.freeze('workflow-input'),
      localValue: null,
      localSelectValue: null,
    };
  },
  mounted() {
    this.localValue = this.value;
    this.localSelectValue = this.selectValue;
  },
  computed: {
    elComponent() {
      return this.fetchSuggestions ? Autocomplete : Input;
    },
  },
  watch: {
    value() {
      this.localValue = this.value;
    },
  },
};
</script>
<style lang="scss" scoped>
.select-input {
  width: 100px;
}

.workflow-input {
  width: 100%;
  :deep() .el-input__inner {
    height: 43px;
  }
}
</style>
