<template>
  <div
    class="permission-container"
    :class="{
      divider: showDivider,
      'permission-container-hidden': subItem.hidden,
    }"
  >
    <div class="text-container">
      <h4>{{ $t(subItem.switch) }}</h4>
      <p class="description">{{ $t(subItem.description) }}</p>
    </div>
    <el-switch
      :disabled="disabled || subItem.hidden"
      v-model="localIsOpened"
      @change="
        $emit('switch-change', {
          permissions: subItem.relatedPermissions,
          value: $event,
        })
      "
    />
  </div>
</template>

<script>
export default {
  name: 'role-drawer-simple-switch',
  props: {
    subItem: {
      type: Object,
      required: true,
    },
    showDivider: {
      type: Boolean,
      default: true,
    },
    isOpened: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.localIsOpened = this.isOpened;
  },
  data() {
    return {
      localIsOpened: false,
    };
  },
  watch: {
    isOpened() {
      this.localIsOpened = this.isOpened;
    },
  },
};
</script>

<style lang="scss" scoped>
.permission-container {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  &.divider {
    border-bottom: 1px solid $grey-4-mayday;
  }
}

.permission-container-hidden {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
