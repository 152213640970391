<template>
  <el-drawer
    :show-close="false"
    v-on="$listeners"
    :visible="show"
    :with-header="false"
    size="50%"
    :append-to-body="true"
    @close="handleClose"
  >
    <IntegrationSettingsDrawerHeader
      :title="integration.label"
      :description="description"
      :logo="integration.logo"
    />
    <div class="container p-0">
      <!-- SIDE BAR -->
      <div class="side">
        <IntegrationSettingsDrawerSide
          :types="integration.features"
          :links="integration.links"
          :active="integration.active"
          :disabled="integration.disabled"
          :name="integration.label"
          @switched="updateIntegration"
        />
      </div>
      <!-- MAIN -->
      <div class="main">
        <slot :close="handleClose"></slot>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import IntegrationSettingsDrawerHeader from './IntegrationSettingsDrawerHeader.vue';
import IntegrationSettingsDrawerSide from './IntegrationSettingsDrawerSide.vue';
export default {
  name: 'integration-settings-drawer',
  components: {
    IntegrationSettingsDrawerHeader,
    IntegrationSettingsDrawerSide,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    integration: {
      type: Object,
      required: true,
    },
  },
  created() {},
  computed: {
    description() {
      if (!this.integration.description) return '';
      if (this.integration.raw) return this.integration.description;
      return this.$t(
        `settings.integration.${this.integration.description}.description`,
      );
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    updateIntegration(value) {
      this.$emit('update-integration', {
        source: this.integration.name,
        value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
}
.side {
  padding-top: 6rem;
  width: 200px;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
}
.main {
  flex: 0 0 auto;
  width: calc(100% - 230px);
  :deep() .el-tabs__item {
    color: $grey-5-mayday;
  }
  :deep() .el-tabs__item.is-active {
    color: $blue-mayday;
  }
}
</style>
