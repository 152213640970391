<template>
  <div>
    <div class="card-header">
      <h1 class="display-3 text-center">Bienvenue à bord {{user.username}} 👋</h1>
      <br>
      <h2 class="subtitle text-center">
        Si vous êtes ici, c’est que vous avez pris la sage décision de booster l’efficacité de votre service client 🚂 ! 
        <br>Pour ce faire je vais vous guider pas à pas ! 
      </h2>
      <hr class="my-4" />
      <h3>Voici un aperçu des fonctionnalités qu'offre Mayday :</h3>
      <ul class="boost">
        <li class="item">⚡ Mayday permet de booster la productivité des agents du support</li>
        <li class="item">⏱️ Mayday réduit le temps d'onboarding des agents en les rendant opérationnels plus rapidement</li>
        <li class="item">🔍 Mayday permet aux managers d'avoir une vision précise des problématiques des clients</li>
      </ul>
      <h3>Si vous avez le moindre problème passez à l'étape d'après</h3>
    </div>
    
  </div>
</template>

<script>
  import gql from 'graphql-tag';
  
  const user_query = gql `
    query GetUser {
        user: currentUser {
            username
        }
    }
  `;

  export default {
    data() {
      return {
        user: {},
      }
    },
    apollo: {
      user: {
        query: user_query,
      }
    },
    methods: {
      
    }
  }
</script>

<style scoped>
.boost{
    list-style-type:none;
  }
.item{
  margin-bottom: 8px;
  margin-top: 5px;
}
</style>