<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8" style="min-height: : 50%; ">
      <div class="container col-6">
        <card id="step" shadow type="secondary">
          <FormWelcome v-if="currentStepNumber === 1" />
          <!--                 <FormTree v-if="currentStepNumber === 2"/>
                <FormIntegrate v-if="currentStepNumber === 3"/>
          <FormAnalytics v-if="currentStepNumber === 4"/>-->
          <FormReview v-if="currentStepNumber === 2" />

          <div class="progress-bar">
            <div :style="`width: ${progress}%;`"></div>
          </div>

          <div class="buttons float-right">
            <base-button
              size="sm"
              type="secondary"
              @click="goEnd"
              v-if="currentStepNumber < 5"
              class="btn"
            >Passer</base-button>
            <base-button
              type="secondary"
              @click="goBack"
              v-if="currentStepNumber > 1"
              class="btn-outlined"
            >Précédent</base-button>
            <base-button
              type="primary"
              @click="goNext"
              v-if="currentStepNumber < 2"
              class="btn"
            >Suivant</base-button>
          </div>
        </card>
      </div>
    </base-header>
  </div>
</template>

<script>
import FormWelcome from './Onboarding/FormWelcome';
// import FormTree from './Onboarding/FormTree'
// import FormIntegrate from './Onboarding/FormIntegrate'
// import FormAnalytics from './Onboarding/FormAnalytics'
import FormReview from './Onboarding/FormReview';

export default {
  name: 'Onboarding',
  components: {
    FormWelcome,
    // FormTree,
    // FormIntegrate,
    // FormAnalytics,
    FormReview,
  },
  data() {
    return {
      currentStepNumber: 1,
      length: 2,
    };
  },
  computed: {
    progress() {
      return (this.currentStepNumber / this.length) * 100;
    },
  },
  methods: {
    goBack() {
      this.currentStepNumber--;
    },
    goNext() {
      this.currentStepNumber++;
    },
    goEnd() {
      this.currentStepNumber = 2;
    },
  },
};
</script>
<style scoped>
#step {
}
.progress-bar {
  width: 100%;
  border-radius: 9999px;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 12px;
  background: #c6c6c6;
}
.progress-bar > div {
  border-radius: 9999px;
  position: absolute;
  height: 100%;
  background-color: #172b4d;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
</style>