export const parseDate = (el) => {
  let date = null;

  if (el instanceof Date) date = el;
  else if (typeof el === 'string' || typeof el === 'number')
    date = new Date(el);

  if (date && !!date.getTime()) return date;

  return null;
};
