/* eslint-disable no-unused-vars */
import { mapState, mapActions } from 'vuex';
import gqlService from 'utils/gqlService';
import { asyncForEach, updateDeepNestedNode, flattenNodes } from 'utils/utils';

export default {
  computed: {
    ...mapState({
      mixinTree: (state) => state.focusTree,
      mixinFocusPath: (state) => state.focusPath,
      mixinTreeNodes: (state) => state.treeNodes,
      mixinNodesContent: (state) => state.nodesContent,
    }),
  },
  data() {
    return {
      mixinsFlattenDiagnostic: {
        nodes: [],
        contents: [],
      },
    };
  },
  methods: {
    flattenDiagnostic(node) {
      const { id, content, type, actions, nodes } = node;
      if (content) {
        this.mixinsFlattenDiagnostic.nodes.push({
          id,
          type,
          actions,
          contentId: content.id,
          path: nodes ? nodes.map((n) => n.id) : [],
        });
        this.mixinsFlattenDiagnostic.contents.push(content);
        if (nodes) {
          nodes.forEach((node) => this.flattenDiagnostic(node));
        }
      }
    },
    /**
     * Loads child with ID, and updating tree
     * @param {ARRAY} path
     * @param {ID} id
     */
    async loadNodeChild(id) {
      await gqlService.knowledge.readTreeNode(id).then((nodeChild) => {
        this.mixinsFlattenDiagnostic = {
          nodes: [],
          contents: [],
        };
        this.flattenDiagnostic(nodeChild);
        this.updateOrCreateNodesContent(this.mixinsFlattenDiagnostic.contents);
        this.updateOrCreateTreeNodes(this.mixinsFlattenDiagnostic.nodes);
      });
    },

    /**
     * Inserts new_content in DB and updates all the nodes in the
     * tree with the same content Id
     * @param {Object} new_content
     */
    updateNodeContent(new_content) {
      return gqlService.knowledge.updateNodeContent(new_content);
    },

    /**
     * Updates node in DB, and updating tree
     * @param {*} path
     * @param {*} new_tree_node
     */
    updateTreeNode(path, new_tree_node) {
      gqlService.knowledge.updateTreeNode(new_tree_node);
    },

    /**
     * Perform drang and drop in DB, and updating tree
     * @param {*} new_tree_node
     */
    performDragNDrop(new_tree_node) {
      gqlService.knowledge.dragndrop(new_tree_node);
    },

    /**
     * Creates child to node (ID) of type type
     * @param {*} path
     * @param {*} id | Parent Id
     * @param {*} type
     */
    createTreeNodeChildren(id, type, label) {
      return gqlService.knowledge.createTreeNodeChildren(id, type, label);
    },

    /**
     * Removes child (childrenId) from parent (parentId) children
     * @param {*} childrenId
     */
    removeTreeNodeChildren(childrenId) {
      return gqlService.knowledge.bulkRemoveTreeNodes(childrenId);
    },

    /**
     * Duplicates node (id) in parent (parentId) at position
     * @param {*} path
     * @param {*} id
     * @param {*} parentId
     * @param {*} position
     */
    duplicateTreeNodeChildren(path, id, parentId, position) {
      gqlService.knowledge.duplicateTreeNodeChildren(id, parentId, position);
    },
    unlinkTreeNode(path, treeNodeId, newLabel) {
      gqlService.knowledge
        .unlinkTreeNode(treeNodeId, newLabel)
        .then((newTreeNode) => {
          this.$store.dispatch(
            'updateFocusTree',
            updateDeepNestedNode(
              {
                ...this.mixinTree,
              },
              [
                {
                  key: 'content',
                  value: newTreeNode.content,
                },
              ],
              path.slice(1),
            ),
          );
        });
      if (this.linkedNodes) {
        const clones = this.linkedNodes.filter(({ id }) => id !== treeNodeId);
        clones.forEach((clone) => {
          try {
            let parentIds = clone.ancestors.map((o) => o.id);
            parentIds.push(clone.id);
            this.$store.dispatch(
              'updateFocusTree',
              updateDeepNestedNode(
                {
                  ...this.mixinTree,
                },
                [
                  {
                    key: ['content', 'parents'],
                    value: clones.map(({ id }) => ({
                      id,
                    })),
                  },
                ],
                parentIds.slice(1),
              ),
            );
          } catch (e) {
            // eslint-disable-next-line no-console
            return false;
          }
        });
        this.linkedNodes = this.linkedNodes.filter(
          ({ id }) => id === this.tree.id,
        );
      }
    },
    updateContentPublishStatus(id, published) {
      gqlService.knowledge.bulkUpdateContentPublishStatus(id, published);
    },
    updateContentOutdatedStatus(ids, outdated) {
      gqlService.knowledge.bulkUpdateContentOutdatedStatus(ids, outdated);
    },
    /**
     * This method handles the request for a deep nested descendent that
     * may not be available in the store yet.
     * @param {[ID]} path - path to the deep nested descendent
     */
    async recursiveDeepLoadingMixins(path) {
      await asyncForEach(path.slice(1), async (parentId) => {
        // For earch node Id we check if it is already available in the store.
        // Find a node corresponding to this node id is not enough. For innstance,
        // we may find all the Diagnostics, but we don't have any data of their direct
        // children. Hence we go through several assessments:
        const loadedNode = this.mixinTreeNodes.find(
          (node) => node.id === parentId,
        );
        // We assess if the node is available in the store, if not we GET
        // all the necessary node data through loadNodeChild
        if (loadedNode === undefined) {
          await this.readTreeNode(parentId);
        } else {
          const loadedContent = this.mixinNodesContent.find(
            (c) => c.id === loadedNode.contentId,
          );
          // We assess if the node content is available in the store, if not we GET
          // all the necessary node data through loadNodeChild
          if (loadedContent === undefined) {
            await this.readTreeNode(parentId);
          } else {
            if (loadedNode.path.length > 0) {
              const children = this.mixinTreeNodes.filter((node) =>
                loadedNode.path.includes(node.id),
              );
              // We assess if we have the necessary data for the direct descendents of
              // the node, if not we GET all the necessary node data through loadNodeChild
              if (children.length < loadedNode.path.length) {
                await this.readTreeNode(parentId);
              }
            }
          }
        }
      });
      // Now all the required data is in the store, we can update the focusPath and reset
      // the search header (because most of the time deep nested requested come from a search)
      this.updateFocusPath(path);
      this.$emit('toggle-loading');
      this.$nextTick(() => this.$store.dispatch('resetHeaderSearch'));
    },
    ...mapActions('kbStore', [
      'updateFocusPath',
      'readTreeNode',
      'updateOrCreateNodesContent',
      'updateOrCreateTreeNodes',
    ]),
  },
};
