<template>
  <div class="label-container">
    <!-- ICON EDITION -->
    <SymbolEditor
      :icon="icon.value"
      :color="icon.color"
      @update-icon="updateLabelIcon"
      @update-color="updateLabelColor"
    />

    <!-- LABEL TITLE -->
    <TextEllipsis
      v-if="!editTitle"
      :text="title"
      @dblclick="editTitle = true"
    />

    <ItemEditor
      class="label-editor"
      v-else
      :value="title"
      @edit="updateLabelTitle"
      :isLabel="true"
      @cancel="editTitle = false"
      placeholder="settings.permissions.user-labels.category-input-placeholder"
    />
    <!-- OPTIONS -->
    <div class="label-options" v-if="!editTitle">
      <div class="options-icon options-icon_edit" @click="editTitle = true">
        <font-awesome-icon :icon="['fad', 'edit']" />
      </div>
      <el-popconfirm
        :confirm-button-text="$t('settings.permissions.user-labels.confirm')"
        :cancel-button-text="$t('settings.permissions.user-labels.cancel')"
        icon="el-icon-info"
        icon-color="red"
        :title="$t('settings.permissions.user-labels.suppress-confirm')"
        @confirm="deleteLabel"
      >
        <div class="options-icon options-icon_trash" slot="reference">
          <font-awesome-icon :icon="['fad', 'trash']" />
        </div>
      </el-popconfirm>
    </div>
  </div>
</template>

<script>
import ItemEditor from '../PermissionSettings/AccessSettings/LabelCategorySettings/ItemEditor';
import SymbolEditor from '@/components/Settings/ContentParameters/SymbolEditor';
import TextEllipsis from '@/components/TextEllipsis.vue';

export default {
  name: 'ModuleLabelsSettingsOverviewItem',
  components: {
    TextEllipsis,
    ItemEditor,
    SymbolEditor,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    icon: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    parentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editTitle: false,
    };
  },
  methods: {
    /** DELETE LABEL */
    deleteLabel() {
      this.$emit('delete-label', {
        id: this.id,
        title: this.title,
        parentId: this.parentId,
      });
    },

    /** EDIT LABEL */
    updateLabelTitle(title) {
      this.editTitle = false;
      if (title !== this.title) {
        return this.$emit('update-label-title', {
          id: this.id,
          title,
          parentId: this.parentId,
        });
      }
    },

    /** EDIT ICON */
    updateLabelIcon(icon) {
      this.$emit('update-label-icon', {
        id: this.id,
        icon: {
          value: icon,
          color: this.icon.color,
          type: this.icon.type,
        },
        parentId: this.parentId,
      });
    },
    updateLabelColor(color) {
      this.$emit('update-label-icon', {
        id: this.id,
        icon: {
          value: this.icon.value,
          color,
          type: this.icon.type,
        },
        parentId: this.parentId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.label-container {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0;
}

.label-editor {
  margin-left: 5px;
}

.label-container .label-options {
  visibility: hidden;
  display: contents;
}

.label-container:hover .label-options {
  visibility: visible;
}

.label-options {
  margin-left: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.options-icon {
  color: $grey-7-mayday;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  &:hover {
    background-color: $grey-1-mayday;
  }
  cursor: pointer;
  &_trash:hover {
    color: $red-mayday;
  }
  &_edit:hover {
    color: $blue-mayday;
  }
  * {
    width: 12px;
    height: 12px;
    font-size: 12px;
  }
}
</style>
