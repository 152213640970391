<template>
  <div class="settings__callout" :class="type">
    <div class="left" :class="type">
      <div class="callout-icon">
        <font-awesome-icon :icon="['fal', icon]" />
      </div>
    </div>
    <div class="main">
      <div class="title">{{ $t(tradPath) }}</div>
      <div class="desc">
        {{ $t(`${tradPath}-desc`) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content-view-settings-callout',
  props: {
    trad: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    tradPath() {
      return `${this.trad}.${this.type}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.settings__callout {
  margin: 8px 0;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  color: $grey-7-mayday;
  width: 100%;
  padding: 8px;
  white-space: normal !important;
  background-color: white;
  overflow: hidden;
  &.error {
    border: 1px solid $red-mayday;
  }
  &.warning {
    border: 1px solid $yellow-mayday;
  }
  &.info {
    border: 1px solid $blue-mayday;
  }
}

.left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  .callout-icon {
    width: 14px;
    height: 14px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 14px;
      height: 14px;
    }
  }
}

.left {
  border-radius: 4px;

  &.error {
    background-color: rgba($red-mayday, 0.1);
    color: $red-mayday;
  }
  &.warning {
    background-color: rgba($yellow-mayday, 0.1);
    color: $yellow-mayday;
  }

  &.info {
    background-color: rgba($blue-mayday, 0.1);
    color: $blue-mayday;
  }
}

.main {
  width: 80%;
  margin-left: 8px;
  line-height: 1;
  padding: 4px 0;
  .title {
    color: #525f7f !important;
    margin-bottom: 4px;
    font-weight: 700;
    font-size: 14px;
  }
  .desc {
    color: $grey-7-mayday;
    font-size: 12px;
  }
}

label {
  width: 100%;
}
</style>
