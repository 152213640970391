var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content__result"},[_c('ul',{class:['content__list', _vm.contentListShow ? 'show' : ''],attrs:{"id":"content__list"}},_vm._l((_vm.contents),function(content){return _c('ContentListItem',{key:content.id,attrs:{"content":content,"selected-contents":_vm.selectedContents,"item-type":_vm.itemType,"showBulkActions":_vm.showBulkActions},on:{"focus-content":function($event){return _vm.$emit('focus-content', $event)},"archive":function($event){return _vm.$emit('archive', $event)},"update-selected-contents":function($event){return _vm.$emit('update-selected-contents', $event)},"restore":function($event){return _vm.$emit('restore', $event)},"delete":function($event){return _vm.$emit('delete', $event)},"update-content":function($event){return _vm.$emit('update-content', $event)},"update-content-settings":function($event){_vm.$emit(
          'update-content-settings',
          Object.assign({}, $event, { id: content.id }),
        )},"open-clone-to-target-modal":function($event){return _vm.$emit('open-clone-contents-to-target-modal', [content])},"duplicate-in-same-location":function($event){return _vm.$emit('duplicate-in-same-location', {
          type: 'content',
          contents: [content],
        })}}})}),1),(!_vm.moreOptions && _vm.contents.length > 20)?_c('span',{staticClass:"text-muted display-more",on:{"click":_vm.displayMoreSolutions}},[_vm._v(_vm._s(_vm.$t('knowledge.product.display-more')))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }