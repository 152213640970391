<template>
  <div>
    <div class="section">
      <h4 class="section-title">
        {{ $t('knowledge.modals.notifications.title.label') }}
      </h4>
      <el-input
        v-model="notification.title"
        :placeholder="$t('knowledge.modals.notifications.title.placeholder')"
        @change="onKeyupEnterSubject"
        @keyup.tab="onKeyupEnterSubject"
      />
    </div>
    <div class="section">
      <h4 class="section-title">
        {{ $t('knowledge.modals.notifications.message.label') }}
      </h4>
      <MaydayNotificationEditor
        ref="NotificationEditor"
        class="notification__editor"
        :message="notification.description"
        :editable="true"
        @input="notification.description = $event"
      />
    </div>
    <div class="section">
      <h4 class="section-title">
        {{ $t('knowledge.modals.notifications.urgent.label') }}
      </h4>
      <base-switch v-model="notification.urgent"></base-switch>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import MaydayNotificationEditor from '@/components/Editors/MaydayNotificationEditor';

export default {
  name: 'NotificationEdition',
  components: {
    MaydayNotificationEditor,
  },
  data() {
    return {
      notification: {
        title: '',
        description: '',
        urgent: false,
        filter: [],
        groupIds: [],
      },
      newTag: '',
    };
  },
  computed: mapState(['userGroups']),
  methods: {
    onKeyupEnterSubject() {
      if (this.notification.title.length > 0) {
        this.$refs['NotificationEditor'].focusEditor();
      }
    },
    handleCloseTag(index) {
      this.notification.tags = this.notification.tags.filter((v, indexV) => {
        return indexV !== index;
      });
    },
    addTagToList() {
      this.notification.tags.push(this.newTag);
      this.newTag = '';
    },
  },
  watch: {
    notification: {
      handler() {
        this.$emit('change', this.notification);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.section {
  margin-bottom: 1rem;
  .section-title {
    color: $grey-4-mayday;
    text-transform: uppercase;
  }
}

.notification__editor {
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  min-height: 20vh;
}
</style>
