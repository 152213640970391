<template>
  <div class="logigram-option">
    <side-bar-item
      :description="tooltipDescription"
      :type="type"
      @click="logigramModal = true"
    >
      <template v-slot:icon="{ _class }">
        <font-awesome-icon
          slot="icon"
          :class="_class"
          :icon="['fad', 'project-diagram']"
        />
      </template>
    </side-bar-item>
    <DiagArchiModal
      v-if="logigramModal"
      :display="logigramModal"
      :diagnostic-id="diagnosticId"
      :user-permissions="userPermissions"
      @close="logigramModal = false"
      @relocate-child="$emit('relocate-child', $event)"
    />
  </div>
</template>

<script>
import SideBarItem from './SideBarItem';
import DiagArchiModal from 'components/Modals/DiagArchiModal';

export default {
  name: 'logigram-option',
  components: {
    SideBarItem,
    DiagArchiModal,
  },
  props: {
    diagnosticId: {
      type: String,
    },
    type: {
      type: String,
      default: 'diagnostic',
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tooltipDescription: this.$t(
        'knowledge.diagnostic-builder.side-bar.show-architecture.tooltip',
      ),
      logigramModal: false,
    };
  },
};
</script>
