<template>
  <div>
    <div>
      <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="element-title">
          {{
            $t(
              'settings.permissions.groups-roles.groups-knowledge-access.title',
            )
          }}
        </div>
        <!-- ADD A GROUP -->
        <span class="d-flex">
          <span class="mr-2 d-flex align-items-center">
            <font-awesome-icon
              v-if="!showSearch"
              class="header__search__icon"
              :icon="['fal', 'search']"
              @click="showSearch = !showSearch"
            />
            <el-input
              pre="search"
              v-model="search"
              size="mini"
              :autofocus="true"
              v-click-outside="hideSearch"
              v-else
            >
            </el-input>
          </span>
          <el-button
            outline
            type="primary"
            size="mini"
            @click="modals.newGroup = true"
            :disabled="groups === null"
          >
            {{
              $t(
                'settings.permissions.groups-roles.groups-knowledge-access.create-group-button',
              )
            }}
          </el-button>
        </span>
      </div>
      <div class="element-description">
        {{
          $t(
            'settings.permissions.groups-roles.groups-knowledge-access.description',
          )
        }}
      </div>
      <!-- GROUPS TABLE-->
      <div
        class="user-groups-table mr-0"
        v-if="success"
        v-loading="groupsIsLoading"
      >
        <user-groups-table
          v-if="filteredGroups.length > 0"
          class="col-12"
          :groups="filteredGroups"
          @update-user-group="triggerGroupUpdate"
          @delete-user-group="deleteCompanyGroup"
        />
        <div class="col-12 text-center mt-4" v-if="!groups.length">
          <h2 class="text-muted">
            <font-awesome-icon :icon="['fad', 'user-friends']" class="mr-1" />
            {{
              $t(
                'settings.permissions.groups-roles.groups-knowledge-access.muted-text',
              )
            }}
          </h2>
        </div>
      </div>
      <!-- RELOAD GROUPS -->
      <div
        v-else
        class="d-flex justify-content-center mt-2"
        v-loading="groupsIsLoading"
      >
        <el-button size="small" type="primary" @click="getGroups">
          {{ $t('settings.reload') }}
        </el-button>
      </div>
    </div>

    <add-company-group-modal
      v-if="modals.newGroup"
      :display="modals.newGroup"
      :groups="groups"
      :group="focusGroup"
      :former-name="formerGroupName"
      :step="step"
      @choice="updateOrCreateGroup"
      @close="handleCloseAddGroupModal"
    />
  </div>
</template>
<script>
import UserGroupsTable from './GroupsSettings/UserGroupsTable';
import AddCompanyGroupModal from './GroupsSettings/AddCompanyGroupModal/AddCompanyGroupModal';
import removeAccents from '../../../../utils/removeAccents';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'groups-settings-main',
  components: {
    UserGroupsTable,
    AddCompanyGroupModal,
  },
  props: {
    generalSuccess: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modals: {
        newGroup: false,
      },
      focusGroup: null,
      formerGroupName: '',
      step: 1,
      localSuccess: true,
      showSearch: false,
      search: null,
    };
  },
  computed: {
    success() {
      return this.generalSuccess && this.localSuccess;
    },
    filteredGroups() {
      if (!this.search) return this.groups;
      return this.groups.filter((g) => {
        const regex = new RegExp(`${removeAccents(this.search)}`, 'i');
        return regex.test(removeAccents(g.name));
      });
    },
    ...mapGetters('adminModule', ['groups', 'groupsIsLoading']),
  },
  methods: {
    handleCloseAddGroupModal() {
      this.formerGroupName = '';
      this.modals.newGroup = false;
      this.focusGroup = null;
      this.step = 1;
    },
    triggerGroupUpdate({ group, step = 1 }) {
      this.focusGroup = group;
      this.step = step;
      this.formerGroupName = group.name;
      this.modals.newGroup = true;
    },
    async updateOrCreateGroup(e) {
      if (e) {
        const { name, knowledges: knowledgeIds, reachable } = e;
        if (this.focusGroup) {
          this.localSuccess = await this.updateCompanyGroup({
            id: this.focusGroup.id,
            name,
            knowledgeIds,
            reachable,
          });
        } else {
          this.localSuccess = await this.createCompanyGroup({
            name,
            knowledgeIds,
            reachable,
          });
        }
      }
      this.handleCloseAddGroupModal();
    },
    async deleteGroup(payload) {
      this.localSuccess = await this.deleteCompanyGroup(payload);
    },
    async getGroups() {
      this.search = null;
      this.localSuccess = await this.getCompanyGroups();
    },
    hideSearch(evt) {
      if (
        !!evt.srcElement.classList &&
        Array.from(evt.srcElement.classList).includes('header__search__icon')
      )
        return;
      if (!this.search) this.showSearch = false;
    },
    ...mapActions('adminModule', [
      'getCompanyGroups',
      'updateCompanyGroup',
      'createCompanyGroup',
      'deleteCompanyGroup',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}

.sub-item {
  color: #adb5bd;
}

.user-groups-table {
  :deep() .card {
    border: none;
  }
}

.small-icon-hoverable {
  width: 15px;
  height: 15px;
  padding: 2px;
  color: #8898aa;
  margin-left: 10px;
}
.small-icon-hoverable:hover {
  border-radius: 2px;
  background-color: #e9ecef;
  cursor: pointer;
}

.settings-card-header-description {
  color: #8898aa;
  font-size: 80%;
}

.subtitle {
  text-transform: uppercase;
  color: #adb5bd;
}

.right-divider {
  border-top: 1px solid#e9ecef;
}

.send-invitation {
  max-height: 50vh;
  overflow: auto;
  overflow-x: hidden;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}

.vertical-line {
  border-left: 1px solid lightgrey;
}

.plus-icon {
  padding: 5px;
  border: 1px solid $blue-mayday;
  border-radius: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.plus-icon:hover {
  background-color: rgba($blue-mayday, 0.4);
  color: white;
  cursor: pointer;
}
.plus-icon {
  padding: 5px;
  border: 1px solid $blue-mayday;
  border-radius: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.plus-icon:hover {
  background-color: rgba($blue-mayday, 0.4);
  color: white;
  cursor: pointer;
}

.main-container {
  margin-top: 40px;
  margin-bottom: 40px;
}

.menubar__input {
  margin: 0px;
}

.menububble__form {
  display: inline-block;
}

.menubar__button {
  background-color: Transparent;
  outline: none;
  border: none;
  border-radius: 5px;
}

.menubar__button:hover {
  background-color: #dee2e6;
}

.empty-placeholder {
  color: #ced4da;
}

.options_selection {
  max-width: 20px;
  cursor: pointer;
}

.center-element {
  justify-content: center;
  vertical-align: middle;
  display: flex;
}

.dropdown {
  padding: 0px;
  border-width: 0px;
  box-shadow: none;
  background-color: transparent;
  margin-right: 0px;
  margin: 5px;
}

.dropdown:hover {
  background-color: white;
}

.dropdown-item span {
  margin-left: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.col-1 {
  width: 400px;
}
th {
  font-weight: bold;
}

tr {
  align-items: baseline;
}

td {
  color: #525f7f;
  font-size: 13px !important;
}

.table-header {
  background-color: white;
  align-items: baseline;
  height: 40px;
  text-transform: uppercase;
  font-size: 0.65rem;
}

.header-col {
  color: #8898aa;
}

.no-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.header__search {
  &__icon {
    cursor: pointer;
  }
}
</style>
