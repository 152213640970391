<template>
  <div class="ask-feedback-container">
    <el-tooltip :content="$t('ask.search.like')">
      <div
        class="thumb-container"
        :class="[
          { selected: feedback === 'up' },
          { 'thumb-container__hovered': hover.up },
        ]"
        @click="handleFeedback('up')"
        @mouseover="handleHover('up', true)"
        @mouseleave="handleHover('up', false)"
      >
        <font-awesome-icon
          :icon="[iconStyle.up, 'thumbs-up']"
          :class="[{ selected: feedback === 'up' }]"
        /></div
    ></el-tooltip>
    <el-tooltip :content="$t('ask.search.dislike')">
      <div
        class="thumb-container"
        :class="[
          { selected: feedback === 'down' },
          { 'thumb-container__hovered': hover.down },
        ]"
        @click="handleFeedback('down')"
        @mouseover="handleHover('down', true)"
        @mouseleave="handleHover('down', false)"
      >
        <font-awesome-icon
          :icon="[iconStyle.down, 'thumbs-down']"
          :class="[{ selected: feedback === 'down' }]"
        /></div
    ></el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ask-feedback',
  props: {
    feedback: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hover: {
        up: false,
        down: false,
      },
    };
  },
  computed: {
    statusIsSet() {
      return this.feedback === 'up' || this.feedback === 'down';
    },
    iconStyle() {
      return {
        up: this.feedback === 'up' || this.hover.up ? 'fas' : 'far',
        down: this.feedback === 'down' || this.hover.down ? 'fas' : 'far',
      };
    },
  },
  methods: {
    handleFeedback(status) {
      if (this.statusIsSet) return;
      this.$emit('update-feedback-status', status);
      this.hover[status] = false;
    },
    handleHover(status, hover) {
      if (this.statusIsSet) return;
      this.hover[status] = hover;
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-feedback-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: fit-content;
}
.thumb-container {
  color: $purple-5-mayday;
  background-color: white;
  border-radius: 4px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  &__hovered {
    background-color: $purple-5-mayday;
    color: white;
    cursor: pointer;
  }
}
.thumbs-down {
  font-size: 14px;
}
.thumbs-up {
  font-size: 14px;
}
</style>
