<template>
  <div :class="`${base}`">
    <div :class="[`${base}__grip`]">
      <font-awesome-icon
        :class="[`${base}__grip__icon`]"
        :icon="['fas', 'grip-vertical']"
      />
    </div>
    <div :class="`${base}__main`">
      <div
        :class="[
          `${base}__main__header`,
          { [`${base}__main__header-expand`]: expand },
        ]"
        @click="expand = !expand"
      >
        <span :class="[`${base}__main__header__title`, 'mb-0']">
          <font-awesome-icon
            v-if="workflowAction.library"
            class="mr-2"
            :icon="['fa', 'lock-alt']"
          />
          {{
            workflowAction.library && workflowAction.title
              ? workflowAction.title
              : $t(`${pre}.actions.${workflowAction.type}`)
          }}
        </span>
        <span :class="`${base}__main__header__actions`">
          <font-awesome-icon
            :icon="['fal', 'chevron-down']"
            :class="[
              `${base}__main__header__actions__icon`,
              { [`${base}__main__header__actions__icon-rotate`]: expand },
            ]"
          />
        </span>
      </div>
      <div :class="`${base}__main__body`" v-if="expand">
        <component
          :is="componentByActionType"
          :workflow-action="editableWorkflowAction"
          :content="content"
          @change="($evt) => (editableWorkflowAction = $evt)"
          @save="saveUpdates()"
        />
      </div>
      <div :class="`${base}__main__footer`" v-if="expand">
        <!-- ADD LIBRARY FOOTER -->
        <span
          :class="[
            `${base}__main__footer__actions`,
            { [`${base}__main__footer__actions-left`]: edit.library },
            {
              [`${base}__main__footer__actions-left-valid`]:
                library.title.length,
            },
          ]"
          v-if="libraryEligible"
        >
          <el-tooltip placement="top">
            <div slot="content">{{ $t(`${pre}.library.add`) }}</div>
            <base-button
              v-if="libraryEligible"
              class="action-btn"
              type="secondary"
              size="sm"
              @click="edit.library = !edit.library"
            >
              <font-awesome-icon :icon="['fal', 'bookmark']" />
            </base-button>
          </el-tooltip>
          <el-input
            v-if="edit.library"
            v-model="library.title"
            ref="libraryTitleInput"
            size="mini"
            :placeholder="$t(`${pre}.library.placeholder`)"
            @keyup.native.enter="addLibraryItem"
            @keyup.native.esc="edit.library = !edit.library"
          >
            <template slot="append">
              <span @click="addLibraryItem" style="cursor: pointer">
                <i
                  class="fas fa-spinner fa-spin mr-1"
                  v-if="loader.library"
                ></i>
                {{ $t(`${pre}.library.create`) }}</span
              ></template
            >
          </el-input>
        </span>

        <!-- EDIT LIBRARY FOOTER -->
        <span
          :class="[`${base}__main__footer__actions`]"
          v-if="editLibraryEligible"
        >
          <el-tooltip placement="top">
            <div slot="content">{{ $t(`${pre}.library.edit`) }}</div>
            <base-button
              class="action-btn"
              type="secondary"
              size="sm"
              @click="edit.library = !edit.library"
            >
              <font-awesome-icon :icon="['fal', 'pen']" />
            </base-button>
          </el-tooltip>
        </span>
        <span
          :class="`${base}__main__footer__actions`"
          v-if="edit.library && editLibraryEligible"
        >
          <base-button
            type="secondary"
            size="sm"
            @click="edit.library = !edit.library"
          >
            {{ $t(`${pre}.cancel`) }}
          </base-button>
          <el-popconfirm
            :confirm-button-text="$t(`${pre}.library.update.warning.confirm`)"
            :cancel-button-text="$t(`${pre}.library.update.warning.cancel`)"
            icon="el-icon-info"
            icon-color="red"
            :title="$t(`${pre}.library.update.warning.title`)"
            @confirm="updateLibraryItem()"
          >
            <base-button type="primary" size="sm" slot="reference">
              <i class="fas fa-spinner fa-spin mr-1" v-if="loader.library"></i>
              {{ $t(`${pre}.library.update.title`) }}
            </base-button>
          </el-popconfirm>
        </span>

        <!-- DEFAULT FOOTER -->
        <span :class="`${base}__main__footer__actions`" v-if="!edit.library">
          <el-popconfirm
            :confirm-button-text="$t(`${pre}.delete-warning.confirm`)"
            :cancel-button-text="$t(`${pre}.delete-warning.cancel`)"
            icon="el-icon-info"
            icon-color="red"
            :title="$t(`${pre}.delete-warning.title`)"
            @confirm="deleteAction()"
          >
            <base-button type="danger" size="sm" slot="reference">
              <i class="fas fa-spinner fa-spin mr-1" v-if="loader.delete"></i>
              {{ $t(`${pre}.delete`) }}
            </base-button>
          </el-popconfirm>
          <base-button
            v-if="!workflowAction.library"
            type="primary"
            size="sm"
            @click="saveUpdates()"
            class="ml-1"
          >
            <i class="fas fa-spinner fa-spin mr-1" v-if="loader.save"></i>
            {{ $t(`${pre}.save`) }}
          </base-button>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'workflow-action',
  components: {
    WorkflowAutoStepSelection: () =>
      import('./WorkflowAutomaticTriggers/WorkflowAutoStepSelection'),
    WorkflowInitSession: () =>
      import('./WorkflowAutomaticTriggers/WorkflowInitSession'),
    WorkflowSetContextFromContextSelection: () =>
      import('./WorkflowInputAction/WorkflowSetContextFromContextSelection'),
    WorkflowSetContextFromInput: () =>
      import('./WorkflowInputAction/WorkflowSetContextFromInput'),
    WorkflowSetContextFromForm: () =>
      import('./WorkflowInputAction/WorkflowSetContextFromForm'),
    WorkflowSetContextFromSelect: () =>
      import('./WorkflowInputAction/WorkflowSetContextFromSelect'),
    WorkflowSetContextFromResourceFunction: () =>
      import('./WorkflowInputAction/WorkflowSetContextFromResourceFunction'),
    WorkflowActionLibraryItem: () => import('./WorkflowActionLibraryItem'),
  },
  props: {
    listIndex: {
      type: Number,
    },
    workflowAction: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.editableWorkflowAction = cloneDeep(this.workflowAction);
    if (!this.editableWorkflowAction._id) this.expand = true;
  },
  computed: {
    componentByActionType() {
      switch (this.workflowAction.type) {
        case 'AUTO_STEP_SELECTION':
          return 'WorkflowAutoStepSelection';
        case 'INIT_SESSION':
          return 'WorkflowInitSession';
        case 'SET_CONTEXT_FROM_CONTEXT_SELECTION':
          return 'WorkflowSetContextFromContextSelection';
        case 'SET_CONTEXT_FROM_INPUT':
          return 'WorkflowSetContextFromInput';
        case 'SET_CONTEXT_FROM_SELECT':
          return 'WorkflowSetContextFromSelect';
        case 'SET_CONTEXT_FROM_FORM':
          return 'WorkflowSetContextFromForm';
        case 'SET_CONTEXT_FROM_RESOURCE_FUNCTION':
          return 'WorkflowSetContextFromResourceFunction';
        case 'LIBRARY_ACTION':
          return 'WorkflowActionLibraryItem';
        default:
          return null;
      }
    },
    libraryEligible() {
      return (
        [
          'SET_CONTEXT_FROM_CONTEXT_SELECTION',
          'SET_CONTEXT_FROM_INPUT',
          'SET_CONTEXT_FROM_SELECT',
          'SET_CONTEXT_FROM_FORM',
          'SET_CONTEXT_FROM_RESOURCE_FUNCTION',
        ].includes(this.workflowAction.type) && !this.workflowAction.library
      );
    },
    editLibraryEligible() {
      return !!this.workflowAction.library;
    },
  },
  data() {
    return {
      base: 'workflow-action',
      expand: false,
      pre: 'knowledge.diagnostic-builder.side-bar.workflow.main',
      editableWorkflowAction: {},
      loader: {
        delete: false,
        deleteTimeout: null,
        save: false,
        saveTimeout: null,
        library: false,
        libraryTimeout: null,
      },
      library: {
        title: '',
      },
      edit: {
        library: false,
      },
    };
  },
  methods: {
    saveUpdates() {
      if (this.loader.save) return;
      this.startLoader('save');
      this.$emit('change', this.editableWorkflowAction);
    },
    deleteAction() {
      if (this.loader.delete) return;
      this.startLoader('delete');
      this.$emit('click:delete', this.workflowAction._id);
    },
    startLoader(type) {
      this.loader[type] = true;
      this.loader[`${type}Timeout`] = setTimeout(() => {
        this.stopLoader(type);
        this.sendMessage('error');
      }, 5000);
    },
    stopLoader(type) {
      this.loader[type] = false;
      clearTimeout(this.loader[`${type}Timeout`]);
      this.loader[`${type}Timeout`] = null;
    },
    sendMessage(type) {
      this.$message({
        duration: 2000,
        type,
        message: this.$t(`${this.pre}.message.${type}`),
      });
    },
    // Library Actions
    async addLibraryItem() {
      if (!this.library.title || !this.library.title.length) return;
      this.startLoader('library');
      try {
        await this.addWorkflowActionLibraryItem({
          title: this.library.title,
          type: this.editableWorkflowAction.type,
          payload: this.editableWorkflowAction.payload,
        });
        this.library.title = '';
        this.edit.library = false;
        this.sendMessage('success');
      } catch (_err) {
        this.sendMessage('error');
      } finally {
        this.stopLoader('library');
      }
    },
    async updateLibraryItem() {
      this.startLoader('library');
      try {
        await this.updateWorkflowActionLibraryItem({
          id: this.workflowAction.libraryId,
          item: {
            title: this.workflowAction.title,
            type: this.editableWorkflowAction.type,
            payload: this.editableWorkflowAction.payload,
          },
          formerItem: this.workflowAction,
        });
        this.library.title = '';
        this.edit.library = false;
        this.sendMessage('success');
      } catch (_err) {
        this.sendMessage('error');
      } finally {
        this.stopLoader('library');
      }
    },
    ...mapActions('knowledgeModule', [
      'addWorkflowActionLibraryItem',
      'updateWorkflowActionLibraryItem',
    ]),
  },
  watch: {
    workflowAction() {
      this.editableWorkflowAction = cloneDeep(this.workflowAction);
      this.stopLoader('save');
    },
    'edit.library'() {
      if (!this.edit.library) return;
      this.$nextTick(() => {
        this.$refs['libraryTitleInput'].focus();
      });
    },
  },
  beforeDestroy() {
    this.stopLoader('save');
    this.stopLoader('delete');
  },
};
</script>
<style lang="scss" scoped>
.workflow-action {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 8px;
  &__grip {
    padding-top: 8px;
    &__icon {
      color: $grey-6-mayday;
      &:hover {
        cursor: grab;
        color: $blue-mayday;
      }
    }
  }
  &__main {
    border: 1px solid $grey-5-mayday;
    border-radius: 8px;
    padding: 8px;
    width: 100%;
    margin-left: 4px;
    margin-bottom: 4px;
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 0px 4px;

      &:hover {
        cursor: pointer;
      }

      &-expand {
        padding-bottom: 4px;
        margin-bottom: 8px;
      }

      &__title {
        display: flex;
        flex-direction: row;
        font-weight: bold;
        align-items: center;
      }

      &__actions {
        &__icon {
          transition: transform 250ms ease-in-out;
          &:hover {
            color: $blue-mayday;
            cursor: pointer;
          }
          &-rotate {
            transform: rotate(-180deg);
            color: $blue-mayday;
          }
        }
      }
    }

    &__footer {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      margin-top: 8px;
      padding-top: 4px;
      &__actions {
        padding: 8px;
        display: flex;
        &-left {
          width: 100%;
          &-valid {
            :deep() .el-input-group__append {
              background-color: $blue-mayday;
              border: 1px solid $blue-mayday;
              color: white;
            }
          }
        }
      }
      :deep() .el-input__inner {
        padding-left: 8px;
        padding-right: 8px;
      }
      :deep() .el-input-group__append {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
}
</style>
