<template>
  <div class="content-modal-container">
    <div class="content-modal-base-metrics-container">
      <AnalyticsCard
        class="content-modal-chart-card"
        v-loading="chartIsLoading"
        :title="
          $t(
            `new-dashboard.header.title.analytics-content-views-${chartCardTranslateKey}`,
          )
        "
        :tooltip-content="
          $t(
            `new-dashboard.header.tooltip.analytics-content-views-${chartCardTranslateKey}`,
            { contentTitle: metadata.contentTitle },
          )
        "
      >
        <template v-slot:header-menu>
          <AnalyticsGraphTypeMenu :type.sync="chartTypeModel" />
        </template>
        <component
          v-if="!chartIsLoading && !chartIsEmpty"
          :is="chartComponent"
          :height="250"
          :chart-data="chartData"
          :extra-options="chartOptions"
        ></component>
        <EmptyState
          v-if="chartIsEmpty"
          class="empty-state-container"
          @open-filters-drawer="() => $emit('open-filters-drawer')"
        />
      </AnalyticsCard>
      <AnalyticsCard
        class="content-modal-overview-stats-card"
        :title="$t('new-dashboard.header.title.analytics-usages-modal-stats')"
        :loading="contentStatsIsLoading"
        :tooltip-content="
          $t(`new-dashboard.header.tooltip.analytics-content-overview`, {
            contentTitle: metadata.contentTitle,
          })
        "
      >
        <AnalyticsStatsCard
          v-if="!contentStatsIsLoading"
          class="analytics-content-modal-stats-card"
          title=""
          :stats="contentStats"
          :external-doc-url="externalDocUrl"
        >
          <template v-slot:stat="{ stat }">
            <span>{{ stat }}</span>
          </template>
          <template v-slot:trendText="{ footerStat }">
            <p class="footer-text">
              {{
                $t(
                  `new-dashboard.stat-card.additional-stats-usage.stat-trend-${footerStat}`,
                )
              }}
              {{
                $t(
                  'new-dashboard.stat-card.additional-stats-usage.stat-trend-timeframe',
                )
              }}
            </p>
          </template>
          <template v-slot:averageRating="{ footerStat }">
            <p class="footer-text">
              {{
                $t(
                  `new-dashboard.stat-card.additional-stats-usage.feedback-average-rating`,
                  {
                    averageRating: footerStat,
                  },
                )
              }}
              {{
                $t(
                  'new-dashboard.stat-card.additional-stats-usage.stat-trend-timeframe',
                )
              }}
            </p>
          </template>

          <template v-slot:trendBadge="{ additionalStat }">
            <font-awesome-icon
              :class="additionalStat"
              class="trend-badge"
              :icon="['fad', 'arrow-right']"
            />
          </template>

          <template v-slot:stat-tooltip="{ tooltipStat }">
            <el-popover
              trigger="hover"
              placement="right"
              :disabled="shouldDisableLanguagesPopover(tooltipStat)"
            >
              <LanguagesCounts
                v-if="!shouldDisableLanguagesPopover(tooltipStat)"
                :languagesCounts="tooltipStat"
              />

              <template #reference>
                <font-awesome-icon
                  class="search-icon"
                  :icon="['fad', 'search']"
                />
              </template>
            </el-popover>
          </template>

          <template v-slot:userReturnsRepartition="{ footerStat }">
            <p v-if="isParametric" class="footer-text">
              {{
                $t(
                  'new-dashboard.stat-card.additional-stats-usage.user-returns-repartition',
                  {
                    feedbackCount: footerStat.feedbackCount,
                    satisfactionCount: footerStat.satisfactionCount,
                  },
                )
              }}
            </p>
          </template>
        </AnalyticsStatsCard>
      </AnalyticsCard>
    </div>
    <AnalyticsCard
      v-show="isDiagnostic"
      :loading="diagnosticChartIsLoading"
      class="diagnostic-flow-chart-container"
      :title="
        $t(
          'new-dashboard.header.title.analytics-content-diagnostic-routes-distribution',
        )
      "
      :tooltip-content="
        $t(
          'new-dashboard.header.tooltip.analytics-content-diagnostic-routes-distribution',
          { contentTile: metadata.contentTitle },
        )
      "
    >
      <!-- Allow br tag -->
      <template v-slot:tooltip-content="{ content }">
        <span v-html="sanitize(content)"></span>
      </template>
      <flowy
        class="diagnostic-flow-chart"
        v-if="isDiagnostic && !diagnosticChartIsLoading"
        :nodes="diagnosticChart"
        :options="options"
      >
        <template v-slot:node-title="{ node }">
          <div class="flex flex-row items-center">
            <div class="header-title">
              <span class="font-bold text-md text-dark-blue">{{
                node.data.title
              }}</span>
            </div>
          </div>
        </template>
      </flowy>
    </AnalyticsCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AnalyticsCard from '../../AnalyticsCard';
import AnalyticsGraphTypeMenu from '../../AnalyticsGraphTypeMenu';
import AnalyticsStatsCard from '../../AnalyticsStatsCard';
import LanguagesCounts from '../../LanguagesCounts';
import { sanitize } from '@/plugins/dompurify';

export default {
  props: {
    diagnosticChart: {
      type: Array,
      required: true,
    },
    diagnosticChartIsLoading: {
      type: Boolean,
      required: true,
    },
    diagnosticChartIsEmpty: {
      type: Boolean,
      required: true,
    },
    chartType: {
      type: String,
      required: true,
    },
    chartTypeIsLine: {
      type: Boolean,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    chartIsLoading: {
      type: Boolean,
      required: true,
    },
    chartIsEmpty: {
      type: Boolean,
      required: true,
    },
    isDiagnostic: {
      type: Boolean,
      required: true,
    },
    contentStats: {
      type: Array,
      required: true,
    },
    contentStatsIsLoading: {
      type: Boolean,
      required: true,
    },
    externalDocUrl: {
      type: String,
      required: true,
    },
    metadata: {
      type: Object,
      required: true,
    },
  },

  components: {
    // prettier-ignore
    'LineChart': () => import('@/components/Charts/LineChart'),
    // prettier-ignore
    'DoughnutChart': () => import('@/components/Charts/DoughnutChart'),
    AnalyticsCard,
    AnalyticsGraphTypeMenu,
    AnalyticsStatsCard,
    LanguagesCounts,
  },

  data() {
    return {
      options: {
        hasStats: true,
        isDraggable: false,
        hasMinimap: true,
        isZoomable: true,
      },
      chartOptionsBase: {
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'month',
              },
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
      sanitize: sanitize,
    };
  },

  computed: {
    chartTypeModel: {
      get() {
        return this.chartType;
      },
      set(value) {
        this.handleChartTypeChange(value);
        return value;
      },
      ...mapGetters(['isParametric']),
    },

    chartOptions() {
      const base = this.chartOptionsBase;

      return !this.chartTypeIsLine
        ? Object.assign({}, base, {
            scales: {
              xAxes: [
                {
                  stacked: false,
                  display: false,
                },
              ],
              yAxes: [
                {
                  display: false,
                },
              ],
            },
          })
        : base;
    },

    chartComponent() {
      return this.chartTypeIsLine ? 'LineChart' : 'DoughnutChart';
    },

    chartCardTranslateKey() {
      return this.chartTypeIsLine ? 'evolution' : 'distribution';
    },
  },

  methods: {
    shouldDisableLanguagesPopover(languages) {
      return !languages || !Object.keys(languages).length;
    },
    handleChartTypeChange(value) {
      this.$emit('update:chartType', value);
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-content-modal-stats-card {
  :deep() .stat-container:last-child {
    border-top: 2px solid $grey-2-mayday;
    padding-top: 24px;
  }
}

.footer-text {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: $grey-6-mayday;
}

.content-modal-container {
  .content-modal-base-metrics-container {
    padding-top: 21px;
    display: flex;
    justify-content: space-between;

    .content-modal-chart-card {
      flex-basis: 70%;
    }
  }

  .content-modal-overview-stats-card {
    width: 28%;
    :deep() .analytics-card-body-container {
      padding: 16px;
    }
    :deep() .stat-container {
      padding-bottom: 16px;
    }
  }

  .diagnostic-flow-chart-container {
    margin-top: 24px;
    .diagnostic-flow-chart {
      padding: 24px;
      display: flex;
      justify-content: center;
      :deep() #flowy-tree {
        max-width: 100%;
      }
      :deep() .items-center {
        padding: 4px 8px !important;
      }
      :deep() .font-bold.text-md.text-dark-blue {
        word-break: break-word;
      }
      :deep() .icon {
        max-width: 16px;
        max-height: 16px;
        color: $grey-5-mayday;
        &:hover {
          color: $blue-mayday;
        }
      }
      :deep() .justify-between {
        justify-content: space-between;
      }
      :deep() img {
        display: none;
      }
      :deep() button {
        border: unset;
        &:focus {
          border: unset;
          outline: none;
        }
      }
    }
  }
  .trend-badge {
    width: 90%;
    height: 90%;

    &.up {
      transform: rotate(-45deg);
    }
    &.down {
      transform: rotate(45deg);
    }
  }
}

.header-title {
  justify-content: start;
  width: 100%;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  // Addition lines for 2 line or multiline ellipsis
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  font-weight: bold;
}

.search-icon {
  width: 16px;
  margin-left: 8px;
  cursor: pointer;
}
</style>
