<template>
  <div>
    <div class="title">{{ $t('knowledge.search-admin.suggestions') }}</div>
    <div>
      <div v-for="(result, index) in results" :key="result.id">
        <SuggestionItem
          :item="result"
          :hovered="index === hovered"
          :labelSearchedIds="labelSearchedIds"
          :searchLanguage="searchLanguage"
          :popoverToDisplay="popoverToDisplay"
          @mouseenter.native="onHover(index)"
          @mouseleave.native="hovered = -1"
          @go-to-document="$emit('go-to-document', { ...result, index })"
          @display-explain-popover="handleDisplayPopover"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SuggestionItem from './SuggestionItem';

export default {
  name: 'search-suggestions',
  components: {
    SuggestionItem,
  },
  props: {
    results: {
      type: Array,
      default: () => [],
    },
    labelSearchedIds: { type: Array, default: () => [] },
    searchLanguage: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      hovered: -1,
      popoverToDisplay: '',
    };
  },
  methods: {
    onHover(index) {
      this.hovered = index;
    },
    scrollToSelectedItem(direction) {
      this.$nextTick(() => {
        const el = document.querySelectorAll('[class*="is-hovered"]');
        if (el) {
          el[0].scrollIntoView({ block: direction, inline: 'nearest' });
        }
      });
    },
    handleDisplayPopover(id) {
      if (id === this.popoverToDisplay) this.popoverToDisplay = '';
      else this.popoverToDisplay = id;
    },
  },
  mounted() {
    this._keyListener = (e) => {
      if (
        e.key === 'Enter' &&
        this.hovered >= 0 &&
        this.hovered <= this.results.length
      ) {
        e.preventDefault();
        this.$emit('go-to-document', this.results[this.hovered]);
      } else if (e.key === 'ArrowUp') {
        this.hovered =
          this.hovered > 0 ? this.hovered - 1 : this.results.length - 1;
        this.scrollToSelectedItem('end');
      } else if (e.key === 'ArrowDown') {
        this.hovered =
          this.hovered < this.results.length - 1 ? this.hovered + 1 : 0;
        this.scrollToSelectedItem('start');
      }
    };
    document.addEventListener('keydown', this._keyListener.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener);
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding-left: 16px;
  font-weight: bold;
  font-size: 12px;
  color: $grey-6-mayday;
}
.content-icon {
  max-width: 16px;
  max-height: 16px;
}
</style>
