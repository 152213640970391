import { mapState } from 'vuex';
import debounce from 'lodash.debounce';
import { dragndrop } from 'utils/debounceService';

export default {
  data() {
    return {
      updateTargetFolderNodeDebounce: debounce(function(newTreeNode) {
        dragndrop(newTreeNode);
      }, 5000),
    };
  },
  computed: mapState({
    mixinsTreeNodes: (state) => state.treeNodes,
  }),
  methods: {
    dragAndDropEdgeCases(
      updateNodeDebounce,
      type,
      newTreeNode,
      dragoverNodeId,
      draggedNodeId,
    ) {
      // If type is different from removed, we assess if there is a current dragoverNodeId
      // and a draggedNodeId in order to treat the update as a dragAndDrop OVER a Folder or not.
      // NB: we do not treat the removed event as no matter what kind of dragAndDrop it is,
      // the node is always removed the same way from its source.

      if (dragoverNodeId && draggedNodeId && type !== 'removed') {
        // Here we consider a dragAndDrop OVER a Folder. Hence we:
        //
        // 1. Retrieve this draggedNode from the current folder as it will be added to the
        // draggedOverNode
        newTreeNode.path = newTreeNode.path.filter(
          (id) => id !== draggedNodeId,
        );
        this.$store.dispatch('SOCKET_UPDATED_TREE_NODE', {
          id: newTreeNode.id,
          path: newTreeNode.path,
        });
        updateNodeDebounce({ new_tree_node: newTreeNode });

        // 2. We add the draggedNode to dragoverNode
        const targetFolder = this.mixinsTreeNodes.find(
          (o) => o.id === dragoverNodeId,
        );
        if (targetFolder) {
          targetFolder.path = [draggedNodeId].concat(targetFolder.path);
          this.$store.dispatch('SOCKET_UPDATED_TREE_NODE', {
            id: targetFolder.id,
            path: targetFolder.path,
          });
          this.updateTargetFolderNodeDebounce({ new_tree_node: targetFolder });
        }
      }
    },
  },
};
