<template>
  <div>
    <div v-loading="statsIsLoading" class="sub-header my-2">
      <div class="group-container">
        <span class="subtitle">{{
          $t('new-dashboard.header.title.analytics-usages-modal-group')
        }}</span>
        <div v-if="!deletedAt || groupName" class="group-wrapper">
          <font-awesome-icon class="group-icon" :icon="['fad', 'users']" />
          <span class="group-label">{{ groupName }}</span>
        </div>
        <div v-else class="group-wrapper">
          <span class="group-label">{{
            $t('new-dashboard.table.body.deleted-user-group')
          }}</span>
        </div>
      </div>

      <div class="user-labels-container">
        <span class="subtitle">
          {{ $t('new-dashboard.header.title.analytics-usages-modal-labels') }}
        </span>
        <div v-if="!deletedAt" class="label-tags">
          <LabelTag
            v-for="{ id, title, tagAttributes } in labels"
            :key="id"
            :name="title"
            :icon="tagAttributes.icon"
            :color="tagAttributes.color"
          />
        </div>
        <div v-else class="label-tags">
          <span>{{ $t('new-dashboard.table.body.deleted-user-label') }}</span>
        </div>
      </div>
    </div>
    <div class="upper-modal">
      <AnalyticsCard
        class="graph"
        :title="$t('new-dashboard.header.title.analytics-views')"
        :tooltip-content="
          $t('new-dashboard.header.tooltip.analytics-usages.analytics-views', {
            userName,
          })
        "
        :loading="chartIsLoading"
      >
        <LineChart
          v-if="!chartIsLoading"
          :height="hasContributions ? 250 : 200"
          :chart-data="chartData"
          :extra-options="chartOptions"
        />
      </AnalyticsCard>
      <div class="usage-modal-container stats">
        <AnalyticsCard
          :title="$t('new-dashboard.header.title.analytics-usages-modal-stats')"
          :tooltip-content="
            $t('new-dashboard.header.tooltip.analytics-usages-stats')
          "
        >
          <AnalyticsStatsCard
            v-loading="statsIsLoading"
            class="analytics-usage-stats-card"
            :stats="stats"
            :external-doc-url="externalDocUrl"
          >
            <template v-slot:stat="{ stat }">
              <span>{{ stat }}</span>
            </template>
            <template v-slot:userReturnsRepartition="{ footerStat }">
              <p class="stat-footer">
                {{
                  $t(
                    'new-dashboard.stat-card.additional-stats-usage.user-returns-repartition',
                    {
                      feedbackCount: footerStat.feedbackCount,
                      satisfactionCount: footerStat.satisfactionCount,
                    },
                  )
                }}
              </p>
            </template>

            <template v-slot:trendText="{ footerStat }">
              <p class="stat-footer">
                {{
                  $t(
                    `new-dashboard.stat-card.additional-stats-usage.stat-trend-${footerStat}`,
                  )
                }}
                {{
                  $t(
                    'new-dashboard.stat-card.additional-stats-usage.stat-trend-timeframe',
                  )
                }}
              </p>
            </template>
            <template v-slot:averageRating="{ footerStat }">
              <div v-if="footerStat && footerStat.averageRating">
                <font-awesome-icon :icon="['fas', 'star']" class="star" />
                <span class="stat-footer">
                  {{
                    $tc(
                      `new-dashboard.stat-card.additional-stats-usage.feedback-average-rating`,
                      footerStat.ratingCount,
                      {
                        averageRating: footerStat.averageRating,
                        ratingCount: footerStat.ratingCount,
                      },
                    )
                  }}
                </span>
              </div>
              <span v-else class="stat-footer">
                {{
                  $t(
                    'new-dashboard.stat-card.additional-stats-usage.no-rated-feedback',
                  )
                }}
              </span>
            </template>
            <template v-slot:trendBadge="{ additionalStat }">
              <font-awesome-icon
                :icon="['fad', 'arrow-right']"
                class="trend-badge"
                :class="additionalStat"
              />
            </template>

            <template v-slot:stat-tooltip="{ tooltipStat }">
              <el-popover
                trigger="hover"
                placement="right"
                :disabled="shouldDisableLanguagesPopover(tooltipStat)"
              >
                <LanguagesCounts
                  v-if="!shouldDisableLanguagesPopover(tooltipStat)"
                  :languagesCounts="tooltipStat"
                />

                <template #reference>
                  <font-awesome-icon
                    class="search-icon"
                    :icon="['fad', 'search']"
                  />
                </template>
              </el-popover>
            </template>

            <template v-slot:tooltip-content="{ content }">
              {{ $t(content, { userName }) }}
            </template>
          </AnalyticsStatsCard>
        </AnalyticsCard>
      </div>
    </div>
    <AnalyticsCard
      :loading="tableIsLoading"
      :title="$t('new-dashboard.header.title.analytics-user-usages-by-content')"
      :tooltip-content="
        $t('new-dashboard.header.tooltip.analytics-usages-modal-table', {
          userName,
        })
      "
    >
      <AnalyticsUsageModalTable
        v-if="tableIsReady"
        class="analytics-usage-modal-table"
        :data="tableData"
        :data-options.sync="tableDataOptionsModel"
        :total-count="tableTotalCount"
        :total-pages="tableTotalPages"
        :current-page="tableCurrentPage"
        :external-doc-url="externalDocUrl"
        :external-doc-anchor="externalUsageModalTableDocAnchor"
      />
    </AnalyticsCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AnalyticsStatsCard from '../../AnalyticsStatsCard';
import AnalyticsCard from '../../AnalyticsCard';
import AnalyticsUsageModalTable from '../AnalyticsUsageModalTable';
import LineChart from '@/components/Charts/LineChart';
import { EXTERNAL_DOC_ANCHORS } from '@/store/dashboard/constants';
import LabelTag from '@/components/Settings/LabelTag';
import LanguagesCounts from '../../LanguagesCounts';

export default {
  components: {
    AnalyticsCard,
    AnalyticsStatsCard,
    AnalyticsUsageModalTable,
    LineChart,
    LabelTag,
    LanguagesCounts,
  },

  props: {
    externalDocUrl: {
      type: String,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
    deletedAt: {
      type: String,
    },
    userName: {
      type: String,
      required: true,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    stats: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    tableDataOptions: {
      type: Object,
      required: true,
    },
    tableTotalCount: {
      type: Number,
      required: true,
    },
    tableTotalPages: {
      type: Number,
      required: true,
    },
    tableCurrentPage: {
      type: Number,
      required: true,
    },
    tableIsReady: {
      type: Boolean,
      required: true,
    },
    statsIsLoading: {
      type: Boolean,
      required: true,
    },
    tableIsLoading: {
      type: Boolean,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    chartIsLoading: {
      type: Boolean,
      required: true,
    },
    chartIsEmpty: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['hasContributions']),
    tableDataOptionsModel: {
      get() {
        return this.tableDataOptions;
      },
      set(value) {
        this.$emit('update:tableDataOptions', value);
        return value;
      },
    },
  },

  data() {
    return {
      chartOptions: {
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'month',
              },
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
      externalUsageModalTableDocAnchor: EXTERNAL_DOC_ANCHORS.usageModalTable,
    };
  },

  methods: {
    shouldDisableLanguagesPopover(languages) {
      return !languages || !Object.keys(languages).length;
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 16px;
  font-family: Lato;
  font-style: normal;
  letter-spacing: 0.02em;

  .subtitle {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 900;
    line-height: 14px;
    color: $grey-5-mayday;
  }
  .group-label {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: $grey-8-mayday;
  }
  .group-wrapper {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .group-icon {
      color: $grey-5-mayday;
      margin-right: 15px;
    }
  }
  .user-labels-container {
    .label-tags {
      display: flex;
      column-gap: 8px;
      margin-top: 8px;
    }
  }
}
.upper-modal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .graph {
    flex-basis: 70%;
  }

  .analytics-usage-stats-card {
    padding: 0px 16px;
    :deep() .stat-container {
      margin-top: 0px;
    }
    :deep() .stat-container:not(:first-child) {
      border-top: 2px solid $grey-2-mayday;
    }
    :deep() .stat-wrapper {
      padding: 16px 0px;
    }
  }
  .stats {
    width: 28%;
    .analytics-card-container {
      height: 100%;
    }
    .stat-footer {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 10px;
      letter-spacing: 0.02em;
      color: $grey-6-mayday;
    }
  }
  .trend-badge {
    width: 90%;
    height: 90%;

    &.up {
      transform: rotate(-45deg);
    }
    &.down {
      transform: rotate(45deg);
    }
  }
}

.analytics-usage-modal-table {
  :deep() .analytics-card-body-container {
    padding: 0px 16px;
  }
  :deep() .custom-table {
    padding: 0 8px;
  }
  :deep() .item-count {
    padding: 0px 8px;
  }
  :deep() .usage-table-cell-info {
    margin-left: 8px;
  }
}

.star {
  color: $yellow-mayday;
  width: 12px;
  height: 12px;
}

.search-icon {
  width: 16px;
  margin-left: 8px;
  cursor: pointer;
}
</style>
