<template>
  <div>
    <LazyCustomTable
      :headers="tableHeaders"
      :data="data"
      :options="tableOptions"
      :data-count="totalCount"
      :init-data-options="dataOptions"
      :rows-count="[10]"
      @update-data-options="handleUpdateDataOptions"
    >
      <template v-slot:empty>
        <EmptyState
          :external-doc-url="externalDocUrl"
          anchor-link-name=""
          @open-filters-drawer="() => $emit('open-filters-drawer')"
        />
      </template>

      <template v-slot:content="{ column: { title, type } }">
        <i :class="[generateContentIconClass(type), 'content-icon']"></i>
        <span>{{ title }}</span>
      </template>

      <template v-slot:parents="{ column: parents }">
        <font-awesome-icon
          :icon="['fad', 'folder']"
          class="collection-icon"
        ></font-awesome-icon>
        <el-popover
          v-if="parents && parents.length"
          trigger="hover"
          placement="top-end"
        >
          <div
            class="collection-title"
            v-for="parent in parents"
            :key="parent.id"
          >
            {{ parent.title }}
          </div>
          <template #reference>
            <span>
              {{ parents[0].title }}
              <span v-if="parents.length > 1">...</span>
            </span>
          </template>
        </el-popover>
        <span v-else class="collection-title deleted">
          {{ $t('new-dashboard.table.body.no-collection') }}</span
        >
      </template>

      <template v-slot:views="{ column: { count, trend }, row: { languages } }">
        <el-popover
          trigger="hover"
          placement="right"
          :disabled="shouldDisableLanguagesPopover(languages)"
        >
          <LanguagesCounts
            v-if="!shouldDisableLanguagesPopover(languages)"
            :languagesCounts="languages"
          />
          <template #reference>
            <div class="view-container">
              <font-awesome-icon
                :icon="['fal', 'arrow-right']"
                class="views-icon"
                :class="trend"
              />
              <span class="usage-table-cell-info">{{ count }}</span>
            </div>
          </template>
        </el-popover>
      </template>
    </LazyCustomTable>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import LazyCustomTable from '@/components/Tables/LazyCustomTable';
import EmptyState from '../EmptyState';
import LanguagesCounts from '../LanguagesCounts';

export default {
  components: {
    LazyCustomTable,
    EmptyState,
    LanguagesCounts,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
    dataOptions: {
      type: Object,
      required: true,
    },
    externalDocUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableHeaders: [
        {
          prop: 'content',
          path: 'content.title',
          key: 'label',
          label: this.$t(
            'new-dashboard.table.header.analytics-contents.content',
          ),
          sortable: true,
          template: true,
        },
        {
          prop: 'parents',
          key: 'collection',
          width: 400,
          label: this.$t(
            'new-dashboard.table.header.analytics-contents.collection',
          ),
          template: true,
        },
        {
          prop: 'views',
          key: 'total_count',
          label: this.$t('new-dashboard.table.header.analytics-contents.views'),
          sortable: true,
          template: true,
        },
      ],
      tableOptions: {
        search: false,
        download: false,
        showRowsCount: false,
        showItemCount: true,
        bulkActions: false,
        pagination: true,
        expandableRows: false,
      },
    };
  },
  methods: {
    shouldDisableLanguagesPopover(languages) {
      return !languages || !Object.keys(languages).length;
    },
    handleUpdateDataOptions(options) {
      this.$emit('update:dataOptions', options);
    },

    generateContentIconClass(type) {
      const iconType = type ? type.toLowerCase() : 'article';

      return `${iconType}-icon`;
    },
  },
};
</script>

<style lang="scss" scoped>
.view-container {
  width: fit-content;
  padding-right: 8px;
}

.article-icon {
  background-image: url('~@/assets/article-icon-v2.1.svg');
  background-size: cover;
  background-position: center;
}
.diagnostic-icon {
  background-image: url('~@/assets/diag-icon-v2.1.svg');
  background-size: cover;
  background-position: center;
}

.content-icon {
  display: inline-block;
  margin-right: 10px;
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.collections-cell {
  display: flex;
  align-items: center;
}

.collection-title.deleted {
  color: $grey-5-mayday;
  font-style: italic;
}

.collection-icon {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}
:deep() .collections > .cell {
  white-space: nowrap;
}

.views-icon {
  &.flat {
    color: rgba($yellow-mayday, 0.8);
  }
  &.up {
    transform: rotate(-45deg);
    color: rgba($green-mayday, 0.8);
  }
  &.down {
    transform: rotate(45deg);
    color: rgba($red-mayday, 0.8);
  }
}
</style>
