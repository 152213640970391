var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"collapse-section"},[_c('div',{staticClass:"col-12 d-flex product-viewer-collapsable justify-content-between pr-md-0 my-2 align-items-center"},[_c('p',{staticClass:"text-uppercase section-title",on:{"click":function($event){_vm.productNotificationsItemsShow = !_vm.productNotificationsItemsShow}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fad', 'bells']}}),_vm._v(" "+_vm._s(_vm.$t('knowledge.product.alerts'))+" ")],1),_c('div',[(!_vm.isMaydayWeb)?_c('el-tooltip',{staticClass:"group-tooltip",attrs:{"content":_vm.$t('knowledge.modals.notifications.tooltip'),"placement":"top"}},[_c('button',{staticClass:"product__button",on:{"click":function($event){$event.preventDefault();return _vm.sendOpenCreateProductNotificationModalEvent.apply(null, arguments)}}},[_c('font-awesome-icon',{staticClass:"trigger-alert-modal",attrs:{"icon":['fad', 'plus-circle']}})],1)]):_vm._e(),_c('button',{class:[
          'product__button',
          _vm.productNotificationsItemsShow ? null : 'product__button--collapsed',
        ],attrs:{"aria-expanded":_vm.productNotificationsItemsShow ? 'true' : 'false',"aria-controls":"productAlertsItems"},on:{"click":function($event){_vm.productNotificationsItemsShow = !_vm.productNotificationsItemsShow}}},[_c('i',{class:[
            'product__toogle-icon',
            'fa',
            _vm.productNotificationsItemsShow
              ? 'fa-caret-square-down'
              : 'fa-caret-square-right',
          ]})])],1)]),(_vm.notifications)?_c('b-collapse',{attrs:{"id":"productAlertsItems"},model:{value:(_vm.productNotificationsItemsShow),callback:function ($$v) {_vm.productNotificationsItemsShow=$$v},expression:"productNotificationsItemsShow"}},[(!_vm.notifications.length)?_c('p',{staticClass:"ml-3 empty-state-alert"},[_vm._v(" Il n'y a pas d'alertes sur ce produit ")]):_vm._e(),_c('ul',{class:[
        'product__list px-2',
        _vm.productNotificationsItemsShow ? 'show' : '',
      ],attrs:{"id":"product__alerts__items collapse"}},_vm._l((_vm.notifications),function(notification){return _c('li',{key:notification.id,class:[
          'product__alerts__item',
          notification.urgent ? 'product__alerts__item--urgent' : '',
        ]},[_c('base-alert',{ref:"alert",refInFor:true,staticClass:"text-left mb-2 d-flex align-items-between product-alert",attrs:{"type":notification.urgent ? 'danger' : 'warning',"icon":notification.urgent ? 'fa fa-exclamation-circle' : 'fa fa-bell'},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('div',{staticClass:"d-flex inner-text-alert justify-content-between align-items-center"},[_c('div',{staticClass:"alert-body"},[_c('strong',[_vm._v(_vm._s(notification.title))]),_c('span',{staticClass:"product__alerts__item__details mb-0",domProps:{"innerHTML":_vm._s(_vm.sanitize(notification.description))}})]),_c('el-tooltip',{attrs:{"placement":"top","effect":"dark"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._t("tooltip-content",function(){return [_c('span',{staticClass:"tooltip-icon"},[_vm._v(_vm._s(_vm.$t('knowledge.modals.notifications.delete')))])]})]},proxy:true}],null,true)},[_c('font-awesome-icon',{staticClass:"modal-menu-icon",attrs:{"icon":['fad', 'trash']},on:{"click":function($event){return _vm.sendDeleteNotificationEvent(notification.id)}}})],1)],1)]},proxy:true}],null,true)})],1)}),0)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }