<template>
  <div v-loading="loading" class="thread__container">
    <div class="thread__wrapper">
      <!-- THREAD DROPDOWN HEADER -->
      <button
        class="thread__header"
        @click="displayContentThread"
        :class="{ 'thread__header-active': isDisplay }"
      >
        <div class="thread__header-info">
          <div class="thread__header-title">{{ $t('threads.title') }}</div>
          <el-tooltip
            placement="top"
            :content="
              hasOpenContentThread
                ? $t('threads.tooltip.active')
                : $t('threads.tooltip.inactive')
            "
          >
            <div
              class="thread__header-badge"
              :class="{
                'thread__header-badge-active': hasOpenContentThread,
              }"
            ></div>
          </el-tooltip>
        </div>
        <font-awesome-icon
          :icon="['fal', 'chevron-down']"
          class="thread__header-icon"
          :class="{ 'thread__header-icon-rotate': isDisplay }"
        />
      </button>

      <!-- THREAD SECTION -->
      <div v-if="isDisplay">
        <!-- TABS -->
        <ParametricContentThreadsTabs
          :focus-status="focusStatus"
          @focus-status="focusStatus = $event"
          @update-status="initUpdateStatus($event, openContentThread)"
        />

        <div class="thread__content">
          <!-- OPEN -->
          <div v-if="focusStatus === 'OPEN'">
            <ThreadTimeline
              v-if="hasOpenContentThread && displayScrollBar"
              :messages="openContentThread.messages"
              :scroll="scroll"
              @drag="handleDrag"
            />
            <div
              v-if="hasOpenContentThread"
              id="open-thread-messages"
              class="thread__messages"
              :class="{ thread__scrollbar: displayScrollBar }"
            >
              <ThreadMessage
                v-for="(message, index) in openContentThread.messages"
                :key="`${message.id}-${message.updatedAt}`"
                :index="index"
                :editing-message="editingMessage"
                :deleting-message="deletingMessage"
                :contributors="openContentThreadContributors"
                :is-focused="focusId === message.id"
                v-bind="message"
                :message-attachments="message.attachments"
                @init-edit="initEditMessage"
                @confirm-edit="handleEditMessage"
                @cancel-edit="cancelEditMessage"
                @init-delete="initDeleteMessage"
                @confirm-delete="handleDeleteMessage"
                @cancel-delete="cancelDeleteMessage"
                @vote="handleVoteMessage"
              />
            </div>
            <!-- EMPTY STATE -->
            <div v-else class="thread__content-empty">
              <div class="thread__content-empty-title">
                {{ $t('threads.messages.empty.title') }}
              </div>
              <div class="thread__content-empty-description">
                {{ $t('threads.messages.empty.description') }}
              </div>
              <el-button type="primary" size="mini" @click="focusInput">
                {{ $t('threads.messages.empty.button') }}
              </el-button>
            </div>
          </div>

          <!-- OTHERS -->
          <div v-else class="thread__messages" id="thread-messages">
            <div
              v-for="thread in filteredContentThreads(focusStatus)"
              :key="thread.id"
              :id="`thread-${thread.id}`"
              class="thread__dropdown-wrapper"
            >
              <ThreadDropdown
                :messages="thread.messages"
                :contributors="thread.contributors"
                :type="focusStatus"
                :is-focused="focusId === thread.id"
                @update-status="initUpdateStatus($event, thread)"
              />
            </div>
          </div>

          <!-- UPDATING STATUS -->
          <div v-if="updatingStatus" class="thread__content-delete">
            <div v-if="ongoingUpdate" class="thread__content-delete-loader">
              <font-awesome-icon :icon="['fal', 'spinner-third']" spin />
            </div>
            <div v-else class="thread__content-delete-info">
              <div class="thread__content-delete-confirm">
                <font-awesome-icon :icon="['fal', 'check']" />
                <span>{{ $t('threads.update-statuses.confirm') }}</span>
              </div>
              <el-button type="primary" size="mini" @click="cancelUpdateStatus">
                {{ $t('threads.update-statuses.cancel') }}
              </el-button>
            </div>
          </div>
        </div>

        <div v-show="focusStatus === 'OPEN'" class="thread__input-wrapper">
          <ThreadInput
            ref="input"
            @send-message="handleSendMessage"
            :disabled="focusStatus !== 'OPEN'"
            :creating="creatingMessage"
            :autofocus="hasOpenContentThread"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThreadInput from '@/components/Threads/ThreadInput';
import ParametricContentThreadsTabs from './ParametricContentThreadsTabs';
import ThreadMessage from '@/components/Threads/ThreadMessage';
import ThreadDropdown from '@/components/Threads/ThreadDropdown';
import ThreadTimeline from '@/components/Threads/ThreadTimeline';

import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'parametric-content-thread',
  props: {
    contentId: {
      type: String,
      default: '',
    },
  },
  components: {
    ParametricContentThreadsTabs,
    ThreadMessage,
    ThreadInput,
    ThreadDropdown,
    ThreadTimeline,
  },
  data() {
    return {
      display: false,
      loading: false,
      focusStatus: 'OPEN',
      focusId: null,
      editingMessage: null,
      deletingMessage: null,
      creatingMessage: false,
      updatingStatus: null,
      updatingThread: null,
      ongoingUpdate: false,
      timeout: null,
      scroll: 0,
      displayScrollBar: false,
    };
  },
  async created() {
    await this.init();
  },
  beforeDestroy() {
    const openThreadMessages = document.getElementById('open-thread-messages');
    if (openThreadMessages)
      openThreadMessages.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    openContentThreadContributors() {
      if (!this.openContentThread || !this.openContentThread.contributors)
        return {};
      return Object.fromEntries(
        this.openContentThread.contributors.map((c) => [c.id, c]),
      );
    },
    isDisplay() {
      return this.displayContentThreads.includes(this.contentId);
    },
    hasOpenContentThread() {
      return !!(
        this.openContentThread && this.openContentThread.messages.length
      );
    },
    ...mapGetters('threadModule', [
      'contentThreads',
      'openContentThread',
      'filteredContentThreads',
      'displayContentThreads',
    ]),
    ...mapGetters('knowledgeModule', ['editingLanguage']),
  },
  methods: {
    async init() {
      this.loading = true;
      this.focusStatus = 'OPEN';
      this.deletingMessage = null;
      this.editingMessage = null;
      this.updatingStatus = null;
      this.updatingThread = null;
      await this.getAllContentThread();
      await this.getContributorIds();
      this.loading = false;
      this.focusThread();
    },
    focusThread() {
      const { threadId, messageId } = this.$route.query;
      if (threadId) {
        const thread = this.contentThreads.find(({ id }) => id === threadId);
        if (!thread) return;

        // Open dropdown
        if (!this.isDisplay) this.displayContentThread();

        // Focus status
        const { status } = thread;
        this.focusStatus = status;

        // Focus thread/message + scroll
        setTimeout(() => {
          const containerId =
            status === 'OPEN' ? 'open-thread-messages' : 'thread-messages';
          const elementId =
            status === 'OPEN' ? `message-${messageId}` : `thread-${threadId}`;

          const containerEl = document.getElementById(containerId);
          const element = document.getElementById(elementId);
          if (containerEl && element) {
            containerEl.scrollTo({
              top: element.offsetTop - 12,
              behaviour: 'smooth',
            });
          }

          this.focusId = status === 'OPEN' ? messageId : threadId;
        }, 1000);

        setTimeout(() => {
          // es-lint here because we spread to remove messageId and threadId
          // eslint-disable-next-line no-unused-vars
          const { messageId, threadId, ...query } = this.$route.query;

          this.$router.replace({ query });
        }, 2000);
      }
    },
    focusMessages() {
      if (!this.openContentThread || !this.openContentThread.messages.length)
        return;
      setTimeout(() => {
        const element = document.getElementById('open-thread-messages');
        if (!element) return;

        element.addEventListener('scroll', this.handleScroll);
        this.displayScrollBar = element.scrollHeight > element.clientHeight;

        if (this.displayScrollBar) {
          element.scrollTo({ top: element.scrollHeight, behaviour: 'smooth' });
          this.handleScroll({ target: element });
        }
      }, 100);
    },
    handleScroll(e) {
      const { scrollHeight, clientHeight, scrollTop } = e.target;
      this.scroll = scrollTop / (scrollHeight - clientHeight);
    },
    handleDrag(y) {
      const element = document.getElementById('open-thread-messages');
      if (!element) return;
      element.scrollTo({
        top: y * (element.scrollHeight - element.clientHeight),
        behaviour: 'smooth',
      });
    },
    focusInput() {
      this.$refs.input.focus();
    },
    displayContentThread() {
      return this.updateDisplayContentThread(this.contentId);
    },
    // CREATE
    parseMentions(body) {
      if (!body) return [];
      const domParser = new DOMParser().parseFromString(body, 'text/html');

      const inlineMentions = domParser.querySelectorAll('.inline-mention');
      const resolveMentions = Array.from(inlineMentions).map((el) => {
        return {
          targetId: el.attributes['data-node-id'].value,
          targetType: el.attributes['data-node-type'].value,
        };
      });

      return [...new Set(resolveMentions)];
    },
    async handleSendMessage({ body, attachments }) {
      if (!body && !attachments.length) return;
      if (!this.openContentThread) {
        return this.handleCreateContentThread({ body, attachments });
      }

      return this.handleCreateMessage({ body, attachments });
    },
    async handleCreateContentThread({ body, attachments }) {
      this.creatingMessage = true;
      await this.createContentThread({
        body,
        mentions: this.parseMentions(body),
        attachments,
      });
      this.creatingMessage = false;
    },
    async handleCreateMessage({ body, attachments }) {
      const threadId = this.openContentThread.id;
      this.creatingMessage = true;
      await this.createContentThreadMessage({
        threadId,
        body,
        mentions: this.parseMentions(body),
        attachments,
      });
      this.creatingMessage = false;
      this.focusMessages();
    },
    // EDIT
    initEditMessage(id) {
      this.editingMessage = id;
    },
    cancelEditMessage() {
      this.editingMessage = null;
    },
    async handleEditMessage({ body, attachments }) {
      const messageId = this.editingMessage;
      if (!messageId) return;

      const threadId = this.openContentThread.id;
      await this.editContentThreadMessage({
        threadId,
        messageId,
        body,
        mentions: this.parseMentions(body),
        attachments,
      });
      this.cancelEditMessage();
    },
    // DELETE
    initDeleteMessage(id) {
      this.deletingMessage = id;
    },
    cancelDeleteMessage() {
      this.deletingMessage = null;
    },
    async handleDeleteMessage() {
      if (!this.deletingMessage) return;

      const threadId = this.openContentThread.id;
      await this.deleteContentThreadMessage({
        threadId,
        messageId: this.deletingMessage,
      });

      this.cancelDeleteMessage();
    },
    // STATUS
    initUpdateStatus(status, thread) {
      this.updatingStatus = status;
      this.updatingThread = thread;
      this.ongoingUpdate = true;

      window.setTimeout(() => {
        this.ongoingUpdate = false;
      }, 1000);
    },
    cancelUpdateStatus() {
      this.ongoingUpdate = false;
      this.updatingStatus = null;
      this.updatingThread = null;
      clearTimeout(this.timeout);
    },
    async handleUpdateStatus() {
      if (!this.updatingStatus || !this.updatingThread) return;

      const threadId = this.updatingThread.id;
      await this.updateContentThreadStatus({
        threadId,
        status: this.updatingStatus,
      });

      if (
        this.focusStatus !== 'OPEN' &&
        !this.filteredContentThreads(this.updatingThread.status).length
      ) {
        this.focusStatus = this.updatingStatus;
      }

      this.cancelUpdateStatus();
    },
    // VOTE
    async handleVoteMessage(messageId) {
      const threadId = this.openContentThread.id;
      await this.voteContentThreadMessage({
        threadId,
        messageId,
      });
    },
    ...mapActions('threadModule', [
      'createContentThread',
      'createContentThreadMessage',
      'editContentThreadMessage',
      'voteContentThreadMessage',
      'deleteContentThreadMessage',
      'updateContentThreadStatus',
      'getAllContentThread',
      'updateDisplayContentThread',
    ]),
    ...mapActions('knowledgeModule', ['getContributorIds']),
  },
  watch: {
    focusStatus: {
      handler(newValue) {
        this.focusId = null;
        if (newValue === 'OPEN') {
          this.focusMessages();
        }
      },
    },
    openContentThread: {
      immediate: true,
      deep: true,
      handler() {
        if (this.focusStatus === 'OPEN') {
          this.focusMessages();
        }
      },
    },
    isDisplay(newValue) {
      if (newValue && this.focusStatus === 'OPEN') {
        this.deletingMessage = null;
        this.editingMessage = null;
        this.updatingStatus = null;
        this.updatingThread = null;
        this.focusMessages();
      }
    },
    '$route.query'() {
      this.focusThread();
    },
    ongoingUpdate(newValue) {
      if (!newValue && this.updatingStatus) {
        this.timeout = setTimeout(() => {
          this.handleUpdateStatus();
        }, 2000);
      }
    },
    async contentId() {
      await this.init();
    },
    async editingLanguage() {
      await this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.thread__container {
  margin-bottom: 16px;
}

.thread__wrapper {
  border: 1px solid $grey-3-mayday;
  border-radius: 4px;
}

.thread__scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.thread__scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: relative;
  width: calc(100% - 64px);
}

.thread__header {
  margin: 0;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: $grey-1-mayday;
  color: $grey-8-mayday;
  transition: background-color 250ms ease-in-out;
  width: 100%;

  &-icon {
    font-size: 12px;
    transition: transform 250ms ease-in-out;
    &:hover {
      color: $blue-mayday;
    }
    &-rotate {
      transform: rotate(-180deg);
      color: $blue-mayday;
    }
  }

  &:hover,
  &-active {
    color: black;
  }

  &-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &-title {
    font-weight: 700;
    font-size: 14px;
  }

  &-badge {
    content: '';
    width: 10px;
    height: 10px;
    background-color: $grey-6-mayday;
    border-radius: 50%;
    &-active {
      background-color: $blue-mayday;
    }
  }
}

.thread__content {
  position: relative;

  &-delete {
    z-index: 2;
    position: absolute;
    inset: 0;
    background-color: $grey-1-mayday;
    color: $blue-mayday;
    animation: fade-in 250ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    &-loader {
      font-size: 24px;
    }
    &-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: fade-in 250ms ease-in-out;
    }
    &-confirm {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      font-weight: 700;
      color: black;
      margin-bottom: 8px;
    }
  }

  &-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 200px;
    width: 300px;
    margin: 16px auto;
    gap: 4px;

    &-title {
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }

    &-description {
      text-align: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: $grey-6-mayday;
      margin-bottom: 8px;
    }
  }
}

.thread__messages {
  padding: 12px;
  max-height: 200px;
  overflow: auto;
}

.thread__dropdown-wrapper + .thread__dropdown-wrapper {
  margin-top: 8px;
}

.thread__input-wrapper {
  padding: 0 12px 12px 12px;
}
</style>
