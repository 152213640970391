<template>
  <div class="mt-2">
    <!-- UPLOADED URL -->
    <div v-if="isEmpty || !fileUrl" class="empty-state">
      {{
        $t('public-knowledge.appearances.items.code-custom.custom-css.empty')
      }}
    </div>
    <div v-else>
      <a class="download-link" :href="fileUrl" target="_blank">
        <font-awesome-icon :icon="['fas', 'download']" />
        {{
          $t(
            'public-knowledge.appearances.items.code-custom.custom-css.download',
          )
        }}
      </a>
    </div>

    <!-- CHOOSE FILE -->
    <el-button @click="upload = true" type="primary" size="small" class="mt-2">
      {{
        $t(
          'public-knowledge.appearances.items.code-custom.custom-css.configure',
        )
      }}
    </el-button>

    <!-- MODAL -->
    <UploadFilesModal
      v-if="upload"
      :display="upload"
      :url="fileUrl"
      @close="upload = false"
      @uploaded-file="handleFileUrl"
      :accept="element.accept"
      :template-url="element.templateUrl"
      :callout="element.callout"
      :file-name="element.fileName"
    />
  </div>
</template>

<script>
import UploadFilesModal from '@/components/Modals/UploadFilesModal';
export default {
  name: 'FileInput',
  components: {
    UploadFilesModal,
  },
  data() {
    return {
      fileUrl: '',
      upload: false,
    };
  },
  props: {
    element: Object,
    elementValueDisplay: String,
    isEmpty: Boolean,
  },
  created() {
    this.isModifying = this.isEmpty;
    if (!this.isEmpty) {
      this.fileUrl = this.element.renderMethod
        ? this.element.renderMethod(this.elementValueDisplay)
        : this.elementValueDisplay;
    }
  },
  methods: {
    handleFileUrl(url) {
      this.fileUrl = url;
      return this.newValue();
    },
    newValue() {
      const toSend = this.element.formatMethod
        ? this.element.formatMethod(this.fileUrl)
        : this.fileUrl;

      if (toSend || toSend === '') {
        this.$emit(toSend === '' ? 'delete-element' : 'new-element', toSend);
        this.isModifying = toSend === '' ? true : false;
        this.notifyRegistered(true);
      } else {
        this.notifyRegistered(false);
      }
    },
    notifyRegistered(done) {
      this.$message({
        message: this.$t(
          `${
            done
              ? 'public-knowledge.registered-confirmation-message'
              : 'public-knowledge.not-registered-confirmation-message'
          }`,
        ),
        type: `${done ? 'success' : 'fail'}`,
      });
    },
  },
  watch: {
    elementValueDisplay() {
      if (this.isEmpty) this.fileUrl = '';
      else
        this.fileUrl = this.element.renderMethod
          ? this.element.renderMethod(this.elementValueDisplay)
          : this.elementValueDisplay;
    },
  },
};
</script>

<style lang="scss" scoped>
.download-link {
  font-size: 14px;
  color: $blue-mayday;
  &:hover {
    text-decoration: underline;
  }
}

.empty-state {
  font-size: 14px;
  color: $grey-7-mayday;
}
</style>
