var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AnalyticsModal',{attrs:{"visible":_vm.show},on:{"close":_vm.hideContentModal},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.contentStatsIsLoading),expression:"contentStatsIsLoading"}],staticClass:"content-title-container"},[_c('div',{staticClass:"title-wrapper"},[_c('i',{class:[_vm.isDiagnostic ? 'diagnostic-icon' : 'article-icon']}),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.contentMetadata.contentTitle))])])])]},proxy:true},{key:"body",fn:function(){return [_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.contentStatsIsLoading),expression:"contentStatsIsLoading"}],staticClass:"subtitle-wrapper"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('font-awesome-icon',{staticClass:"collection-icon",attrs:{"icon":['fas', 'folder']}}),_c('span',{staticClass:"collection-name"},[_vm._v(_vm._s(_vm.contentMetadata.parentTitle))])],1),(_vm.satisfaction.count)?_c('div',{class:[
          'satisfaction-container',
          _vm.satisfaction.rate >= 50 ? 'liked' : 'disliked',
        ]},[_c('font-awesome-icon',{staticClass:"satisfaction-icon",attrs:{"icon":[
            'fal',
            _vm.satisfaction.rate >= 50 ? 'thumbs-up' : 'thumbs-down',
          ]}}),_vm._v(" "+_vm._s(_vm.clamp(_vm.satisfaction.rate, 0, 100) || 0)+"% "),_c('el-tooltip',{attrs:{"content":`${_vm.satisfaction.up}/${_vm.satisfaction.count}`,"placement":"top"}},[_c('font-awesome-icon',{staticClass:"satisfaction-icon",attrs:{"icon":['fal', 'info-circle']}})],1)],1):_vm._e()]),(_vm.show)?_c('AnalyticsContentModalBody',{attrs:{"is-diagnostic":_vm.isDiagnostic,"diagnostic-chart":_vm.diagnosticChart,"diagnostic-chart-is-loading":_vm.diagnosticChartIsLoading,"diagnostic-chart-is-empty":_vm.diagnosticChartIsEmpty,"chart-type":_vm.chartTypeModel,"chart-type-is-line":_vm.chartTypeIsLine,"chart-is-empty":_vm.chartIsEmpty,"chart-is-loading":_vm.chartIsLoading,"chart-data":_vm.chartData,"content-stats":_vm.contentStats,"content-stats-is-loading":_vm.contentStatsIsLoading,"external-doc-url":_vm.externalDocUrl,"metadata":_vm.contentMetadata},on:{"update:chartType":function($event){_vm.chartTypeModel=$event},"update:chart-type":function($event){_vm.chartTypeModel=$event}}}):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }