<template>
  <div class="update-user-role-modal">
    <modal :show.sync="localDisplay">
      <div class="row d-flex mt-5 justify-content-center">
        <div class="col-11 text-center">
          <modal-header
            :style-name="{ backgroundColor: '#0081F91A' }"
            class="mb-3"
          >
            <div slot="icon" class="d-inline-flex">
              <font-awesome-icon
                class="icon blue-mayday"
                :icon="['fas', 'user-tag']"
              />
            </div>
            <div class="mt-3">
              <div>
                <p class="mb-0 font-weight-bold">
                  {{
                    $tc(
                      'components.modals.update-user-role.explaination',
                      multipleUpdate,
                    )
                  }}
                </p>
              </div>
              <el-divider class="my-3"></el-divider>
              <small class="text-primary">
                {{ $t('components.modals.update-user-role.description') }}
              </small>
            </div>
          </modal-header>
          <div class="flex-column d-flex justify-content-center subcontent">
            <p class="my-3">
              {{ $t('components.modals.update-user-role.action') }}
            </p>
            <div class="text-center mb-4 w-100" v-if="roles.length">
              <el-select
                class="large-select"
                multiple
                v-model="localRoleId"
                :placeholder="
                  $t(
                    'components.modals.update-user-role.role-select-placeholder',
                  )
                "
              >
                <el-option
                  v-for="role in roles"
                  :key="role.id"
                  :label="role.name"
                  :value="role.id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div class="row"></div>
      <template slot="footer">
        <el-button type="danger" size="small" @click="$emit('close')">{{
          $t('components.modals.update-user-role.cancel-button')
        }}</el-button>
        <el-button
          type="primary"
          outline
          size="small"
          @click="handleUpdateRole"
        >
          <span
            v-if="isLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-else>{{
            $t('components.modals.update-user-role.confirm-button')
          }}</span>
        </el-button>
      </template>
    </modal>
  </div>
</template>
<script>
import Modal from '@/components/Modal';
import ModalHeader from '@/components/Modals/ModalHeader';

export default {
  name: 'update-user-role-modal',
  components: {
    Modal,
    ModalHeader,
  },
  props: {
    roles: Array,
    focusRoleId: { type: Array },
    display: Boolean,
    multipleUpdate: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    if (this.focusRoleId) {
      this.localRoleId = this.focusRoleId[0];
    }
  },
  data() {
    return {
      localDisplay: this.display,
      localRoleId: [],
      isLoading: false,
    };
  },
  methods: {
    handleUpdateRole() {
      if (this.localRoleId.length)
        this.$emit('update-user-role', this.localRoleId);
      else
        this.$message({
          message: this.$t(
            'settings.users-administration.drawer.main.select-role',
          ),
          type: 'error',
          center: true,
        });
    },
  },

  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.content-primary-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.05);
}

.blue-mayday {
  color: $blue-mayday;
}

.large-select {
  width: 90%;
}

.icon {
  width: 24px;
  height: 24px;
}

.subcontent {
  border: 1px solid $blue-mayday;
  border-radius: 2px;
}
</style>
