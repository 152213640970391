var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"atg-modal__background"},[_c('div',{staticClass:"atg-modal"},[_c('div',{staticClass:"atg-modal__close",on:{"click":function($event){return _vm.$emit('close')}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'times']}})],1),_c('div',{staticClass:"atg-modal__header"},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'sync-alt']}}),_c('span',[_vm._v(_vm._s(_vm.$t('knowledge.modals.article-to-guide.title')))]),_c('AiBadge')],1),_c('div',{staticClass:"atg-modal__body"},[_c('div',[_c('div',{staticClass:"atg-modal__body__title"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.modals.article-to-guide.text.title'))+" ")]),_c('div',{staticClass:"atg-modal__body__description"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.modals.article-to-guide.text.description'))+" ")])]),_c('div',{class:[
          'atg-modal__body__input',
          { error: !!_vm.getPromptError && !_vm.loading },
          { loading: _vm.loading },
        ]},[(_vm.loading)?_c('font-awesome-icon',{attrs:{"icon":['fas', 'spinner-third'],"spin":""}}):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.prompt),expression:"prompt"}],attrs:{"disabled":_vm.loading,"placeholder":_vm.$t(
              `knowledge.modals.article-to-guide.${
                _vm.loading ? 'loading' : 'placeholder'
              }`,
            ),"type":"text"},domProps:{"value":(_vm.prompt)},on:{"input":function($event){if($event.target.composing)return;_vm.prompt=$event.target.value}}}),(!_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.getPromptError))]):_vm._e()],1),_c('div',{staticClass:"atg-modal__body__infos"},[_c('div',{class:[
            'atg-modal__body__infos__collapse',
            { expanded: _vm.collapseExpanded },
          ]},[_c('div',{staticClass:"atg-modal__body__infos__collapse__header",on:{"click":function($event){_vm.collapseExpanded = !_vm.collapseExpanded}}},[_c('div',[_c('font-awesome-icon',{attrs:{"icon":['fal', 'info-circle']}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('knowledge.modals.article-to-guide.collapse.title'))+" ")])],1),_c('font-awesome-icon',{attrs:{"icon":['fal', 'caret-right']}})],1),_c('div',{staticClass:"atg-modal__body__infos__collapse__body"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.modals.article-to-guide.collapse.content'))+" ")])]),_c('div',{staticClass:"atg-modal__body__infos__callout"},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'exclamation-triangle']}}),_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.$t('knowledge.modals.article-to-guide.callout.title'))+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t('knowledge.modals.article-to-guide.callout.content')))])])],1)])]),_c('div',{staticClass:"atg-modal__footer"},[_c('button',{staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('knowledge.modals.article-to-guide.cancel'))+" ")]),_c('button',{staticClass:"btn btn-primary btn-sm",attrs:{"disabled":!!_vm.getPromptError || _vm.loading},on:{"click":function($event){return _vm.$emit('generate-guide', _vm.prompt)}}},[_vm._v(" "+_vm._s(_vm.$t('knowledge.modals.article-to-guide.create'))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }