<template>
  <div class="salesforce-input-row-wrapper">
    <WorkflowSelect
      :placeholder="$t('knowledge.action-triggers.forms.select-placeholder')"
      :options="displayedAttributes"
      :value="attributePayload.key"
      @change="handleAttributeChange"
    />
    <div class="separator-icon-wrapper">
      <font-awesome-icon
        class="separator-icon"
        :icon="['fal', 'arrow-right']"
      />
    </div>
    <WorkflowSelect
      v-if="selectedAttribute.values && selectedAttribute.values.length"
      filterable
      :value="attributePayload.value"
      :options="selectedAttribute.values"
      @change="handleValueChange($event, 'value')"
    />
    <WorkflowInput
      v-else
      :value="attributePayload.value"
      @change="handleValueChange($event, 'text')"
    />
    <div class="delete-icon-wrapper">
      <font-awesome-icon
        class="delete-icon"
        :icon="['fal', 'times']"
        @click="$emit('delete')"
      />
    </div>
  </div>
</template>

<script>
import WorkflowInput from '../Workflow/WorkflowInput';
import WorkflowSelect from '../Workflow/WorkflowSelect';

export default {
  name: 'salesforce-case-action-input',
  components: {
    WorkflowInput,
    WorkflowSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => [],
    },
    attributes: {
      type: Array,
      default: () => [],
    },
    forbiddenKeys: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      attributePayload: {
        key: '',
        value: '',
      },
    };
  },
  computed: {
    displayedAttributes() {
      return this.attributes
        .filter(
          ({ id }) =>
            this.attributePayload.key === id ||
            !this.forbiddenKeys.includes(id),
        )
        .map((attribute) => ({
          value: attribute.id,
          label: attribute.label,
          values: attribute.companyAttributeValues.map((value) => ({
            value: value.id,
            label: value.value,
          })),
        }));
    },
    selectedAttribute() {
      return (
        this.displayedAttributes.find(
          ({ value }) => value === this.attributePayload.key,
        ) || {}
      );
    },
  },
  methods: {
    handleAttributeChange(attributeId) {
      this.attributePayload = {
        key: attributeId,
        value: '',
      };
      this.$emit('change', this.attributePayload);
    },

    handleValueChange(value, type) {
      this.attributePayload.value = value;
      this.attributePayload.type = type;
      this.$emit('change', this.attributePayload);
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.attributePayload = newValue;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.salesforce-input-row-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;

  :deep() .workflow-input-wrapper {
    width: 45%;
  }
}

.separator-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  aspect-ratio: 1 / 1;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid $blue-mayday;
  background-color: rgba($blue-mayday, 0.1);
}

.separator-icon {
  padding: 2px;
  color: $blue-mayday;
}
.delete-icon-wrapper {
  width: 10px;
}
.delete-icon {
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}
</style>
