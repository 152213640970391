<template>
  <div class="breadcrumb-container">
    <div class="breadcrumb-wrapper" ref="breadcrumb">
      <div class="breadcrumb-elem">
        <!-- HOME -->
        <span @click="$emit('back-home')" class="breadcrumb-home">
          <font-awesome-icon :icon="['fal', 'home']" />
        </span>
        <!-- DIVIDER -->
        <font-awesome-icon
          v-if="parents.length"
          class="breadcrumb-divider mr-2"
          :icon="['fal', 'chevron-right']"
        />
      </div>

      <div
        v-for="(parent, idx) in renderedPath"
        :key="idx"
        class="breadcrumb-elem"
      >
        <!-- OVERFLOW ... -->
        <KnowledgeBreadcrumbDropdown
          class="breadcrumb-label"
          v-if="parent.label === '...'"
          :hidden-parents="hiddenParents"
          @click:folder="handleClick"
        />
        <!-- LABEL -->
        <TextEllipsis
          class="breadcrumb-label"
          v-else
          @click="handleClick(idx)"
          :width="maxWidth"
          :text="parent.label"
        />

        <!-- DIVIDER -->
        <font-awesome-icon
          v-if="idx + 1 !== renderedPath.length"
          class="breadcrumb-divider"
          :icon="['fal', 'chevron-right']"
        />
      </div>
    </div>
  </div>
</template>

<script>
import KnowledgeBreadcrumbDropdown from './KnowledgeBreadcrumbDropdown';
import TextEllipsis from './TextEllipsis';

export default {
  name: 'step-mover-breadcrumb',
  components: {
    KnowledgeBreadcrumbDropdown,
    TextEllipsis,
  },
  props: {
    parents: {
      type: Array,
      default: () => [],
    },
    contentParents: {
      type: Array,
      default: () => [],
    },
    focusContent: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isOverflow: false,
    };
  },
  mounted() {
    this.computeOverflow();
  },
  computed: {
    renderedPath() {
      if (!this.isOverflow) return this.parents;

      return [this.parents[0], { label: '...' }, this.parents.at(-1)];
    },
    hiddenParents() {
      if (!this.isOverflow) return [];

      return this.parents.slice(1, -1);
    },
    maxWidth() {
      const margin = 32;
      const start = 36 * 2;
      return 225 - margin - start;
    },
  },
  methods: {
    computeOverflow() {
      this.isOverflow = false;
      this.$nextTick(() => {
        const { breadcrumb } = this.$refs;
        this.isOverflow = breadcrumb.scrollWidth > breadcrumb.clientWidth;
      });
    },
    handleClick(idx) {
      if (idx + 1 !== this.parents.length) {
        return this.$emit('click:folder', idx);
      }
      return '';
    },
  },
  watch: {
    parents() {
      this.computeOverflow();
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb-container {
  position: relative;
  left: 8px;
}

.breadcrumb-wrapper {
  margin-top: 10px !important;
  margin-bottom: 8px !important;
  display: flex !important;
  width: 100% !important;
}

.breadcrumb-home:hover {
  cursor: pointer;
  color: $blue-mayday;
}

.breadcrumb-elem {
  white-space: nowrap !important;
  display: flex !important;
  align-items: center !important;
  font-size: 12px !important;
  color: $grey-7-mayday !important;
  font-weight: 700 !important;
  &:last-child {
    .breadcrumb-label {
      color: $blue-mayday !important;
      cursor: auto !important;
    }
  }
  &:hover:not(:last-child) {
    cursor: pointer !important;
    .breadcrumb-label {
      color: $blue-mayday !important;
    }
  }

  .breadcrumb-divider {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: $grey-4-mayday !important;
    font-size: 12px !important;
    margin: auto 8px;
  }
}
</style>
