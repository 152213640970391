<template>
  <BaseActionCollapse
    :header-title="$t('knowledge.action-triggers.options.zendesk-macro')"
    :header-custom-icon-path="icon"
    :auto-open="autoOpen"
    :save-disabled="!isValid"
    :is-new="isNew"
    @create="$emit('create', { actionPayload, langs, title })"
    @delete="$emit('delete')"
    v-loading="macroIsLoading"
  >
    <template v-slot:additional-header>
      <div class="addition-header-wrapper">
        <span>{{ title }}</span>
        <div v-if="langs.length" class="languages-count-wrapper">
          <font-awesome-icon :icon="['fal', 'globe-stand']" />
          <span
            >{{ langs.length }}
            {{
              $tc('knowledge.action-triggers.forms.languages', langs.length)
            }}</span
          >
        </div>
      </div>
    </template>

    <template v-slot:body>
      <div class="zendesk-action-form">
        <WorkflowInput
          :title="$t('knowledge.action-triggers.forms.action-title')"
          :placeholder="$t('knowledge.action-triggers.forms.action-title')"
          :value="title"
          @input="title = $event"
        />
        <WorkflowSelect
          v-if="languagesOptions.length > 1"
          :title="$tc('knowledge.action-triggers.forms.languages', 2)"
          :placeholder="
            $t('knowledge.action-triggers.forms.select-placeholder')
          "
          multiple
          :options="languagesOptions"
          :value="langs"
          @change="langs = $event"
        />
        <WorkflowInput
          :title="$t('knowledge.action-triggers.zendesk-form.macro')"
          :placeholder="selectPlaceholder"
          :options="[
            {
              label: $t(
                'knowledge.action-triggers.zendesk-form.macro-name-option',
              ),
              value: 'name',
            },
            {
              label: $t(
                'knowledge.action-triggers.zendesk-form.macro-id-option',
              ),
              value: 'id',
            },
          ]"
          :value="actionPayload.macro_id"
          :select-value="macroSelectKey"
          :fetch-suggestions="computedRemoteSearch"
          @input="handleInputChange"
          @select="handleSelectMacro"
          @change-select="handleSearchTypeChange"
        />

        <!-- MACRO PREVIEW -->
        <div class="preview-collapse-wrapper">
          <div
            class="preview-collapse-header"
            @click="displayPreview = !displayPreview"
          >
            <div class="preview-collapse-title">
              <div class="preview-icon-title-wrapper">
                <font-awesome-icon
                  :icon="['fal', 'eye']"
                  class="preview-icon"
                />
              </div>
              <span>
                {{ $t('knowledge.action-triggers.zendesk-form.preview') }}
              </span>
            </div>
            <div class="preview-collapse-open-icon">
              <font-awesome-icon
                :icon="['fal', 'chevron-down']"
                class="collapse-icon"
                :class="{ active: displayPreview }"
              />
            </div>
          </div>

          <div
            class="preview-collapse-body"
            v-if="displayPreview && previewData"
          >
            <div class="preview-collapse-general-data">
              <div
                class="preview-collapse-macro-id-wrapper"
                @click="handleTitleClick"
              >
                <img src="~/assets/brand/zendesk_round.svg" />
                {{ `${previewData.title} - ${previewData.id}` }}
                <font-awesome-icon :icon="['fal', 'external-link']" />
              </div>
              <div class="general-data-wrapper">
                <div class="general-data-row">
                  <span class="general-data-title">{{
                    $t('knowledge.action-triggers.zendesk-form.created-at')
                  }}</span>
                  <span>{{ parseDate(previewData.created_at) }}</span>
                </div>
                <div class="general-data-row">
                  <span class="general-data-title">{{
                    $t('knowledge.action-triggers.zendesk-form.updated-at')
                  }}</span>
                  <span>{{ parseDate(previewData.updated_at) }}</span>
                </div>
              </div>
            </div>

            <div class="preview-collapse-macro-fields">
              <span class="actions-preview-title">{{
                $t('knowledge.action-triggers.zendesk-form.macro-actions')
              }}</span>
              <div class="macro-actions-wrapper">
                <div
                  class="macro-action-row"
                  v-for="action in previewData.actions"
                  :key="action.id"
                >
                  <span class="macro-actions-title">{{ action.field }}</span>
                  <span class="macro-actions-value">{{ action.value }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseActionCollapse>
</template>

<script>
import debounce from 'lodash.debounce';
import BaseActionCollapse from './Base/BaseActionCollapse';
import WorkflowSelect from '../Workflow/WorkflowSelect';
import WorkflowInput from '../Workflow/WorkflowInput';

import zendeskIcon from 'assets/brand/zendesk.svg';

export default {
  name: 'zendesk-action-form',
  components: {
    BaseActionCollapse,
    WorkflowSelect,
    WorkflowInput,
  },
  props: {
    languagesOptions: {
      type: Array,
      default: () => [],
    },
    autoOpen: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Object,
      required: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      debounceGetMacroById: debounce(this.getMacroById, 500),
      displayPreview: false,
      icon: zendeskIcon,
      macroSelectKey: 'name',
      langs: [],
      actionPayload: {
        macro_id: '',
      },
      previewData: null,
      macroIsLoading: false,
      isValid: false,
      title: '',
    };
  },
  methods: {
    handleTitleClick() {
      if (!this.previewData) return;

      const baseURL = new URL(this.previewData.url);
      window.open(
        `${baseURL.origin}/admin/workspaces/agent-workspace/macros/${this.previewData.id}`,
        '_blank',
      );
    },
    async fetchMacros(query, cb) {
      try {
        if (!query) return cb([]);
        const macros = await this.$services.zendesk.searchMacros(query);

        if (!macros || !macros.length) return cb([]);

        cb(
          macros.map((macro) => ({
            value: `${macro.title} - ${macro.id}`,
            macroId: macro.id,
          })),
        );
      } catch (error) {
        cb([]);
      }
    },
    handleInputChange(macroId) {
      if (macroId && this.macroSelectKey === 'id') {
        this.debounceGetMacroById(macroId);
      }
    },
    handleSelectMacro(event) {
      this.getMacroById(event.macroId);
    },
    handleSearchTypeChange(type) {
      this.macroSelectKey = type;
      this.actionPayload.macro_id = '';
    },
    async getMacroById(id) {
      try {
        this.macroIsLoading = true;
        const macro = await this.$services.zendesk.getMacroById(id);

        if (macro) {
          this.actionPayload.macro_id = String(id);
          this.previewData = macro;
          this.isValid = true;
          this.displayPreview = true;
          this.macroSelectKey = 'id';
        } else {
          this.displayPreview = false;
          this.isValid = false;
          this.previewData = null;
          this.triggerErrorMessage();
        }
      } catch {
        this.triggerErrorMessage();
        this.displayPreview = false;
        this.isValid = false;
        this.previewData = null;
      } finally {
        this.macroIsLoading = false;
      }
    },
    triggerErrorMessage() {
      this.$message({
        message: this.$t(
          'knowledge.diagnostic-builder.side-bar.triggers.add-answer.container.macro-not-found',
        ),
        type: 'error',
        center: true,
      });
    },
    parseDate(date) {
      let options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
      let formatDate = new Date(Number(Date.parse(date))).toLocaleDateString(
        this.$i18n.locale,
        options,
      );
      return formatDate.charAt(0).toUpperCase() + formatDate.slice(1);
    },
  },
  computed: {
    computedRemoteSearch() {
      return this.macroSelectKey === 'id' ? null : this.fetchMacros;
    },
    selectPlaceholder() {
      return this.macroSelectKey === 'name'
        ? this.$t(
            'knowledge.action-triggers.zendesk-form.macro-name-placeholder',
          )
        : this.$t(
            'knowledge.action-triggers.zendesk-form.macro-id-placeholder',
          );
    },
  },
  watch: {
    action: {
      handler() {
        if (!this.action) return;
        this.actionPayload = this.action.payload;
        this.langs = this.action.langs;
        this.title = this.action.title;
        if (this.actionPayload.macro_id) {
          this.macroSelectKey = 'id';
          this.getMacroById(this.actionPayload.macro_id);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.languages-count-wrapper {
  color: $grey-5-mayday;
  font-size: 12px;
  font-weight: lighter;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
  border-radius: 4px;
  background-color: $grey-1-mayday;
}

.addition-header-wrapper {
  font-weight: 500;
  color: $grey-5-mayday;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.preview-collapse-wrapper {
  padding-block: 8px;
  padding-inline: 16px;
  border: 1px solid $grey-4-mayday;
  background-color: $grey-1-mayday;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
}
.preview-collapse-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}

.preview-collapse-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.preview-icon-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background-color: white;
}

.preview-icon {
  padding: 2px;
  font-size: 20px;
}

.collapse-icon {
  &.active {
    transform: rotate(180deg);
  }
}
.preview-collapse-body {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}
.zendesk-action-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.preview-collapse-general-data {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-block: 1px solid $grey-4-mayday;
  padding-block: 8px;
}

.preview-collapse-macro-id-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
    color: rgba($blue-mayday, 0.8);
  }
}
.general-data-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.general-data-row {
  display: flex;
  gap: 16px;
}

.general-data-title {
  font-weight: bold;
  color: $grey-5-mayday;
  width: 80px;
}

.preview-collapse-macro-fields {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.actions-preview-title {
  font-weight: bold;
}
.macro-action-row {
  display: flex;
  gap: 8px;
}
.macro-actions-wrapper {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.macro-actions-title {
  font-weight: bold;
  color: $grey-5-mayday;
  text-transform: uppercase;
  width: 130px;
  word-wrap: break-word;
}
.macro-actions-value {
  word-break: break-word;
}
</style>
