<template>
  <div>
    <DrawerRowTitle
      :title="
        $t('knowledge.drawers.content-view-setting.settings.parameters.title')
      "
      :tooltip="
        $t('knowledge.drawers.content-view-setting.settings.parameters.tooltip')
      "
    />
    <div
      v-if="LATER_FLAG"
      class="select-knowledge-parameters"
      v-loading="isLoading"
    >
      <div v-for="(o, idx) in optionsType" :key="o.id" class="px-0">
        <el-divider direction="horizontal" v-if="idx < 0" class="my-2" />
        <SelectParameter
          v-if="parameters.length"
          :parameters="parameters"
          :hierarchy="o"
          :update-filter="updateFilter"
          :filters="filters"
          @update-knowledge="updateKnowledge"
        />
      </div>
    </div>

    <div>
      <!-- TO BE REMOVED IN THE FUTURE -->
      <not-allowed-tooltip
        :user-permissions="userPermissions"
        permission-key="canUpdateContent"
        placement="top"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <el-button
            @click="hasPermissions ? $emit('open-modal') : ''"
            class="w-100"
            :disabled="!hasPermissions"
            >{{
              $t(
                'knowledge.drawers.content-view-setting.settings.parameters.modify',
              )
            }}
          </el-button>
        </template>
      </not-allowed-tooltip>
    </div>
  </div>
</template>

<script>
import SelectParameter from '@/components/Commons/KnowledgeParameters/SelectParameter.vue';
import DrawerRowTitle from '@/components/Drawers/Commons/DrawerRowTitle';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

import ProductMixin from 'mixins/KnowledgeMixin/ProductMixin';

export default {
  name: 'content-view-settings-parameters',
  mixins: [ProductMixin],
  components: {
    DrawerRowTitle,
    SelectParameter,
    NotAllowedTooltip,
  },
  props: {
    initFilters: {
      type: Array,
      default: () => [],
    },
    cascadeType: {
      type: String,
      default: 'settings',
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    optionsType() {
      return this.cascadeType === 'settings'
        ? this.mxHierarchy.options.filter((o) => !o.filterOnly)
        : this.mxHierarchy.options;
    },
  },
  data() {
    return {
      parameters: [],
      filters: this.initFilters || [],
      isLoading: false,
      LATER_FLAG: false,
    };
  },
  async created() {
    this.isLoading = true;
    this.parameters = await this.$services.hierarchies.getHierarchies();
    this.parameters = this.parameters.sort((a, b) =>
      a.label.localeCompare(b.label),
    );
    this.isLoading = false;
  },
  methods: {
    async updateKnowledge(knowledge) {
      this.$emit('update-knowledge', knowledge);
    },
    async updateFilter(newFilter) {
      const hasValues = newFilter.values.length > 0;
      const keyIndex = this.filters.findIndex((f) => f.key === newFilter.key);
      const alreadyExisting = keyIndex !== -1;
      if (alreadyExisting) {
        if (hasValues) {
          this.filters[keyIndex].values = newFilter.values;
        } else {
          if (this.filters.length > 1) {
            this.filters.splice(keyIndex, 1);
          } else {
            this.filters = [];
          }
        }
      } else {
        if (hasValues) {
          this.filters.push(newFilter);
        }
      }
      this.$emit('update-product-filters', this.filters);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-knowledge-parameters {
  border-radius: 4px;
}
</style>
