<template>
  <div class="add-button">
    <font-awesome-icon class="add-icon" :icon="['fal', 'plus-circle']" />
    <span>{{ $t('knowledge.action-triggers.action') }}</span>
  </div>
</template>

<script>
export default {
  name: 'new-action-button',
};
</script>

<style lang="scss" scoped>
.add-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 8px;
  gap: 8px;
  border: 1px dashed gray;
  border-radius: 4px;
  color: $blue-mayday;

  &:hover {
    cursor: pointer;
    border-color: $blue-mayday;
  }
}
</style>
