var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"permission-container",class:{
    divider: _vm.showDivider,
    'permission-container-hidden': _vm.subItem.hidden,
  }},[_c('div',{staticClass:"text-container"},[_c('h4',[_vm._v(_vm._s(_vm.$t(_vm.subItem.switch)))]),_c('p',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t(_vm.subItem.description)))])]),_c('el-switch',{attrs:{"disabled":_vm.disabled || _vm.subItem.hidden},on:{"change":function($event){return _vm.$emit('switch-change', {
        permissions: _vm.subItem.relatedPermissions,
        value: $event,
      })}},model:{value:(_vm.localIsOpened),callback:function ($$v) {_vm.localIsOpened=$$v},expression:"localIsOpened"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }