<template>
  <div
    class="knowledge-manage-settings-row-header-left d-flex align-items-center py-1"
  >
    <IconWrapper
      class="title-icon-background p-1"
      :icon="refinedIcon"
      :size="14"
    />
    <div
      v-if="edit.label"
      class="row justify-content-end align-items-center px-2"
    >
      <ItemEditor
        :reference="'updateKnowledgeInput'"
        :value="label"
        :placeholder="'settings.general.kb-manage.actions.placeholder-label'"
        @edit="updateKnowledgeLabel"
        @cancel="edit.label = false"
        class="knowledge-base-input"
      />
    </div>
    <h4
      v-else
      class="knowledge-base-label px-2"
      @dblclick="$emit('edit-label')"
    >
      {{ label }}
    </h4>
    <el-badge
      v-if="isDefault"
      class="col-auto default-tag"
      type="primary"
      size="mini"
    >
      {{ $t('settings.general.kb-manage.default') }}
    </el-badge>
    <el-badge
      v-if="isDeleted"
      class="ml-2 col-auto deleted-tag"
      type="primary"
      size="mini"
    >
      {{ $t('settings.general.kb-manage.deleted') }}
    </el-badge>
  </div>
</template>
<script>
import IconWrapper from '@/components/IconWrapper';
import ItemEditor from '../../../../components/Settings/ContentParameters/ItemEditor.vue';
export default {
  name: 'knowledge-manage-settings-row-header',
  components: {
    ItemEditor,
    IconWrapper,
  },
  props: {
    isCategory: {
      type: Boolean,
      default: false,
    },
    label: String,
    icon: {
      type: Object,
      default: () => {
        return {
          iconType: 'fa',
          value: 'folder',
          color: null,
        };
      },
    },
    isDefault: Boolean,
    id: String,
    edit: {
      label: String,
    },
    isDeleted: Boolean,
    isPublic: {
      type: Boolean,
      default: false,
    },
    isKnowledge: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    refinedIcon() {
      return this.icon.iconType
        ? this.icon
        : {
            iconType: 'fa',
            value: 'folder',
            color: null,
          };
    },
  },
  methods: {
    updateKnowledgeLabel(event) {
      this.$emit('update-knowledge-label', {
        id: this.id,
        newLabel: event,
        oldName: this.label,
      });
      this.edit.label = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.title-icon {
  color: $grey-6-mayday;
}

.title-icon-background {
  background: $grey-1-mayday;
  border-radius: 2px;
}

.tag {
  display: block;
  color: $grey-6-mayday;
  font-size: xx-small;
}

.default-tag {
  background-color: rgba($blue-mayday, 0.1);
  border: 1px solid $blue-mayday;
  box-sizing: border-box;
  border-radius: 2px;
  color: $blue-mayday;
  font-weight: bold;
  font-size: 10px;
  line-height: 129.8%;
  text-align: center;
  letter-spacing: 0.5px;
}

.deleted-tag {
  background-color: rgba($red-mayday, 0.1);
  border: 1px solid $red-mayday;
  box-sizing: border-box;
  border-radius: 2px;
  color: $red-mayday;
  font-weight: bold;
  font-size: 10px;
  line-height: 129.8%;
  text-align: center;
  letter-spacing: 0.5px;
}

.knowledge-base-label {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
}

h4 {
  margin-bottom: 0px;
}

.title-icon-background :deep() .logo-container {
  color: $grey-4-mayday;
  max-width: 16px;
  max-height: 16px;
  display: flex;
}

.knowledge-base-input {
  :deep() .item-editor__input--category {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: inherit;
    text-transform: none;
    text-align: left;
  }
}
</style>
