<template>
  <div class="product container">
    <div class="row justify-content-center mt-4">
      <main class="col-md-9 col-sm-12 mt-2 mb-8">
        <ResultsInput
          class="results-input-section"
          v-loading="init"
          v-model="searchInputFilter"
          :tags="selectedTags"
          @delete-tag="deleteTag"
          @delete-all="deleteAllFilters"
          @toggle-filters="toggleFilters"
        />
        <SearchHeader
          v-if="showFilters"
          :class="{ 'border-top': showToggleMoreFilters }"
          :display="showToggleMoreFilters"
          :toggle="toggleMoreFilters"
          @toggle-filters="toggleMoreFilters = !toggleMoreFilters"
        />
        <SearchFilters
          class="padding-bottom"
          :class="{ 'border-top': !showToggleMoreFilters }"
          ref="filters"
          v-loading="init"
          :display="showFilters"
          :selected-tags="selectedTags"
          :show-more-filters="toggleMoreFilters"
          :searchable-languages="searchableLanguages"
          :search-language="searchLanguage"
          @update-selected-tags="updateTags"
          @change-language="handleLanguage"
        />
        <results-list-section
          class="results-list-section"
          v-loading="loading || init"
          :results="results"
          :counts="counts"
          :tabs="tabsConfig"
          :is-read-only="isWebParametric"
          :labelSearchedIds="labelSearchedIds"
          :searchLanguage="searchLanguage"
          @update-post-filters="updatePostFilters"
          @open-document="goToDocument"
          @bulk-update-attributes="bulkUpdateAttributes"
          @bulk-update-status="bulkUpdateStatus"
          @bulk-archive-contents="handleBulkArchiveContents"
        >
          <div class="text-center">
            <img src="~assets/empty-contents.svg" />
            <div
              class="d-flex justify-content-center align-items-start text-blue text-small"
            >
              Aucun résultat
              <span v-if="searchInProgress" class="mx-1">:</span>
              <div
                v-if="searchInProgress"
                class="delete-cta"
                @click="deleteAllFilters"
              >
                Effacer les filtres
                <font-awesome-icon
                  :icon="['fal', 'times-circle']"
                  class="icon-delete"
                  @click="$emit('delete-all')"
                />
              </div>
            </div>
          </div>
        </results-list-section>
        <div ref="scroll"></div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import debounce from 'lodash.debounce';
import { v4 as uuid } from 'uuid';
import { getDurationFromDateRange } from '@/utils/dates/dateBetween';

import ResultsInput from '@/components/SuperSearch/Results/ResultsInput.vue';
import SearchHeader from '@/components/SuperSearch/SearchHeader';
import SearchFilters from '@/components/SuperSearch/SearchFilters.vue';
import ResultsListSection from '@/components/SuperSearch/Results/ResultsListSection.vue';

const counts = {
  totalCount: 0,
  casesCount: 0,
  contentsCount: 0,
  articlesCount: 0,
  diagnosticsCount: 0,
  productsCount: 0,
};

export default {
  name: 'search-result-viewer',
  components: {
    ResultsInput,
    SearchHeader,
    SearchFilters,
    ResultsListSection,
  },
  data() {
    return {
      toggleMoreFilters: false,
      preTags: [],
      selectedTags: [],
      showFilters: false,
      results: [],
      counts: { ...counts },
      init: false,
      loading: false,
      searchInputFilter: '',
      postFilter: {},
      loadingMessage: null,
      searchFrom: 0,
      searchLanguage: '',
      size: 100,
      endOfResults: false,
      isWebParametric: false,
      isWeb: false,
      correlationId: '',
      debounceSearchFilters: debounce(() => {
        this.superSearch();
      }, 200),
    };
  },
  computed: {
    ...mapGetters([
      'isGranularAccessRestrictions',
      'isParametric',
      'hasAgentRestrictions',
    ]),
    ...mapGetters('knowledgeModule', [
      'getContentParameterLabels',
      'searchableLanguages',
    ]),
    ...mapGetters('webKnowledgeModule', ['focusKnowledgeId']),
    showToggleMoreFilters() {
      return this.chosenEntity === 'attached-contents' || !this.isParametric;
    },
    labelSearchedIds() {
      return this.selectedTags.reduce((acc, tag) => {
        if (tag.key === 'categories') acc.push(tag.id);
        return acc;
      }, []);
    },
    currentFilter() {
      return {
        filterType: this.entitiesSearchField,
        entityType: this.entityType,
        search: this.searchInputFilter,
        knowledge: this.knowledgeFilter,
        correlationId: this.correlationId,
        product: null,
        cases: null,
        status: [{ key: 'published', value: true }],
        accessRestrictions: null,
        labels: this.labelsFilters,
        isContribution: null,
        searchFrom: this.searchFrom,
        size: this.size,
      };
    },
    chosenEntity() {
      const entity = this.selectedTags.find((tag) => tag.key === 'entity');
      return entity ? entity.value : '';
    },
    chosenContentTypes() {
      return this.selectedTags.reduce((acc, tag) => {
        if (tag.key === 'type') {
          acc.push(tag.value);
        }
        return acc;
      }, []);
    },
    entityType() {
      return this.preFilters.type || null;
    },
    knowledgeFilter() {
      const knowledge = this.selectedTags.find(
        (tag) => tag.key === 'knowledge',
      );
      return knowledge ? knowledge.value : '';
    },
    productFilters() {
      return this.selectedTags.reduce((acc, tag) => {
        if (tag.key === 'family' || tag.key === 'brand') {
          let filterIndex = acc.findIndex((f) => f.key === tag.key);
          if (filterIndex > -1) {
            acc[filterIndex].values.push(tag.value);
          } else {
            acc.push({ key: tag.key, values: [tag.value] });
          }
        }
        return acc;
      }, []);
    },
    statusFilters() {
      return this.selectedTags.reduce((acc, tag) => {
        if (tag.key === 'published' || tag.key === 'isOutdated') {
          let status = {
            key: tag.key,
            value: tag.value,
          };
          acc.push(status);
        }
        return acc;
      }, []);
    },
    labelsFilters() {
      return this.selectedTags.reduce((acc, tag) => {
        if (tag.key === 'categories') {
          acc.push(tag.value);
        }
        return acc;
      }, []);
    },
    entitiesSearchField() {
      return this.chosenEntity === 'attached-contents' ||
        this.chosenEntity === 'new-products'
        ? [this.chosenEntity]
        : this.isWebParametric
        ? ['attached-contents', 'new-products']
        : this.isParametric
        ? ['attached-contents', 'cases', 'new-products']
        : ['attached-contents', 'cases'];
    },
    preFilters() {
      return this.selectedTags.reduce((acc, tag) => {
        if (tag.key !== 'entity') {
          if (acc[tag.key]) {
            tag.noArray
              ? (acc[tag.key] = tag.value)
              : acc[tag.key].push(tag.value);
          } else {
            acc[tag.key] = tag.noArray ? tag.value : [tag.value];
          }
        }
        return acc;
      }, {});
    },
    tabsConfig() {
      return {
        all:
          this.entitiesSearchField.length > 1 ||
          (this.entitiesSearchField.includes('attached-contents') &&
            this.chosenContentTypes.length !== 1),
        cases:
          this.entitiesSearchField.includes('cases') &&
          !this.chosenContentTypes.length,
        articles:
          this.entitiesSearchField.includes('attached-contents') &&
          !(
            this.chosenContentTypes.includes('Diagnostic') &&
            this.chosenContentTypes.length === 1
          ),
        diagnostics:
          this.entitiesSearchField.includes('attached-contents') &&
          !(
            this.chosenContentTypes.includes('Article') &&
            this.chosenContentTypes.length === 1
          ),
        products: this.entitiesSearchField.includes('new-products'),
      };
    },
    searchInProgress() {
      return this.selectedTags.length > 0 || this.searchInputFilter !== '';
    },
    queryParameters() {
      return {
        search: this.searchInputFilter,
        ...this.selectedTags.reduce((acc, tag) => {
          if (
            tag.key === 'createdAt' ||
            tag.key === 'updatedAt' ||
            tag.key === 'verificationPolicy.verificationDueDate'
          ) {
            acc[tag.key] = JSON.stringify(tag.value);
            return acc;
          }
          if (acc[tag.key]) {
            tag.noArray
              ? (acc[tag.key] = tag.value)
              : acc[tag.key].push(tag.value);
          } else {
            acc[tag.key] = tag.noArray ? tag.value : [tag.value];
          }
          return acc;
        }, {}),
      };
    },
  },
  async beforeCreate() {
    this.$emit('change-route-type', 'Filter');
  },
  async created() {
    const { query, path, params } = this.$route;
    const { lang } = params;
    this.isWebParametric = path.includes('web-parametric');
    this.isWeb = path.includes('web');
    if (query) {
      this.routeUpdateHandler();
    }
    document.addEventListener('scroll', this.handleScroll);

    if (!lang) {
      this.searchLanguage = this.searchableLanguages[0];
      return this.updateRoute();
    }
    this.searchLanguage = lang;
  },
  async mounted() {
    await this.initTags();
  },
  beforeDestroy() {
    this.debounceSearchFilters.flush();
    document.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions('knowledgeModule', ['bulkArchiveContents']),
    ...mapActions(['searchAction']),
    getCorrelationId() {
      return (
        (this.isParametric ? 'web-parametric-search-' : 'admin-search-bar-') +
        uuid()
      );
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
      this.toggleMoreFilters = false;
    },
    handleAgentRestrictions(filters) {
      if (this.isWebParametric) filters.published = ['true', null];
      if (!this.isWebParametric || !this.hasAgentRestrictions || filters.type)
        return filters;
      filters.type = ['Article', 'Diagnostic', 'keyStep', null];
      return filters;
    },
    async superSearch() {
      if (!(this.selectedTags.length || this.searchInputFilter)) return;
      try {
        this.loading = true;
        const resp = await this.searchAction({
          searchType: 'superSearch',
          searchArgs: {
            entity: this.entitiesSearchField,
            fields: ['entity', 'type'],
            searchFrom: this.searchFrom,
            size: this.size,
            correlationId: this.correlationId,
            lang: this.searchLanguage,
            payload: {
              queryText: this.searchInputFilter,
              pre: this.handleAgentRestrictions(this.preFilters),
              post: this.postFilter,
              preTag: '<span style="font-weight:900">',
              postTag: '</span>',
              showHidden: true,
            },
          },
        });

        this.results = this.infiniteScroll
          ? this.results.concat(resp.documents)
          : resp.documents;
        this.endOfResults = resp.documents.length < this.size;
        this.counts = resp.counts;
        this.loading = false;
        this.infiniteScroll = false;

        this.storeEvent({
          eventMethod: 'search',
          event: {
            search: this.currentFilter,
            displayedHits: this.results.length,
            totalHits: this.counts.totalCount,
          },
        });
      } catch (e) {
        console.log(e);
        return e;
      }
    },
    tryToSearch() {
      this.searchFrom = 0;
      if (!this.init && (this.selectedTags.length || this.searchInputFilter)) {
        this.debounceSearchFilters();
      } else {
        this.toggleMoreFilters = false;
        this.results = [];
        this.counts = { ...counts };
      }
      this.updateRoute();
    },
    async initTags() {
      this.init = true;
      this.selectedTags = await this.$refs.filters.initTags(this.preTags);
      this.init = false;
    },
    updateTags(tags) {
      this.selectedTags = tags;
    },
    deleteTag(tag) {
      const index = this.selectedTags.findIndex(
        (t) => t.id == tag.id && t.key == tag.key,
      );
      this.selectedTags.splice(index, 1);
      if (tag.key === 'entity') {
        this.selectedTags = [];
      } else if (tag.key === 'knowledge') {
        this.selectedTags = this.selectedTags.filter((t) => t.key === 'entity');
      } else if (tag.key === 'family') {
        this.selectedTags = this.selectedTags.filter(
          (t) => t.key === 'entity' || t.key === 'knowledge',
        );
      }
    },
    deleteAllFilters() {
      this.searchInputFilter = '';
      this.selectedTags = [];

      this.storeEvent({
        eventMethod: 'searchAbort',
        event: {
          search: this.currentFilter,
          displayedHits: this.results.length,
          totalHits: this.counts.totalCount,
        },
      });

      this.correlationId = this.getCorrelationId();
    },
    async routeUpdateHandler(to = null) {
      const route = to === null ? this.$route : to;
      this.routeProcessing(route);
      if (this.$refs.filters) {
        await this.initTags();
      }
    },
    updatePostFilters(postFilter) {
      this.postFilter = postFilter;
      this.searchFrom = 0;
      this.debounceSearchFilters();
    },
    routeProcessing(to = null) {
      const route = to === null ? this.$route : to;
      const { search, correlation_id, ...parameters } = route.query;
      this.correlationId = correlation_id
        ? correlation_id
        : this.getCorrelationId();
      this.searchInputFilter = search ? search : '';
      this.preTags = this.parsePreTags(parameters);
      this.preTags = this.enrichPreTags();
    },
    parsePreTags(parameters) {
      return Object.keys(parameters).reduce((acc, key) => {
        if (typeof parameters[key] === 'object') {
          let preTags = parameters[key].map((f) => {
            return { key: key, value: f, label: f };
          });
          acc = acc.concat(preTags);
        } else {
          acc.push({
            key: key,
            value: parameters[key],
            label: parameters[key],
          });
        }
        return acc;
      }, []);
    },
    enrichPreTags() {
      return this.preTags.map((tag) => {
        if (
          tag.key === 'updatedAt' ||
          tag.key === 'createdAt' ||
          tag.key === 'verificationPolicy.verificationDueDate'
        ) {
          const value = JSON.parse(tag.value);
          const duration = getDurationFromDateRange(
            value.gte,
            value.lte,
            'days',
          );
          tag.value = value;
          tag.label = this.$tc(
            `knowledge.search-admin.quick-filters.dates.${tag.key
              .split('.')
              .slice(-1)}`,
            duration,
          );
          tag.noArray = true;
        }
        if (tag.key === 'verificationPolicy.hasVerificationPolicy') {
          tag.label = this.$t(
            `knowledge.search-admin.quick-filters.verification-policy.has.${tag.value}`,
          );
        }
        return tag;
      });
    },
    goToDocument(doc) {
      const documentType = doc.__typename.toLowerCase();
      const path = this.isWebParametric
        ? `/web-parametric/${documentType}/`
        : `/knowledge/${documentType}/`;

      let subPath = doc.id;
      if (
        documentType === 'content' &&
        ['Step', 'keyStep'].includes(doc.type)
      ) {
        subPath = `${doc.ancestors[0].split('/')[0]}/step/${doc.id}`;
      }

      if (this.isWebParametric && documentType === 'product') {
        subPath += `?correlation_id=${this.correlationId}`;
      }

      this.storeEvent({
        eventMethod: 'searchSuccess',
        event: {
          content: doc,
          search: this.currentFilter,
          displayedHits: this.results.length,
          totalHits: this.counts.totalCount,
        },
      });

      this.$router.push({
        path: path + subPath,
        query: {
          from: 'search',
        },
      });
      this.localDisplay = false;
    },
    updateRoute() {
      this.$router.replace({
        name: this.isWebParametric ? 'web-filter-results' : 'knowledge-filter',
        query: this.queryParameters,
        params: { lang: this.searchLanguage },
      });
    },

    extractLabelIdsFromToArray(toArray) {
      const toCategories = toArray.find(
        (toItem) => toItem.key === 'categories',
      );

      return toCategories ? toCategories.values : [];
    },
    async bulkUpdateAttributes({ contentIds, toAdd, toRemove }) {
      const labelIdsToRemove = this.extractLabelIdsFromToArray(toRemove);
      const labelIdsToAdd = this.extractLabelIdsFromToArray(toAdd).filter(
        (potentialIdToAdd) => !labelIdsToRemove.includes(potentialIdToAdd),
      );

      const labelsToAdd = this.getContentParameterLabels(labelIdsToAdd);

      const resultsCopy = [...this.results];

      contentIds.forEach((contentId) => {
        const idx = resultsCopy.findIndex(
          (resultItem) => resultItem.id === contentId,
        );

        if (idx === -1) return;

        const resultItemLabels = resultsCopy[idx].labels || [];

        // Add label only if it is not already in the item's labels
        labelsToAdd.forEach((label) => {
          const labelAlreadyExists =
            resultItemLabels.findIndex(
              (resultItemLabel) => resultItemLabel.id === label.id,
            ) !== -1;
          if (labelAlreadyExists) return;

          resultItemLabels.push(label);
        });

        resultsCopy[idx].labels = resultItemLabels.filter(
          (label) => !labelIdsToRemove.includes(label.id),
        );
      });

      this.results = resultsCopy;
    },
    async bulkUpdateStatus({ contentIds, key, value }) {
      const resultsCopy = [...this.results];

      contentIds.forEach((contentId) => {
        const idx = resultsCopy.findIndex(
          (resultItem) => resultItem.id === contentId,
        );

        if (idx === -1) return;

        resultsCopy[idx][key] = value;
      });

      this.results = resultsCopy;
    },
    async handleBulkArchiveContents({ contentIds }) {
      this.triggerBulkLoadingMessage(true);
      await this.bulkArchiveContents({ contentIds });

      this.results = this.results.filter(
        (resultItem) => !contentIds.includes(resultItem.id),
      );
      this.loadingMessage.close();
    },
    triggerBulkLoadingMessage(isArchive = false) {
      const message = isArchive
        ? `<i class="fas fa-spinner fa-spin" style="margin-right: 1em;"></i> Archivage des contenus sélectionnés en cours ...`
        : `<i class="fas fa-spinner fa-spin" style="margin-right: 1em;"></i> Mise à jour groupée en cours ...`;
      this.loadingMessage = this.$message({
        duration: 0,
        dangerouslyUseHTMLString: true,
        iconClass: 'display: none',
        showClose: true,
        message,
      });
    },
    handleScroll() {
      const scrollY = this.$refs.scroll.getBoundingClientRect().top;
      const screenSize = window.innerHeight;

      if (
        scrollY < screenSize &&
        !this.infiniteScroll &&
        !this.endOfResults &&
        this.results.length
      ) {
        this.infiniteScroll = true;
        this.searchFrom += this.size;
        this.debounceSearchFilters();
      }
    },
    storeEvent({ eventMethod, event }) {
      if (this.isWebParametric || this.isWeb) {
        event.knowledgeId = this.focusKnowledgeId;
        this.$services.events.content[eventMethod](
          event,
          window.location.href,
          'WEB_PARAMETRIC',
        );
      }
    },
    handleLanguage(e) {
      this.searchLanguage = e;
      this.tryToSearch();
    },
  },
  watch: {
    $route(to) {
      if (to.query && to.query.init) {
        this.routeUpdateHandler(to);
      }
    },
    searchInputFilter() {
      this.tryToSearch();
    },
    selectedTags() {
      this.tryToSearch();
    },
    init() {
      this.tryToSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
.results-input-section {
  padding-bottom: 16px;
}
.border-top {
  padding-top: 16px;
  border-top: 1px solid $grey-2-mayday;
}
.padding-bottom {
  padding-bottom: 16px;
}
.results-list-section {
  padding-top: 4px;
  border-top: 1px solid $grey-2-mayday;
}
.text-small {
  font-size: 12px;
}
</style>
