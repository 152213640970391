/* eslint-disable no-unused-vars */
import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState('kbStore', {
      treeNodesUtilsMixin: (state) => state.treeNodes,
      focusKnowledgeUtilsMixin: (state) => state.focusKnowledge,
    }),
    ...mapGetters({
      filteredTreeNodesUtilsMixin: 'kbStore/filteredTreeNodes',
    }),
  },
  methods: {
    nodeIsMagicAnswer(nodeId, bestMacros) {
      return bestMacros.filter((o) => o.treeNodeId === nodeId);
    },
    nodeHasGroup(nodeId, groups) {
      return groups.filter((o) => o.treeNodeId === nodeId);
    },
    computeUpdatedTreeNode(treeNode) {
      const { id, type, actions, contentId, path } = treeNode;
      return {
        id,
        contentId,
        path,
        type,
        public: false,
        actions,
      };
    },
    focusFirstRootCollection(currentId) {
      const { rootId } = this.focusKnowledgeUtilsMixin;
      const root = this.treeNodesUtilsMixin.find((o) => o.id === rootId);
      const futureFocus = currentId
        ? root.path.filter((o) => o.id !== currentId)
        : root.path;
      if (futureFocus.length)
        this.$store.dispatch('kbStore/updateFocusPath', [
          rootId,
          futureFocus[0],
        ]);
      else this.$store.dispatch('kbStore/updateFocusPath', [rootId]);
    },
    focusFirstFilterElement(currentIds) {
      const filteredTreeNodesLeft = this.filteredTreeNodesUtilsMixin.filter(
        (o) => !currentIds.includes(o.id),
      );
      if (filteredTreeNodesLeft.length > 0) {
        const futureParents = this.getNodeParents(filteredTreeNodesLeft[0].id);
        this.$store.dispatch('kbStore/updateFocusPath', futureParents);
      } else {
        this.focusFirstRootCollection();
        this.$store.dispatch('kbStore/updateKnowledgeFilters', []);
      }
    },
    getNodeParent(treeNodeId) {
      return this.treeNodesUtilsMixin.find(({ path }) =>
        path.includes(treeNodeId),
      );
    },
    getNodeParents(treeNodeId) {
      const { rootId } = this.focusKnowledgeUtilsMixin;
      let parentIds = [treeNodeId];
      let flagId = treeNodeId;
      while (flagId !== rootId) {
        let parent = this.treeNodesUtilsMixin.find(({ path }) =>
          path.includes(flagId),
        );
        if (parent) {
          flagId = parent.id;
          parentIds.unshift(flagId);
        } else {
          parentIds = [rootId];
          break;
        }
      }
      return parentIds;
    },
  },
};
