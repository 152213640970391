<template>
  <div class="mayday-editor-ce-wrapper" :class="{ 'image-pointer': !editable }">
    <!-- EDITOR SECTION -->
    <mayday-editor-ce
      :key="uniqueKey + locale + editable"
      :id="uniqueKey"
      :content="content"
      :options="JSON.stringify(options)"
      :editable="editable"
      :locale="locale"
      :isParametric="isParametric"
      :subdomain="subdomain"
      :jwt="accessToken"
      class="ceEditorContainer"
      @update="onUpdate"
      @click:attached-file="handleClickAttachedFile"
      @click:focus-hash="handleOnFocusHash"
      @click:image="handleOnImageClick"
      @click:inline-content="handleOnClickInlineContent($event.detail[0])"
      @click:inline-content-tab="
        handleOnClickInlineContentTab($event.detail[0])
      "
      @click:open-link="handleOpenLink"
      @click:open-link-modal="openLinkModal"
      @click:copy="handleOnCopy"
      @click:toc="onClickToc"
      @generate-summary="handleGenerateSummary"
      @generate-action-items="$emit('generate-action-items')"
      @stream-wrapper:use-suggestion="handleUseSuggestion"
      @stream-wrapper:cancel-suggestion="handleCancelSuggestion"
    ></mayday-editor-ce>
    <LightBox v-show="!!imageUrl" :image-url.sync="imageUrl" />

    <MaydayEditorOpenLinkModal
      v-if="linkModal.display"
      :display="linkModal.display"
      :src="linkModal.src"
      @close="closeLinkModal"
    />
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import MaydayEditorOpenLinkModal from './utils/MaydayEditorOpenLinkModal';
import LightBox from '@/components/LightBox';
import initEditorInGlobalWindow from '@/utils/initEditorInGlobalWindow';

function randomID() {
  return Math.floor(Math.random() * 100000000)
    .toString()
    .padStart(8, '0');
}

export default {
  name: 'MaydayEditorCEWrapper',
  components: {
    MaydayEditorOpenLinkModal,
    LightBox,
  },
  props: {
    body: String,
    editable: {
      type: Boolean,
      default: true,
    },
    locale: {
      type: String,
      required: true,
    },
    customOptions: {
      type: Object,
      default: () => ({}),
    },
    focusEditor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editorKey: '',
      content: this.body,
      linkModal: {
        display: false,
        src: '',
      },
      imageUrl: '',
      options: {
        fontOptions: true,
        extraFontOptions: true,
        colorOptions: true,
        structurationOptions: true,
        extraStructurationOptions: true,
        tableOptions: true,
        richOptions: true,
        tocClickOptions: true,
        allowToc: true,
        allowSummary: false,
        allowTranslation: false,
        allowGrammarCorrection: false,
        allowToneImprovement: false,
        allowOpenPrompt: false,
      },
      editorCe: false,
      contextVariables: null,
    };
  },
  created() {
    setTimeout(() => {
      const hash = this.$route.hash;
      this.handleOnFocusHash(hash);
    }, 1000);
    this.options =
      Object.keys(this.customOptions).length > 0
        ? this.customOptions
        : this.options;
    this.options.allowSummary = this.companyAllowSummaryPreference;
    this.options.allowTranslation = this.companyAllowTranslationPreference;
    this.options.allowGrammarCorrection =
      this.companyAllowGrammarCorrectionPreference;
    this.options.allowToneImprovement =
      this.companyAllowToneImprovementPreference;
    this.options.allowImprovement = this.companyAllowImprovementPreference;
    this.options.allowActionItems = this.companyAllowActionItemsPreference;
    this.options.allowLinkSeoAttributes =
      this.companyAllowLinkSeoAttributesPreference;
    this.options.allowVariableFeatures = this.hasWorkflowActivated;
    this.options.allowOpenPrompt = this.companyAllowGenerateOpenPrompt;
    this.resetEditor();
  },
  mounted() {
    this.$root.$on('IMPORTED_ARTICLE_EDITOR_REFRESH', () => {
      this.resetEditor();
    });
    this.$root.$on('content-restored', () => {
      this.resetEditor();
    });
    this.editorCe = window.___MAYDAY_CE___.editors[this.uniqueKey];
    if (this.focusEditor) this.editorCe.editor.commands.focus();
  },
  destroyed() {
    if (this.editorKey) delete window.___MAYDAY_CE___.editors[this.uniqueKey];
  },
  computed: {
    uniqueKey() {
      return `me__${this.editorKey}`;
    },
    subdomain() {
      return window.location.origin;
    },
    hasWorkflowActivated() {
      return this.hasCompanyPreferenceWithValue('WORKFLOW_ACTIVATED');
    },
    ...mapState(['accessToken']),
    ...mapGetters([
      'companyAllowSummaryPreference',
      'companyAllowTranslationPreference',
      'companyAllowGrammarCorrectionPreference',
      'companyAllowToneImprovementPreference',
      'companyAllowImprovementPreference',
      'companyAllowActionItemsPreference',
      'companyAllowLinkSeoAttributesPreference',
      'isParametric',
      'hasCompanyPreferenceWithValue',
      'companyAllowGenerateOpenPrompt',
    ]),
    ...mapGetters('knowledgeModule', ['focusContentId']),
  },
  methods: {
    getShadowRoot() {
      const wrapper = window.document.getElementById(this.uniqueKey);
      return wrapper.shadowRoot;
    },
    handleOpenLink(event) {
      const href = event.detail[0].href;
      if (!href) return;
      window.open(href, '_blank');
    },
    handleGenerateOpenPrompt() {
      this.editorCe = window.___MAYDAY_CE___.editors[this.uniqueKey];
      if (this.editorCe && this.editorCe.editor) {
        if (
          this.editorCe.editor.storage &&
          this.editorCe.editor.storage.streamWrapper
        ) {
          this.editorCe.editor.storage.streamWrapper.triggerSource = 'external';
        }
        if (this.editorCe.editor.commands) {
          this.editorCe.editor.commands.handleDisplayOpenPromptInput();
        }
      }
    },
    initMECE() {
      initEditorInGlobalWindow(this.uniqueKey);
      window.___MAYDAY_CE___.editors[this.uniqueKey]['props'] = {
        fetchInlineContent: this.fetchInlineContent,
        fetchInlineMention: this.handleFetchInlineMention,
        fetchInlineVariable: this.handleFetchInlineVariable,
        searchInlineEntity: this.handleSearchInlineEntity,
        searchInlineVariable: this.handleSearchInlineVariable,
        uploadFileCb: this.handleFileUpload,
        notifyUploadErrorCb: this.notifyUploadError,
        generateTranslation: this.fetchTranslationBuffer,
        generateGrammar: this.fetchCorrectionBuffer,
        generateStyle: this.fetchStyleBuffer,
        generateImprove: this.fetchImproveBuffer,
        generateOpenPrompt: this.fetchOpenPromptBuffer,
      };
    },
    resetEditor() {
      if (this.editorKey) delete window.___MAYDAY_CE___.editors[this.uniqueKey];

      this.editorKey = randomID();
      this.initMECE();
    },
    onUpdate(event) {
      const body = event.detail[0];
      this.content = body;
      this.$emit('update-written-content', { body });
    },
    openLinkModal(src) {
      this.linkModal.display = true;
      this.linkModal.src = src;
    },
    closeLinkModal() {
      this.linkModal.display = false;
      this.linkModal.src = '';
    },
    handleClickAttachedFile(event) {
      const href = event.detail[0].href;
      if (href) {
        window.open(href, '_blank');
      }
    },
    handleOnImageClick(event) {
      const src = event.detail[0].src;
      if (src) {
        this.imageUrl = src;
      }
    },
    async *fetchTranslationBuffer(
      text,
      language,
      abortController,
      query,
      triggerOption,
    ) {
      for await (const chunk of await this.fetchTranslation({
        text,
        language,
        abortController,
        contentId: this.focusContentId,
        triggerOption,
        feature: 'translate',
      })) {
        yield chunk;
      }
    },
    async *fetchCorrectionBuffer(
      text,
      _,
      abortController,
      query,
      triggerOption,
    ) {
      for await (const chunk of await this.fetchCorrection({
        text,
        abortController,
        correctionType: 'grammar',
        contentId: this.focusContentId,
        triggerOption,
        feature: 'correctGrammar',
      })) {
        yield chunk;
      }
    },
    async *fetchStyleBuffer(text, _, abortController, query, triggerOption) {
      for await (const chunk of await this.fetchCorrection({
        text,
        abortController,
        correctionType: 'style',
        contentId: this.focusContentId,
        triggerOption,
        feature: 'correctStyle',
      })) {
        yield chunk;
      }
    },
    async *fetchImproveBuffer(text, _, abortController, query, triggerOption) {
      for await (const chunk of await this.fetchImprove({
        text,
        abortController,
        contentId: this.focusContentId,
        triggerOption,
        feature: 'improve',
      })) {
        yield chunk;
      }
    },
    async *fetchOpenPromptBuffer(
      text,
      _,
      abortController,
      query,
      triggerOption,
    ) {
      for await (const chunk of await this.fetchOpenPrompt({
        contentId: this.focusContentId,
        text,
        query,
        abortController,
        triggerOption,
        feature: 'openPrompt',
      })) {
        yield chunk;
      }
    },
    handleOnFocusHash(hash) {
      if (!hash) return;
      const id = hash.slice(1);
      const shadowRoot = this.getShadowRoot();

      const uniqueNode = shadowRoot.querySelector(
        `[data-uid="${id}"], [id="${id}"]`,
      );
      if (!uniqueNode) return;
      uniqueNode.scrollIntoView({ behavior: 'smooth' });
    },
    onClickToc({ detail }) {
      this.handleOnFocusHash(`#${detail[0].id}`);
    },
    handleOnCopy(e) {
      const uniqueId = e.detail[0];
      const url = new URL(window.location.href);
      navigator.clipboard.writeText(`${url.origin}${url.pathname}#${uniqueId}`);

      this.$message(this.$t('editor.copy'));
    },
    handleGenerateSummary(event) {
      const isShortSummary = event.detail[0];
      this.$emit('generate-summary', isShortSummary);
    },
    fetchInlineContent(contentId, lang, type) {
      return this.handleFetchInlineContent({ contentId, lang, type });
    },
    async handleFetchInlineVariable({ id }) {
      if (!this.contextVariables) {
        this.contextVariables = await this.getContextVariables();
      }
      return this.contextVariables.find((variable) => variable._id === id);
    },
    async handleSearchInlineVariable({ query }) {
      if (!this.contextVariables) {
        this.contextVariables = await this.getContextVariables();
      }
      return this.contextVariables.filter(
        (variable) =>
          variable.name.toLowerCase().includes(query) ||
          variable.path.toLowerCase().includes(query),
      );
    },
    handleOnClickInlineContentTab(content) {
      this.handleOnClickInlineContent({ ...content, newTab: true });
    },
    handleUseSuggestion(e) {
      const event = e.detail[0];
      event.contentId = this.focusContentId;
      this.streamSuggestionEvent({ eventName: 'useSuggestion', event });
    },
    handleCancelSuggestion(e) {
      const event = e.detail[0];
      event.contentId = this.focusContentId;
      this.streamSuggestionEvent({ eventName: 'cancelSuggestion', event });
    },
    ...mapActions('knowledgeModule', [
      'handleOnClickInlineContent',
      'handleFetchInlineContent',
      'handleFetchInlineMention',
      'handleSearchInlineEntity',
      'handleFileUpload',
      'notifyUploadError',
      'getContextVariables',
    ]),
    ...mapActions('brainModule', [
      'fetchTranslation',
      'fetchCorrection',
      'fetchImprove',
      'fetchOpenPrompt',
      'streamSuggestionEvent',
    ]),
  },
  watch: {
    locale() {
      if (this.editorKey) {
        delete window.___MAYDAY_CE___.editors[this.uniqueKey];
      }

      this.initMECE();
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .mayday-editor {
  padding: 0;
}

:deep().mayday-editor__slash-menu-command-button {
  z-index: 1;
}

.image-pointer {
  :deep() .mayday-editor {
    &__image-wrapper {
      img {
        cursor: pointer;
      }
    }
  }
}

:deep() summary {
  color: black;
}
</style>
