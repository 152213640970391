<template>
  <div>
    <DrawerRowTitle
      :title="
        $t(
          'knowledge.drawers.content-view-setting.settings.access.title-private',
        )
      "
      :tooltip="
        $t(
          'knowledge.drawers.content-view-setting.settings.access.tooltip-private',
        )
      "
    />
    <div class="d-flex">
      <!-- READ ONLY -->
      <div class="content-view-setting__groups-area w-100">
        <div v-if="groupInfos.length === 0">
          {{
            $t('knowledge.drawers.content-view-setting.settings.access.empty')
          }}
        </div>
        <div v-else v-for="group in groupInfos" :key="group.id">
          <font-awesome-icon
            slot="prefix"
            class="read-icon"
            :icon="['fad', 'users']"
          />
          {{ group.name }}
        </div>
      </div>
      <!-- WRITE -->
      <div v-if="FOR_LATER" class="overflow-group w-100">
        <el-select
          v-model="newGroupIds"
          multiple
          :placeholder="
            $t(
              'knowledge.drawers.content-view-setting.settings.access.placeholder',
            )
          "
          style="width: 100%"
          @change="sendUpdateGroupIdsEvent"
          class="icon-selector__select"
        >
          <font-awesome-icon
            slot="prefix"
            class="select-icon-group"
            :icon="['fa', 'user']"
          />

          <el-option
            v-for="group in possibleUserGroups"
            :key="group.id"
            :label="group.name"
            :value="group.id"
          ></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import DrawerRowTitle from '@/components/Drawers/Commons/DrawerRowTitle';

export default {
  name: 'content-view-settings-groups',
  components: {
    DrawerRowTitle,
  },
  props: {
    groupIds: {
      type: Array,
    },
    possibleUserGroups: {
      type: Array,
    },
    access: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      newGroupIds: this.groupIds,
      FOR_LATER: false,
    };
  },
  computed: {
    groupInfos() {
      return this.possibleUserGroups.filter((g) =>
        this.newGroupIds.includes(g.id),
      );
    },
  },
  methods: {
    sendUpdateGroupIdsEvent(val) {
      this.$emit('update-group-ids', val);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .el-select .el-select__tags {
  padding-left: 2rem !important;
}

:deep() .el-input--prefix .el-input__inner {
  padding-left: 2rem !important;
}

:deep() .el-input__prefix {
  left: 0;
  width: 2.2rem;
  height: 2rem;
  padding: 0;
  margin-top: 12px;
}
.text-small {
  color: $grey-5-mayday;
  font-size: 0.9em;
}

.select-icon-group {
  color: $grey-4-mayday;
  font-size: 14px;
  margin: auto;
  line-height: 0;
}

.icon-selector__select {
  width: 100%;
  cursor: pointer;
}

.read-icon {
  color: $grey-6-mayday;
  margin-right: 8px;
}

.overflow-group {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.content-view-setting__groups-area {
  background-color: $grey-1-mayday;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 12px;
}
</style>
