<template>
  <div>
    <div
      class="flip-card"
      shadow
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
      :style="`z-index: ${100 - index}`"
    >
      <div class="flip-card-inner">
        <!-- FLIP FRONT -->
        <div class="flip-card-front text-center">
          <IconWrapper class="mt-3" :icon="icon" />
          <div class="collection-info">
            <h3>
              <span class="line-clamp-2">
                {{ label }}
              </span>
              <TranslationTooltip
                v-if="!isTranslated"
                class="root-card-translation-tooltip"
                :is-translated="isTranslated"
                :has-translated-contents="hasTranslatedContents"
              />
            </h3>
            <p class="line-clamp-3">{{ body }}</p>
          </div>
        </div>

        <!-- FLIP BACK -->
        <div class="flip-card-back">
          <div class="row header-row justify-content-between mx-1">
            <button
              class="card-title-wrapper d-flex align-items-center"
              :class="{ 'card-title-wrapper-translation': !isTranslated }"
              @click.exact="focusComponent(node.id)"
              @click.ctrl.exact="focusComponent(node.id, true)"
              @click.meta.exact="focusComponent(node.id, true)"
            >
              <IconWrapper
                class="mini-icon"
                :icon="icon"
                :mini-icon="miniIcon"
                :size="16"
              />

              <!-- EDIT COLLECTION NAME -->
              <input
                v-if="editLabel"
                alternative
                :placeholder="$t('knowledge.case-title-placeholder')"
                class="inputNodeLabel"
                ref="label"
                v-model="newLabel"
                @blur="
                  updateContent('label', newLabel);
                  editLabel = false;
                "
                @keyup.enter="$event.target.blur()"
              />
              <!-- COLLECTION NAME -->
              <TextEllipsis v-else class="card-title" :text="label" />
            </button>

            <div class="d-flex align-items-center mr-1">
              <TranslationTooltip
                v-if="!isTranslated"
                :is-translated="isTranslated"
                :is-content="!!node.type"
                :has-translated-contents="hasTranslatedContents"
              />
              <ParametricRootFolderCardOptions
                :user-permissions="node.userPermissions"
                @update-label="editCaseLabel()"
                @update-icon="modals.icon = true"
                @update-mini-icon="modals.miniIcon = true"
                @add-child="modals.addItem = true"
                @clone-kb="modals.cloneTo = true"
                @delete-node="openDeleteModal"
                @duplicate-in-same-location="
                  handleCloneToTarget({
                    caseName: node.label,
                    targetKnowledge: focusKnowledgeValue,
                    targetKnowledgeId: focusKnowledgeId,
                  })
                "
              />
            </div>
          </div>

          <!-- CARD BODY -->
          <div class="node-wrapper" v-loading="isLoading">
            <div v-if="flipTab === 0">
              <parametric-root-children
                class="card-item"
                v-for="el in nodes"
                :key="el.key"
                :node="el"
                @focus-component="focusItem(el)"
                @focus-component-tab="focusItem(el,true)"
                @update-content="$emit('update-content-published', $event)"
                @update-content-verification="updateContentVerification"
              />
              <div
                class="row cursor align-items-center mb-2"
                v-if="nodes.length == 0"
                @click.exact="focusComponent(node.id)"
                @click.ctrl.exact="focusComponent(node.id, true)"
                @click.meta.exact="focusComponent(node.id, true)"
              >
                <div class="col-12 text-center">
                  <img
                    src="~assets/empty-collection.png"
                    class="empty-collection-icon"
                  />
                  <p class="text-muted text-center">
                    <small>{{ $t('knowledge.root.empty-0') }}</small>
                  </p>
                </div>
              </div>
            </div>
            <div v-else class="description">
              {{ node.body }}
            </div>
          </div>

          <!-- FOOTER -->
          <div slot="footer">
            <div class="row footer-row justify-content-between mx-0">
              <div class="col-8 d-flex">
                <small
                  class="cta"
                  :class="[flipTab === 0 && body ? 'text-black' : 'text-muted']"
                  @click="flipTab = 0"
                >
                  {{
                    `${nodes.length} ${$tc(
                      'knowledge.element',
                      nodes.length > 1 ? 2 : 1,
                    )}`
                  }}
                </small>

                <small
                  v-if="body"
                  class="cta description"
                  :class="[flipTab === 1 ? 'text-black' : 'text-muted']"
                  @click="flipTab = 1"
                >
                  {{ $t('knowledge.description') }}
                </small>
              </div>
              <el-tooltip
                :content="$t('knowledge.actions.display-all')"
                placement="top"
              >
                <a
                  class="col-auto cursor text-right text-muted"
                  @click="focusComponent(node.id)"
                >
                  <font-awesome-icon class="eye-icon" :icon="['fad', 'eye']" />
                </a>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalEvents
      modal-name="DeleteContentModal"
      :uid="deleteModalUniqueKey"
      @choice="deleteChild"
    />
    <ModalEvents
      modal-name="SoftDeleteContentModal"
      :uid="deleteModalUniqueKey"
      @choice="softDeleteContent"
    />

    <IconEditionModal
      v-if="modals.icon || modals.miniIcon"
      :display="modals.icon || modals.miniIcon"
      :current-icon="focusedIcon"
      @choice="updateContentIcon($event)"
      @delete="handleDeleteIcon"
      @close="modals.icon = false"
    />
    <AddCollectionItemModal
      v-if="modals.addItem"
      :display="modals.addItem"
      @close="modals.addItem = false"
      @add-item="addItem($event)"
      @add-item-from-template="openTemplateModal"
    />

    <ModalEvents
      modal-name="AddTemplateModal"
      :uid="templateModalUniqueKey"
      @choice="addItemFromTemplate"
    />

    <CloneKnowledgeBaseModal
      v-if="modals.cloneTo"
      :display="modals.cloneTo"
      sourceType="case"
      :sourceLabel="label"
      :sourceId="node.id"
      @close="modals.cloneTo = false"
      @clone-to-target="handleCloneToTarget($event)"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import IconWrapper from '@/components/IconWrapper';
import ParametricRootFolderCardOptions from './ParametricRootFolderCardOptions';
import ParametricRootChildren from './ParametricRootChildren';
import ModalEvents from '@/components/Modals/ModalEvents';
import IconEditionModal from 'components/Modals/IconEditionModal/IconEditionModal';
import AddCollectionItemModal from 'components/Modals/AddCollectionItemModal';
import CloneKnowledgeBaseModal from 'components/Modals/CloneKnowledgeBaseModal/CloneKnowledgeBaseModal';
import TranslationTooltip from '@/components/Commons/TranslationTooltip';
import TextEllipsis from '@/components/TextEllipsis.vue';

export default {
  name: 'parametric-root-folder-card',
  components: {
    IconWrapper,
    ParametricRootFolderCardOptions,
    ParametricRootChildren,
    ModalEvents,
    IconEditionModal,
    AddCollectionItemModal,
    CloneKnowledgeBaseModal,
    TranslationTooltip,
    TextEllipsis,
  },
  props: {
    node: {
      type: Object,
      default: () => ({
        content: {
          label: '',
        },
        type: '',
      }),
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      newLabel: '', // this.node.content.label,
      editLabel: false,
      isHovered: false,
      timer: '',
      isLoading: false,
      modals: {
        addItem: false,
        delete: false,
        icon: false,
        miniIcon: false,
        cloneTo: false,
      },
      resultCount: 0,
      flipTab: 0,
    };
  },
  computed: {
    deleteModalUniqueKey() {
      return `${this.$options.name}-${this.node.id}`;
    },
    templateModalUniqueKey() {
      return `${this.$options.name}-template-${this.node.id}`;
    },
    type() {
      return this.node.type;
    },
    content() {
      return this.node.children;
    },
    label() {
      return this.node.label;
    },
    body() {
      return this.node.body;
    },
    nodes() {
      return this.node.orderedChildrenAndContents || [];
    },
    icon() {
      return this.node.icon;
    },
    miniIcon() {
      return this.node.miniIcon;
    },
    focusedIcon() {
      let currentIcon = 'icon';
      if (this.modals.miniIcon) currentIcon = 'miniIcon';
      return this.node[currentIcon] && this.node[currentIcon].iconType == 'fa'
        ? this.node[currentIcon]
        : { iconType: 'fa', value: 'folder', color: null };
    },
    isTranslated() {
      return this.node.returnLanguage === this.navigationLanguage;
    },
    hasTranslatedContents() {
      return this.node.untranslatedWithTranslatedContents;
    },
    ...mapGetters('knowledgeModule', [
      'navigationLanguage',
      'focusKnowledgeId',
      'focusKnowledgeValue',
    ]),
  },
  watch: {
    editLabel(val) {
      if (val) this.newLabel = this.label;
    },
    isHovered(val) {
      if (val) {
        if (this.nodes && this.nodes.length) return;
        this.isLoading = true;
        this.timer = setTimeout(async () => {
          await this.getCaseById({
            caseId: this.node.id,
            updateFocusRootState: true,
          });
          this.isLoading = false;
        }, 200);
      } else {
        clearTimeout(this.timer);
      }
    },
  },
  methods: {
    ...mapActions('knowledgeModule', ['bulkUpdateStatus', 'getCaseById']),
    async openDeleteModal({ softDeleted } = {}) {
      let loadingMessage = this.$message({
        duration: 0,
        dangerouslyUseHTMLString: true,
        iconClass: 'display: none',
        message: `<i class="fas fa-spinner fa-spin" style="margin-right: 1em;"></i> ${this.$t(
          'components.modals.delete-modal.loading',
        )}`,
      });
      this.resultCount =
        await this.$services.parametricContents.filterContentsCount(
          undefined, // knowledge name
          [], // product parameters
          [this.node.id],
          this.focusKnowledgeId,
        );
      loadingMessage.close();
      const payload = {
        component: softDeleted
          ? 'SoftDeleteContentModal'
          : 'DeleteContentModal',
        uid: this.deleteModalUniqueKey,
        props: softDeleted
          ? {
              display: true,
              type: 'content',
              toDeleteName: 'SELECTED-COLLECTION',
              caseCount: this.resultCount,
              label: this.label,
            }
          : {
              display: true,
              caseCount: this.resultCount,
              isCase: true,
              label: this.label,
            },
      };
      this.openModal(payload);
    },
    updateContent(type, value) {
      let payload;
      if (type === 'label') {
        if (!value || !value.trim()) return;

        payload = {
          label: value,
          title: value,
        };
      } else if (type === 'icon') {
        payload = { icon: value };
      } else if (type === 'published') {
        payload = value;
      }

      this.$emit('update-content', {
        id: this.content.id,
        payload,
      });
      this.modals.icon = false;
      this.editLabel = false;
    },
    updateContentIcon(icon) {
      let field = 'miniIcon';
      if (this.modals.icon) field = 'icon';
      this.$emit('update-content-icon', { icon, field });
      this.modals.icon = false;
      this.modals.miniIcon = false;
    },
    handleDeleteIcon() {
      let field = 'icon';
      if (this.modals.miniIcon) field = 'miniIcon';
      this.$emit('delete-content-icon', { field });
      this.modals.miniIcon = false;
      this.modals.icon = false;
    },
    editCaseLabel() {
      this.editLabel = true;
      this.$nextTick(() => this.$refs.label.focus());
    },
    addItem(item) {
      this.$emit('add-item', item);
      this.modals.addItem = false;
    },
    async openTemplateModal() {
      this.modals.addItem = false;
      const payload = {
        component: 'AddTemplateModal',
        uid: this.templateModalUniqueKey,
        props: {
          display: true,
        },
      };
      this.openModal(payload);
    },
    addItemFromTemplate(template) {
      const { label, type } = template.content;
      return this.$emit('add-item', {
        type,
        label,
        templateId: template.id,
      });
    },
    deleteChild(choice) {
      if (choice) this.$emit('delete-node', { caseId: this.node.id });
    },
    softDeleteContent(choice) {
      if (choice)
        return this.$emit('delete-node', {
          caseId: this.node.id,
          softDeleted: true,
        });
    },
    handleCloneToTarget(payload) {
      this.$emit('clone-case-to-target', payload);
      this.modals.cloneTo = false;
    },
    focusComponent(childId, newTab) {
      if (this.editLabel) return;

      const newCase = childId ? childId : this.node.id;
      this.$emit('focus-component', newCase, newTab);
    },
    focusItem(item, newTab) {
      switch (item.type) {
        case 'Article':
        case 'Diagnostic':
          this.$emit('focus-content', { content: item, caseRoute: this.node, newTab });
          break;
        default:
          this.$emit('focus-sub-component', { ...item, newTab});
          break;
      }
    },
    async updateContentVerification({ id, isOutdated }) {
      await this.bulkUpdateStatus({
        contentIds: [id],
        key: 'isOutdated',
        value: isOutdated,
      });
    },
    ...mapActions('modalsModule', ['openModal']),
  },
};
</script>

<style lang="scss" scoped>
.card-item,
.eye-icon {
  &:hover {
    color: $blue-mayday;
  }
}

.node-wrapper {
  overflow: auto;
  max-height: 128px;
  padding-left: 16px;
  padding-right: 16px;

  .description {
    word-break: break-word;
  }
}

.footer-row {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  line-height: 16px;
  margin-bottom: 4px;

  .description {
    border-left: 1px solid $grey-4-mayday;
    margin-left: 8px;
    padding-left: 8px;
  }

  .cta {
    cursor: pointer;
  }
}

.text-black {
  color: $grey-9-mayday;
}

.header-row {
  padding: 4px;
}

.home-card-icon {
  color: $grey-4-mayday;
  font-size: 48px;
  margin-top: 32px;
}

.collection-info {
  margin: 0 auto;
  margin-top: 4px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0 16px;

  h3 {
    font-size: 18px;
    line-height: 24px;
    width: 200px;
    max-height: 48px;
    color: $grey-9-mayday;
    font-weight: bold;
    margin-bottom: 0;
    position: relative;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    color: $grey-7-mayday;
  }
}

.root-card-translation-tooltip {
  position: absolute;
  top: 6px;
  right: -24px;
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 200px;
  perspective: 1000px;
  border-radius: 4px;
  // Fix options dropdown going behind other with dynamic z-index based on item's index
  position: relative;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: white;
  color: black;
  box-shadow: 0px 5px 30px rgba(112, 144, 176, 0.2);
  border-radius: 4px;
}

.flip-card-back {
  background-color: white;
  color: black;
  transform: rotateY(180deg);
  box-shadow: 0px 5px 40px $shadow-4-mayday;
  border-radius: 4px;
}

.inputNodeLabel::placeholder {
  color: $grey-4-mayday;
}

.inputNodeLabel:focus,
.inputNodeLabel:active,
.inputNodeLabel {
  margin: 0px;
  padding: 0px;
  outline: none;
  border: none;
  font-family: inherit;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 1.5;
  color: $blue-9-mayday;
}

.empty-collection-icon {
  max-width: 70px;
}

/* Content Style */
.card-icon {
  max-width: 40px;
}

.card-title {
  margin-bottom: 0px !important;
  word-break: break-word;
  color: $grey-9-mayday;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 1.5;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.card-title-wrapper {
  margin-left: 12px;
  min-width: 0;
  gap: 8px;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  width: calc(100% - 36px);
}

.card-title-wrapper-translation {
  width: calc(100% - 60px) !important;
}
</style>
