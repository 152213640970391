<template>
  <el-popover
    v-if="Object.keys(displayedHighlights).length > 0"
    placement="top"
    width="400"
    trigger="manual"
    visible-arrow
    v-model="visible"
    popper-class="prompt-popover"
  >
    <div class="d-flex flex-row justify-content-between p-2 align-items-center">
      <span>{{ $t('knowledge.search-admin.prompt-popover.title') }}</span>
      <font-awesome-icon
        :icon="['fal', 'times']"
        class="mr-2 close-icon"
        @click.stop="$emit('close-popover')"
      />
    </div>
    <el-divider direction="horizontal" class="m-0" />
    <el-collapse accordion class="p-2 explain-collapse">
      <el-collapse-item
        v-for="key in Object.keys(displayedHighlights)"
        :key="key"
        :class="{ 'no-arrow': noArrow(key) }"
        :disabled="noArrow(key)"
      >
        <template slot="title">
          {{ $t(`knowledge.search-admin.prompt-popover.location.${key}`) }}
        </template>
        <ExplainPromptPopoverHighlights
          v-if="!noArrow(key)"
          :highlights="displayedHighlights[key]"
        ></ExplainPromptPopoverHighlights>
      </el-collapse-item>
    </el-collapse>

    <el-tooltip
      slot="reference"
      :content="$t('knowledge.search-admin.prompt-popover.title')"
      placement="right"
      :disabled="visible"
    >
      <font-awesome-icon
        @click.prevent.stop="$emit('display-popover')"
        :icon="['fad', 'info-circle']"
        class="info-icon mx-2 leading-min"
      />
    </el-tooltip>
  </el-popover>
</template>

<script>
import ExplainPromptPopoverHighlights from './ExplainPromptPopoverHighlights.vue';

export default {
  name: 'explain-prompt',
  props: {
    explain: {
      type: Array,
      default: () => [],
    },
    searchLanguage: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: { ExplainPromptPopoverHighlights },
  data() {
    return {
      displayedHighlights: {},
    };
  },
  methods: {
    regroupHighlights(highlights) {
      return Object.keys(highlights).reduce((acc, val) => {
        if (this.isParametric(val))
          acc.parametric = [
            ...(acc.parametric ? [] : acc.parametric),
            ...highlights[val],
          ];
        if (this.isId(val))
          acc.id = [...(acc.id ? [] : acc.id), ...highlights[val]];
        acc[val] = highlights[val];
        return acc;
      }, {});
    },
    isParametric(field) {
      const regex = /^(facets|parameters\..*)$/;
      return regex.test(field);
    },
    isId(field) {
      const regex = /^(externalId|reference|uniqueIds\..*)$/;
      return regex.test(field);
    },
    setDisplayedHighlights() {
      let highlights = this.explain.reduce((acc, val) => {
        if (val.field === 'label') return acc;
        if (val.field === 'tags' || val.field === 'categories') {
          acc[val.field] = val.highlights;
          return acc;
        }
        if (val.lang !== this.searchLanguage) return acc;
        acc[val.field] = val.highlights;
        return acc;
      }, {});
      highlights = this.regroupHighlights(highlights);
      this.displayedHighlights = highlights;
    },
    noArrow(field) {
      return ['tags', 'categories'].includes(field);
    },
  },
  watch: {
    explain: {
      handler(newVal) {
        if (newVal && newVal.length) this.setDisplayedHighlights();
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.info-icon {
  color: $grey-5-mayday;
  font-size: 12px;
}

.explain-collapse :deep() {
  border: none;
  .no-arrow {
    .el-collapse-item__arrow {
      display: none;
    }
    .el-collapse-item__header {
      color: #333;
      cursor: default;
      &:hover {
        color: #333;
      }
    }
  }
  .el-collapse-item__header {
    border: none;

    height: 24px;
    &:hover {
      color: $blue-mayday;
    }

    .collapse-title {
      font-family: 'Lato', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.02em;
      align-items: center;
      display: inline-flex;
      gap: 8px;
    }
  }

  .el-collapse-item__content {
    float: right;
    width: 100%;
    padding-left: 8px;
  }
  .el-collapse-item__wrap {
    border: none;
  }
}
.close-icon {
  &:hover {
    color: $blue-mayday;
    cursor: pointer;
  }
}
</style>
