<template>
  <div>
    <AnalyticsStatsCard
      :loading="usageStatsLoading"
      class="analytics-usage-stats-card"
      :stats="usageStats"
      :title="$t('new-dashboard.header.title.analytics-usages-stats')"
      :tooltip-content="
        $t(
          'new-dashboard.header.tooltip.analytics-usages.overview-stats.overview',
        )
      "
      :external-doc-url="externalDocUrl"
      flex
    >
      <template v-slot:userReturnsRepartition="{ footerStat }">
        <p class="stat-footer">
          {{
            $t(
              'new-dashboard.stat-card.additional-stats-usage.user-returns-repartition',
              {
                feedbackCount: footerStat.feedbackCount,
                satisfactionCount: footerStat.satisfactionCount,
              },
            )
          }}
        </p>
      </template>

      <template v-slot:groupRepartition="{ footerStat }">
        <p class="stat-footer">
          {{
            $t(
              'new-dashboard.stat-card.additional-stats-usage.group-repartition',
              { groupCount: footerStat },
            )
          }}
        </p>
      </template>

      <template v-slot:adoptionRateDetail="{ footerStat }">
        <p class="stat-footer">
          {{
            $t(
              'new-dashboard.stat-card.additional-stats-usage.adoption-rate-detail',
              footerStat,
            )
          }}
        </p>
      </template>

      <template v-slot:createdUsers="{ footerStat }">
        <p class="stat-footer">
          {{
            $t('new-dashboard.stat-card.additional-stats-usage.deleted-users', {
              deletedCount: footerStat,
            })
          }}
        </p>
      </template>

      <template v-slot:trendText="{ footerStat }">
        <p class="stat-footer">
          {{
            $t(
              `new-dashboard.stat-card.additional-stats-usage.stat-trend-${footerStat}`,
            )
          }}
          {{
            $t(
              'new-dashboard.stat-card.additional-stats-usage.stat-trend-timeframe',
            )
          }}
        </p>
      </template>
      <template v-slot:trendBadge="{ additionalStat }">
        <font-awesome-icon
          :class="additionalStat"
          class="trend-badge"
          :icon="['fad', 'arrow-right']"
        />
      </template>
      <template v-slot:adoptionRateChart="{ additionalStat: chartData }">
        <div
          @click="openAdoptionRateChartModal"
          class="adoption-rate-chart-overview-container"
        >
          <a class="adoption-rate-chart-modal-link"
            ><font-awesome-icon :icon="['fad', 'search']"
          /></a>
          <LineChart
            v-if="adoptionRateDataIsReady"
            :chart-data="chartData"
            :height="60"
            :width="130"
            :extra-options="adoptionRateChartOptions"
          />
        </div>
      </template>
      <template v-slot:tooltip-content="{ content }">
        <span>{{ $t(content) }}</span>
      </template>
    </AnalyticsStatsCard>
    <el-divider></el-divider>
    <AnalyticsAdoptionRateModal
      v-if="adoptionRateModalChart"
      :show.sync="showAdoptionRateModal"
      :chart-data="adoptionRateModalChart"
      :date-range="filterDateRangeValue"
    />
    <AnalyticsUsageModal
      :show="showUsageModal"
      :group-name="usageModalGroupName"
      :user-name="usageModalUsername"
      :deleted-at="usageModalDeletedAt"
      :labels="usageModalLabels"
      :stats-is-loading="usageModalDetailsIsLoading"
      :stats="usageModalStats"
      :table-is-loading="usageModalTableIsLoading"
      :table-data-options.sync="usageModalTableDataOptionsModel"
      :table-data="usageModalTableData"
      :table-total-count="usageModalTableTotalCount"
      :table-current-page="usageModalTableCurrentPage"
      :table-total-pages="usageModalTableTotalPages"
      :table-is-ready="usageModalTableIsReady"
      :chart-data="usageModalChartData"
      :chart-is-loading="usageModalChartIsLoading"
      :chart-is-empty="usageModalChartIsEmpty"
      :feedbacks="usageModalFeedbacks"
      :external-doc-url="externalDocUrl"
      @close="hideUsageModal"
    />

    <AnalyticsUsagesTable
      v-if="usageTableIsReady"
      :views-filter.sync="usageTableIsWithViewsModel"
      :search-filter.sync="usageTableSearchModel"
      :data-options.sync="usageTableDataOptionsModel"
      :data="usageTableData"
      :is-loading="usageTableDataIsLoading"
      :is-empty="usageTableIsEmpty"
      :total-count="usageTableTotalCount"
      :external-doc-url="externalDocUrl"
      :download="downloadTable"
      @show-details="prepareUsageModalDetails"
      @open-filters-drawer="() => $emit('open-filters-drawer')"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('dashboard');
import AnalyticsStatsCard from '../AnalyticsStatsCard';
import AnalyticsUsagesTable from './AnalyticsUsagesTable';
import AnalyticsUsageModal from './AnalyticsUsageModal/AnalyticsUsageModal';
import LineChart from '@/components/Charts/LineChart';
import AnalyticsAdoptionRateModal from './AnalyticsAdoptionRateModal';

export default {
  props: {
    externalDocUrl: {
      type: String,
      required: true,
    },
  },

  components: {
    AnalyticsStatsCard,
    AnalyticsUsagesTable,
    AnalyticsUsageModal,
    LineChart,
    AnalyticsAdoptionRateModal,
  },

  data() {
    return {
      adoptionRateChartOptions: {
        scales: {
          xAxes: [{ display: false }],
          yAxes: [{ display: false }],
        },
        tooltips: {
          enabled: false,
        },
      },
      showAdoptionRateModal: false,
    };
  },

  created() {
    this.initUsageTableSortOptions();
    this.initUsageModalTableSortOptions();
    this.initAdditionalUsageTableFilters();
  },

  computed: {
    ...mapGetters([
      // USAGE PAGE
      'usageStats',
      'usageStatsLoading',
      'adoptionRateChart',
      'adoptionRateModalChart',
      'filterDateRangeValue',
      'usageTableIsWithViews',
      'usageTableSearchFilter',
      'usageTableData',
      'usageTableDataIsLoading',
      'usageTableTotalCount',
      'usageTableTotalPages',
      'usageTableCurrentPage',
      'usageTableDataOptions',
      'usageTableIsEmpty',
      'usageTableIsReady',
      // USAGE MODAL
      'showUsageModal',
      'usageModalDetailsIsLoading',
      'usageModalUsername',
      'usageModalDeletedAt',
      'usageModalGroupName',
      'usageModalLabels',
      'usageModalStats',
      'usageModalTableData',
      'usageModalTableIsLoading',
      'usageModalTableTotalCount',
      'usageModalTableTotalPages',
      'usageModalTableCurrentPage',
      'usageModalTableDataOptions',
      'usageModalChartData',
      'usageModalChartIsEmpty',
      'usageModalChartIsLoading',
      'adoptionRateDataIsReady',
      'usageModalTableIsReady',
      'usageModalFeedbacks',
    ]),

    usageTableIsWithViewsModel: {
      get() {
        return this.usageTableIsWithViews;
      },
      set(value) {
        this.updateUsageTableIsWithViews(value);
        return value;
      },
    },

    usageTableSearchModel: {
      get() {
        return this.usageTableSearchFilter;
      },
      set(value) {
        this.updateUsageTableSearch(value);
        return value;
      },
    },

    usageTableDataOptionsModel: {
      get() {
        return this.usageTableDataOptions;
      },
      set(value) {
        this.updateUsageTableDataOptions(value);
        return value;
      },
    },

    usageModalTableDataOptionsModel: {
      get() {
        return this.usageModalTableDataOptions;
      },
      set(value) {
        this.updateUsageModalTableDataOptions(value);
        return value;
      },
    },
  },
  methods: {
    ...mapActions([
      'downloadTable',
      // USAGE PAGE
      'initAdditionalUsageTableFilters',
      'updateUsageTableIsWithViews',
      'updateUsageTableSearch',
      'updateUsageTableDataOptions',
      'updateUsageModalTableDataOptions',
      'initUsageTableSortOptions',
      // USAGE MODAL
      'hideUsageModal',
      'prepareUsageModalDetails',
      'initUsageModalTableSortOptions',
    ]),

    openAdoptionRateChartModal() {
      this.showAdoptionRateModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-usage-stats-card {
  margin-bottom: 24px;

  .adoption-rate-chart-overview-container {
    text-align: right;
    cursor: pointer;
    max-height: 60px;
    &:hover {
      filter: saturate(2.2);
      color: $blue-mayday;
    }
    .adoption-rate-chart-modal-link {
      position: absolute;
      margin-left: auto;
      max-height: 12px;
      &:hover {
        color: $blue-mayday;
      }
    }
  }
  // ! TEMPORARY
  :deep() .stat-cards-wrapper.flex-wrapper .stat-container {
    flex-basis: 25%;
  }
}
.stat-footer {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: $grey-6-mayday;
}
.trend-badge {
  width: 90%;
  height: 90%;

  &.up {
    transform: rotate(-45deg);
  }
  &.down {
    transform: rotate(45deg);
  }
}
.header-icon {
  color: $grey-5-mayday;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}
</style>
