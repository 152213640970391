<template>
  <div class="d-flex flex-column gap-2">
    <SmartActionsDrawer
      v-for="(currentPlugin, index) in currentPluginEntities"
      :key="`${currentPlugin.id}_${index}`"
      :currentPlugin="currentPlugin"
      :index="index"
      :errors="errors.length && errors[index] ? errors[index] : undefined"
      :config="config"
      :disable-delete="currentPluginEntities.length < 2"
      @update-plugin="$emit('update-plugin', { index, ...$event })"
      @delete-plugin="$emit('delete-plugin', index)"
    />
    <div
      @click="$emit('add-plugin')"
      class="add-plugin-entity-box d-flex justify-content-center align-items-center gap-2 p-4 bg-white w-100 overflow-hidden"
    >
      <font-awesome-icon :icon="['fal', 'circle-plus']" />
      <TextEllipsis
        fontSize="14px"
        :text="
          $t(
            'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.tabs.add-more-button',
          )
        "
      ></TextEllipsis>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import SmartActionsDrawer from './SmartActionsDrawer.vue';
import TextEllipsis from 'components/TextEllipsis.vue';

export default defineComponent({
  name: 'smart-actions-list',
  components: {
    SmartActionsDrawer,
    TextEllipsis,
  },
  props: {
    currentPluginEntities: {
      type: Array,
      required: true,
    },
    config: {
      type: Object,
    },
    errors: {
      type: Array,
    },
  },
});
</script>

<style lang="scss" scoped>
.add-plugin-entity-box {
  border: 1px dashed;
  border-radius: 4px;
  border-color: $grey-4-mayday;
  color: $grey-5-mayday;
  transition: border-color 250ms ease-in-out;

  &:hover {
    cursor: pointer;
    border-color: $grey-7-mayday;
  }
}
</style>
