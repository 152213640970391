<template>
  <div class="thread__timeline">
    <div class="thread__timeline-date">{{ firstDate }}</div>
    <div class="thread__timeline-scroll">
      <div
        id="thread-timeline"
        class="thread__timeline-bar"
        @click="handleClick"
      ></div>
      <div
        class="thread__timeline-handle"
        :style="handlePosition"
        @mousedown="handleDrag"
      >
        <span class="thread__timeline-handle-date">
          {{ computeIndex ? dates[computeIndex] : '' }}
        </span>
      </div>
    </div>
    <div class="thread__timeline-date">{{ lastDate }}</div>
  </div>
</template>

<script>
export default {
  name: 'thread-timeline',
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    scroll: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      height: 168,
    };
  },
  created() {
    document.addEventListener('mouseup', this.stopDrag);
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.stopDrag);
  },
  computed: {
    handlePosition() {
      const offset = 12;
      return { top: `${this.scroll * this.height + offset}px` };
    },
    computeIndex() {
      return Math.round(this.dates.length * this.scroll);
    },
    dates() {
      return this.messages.map(({ createdAt }) =>
        new Date(parseInt(createdAt)).toLocaleDateString(),
      );
    },
    lastDate() {
      const last = this.dates.at(-1);
      if (new Date().toLocaleDateString() === last) {
        return this.$t('notifications.today');
      }

      return last;
    },
    firstDate() {
      const first = this.dates.at(0);
      if (new Date().toLocaleDateString() === first) {
        return this.$t('notifications.today');
      }

      return first;
    },
  },
  methods: {
    handleClick({ offsetY }) {
      const percentage = offsetY / this.height;
      this.$emit('drag', percentage);
    },
    handleDrag() {
      document.addEventListener('mousemove', this.handleMove);
    },
    handleMove({ pageY }) {
      // scroll bar top position
      const { top } = document
        .getElementById('thread-timeline')
        .getBoundingClientRect();
      // clamp diff
      const diff = Math.min(Math.max(pageY - top, 0), this.height);
      this.$emit('drag', diff / this.height);
    },
    stopDrag() {
      document.removeEventListener('mousemove', this.handleMove);
    },
  },
};
</script>

<style lang="scss" scoped>
.thread__timeline {
  position: absolute;
  height: 100%;
  width: 64px;
  top: 0px;
  right: 0px;

  &-date {
    font-weight: 500;
    font-size: 8px;
    line-height: 16px;
    color: $grey-6-mayday;
  }

  &-scroll {
    height: calc(100% - 32px);
  }

  &-bar {
    height: 100%;
    width: 3px;
    background-color: $grey-3-mayday;
    cursor: pointer;
  }

  &-handle {
    left: -2.5px;
    position: absolute;
    user-select: none;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $grey-7-mayday;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    &:hover {
      background-color: $blue-mayday;
    }
    &:active {
      background-color: $blue-mayday;
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }

    display: flex;
    align-items: center;
    &-date {
      margin-left: 12px;
      font-weight: 500;
      font-size: 8px;
      color: $grey-8-mayday;
    }
  }
}
</style>
