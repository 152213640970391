<template>
  <Transition name="grow">
    <div class="loading-bar" :style="animationStyle" />
  </Transition>
</template>

<script>
export default {
  name: 'LoadingBar',
  props: {
    width: {
      type: Number,
      default: 100,
      description: 'Width of the loading bar in percentage',
    },
    delay: {
      type: Number,
      default: 0,
      description: 'Delay before the loading bar starts',
    },
  },
  computed: {
    animationStyle() {
      return `max-width: ${this.width}%;animation-delay: ${this.delay}ms`;
    },
  },
};
</script>

<style scoped lang="scss">
.loading-bar {
  width: 100%;
  height: 16px;
  background-size: 400% !important;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  border-radius: 4px;
  animation: bar-idle 1.2s ease-in-out infinite, bar-open 0.4s ease-in-out;
}

.grow-leave-active {
  animation: bar-close 0.4s ease-in-out;
}

@keyframes bar-idle {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@keyframes bar-close {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

@keyframes bar-open {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
