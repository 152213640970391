<template>
  <div>
    <span
      v-html="
        sanitize(
          $tc(
            `knowledge.search-admin.prompt-popover.subtitle`,
            highlights.length,
          ),
        )
      "
      class="explain-occurrence"
    ></span>
    <div class="prompt-callout">
      <span v-html="sanitize(highlights[step])"></span>
    </div>
    <div v-if="highlights.length > 1" class="prompt-footer">
      <div class="prompt-count">
        {{
          $t('knowledge.search-admin.prompt-popover.occurrence', {
            counter: `${step + 1}/${highlights.length}`,
          })
        }}
      </div>
      <div>
        <el-button
          v-if="step > 0"
          @click="step -= 1"
          size="mini"
          plain
          type="secondary"
          >{{ $t('knowledge.search-admin.prompt-popover.previous') }}</el-button
        >
        <el-button
          @click="step += 1"
          size="mini"
          type="primary"
          :disabled="step === highlights.length - 1"
          >{{ $t('knowledge.search-admin.prompt-popover.next') }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { sanitize } from '@/plugins/dompurify';

export default {
  name: 'explain-prompt',
  props: {
    highlights: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      step: 0,
      sanitize: sanitize,
    };
  },

  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.prompt-callout {
  text-align: left;
  height: 71px;
  padding: 6px;
  background-color: $grey-2-mayday;
  font-size: 14px;
  word-break: break-word;
  overflow-y: auto;

  .prompt-related {
    display: block;
    font-style: italic;
    font-size: 12px;
  }

  :deep() em {
    font-weight: 700;
  }
}

.prompt-footer {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prompt-count {
  font-size: 12px;
  color: $grey-7-mayday;
}
.explain-occurrence {
  word-break: auto-phrase;
}
</style>
