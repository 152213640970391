<template>
  <div v-if="recentlyConsultedContentsFormatted.length">
    <div class="title" v-if="displayTitle">
      {{ $t('knowledge.search-admin.recently-consulted') }}
    </div>
    <div>
      <div
        v-for="(content, index) in recentlyConsultedContentsFormatted"
        :key="content.id"
      >
        <SuggestionItem
          :item="content"
          :hovered="index === hovered"
          :isRecentlyConsultedSuggestion="true"
          @mouseenter.native="onHover(index)"
          @mouseleave.native="onHover(-1)"
          @go-to-document="handleGoToDocument($event, index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SuggestionItem from './SuggestionItem';

export default {
  name: 'search-recently-consulted',
  components: {
    SuggestionItem,
  },
  props: {
    contents: {
      type: Array,
      default: () => [],
    },
    isMaydayWeb: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hovered: -1,
    };
  },
  methods: {
    onHover(index) {
      this.hovered = index;
    },
    scrollToSelectedItem(direction) {
      this.$nextTick(() => {
        const el = document.querySelectorAll('[class*="is-hovered"]');
        if (el) {
          el[0].scrollIntoView({ block: direction, inline: 'nearest' });
        }
      });
    },
    handleGoToDocument(doc, index) {
      this.$emit('go-to-document', { ...doc, index });
    },
  },
  computed: {
    displayTitle() {
      return (
        this.recentlyConsultedContentsFormatted &&
        this.recentlyConsultedContentsFormatted.length
      );
    },
    recentlyConsultedContentsFormatted() {
      const focusContentId = this.$route.params.contentId;
      const filteredContents = focusContentId
        ? this.contents.filter((i) => !focusContentId.includes(i.id))
        : this.contents;
      return filteredContents.slice(0, 5);
    },
  },
  mounted() {
    this._keyListener = (e) => {
      if (
        e.key === 'Enter' &&
        this.hovered >= 0 &&
        this.hovered <= this.contents.length
      ) {
        e.preventDefault();
        this.$emit('go-to-document', this.contents[this.hovered]);
      } else if (e.key === 'ArrowUp' && !this.isMaydayWeb) {
        this.hovered =
          this.hovered > 0 ? this.hovered - 1 : this.contents.length - 1;
        this.scrollToSelectedItem('end');
      } else if (e.key === 'ArrowDown' && !this.isMaydayWeb) {
        this.hovered =
          this.hovered < this.contents.length - 1 ? this.hovered + 1 : 0;
        this.scrollToSelectedItem('start');
      }
    };
    document.addEventListener('keydown', this._keyListener.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener);
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding-left: 16px;
  font-weight: bold;
  font-size: 12px;
  color: $grey-6-mayday;
}
.content-icon {
  max-width: 16px;
  max-height: 16px;
}
</style>
