<template>
  <section>
    <slot name="header-list" :show-bulk-actions="edit.showBulk"></slot>
    <ContentBulkActionSelect
      v-if="contentsDisplayedCount"
      :selected-count="selectedContents.length"
      :show-bulk="edit.showBulk"
      :show-select-all="showSelectAll"
      :show-select="showSelect"
      :display-clone="displayClone"
      :user-permissions="permissions"
      :is-result-list="isResultList"
      :disable-actions="isSelectionIdsEmpty"
      @show-bulk="edit.showBulk = !edit.showBulk"
      @edit-contents="handleEditContents"
      @edit-product-parameters="edit.products = true"
      @update-status="handleBulkUpdateStatus"
      @archive="openArchiveModal"
      @bulk-delete="openSoftDeleteModal"
      @clone="handleBulkClone"
      @duplicate-in-same-location="handleBulkDuplicate"
      @select-all="selectAllContents"
      @unselect-all="selectedContents = []"
    />
    <slot
      name="main-list"
      :show-bulk-actions="edit.showBulk"
      :selected-contents="selectedContents"
      :updateSelectedContents="updateSelectedContents"
    >
    </slot>

    <slot name="empty-state"> </slot>

    <slot name="footer-list"> </slot>

    <ContentAttributesModal
      v-if="edit.attributes"
      :display="edit.attributes"
      :content-qty="selectedContents.length"
      :focus-knowledge="hasKnowledgeFilter"
      :option="editAttributesOption"
      :labels-to-remove="labelsToRemove"
      :access-restrictions-ids-to-remove="accessRestrictionsIdsToRemove"
      :label-ids-in-common="labelIdsInCommon"
      :access-restriction-ids-in-common="accessRestrictionIdsInCommon"
      @update-attributes="handleBulkUpdateAttributes"
      @close="edit.attributes = false"
    />
    <ContentParametersEditionModal
      v-if="edit.products"
      :display="edit.products"
      :content-qty="selectedContents.length"
      @update-parameters="handleBulkUpdateParameters"
      @close="edit.products = false"
    />

    <ModalEvents
      modal-name="DeleteContentModal"
      :uid="deleteModalUniqueKey"
      @choice="handleBulkArchiveContents"
    />
    <ModalEvents
      modal-name="SoftDeleteContentModal"
      :uid="deleteModalUniqueKey"
      @choice="handleBulkDeleteContents"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ContentBulkActionSelect from '@/components/ListItems/ContentBulkActionSelect.vue';
import ContentAttributesModal from '@/components/Modals/ContentAttributesModal/ContentAttributesModal';
import ContentParametersEditionModal from '@/components/Modals/ContentParametersEditionModal';
import ModalEvents from '@/components/Modals/ModalEvents';

export default {
  components: {
    ContentBulkActionSelect,
    ContentAttributesModal,
    ContentParametersEditionModal,
    ModalEvents,
  },
  props: {
    contentsDisplayedCount: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    showSelectAll: {
      type: Boolean,
      default: true,
    },
    bulkTrigger: {
      type: Boolean,
      default: false,
    },
    displayClone: {
      type: Boolean,
      default: true,
    },
    isResultList: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      contentListShow: true,
      selectedContents: [],
      edit: {
        showBulk: false,
        attributes: false,
        products: false,
      },
      editAttributesOption: '',
      cloneToTargetModal: false,
    };
  },
  methods: {
    updateSelectedContents(selectedContents) {
      this.selectedContents = selectedContents;
    },
    handleEditContents({ option }) {
      this.edit.attributes = true;
      this.editAttributesOption = option;
    },
    selectAllContents() {
      this.selectedContents = this.contents.map((content) => ({
        id: content.id,
        userPermissions: content.userPermissions,
        labels: content.labels,
        accessRestrictions: content.accessRestrictions,
      }));
    },

    // BULK HANDLERS

    // This one is a bit special
    // Since we can also clone a single content and case, parent needs to
    // handle the modal
    handleBulkClone() {
      const contents = this.selectedContentsIds.map((id) => ({ id }));
      this.$emit('open-clone-contents-to-target-modal', contents);
    },
    handleBulkDuplicate() {
      const contents = this.selectedContentsIds.map((id) => ({ id }));
      this.$emit('duplicate-in-same-location', { type: 'contents', contents });
    },
    async handleBulkUpdateAttributes({ toAdd, toRemove }) {
      if (this.isSelectionIdsEmpty) return;

      this.triggerBulkLoadingMessage();
      const payload = {
        contentIds: this.selectedContentsIds,
        toAdd,
        toRemove,
        isGranularAccessRestrictions: this.isGranularAccessRestrictions,
      };
      await this.bulkUpdateAttributes(payload);
      this.$emit('bulk-update-attributes', payload);
      this.loadingMessage.close();
      this.selectedContents = [];
    },
    async handleBulkUpdateStatus({ key, value }) {
      if (this.isSelectionIdsEmpty) return;

      this.triggerBulkLoadingMessage();
      const payload = {
        contentIds: this.selectedContentsIds,
        key,
        value,
      };
      await this.bulkUpdateStatus(payload);
      this.$emit('bulk-update-status', payload);
      this.loadingMessage.close();
    },
    openArchiveModal() {
      if (this.isSelectionIdsEmpty) return;
      const payload = {
        component: 'DeleteContentModal',
        uid: this.deleteModalUniqueKey,
        props: {
          display: true,
          bulkCount: this.selectedContentsIds.length,
        },
      };
      this.openModal(payload);
    },

    openSoftDeleteModal() {
      if (this.isSelectionIdsEmpty) return;
      const payload = {
        component: 'SoftDeleteContentModal',
        uid: this.deleteModalUniqueKey,
        props: {
          display: true,
          type: 'content',
          toDeleteName: 'SELECTED-CONTENTS',
          bulkCount: this.selectedContentsIds.length,
        },
      };
      this.openModal(payload);
    },

    handleBulkDeleteContents(choice) {
      if (choice) {
        this.triggerBulkLoadingMessage(true);

        this.$emit('bulk-soft-delete-contents', {
          contentIds: this.selectedContents.map((content) => content.id),
          softDeleted: true,
        });
        this.loadingMessage.close();
        this.selectedContents = [];
        this.displayDeleteModal = false;
      }
    },
    async handleBulkArchiveContents(choice) {
      if (choice) {
        this.triggerBulkLoadingMessage(true);

        await this.bulkArchiveContents({
          contentIds: this.selectedContentsIds,
        });
        this.$emit('bulk-archive-contents', {
          contentIds: this.selectedContentsIds,
        });
        this.loadingMessage.close();
        this.selectedContents = [];
      }
    },
    async handleBulkUpdateParameters({ knowledge, parameters }) {
      if (this.isSelectionIdsEmpty) return;

      this.triggerBulkLoadingMessage();
      await this.bulkUpdateParameters({
        contentIds: this.selectedContentsIds,
        knowledge,
        parameters,
      });
      this.loadingMessage.close();
    },
    triggerBulkLoadingMessage(isArchive = false) {
      const message = isArchive
        ? `<i class="fas fa-spinner fa-spin" style="margin-right: 1em;"></i> Archivage des contenus sélectionnés en cours ...`
        : `<i class="fas fa-spinner fa-spin" style="margin-right: 1em;"></i> Mise à jour groupée en cours ...`;
      this.loadingMessage = this.$message({
        duration: 0,
        dangerouslyUseHTMLString: true,
        iconClass: 'display: none',
        showClose: true,
        message,
      });
    },
    ...mapActions('knowledgeModule', [
      'bulkUpdateAttributes',
      'bulkUpdateStatus',
      'bulkArchiveContents',
      'bulkUpdateParameters',
    ]),
    ...mapActions('modalsModule', ['openModal']),
  },
  computed: {
    showBulk() {
      return this.edit.showBulk;
    },
    contents() {
      return this.items.filter((item) => item.type);
    },
    isSelectionIdsEmpty() {
      return !this.selectedContentsIds || !this.selectedContentsIds.length;
    },
    isCaseDisplay() {
      return this.$route.name.includes('case');
    },
    contentCount() {
      return this.count ? this.count : this.contents.length;
    },
    hasKnowledgeFilter() {
      if (this.$route.query.knowledge) {
        return this.$route.query.knowledge;
      }
      if (this.focusKnowledgeValue) {
        return this.focusKnowledgeValue;
      }
      return null;
    },
    deleteModalUniqueKey() {
      return `${this.$options.name}-${this.currentCaseId}`;
    },
    currentCaseId() {
      return this.focusCollection ? this.focusCollection.id : null;
    },
    selectedContentsIds() {
      return this.selectedContents.map((content) => content.id);
    },
    selectedComputedPermissions() {
      return this.selectedContents.reduce(
        (computedPermissions, content) => {
          const contentPermissions = content.userPermissions || {};
          computedPermissions = Object.keys(computedPermissions).reduce(
            (acc, key) => {
              // Immediately return if the key is already false
              if (!computedPermissions[key]) return acc;

              acc[key] = Boolean(contentPermissions[key]);
              return acc;
            },
            computedPermissions,
          );

          return computedPermissions;
        },
        {
          canCreateContent: true,
          canDeleteContent: true,
          canUpdateContent: true,
          canVerifyContent: true,
        },
      );
    },
    labelIdsInCommon() {
      if (!this.selectedContents.length) return [];
      if (this.selectedContents.length === 1)
        return this.selectedContents[0].labels.map((l) => l.id);
      const labelsIdsAsArray = this.selectedContents.map((content) => {
        return content.labels.map((label) => label.id);
      });
      if (!labelsIdsAsArray.length) return [];
      return labelsIdsAsArray.reduce(function (a, b) {
        return a.filter(function (id) {
          return b.includes(id);
        });
      });
    },
    accessRestrictionIdsInCommon() {
      if (!this.selectedContents.length || !this.isParametric) return [];
      if (this.selectedContents.length === 1)
        return this.selectedContents[0].accessRestrictions;
      const accessRestrictionIdsAsArray = this.selectedContents.map(
        (content) => content.accessRestrictions,
      );
      return accessRestrictionIdsAsArray.reduce(function (a, b) {
        return a.filter(function (id) {
          return b.includes(id);
        });
      });
    },
    permissions() {
      return this.isResultList
        ? this.selectedComputedPermissions
        : this.userPermissions;
    },
    labelsToRemove() {
      if (!this.selectedContents.length) return [];
      return this.selectedContents
        .reduce((acc, item) => {
          if (item.labels && item.labels.length) acc.push(...item.labels);
          return acc;
        }, [])
        .filter(
          (obj, index, self) =>
            index === self.findIndex((t) => t.id === obj.id),
        );
    },
    accessRestrictionsIdsToRemove() {
      if (!this.selectedContents.length || !this.isParametric) return [];

      return this.selectedContents
        .reduce((acc, item) => {
          if (item.accessRestrictions && item.accessRestrictions.length)
            acc.push(...item.accessRestrictions);
          return acc;
        }, [])
        .filter(
          (obj, index, self) => index === self.findIndex((t) => t === obj),
        );
    },
    ...mapGetters(['isGranularAccessRestrictions', 'isParametric']),
    ...mapGetters('knowledgeModule', [
      'focusKnowledgeValue',
      'focusCollection',
    ]),
  },
  watch: {
    bulkTrigger() {
      this.edit.showBulk = false;
    },
    showBulk() {
      this.selectedContents = [];
    },
  },
};
</script>

<style lang="scss" scoped></style>
