<template>
  <div class="node-item">
    <input
      v-if="edit"
      alternative
      :placeholder="$t('knowledge.actions.input-placeholder')"
      :maxlength="inputMaxLength"
      class="w-100 inputNodeLabel"
      ref="labelInput"
      v-model="newLabel"
      @blur="updateContent()"
      @keyup.enter="$event.target.blur()"
      @keyup.escape="$emit('escape-edit')"
      @input="notifyUserLength"
    />
    <TextEllipsis
      v-else
      :text="label"
      class="inputNode"
      @click.native="handleClick"
    />
  </div>
</template>

<script>
import TextEllipsis from '@/components/TextEllipsis.vue';

export default {
  name: 'common-node-label',
  components: {
    TextEllipsis,
  },
  props: {
    edit: Boolean,
    label: String,
    parentIds: Array,
    nodeType: String,
  },
  data() {
    return {
      newLabel: '',
      maxCharacter: 35,
    };
  },
  computed: {
    inputMaxLength() {
      if (this.nodeType === 'Folder') return this.maxCharacter;
      return undefined;
    },
  },
  mounted() {
    this.newLabel = this.label;
    if (!this.label === '' && this.edit) this.$emit('edit-label');
  },
  methods: {
    notifyUserLength() {
      if (this.inputMaxLength && this.newLabel.length === this.maxCharacter)
        this.$notify({
          title: 'Attention',
          message: 'Ne donnez pas des titres trop longs à vos collections',
          type: 'info',
        });
    },
    updateContent() {
      if (this.newLabel !== '') {
        this.$emit('update-content', {
          label: this.newLabel,
          title: this.newLabel,
        });
      }
    },
    handleClick(e) {
      if (e.metaKey || e.ctrlKey) {
        return this.$emit('click');
      }
      return this.$emit('focus');
    },
  },
  watch: {
    edit() {
      if (this.edit) {
        this.$nextTick(() => this.$refs['labelInput'].focus());
        this.newLabel = this.label;
      }
    },
    label() {
      if (this.label === '') this.$emit('edit-label');
    },
  },
};
</script>

<style lang="scss" scoped>
.node-item {
  padding-left: 2px;
  padding-right: 2px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.node-item .focus {
  z-index: 0;
  color: $blue-mayday;
}

.inputNode {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px !important;
  font-weight: 600 !important;
  word-break: break-all;
  border: 0px;
  outline: none;
  background-color: transparent;
  color: $black-mayday;
  cursor: pointer;
  height: 20px;
  line-height: 1.7;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.inputNode:focus {
  background-color: white;
  color: $grey-7-mayday;
}

.inputNodeLabel {
  color: $grey-6-mayday;
  background-color: $grey-3-mayday;
  padding-left: 5px;
  border: 0px;
  outline: none;
  border-radius: 2px;
  font-size: 12px;
  height: 20px;
  line-height: 1.7;
}

.magic-badge {
  margin-left: 10px;
  color: $yellow-mayday;
  margin-top: -15px;
}

/* END NEW STYLE */

.isDraft .inputNode {
  color: $grey-5-mayday;
}

.node-item .focus {
  z-index: 0;
}

.focus {
  background-color: white;
  border-radius: 2px;
}
</style>
