<template>
  <div class="action-items-section">
    <div
      :class="[
        'action-items-section__empty',
        {
          expanded: isEmpty,
        },
      ]"
    >
      <div class="action-items-section__empty-text">
        <div class="action-items-section__empty-title">
          {{ $t('knowledge.ai-drawer.action-items.title') }}
          <AiBadge size="medium" />
          <el-tooltip
            :content="$t('knowledge.ai-drawer.action-items.tooltip')"
            placement="top"
          >
            <font-awesome-icon
              :icon="['fal', 'info-circle']"
              class="action-items-section__infos"
            />
          </el-tooltip>
        </div>
        <div class="action-items-section__empty-description">
          {{ $t('knowledge.ai-drawer.action-items.description') }}
        </div>
      </div>
      <button
        @click="$emit('generate-action-items')"
        class="btn-primary btn-sm btn"
      >
        <font-awesome-icon :icon="['fal', 'bolt']" />
        {{ $t('knowledge.ai-drawer.generate') }}
      </button>
    </div>
    <div
      :class="[
        'action-items-section__body',
        { loading: generatingAnimation },
        { expanded: actionItems && actionItems.length },
        { editing: editing },
      ]"
    >
      <div v-if="generatingAnimation" class="action-items-section__loader">
        <font-awesome-icon
          v-if="generatingAnimation"
          :icon="['fal', 'spinner-third']"
          spin
        />
        <span v-if="generatingAnimation">{{
          $t(`knowledge.ai-drawer.generating`)
        }}</span>
      </div>
      <LoadingBar v-if="generating" />
      <LoadingBar v-if="generating" />
      <LoadingBar v-if="generating" :width="50" />
      <div
        v-if="deletingAnimation"
        class="action-items-section__deleting-container"
      >
        <div v-if="deleting">
          <font-awesome-icon :icon="['fal', 'spinner-third']" spin />
          <span> {{ $t(`knowledge.ai-drawer.deleting.title`) }}</span>
          <button class="btn btn-sm" @click="$emit('cancel')">
            {{ $t(`knowledge.ai-drawer.cancel`) }}
          </button>
        </div>
        <div v-else-if="!processInterrupted">
          <font-awesome-icon :icon="['fal', 'check']" />
          <span> {{ $t(`knowledge.ai-drawer.deleting.done`) }}</span>
        </div>
      </div>
      <div v-else-if="!generating" class="action-items-section__content">
        <div v-if="!generatingAnimation" class="action-items-section__header">
          <div class="action-items-section__label">
            <div
              v-if="!isEmpty && lastUpdate"
              class="action-items-section__switch"
            >
              <div class="action-items-section__switch-label">
                {{ $t('knowledge.ai-drawer.visible.title') }}
                <el-tooltip
                  :content="$t('knowledge.ai-drawer.visible.tooltip')"
                >
                  <font-awesome-icon :icon="['fal', 'question-circle']" />
                </el-tooltip>
              </div>
              <el-switch v-model="visibility" @change="handleSave" />
            </div>
          </div>
          <div class="action-items-section__actions">
            <el-tooltip
              placement="top"
              :content="$t('knowledge.ai-drawer.action-items.expired')"
            >
              <div :class="['action-items-section__date']">
                <font-awesome-icon :icon="['fal', 'info-circle']" />
                <span
                  >{{
                    $t(
                      `knowledge.ai-drawer.${
                        manualEdit ? 'edited-at' : 'generated-at'
                      }`,
                    )
                  }}
                  {{ updatedAt }}</span
                >
              </div>
            </el-tooltip>
            <base-dropdown direction="down">
              <div slot="title" class="action-items-section__button">
                <font-awesome-icon :icon="['fal', 'ellipsis-h']" />
              </div>
              <div
                :class="['dropdown-item', { disabled: generating }]"
                @click="$emit('generate-action-items')"
              >
                <font-awesome-icon
                  :icon="['fal', 'undo']"
                  class="dropdown-item__icon"
                />
                <span>{{ $t(`knowledge.ai-drawer.popover.regenerate`) }}</span>
              </div>
              <div class="dropdown-item" @click="editing = true">
                <font-awesome-icon
                  :icon="['fal', 'pen']"
                  class="dropdown-item__icon"
                />
                <span>{{ $t(`knowledge.ai-drawer.popover.edit`) }}</span>
              </div>
              <div class="dropdown-item" @click="$emit('delete')">
                <font-awesome-icon
                  :icon="['fal', 'trash']"
                  class="dropdown-item__icon"
                />
                <span>{{ $t(`knowledge.ai-drawer.popover.delete`) }}</span>
              </div>
            </base-dropdown>
          </div>
        </div>
        <div v-if="editing" class="action-items-section__editor">
          <mayday-editor
            ref="actionsEditor"
            :content="parseActionItems"
            :editable="editing"
            :options="{
              hideDragHandle: true,
              fontOptions: false,
              structurationOptions: true,
              richOptions: false,
            }"
          />
          <div v-if="editing">
            <button
              class="btn btn-sm btn-primary"
              @click="handleSave"
              :disabled="!editing"
            >
              {{ $t(`knowledge.ai-drawer.save`) }}
            </button>
            <button
              class="btn btn-sm btn-secondary"
              @click="handleCancel"
              :disabled="!editing"
            >
              {{ $t(`knowledge.ai-drawer.cancel`) }}
            </button>
          </div>
        </div>
        <div
          v-else
          v-html="parseActionItems"
          class="action-items-section__actions-container"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingBar from 'components/AI/LoadingBar.vue';
import AiBadge from 'components/AI/AiBadge.vue';

export default {
  name: 'ActionItemsSection',
  components: { AiBadge, LoadingBar },
  props: {
    generating: {
      type: Boolean,
      default: true,
    },
    deleting: {
      type: Boolean,
      default: false,
    },
    processInterrupted: {
      type: Boolean,
      default: false,
    },
    actionItems: {
      type: Array,
      default: () => [],
    },
    lastUpdate: {
      type: String,
      default: '',
    },
    docLastUpdate: {
      type: String,
      default: '',
    },
    manualEdit: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    generatingAnimation: false,
    deletingAnimation: false,
    editing: false,
    visibility: false,
    currentActionItems: [],
  }),
  created() {
    this.currentActionItems = this.actionItems;
    this.visibility = this.isVisible;
  },
  computed: {
    updatedAt() {
      if (!this.lastUpdate) return null;
      const date = new Date(parseInt(this.lastUpdate));
      return `${date.getDate() < 10 ? 0 : ''}${date.getDate()}/${
        date.getMonth() < 9 ? 0 : ''
      }${date.getMonth() + 1}/${date.getFullYear()}`;
    },
    isEmpty() {
      return (
        (!this.actionItems || !this.actionItems.length) &&
        !this.generating &&
        !this.generatingAnimation
      );
    },
    isExpired() {
      return false;
      //We keep this code commented for now, we first need to define the expiration time
      /*if (!this.docLastUpdate) return false;
      return this.docLastUpdate - 3000 > this.lastUpdate;*/
    },
    parseActionItems() {
      if (!this.actionItems) return null;
      return `<ul>${this.actionItems
        .map((item) => `<li>${item}</li>`)
        .join('')}</ul>`;
    },
  },
  methods: {
    handleSave() {
      const updatedActionItems = {};
      if (this.$refs.actionsEditor) {
        this.currentActionItems = this.sanitizeEditorHTML(
          this.$refs.actionsEditor.editor.getHTML(),
        );
      }
      if (this.currentActionItems !== this.actionItems) {
        updatedActionItems.body = this.currentActionItems;
        updatedActionItems.manualEdit = true;
      }
      updatedActionItems.isVisible = this.visibility;
      this.editing = false;
      this.$emit('save', updatedActionItems);
    },
    handleCancel() {
      this.editing = false;
      this.currentActionItems = this.actionItems;
      this.$refs.actionsEditor.editor.commands.setContent(
        this.parseActionItems,
      );
    },
    sanitizeEditorHTML(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      const ul = div.querySelector('ul');
      if (!ul) return '';
      const lis = ul.querySelectorAll('li');
      const actionItems = [];
      lis.forEach((li) => {
        actionItems.push(
          li.innerHTML.replace(/<p>/g, '').replace(/<\/p>/g, ''),
        );
      });
      return actionItems;
    },
  },
  watch: {
    deleting() {
      if (this.deleting) {
        this.generatingAnimation = false;
        this.deletingAnimation = true;
      } else if (!this.processInterrupted) {
        setTimeout(() => {
          this.deletingAnimation = false;
        }, 1000);
      } else {
        this.deletingAnimation = false;
      }
    },
    generating: {
      handler(newVal, oldVal) {
        if (oldVal && !newVal) {
          if (this.lastUpdate !== this.docLastUpdate)
            setTimeout(() => {
              this.generatingAnimation = false;
            }, 1000);
          else {
            this.generatingAnimation = false;
          }
        } else if (newVal) {
          this.generatingAnimation = true;
        }
      },
      immediate: true,
    },
    actionItems() {
      this.currentActionItems = this.actionItems;
    },
  },
};
</script>

<style scoped lang="scss">
.action-items-section__empty {
  height: 0;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $grey-1-mayday;
  text-align: center;
  border-radius: 4px;

  &.expanded {
    height: 188px;
    gap: 8px;
    padding: 12px 18px;
  }
  .btn {
    background-color: $purple-5-mayday;
    border-color: $purple-5-mayday;
    svg {
      margin-right: 4px;
    }
  }

  .action-items-section__empty-title {
    font-size: 16px;
    font-weight: 700;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
  }

  .action-items-section__empty-description {
    font-size: 14px;
    font-weight: 400;
    color: $grey-6-mayday;
  }

  .ai-drawer__badge-container {
    margin-top: -4px;
  }

  .action-items-section__infos {
    font-size: 12px;
    color: $grey-6-mayday;
  }
}

.action-items-section__body {
  background-color: $grey-1-mayday;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 0;
  overflow: hidden;
  font-size: 14px;

  &.loading {
    padding: 8px;
    height: 188px;
  }

  &.expanded {
    padding: 12px 18px;
    height: 188px;
  }

  &.editing {
    padding: 12px 18px;
    height: 188px;
  }

  .action-items-section__loader {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    color: $purple-5-mayday;
    font-size: 16px;
  }

  .action-items-section__deleting-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 100%;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      svg {
        font-size: 24px;
        color: $red-mayday;
      }
      span {
        font-size: 16px;
        color: $grey-6-mayday;
        font-weight: 700;
      }
    }
  }
}

.action-items-section__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  .action-items-section__label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    color: $purple-5-mayday;
    font-size: 16px;

    .action-items-section__switch {
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 12px;
      color: $grey-6-mayday;
      font-weight: 500;

      :deep() .el-switch__core {
        height: 16px;
        width: 28px !important;
      }

      :deep() .el-switch__core:after {
        width: 12px;
        height: 12px;
      }

      :deep() .el-switch.is-checked .el-switch__core::after {
        margin-left: -13px;
      }

      :deep() .is-checked .el-switch__core {
        background-color: $purple-5-mayday;
        border-color: $purple-5-mayday;
      }
    }
  }

  .action-items-section__actions {
    display: flex;
    gap: 12px;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    color: $grey-6-mayday;

    .dropdown-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        color: $grey-2-mayday;
        pointer-events: none;
      }

      &:hover {
        color: $purple-5-mayday;
      }

      .dropdown-item__icon {
        font-size: 14px;
      }
    }
  }

  .action-items-section__date {
    padding: 2px 4px;
    border-radius: 4px;
    display: flex;
    gap: 4px;
    align-items: center;
    &.expired {
      color: $yellow-mayday;
      background-color: rgba($yellow-mayday, 0.1);
    }
  }
  .action-items-section__button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $grey-7-mayday;
    cursor: pointer;
    font-size: 16px;
    width: 20px;
    height: 20px;
    padding: 2px;
    border-radius: 4px;

    svg {
      transition: transform 0.2s ease-in-out;
    }

    &:hover {
      color: $purple-5-mayday;
      background-color: $grey-3-mayday;
    }
  }
}

.action-items-section__editor {
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: all 0.2s ease-in-out;
  align-items: flex-end;
  :deep() .mayday-editor {
    width: 100%;
    padding: 0 0 40px;
    height: 100px;
    overflow-y: auto;
    ul {
      padding: 0 14px;
      margin: 0;
      li {
        margin: 0;
        &::marker {
          color: $purple-5-mayday;
        }
        p {
          font-size: 14px !important;
        }
      }
    }
  }

  .btn {
    color: $purple-5-mayday;
  }

  .btn-primary {
    background-color: $purple-5-mayday;
    border-color: $purple-5-mayday;
    color: white;
  }
}

.action-items-section__actions-container {
  max-height: 120px;
  overflow-y: auto;
  :deep() ul {
    padding: 0 14px;
    margin: 0;
    li {
      margin: 0;
      &::marker {
        color: $purple-5-mayday;
      }
      p {
        font-size: 14px !important;
      }
    }
  }
}
</style>
