<template>
  <section class="mayday-web-product-solutions-card py-2">
    <product-viewer-item-card card-title="Collections" :is-collapsable="true">
      <!-- ROOT VIEW -->
      <div class="root-view" v-if="isRoot">
        <div class="root-collection-wrapper" v-if="!resolutionSearch">
          <RootCollectionCard
            v-for="collection in cases"
            :key="collection.id"
            :collection="collection"
            @click="getChild(collection)"
          />
        </div>
        <div class="search-resolutions-list my-2" v-else>
          <SearchResolutionsList
            v-loading="isSolutionLoading"
            :solutions="solutions"
            :solutions-count="solutionsCount"
            :more-options="moreOptions"
            @focus-content="$emit('focus-content', $event)"
            @click:more="updateDisplayCount('more')"
            @click:less="updateDisplayCount('less')"
          />
        </div>
      </div>

      <!-- NAVIGATION VIEW -->
      <div class="navigation-view" v-else>
        <CollectionsNavigator
          :parents="parents"
          :collections="cases"
          :solutions="solutions"
          :solutions-count="solutionsCount"
          :is-solution-loading="isSolutionLoading"
          :is-collection-loading="isCollectionLoading"
          :resolution-search="resolutionSearch"
          @click:child="getChild"
          @click:home="getRoots"
          @click:parent="getParent"
          @search-input="launchSearchDebounce"
          @search-keyup:enter="updateResolutionSearch"
          @focus-content="$emit('focus-content', $event)"
          @click:more="updateDisplayCount('more')"
          @click:less="updateDisplayCount('less')"
        />
      </div>
    </product-viewer-item-card>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import debounce from 'lodash.debounce';
import CollectionsNavigator from './CollectionsNavigator/CollectionsNavigator';
import ProductViewerItemCard from '../ProductViewerItemCard';
import RootCollectionCard from './RootCollectionCard';
import SearchResolutionsList from './SearchResolutionsList';

export default {
  name: 'mayday-web-product-solutions-card',
  components: {
    ProductViewerItemCard,
    RootCollectionCard,
    CollectionsNavigator,
    SearchResolutionsList,
  },
  props: {
    solutions: {
      type: Array,
      required: true,
    },
    solutionsCount: {
      type: Number,
      required: true,
    },
    productId: {
      type: String,
    },
    knowledge: {
      type: String,
    },
    parameters: {
      type: Array,
      default: () => [],
    },
    isSolutionLoading: {
      type: Boolean,
      default: false,
    },
    isPartial: {
      type: Boolean,
      default: false,
    },
    resolutionSearch: {
      type: String,
      default: '',
    },
  },
  async mounted() {
    window.addEventListener('beforeunload', () =>
      this.launchSearchDebounce.flush(),
    );
  },
  computed: mapGetters('webModule', {
    isRoot: 'rootCollectionFocused',
    focusedCaseId: 'focusedCollectionId',
    cases: 'collections',
    parents: 'collectionParents',
    isCollectionLoading: 'collectionsLoading',
  }),
  data() {
    return {
      moreOptions: false,
      localResolutionSearch: this.resolutionSearch,
      launchSearchDebounce: debounce((search) => {
        this.moreOptions = false;
        this.$emit('update-resolution-search', {
          caseId: this.focusedCaseId,
          search,
        });
      }, 500),
    };
  },
  methods: {
    // CASES METHODS
    async getChild(collection) {
      const { id } = collection;
      const newParents = this.parents.concat([collection]);
      this.$emit('reset-search');
      await this.loadCollectionChildren(id);
      this.setCollectionParents(newParents);
    },

    async getRoots() {
      this.$emit('reset-search');
      await this.loadRootCollections();
      this.setCollectionParents([]);
    },

    async getParent(idx) {
      const collection = this.parents[idx];
      const newParents = this.parents.slice(0, idx + 1);
      await this.loadCollectionChildren(collection.id);
      this.setCollectionParents(newParents);
    },

    // SOLUTIONS METHODS
    updateResolutionSearch() {
      this.launchSearchDebounce.flush();
    },

    updateDisplayCount(value) {
      this.moreOptions = value === 'more';
      this.loadSolutions(
        this.focusedCaseId,
        value === 'more' ? this.solutionsCount : 10,
        this.resolutionSearch,
      );
    },

    loadSolutions(caseId, count = 10, search = '') {
      this.$emit('update-solutions', { caseId, count, search });
    },

    ...mapActions('webModule', [
      'setCollectionParents',
      'loadRootCollections',
      'loadCollectionChildren',
    ]),
  },
  watch: {
    parents() {
      const collection = this.parents.slice(-1)[0];
      this.loadSolutions(collection ? collection.id : null);
    },
  },
  beforeDestroy() {
    this.launchSearchDebounce.flush();
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  :deep() .form-control {
    font-size: 16px;
    font-weight: 500;
  }
  :deep() .form-control::placeholder {
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
