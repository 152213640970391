<template>
  <div class="status-indicator" :class="status">
    <font-awesome-icon
      :icon="['fal', statusIcon]"
      class="indicator"
      :spin="status === 'loading'"
    />
  </div>
</template>

<script>
export default {
  name: 'status-indicator',
  props: {
    status: {
      type: String,
      default: null,
      validator: (v) =>
        ['published', 'draft', 'no-status', 'loading'].includes(v),
    },
  },
  computed: {
    statusIcon() {
      switch (this.status) {
        case 'loading':
          return 'spinner-third';
        case 'published':
          return 'check';
        case 'draft':
          return 'file';
        default:
          return 'times';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.status-indicator {
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex: none;
}

.no-status {
  border: 1px solid $grey-6-mayday;
  background-color: $grey-3-mayday;
  .indicator {
    color: $grey-6-mayday;
  }
}

.published {
  border: 1px solid $blue-mayday;
  background-color: $blue-mayday;
  .indicator {
    color: white;
  }
}

.loading {
  .indicator {
    color: $purple-5-mayday;
    width: 20px;
    height: 20px;
  }
}

.draft {
  border: 1px solid $grey-6-mayday;
  background-color: $grey-3-mayday;
  .indicator {
    color: $grey-6-mayday;
  }
}
</style>
