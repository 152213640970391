<template>
  <modal
    :show.sync="localOpen"
    modal-classes="modal-dialog-centered modal align-items-center product-agora-documentation-side-bar"
  >
    <h4 slot="header" class="modal-title" id="modal-title-default">AGORA</h4>
    <product-agora-documentation
      v-if="documentations"
      :documentations="documentations"
      context="modal"
    />
    <product-agora-documentation-error
      v-if="error"
      :error="error"
      @click:retry="$emit('click:retry')"
    />
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import ProductAgoraDocumentation from './ProductAgoraDocumentation.vue';
import ProductAgoraDocumentationError from './ProductAgoraDocumentationError.vue';

export default {
  name: 'product-agora-documentation-modal',
  props: {
    display: Boolean,
    documentations: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Object,
    },
  },
  components: {
    Modal,
    ProductAgoraDocumentation,
    ProductAgoraDocumentationError,
  },
  data() {
    return {
      localOpen: this.display,
    };
  },
  watch: {
    localOpen() {
      if (!this.localOpen) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-main {
  max-height: 60vh;
  overflow: auto;
}

:deep() .modal-header {
  align-items: center;
}

:deep() .modal-body {
  padding-top: 0px;
}

.trigger-slideshow {
  cursor: pointer;
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.carousel-slide {
  cursor: crosshair;
}
</style>
