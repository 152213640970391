<template>
  <div class="animated-text__container">
    <span class="animated-text">
      {{ textArray.join('') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'AnimatedText',
  props: {
    text: {
      type: String,
      required: true,
    },
    container: {
      type: HTMLDivElement,
      required: false,
    },
  },
  data: () => ({
    textArray: [],
    autoScrollDisabled: false,
  }),
  methods: {
    appendTextArray() {
      const textArray = this.text.split('');
      textArray.forEach((word, i) => {
        setTimeout(() => {
          this.textArray.push(word);
          if (this.container && !this.autoScrollDisabled)
            this.container.scrollTop = this.container.scrollHeight;
          if (i === textArray.length - 1)
            setTimeout(() => {
              this.$emit('text-displayed');
            }, 100);
        }, i * 50);
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.appendTextArray();
      this.container.addEventListener('wheel', () => {
        this.autoScrollDisabled = true;
      });
    }, 10);
  },
};
</script>

<style scoped lang="scss">
.animated-text__container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.animated-text {
  display: inline-block;
  font-size: 14px;
  overflow: hidden;
  line-height: 21px;
  white-space: pre-wrap;
}

.animated-text::after {
  content: '';
  display: inline-block;
  width: 3px;
  height: 16px;
  background-color: $purple-5-mayday;
  animation: animated-caret 1s step-end infinite;
  animation-fill-mode: forwards;
  margin-left: 2px;
  border-radius: 2px;
  margin-bottom: -2px;
}

@keyframes animated-caret {
  from,
  to {
    background-color: transparent;
  }
  50% {
    background-color: $purple-5-mayday;
  }
}
</style>
